import React from 'react'

const Pencil = ({ fill = '#323232', width = '20', height = '20', ...rest }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.511 1.9 3.278 3.278a1.15 1.15 0 0 1 .006 1.628l-1.823 1.822-4.905-4.906L13.889 1.9a1.15 1.15 0 0 1 1.622 0ZM1.29 18l1.056-4.556 8.766-8.777 4.912 4.9-8.8 8.766-4.584 1.056c-.08.008-.16.008-.239 0A1.144 1.144 0 0 1 1.29 18Z"
        fill={fill}
      />
    </svg>
  )
}

export default Pencil
