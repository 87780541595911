import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import {
  split,
  eq,
  mapKeys,
  get,
  isNull,
  map,
  find,
  isUndefined,
  filter,
} from 'lodash'
import { fixFloatingPointErrors } from '../utils/utils'
import { API_PATHS } from '@pv3-constants'
import TokenRequest from '../utils/tokenRequest';

const request = new Request()
let pricesTimer = null
let volumesTimer = null;

const calculateOnOrderTotal = (currency, orders) => {
  let total = orders.reduce((acc, order) => {
    let currencies = split(get(order, 'instrument'), '_')
    if (eq(currencies[0], currency) && eq(get(order, 'type'), 'sell')) {
      return parseFloat(
        fixFloatingPointErrors(
          acc + (get(order, 'amount') - get(order, 'unitsFilled')),
          12
        )
      )
    } else if (eq(currencies[1], currency) && eq(get(order, 'type'), 'buy')) {
      return parseFloat(
        fixFloatingPointErrors(
          acc +
            (get(order, 'amount') - get(order, 'unitsFilled')) *
              get(order, 'price'),
          12
        )
      )
    } else {
      return acc
    }
  }, 0)

  return total
}

export const getAccountBalance = createAsyncThunk(
  'wallets/getAccountBalance',
  async (_, { getState, rejectWithValue }) => {
    try {
      let res = await new TokenRequest(getState().account.idToken).get(API_PATHS.COIN.ACCOUNT_BALANCE);
      return res.data;
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const getUsdPrices = createAsyncThunk(
  'wallets/getUsdPrices',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.PRICES)
      const balances = get(getState(), 'wallets.balances')

      if (!isNull(balances)) {
        let total = 0
        let walletValues = {}

        const orders = get(getState(), 'trades.openOrders')

        mapKeys(balances, function (value, key) {
          if (response.data[key]) {
            let onOrder = calculateOnOrderTotal(key, orders)
            let value = response.data[key] * (balances[key] + onOrder)
            total = total + value
            walletValues[key] = value
          }
        })

        dispatch(setTotalValue(total))
        dispatch(setWalletValues(walletValues))
      }

      if (isNull(pricesTimer)) {
        setTimeout(() => dispatch(getUsdPrices()), 600)
        setTimeout(() => dispatch(getUsdPrices()), 1800)
        setTimeout(() => dispatch(getUsdPrices()), 5000)
        pricesTimer = setInterval(() => dispatch(getUsdPrices()), 15000)
      }

      return response.data
    } catch (e) {
      rejectWithValue(e.message)
    }
  }
)

export const getVolumes = createAsyncThunk(
  'wallets/getVolumes',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await new TokenRequest(getState().account.idToken).get(API_PATHS.COIN.VOLUMES)

      if (isNull(volumesTimer)) {
        volumesTimer = setInterval(() => dispatch(getVolumes()), 15000)
      }

      let volumes = {};

      response.data.forEach((d) => {
        volumes[d.assetId] = {
          volume: d.volume,
          changePercentage: d.changePercentage * 100,
        };
      });

      return volumes;
    } catch (e) {
      rejectWithValue(e.message)
    }
  }
)

export const getBuySellPrices = createAsyncThunk(
  'wallets/getBuySellPrices',
  async (_, { getState, rejectWithValue }) => {
    try {
      let res = await new TokenRequest(getState().account.idToken).get(API_PATHS.COIN.PRICES);
      let data = {};
      res.data.forEach((price) => {
        data[price.assetId] = price;
      });
      return data;
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const createOrder = createAsyncThunk(
  'wallets/createBuyOrder',
  async (order, { getState, rejectWithValue }) => {
    try {
      await new TokenRequest(getState().account.idToken).post(API_PATHS.COIN.ORDER, order);
    } catch (e) {
      const errorMessage = e.response.data;
      if(errorMessage.includes("System.ArgumentOutOfRangeException: Price spread is too low (Parameter 'Price')")) {
        return rejectWithValue('Your LION orders cannot be within 5% of your other LION orders.');
      } else if(errorMessage.includes("System.ArgumentOutOfRangeException: Amount exceeds maximum allowed amount in USD (Parameter 'Amount')")) {
        return rejectWithValue('LION orders must be less than $1,000 per order.');
      }

      return rejectWithValue('There was an error submitting your order.');
    }
  }
)

export const getWithdrawalLimits = createAsyncThunk(
  'wallets/getWithdrawalLimits',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.WITHDRAWAL_LIMITS)
      return response.data
    } catch (e) {
      rejectWithValue(e.message)
    }
  }
)

const walletsSlice = createSlice({
  name: 'wallets',
  initialState: {
    accountBalances: null,
    balances: null,
    buySellPrices: {},
    buySellPricesUpdateDateTime: null,
    totalValueInUSD: null,
    transfers: [],
    usdPrices: {},
    usdPricesUpdateDateTime: null,
    walletValuesInUSD: {},
    withdrawalLimits: {},
  },
  reducers: {
    setBalances: (state, action) => {
      state.balances = {
        ...state.balances,
        ...action.payload,
      }
    },
    setTotalValue: (state, action) => {
      state.totalValueInUSD = action.payload
    },
    setWalletValues: (state, action) => {
      state.walletValuesInUSD = action.payload
    },
    setTransfers: (state, action) => {
      const updatedTransfers = map(state.transfers, (transfer) => {
        const update = find(action.payload, ({ transferId }) =>
          eq(transferId, transfer.transferId)
        )
        return isUndefined(update) ? transfer : update
      })

      state.transfers = [
        ...filter(action.payload, (transfer) => {
          const existingTransfer = find(state.transfers, ({ transferId }) =>
            eq(transferId, transfer.transferId)
          )
          return isUndefined(existingTransfer)
        }),
        ...updatedTransfers,
      ]
    },
  },
  extraReducers: {
    [getAccountBalance.fulfilled]: (state, action) => {
      state.accountBalances = action.payload;
    },
    [getUsdPrices.fulfilled]: (state, action) => {
      state.usdPrices = action.payload
      state.usdPricesUpdateDateTime = new Date().getTime()
    },
    [getVolumes.fulfilled]: (state, action) => {
      state.volumes = action.payload
      state.volumesUpdateDateTime = new Date().getTime()
    },
    [getBuySellPrices.fulfilled]: (state, action) => {
      state.buySellPrices = action.payload;
      state.buySellPricesUpdateDateTime = new Date().getTime();
    },
    [getWithdrawalLimits.fulfilled]: (state, action) => {
      state.withdrawalLimits = action.payload
    },
  },
})

export const { setBalances, setTotalValue, setWalletValues, setTransfers } =
  walletsSlice.actions
export default walletsSlice.reducer
