import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, isEmpty } from 'lodash'
import Request from '../utils/request'
import { API_PATHS } from '@pv3-constants'
import { formatISO } from 'date-fns'
import {
  FACIAL_VERIFICATION_TIMEOUT,
  KYC_VERIFICATION_LEVELS,
  KYC_VERIFICATION_STATUSES,
  PROFILE_TYPES,
  PROFILE_TYPES_LOOKUP
} from "../constants";
import TokenRequest from "../utils/tokenRequest";

const request = new Request()

let timer;

export const convertAssets = createAsyncThunk(
  'profile/convertAssets',
  async ({creditAsset, debitAsset}, { rejectWithValue }) => {
    try {
      const res = await request.post(API_PATHS.COIN.CONVERT_ASSETS, {
        debitAsset,
        creditAsset,
        convertAll: true
      });
      return res;
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const getActivitySummary = createAsyncThunk(
  'profile/getActivitySummary',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const tokenRequest = new TokenRequest(getState().account.idToken);
      const response = await tokenRequest.get(API_PATHS.COIN.ACTIVITY_SUMMARY);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.status);
    }
  }
)

export const getAddresses = createAsyncThunk(
    'profile/getAddresses',
    async (_, { dispatch, getState, rejectWithValue }) => {
      try {
        const tokenRequest = new TokenRequest(getState().account.idToken);
        let response = await tokenRequest.get(API_PATHS.COIN.ADDRESSES);

        /*let response = {
          data: [
            {
              id: 1,
              country: 'US',
              postCode: '55555',
              town: 'Springfield',
              street: '123 Fake St',
              subStreet: null,
              state: 'SD',
              buildingName: null,
              flatNumber: null,
              buildingNumber: '116',
              endDate: new Date().toString(),
              startDate: new Date(2022, 1, 1).toString(),
            }, {
              country: 'US',
              postCode: '57106',
              town: 'Sioux Falls',
              street: 'w 64th St',
              subStreet: '',
              state: 'SD',
              buildingName: '',
              flatNumber: '',
              buildingNumber: '7005',
              startDate: new Date(2020, 1, 1).toString(),
              endDate: new Date (2022, 1, 1).toString(),
              formattedAddress: '',
            }
          ]
        }*/

        response.data.forEach((address, i) => {
          address.id = i + 1;
        });

        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.status);
      }
    }
)

export const getFacialVerificationResult = createAsyncThunk(
  'profile/getFacialVerificationResult',
  async (applicantActionId, { dispatch, rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.SUMSUB_FACE_AUTH_STATUS, {applicantActionId});

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.status);
    }
  }
)

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const profileResponse = await request.get(API_PATHS.COIN.PROFILE_V2)
      profileResponse.data.profileType = PROFILE_TYPES_LOOKUP[profileResponse.data.profileType]
      //profileResponse.data.kycVerificationStatus = 2;
      // start polling because the account is pending at we want to be able to tell them when they are approved
      if(profileResponse.data.kycVerificationStatus === KYC_VERIFICATION_STATUSES.PENDING) {
        window.clearTimeout(timer);
        timer = setTimeout(() => dispatch(updateKycVerificationStatus()), 30000);
      }

      if(profileResponse.data.hasDarkTheme) {
        document.querySelector(':root').classList.add('dark-mode');
      } else {
        document.querySelector(':root').classList.remove('dark-mode');
      }

      return profileResponse.data
    } catch (e) {
      return rejectWithValue(e.response.status)
    }
  }
)

export const updateKycVerificationStatus = createAsyncThunk(
  'profile/updateKycVerificationStatus',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const profileResponse = await request.get(API_PATHS.COIN.PROFILE_V2)

      // start polling because the account is pending at we want to be able to tell them when they are approved
      if(profileResponse.data.kycVerificationStatus === KYC_VERIFICATION_STATUSES.PENDING) {
        window.clearTimeout(timer);
        timer = setTimeout(() => dispatch(updateKycVerificationStatus()), 30000);
      }

      return profileResponse.data.kycVerificationStatus;
    } catch (e) {
      return rejectWithValue(e.response.status)
    }
  }
)

export const getCountries = createAsyncThunk(
  'profile/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const countriesResponse = await request.get(API_PATHS.COIN.COUNTRIES)
      return countriesResponse.data.data
    } catch (e) {
      return rejectWithValue(e.response.status)
    }
  }
)

export const getStatePermissions = createAsyncThunk(
  'profile/getStatePermissions',
  async (_, { rejectWithValue }) => {
    try {
      const statePermissionsResponse = await request.get(API_PATHS.COIN.STATES)
      return statePermissionsResponse.data
    } catch (e) {
      return rejectWithValue(e.response.status)
    }
  }
)

export const updateBusinessProfile = createAsyncThunk(
  'profile/updateBusiness',
  async (profileUpdate, { rejectWithValue }) => {
    try {
      const updateResponse = await request.put(
        API_PATHS.COIN.PROFILE,
        profileUpdate
      )
      return updateResponse.data
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (profileUpdate, { rejectWithValue }) => {
    try {
      const updateResponse = await request.put(
        API_PATHS.COIN.PROFILE_V2,
        profileUpdate
      )
      updateResponse.data.profileType = PROFILE_TYPES_LOOKUP[updateResponse.data.profileType]
      return { ...updateResponse.data }
    } catch (e) {
      const errors = get(e, 'response.data.errors')

      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      }

      return rejectWithValue(errors)
    }
  }
)

export const setProfileType = createAsyncThunk(
  'profile/setProfileType',
  async ({profileType, country, state: region}, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      let profileData = {
        ...state.profile.data,
        profileType,
      };

      if(country) {
        profileData.country = country;
      }

      if(region) {
        profileData.state = region;
      }

      const updateResponse = await request.put(API_PATHS.COIN.PROFILE_V2, profileData);
      updateResponse.data.profileType = PROFILE_TYPES_LOOKUP[updateResponse.data.profileType];
      return updateResponse.data;
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const setProfileTin = createAsyncThunk(
  'profile/setProfileTin',
  async (profileTin, { rejectWithValue }) => {
    try {
      const updateResponse = await request.put(API_PATHS.COIN.PROFILE_TIN, {
        tin: profileTin,
      })
      return updateResponse.data
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const setProfileAcceptTerms = createAsyncThunk(
  'profile/setProfileAcceptTerms',
  async (_, { rejectWithValue }) => {
    try {
      const updateResponse = await request.post(API_PATHS.COIN.ACCEPT_TERMS)

      return updateResponse.data
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const getRoi = createAsyncThunk(
  'profile/getRoi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.ROI)
      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const getSumsubToken = createAsyncThunk(
  'profile/getSumsubToken',
  async ({levelName} = {}, { getState, rejectWithValue }) => {

    try {
      if(!levelName) {
        const profile = getState().profile.data;
        levelName = KYC_VERIFICATION_LEVELS.BUSINESS;

        if(profile.profileType === PROFILE_TYPES.INDIVIDUAL) {
          if(profile.country === 'US') {
            levelName = KYC_VERIFICATION_LEVELS.INDIVIDUAL;
          } else {
            levelName = KYC_VERIFICATION_LEVELS.INDIVIDUAL_INTERNATIONAL;
          }
        }
      }

      let path = API_PATHS.COIN.SUMSUB_TOKEN;
      if(levelName === KYC_VERIFICATION_LEVELS.FACIAL_VERIFICATION) {
        path = API_PATHS.COIN.SUMSUB_FACE_AUTH_TOKEN;
      }

      const response = await request.post(path, {levelName});

      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const setHomeQuoteAsset = createAsyncThunk(
  'profile/setHomeQuoteAsset',
  async (homeQuoteAsset, { rejectWithValue }) => {
    try {
      const updateResponse = await request.put(API_PATHS.COIN.PROFILE, {
        homeQuoteAsset: homeQuoteAsset,
      })
      return get(updateResponse.data, 'homeQuoteAsset')
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

export const uploadAvatar = createAsyncThunk(
  'profile/uploadAvatar',
  async (file, { dispatch, rejectWithValue }) => {
    try {
      let formData = new FormData();
      formData.append('avatarImageFile', file);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => dispatch(setAvatarUploadPercentage((progressEvent.loaded / progressEvent.total) * 100))
      };

      const uploadResponse = await request.post(API_PATHS.COIN.AVATAR_UPLOAD, formData, config);

      setTimeout(() => dispatch(setAvatarUploadPercentage(0)), 500);

      return uploadResponse;
    } catch (e) {
      const errors = get(e, 'response.data.errors')
      if (isEmpty(errors)) {
        return rejectWithValue([{ message: 'Unrecognized error' }])
      } else {
        return rejectWithValue(errors)
      }
    }
  }
)

const defaultState = {
  activitySummary: {},
  addresses: [],
  data: {
    nickname: '',
    email: '',
    userId: '',
    countryCode: '',
    taxIdentificationLastFour: '',
    profileType: '',
    created: null,
    homeQuoteAsset: null,
    kycVerificationStatus: null,
  },
  permissions: {
    usdAllowed: null,
    usdAllowedReason: null,
    reason: null,
  },
  errors: [],
  roi: {},
  assetsForMigration: null,
  sumsubToken: '',
  lastProfileRetrieval: null,
  statePermissions: null,
  countries: null,
  avatarUploadPercentage: 0,
  facialVerificationApplicantActionId: null,
  facialVerificationResult: null,
  facialVerificationTime: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState: defaultState,
  reducers: {
    setAvatarUploadPercentage: (state, action) => {
      state.avatarUploadPercentage = action.payload;
    },
    clearSumsubToken: (state) => {
      state.sumsubToken = '';
    },
    setAssetsForMigration: (state, action) => {
      state.assetsForMigration = action.payload;
    },
    setFacialVerificationApplicantActionId: (state, action) => {
      state.facialVerificationApplicantActionId = action.payload;
    },
    setFacialVerificationResult: (state, action) => {
      if(action.payload) {
        state.facialVerificationResult = action.payload;
        state.facialVerificationTime = new Date().getTime();
      } else {
        state.facialVerificationResult = null;
        state.facialVerificationTime = null;
      }
    },
    setTradingPermissions: (state, action) => {
      state.permissions = action.payload
    },
    testFacialVerificationExpiration: (state) => {
      if(state.facialVerificationTime && new Date() - new Date(state.facialVerificationTime) > FACIAL_VERIFICATION_TIMEOUT) {
        state.facialVerificationResult = null;
        state.facialVerificationTime = null;
      }
    }
  },
  extraReducers: {
    [getActivitySummary.fulfilled]: (state, action) => {
      state.activitySummary = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries = action.payload
    },
    [getFacialVerificationResult.fulfilled]: (state, action) => {
      state.facialVerificationResult = action.payload.reviewAnswer || action.payload;
      state.facialVerificationTime = new Date();
    },
    [getProfile.fulfilled]: (state, action) => {
      state.data = action.payload
      state.lastProfileRetrieval = formatISO(new Date())
    },
    [updateKycVerificationStatus.fulfilled]: (state, action) => {
      state.data.kycVerificationStatus = action.payload
    },
    [getStatePermissions.fulfilled]: (state, action) => {
      state.statePermissions = action.payload
    },
    'session/signOut/fulfilled': (state, action) => defaultState,
    'session/signOut/rejected': (state, action) => defaultState,
    [updateProfile.fulfilled]: (state, action) => {
      state.data = action.payload
    },
    [updateProfile.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [updateBusinessProfile.fulfilled]: (state, action) => {
      state.data = action.payload
    },
    [updateBusinessProfile.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [setProfileType.fulfilled]: (state, action) => {
      state.data = action.payload
    },
    [setProfileType.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [setHomeQuoteAsset.fulfilled]: (state, action) => {
      state.data.homeQuoteAsset = action.payload
    },
    [setProfileAcceptTerms.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [setProfileTin.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [getRoi.fulfilled]: (state, action) => {
      state.roi = action.payload
    },
    [getSumsubToken.fulfilled]: (state, action) => {
      state.sumsubToken = action.payload.token
    },
  },
})

export const { setAvatarUploadPercentage, clearSumsubToken, setAssetsForMigration, setFacialVerificationApplicantActionId, setFacialVerificationResult, setTradingPermissions, testFacialVerificationExpiration } = profileSlice.actions
export default profileSlice.reducer
