import React, { useEffect, useState } from 'react'
import Request from "../../../utils/request";
import { API_PATHS } from '@pv3-constants'
import {eq, find, get as _get, isEmpty, isUndefined, map} from "lodash";
import useProfileDuck from "../../../hooks/useProfileDuck";
import Select from "../Select";
import { useFormContext } from "react-hook-form";
import countryLibrary from "country-region-data";
import EnhancedFlash from "../EnhancedFlash";
import InputError from "../InputError";

const request = new Request()

const CountryAndRegionSelect = ({setDisableContinue}) => {
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [showStateNotAllowed, setShowStateNotAllowed] = useState(false)

  const { countries, getCountries } = useProfileDuck();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext();

  const selectedCountry = watch('country');
  const selectedRegion = watch('state');

  useEffect(() => {
    if (!isEmpty(countries)) return
      ;(async function requestCountries() {
      try {
        getCountries();

        const { data: usStates } = await request.get(API_PATHS.COIN.STATES);

        setStates(usStates);
      } catch (error) {
        console.error(error);
      }
    })()
  }, [countries, getCountries]);

  useEffect(() => {
    if (eq(selectedCountry, 'US')) {
      setRegions(
        map(states, (state) => {
          return { name: _get(state, 'name'), shortCode: _get(state, 'uspsId') }
        })
      );
    } else {
      const selectedCountryData = find(countryLibrary, {
        countryShortCode: selectedCountry,
      });

      if (isUndefined(selectedCountryData)) return;

      setRegions(_get(selectedCountryData, 'regions'));
    }
    setShowStateNotAllowed(false);
    setValue('state', '');
    clearErrors('state');
  }, [clearErrors, selectedCountry, setValue, states]);

  useEffect(() => {
    if (eq(selectedCountry, 'US') && !isEmpty(getValues('state'))) {
      trigger('state');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    setDisableContinue(showStateNotAllowed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStateNotAllowed]);

  return (
    <div className="CountryAndRegionSelect">
      <div className="InputsTwoUp">
        <label className="Label">
          Eligible countries
          <Select
            ariaLabel="Select country"
            id="country_select"
            className={`${errors.country ? 'Input--error' : ''}`}
            register={{
              ...register('country', {
                required: 'Country is required',
              }),
            }}
            defaultValue=""
          >
            <option disabled value="" key="select">
              Select…
            </option>
            {map(countries, ({ name, code }) => (
              <option id={`{country_${code}}`} key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
          {errors.country && <InputError message={errors.country.message} />}
        </label>
        <label className="Label">
          State or territory
          <Select
            ariaLabel="Select state or territory"
            id="state_select"
            className={`${errors.state ? 'Input--error' : ''}`}
            register={{
              ...register('state', {
                required: 'State or territory is required',
                validate: (value) => {
                  if (!eq(selectedCountry, 'US')) return true

                  const state = find(states, (s) => eq(s.uspsId, value))

                  if (
                    isUndefined(state) ||
                    !eq(_get(state, 'cryptoAllowed'), true)
                  ) {
                    setShowStateNotAllowed(true)
                    return 'State not eligible'
                  }

                  setShowStateNotAllowed(false)
                  return true
                },
              }),
            }}
            defaultValue=""
          >
            <option value="" disabled key="select">
              Select…
            </option>
            {isEmpty(regions) && (
              <option value="" disabled key="selectCountry">
                First select a country
              </option>
            )}
            {map(regions, ({ name, shortCode }) => (
              <option
                id={`{state_${name}}`}
                key={shortCode}
                value={shortCode}
              >
                {name}
              </option>
            ))}
          </Select>
          {errors.state && <InputError message={errors.state.message} />}
        </label>
      </div>
      {showStateNotAllowed && (
        <EnhancedFlash
          variant="warning"
          heading={`We are currently not able to onboard users from ${_get(
            find(states, (s) => eq(s.uspsId, selectedRegion)),
            'name'
          )}.`}
          subheading={`Please check back at a later time to see if ${_get(
            find(states, (s) => eq(s.uspsId, selectedRegion)),
            'name'
          )} is eligible.`}
        />
      )}
    </div>
  )
};

export default CountryAndRegionSelect;
