import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { API_PATHS } from "../constants";
import {
  includes,
  sortBy,
  remove,
  find,
  eq,
  forEach,
  isUndefined,
} from 'lodash'
import {isArrayNullOrEmpty} from "../utils/utils";

const request = new Request()
const marketdataRequest = new Request(false, true);

export const fetchAllMarkets = createAsyncThunk(
  'markets/fetchAllMarkets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.MARKETS_INFO)
      return response.data.pairs
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const getLionHistory = createAsyncThunk(
  'markets/getLionHistory',
  async (data, { rejectWithValue }) => {
    try {
      const response = await marketdataRequest.get(API_PATHS.COIN.LION_HISTORY(data));
      let responseData = response.data;
      responseData.startDate = new Date(data.startDate).getTime();
      responseData.endDate = new Date(data.endDate).getTime();
      responseData.data.forEach((d) => {
        if(responseData.minimum == null || d.close < responseData.minimum) {
          responseData.minimum = d.close;
        }

        if(responseData.maximum == null || d.close > responseData.maximum) {
          responseData.maximum = d.close;
        }

        d.timecode = new Date(d.end).getTime();
      });

      responseData.change = !isArrayNullOrEmpty(responseData.data) ? ((responseData.data[responseData.data.length - 1].close - responseData.data[0].close) / responseData.data[0].close) * 100 : 0;
      return responseData;
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

const marketsSlice = createSlice({
  name: 'markets',
  initialState: {
    allMarkets: {},
    tickerData: [],
    baseAssets: [],
    quoteAssets: [],
    orderBooks: {},
    lionHistorySelectedTimePeriod: 'M',
    trades: {},
  },
  reducers: {
    setLionHistorySelectedTimePeriod: (state, action) => {
      state.lionHistorySelectedTimePeriod = action.payload;
    },
    setTickerData: (state, action) => {
      state.tickerData = action.payload
    },
    setOrderBook: (state, action) => {
      const { askTotalAmount, bidTotalAmount, instrument, snapshot, version } =
        action.payload
      let asks
      let bids

      if (isUndefined(state.orderBooks[action.payload.instrument])) {
        asks = action.payload.asks
        bids = action.payload.bids
      } else {
        asks = state.orderBooks[action.payload.instrument].asks
        bids = state.orderBooks[action.payload.instrument].bids

        forEach(action.payload.asks, (newAsk) => {
          const currentAsk = find(asks, ({ price }) => eq(price, newAsk.price))
          if (isUndefined(currentAsk) || !eq(newAsk.amount, 0)) {
            asks.push(newAsk)
          } else if (eq(newAsk.amount, 0)) {
            remove(asks, ({ price }) => eq(price, currentAsk.price))
          } else {
            currentAsk.amount = newAsk.amount
          }
        })

        forEach(action.payload.bids, (newBid) => {
          const currentBid = find(bids, ({ price }) => eq(price, newBid.price))
          if (isUndefined(currentBid) || !eq(newBid.amount, 0)) {
            bids.push(newBid)
          } else if (eq(newBid.amount, 0)) {
            remove(bids, ({ price }) => eq(price, currentBid.price))
          } else {
            currentBid.amount = newBid.amount
          }
        })
      }

      state.orderBooks[action.payload.instrument] = {
        askTotalAmount,
        bidTotalAmount,
        instrument,
        snapshot,
        version,
        asks: sortBy(asks, 'price'),
        bids: sortBy(bids, 'price'),
      }
    },
    setTradesByMarket: (state, action) => {
      state.trades[action.payload.market] = action.payload.trades
    },
  },
  extraReducers: {
    [fetchAllMarkets.fulfilled]: (state, action) => {
      state.allMarkets.pairs = action.payload;
      const availableMarkets = [];
      Object.keys(action.payload).forEach((key) => {
        const { baseAsset, hidden, quoteAsset } = action.payload[key];
        if(hidden !== 1) {
          if (!includes(availableMarkets, baseAsset)) availableMarkets.push(baseAsset);
          if (!includes(availableMarkets, quoteAsset)) availableMarkets.push(quoteAsset);
        }
      });
      state.availableMarketAssetIds = availableMarkets;
    },
    [getLionHistory.fulfilled]: (state, action) => {
      state.lionHistory = action.payload;
    },
  },
})

export const { setLionHistorySelectedTimePeriod, setTickerData, setOrderBook, setTradesByMarket } =
  marketsSlice.actions
export default marketsSlice.reducer
