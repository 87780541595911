import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, useFormState } from 'react-hook-form'
import Icon from '@pv3-shared-components/Icons'
import Tos from '@pv3-components/OnboardingTerms/tos'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import { APP_PATHS } from '@pv3-constants'
import Button from '@pv3-shared-components/Button'
import useDocTitle from '@pv3-hooks/useDocTitle'
import InputError from '@pv3-shared-components/InputError'

const OnboardingTerms = () => {
  useDocTitle('User Agreement')

  const { setProfileAcceptTerms } = useProfileDuck()
  const history = useHistory()
  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors },
  } = useForm()
  const { isSubmitting } = useFormState({ control })
  const termsAgreed = watch('userAgrees')

  const handleFormSubmit = async () => {
    try {
      await setProfileAcceptTerms()

      history.push(APP_PATHS.BASE)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <form
      className="SinglePanel-panel SinglePanel-panel--terms"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <h1 className="SinglePanel-logo">
        <Icon name="coinlionlogo" />
      </h1>
      <h2 className="SinglePanel-title">User Agreement</h2>
      <div className="SinglePanel-textScroll">
        <Tos />
      </div>
      <label className="SinglePanel-checkbox">
        <input
          type="checkbox"
          {...register('userAgrees', {
            required: 'Agreeing to the user agreement is required',
          })}
        />
        By continuing, I agree to the user agreement
      </label>
      {errors.userAgrees && <InputError message={errors.userAgrees.message} />}
      <Button
        variant="primary"
        type="submit"
        disabled={!termsAgreed}
        isLoading={isSubmitting}
        size="large"
      >
        Continue
      </Button>
    </form>
  )
}

export default OnboardingTerms
