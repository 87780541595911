import './CurrencyValue.scss';

import React from 'react';
import PropTypes from 'prop-types';

export default class CurrencyValue extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        format: PropTypes.oneOf([
            'usd'
        ]),
        toFixed: PropTypes.number,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    };

    render() {
        const { className, format, toFixed } = this.props;
        let { value } = this.props;

        if(value == null) {
            value = 0;
        }

        if(!format || format.toLowerCase() !== 'usd') {
            let numDecimals = toFixed != null ? toFixed : 8;
            let valueString = value.toLocaleString(undefined, {maximumFractionDigits:numDecimals, minimumFractionDigits:numDecimals});
            const isNegative = valueString.indexOf('-') === 0;
            if(isNegative) {
              valueString = valueString.substring(1);
            }
            let regex = /([0-9,]+\.\d{0}\d*?[1-9]?)(0+)(?:\D|$)/g
            let trailingZeros = regex.exec(valueString);

            return (
                trailingZeros !== undefined && trailingZeros !== null
                    ?   (<span className={`c-currency-value ${className || ''}`}>
                            {isNegative ? '-' : ''}{trailingZeros[1]}
                            <span className="c-currency-value__trailing-zeros">
                                {trailingZeros[2]}
                            </span>
                        </span>)
                    :   <span className={`c-currency-value ${className || ''}`}>{isNegative ? '-' : ''}{valueString}</span>
            );
        } else {
            return (
                <span className={`c-currency-value a-currency-value--format-${format} ${className || ''}`}>
                {Number(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
            </span>
            );
        }
    }
}
