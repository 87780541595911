import './referralsOverview.scss';

import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard'

import useDocTitle from '../../../../../hooks/useDocTitle';
import useProfileDuck from "../../../../../hooks/useProfileDuck";
import { useTypedSelector } from "../../../../../ducks";

import Button, { ButtonVariants } from "../../../../shared/Button";
import Icon from '../../../../shared/Icons';
import Tooltip from '../../../../shared/Tooltip';
import { useState } from "react";
import Flash from "../../../../shared/Flash";
import EnhancedFlash, { EnhancedFlashVariants } from "../../../../shared/EnhancedFlash";
import { KycVerificationStatuses } from "../../../../../utils/enums";

const ReferralsOverview: React.FC = () => {
  useDocTitle('Referrals Overview');

  const {profile: { kycVerificationStatus, referralCode }} = useProfileDuck();
  const {referralCount, referralTotalEarned, referralTotalPotential} = useTypedSelector((state) => state.rewards);

  const [showUrlCopied, setShowUrlCopied] = useState<boolean>(false);
  const [showCodeCopied, setShowCodeCopied] = useState<boolean>(false);

  const referralLink = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/referral/${referralCode}`;

  return (
    <div className="Referrals-Content Referrals-Content--overview">
      <section className="ReferralsData">
        <div className="ReferralsData-Left">
          <div className="ReferralsData-Item">
            <div className="ReferralsData-ItemValue">
              {referralTotalEarned.toLocaleString()} LION
            </div>
            <div className="ReferralsData-ItemLabel">
              Your Earnings

              <Tooltip>
                Future Tooltip
              </Tooltip>
            </div>
          </div>

          <div className="ReferralsData-Item">
            <div className="ReferralsData-ItemValue">
              {referralTotalPotential.toLocaleString()} LION
            </div>
            <div className="ReferralsData-ItemLabel">
              Your Earning Potential

              <Tooltip>
                Future Tooltip
              </Tooltip>
            </div>
          </div>

          <div className="ReferralsData-Item">
            <div className="ReferralsData-ItemValue">
              {referralCount.toLocaleString()}
            </div>
            <div className="ReferralsData-ItemLabel">
              Friend{referralCount !== 1 && 's'} Trading

              <Tooltip>
                Future Tooltip
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="ReferralsData-Right">
          {kycVerificationStatus === KycVerificationStatuses.Verified && (
            <>
              <div className="ReferralsData-RightItem">
                <div className="ReferralsData-Label">
                  Referral Link
                </div>
                <div className="ReferralsData-Textarea">
                  {referralLink}
                </div>

                <CopyToClipboard
                  text={referralLink}
                  options={{format: 'text/plain'}}
                  onCopy={() => {
                    setShowUrlCopied(true)
                    setTimeout(() => setShowUrlCopied(false), 2000)
                  }}
                >
                  <Button variant={ButtonVariants.Quinary} className="WireTransfer-copyButton">
                    <Icon name="copy" width="24"/>
                    Copy to clipboard
                  </Button>
                </CopyToClipboard>

                <div className="DepositCrypto-copiedToClipboardAlertContainer">
                  <div
                    className={`DepositCrypto-copiedToClipboardAlert ${
                      showUrlCopied ? 'visible' : ''
                    }`}
                  >
                    <Flash
                      className="Flash--success"
                      hideCloseButton
                      iconSize={20}
                      iconFill="#00854d"
                    >
                      Successfully copied
                    </Flash>
                  </div>
                </div>
              </div>

              <div className="ReferralsData-RightItem">
                <div className="ReferralsData-Label">
                  Referral Code
                </div>

                <div className="ReferralsData-Textarea">
                  {referralCode}
                </div>

                <CopyToClipboard
                  text={referralCode}
                  options={{format: 'text/plain'}}
                  onCopy={() => {
                    setShowCodeCopied(true)
                    setTimeout(() => setShowCodeCopied(false), 2000)
                  }}
                >
                  <Button variant={ButtonVariants.Quinary} className="WireTransfer-copyButton">
                    <Icon name="copy" width="24"/>
                    Copy to clipboard
                  </Button>
                </CopyToClipboard>

                <div className="DepositCrypto-copiedToClipboardAlertContainer">
                  <div
                    className={`DepositCrypto-copiedToClipboardAlert ${
                      showCodeCopied ? 'visible' : ''
                    }`}
                  >
                    <Flash
                      className="Flash--success"
                      hideCloseButton
                      iconSize={20}
                      iconFill="#00854d"
                    >
                      Successfully copied
                    </Flash>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <EnhancedFlash className="ReferralsData-Rules" heading="Rules" variant={EnhancedFlashVariants.Warning}>
        <p>
          There are two types of users that you can get rewards for: Sophisticated Traders called Publishers as well as the Average Trader who wants to follow the Publishers trades. The average trader has 4 reward milestones and a Publisher has an additional 4 milestones. Both you and the person you refer receive the same reward.  Meaning, if an Average Trader creates a new account they get 50 LION and you get 50 LION.
        </p>
        <p>
          LION Rewards will be clawed back if users are seen as manipulating the system. As an example: If a user deposits $500 and then withdraws the $500 within 10 days and without making any trades then their LION will be clawed back when they make their withdrawal. If a user Subscribes to a Publisher and then unsubscribes without any trades being made then their LION rewards will be clawed back.
        </p>
      </EnhancedFlash>
    </div>
  );
};

export default ReferralsOverview;
