import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import HodlAllocation from "../types/HodlAllocation";
import HodlEnrollment from "../types/HodlEnrollment";
import HodlProgram from "../types/HodlProgram";
import { Referral } from "../types/Referral";
import { ReferralRewardAction } from "../types/ReferralRewardAction";
import { RootState } from "./index";

import { API_PATHS } from '../constants';
import TokenRequest from '../utils/tokenRequest';
import { setShowCtaCard } from './ui';
import LiquidatorProgram from "../types/LiquidatorProgram";
import LiquidatorEnrollment from "../types/LiquidatorEnrollment";
import LiquidatorEvent from "../types/LiquidatorEvent";
import AggregatorEnrollment from "../types/AggregatorEnrollment";
import AggregatorEvent from "../types/AggregatorEvent";
import AggregatorProgram from "../types/AggregatorProgram";

type ClaimHodlEnrollmentProps = {
  enrollmentId: string
}

export const claimHodlEnrollment = createAsyncThunk(
  'rewards/claimHodlEnrollment',
  async ({enrollmentId}: ClaimHodlEnrollmentProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).put(API_PATHS.COIN.HODL_ENROLLMENT_CLAIM(enrollmentId));

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type DeleteHodlEnrollmentProps = {
  enrollmentId: string
}

export const deleteHodlEnrollment = createAsyncThunk(
  'rewards/deleteHodlEnrollment',
  async ({enrollmentId}: DeleteHodlEnrollmentProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).delete(API_PATHS.COIN.HODL_ENROLLMENT_DELETE(enrollmentId));

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type EnrollInAggregatorProps = {
  buyMaximum: number
  isActive: boolean
  programId: string
  userFunds: number
}

export const enrollInAggregator = createAsyncThunk(
  'rewards/enrollInAggregator',
  async (enrollData: EnrollInAggregatorProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).post(API_PATHS.COIN.AGGREGATOR_ENROLL, enrollData);
      console.log('here in enrollInAggregator Action', res);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type EnrollInHodlProps = {
  lionQty: number
  programId: string
}

export const enrollInHodl = createAsyncThunk(
  'rewards/enrollInHodl',
  async (enrollData: EnrollInHodlProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).post(API_PATHS.COIN.HODL_ENROLL, enrollData);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type EnrollInLiquidatorProps = {
  isActive: boolean
  lionQty: number
  programId: string
  sellMinimum: number
}

export const enrollInLiquidator = createAsyncThunk(
  'rewards/enrollInLiquidator',
  async (enrollData: EnrollInLiquidatorProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).post(API_PATHS.COIN.LIQUIDATOR_ENROLL, enrollData);
      console.log('here in enrollInLiquidator Action', res);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAggregatorEnrollments = createAsyncThunk(
  'rewards/getAggregatorEnrollments',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.AGGREGATOR_ENROLLMENTS);

      return res.data || null;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAggregatorEvents = createAsyncThunk(
  'rewards/getAggregatorEvents',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.AGGREGATOR_EVENTS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAggregatorPrograms = createAsyncThunk(
  'rewards/getAggregatorPrograms',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.AGGREGATOR_PROGRAMS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getHodlAnnualAllocations = createAsyncThunk(
  'rewards/getHodlAnnualAllocations',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.HODL_ANNUAL_ALLOCATIONS);

      const annualAllocationsForHodl = res.data.filter((allocation: HodlAllocation) => allocation.code.includes('HODL'));
      const annualAllocationsForPublishers = res.data.filter((allocation: HodlAllocation) => allocation.code.includes('PUB'));

      return {annualAllocationsForHodl, annualAllocationsForPublishers};
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getHodlGlobalTotals = createAsyncThunk(
  'rewards/getHodlGlobalTotals',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.HODL_GLOBAL_TOTALS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getHodlPrograms = createAsyncThunk(
  'rewards/getHodlPrograms',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.HODL_PROGRAMS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getHodlUserTotals = createAsyncThunk(
  'rewards/getHodlUserTotals',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.HODL_USER_TOTALS);

      const enrollments = res.data.allHodlEnrollments.map((en) => ({
        ...en.enrollment,
        totalRewardsLion: en.totalRewardsLion,
      }));

      return {
        ...res.data,
        enrollments,
      };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getLiquidatorEnrollments = createAsyncThunk(
  'rewards/getLiquidatorEnrollments',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.LIQUIDATOR_ENROLLMENTS);

      return res.data?.[0];
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getLiquidatorEvents = createAsyncThunk(
  'rewards/getLiquidatorEvents',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.LIQUIDATOR_EVENTS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getLiquidatorPrograms = createAsyncThunk(
  'rewards/getLiquidatorPrograms',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.LIQUIDATOR_PROGRAMS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getReferralRewards = createAsyncThunk(
  'rewards/getReferralRewards',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.REFERRAL_REWARDS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getReferralRewardActions = createAsyncThunk(
  'rewards/getReferralRewardActions',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.REFERRAL_REWARD_ACTIONS);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getReferredBy = createAsyncThunk(
  'rewards/getReferredBy',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.REFERRED_BY);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getTradingFeeDiscountsInfo = createAsyncThunk(
  'rewards/getTradingFeeDiscountsInfo',
  async (_, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.TRADING_DISCOUNTS_INFO);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type SaveReferralCodeProps = {
  referralCode: string
}

export const saveReferralCode = createAsyncThunk(
  'rewards/saveReferralCode',
  async ({referralCode}: SaveReferralCodeProps, {dispatch, getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).post(API_PATHS.COIN.SAVE_REFERRAL_CODE, {referralCode});

      dispatch(setShowCtaCard({ card: 'REFERRAL_CODE', show: false }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    } finally {
      localStorage.removeItem('referralCode');
    }
  }
);

type UpdateAggregatorEnrollmentProps = {
  buyMaximum: number
  enrollmentId: string
  isActive: true
  programId: string
  userFunds: number
}

export const updateAggregatorEnrollment = createAsyncThunk(
  'rewards/updateAggregatorEnrollment',
  async (enrollmentData: UpdateAggregatorEnrollmentProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).put(API_PATHS.COIN.AGGREGATOR_UPDATE(enrollmentData.enrollmentId), enrollmentData);
      console.log('update res', res);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type UpdateLiquidatorEnrollmentProps = {
  enrollmentId: string
  isActive: boolean
  lionQty: number
  programId: string
  sellMinimum: number
}

export const updateLiquidatorEnrollment = createAsyncThunk(
  'rewards/upgradeLiquidatorEnrollment',
  async (enrollmentData: UpdateLiquidatorEnrollmentProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).put(API_PATHS.COIN.LIQUIDATOR_UPDATE(enrollmentData.enrollmentId), enrollmentData);
      console.log('update res', res);

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type UpgradeHodlEnrollmentProps = {
  enrollmentId: string
  newProgramId: string
}

export const upgradeHodlEnrollment = createAsyncThunk(
  'rewards/upgradeHodlEnrollment',
  async ({enrollmentId, newProgramId}: UpgradeHodlEnrollmentProps, {getState, rejectWithValue}) => {
    try {
      let res = await new TokenRequest((getState() as RootState).account.idToken).put(API_PATHS.COIN.HODL_ENROLLMENT_UPGRADE(enrollmentId, newProgramId));

      return res.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);


interface RewardsState {
  aggregatorEnrollment?: AggregatorEnrollment
  aggregatorEvents: Array<AggregatorEvent>
  aggregatorPrograms: Array<AggregatorProgram>
  annualAllocationsForHodl: Array<HodlAllocation>,
  annualAllocationsForPublishers: Array<HodlAllocation>,
  discountsInfo: {
    has60PercentHodl?: boolean
    has80PercentHodl?: boolean
    standardFeePercent?: number
    tier1FeePercent?: number
    tier2FeePercent?: number
    tier3FeePercent?: number
    tokenBalancerEnabled?: boolean
    userFeePercent?: number
  }
  enrollments: Array<HodlEnrollment>
  globalTotals: {
    hodlTotalAllUsers?: number
    hodlTotalCoinLionUsers?: number
    lionAllocatedForCurrentYear?: number
  }
  liquidatorEnrollment?: LiquidatorEnrollment
  liquidatorEvents: Array<LiquidatorEvent>
  liquidatorPrograms: Array<LiquidatorProgram>
  programs: Array<HodlProgram>
  referralCount: number
  referralRewardActions: Array<ReferralRewardAction>
  referrals: Array<Referral>
  referralTotalEarned: number
  referralTotalPotential: number
  referredBy?: {
    referredByName?: string
    referredByCode?: string
  }
  userTotals: {
    totalLionBalance?: number
    lionHodlLocked?: number
    lionInOpenOrders?: number
    lionSoldLast12Months?: number
  }
}

const initialState: RewardsState = {
  aggregatorEnrollment: undefined,
  aggregatorEvents: [],
  aggregatorPrograms: [],
  annualAllocationsForHodl: [],
  annualAllocationsForPublishers: [],
  discountsInfo: {},
  enrollments: [],
  globalTotals: {},
  liquidatorEnrollment: undefined,
  liquidatorEvents: [],
  liquidatorPrograms: [],
  programs: [],
  referralCount: 0,
  referralRewardActions: [],
  referrals: [],
  referralTotalEarned: 0,
  referralTotalPotential: 0,
  referredBy: {},
  userTotals: {},
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: ({addCase}) => {
    addCase(getAggregatorEnrollments.fulfilled, (state, action) => {
      state.aggregatorEnrollment = action.payload;
    });
    addCase(getAggregatorEvents.fulfilled, (state, action) => {
      state.aggregatorEvents = action.payload;
    });
    addCase(getAggregatorPrograms.fulfilled, (state, action) => {
      state.aggregatorPrograms = action.payload;
    });
    addCase(getHodlAnnualAllocations.fulfilled, (state, action) => {
      state.annualAllocationsForHodl = action.payload.annualAllocationsForHodl;
      state.annualAllocationsForPublishers = action.payload.annualAllocationsForPublishers;
    });
    addCase(getHodlGlobalTotals.fulfilled, (state, action) => {
      state.globalTotals = action.payload;
    });
    addCase(getHodlPrograms.fulfilled, (state, action) => {
      state.programs = action.payload;
    });
    addCase(getHodlUserTotals.fulfilled, (state, action) => {
      state.enrollments = action.payload.enrollments;
      state.userTotals = {
        totalLionBalance: action.payload.totalLionBalance,
        lionHodlLocked: action.payload.lionHodlLocked,
        lionInOpenOrders: action.payload.lionInOpenOrders,
        lionSoldLast12Months: action.payload.lionSoldLast12Months,
      };
    });
    addCase(getLiquidatorEnrollments.fulfilled, (state, action) => {
      state.liquidatorEnrollment = action.payload;
    });
    addCase(getLiquidatorEvents.fulfilled, (state, action) => {
      state.liquidatorEvents = action.payload;
    });
    addCase(getLiquidatorPrograms.fulfilled, (state, action) => {
      state.liquidatorPrograms = action.payload;
    });
    addCase(getReferralRewards.fulfilled, (state, action) => {
      state.referralCount = action.payload.referralCount;
      state.referrals = action.payload.referrals;
      state.referralTotalEarned = action.payload.totalEarned;
      state.referralTotalPotential = action.payload.totalPotential;
    });
    addCase(getReferralRewardActions.fulfilled, (state, action) => {
      state.referralRewardActions = action.payload;
    });
    addCase(getReferredBy.fulfilled, (state, action) => {
      state.referredBy = action.payload;
    });
    addCase(getTradingFeeDiscountsInfo.fulfilled, (state, action) => {
      state.discountsInfo = action.payload;
    });
  },
});

export default rewardsSlice.reducer;
