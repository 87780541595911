import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@pv3-shared-components/Button'
import './wiretransfer.scss'
import Icon from '@pv3-shared-components/Icons'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import useUiDuck from '@pv3-hooks/useUiDuck'
import Flash from '../shared/Flash'

const WireTransfer = () => {
  const { profile } = useProfileDuck()
  const { pushFlash } = useUiDuck()
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false)

  const copyPasta = `Recipient Name: CoinLion, LLC

Recipient Address:
Suite 101A
4305 S Louise Ave
Sioux Falls, SD 57106

Routing Number: 091408446

Account Number: 6100258550

Bank Name: First Bank and Trust

Bank Address:
2220 6th Street
Brookings, SD 57006

Memo:
${profile.email}
`

  useEffect(() => {
    pushFlash({ id: 'wire-transfer-email' })
  }, [pushFlash])

  return (
    <div className="AddFunds-body">
      <h3 className="AddFunds-header">Wire transfer</h3>
      <p>
        Provide the following information to your bank to initiate a wire
        transfer
      </p>

      <CopyToClipboard
        text={copyPasta}
        options={{ format: 'text/plain' }}
        onCopy={() => {
          setShowCopiedToClipboard(true)
          setTimeout(() => setShowCopiedToClipboard(false), 2000)
        }}
      >
        <div className="WireTransfer-copyBox">
          <Button variant="quinary" className="WireTransfer-copyButton">
            <Icon name="copy" width="24" />
            Copy to clipboard
          </Button>

          <div className="DepositCrypto-copiedToClipboardAlertContainer">
            <div
              className={`WireTransfer-copiedToClipboardAlert ${
                showCopiedToClipboard ? 'visible' : ''
              }`}
            >
              <Flash
                className="Flash--success"
                hideCloseButton
                iconSize={20}
                iconFill="#00854d"
              >
                Bank info copied
              </Flash>
            </div>
          </div>

          <div className="WireTransfer-infoItem">
            <p className="label">Recipient Name</p>
            <p className="value">CoinLion, LLC</p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Recipient Address</p>
            <p className="value">
              Suite 101A
              <br />
              4305 S Louise Ave
              <br />
              Sioux Falls, SD 57106{' '}
            </p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Routing Number</p>
            <p className="value">091408446</p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Account Number</p>
            <p className="value">6100258550</p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Bank Name</p>
            <p className="value">First Bank and Trust</p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Bank Address</p>
            <p className="value">
              2220 6th Street
              <br />
              Brookings, SD 57006
            </p>
          </div>
          <div className="WireTransfer-infoItem">
            <p className="label">Memo</p>
            <p className="value">{profile.email}</p>
          </div>
          <EnhancedFlash
            id="wire-transfer-email"
            variant="warning"
            heading="Email address must be included in wire transfer memo line"
          />
        </div>
      </CopyToClipboard>
    </div>
  )
}

export default WireTransfer
