import React from 'react';
import TradeHistory from "../TradeHistory";
import './strategyTradeHistory.scss';

const StrategyTradeHistory = () => {
  return (
    <div className="StrategyTradeHistory Strategy-content u-container">
      <TradeHistory />
    </div>
  )
};

export default StrategyTradeHistory;
