import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { API_PATHS } from '@pv3-constants'

const request = new Request()

export const getTaxDocuments = createAsyncThunk(
  'reports/getTaxDocuments',
  async (_) => {
    try {
      const response = await request.get(API_PATHS.COIN.TAX_DOCUMENTS)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const getTaxDocumentTypes = createAsyncThunk(
  'reports/getTaxDocumentTypes',
  async (_) => {
    try {
      const response = await request.get(API_PATHS.COIN.TAX_DOCUMENT_TYPES)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const requestTaxDocument = createAsyncThunk(
  'reports/requestTaxDocument',
  async ({ type, year }, { rejectWithValue }) => {
    try {
      await request.post(API_PATHS.COIN.REQUEST_TAX_DOCUMENT, { type, year })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getTaxDocumentUrl = createAsyncThunk(
  'reports/getTaxDocumentTypes',
  async (s3ObjectKey) => {
    try {
      const response = await request.get(
        API_PATHS.COIN.TAX_DOCUMENT_URL(s3ObjectKey)
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    taxDocuments: null,
    taxDocumentTypes: {},
  },
  reducers: {},
  extraReducers: {
    [getTaxDocuments.fulfilled]: (state, action) => {
      state.taxDocuments = action.payload
    },
    [getTaxDocumentTypes.fulfilled]: (state, action) => {
      state.taxDocumentTypes = action.payload
    },
  },
})

export default reportsSlice.reducer
