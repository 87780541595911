import React from 'react'

const DocumentSmall = ({
  fill = '#500078',
  width = '24',
  height = '24',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.594 2.667H5.22A1.253 1.253 0 0 0 4 3.94v16.12a1.253 1.253 0 0 0 1.22 1.273h13.56A1.253 1.253 0 0 0 20 20.06V7.947l-5.406-5.28Zm-.2 1.66 4 3.933h-4V4.327ZM5.333 4v16h13.333V9.333h-5.334V4h-8Z"
        fill={fill}
      />
    </svg>
  )
}

export default DocumentSmall
