import React from 'react'

const Minus = ({ fill = '#500078', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.1111 15.1113H8.88889C8.39797 15.1113 8 15.5093 8 16.0002C8 16.4911 8.39797 16.8891 8.88889 16.8891H23.1111C23.602 16.8891 24 16.4911 24 16.0002C24 15.5093 23.602 15.1113 23.1111 15.1113Z"
        fill={fill}
      />
    </svg>
  )
}

export default Minus
