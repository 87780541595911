import React from 'react'
import PortfolioSummary from '@pv3-components/PortfolioSummary'
import PortfolioDetails from '@pv3-components/PortfolioDetails'
import useDocTitle from '@pv3-hooks/useDocTitle'

const Portfolio = () => {
  useDocTitle('Dashboard')

  return (
    <React.Fragment>
      <PortfolioSummary />
      <PortfolioDetails />
    </React.Fragment>
  )
}

export default Portfolio
