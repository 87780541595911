import './enhancedFlash.scss';

import * as React from 'react';
import { find, isUndefined } from 'lodash';

import useUiDuck from '../../../hooks/useUiDuck';

import Icon from '../Icons';
import StopLoss from "../Icons/StopLoss";

const iconMap = {
  warning: <Icon name="exclamation triangle" className="EnhancedFlash-icon" />,
  warningAlt: (
    <Icon
      name="exclamation triangle"
      className="EnhancedFlash-icon"
      fill="#500078"
    />
  ),
  info: <Icon name="infooutline" className="EnhancedFlash-icon" />,
  danger: (
    <Icon
      name="exclamation circle"
      width={20}
      height={20}
      className="EnhancedFlash-icon"
    />
  ),
  stopLoss: <StopLoss />
}

export enum EnhancedFlashVariants {
  Danger = 'danger',
  Info = 'info',
  StopLoss = 'stopLoss',
  Warning = 'warning',
  WarningAlt = 'warningAlt',
}

type Props = {
  children?: React.ReactNode
  className?: string
  cta?: React.ReactNode
  id?: string
  heading: string
  subheading?: string | React.ReactNode
  variant?: EnhancedFlashVariants
}

const EnhancedFlash: React.FC<Props> = ({
  children,
  className = '',
  cta,
  id,
  variant = EnhancedFlashVariants.Info,
  heading,
  subheading,
}) => {
  const { flashes } = useUiDuck();

  if (!isUndefined(id) && !find(flashes, { id: id })) {
    return <React.Fragment />;
  }

  return (
    <div
      className={`EnhancedFlash ${
        variant ? `EnhancedFlash--${variant}` : null
      } ${className}`}
    >
      {iconMap[variant]}
      <div className="EnhancedFlash-headings">
        <div className="EnhancedFlash-heading">{heading}</div>
        {!isUndefined(subheading) && (
          <div className="EnhancedFlash-subheading">{subheading}</div>
        )}
      </div>
      {!isUndefined(cta) && <div className="EnhancedFlash-cta">{cta}</div>}
      {!isUndefined(children) && <div className="EnhancedFlash-children">{children}</div>}
    </div>
  )
}

export default EnhancedFlash;
