import React from 'react'

const QR = ({ fill = '#500078', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3334 3.55566H27.0223C27.8078 3.55566 28.4446 4.19241 28.4446 4.97789V10.6668H21.3334V3.55566ZM23.1112 8.889H26.6668V5.33344H23.1112V8.889ZM17.7779 8.889V7.11122H19.5557V3.55566H12.4446V7.11122H14.2223V10.6668H16.0001V12.4446H12.4446V10.6668H10.6668V3.55566H4.97789C4.19241 3.55566 3.55566 4.19241 3.55566 4.97789V10.6668H10.6668V12.4446H12.4446V14.2223H10.6668V16.0001H8.889V12.4446H7.11122V16.0001H5.33344V12.4446H3.55566V16.0001H5.33344V17.7779H3.55566V19.5557H7.11122V17.7779H8.889V19.5557H10.6668V17.7779H12.4446V24.889H14.2223V23.1112H16.0001V21.3334H14.2223V17.7779H16.0001V19.5557H17.7779V24.889H16.0001V26.6668H12.4446V28.4446H19.5557V26.6668H17.7779V24.889H19.5557V19.5557H24.889V17.7779H26.6668V19.5557H28.4446V17.7779H26.6668V16.0001H28.4446V14.2223H26.6668V12.4446H24.889V14.2223H23.1112V12.4446H19.5557V8.889H17.7779ZM19.5557 16.0001H21.3334V14.2223H23.1112V16.0001H24.889V17.7779L19.5557 17.7779V16.0001ZM17.7779 14.2223V16.0001H19.5557V14.2223H17.7779ZM16.0001 14.2223H12.4446V17.7779H14.2223V16.0001H16.0001V14.2223ZM16.0001 14.2223H17.7779V12.4446H16.0001V14.2223ZM19.5557 17.7779V19.5557H17.7779V17.7779H19.5557ZM17.7779 8.889L17.7779 10.6668H16.0001V8.889H17.7779ZM14.2223 7.11122H17.7779L17.7779 5.33344H14.2223V7.11122ZM5.33344 8.889H8.889V5.33344H5.33344V8.889ZM3.55566 27.0223C3.55566 27.8078 4.19241 28.4446 4.97789 28.4446H10.6668V21.3334H3.55566V27.0223ZM8.889 26.6668V23.1112H5.33344V26.6668H8.889ZM21.3334 28.4446H27.0223C27.8078 28.4446 28.4446 27.8078 28.4446 27.0223V21.3334H21.3334V28.4446ZM26.6668 23.1112H23.1112V26.6668H26.6668V23.1112Z"
        fill={fill}
      />
    </svg>
  )
}

export default QR
