import React from 'react'
import './loading.scss'

const Loading = () => {
  return (
    <svg
      className="Loading"
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect className="Loading-bar" x="36" width="4" height="24" rx="1" />
        <rect className="Loading-bar" x="24" width="4" height="24" rx="1" />
        <rect className="Loading-bar" x="12" width="4" height="24" rx="1" />
        <rect className="Loading-bar" width="4" height="24" rx="1" />
      </g>
    </svg>
  )
}

export default Loading
