import React, { useEffect, useMemo, useState } from 'react'
import useTradesDuck from '../../hooks/useTradesDuck'
import './openpositions.scss'
import SummaryPanel from '@pv3-shared-components/SummaryPanel'
import {
  eq,
  filter,
  get,
  gt,
  isNull,
  isNil,
  isUndefined,
  lt,
  meanBy,
  round,
  sumBy,
  trim,
  some, find, sortBy, isEmpty,
} from 'lodash'
import { differenceInMinutes } from 'date-fns'
import clone from "clone";

import ComponentLoader from '@pv3-components/ComponentLoader'
import { LOADING_STATES } from '../../constants'
import OpenPositionsTable from '../OpenPositionsTable'
import useDocTitle from '@pv3-hooks/useDocTitle'
import Select from '@pv3-shared-components/Select'
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";

import './../shared/Ledger/ledger.scss'
import useStrategiesDuck from "../../hooks/useStrategiesDuck";

const OpenPositions = () => {
  useDocTitle('Open Positions')
  const { openPositions } = useTradesDuck()
  const { indicators, getIndicators } = useStrategiesDuck();

  const [selectedMarketOptionForFilter, setSelectedMarketOptionForFilter] = useState('');
  const [selectedPublisherOptionForFilter, setSelectedPublisherOptionForFilter] = useState('');
  const [selectedStrategyOptionForFilter, setSelectedStrategyOptionForFilter] = useState('');

  useEffect(() => {
    if (isEmpty(indicators)) {
      getIndicators()
    }
  }, [indicators, getIndicators])

  const handleMarketOptionsFilterChange = (event) => {
    const { value } = event.target;
    setSelectedMarketOptionForFilter(value);
  }

  const handlePublisherOptionsFilterChange = (event) => {
    const { value } = event.target;
    setSelectedPublisherOptionForFilter(value);
  }

  const handleStrategyOptionsFilterChange = (event) => {
    const { value } = event.target;
    setSelectedStrategyOptionForFilter(value);
  }

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    []
  )

  const positionsData = useMemo(
    () => {
      if(isArrayNullOrEmpty(openPositions)) {
        return [];
      }

      return openPositions;
    }, [openPositions]
  );

  const positionsToDisplay = useMemo(
    () => {
      if(isArrayNullOrEmpty(openPositions)) {
        return [];
      }

      let positions = clone(openPositions);

      if(selectedMarketOptionForFilter) {
        positions = filter(positions, (op) => op.position && op.position.marketId === selectedMarketOptionForFilter);
      }

      if(selectedPublisherOptionForFilter) {
        positions = filter(positions, (op) => op.position && op.position.strategyBasic && op.position.strategyBasic.publisherUserId === selectedPublisherOptionForFilter);
      }

      if(selectedStrategyOptionForFilter) {
        positions = filter(positions, (op) => op.position && op.position.strategyBasic && op.position.strategyBasic.id === selectedStrategyOptionForFilter);
      }

      return positions;
    }, [openPositions, selectedMarketOptionForFilter, selectedPublisherOptionForFilter, selectedStrategyOptionForFilter]
  )

  const openPositionsCount = useMemo(() => {
    if (isNull(positionsToDisplay)) return 0
    return positionsToDisplay.length
  }, [positionsToDisplay])

  const totalPrice = useMemo(() => {
    if (isNull(positionsToDisplay) || eq(positionsToDisplay.length, 0)) return 0

    return sumBy(
      positionsToDisplay,
      (op) => get(op, 'position.openPrice') * get(op, 'position.openAmount')
    )
  }, [positionsToDisplay])

  const avgAge = useMemo(() => {
    if (isNull(positionsToDisplay) || eq(positionsToDisplay.length, 0)) return 0

    const avgDiff = round(
      meanBy(positionsToDisplay, (op) =>
        differenceInMinutes(new Date(), new Date(get(op, 'position.created')))
      )
    )

    if (lt(avgDiff, 60)) {
      return `${avgDiff}M`
    } else {
      const days = Math.floor(avgDiff / 1440)
      const hours = Math.floor((avgDiff % 1440) / 60)

      return trim(
        `${gt(days, 0) ? days + 'D' : ''} ${gt(hours, 0) ? hours + 'H' : ''}`
      )
    }
  }, [positionsToDisplay])

  const averageGainLoss = useMemo(
    () => round(meanBy(positionsToDisplay, 'gainLossRate') * 100, 2),
    [positionsToDisplay]
  )
  const totalGainLoss = useMemo(
    () => sumBy(positionsToDisplay, 'gainLossAmount'),
    [positionsToDisplay]
  )

  const marketOptionsForFilter = useMemo(
    () => {
      let marketOptions = [];

      positionsData.forEach((op) => {
        if(isObjectNullOrEmpty(op.position)) {
          return;
        }

        if(!find(marketOptions, {marketId: op.position.marketId})) {
          let position = clone(op.position);
          position.label = position.marketId.replace('_', '-').toUpperCase();
          position.value = position.marketId;
          marketOptions.push(position);
        }
      });

      marketOptions = sortBy(marketOptions, 'label');
      marketOptions = [
        {label: 'All markets', value: ''},
        ...marketOptions,
      ];

      return marketOptions;

    }, [positionsData]
  )

  const publisherOptionsForFilter = useMemo(
    () => {
      let publisherOptions = [];

      positionsData.forEach((op) => {
        if(isObjectNullOrEmpty(op.position) || isObjectNullOrEmpty(op.position.strategyBasic)) {
          return;
        }

        if(!find(publisherOptions, {publisherUserId: op.position.strategyBasic.publisherUserId})) {
          let strategy = clone(op.position.strategyBasic);
          strategy.label = strategy.publisherDisplayName || `${strategy.publisherFirstName} ${strategy.publisherLastName}`;
          strategy.value = strategy.publisherUserId;
          publisherOptions.push(strategy);
        }
      });

      publisherOptions = sortBy(publisherOptions, 'label');
      publisherOptions = [
        {label: 'All publishers', value: ''},
        ...publisherOptions,
      ];

      return publisherOptions;

    }, [positionsData]
  )

  const strategyOptionsForFilter = useMemo(
    () => {
      let strategyOptions = [];

      positionsData.forEach((op) => {
        if(isObjectNullOrEmpty(op.position) || isObjectNullOrEmpty(op.position.strategyBasic)) {
          return;
        }

        if(!find(strategyOptions, {id: op.position.strategyBasic.id})) {
          let strategy = clone(op.position.strategyBasic);
          strategy.label = strategy.name;
          strategy.value = strategy.id;
          strategyOptions.push(strategy);
        }
      });

      strategyOptions = sortBy(strategyOptions, 'name');
      strategyOptions = [
        {label: 'Any strategies', value: ''},
        ...strategyOptions,
      ];

      return strategyOptions;
    }, [positionsData]
  )

  return (
    <div className="Content Content--openPositions">
      <ComponentLoader loadingStates={[LOADING_STATES.FETCH_OPEN_POSITIONS]} />
      <h2 className="Activity-headline h4 smallBottomMargin">Open positions</h2>
      <p className="OpenPositions-info">
        When auto-trader purchases a crypto asset, it's considered an open
        position until it sells again.
      </p>
      <SummaryPanel
        figures={[
          {
            variant: ['secondary'],
            value: openPositionsCount,
            label: 'Total # positions',
          },
          {
            variant: ['secondary'],
            value: currencyFormatter.format(totalPrice),
            label: 'Total $ cost',
          },
          {
            variant: ['secondary'],
            value: avgAge,
            label: 'Average age',
          },
          {
            variant: [
              'secondary',
              (() => {
                if (lt(averageGainLoss, 0)) return 'loss'
                if (gt(averageGainLoss, 0)) return 'gain'
                return 'neutral'
              })(),
            ],
            value:
              isNil(averageGainLoss) || isNaN(averageGainLoss)
                ? 0
                : `${averageGainLoss}%`,
            label: 'Average gain/loss',
            loading: useMemo(
              () =>
                some(positionsToDisplay, ({ gainLossRate }) =>
                  isUndefined(gainLossRate)
                ),
              [positionsToDisplay]
            ),
          },
          {
            variant: [
              'secondary',
              (() => {
                if (lt(totalGainLoss, 0)) return 'loss'
                if (gt(totalGainLoss, 0)) return 'gain'
                return 'neutral'
              })(),
            ],
            value: isNil(totalGainLoss)
              ? 0
              : currencyFormatter.format(totalGainLoss),
            label: 'Total unrealized gain/loss',
            loading: useMemo(
              () =>
                some(positionsToDisplay, ({ gainLossAmount }) =>
                  isUndefined(gainLossAmount)
                ),
              [positionsToDisplay]
            ),
          },
        ]}
      >
        <div className="TradeHistory-filters FlexRow--desktop">
          <div className="FilterBar">
            <Select
              ariaLabel="Select market to filter open positions by"
              options={marketOptionsForFilter}
              id="marketOptionsForFilter"
              name="selectedMarketOptionForFilter"
              defaultValue={selectedMarketOptionForFilter}
              onChange={handleMarketOptionsFilterChange}
            />

            <Select
              ariaLabel="Select strategy to filter open positions by"
              options={strategyOptionsForFilter}
              id="strategyOptionsForFilter"
              name="selectedStrategyOptionForFilter"
              defaultValue={selectedStrategyOptionForFilter}
              onChange={handleStrategyOptionsFilterChange}
            />

            <Select
              ariaLabel="Select publisher to filter open positions by"
              options={publisherOptionsForFilter}
              id="publisherOptionsForFilter"
              name="selectedPublisherOptionForFilter"
              defaultValue={selectedPublisherOptionForFilter}
              onChange={handlePublisherOptionsFilterChange}
            />
          </div>
        </div>
      </SummaryPanel>

      <OpenPositionsTable positionsToDisplay={positionsToDisplay} />
    </div>
  )
}

export default OpenPositions
