import {eq, filter, isNull, map} from 'lodash'
import React, { useState } from 'react'
import DepositsWithdrawalsTable from '@pv3-components/DepositsWithdrawalsTable'
import Button from '@pv3-shared-components/Button'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import useDocTitle from '@pv3-hooks/useDocTitle'
import useModal from '@pv3-hooks/useModal'
import AddFundsModal from '@pv3-components/AddFundsModal'
import RemoveFundsModal from '@pv3-components/RemoveFundsModal'

const DepositsWithdrawals = () => {
  useDocTitle('Deposits & Withdrawals')
  const [filterValue, setFilterValue] = useState(null)
  const { transfers } = useWalletsDuck()
  const addFundsModal = useModal()
  const removeFundsModal = useModal()

  const filterOptions = [
    { label: 'All', value: null },
    { label: 'Bank', value: 'BANK' },
    { label: 'Crypto', value: 'CRYPTO' },
  ]

  const selectedClass = (s) => (eq(s, filterValue) ? 'Button--selected' : '')

  const handleDepositClick = () => {
    addFundsModal.setShow(true)
  }

  const handleWithdrawClick = () => {
    removeFundsModal.setShow(true)
  }

  return (
    <div className="Content">
      <div className="DepositsWithdrawals-headline">
        <h2 className="Activity-headline h4">Deposits &amp; Withdrawals</h2>
        <Button onClick={handleDepositClick}>Deposit</Button>
        <Button onClick={handleWithdrawClick}>Withdraw</Button>
      </div>
      <div className="FilterBar">
        {map(filterOptions, (option) => (
          <Button
            key={option.label}
            className={selectedClass(option.value)}
            variant="tertiary"
            size="small"
            onClick={() => setFilterValue(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </div>

      <DepositsWithdrawalsTable
        transfers={filter(transfers, (transfer) => {
          if (isNull(filterValue)) {
            return true
          } else if (eq(filterValue, 'BANK')) {
            return eq(transfer.asset, 'usd')
          } else {
            return !eq(transfer.asset, 'usd')
          }
        })}
      />
      <AddFundsModal modal={addFundsModal} />
      <RemoveFundsModal modal={removeFundsModal} />
    </div>
  )
}

export default DepositsWithdrawals
