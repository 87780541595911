export const PASSWORD_MAX = 64
export const PASSWORD_MIN = 12

export const PROFILE_TYPES = {
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
}

export const PROFILE_TYPES_LOOKUP = [
  PROFILE_TYPES.INDIVIDUAL,
  PROFILE_TYPES.BUSINESS,
];

export const KYC_VERIFICATION_STATUSES = {
  NOT_FOUND: 0,
  INIT: 1,
  PENDING: 2,
  FAILED: 3, // RED
  VERIFIED: 4, // GREEN
};

export const KYC_VERIFICATION_LEVELS = {
  BUSINESS: 'basic-kyb-level',
  FACIAL_VERIFICATION: 'facial-auth',
  INDIVIDUAL: 'basic-kyc-level',
  INDIVIDUAL_INTERNATIONAL: 'basic-kyc-level-nonUS',
  PROOF_OF_ADDRESS: 'poa',
  PROOF_OF_ADDRESS_BUSINESS: 'KYB-POA',
};

export const FACE_AUTH_RESULTS_TITLE = {
  ERROR: 'Unsuccessful',
  GREEN: 'Success!',
  RED: 'There was a problem',
  YELLOW: 'Verification is pending',
};

export const FACE_AUTH_RESULTS_CONTENT = {
  ERROR: 'The face scan does not match the records we have on file, if you believe this is an error please contact support.',
  GREEN: 'You have been successfully verified and can now continue.',
  RED: 'We were either unable to get a clear scan of your face, or your scan didn\'t match the scan on file. Please try again.',
  YELLOW: 'You can continue the process while we continue verification.',
};

export const TRIGGER_SETTINGS_ACCESS_STRINGS = [
  'buyIndicatorTriggerSettings.triggerIndicatorSettings',
  'sellIndicatorTriggerSettings.triggerIndicatorSettings',
  'stopLossIndicatorTriggerSettings.triggerIndicatorSettings',
  'buyIndicatorManagementSettings.enableIndicatorSettings',
  'buyIndicatorManagementSettings.disableIndicatorSettings',
  'sellIndicatorManagementSettings.enableIndicatorSettings',
  'sellIndicatorManagementSettings.disableIndicatorSettings',
];

export const APP_PATHS = {
  BASE: '/',
  SIGN_IN: '/sign-in',
  SIGN_IN_2FA: '/sign-in/2fa',
  CREATE_ACCOUNT: '/create-account',
  CREATE_ACCOUNT_SIGNUP: '/create-account/signup',
  CREATE_ACCOUNT_VERIFY_EMAIL: '/create-account/verify-email',
  STRATEGIES_NEW: '/strategies/new/details',
  STRATEGIES_NEW_BACKTEST: '/strategies/new/backtest',
  STRATEGY: `/strategies/:strategyId`,
  STRATEGY_DETAILS: `/strategies/:strategyId/details`,
  STRATEGY_BACKTEST: `/strategies/:strategyId/backtest`,
  STRATEGY_SIGNALS: `/strategies/:strategyId/signals`,
  STRATEGY_TRADE_HISTORY: `/strategies/:strategyId/history`,
  EMAIL_VERIFIED: '/email-verified',
  ONBOARDING: '/onboarding',
  ONBOARDING_TERMS: `/onboarding/terms`,
  ONBOARDING_ACCOUNT_TYPE: '/onboarding/account-type',
  ONBOARDING_INFO: '/onboarding/info',
  ONBOARDING_BUSINESS_INFO: '/onboarding/business-info',
  ONBOARDING_IDENTITY: '/onboarding/identity',
  ONBOARDING_COMPLETE: '/onboarding/complete',
  ONBOARDING_BUSINESS_DOCUMENTS: '/onboarding/business-documents',
  REFERRAL: '/referral/:referralCode',
  REWARDS: '/rewards',
  REWARDS_AGGREGATOR: '/rewards/aggregator',
  REWARDS_CLTB: '/rewards/token-balancer',
  REWARDS_HODL: '/rewards/hodl',
  REWARDS_HODL_OVERVIEW: '/rewards/hodl/overview',
  REWARDS_HODL_ACTIVITY: '/rewards/hodl/activity',
  REWARDS_HODL_INFO: '/rewards/hodl/info',
  REWARDS_REFERRALS: '/rewards/referrals',
  REWARDS_REFERRALS_OVERVIEW: '/rewards/referrals/overview',
  REWARDS_REFERRALS_ACTIVITY: '/rewards/referrals/activity',
  REWARDS_REFERRALS_HISTORY: '/rewards/referrals/history',
  REWARDS_DISCOUNTS: '/rewards/discounts',
  REWARDS_DISCOUNTS_OVERVIEW: '/rewards/discounts/overview',
  REWARDS_DISCOUNTS_SCHEDULE: '/rewards/discounts/schedule',
  REWARDS_LIQUIDATOR: '/rewards/liquidator',
  REWARDS_LIQUIDATOR_OVERVIEW: '/rewards/liquidator/overview',
  REWARDS_LIQUIDATOR_ACTIVITY: '/rewards/liquidator/activity',
  REWARDS_LIQUIDATOR_INFO: '/rewards/liquidator/info',
  REWARDS_VOTING: '/rewards/voting',
  MARKETPLACE: '/marketplace',
  MARKETPLACE_CRYPTOCURRENCIES: '/marketplace/cryptocurrencies',
  ACTIVITY: '/activity',
  ACTIVITY_TRADE_HISTORY: '/activity/trade-history',
  ACTIVITY_INCOMPLETE_ORDERS: '/activity/incomplete-orders',
  ACTIVITY_DEPOSITS_WITHDRAWALS: '/activity/deposits-withdrawals',
  ACCOUNT: '/account',
  ACCOUNT_BANK: '/account/bank',
  ACCOUNT_BALANCER: '/account/token-balancer',
  LOAD_TEST_SIGN_IN: '/loadsignin',
  TAX_DOCUMENTS: '/account/tax-documents',
}
export const API_PATHS = {
  COIN: {
    ACCOUNT_BALANCE: 'account/balance',
    ACTIVITY_SUMMARY: 'users/activity-summary',
    ACTUAL_MARKET_PERFORMANCE: (market, startDate, endDate) => `markets/market-performance/${market}?startDate=${startDate}&endDate=${endDate}`,
    ADDRESSES: 'sumsub/applicant/addresses',
    API_KEY: 'apikeys',
    API_KEY_GENERATE: 'apikeys',
    API_SECRET_CYCLE: 'apikeys/cycle-api-secret',
    AVATAR_UPLOAD: 'profile/avatar',
    BACKTEST_SCENARIOS: 'tradingtool/strategies/backtest-scenarios',
    CONVERT_ASSETS: 'wallet/convert-stable-asset',
    DASHBOARD_BACKTEST_HISTORIES: 'tradingtool/strategies/user-backtest-histories',
    DOMAIN: 'coinV3proxy2.test:3001',
    SIGN_IN: 'post-external-login',
    SIGN_OUT: 'sign-out',
    SESSIONS: '/sessions',
    PROFILE: 'profile',
    PROFILE_V2: 'profile/v2',
    PROFILE_TIN: 'profile/tin',
    TRADE_HISTORY: (filters) => `trading_tool/trades${filters ? `${filters}` : ''}`,
    OPEN_POSITIONS: 'trading_tool/positions',
    ORDER_HISTORY: 'order_history',
    TRADE_TOOLS_TRADES: 'trading_tool/trades',
    SELL_POSITION: (positionId) => `tradingtool/positions/${positionId}/sell`,
    DELETE_OPEN_POSITION: (id) => `trading_tool/positions/${id}`,
    DELETE_ORDER: (id) => `orders/${id}`,
    DELETE_ORDERS_FOR_MARKET_AND_SIDE: (marketId, side) => `orders/market/${marketId}/side/${side}`,
    COUNTRIES: 'countries',
    STATES: 'states',
    MARKETS_INFO: 'info',
    ASSETS_INFO: '/assets-info',
    ACCEPT_TERMS: 'profile/accept-terms',
    STRATEGIES: 'trading_tool/strategies',
    STRATEGIES_INDICATORS: 'trading_tool/strategies/indicators',
    STRATEGIES_BUILDER: 'trading_tool/strategies/builder',
    STRATEGY_SIGNALS: (id, startDate, endDate) => `trading_tool/strategies/${id}/signal-history?startDate=${startDate}&endDate=${endDate}`,
    STRATEGY_BACKTEST: 'trading_tool/strategies/backtest',
    DELETE_STRATEGY: (id) => `trading_tool/strategies/${id}`,
    TRADING_STATS_DASHBOARD: 'tradingstats/dashboard',
    TRADING_STATS_MARKETPLACE: 'tradingstats/marketplace',
    PUBLISHERS: 'trading_tool/subscriptions/publishers',
    SUBSCRIPTIONS: 'trading_tool/subscriptions/list',
    PRICES: 'assets/prices',
    BALANCER: 'token-balancer/settings',
    ROI: 'profile/roi',
    DEPOSIT: 'wallet/deposit',
    WITHDRAWAL: 'wallet/withdrawal',
    DEPOSIT_FIAT: 'wallet/deposit/fiat',
    WITHDRAWAL_FIAT: 'wallet/withdrawal/fiat',
    ACH: 'ach/items',
    DELETE_ACH: (itemId) => `ach/items/${itemId}`,
    RELINK_ACH: (itemId) => `ach/items/${itemId}`,
    ACH_PUBLIC_TOKEN: (itemId) => `ach/items/${itemId}/token`,
    CREATE_SUBSCRIPTION: 'trading_tool/subscriptions',
    SUBSCRIBE: (publisherUserId) =>
      `trading_tool/subscriptions/publisher/${publisherUserId}`,
    PUBLISHER_STRATEGIES: (publisherUserId) =>
      `trading_tool/subscriptions/publisher/${publisherUserId}/strategies`,
    ORDER: 'trade/order',
    MARKET_OPTIONS: 'trading_tool/trades/markets',
    SUMSUB_TOKEN: 'profile/sumsub-token',
    SUMSUB_FACE_AUTH_TOKEN: 'profile/sumsub-face-auth/token',
    SUMSUB_FACE_AUTH_STATUS: 'profile/sumsub-face-auth/status',
    TAX_DOCUMENTS: 'reports/tax-statements',
    TAX_DOCUMENT_TYPES: 'reports/tax-statements/types',
    REQUEST_TAX_DOCUMENT: 'reports/tax-statements/request',
    TAX_DOCUMENT_URL: (s3ObjectKey) =>
      `reports/tax-statements/${s3ObjectKey}/access-url`,
    SUPPORTED_INTERVALS: 'trading_tool/strategies/supported-intervals',
    WITHDRAWAL_LIMITS: 'wallet/withdrawal/limits',
    GET_BACKTEST_HISTORY: (strategyId) => `trading_tool/strategies/${strategyId}/backtest-history`,
    PIN_BACKTEST_HISTORY: (strategyId, historyId) => `trading_tool/strategies/${strategyId}/backtest-history/${historyId}/save`,
    UNPIN_BACKTEST_HISTORY: (strategyId, historyId) => `trading_tool/strategies/${strategyId}/backtest-history/${historyId}/unsave`,
    LION_HISTORY: ({startDate, endDate, type, count}) => `instruments/lion_usdc/history?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&type=${type}&count=${count}`,
    MANUAL_BUY_FOR_STRATEGY: (strategyId) => `trading_tool/strategies/${strategyId}/manual-buy`,
    DYNAMIC_VALUES_FOR_INDICATOR_SETTINGS: `tradingtool/indicators/current`,
    DELETE_TRAVEL_RULE_RECIPIENT: (recipientId) => `recipients/${recipientId}`,
    GET_TRAVEL_RULE_RECIPIENTS: 'recipients',
    SAVE_TRAVEL_RULE_RECIPIENT: 'recipients',
    GET_TRAVEL_RULE_CUSTOMER_TOKEN: `wallet/travel-rule/customer-token`,
    VALIDATE_TRAVEL_RULE: `wallet/travel-rule/validate`,
    GET_VASPS: 'https://api.notabene.id/tf/simple/vasps?all=true&includeUncheckedVASPs=false&includeActiveSendersOnly=false&includeActiveReceiversOnly=false&listingType=exclude_subsidiaries',
    HODL_ANNUAL_ALLOCATIONS: `hodl/annual-allocations`,
    HODL_ENROLL: 'hodl/enroll',
    HODL_ENROLLMENT_CLAIM: (enrollmentId) => `hodl/${enrollmentId}/claim`,
    HODL_ENROLLMENT_DELETE: (enrollmentId) => `hodl/${enrollmentId}`,
    HODL_ENROLLMENT_UPGRADE: (enrollmentId, newProgramId) => `hodl/${enrollmentId}/upgrade/${newProgramId}`,
    HODL_GLOBAL_TOTALS: 'hodl/global-totals',
    HODL_PROGRAMS: 'hodl/programs',
    HODL_USER_TOTALS: 'hodl/user-totals',
    AGGREGATOR_ENROLL: 'aggregator/enroll',
    AGGREGATOR_ENROLLMENTS: 'aggregator/enrollments',
    AGGREGATOR_EVENTS: 'aggregator/events',
    AGGREGATOR_PROGRAMS: 'aggregator/programs',
    AGGREGATOR_UPDATE : (enrollmentId) => `aggregator/${enrollmentId}`,
    LIQUIDATOR_ENROLL: 'Liquidator/enroll',
    LIQUIDATOR_ENROLLMENTS: 'Liquidator/enrollments',
    LIQUIDATOR_EVENTS: 'Liquidator/events',
    LIQUIDATOR_PROGRAMS: 'Liquidator/programs',
    LIQUIDATOR_UPDATE : (enrollmentId) => `Liquidator/${enrollmentId}`,
    REFERRAL_REWARDS: `rewards/referral/my-referral-rewards`,
    REFERRAL_REWARD_ACTIONS: `rewards/referral/reward-actions`,
    REFERRED_BY: `rewards/referral/my-referred-by`,
    SAVE_REFERRAL_CODE: 'rewards/referral',
    TRADING_DISCOUNTS_INFO: 'hodl/trading-discounts-info',
    LION_SELL_LIMIT: 'trade/lion-sell-limit',
    VOLUMES: `assets/volumes`,
    CLOSE_ALL_POSITIONS: 'tradingtool/positions/close-all',
    SELL_ALL_POSITIONS: 'tradingtool/positions/sell-all',
    LIQUIDATE_ASSETS: 'account/liquidate-assets',
    UNSUBSCRIBE_FROM_ALL_PUBLISHERS: 'tradingtool/subscriptions/all',
    DISABLE_ALL_STRATEGIES: 'tradingtool/strategies/all',
  },
}
export const LOADING_STATES = {
  GLOBAL: 'GLOBAL',
  SIGN_IN_WITH_AUTH_CREDENTIALS: 'SIGN_IN_WITH_AUTH_CREDENTIALS',
  SIGN_IN_COIN: 'SIGN_IN_COIN',
  CREATE_AUTH0_ACCOUNT: 'CREATE_AUTH0_ACCOUNT',
  FETCH_CLOSED_POSITIONS: 'FETCH_CLOSED_POSITIONS',
  FETCH_OPEN_POSITIONS: 'FETCH_OPEN_POSITIONS',
  DELETE_OPEN_POSITION: 'DELETE_OPEN_POSITION',
  SELL_POSITION: 'SELL_POSITION',
  FETCH_TRADE_HISTORY: 'FETCH_TRADE_HISTORY',
  FETCH_INCOMPLETE_ORDERS: 'FETCH_INCOMPLETE_ORDERS',
  ACH: 'DELETE_ACH',
  ORDER: 'ORDER',
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  FETCH_API_KEY: 'FETCH_API_KEY',
  CYCLE_API_SECRET: 'CYCLE_API_SECRET',
  GETTING_STRATEGY_SIGNALS: 'GETTING_STRATEGY_SIGNALS',
  GETTING_PUBLISHERS: 'GETTING_PUBLISHERS',
  RUNNING_BACKTEST: 'RUNNING_BACKTEST',
  SAVING_BACKTEST_HISTORY_ITEM: 'SAVING_BACKTEST_HISTORY_ITEM',
}
export const USD_GEOGRAPHIC_ALLOWED = [
  'al',
  'alaska',
  'az',
  'arizona',
  'ca',
  'california',
  'co',
  'colorado',
  'de',
  'delaware',
  'fl',
  'florida',
  'guam',
  'ia',
  'iowa',
  'id',
  'idaho',
  'in',
  'indiana',
  'ky',
  'kentucky',
  'la',
  'louisiana',
  'ma',
  'massachusetts',
  'md',
  'maryland',
  'mi',
  'michigan',
  'mn',
  'minnesota',
  'mo',
  'missouri',
  'mt',
  'montana',
  'ne',
  'nebraska',
  'nh',
  'new hampshire',
  'nj',
  'new jersey',
  'nv',
  'nevada',
  'ok',
  'oklahoma',
  'pa',
  'pennsylvania',
  'ri',
  'rhode island',
  'sc',
  'south carolina',
  'sd',
  'south dakota',
  'tx',
  'texas',
  'ut',
  'utah',
  'va',
  'virginia',
  'wa',
  'washington',
  'wy',
  'wyoming',
]
export const MARKET_OPTIONS = [
  {
    label: 'All Active Markets',
    value: 'all',
  },
  {
    label: 'USD',
    value: 'usd',
  },
  /* {
        label: 'USDC',
        value: 'usdc'
    }, {
        label: 'BTC',
        value: 'btc'
    }, */ {
    label: 'ETH',
    value: 'eth',
  } /*, {
        label: 'LION',
        value: 'lion'
    }, {
        label: 'TUSD',
        value: 'tusd'
    }, {
        label: 'USDT',
        value: 'usdt'
    }*/,
]
export const CURRENCY_NAMES = {
  btc: 'Bitcoin',
  ltc: 'Litecoin',
  eth: 'Ethereum',
  lion: 'CoinLion Token',
  bch: 'Bitcoin Cash',
  link: 'Chainlink',
  usdc: 'USD Coin',
  usdt: 'Tether',
  // ada: 'Cardano',
  // sol: 'Solana',
  // doge: 'Dogecoin',
  // avax: 'Avalanche',
  // xrp: 'Ripple'
}
export const STRATEGY_BASE_ASSETS = [
  {
    label: 'Bitcoin (BTC)',
    value: 'btc',
  },
  {
    label: 'Ethereum (ETH)',
    value: 'eth',
  },
  {
    label: 'Litecoin (LTC)',
    value: 'ltc',
  },
  {
    label: 'Bitcoin Cash (BCH)',
    value: 'bch',
  },
  {
    label: 'Chainlink (LINK)',
    value: 'link',
  },
  // {
  //   label: 'Cardano (ADA)',
  //   value: 'ada',
  // },
  // {
  //   label: 'Solana (SOL)',
  //   value: 'sol',
  // },
  // {
  //   label: 'Dogecoin (DOGE)',
  //   value: 'doge',
  // },
  // {
  //   label: 'Avalanche (AVAX)',
  //   value: 'avax',
  // },
  // {
  //   label: 'Ripple (XRP)',
  //   value: 'xrp',
  // }
]
export const STRATEGY_QUOTE_ASSETS = [
  {
    label: 'US Dollars (USD)',
    value: 'usd',
  },
  {
    label: 'USD Coin (USDC)',
    value: 'usdc',
  } /*, {
        label: 'Tether (USDT)',
        value: 'usdt'
    }*/,
]
export const FEATURED_TRADERS = [
  '925b79aa-692c-e217-563f-50786df00eb4',
  '3dd1e60a-a82d-7260-6a92-b36a596b4259',
  'e9e98c5e-6ff6-9ec8-60e4-eaa88a57544e',
]
export const STATS_TIME_FRAMES = [
  { label: '7 Days', value: 1 },
  { label: '30 Days', value: 2 },
  { label: '3 Months', value: 4 },
  { label: '6 Months', value: 5 },
  { label: 'YTD', value: 7 },
  { label: '12 Months', value: 6 },
  { label: 'Lifetime', value: 8 },
]
export const PERMISSION_REASONS = {
  ONBOARDING: 'ONBOARDING',
  REGION: 'REGION',
}
export const FACIAL_VERIFICATION_TIMEOUT = 300000; // five minutes
export const SUBSCRIPTION_POSITION_MIN = 25
export const ELIGIBLE_SUBSCRIPTION_POSITION_MIN_RATIO = 0.8
export const TRULIOO_NON_LATIN_INPUT_COUNTRIES = {
  CN: 'Simplified or traditional Chinese',
  JP: 'Kanji',
  RU: 'Cyrillic',
  TW: 'Traditional Chinese',
  TH: 'Thai',
  UA: 'Cyrillic',
}
export const MONTH_FIRST_COUNTRIES = [
  'CA',
  'DO',
  'FM',
  'HN',
  'NI',
  'PA',
  'PH',
  'PR',
  'PW',
  'SG',
  'SV',
  'US',
]

export const GOOD_TILL_OPTIONS = [
  {
    label: '1 Day',
    value: '1|days',
  },
  {
    label: '1 Week',
    value: '7|days',
  },
  {
    label: '30 Days',
    value: '30|days',
  },
  {
    label: '60 Days',
    value: '60|days',
  },
  {
    label: '90 Days',
    value: '90|days',
  },
  {
    label: '120 Days',
    value: '120|days',
  },
  {
    label: '6 Months',
    value: '6|months',
  },
  {
    label: '9 Months',
    value: '9|months',
  },
  {
    label: '12 Months',
    value: '12|months',
  },
]
