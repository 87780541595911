import React, { useEffect, useState } from 'react'
import Table from '@pv3-shared-components/Table'
import './marketplacecryptocurrencies.scss'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import useFilterMarkets from '@pv3-hooks/useFilterMarkets'
import Currency from '../shared/Currency'
import CurrencyValue from '../shared/CurrencyValue'
import BuySellModal from '@pv3-components/BuySellModal'
import useDocTitle from '@pv3-hooks/useDocTitle'
import useModal from '@pv3-hooks/useModal'
import useMarketsDuck from '@pv3-hooks/useMarketsDuck'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import Loading from '@pv3-shared-components/Loading'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import { eq, isUndefined, get, isEmpty, lt, isNull } from 'lodash'
import { formatNumber } from '../../utils/utils'

const MarketplaceCryptocurrencies = () => {
  useDocTitle('Cryptocurrencies')
  const { buySellPrices, getBuySellPrices, getVolumes, volumes } = useWalletsDuck()
  const { combinedMarketsData, marketsData } = useFilterMarkets()
  const modal = useModal()
  const [modalAsset, setModalAsset] = useState()
  const { startMiniTicker } = useMarketsDuck()
  const { getUsdAllowed } = useProfileDuck()
  const isBig = useWindowWidth()
  const usdAllowed = getUsdAllowed();

  useEffect(() => {
    getBuySellPrices();
    getVolumes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuySellClick = (assetId, exchangeAssetId) => {
    setModalAsset({ assetId, exchangeAssetId })
    modal.setShow(true)
  }

  const columns = React.useMemo(
    () =>
      isBig
        ? [
          // Desktop columns
          {
            Header: '',
            id: 'currencyicon',
            className: 'ReactTable-currencyicon',
            width: 44,
            Cell: (props) => {
              return (
                <Currency
                  className="markets-table__coin"
                  type={get(props, 'original.baseAsset')}
                />
              );
            },
          },
          {
            Header: 'Market',
            maxWidth: 120,
            id: 'market',
            accessor: (d) => {
              const quoteAsset = (combinedMarketsData && combinedMarketsData.filter((md) => md.baseAsset === d.baseAsset).length > 1) ? `-${get(d, 'quoteAsset')}` : '';
              return `${get(d, 'baseAsset')}${quoteAsset}`
            },
            className: 'ReactTable-market',
          },
          {
            Header: 'Currency',
            id: 'currency',
            width: 140,
            Cell: (props) => {
              return get(props, 'original.assetInfo.asset_name');
            },
          },
          {
            Header: 'Volume',
            id: 'volume',
            className: 'u-tableCellMdUp u-justifyend',
            headerClassName: 'u-tableCellMdUp u-tr',
            accessor: (d) => {
              let volumeObj = volumes?.[get(d, 'baseAsset')];
              let volume = volumeObj?.volume;

              return (
                <>
                  {isUndefined(volume) ? (
                    <Loading />
                  ) : (
                    formatNumber(volume, 2, 2)
                  )}
               </>
              );
            }
          },
          {
            Header: 'Buy Price',
            id: 'buyPrice',
            className: 'u-justifyend',
            headerClassName: 'u-tr',
            accessor: (d) =>
              get(d, 'tickerData') ? get(d, 'tickerData.close') : '',
            Cell: (props) => (
              <CurrencyValue
                value={
                  buySellPrices && buySellPrices[get(props, 'original.baseAsset')] ? buySellPrices[get(props, 'original.baseAsset')].buyPrice : 0
                }
                format="usd"
              />
            ),
          },
          {
            Header: 'Sell Price',
            id: 'sellPrice',
            className: 'u-justifyend',
            headerClassName: 'u-tr',
            accessor: (d) =>
              get(d, 'tickerData') ? get(d, 'tickerData.close') : '',
            Cell: (props) => (
              <CurrencyValue
                value={
                  buySellPrices && buySellPrices[get(props, 'original.baseAsset')] ? buySellPrices[get(props, 'original.baseAsset')].sellPrice : 0
                }
                format="usd"
              />
            ),
          },
          {
            Header: '24 Hr Change %',
            id: 'change',
            className: 'u-justifyend',
            headerClassName: 'u-tr',
            width: 150,
            accessor: (d) => {
              let volume = volumes?.[get(d, 'baseAsset')];
              let percentChange = volume?.changePercentage;

              return (
                <div
                  className={`Cryptocurrencies-market-percent ${
                    !eq(percentChange, 0) &&
                    !isNull(percentChange) &&
                    (lt(percentChange, 0)
                      ? 'Cryptocurrencies-market-percent--loss'
                      : 'Cryptocurrencies-market-percent--gain')
                  }`}
                >
                  {isUndefined(percentChange) ? (
                    <Loading />
                  ) : (
                    formatNumber(percentChange, 2, 2) + '%'
                  )}
                </div>
              )
            },
          },
          {
            Header: '',
            id: 'buysell',
            width: 90,
            Cell: (d) => {
              return (
                <button
                  type="button"
                  className="u-buttonReset WalletBalancesTable-buySell"
                  onClick={() =>
                    handleBuySellClick(
                      get(d, 'original.baseAsset'),
                      get(d, 'original.quoteAsset')
                    )
                  }
                >
                  Buy/Sell
                </button>
              )
            },
            headerClassName: 'u-tr',
            className: 'ReactTable-buySell u-justifyend',
          },
        ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'details',
              accessor: (d) => {
                return (
                  <div className="Cryptocurrencies-details">
                    <Currency
                      className="markets-table__coin"
                      type={get(d, 'baseAsset')}
                    />
                    <div className="Cryptocurrencies-details-right">
                      <span className="ReactTable-market Cryptocurrencies-pair">{`${get(
                        d,
                        'baseAsset'
                      )}-${get(d, 'quoteAsset')}`}</span>
                      {get(d, 'currencyName') && (
                        <small className="Cryptocurrencies-currencyName">
                          {get(d, 'currencyName')}
                        </small>
                      )}
                      <button
                        type="button"
                        className="u-buttonReset WalletBalancesTable-buySell"
                        onClick={() =>
                          handleBuySellClick(
                            get(d, 'baseAsset'),
                            get(d, 'quoteAsset')
                          )
                        }
                      >
                        Buy/Sell
                      </button>
                    </div>
                  </div>
                )
              },
            },
            {
              Header: '',
              id: 'market',
              className: 'Cryptocurrencies-market',
              accessor: (d) => {
                let volume = volumes?.[get(d, 'baseAsset')];
                let percentChange = volume?.changePercentage;

                return (
                  <React.Fragment>
                    <div
                      className={`Cryptocurrencies-market-percent ${
                        !eq(percentChange, 0) &&
                        !isNull(percentChange) &&
                        (lt(percentChange, 0)
                          ? 'Cryptocurrencies-market-percent--loss'
                          : 'Cryptocurrencies-market-percent--gain')
                      }`}
                    >
                      {isNull(percentChange) ? (
                        <Loading />
                      ) : (
                        formatNumber(percentChange, 2, 2) + '%'
                      )}
                    </div>
                    <small>
                      {!isEmpty(buySellPrices) && (
                        <>
                          <CurrencyValue
                            value={buySellPrices && buySellPrices[get(d, 'baseAsset')] ? buySellPrices[get(d, 'baseAsset')].buyPrice : 0}
                            format="usd"
                          /> {' / '}
                          <CurrencyValue
                            value={buySellPrices && buySellPrices[get(d, 'baseAsset')] ? buySellPrices[get(d, 'baseAsset')].sellPrice : 0}
                            format="usd"
                          />
                        </>
                      )}
                    </small>
                  </React.Fragment>
                )
              },
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buySellPrices, usdAllowed, marketsData, isBig]
  )

  useEffect(() => {
    startMiniTicker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div className="Content">
        <h2 className="Cryptocurrencies-headline h4">Cryptocurrencies</h2>
        {isEmpty(combinedMarketsData) && <Loading />}
        {combinedMarketsData && (
          <Table
            className="markets-card__table"
            columns={columns}
            data={combinedMarketsData}
          />
        )}
      </div>
      {!isUndefined(modalAsset) && (
        <BuySellModal
          modal={modal}
          assetId={get(modalAsset, 'assetId')}
        />
      )}
    </React.Fragment>
  )
}

export default MarketplaceCryptocurrencies
