import * as React from 'react';
import useProfileDuck from "../../hooks/useProfileDuck";
import Toggle from "../shared/Toggle";
import Loading from "../shared/Loading";
import { useState } from "react";

const ATTEmailSettings: React.FC = () => {
  const [isUpdatingLionEmailPreference, setIsUpdatingLionEmailPreference] = useState<boolean>(false);
  const [isUpdatingAutomatedEmailPreference, setIsUpdatingAutomatedEmailPreference] = useState<boolean>(false);
  const [isUpdatingManualEmailPreference, setIsUpdatingManualEmailPreference] = useState<boolean>(false);

  const { profile, updateProfile } = useProfileDuck();

  const handleToggle = async (name: string) => {
    if (name === 'isLionTradeNotificationEnabled') {
      setIsUpdatingLionEmailPreference(true);
    } else if (name === 'isAttTradeNotificationEnabled') {
      setIsUpdatingAutomatedEmailPreference(true);
    } else if (name === 'isManualTradeNotificationEnabled') {
      setIsUpdatingManualEmailPreference(true);
    }

    try {
      await updateProfile({
        ...profile,
        [name]: !profile[name]
      });
    } catch (err) {
      console.log('ATTEmailSettings handleToggle error', err);
    } finally {
      if (name === 'isLionTradeNotificationEnabled') {
        setIsUpdatingLionEmailPreference(false);
      } else if (name === 'isAttTradeNotificationEnabled') {
        setIsUpdatingAutomatedEmailPreference(false);
      } else if (name === 'isManualTradeNotificationEnabled') {
        setIsUpdatingManualEmailPreference(false);
      }
    }
  };

  return (
    <section
      className="Fieldset AcctSettings-fieldset AcctSettings-fieldset--profile AcctSettings-fieldset--adminTools AcctSettings-fieldset--email-preferences">
      <div>
        <h1 className="Legend AcctSettings-legend">
          Automated Trading Email Settings
        </h1>

        <div className="AcctSettings-fieldset--preferences-toggle">
          <Toggle
            disabled={isUpdatingLionEmailPreference || isUpdatingAutomatedEmailPreference || isUpdatingManualEmailPreference}
            value={profile.isLionTradeNotificationEnabled}
            handleChange={() => handleToggle('isLionTradeNotificationEnabled')}
            label="Receive emails for all automated LION Buys/Sells"
          />

          {isUpdatingLionEmailPreference && <Loading/>}
        </div>


        <div className="AcctSettings-fieldset--preferences-toggle">
          <Toggle
            disabled={isUpdatingLionEmailPreference || isUpdatingAutomatedEmailPreference || isUpdatingManualEmailPreference}
            value={profile.isAttTradeNotificationEnabled}
            handleChange={() => handleToggle('isAttTradeNotificationEnabled')}
            label="Receive emails for all automated Buys/Sells"
          />

          {isUpdatingAutomatedEmailPreference && <Loading/>}
        </div>


        <div className="AcctSettings-fieldset--preferences-toggle">
          <Toggle
            disabled={isUpdatingLionEmailPreference || isUpdatingAutomatedEmailPreference || isUpdatingManualEmailPreference}
            value={profile.isManualTradeNotificationEnabled}
            handleChange={() => handleToggle('isManualTradeNotificationEnabled')}
            label="Receive emails for all manual Buys/Sells"
          />

          {isUpdatingManualEmailPreference && <Loading/>}
        </div>
      </div>
    </section>
  );
};

export default ATTEmailSettings;
