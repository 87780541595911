import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { API_PATHS } from '@pv3-constants'
import {
  get as _get,
  find as _find,
  isUndefined as _isUndefined,
  pull as _pull,
} from 'lodash'
import { getBuilder } from '@pv3-ducks/strategies'
import TokenRequest from '../utils/tokenRequest';

const request = new Request()

export const getPublishers = createAsyncThunk(
  'marketplace/getPublishers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.PUBLISHERS)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const getMarketplaceStats = createAsyncThunk(
  'strategies/getMarketplaceStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(
        API_PATHS.COIN.TRADING_STATS_MARKETPLACE
      )
      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const getSubscriptions = createAsyncThunk(
  'marketplace/getSubscriptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.SUBSCRIPTIONS)
      response.data.forEach((s) => {
        if(!s.publisherDisplayName) {
          s.publisherDisplayName = `${s.publisherFirstName} ${s.publisherLastName}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const getPublisherStrategies = createAsyncThunk(
  'profile/getPublisherStrategies',
  async (publisherUserId, { rejectWithValue }) => {
    try {
      const response = await request.get(
        API_PATHS.COIN.PUBLISHER_STRATEGIES(publisherUserId)
      )
      return response.data;
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const createSubscription = createAsyncThunk(
  'marketplace/createSubscription',
  async (
    { publisherUserId, portfolioPercent },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await request.post(API_PATHS.COIN.CREATE_SUBSCRIPTION, {
        publisherUserId: publisherUserId,
        portfolioPercent: portfolioPercent,
      })
      dispatch(getBuilder())
      return { publisherUserId, portfolioPercent }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateSubscription = createAsyncThunk(
  'marketplace/updateSubscription',
  async (
    { publisherUserId, portfolioPercent },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await request.put(API_PATHS.COIN.SUBSCRIBE(publisherUserId), {
        publisherUserId: publisherUserId,
        portfolioPercent: portfolioPercent,
      })
      dispatch(getBuilder())
      return { publisherUserId, portfolioPercent }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const unsubscribe = createAsyncThunk(
  'marketplace/unsubscribe',
  async (publisherUserId, { rejectWithValue, dispatch }) => {
    try {
      await request.delete(API_PATHS.COIN.SUBSCRIBE(publisherUserId))
      dispatch(getBuilder())
      return publisherUserId
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const unsubscribeFromAllPublishers = createAsyncThunk(
  'marketplace/unsubscribeFromAllPublishers',
  async (_, { getState, rejectWithValue }) => {
    try {
      await new TokenRequest(getState().account.idToken).delete(API_PATHS.COIN.UNSUBSCRIBE_FROM_ALL_PUBLISHERS);
      await new TokenRequest(getState().account.idToken).delete(API_PATHS.COIN.DISABLE_ALL_STRATEGIES);
      return;
    } catch(e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: {
    publishers: null,
    subscriptions: null,
    marketplaceStats: {},
  },
  reducers: {},
  extraReducers: {
    [getPublishers.fulfilled]: (state, action) => {
      state.publishers = action.payload
    },
    [getSubscriptions.fulfilled]: (state, action) => {
      state.subscriptions = action.payload
    },
    [getMarketplaceStats.fulfilled]: (state, action) => {
      state.marketplaceStats = action.payload
    },
    [getPublisherStrategies.fulfilled]: (state, action) => {
      state.publisherStrategies = action.payload;
    },
    [createSubscription.fulfilled]: (state, action) => {
      const publisher = _find(state.publishers, {
        publisherUserId: _get(action.payload, 'publisherUserId'),
      })
      state.subscriptions.push({
        subscriberUserId: _get(state, 'profile.data.userId'),
        publisherUserId: _get(action.payload, 'publisherUserId'),
        publisherDisplayName: _get(publisher, 'displayName'),
        publisherFirstName: _get(publisher, 'firstName'),
        publisherLastName: _get(publisher, 'lastName'),
        portfolioPercent: _get(action.payload, 'portfolioPercent'),
        baseCurrencies: _get(publisher, 'baseCurrencies'),
      })
    },
    [updateSubscription.fulfilled]: (state, action) => {
      const subscription = _find(state.subscriptions, {
        publisherUserId: _get(action.payload, 'publisherUserId'),
      })
      if (!_isUndefined(subscription)) {
        subscription.portfolioPercent = _get(action.payload, 'portfolioPercent')
      }
    },
    [unsubscribe.fulfilled]: (state, action) => {
      const subscription = _find(state.subscriptions, {
        publisherUserId: action.payload,
      })
      if (!_isUndefined(subscription)) {
        _pull(state.subscriptions, subscription)
      }
    },
  },
})

export default marketplaceSlice.reducer
