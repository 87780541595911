import React from 'react'

const Up = ({ fill = '#00854D', width, height, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.4883 13.0636L10.1073 6.71763C10.0536 6.65011 9.94638 6.65011 9.89275 6.71763L4.51178 13.0636C4.38664 13.2155 4.44027 13.3337 4.63691 13.3337H15.3631C15.5598 13.3337 15.6134 13.2155 15.4883 13.0636Z"
        fill={fill}
      />
    </svg>
  )
}

export default Up
