import { useState, useEffect } from 'react'

const useWindowWidth = (width = 768) => {
  const [isBig, setIsBig] = useState(false)

  useEffect(() => {
    const screenSmMin = window.matchMedia(`(min-width: ${width}px)`)

    const handleSmWidthChange = (event) => {
      setIsBig(event.matches)
    }

    const isBigInit = () => {
      setIsBig(window.innerWidth > width)
    }

    screenSmMin.addEventListener('change', handleSmWidthChange)

    if (document.readyState === 'loading') {
      // Loading hasn't finished yet
      document.addEventListener('DOMContentLoaded', isBigInit)
    } else {
      // `DOMContentLoaded` has already fired
      isBigInit()
    }

    return () => {
      screenSmMin.removeEventListener('change', handleSmWidthChange)
      document.removeEventListener('DOMContentLoaded', isBigInit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isBig
}

export default useWindowWidth
