import { isUndefined } from 'lodash'

export const errorFromAxios = (error) => {
  return {
    status: error.response.status,
    statusCode: error.response.statusText,
    data: error.response.data,
  }
}

export const errorFromAuth0 = (response) => {
  if (
    !isUndefined(response.statusCode) &&
    !isUndefined(response.code) &&
    !isUndefined(response.description)
  ) {
    return {
      status: response.statusCode,
      statusCode: response.code,
      data: response.description,
    }
  } else {
    console.log(response)
    return {
      status: 'unknown',
      statusCode: 'unknown',
      data: 'unknown',
    }
  }
}
