import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { eq, find, isUndefined } from 'lodash'
import useUiDuck from '@pv3-hooks/useUiDuck'
import 'react-table/react-table.css'
import './table.scss'

const Table = ({
  className,
  pageSize,
  showPagination,
  emptyHeadline,
  emptyDescription,
  emptyCta,
  loadingStates,
  showIfEmpty,
  ...otherProps
}) => {
  const dataQty = pageSize || otherProps.data.length

  const { loading } = useUiDuck()
  const isLoading = !isUndefined(
    find(loadingStates, (loadingState) => loading[loadingState])
  )

  if (isLoading) {
    return <React.Fragment />
  }

  if (eq(dataQty, 0) && !showIfEmpty) {
    return (
      <section className="EmptyTable">
        {emptyHeadline && (
          <h1 className="EmptyTable-headline">{emptyHeadline}</h1>
        )}
        {emptyDescription && (
          <p className="EmptyTable-description">{emptyDescription}</p>
        )}
        {emptyCta}
      </section>
    )
  }

  return (
    <ReactTable
      pageSize={dataQty}
      showPagination={showPagination || false}
      resizable={false}
      {...otherProps}
    />
  )
}

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  showPagination: PropTypes.bool,
  emptyHeadline: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyCta: PropTypes.node,
}

export default Table
