import './publisherCard.scss';

import * as React from 'react';
import { useMemo, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { get, gte, isEmpty, isNil, sortBy } from "lodash";
import * as moment from "moment";
import { useHistory } from "react-router-dom";

import Publisher from "../../types/Publisher";
import PublisherStats from "../../types/PublisherStats";
import Subscription from "../../types/Subscription";

import { APP_PATHS } from '../../constants';
import { fixFloatingPointErrors, formatAsDollarAmount, isObjectNullOrEmpty } from '../../utils/utils';

import useModal from '../../hooks/useModal';
import useProfileDuck from '../../hooks/useProfileDuck';
import useStrategiesDuck from '../../hooks/useStrategiesDuck';
import useWalletsDuck from '../../hooks/useWalletsDuck';

import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from '../shared/Button';
import EnhancedFlash, { EnhancedFlashVariants } from "../shared/EnhancedFlash";
import Modal from '../shared/Modal';
import ParagraphsFromNewlines from "../shared/ParagraphsFromNewlines";
import StopLossTooltip from "../shared/StopLossTooltip";
import SubscriptionModal from "../SubscriptionModal";
import TooltipLight from "../shared/TooltipLight";

const PIE_COLORS = [
  '#F99F35',
  '#454A75',
  '#9C3C9A',
  '#0AC18E',
];

type Props = {
  activeSubscription: Subscription
  publisher: Publisher
  stats: PublisherStats
  timeFrame: number
}

const PublisherCard: React.FC<Props> = ({
  activeSubscription,
  publisher,
  stats,
  timeFrame,
}) => {
  const { activitySummary, profile } = useProfileDuck();
  const { builder } = useStrategiesDuck();
  const { totalValueInUSD, walletValuesInUSD } = useWalletsDuck();

  const history = useHistory();
  const errorModal = useModal();
  const modal = useModal()

  const [errorType, setErrorType] = useState<string>('');

  const calculatePositiveOrNegative = (value: number | '--') => {
    if(value === 0 || value === '--') {
      return 'neutral';
    }

    if (value > 0) {
      return 'positive';
    } else if (value < 0) {
      return 'negative';
    }
  };

  const formatCurrenciesObjects = (currencies: string) => {
    const baseCurrencies = stats?.baseCurrencies.split(', ');
    let currencyObjects = baseCurrencies.map((currency) => {
      const c = currency.split(' ');
      return {
        name: c[0],
        value: Number(c[1].replace('%', '')),
      };
    });

    return sortBy(currencyObjects, ['value']).reverse();
  };

  const handleCloseErrorModal = () => {
    errorModal.setShow(false);
    setErrorType('');
  };

  const handleCtaClick = () => {
    const isEdit = !isEmpty(activeSubscription);
    if(isEdit) {
      modal.setShow(true);
      return;
    }

    if(gte(builder.portfolioPercentAllocated, 120)) {
      setErrorType('FULLY_ALLOCATED');
      errorModal.setShow(true);
      return;
    } else if(
      (totalValueInUSD < 1000 && activitySummary.hasSubscribed !== true) ||
      totalValueInUSD < 500
    ) {
      setErrorType('INSUFFICIENT_FUNDS');
      errorModal.setShow(true);
      return;
    } else if(
      activitySummary.lastDepositDate &&
      moment(activitySummary.lastDepositDate).isAfter(moment().subtract(1, 'months')) &&
      activitySummary.hasSubscribed !== true &&
      walletValuesInUSD[activitySummary.homeQuoteAsset] <= 0
    ) {
      setErrorType('NEED_FUNDS');
      errorModal.setShow(true);
      return;
    }

    modal.setShow(true);
  }

  const renderAvatarDisplay = () => {
    if(publisher.avatarImageUrl) {
      return (
        <div className="PublisherCard-avatar" style={{backgroundImage: 'url(' + publisher.avatarImageUrl + ')'}} />
      );
    } else {
      const name = publisher.displayName || publisher.fullName;
      const nameParts = name.split(' ');
      const initials = nameParts.map((p) => p.charAt(0));
      return (
        <div className="PublisherCard-avatar PublisherCard--initials">
          {initials.join('')}
        </div>
      );
    }
  }

  const currencies = useMemo(
    () => {
      if (!stats || !stats?.baseCurrencies) {
        return [];
      }

      let currencyObjects = formatCurrenciesObjects(stats?.baseCurrencies);

      if(currencyObjects.length > 4) {
        const otherCurrencies = currencyObjects.slice(3);
        let total = 0;
        otherCurrencies.forEach((c) => total += c.value);
        currencyObjects[3] = {
          name: 'Others',
          value: Number(total.toFixed(2)),
        };
        return currencyObjects.slice(0, 4);
      }

      return currencyObjects;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stats]
  );

  const numberOfTradesPerWeek = useMemo(
    () => {
      if(isNil(stats) || isNil(stats?.numberOfSubscribers) || isNil(stats?.tradeQuantity)) {
        return '--';
      }

      let numberOfSubscribers = Number(stats?.numberOfSubscribers);
      const tradeQuantity = Number(stats?.tradeQuantity);

      if(numberOfSubscribers === 0) {
        numberOfSubscribers = 1;
      }

      switch(timeFrame) {
        case 0:
        case 1:
          return (tradeQuantity / numberOfSubscribers);
        case 2:
          return ((tradeQuantity / numberOfSubscribers) / 4);
        case 3:
          return ((tradeQuantity / numberOfSubscribers) / 8);
        case 4:
          return ((tradeQuantity / numberOfSubscribers) / 12);
        case 5:
          return ((tradeQuantity / numberOfSubscribers) / 26);
        case 6:
          return ((tradeQuantity / numberOfSubscribers) / 52);
        case 7:
          return ((tradeQuantity / numberOfSubscribers) / moment().isoWeeks());
        case 8:
          return (tradeQuantity / numberOfSubscribers);
        default:
          return '--';
      }
    }, [stats, timeFrame]
  );

  if(isObjectNullOrEmpty(publisher) || isObjectNullOrEmpty(stats)) {
    return null;
  }

  return (
    <article className="PublisherCard">
      <header className="PublisherCard-header">
        <div className="PublisherCard-headerInfo">
          <TooltipLight
            className="PublisherCard-aboutTooltip"
            icon="addresscard"
          >
            <ParagraphsFromNewlines
              linkify
              text={publisher.description}
            />
          </TooltipLight>

          <StopLossTooltip
            publisherId={publisher.publisherUserId}
          />
        </div>

        <div className="PublisherCard-avatarContainer">
          <PieChart width={128} height={128}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={currencies}
              cx="50%"
              cy="50%"
              outerRadius={64}
            >
              {currencies.map((_, index) => <Cell key={index} fill={PIE_COLORS[index]}/>)}
            </Pie>
          </PieChart>

          {renderAvatarDisplay()}
        </div>

        <div className="PublisherCard-currenciesContainer">
          <ul className="PublisherCard-currencies">
            {currencies.map((currency, index) => (
              <li className="PublisherCard-currency" key={currency.name}>
                <div className="PublisherCard-currencyDot" style={{backgroundColor: PIE_COLORS[index]}}/>

                <div className="PublisherCard-currencyName">
                  {currency.name}{' '}

                  <span>{currency.value}%</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <h1 className="PublisherCard-name">
          {publisher.displayName || publisher.fullName}
        </h1>
      </header>


      <div className="PublisherCard-mainStats">
        <div className="PublisherCard-mainStat">
          {timeFrame !== 8 && (
            <>
              <div
                className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.publisherRoiPercent)}`}>
                {stats?.publisherRoiPercent.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
              </div>

              <div className="PublisherCard-label">
                Annualized ROI
              </div>
            </>
          )}
        </div>

        <div className="PublisherCard-mainStat">
          <div className="PublisherCard-value">
            {stats?.numberOfSubscribers}
          </div>

          <div className="PublisherCard-label">
            Subscribers
          </div>
        </div>
      </div>

      <ul className="PublisherCard-stats">
        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Strategy Performance
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.strategyPerformance)}`}>
            {stats?.strategyPerformance.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Actual Market Performance
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.actualMarketPerformancePercent)}`}>
            {stats?.actualMarketPerformancePercent.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Tradable Assets
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.tradableAssetsUsd)}`}>
            {formatAsDollarAmount(stats?.tradableAssetsUsd)}
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Followers PnL
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.followersPnl)}`}>
            {formatAsDollarAmount(stats?.followersPnl)}
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Personal PnL
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.personalProfitsCombined)}`}>
            {formatAsDollarAmount(stats?.personalProfitsCombined)}
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            Avg PnL per Trade
          </div>

          <div className={`PublisherCard-value PublisherCard-value--${calculatePositiveOrNegative(stats?.averageProfitCombined)}`}>
            {stats?.averageProfitCombined.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
          </div>
        </li>

        <li className="PublisherCard-stat">
          <div className="PublisherCard-label">
            {timeFrame === 8 ? 'Total Trades' : 'Avg Trades Each Week'}
          </div>

          <div className="PublisherCard-value">
            {numberOfTradesPerWeek !== '--' ? numberOfTradesPerWeek.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) : numberOfTradesPerWeek}
          </div>
        </li>
      </ul>
      {profile?.userId !== publisher?.publisherUserId && (
        <div className="PublisherCard-cta">
          {!isNil(activeSubscription) ? (
            <React.Fragment>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm12.82-2.773a.943.943 0 0 1 0 1.333l-5.853 5.847-3.634-3.634a.943.943 0 1 1 1.334-1.333l2.306 2.3 4.514-4.513a.943.943 0 0 1 1.333 0Z"
                  fill="#500078"
                />
              </svg>
              <span>
                Following {' '}
                {fixFloatingPointErrors(
                  get(activeSubscription, 'portfolioPercent'),
                  2
                )}
                %
              </span>
              <Button
                className="PublisherCard-cta-manage"
                onClick={handleCtaClick}
                size={ButtonSizes.Large}
                variant={ButtonVariants.Secondary}
              >
                Manage
              </Button>
            </React.Fragment>
          ) : (
            <>
              <Button
                className="PublisherCard-cta-subscribe"
                onClick={handleCtaClick}
                size={ButtonSizes.Large}
                variant={ButtonVariants.Primary}
              >
                Follow this Trader
              </Button>
            </>
          )}
        </div>
      )}

      {modal.show && (
        <SubscriptionModal
          modal={modal}
          publisher={publisher}
          activeSubscription={activeSubscription}
        />
      )}

      {errorModal.show && (
        <Modal
          modal={errorModal}
          screens={{
            MAIN: {
              heading: <h4>CopyCat Trading</h4>,
              body: () => (
                <div className="Copycat-errorModal">
                  {errorType === 'FULLY_ALLOCATED' && (
                    <>
                      <EnhancedFlash
                        heading="Your portfolio is fully allocated"
                        subheading="You cannot follow any new Publishers until you either reduce the percent that you allocated to those other Publishers or stop following those Publishers entirely."
                        variant={EnhancedFlashVariants.WarningAlt}
                      />

                      <Button
                        onClick={handleCloseErrorModal}
                        size={ButtonSizes.Large}
                        type={ButtonTypes.Button}
                        variant={ButtonVariants.Primary}
                      >
                        Done
                      </Button>
                    </>
                  )}

                  {errorType === 'INSUFFICIENT_FUNDS' && (
                    <>
                      <EnhancedFlash
                        heading="Insufficient Portfolio Size"
                        subheading="CoinLion requires that all users who wish to leverage the CopyCat trading have a Portfolio balance greater than $1,000."
                        variant={EnhancedFlashVariants.WarningAlt}
                      />

                      <Button
                        onClick={handleCloseErrorModal}
                        size={ButtonSizes.Large}
                        type={ButtonTypes.Button}
                        variant={ButtonVariants.Primary}
                      >
                        Done
                      </Button>
                    </>
                  )}

                  {errorType === 'NEED_FUNDS' && (
                    <>
                      <EnhancedFlash
                        heading="You need funds to auto-trade with."
                        subheading={(
                          <>
                            <p>
                              In order for the auto-trading tool to work you need to ensure that you have a balance in your Home Quote Currency.  Your Home Quote Currency is currently set to {activitySummary.homeQuoteAsset.toUpperCase()} and you can change that in the Account Settings if needed.
                            </p>

                            <p>
                              It does look like you deposited some crypto onto the platform that you may need to sell in order for the auto-trade to being working.
                            </p>
                          </>
                        )}
                        variant={EnhancedFlashVariants.WarningAlt}
                      />

                      <Button
                        className="Copycat--errorButton"
                        onClick={() => {
                          handleCloseErrorModal();
                          history.push(APP_PATHS.BASE, { tab: 'Wallets' });
                        }}
                        size={ButtonSizes.Large}
                        type={ButtonTypes.Button}
                        variant={ButtonVariants.Primary}
                      >
                        Go Sell Some Crypto
                      </Button>

                      <Button
                        className="Copycat--errorButton"
                        onClick={() => {
                          handleCloseErrorModal();
                          history.push(APP_PATHS.ACCOUNT);
                        }}
                        size={ButtonSizes.Large}
                        type={ButtonTypes.Button}
                        variant={ButtonVariants.Primary}
                      >
                        Account Settings
                      </Button>

                      <Button
                        onClick={() => {
                          handleCloseErrorModal();
                          modal.setShow(true);
                        }}
                        size={ButtonSizes.Large}
                        type={ButtonTypes.Button}
                        variant={ButtonVariants.Primary}
                      >
                        Continue to CopyCat Settings
                      </Button>
                    </>
                  )}
                </div>
              )
            },
          }}
        />
      )}
    </article>
  );
};

export default PublisherCard;
