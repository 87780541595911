import { unwrapResult } from '@reduxjs/toolkit'
import { isEmpty, reduce, eq, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenOrders } from '../ducks/trades'
import {
  getAccountBalance as _getAccountBalance,
  getUsdPrices as _getUsdPrices,
  getVolumes as _getVolumes,
  getBuySellPrices as _getBuySellPrices,
  getWithdrawalLimits as _getWithdrawalLimits,
  createOrder as _createOrder,
  setBalances,
  setTransfers,
} from '../ducks/wallets'
import { startWsAccount } from '../websockets/wsAccount'

const useWalletsDuck = () => {
  const dispatch = useDispatch()
  const { accountBalances, balances, buySellPrices, buySellPricesUpdateDateTime, usdPrices, usdPricesUpdateDateTime, totalValueInUSD, volumes, walletValuesInUSD, transfers, withdrawalLimits } =
    useSelector((state) => state.wallets)

  const getAccountBalance = () => dispatch(_getAccountBalance())

  const getUsdPrices = () => dispatch(_getUsdPrices())

  const getVolumes = () => dispatch(_getVolumes())

  const getBuySellPrices = () => dispatch(_getBuySellPrices())

  const createOrder = async (order) => {
    const result = await dispatch(_createOrder(order))
    unwrapResult(result)
  }

  const getWithdrawalLimits = async () => {
    const result = await dispatch(_getWithdrawalLimits())
    unwrapResult(result)
  }

  const totalPortfolioValueMinusLion = useSelector((state) => {
    if (isEmpty(state.wallets.walletValuesInUSD)) return null

    return reduce(
      state.wallets.walletValuesInUSD,
      (totalValue, walletValue, walletKey) => {
        if (eq(walletKey, 'lion')) return totalValue
        return walletValue + totalValue
      },
      0
    )
  })

  const subscribeToAccountBalances = () => {
    startWsAccount(
      (balances) => {
        return dispatch(setBalances(balances))
      },
    (openOrders) => dispatch(updateOpenOrders(openOrders)),
      (transfers) => {
        const processedTransfers = map(transfers, (transfer) => ({
          ...transfer,
          data: eq(transfer.data, '') ? {} : JSON.parse(transfer.data),
        }))
        dispatch(setTransfers(processedTransfers))
      }
    )
  }

  return {
    accountBalances,
    walletValuesInUSD,
    totalPortfolioValueMinusLion,
    totalValueInUSD,
    buySellPrices,
    buySellPricesUpdateDateTime,
    usdPrices,
    usdPricesUpdateDateTime,
    balances,
    transfers,
    volumes,
    withdrawalLimits,
    createOrder,
    getAccountBalance,
    getUsdPrices,
    getVolumes,
    getBuySellPrices,
    getWithdrawalLimits,
    subscribeToAccountBalances,
  }
}

export default useWalletsDuck;
