import { useEffect } from 'react'
import { isNil, some } from 'lodash'

const useClickAway = (refs, callback) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        some(refs, (ref) => isNil(ref.current)) ||
        some(refs, (ref) => ref.current.contains(event.target))
      )
        return

      return callback()
    }

    document.addEventListener('click', listener, { capture: true })

    return () => {
      document.removeEventListener('click', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs])
}

export default useClickAway
