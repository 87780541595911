import { isUndefined, get, isEmpty, isNil } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clone from 'clone';
import {
  subscribeToMiniTicker,
  subscribeToOrderBook,
  subscribeToTradesByMarket,
} from '../websockets/wsMarketDataInfo'
import {
  fetchAllMarkets as marketsFetchAllMarkets,
  getLionHistory as _getLionHistory,
  setLionHistorySelectedTimePeriod as _setLionHistorySelectedTimePeriod,
  setOrderBook,
  setTickerData,
  setTradesByMarket,
} from './../ducks/markets'
import { formatAsDollarAmount, formatNumber, isObjectNullOrEmpty } from '../utils/utils';

const useMarketsDuck = () => {
  const dispatch = useDispatch()
  const {
    allMarkets,
    lionHistory,
    lionHistorySelectedTimePeriod,
    tickerData,
    availableMarketAssetIds,
    orderBooks,
    trades,
  } = useSelector((state) => state.markets)

  const { assets } = useSelector((state) => state.assets);
  const { usdPrices } = useSelector((state) => state.wallets);

  const { pairs } = allMarkets

  const fetchAllMarkets = () => {
    dispatch(marketsFetchAllMarkets())
  }

  const getLionHistory = (data) => dispatch(_getLionHistory(data));
  const setLionHistorySelectedTimePeriod = (data) => dispatch(_setLionHistorySelectedTimePeriod(data));

  useEffect(() => {
    if (!isUndefined(pairs)) return
    fetchAllMarkets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMarkets])

  const getMarketFee = (marketId) =>
    get(allMarkets, `['pairs'][${marketId}]['fee']`)

  const getTakerFee = (marketId) =>
    get(allMarkets, `['pairs'][${marketId}]['takerFee']`)

  const startMiniTicker = () => {
    if (isEmpty(tickerData)) {
      subscribeToMiniTicker((item) => {
        dispatch(setTickerData(item))
      })
    }
  }

  const startOrderBook = (marketPair) => {
    if (isUndefined(orderBooks[marketPair])) {
      subscribeToOrderBook(marketPair, (item) => {
        dispatch(setOrderBook(item))
      })
    }
  }

  const startTradesByMarket = (marketPair) => {
    if (isUndefined(trades[marketPair])) {
      subscribeToTradesByMarket(marketPair, (item) => {
        dispatch(setTradesByMarket({ market: marketPair, trades: item }))
      })
    }
  }

  const calculateBestAskPrice = ({amount, instrument}) => {
    const book = orderBooks[/lion_usd|lion_usdt/.test(instrument) ? 'lion_usdc' : instrument];

    if(isObjectNullOrEmpty(book) || book.asks?.length === 0 || isNil(amount)) {
      return null;
    }

    amount = Number(amount);

    let totalPrice = 0;
    let totalAmount = 0;

    for(let ask of book.asks) {
      if(totalAmount >= amount) {
        break;
      }

      if(amount > totalAmount + ask.amount) {
        totalPrice += ask.amount * ask.price;
        totalAmount += ask.amount;
      } else {
        const amountRemaining = amount - totalAmount;
        totalPrice += amountRemaining * ask.price;
        totalAmount += amountRemaining;
      }
    }

    return totalPrice / totalAmount;
  };

  const calculateBestBidPrice = ({amount, instrument}) => {
    const book = orderBooks[/lion_usd|lion_usdt/.test(instrument) ? 'lion_usdc' : instrument];


    if(isObjectNullOrEmpty(book) || book.bids?.length === 0) {
      return null;
    }

    const bids = clone(book?.bids)?.reverse();
    amount = Number(amount);

    let totalPrice = 0;
    let totalAmount = 0;

    for(let bid of bids) {
      if(totalAmount >= amount) {
        break;
      }

      if(amount > totalAmount + bid.amount) {
        totalPrice += bid.amount * bid.price;
        totalAmount += bid.amount;
      } else {
        const amountRemaining = amount - totalAmount;
        totalPrice += amountRemaining * bid.price;
        totalAmount += amountRemaining;
      }
    }

    return totalPrice / totalAmount;
  };

  const findBuySellPrice = (instrument) => {
    if(!instrument) {
      return null;
    }

    return usdPrices[instrument.split('_')[0]];
  }

  const calculateApproximateBuyPrice = ({ amount, instrument }) => {
    if(!instrument) {
      return null;
    }

    const [receiveAsset, payAsset] = instrument.split('_');

    if(!amount) {
      return `≈ ${payAsset === 'usd' ? formatAsDollarAmount(0) : `0 ${payAsset.toUpperCase()}`}`;
    }

    const bestAsk = receiveAsset === 'lion' ? calculateBestAskPrice({amount, instrument}) : findBuySellPrice(instrument);

    if(bestAsk == null) {
      return 'ERROR';
    }

    const asset = assets.find((asset) => asset.id === payAsset);

    let total = bestAsk * amount;

    if(isNaN(total)) {
      total = 0;
    }

    return `≈ ${payAsset === 'usd' ? formatAsDollarAmount(total) : `${formatNumber(total, 0, asset.scale)} ${payAsset.toUpperCase()}`}`;
  };

  const calculateApproximateSellPrice = ({ amount, instrument }) => {
    if(!instrument) {
      return null;
    }

    const [sellAsset, payAsset] = instrument.split('_');

    if(!amount) {
      return `≈ ${payAsset === 'usd' ? formatAsDollarAmount(0) : `0 ${payAsset.toUpperCase()}`}`;
    }

    const bestBid = sellAsset === 'lion' ? calculateBestBidPrice({amount, instrument}) : findBuySellPrice(instrument);

    if(bestBid == null) {
      return 'ERROR';
    }

    const asset = assets.find((asset) => asset.id === payAsset);

    let total = bestBid * amount;

    if(isNaN(total)) {
      total = 0;
    }

    return `≈ ${payAsset === 'usd' ? formatAsDollarAmount(total) : `${formatNumber(total, 0, asset.scale)} ${payAsset.toUpperCase()}`}`;
  };

  return {
    calculateBestAskPrice,
    calculateBestBidPrice,
    calculateApproximateBuyPrice,
    calculateApproximateSellPrice,
    findBuySellPrice,
    fetchAllMarkets,
    getLionHistory,
    lionHistory,
    lionHistorySelectedTimePeriod,
    setLionHistorySelectedTimePeriod,
    pairs,
    tickerData,
    availableMarketAssetIds,
    orderBooks,
    trades,
    getMarketFee,
    getTakerFee,
    startMiniTicker,
    startOrderBook,
    startTradesByMarket,
  }
}

export default useMarketsDuck
