import React, { useMemo } from 'react'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import Loading from '@pv3-shared-components/Loading'
import { eq, filter, gt, isEmpty, isNil, isNull, map, sortBy, upperCase } from 'lodash'
import { formatAsDollarAmount } from '../../utils/utils'
import './portfolioHoldings.scss'
import useAssetsDuck from '../../hooks/useAssetsDuck';
import { useTypedSelector } from '../../ducks';

const PortfolioHoldings = () => {
  const { accountBalances, balances, usdPrices, walletValuesInUSD, totalValueInUSD } = useWalletsDuck();
  const { assets } = useAssetsDuck();
  const { userTotals } = useTypedSelector((state) => state.rewards);
  const totalPxGap = (filter(balances, (v) => gt(v, 0)).length - 1) * 4
  let totalDecimalPercent = 100

  const assetsToDisplay = useMemo(
    () => {
      if(!assets) return [];
      let sortedAssets = sortBy(assets, ['id']);
      return sortedAssets.map((asset) => asset.id);
    }, [assets]
  );

  const balancesToDisplay = useMemo(
    () => {
      return {
        ...balances,
        lion: userTotals?.totalLionBalance - userTotals?.lionInOpenOrders,
      }

    }, [balances, userTotals]
  );

  if (eq(totalValueInUSD, 0)) return null

  function barGraphMultiplier() {
    // Calculate total absolute percentage, as some asset holdings
    // can be lower than 0%, and some can be greaters
    if (isNull(balances)) return 1

    totalDecimalPercent = 0
    map(assetsToDisplay, (v) => {
      if (isNil(balances[v]) || !gt(balances[v], 0)) return
      totalDecimalPercent += walletValuesInUSD[v] / totalValueInUSD
    })
    return 1 / totalDecimalPercent
  };

  const calculateWalletValueOfLion = () => {
    return (userTotals?.totalLionBalance - userTotals?.lionInOpenOrders) * usdPrices?.lion;
  };

  return (
    <figure className="PortfolioHoldings">
      {isEmpty(balances) || isEmpty(walletValuesInUSD) ? (
        <Loading />
      ) : (
        <React.Fragment>
          <ul className="PortfolioHoldings-barGraph" aria-hidden="true">
            {map(assetsToDisplay, (value) => {
              if (isNil(balancesToDisplay[value]) || !gt(balancesToDisplay[value], 0)) return
              const walletValue = value === 'lion' ? calculateWalletValueOfLion() : walletValuesInUSD[value];
              const decimalPercent = walletValue / accountBalances?.totalAccountBalanceUsd;
              const gap = decimalPercent * totalPxGap
              return (
                <li
                  key={value}
                  style={{
                    width:
                      'calc(' +
                      decimalPercent * barGraphMultiplier() * 100 +
                      '% - ' +
                      gap +
                      'px)',
                  }}
                  className={`PortfolioHoldings-barGraph-amount PortfolioHoldings-barGraph-amount--${upperCase(
                    value
                  )}`}
                />
              )
            })}
          </ul>
          <figcaption className="PortfolioHoldings-legend">
            <ul>
              {map(assetsToDisplay, (value) => {
                const walletValue = value === 'lion' ? calculateWalletValueOfLion() : walletValuesInUSD[value];
                if (
                  isNil(walletValue) ||
                  eq(walletValue, 0)
                )
                  return

                return (
                  <li
                    key={value}
                    className={`PortfolioHoldings-legend-item PortfolioHoldings-legend-item--${upperCase(
                      value
                    )}`}
                  >
                    <b>{formatAsDollarAmount(walletValue)}</b>
                    <span>{upperCase(value)}</span>{' '}
                    {(
                      (walletValue / accountBalances?.totalAccountBalanceUsd) *
                      100
                    ).toFixed(2)}
                    %
                  </li>
                )
              })}
            </ul>
          </figcaption>
        </React.Fragment>
      )}
    </figure>
  )
}

export default PortfolioHoldings
