import './CreateAccountDisclaimer.scss';

import React from 'react';
import { useHistory } from "react-router-dom";

import { APP_PATHS } from "../../constants";

import Button, { ButtonSizes, ButtonVariants } from "../shared/Button";
import Icon from "../shared/Icons";

const CreateAccountDisclaimer = () => {
  const history = useHistory();

  return (
    <div
      className="SinglePanel-panel CreateAccountDisclaimer"
    >
      <h1 className="SinglePanel-logo">
        <Icon name="coinlionlogo"/>
      </h1>

      <h2 className="SinglePanel-title">Important Trading and Account Information</h2>

      <p>
        <strong>$1,000 Minimum to Auto-Trade</strong><br></br>
        There are no minimums for deposits/withdrawals, or manual trades, but the CoinLion Auto-Trader isn't unlocked
        until you have a minimum balance equivalent to $1,000 USD in your account. This ensures your account has enough
        funds to make trades.
      </p><br></br>

      <p>
        <strong>Your Funds are Secure with CoinLion</strong><br></br>
        Being a U.S.-based exchange, we require every user to go through a Know Your Customer (KYC) process. This keeps
        bad actors off our platform. This process includes live selfies that you'll use whenever withdrawing funds,
        protecting fraudulent withdrawals. And finally, CoinLion always holds AT LEAST a 1-to-1 ratio of user assets.
      </p>

      <p>
        <Button
          className="CreateAccountDisclaimer-continue"
          onClick={() => history.push(APP_PATHS.CREATE_ACCOUNT_SIGNUP)}
          size={ButtonSizes.Large}
        >
          Continue to Account Creation
        </Button>
      </p>

      <p>
        <Button
          onClick={() => window.location.href = 'https://coinlion.com'}
          size={ButtonSizes.Large}
          variant={ButtonVariants.Blank}
        >
          Cancel
        </Button>
      </p>
    </div>
  );
};

export default CreateAccountDisclaimer;
