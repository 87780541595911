import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import clone from 'clone';
import Select from '@pv3-shared-components/Select'
import Input from '@pv3-shared-components/Input'
import InputError from '@pv3-shared-components/InputError'
import Tooltip from '@pv3-shared-components/Tooltip'
import './myportfoliocard.scss'
import { map, get, sortBy } from 'lodash'
import Button from '@pv3-shared-components/Button'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import { APP_PATHS } from '@pv3-constants'
import PropTypes from 'prop-types'
import { formatISO } from 'date-fns'
import useModal from "../../hooks/useModal";
import Modal from "../shared/Modal";
import useAssetsDuck from "../../hooks/useAssetsDuck";
import { isArrayNullOrEmpty } from "../../utils/utils";

const MyPortfolioCard = ({ isDeleted, isEditing, isPublic }) => {
  const {
    register,
    formState: { errors, isDirty },
    watch,
  } = useFormContext()
  const history = useHistory()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [isInitiatingABuy, setIsInitiatingABuy] = useState(false);
  const { deleteStrategy, createStrategy, getStrategies, getBuilder, initiateManualBuyForStrategy } =
    useStrategiesDuck()
  const { strategyId } = useParams()
  const { assets } = useAssetsDuck();

  const values = watch()

  const confirmBuySignalModal = useModal();

  const strategyBaseAssets = useMemo(
    () => {
      if(isArrayNullOrEmpty(assets)) {
        return [];
      }

      let clonedAssets = clone(assets);
      let baseAssets = clonedAssets.filter((asset) => asset.is_autotradable && !asset.is_disabled);
      baseAssets.forEach((asset) => {
        asset.label = `${asset.asset_name} (${asset.id.toUpperCase()})`;
        asset.value = asset.id;
      })
      return sortBy(baseAssets, 'label');
    }, [assets]
  )

  const handleInitiateABuy = async (push) => {
    try {
      setIsInitiatingABuy(true);

      await initiateManualBuyForStrategy({strategyId});

      push('SUCCESS');
    } catch(err) {
      console.log('initiate a buy error', err);
    } finally {
      setIsInitiatingABuy(false);
    }
  }

  const handleStrategyDelete = async () => {
    try {
      setIsDeleting(true)

      await deleteStrategy(strategyId)

      history.push(APP_PATHS.BASE, { scrollToStrategies: true })
    } finally {
      setIsDeleting(false)
    }
  }

  const handleStrategyCopy = async () => {
    try {
      setIsCopying(true)

      const result = await createStrategy({
        created: formatISO(new Date()),
        updated: formatISO(new Date()),
        ...values,
        isBuyActive: false,
        isSellActive: false,
        isPublic: false,
        name: `Copy - ${values.name}`,
      })
      const id = get(result, 'payload.id')

      getBuilder()
      await getStrategies()

      history.push(`/strategies/${id}/details`, { viewingNewlyCopiedStrategy: true })
    } catch (e) {
      console.log(e)
    } finally {
      setIsCopying(false)
    }
  }

  return (
    <div className="MyPortfolioCard">
      <div className="MyPortfolioCard-details">
        <Input
          id="strategy_name_input"
          data-testid="strategy_name_input"
          className={`Input Input--large ${errors.name ? 'Input--error' : ''}`}
          type="text"
          error={errors.name && <InputError message={errors.name.message} />}
          placeholder="Name your strategy"
          modifierClass="Label--large"
          name="name"
          registerConfig={{
            required: 'Strategy name is required',
          }}
        >
          Strategy name
        </Input>
        <label className="Label">
          Asset to trade
          <Tooltip modalTitle="Asset to trade">
            This is the crypto asset you want to buy or sell automatically with
            this strategy.
          </Tooltip>
          <Select
            id="asset_to_trade_list"
            ariaLabel="Select asset"
            className={`${errors.asset ? 'Input--error' : ''}`}
            name="baseAsset"
            register={{
              ...register('baseAsset', { required: 'Base Asset is required' }),
            }}
            isForm={true}
          >
            <option disabled value="">
              Select asset…
            </option>
            {map(strategyBaseAssets, ({ label, value }) => (
              <option id={`${value}_asset_option`} key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          {errors.asset && <InputError message={errors.asset.message} />}
        </label>
        <label className="SinglePanel-checkbox">
          <input
            id="enable_buying_box"
            type="checkbox"
            {...register('isBuyActive')}
          />
          Enable buying
          <Tooltip modalTitle="Enable buying on creation">
            When checked, buying is enabled for this strategy. Important note:
            if you are using pause/resume buy signals in this automated
            strategy, those signal settings will override your manual selections
            here. As an example, if you turn this off manually by unchecking it,
            it could automatically get flipped back on through the settings
            below.
          </Tooltip>
        </label>
        <label className="SinglePanel-checkbox">
          <input
            id="enable_selling_box"
            type="checkbox"
            {...register('isSellActive')}
          />
          Enable selling
          <Tooltip modalTitle="Enable selling on creation">
            When checked, selling is enabled for this strategy. Important note:
            if you are using pause or resume sell signals, or the "Stop Loss"
            field, those signal settings will override your manual selections
            here. As an example, if you turn this off manually, it could
            automatically turn back on through the settings below. A stop loss
            will still trigger even if this setting is off.
          </Tooltip>
        </label>
        <label className="SinglePanel-checkbox">
          <input
            id="make_public_box"
            type="checkbox"
            {...register('isPublic')}
          />
          Make strategy public
          <Tooltip modalTitle="Make strategy public">
            If you check this field on, other users will be able to start
            following your Public Strategies. Use this field with caution as
            changes you make within your own strategies will impact your
            followers.
          </Tooltip>
        </label>
      </div>
      {isEditing && (
        <section className="MyPortfolioCard-manage">
          <h1 className="h6">Manage</h1>
          <Button
            disabled={isDeleted}
            size="small"
            variant="quinary"
            type="button"
            isLoading={isDeleting}
            data-testid="delete-strategy_btn"
            onClick={() => {
              if (
                window.confirm('Are you sure you want to delete this strategy?')
              ) {
                handleStrategyDelete()
              }
            }}
          >
            Delete
          </Button>
          <Button
            size="small"
            variant="quinary"
            type="button"
            isLoading={isCopying}
            data-testid="copy-strategy_btn"
            onClick={() => {
              if (isDirty) {
                return window.alert(
                  'Save or discard changes before copying a strategy.'
                )
              }

              if (
                window.confirm(
                  'Are you sure that you want to create a Copy of the current strategy that you have open?'
                )
              ) {
                handleStrategyCopy()
              }
            }}
          >
            Copy
          </Button>
          <Button
            disabled={isDeleted}
            size="small"
            variant="quinary"
            type="button"
            data-testid="initiate-buy_btn"
            onClick={() => confirmBuySignalModal.setShow(true)}
          >
            Initiate Buy Signal
          </Button>
        </section>
      )}

      <Modal
        modal={confirmBuySignalModal}
        screens={{
          MAIN: {
            heading: () => <h4>Initiate Buy Signal</h4>,
            body: (push) => (
              <React.Fragment>
                <p>You are going to open a Position at current market price for this strategy{isPublic ? ' and all subscribers' : ''}.  Are you sure?</p>

                <div className="InitiateBuySignalModal-buttonContainer">
                  <Button
                    isLoading={isInitiatingABuy}
                    variant="primary"
                    size="large"
                    type="button"
                    onClick={() => handleInitiateABuy(push)}
                  >
                    Initiate
                  </Button>

                  <Button
                    disabled={isInitiatingABuy}
                    variant="secondary"
                    size="large"
                    type="button"
                    onClick={() => confirmBuySignalModal.setShow(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </React.Fragment>
            ),
          },
          SUCCESS: {
            heading: () => <h4>Initiate Buy Signal</h4>,
            body: () => (
              <React.Fragment>
                <div className="ConfirmInitiateBuy">
                  <p>We are attempting to open a position for this strategy{isPublic ? ' and all subscribers' : ''}, please check your open positions and come back to your strategy if you want to open more.</p>
                  <Button
                    disabled={isInitiatingABuy}
                    size="large"
                    type="button"
                    variant="primary"
                    onClick={() => confirmBuySignalModal.setShow(false)}
                  >
                    OK
                  </Button>
                </div>
              </React.Fragment>
            ),
          },
        }}
      />
    </div>
  )
}

MyPortfolioCard.propTypes = {
  isEditing: PropTypes.bool,
}

export default MyPortfolioCard
