import './flash.scss';

import * as React from 'react';

import Icon from '../Icons';

type Props = {
  children: React.ReactNode
  className?: string
  danger?: boolean
  hideCloseButton?: boolean
  iconFill?: string
  iconSize?: number
  onDismiss?: () => void
}

const Flash:React.FC<Props> = ({
  children,
  className,
  danger,
  hideCloseButton = false,
  iconFill = '#fff',
  iconSize = 32,
  onDismiss,
  ...rest
}) => {
  const modifierClass = (() => {
    let s = '';
    if (danger) s += 'Flash--danger';
    return s;
  })();

  return (
    <div
      className={`Flash ${modifierClass} ${className}`}
      {...rest}
    >
      <Icon
        name="checkmark"
        height={iconSize}
        width={iconSize}
        fill={iconFill}
      />

      <strong>{children}</strong>

      {!hideCloseButton && (
        <button
          type="button"
          className="u-buttonReset Flash-close"
          onClick={onDismiss}
        >
          <Icon name="x" />
        </button>
      )}
    </div>
  )
}

export default Flash;
