import './rewardsHodlHistory.scss';

import * as React from 'react';
import { useMemo, useState } from "react";
import * as moment from 'moment';
import { isNil, map, sortBy } from "lodash";
import { CSVLink } from "react-csv";
import { format } from "date-fns";

import { isArrayNullOrEmpty } from '../../../../../../../utils/utils';

import { useTypedSelector } from "../../../../../../../ducks";
import useWindowWidth from '../../../../../../../hooks/useWindowWidth';

import Datepicker from '../../../../../../shared/Datepicker';
import Icon from '../../../../../../shared/Icons';
import Pagination from "../../../../../../shared/Pagination";
import Select, { SelectOption } from "../../../../../../shared/Select";
import HodlEnrollment from "../../../../../../../types/HodlEnrollment";

const PAGE_SIZE = 8;

const filterForFundsSourceOptions: Array<SelectOption> = [
  {
    label: 'All Sources',
    value: ''
  }, {
    label: 'My Funds',
    value: 'account',
  }, {
    label: 'Rewards',
    value: 'rewards',
  },
];

const filterForStatusOptions: Array<SelectOption> = [
  {
    label: 'All Statuses',
    value: ''
  }, {
    label: 'Active',
    value: 'active',
  }, {
    label: 'Claimed',
    value: 'claimed',
  }, {
    label: 'Forfeited',
    value: 'forfeited',
  },
];

const sortByOptions: Array<SelectOption> = [
  {
    label: 'Sort',
    value: ''
  }, {
    label: 'Amount Asc',
    value: 'lionQuantity_asc',
  }, {
    label: 'Amount Desc',
    value: 'lionQuantity_desc',
  }, {
    label: 'Start Date Asc',
    value: 'startDate_asc',
  }, {
    label: 'Start Date Desc',
    value: 'startDate_desc',
  },
];

const RewardsHodlHistory: React.FC = () => {
  const isBig = useWindowWidth();

  const [filterForFundsSource, setFilterForFundsSource] = useState<string>('');
  const [filterForStatus, setFilterForStatus] = useState<string>('');
  const [filterStartDate, setFilterStartDate] = useState<string | undefined>();
  const [filterEndDate, setFilterEndDate] = useState<string | undefined>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortByOption, setSortByOption] = useState<string>('');

  const { enrollments, programs } = useTypedSelector((state) => state.rewards);

  const renderProgramName = (programId: string) => {
    const program = programs.find((program) => program.id === programId);

    if (!program) {
      return '--';
    }

    return program.value1 ? `${program.value1} Month` : program.name;
  };

  const renderStatus = (enrollment: HodlEnrollment) => {
    if(enrollment.isActive) {
      return 'Active';
    } else if(enrollment.completedDate) {
      return 'Claimed';
    } else if(enrollment.forfeitedDate) {
      return 'Forfeited';
    }

    return '--';
  }

  const filteredEnrollments = useMemo(
      () => {
        if(isArrayNullOrEmpty(enrollments)) {
          return [];
        }

        return enrollments.filter((enrollment) => !enrollment.parentId);
      }, [enrollments]
  );

  const enrollmentsToDisplay = useMemo(
    () => {
      let enrollmentsList = filteredEnrollments;

      if(filterForStatus) {
        enrollmentsList = enrollmentsList.filter((enrollment) => {
          if(filterForStatus === 'active') {
            return enrollment.isActive;
          } else if(filterForStatus === 'claimed') {
            return !isNil(enrollment.completedDate);
          } else if(filterForStatus === 'forfeited') {
            return !isNil(enrollment.forfeitedDate);
          }

          return true;
        });
      }

      if(filterForFundsSource) {
        enrollmentsList = enrollmentsList.filter((enrollment) => {
          if(filterForFundsSource === 'account') {
            return isNil(enrollment.rewardId);
          } else if(filterForFundsSource === 'rewards') {
            return !isNil(enrollment.rewardId);
          }

          return true;
        });
      }

      if(filterStartDate && filterEndDate) {
        enrollmentsList = enrollmentsList.filter((enrollment) => {
          return moment(enrollment.startDate).isBetween(moment(filterStartDate), moment(filterEndDate));
        });
      }

      if(sortByOption) {
        let sortSettings = sortByOption.split('_');
        enrollmentsList = sortBy(enrollmentsList, sortSettings[0]);
        if(sortSettings[1] === 'desc') {
          enrollmentsList = enrollmentsList.reverse();
        }
      }

      return enrollmentsList;
    }, [filteredEnrollments, filterForFundsSource, filterForStatus, filterEndDate, filterStartDate, sortByOption]
  );

  const pageOfData = useMemo(
    () => {
      if(isArrayNullOrEmpty(enrollmentsToDisplay)) {
        return [];
      }

      return enrollmentsToDisplay.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    }, [enrollmentsToDisplay, pageNumber]
  );

  const reportCsvData = useMemo(
    () => {
      return map(enrollmentsToDisplay, (row) => {
        return {
          StartDate: moment(row.startDate).format('M/DD/YY h:mm A'),
          MatureDate: moment(row.matureDate).format('M/DD/YY h:mm A'),
          Source: row.rewardId ? 'Reward' : 'My Funds',
          Program: renderProgramName(row.programId),
          AmountLION: row.lionQuantity.toLocaleString(),
          Rewards: row.totalRewardsLion ? row.totalRewardsLion.toLocaleString() : '--',
        };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrollmentsToDisplay]
  );

  return (
    <section className="HodlHistory">
      <header className="HodlHistory-FiltersContainer">
        <h4>
          HODL History
        </h4>

        <p>
          List for all your HODL related activity.
        </p>

        <div className="HodlHistory-Filters">
          {/* @ts-ignore */}
          <Datepicker
              endDate={filterEndDate}
              maxDate={new Date()}
              placeholderText="Filter by Start Date"
              startDate={filterStartDate}
              selectsRange
              onChange={(dates) => {
                setFilterStartDate(dates[0]);
                setFilterEndDate(dates[1]);
              }}
              isClearable
          />

          <Select
              onChange={(e) => setFilterForFundsSource(e.target.value)}
          >
            {filterForFundsSourceOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
            ))}
          </Select>

          <Select
              onChange={(e) => setFilterForStatus(e.target.value)}
          >
            {filterForStatusOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
            ))}
          </Select>

          <Select
              onChange={(e) => setSortByOption(e.target.value)}
          >
            {sortByOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
            ))}
          </Select>

          <CSVLink
            data={reportCsvData}
            className="HodlHistory-DownloadReport"
            filename={`HodlHistory_${format(
              new Date(),
              'MM-dd-yy'
            )}.csv`}
          >
            <Icon name="arrowright" /> Download Report
          </CSVLink>
        </div>
      </header>

      {isBig ? (
        <div className="HodlHistory-DesktopListContainer">
          <div className="HodlHistory-DesktopListHeader">
            <div className="HodlHistory-DesktopList-Column">
              Start Date
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Mature Date
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Source
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Program
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Status
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Amount LION
            </div>

            <div className="HodlHistory-DesktopList-Column">
              Rewards
            </div>
          </div>

          <ul className="HodlHistory-DesktopList">
            {pageOfData.map((enrollment) => (
              <li className="HodlHistory-DesktopList-Item" key={enrollment.id}>
                <div className="HodlHistory-DesktopList-Column">
                  {moment(enrollment.startDate).format('M/DD/YY h:mm A')}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {moment(enrollment.matureDate).format('M/DD/YY h:mm A')}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {enrollment.rewardId ? 'Reward' : 'My Funds'}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {renderProgramName(enrollment.programId)}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {renderStatus(enrollment)}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {enrollment.lionQuantity.toLocaleString()}
                </div>

                <div className="HodlHistory-DesktopList-Column">
                  {enrollment.totalRewardsLion ? enrollment.totalRewardsLion.toLocaleString() : '--'}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <ul className="HodlHistory-MobileList">
          {pageOfData.map((enrollment) => (
            <li className="HodlHistory-MobileList-Item">
              <div className="HodlHistory-MobileList-Column">
                <div className="HodlHistory-MobileList-Value">
                  {enrollment.rewardId ? 'Reward' : 'My Funds'}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Source
                </div>

                <div className="HodlHistory-MobileList-Value">
                  {renderProgramName(enrollment.programId)}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Program
                </div>

                <div className="HodlHistory-MobileList-Value">
                  {renderStatus(enrollment)}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Status
                </div>

                <div className="HodlHistory-MobileList-Value">
                  {enrollment.lionQuantity.toLocaleString()} LION
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Amount LION
                </div>
              </div>

              <div className="HodlHistory-MobileList-Column">
                <div className="HodlHistory-MobileList-Value">
                  {moment(enrollment.startDate).format('M/DD/YY h:mm A')}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Date
                </div>

                <div className="HodlHistory-MobileList-Value">
                  {moment(enrollment.matureDate).format('M/DD/YY h:mm A')}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Mature Date
                </div>

                <div className="HodlHistory-MobileList-Value">
                  {enrollment.totalRewardsLion ? enrollment.totalRewardsLion.toLocaleString() : '--'}
                </div>

                <div className="HodlHistory-MobileList-Label">
                  Rewards Earned
                </div>

                <div className="HodlHistory-MobileList-Value">
                  &nbsp;
                </div>

                <div className="HodlHistory-MobileList-Label">
                  &nbsp;
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {filteredEnrollments.length > PAGE_SIZE && (
        <Pagination
          forcePage={pageNumber}
          onPageChange={({selected}) => setPageNumber(selected)}
          pageCount={enrollmentsToDisplay.length / PAGE_SIZE}
        />
      )}
    </section>
  );
};

export default RewardsHodlHistory;
