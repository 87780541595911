import * as React from 'react';

import TokenBalancer from '../../../TokenBalancer';

const RewardsCLTB: React.FC = () => {
    return (
      <div className="Content Content--cltb">
        <h2 className="rewards-title">
          LION Token Balancer
        </h2>

        <TokenBalancer />
      </div>
    );
};

export default RewardsCLTB;
