import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { APP_PATHS } from '@pv3-constants'
import { useAuth0 } from '@auth0/auth0-react'

const useAuthorizedRedirect = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) history.replace(APP_PATHS.BASE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
}

export default useAuthorizedRedirect
