import * as React from 'react';
import {
  Control,
  get,
  useFormContext,
} from 'react-hook-form';
import { isNull, isUndefined } from 'lodash';

import InputError from '../InputError';

export enum InputModes {
  Decimal = 'decimal',
}

export enum InputTypes {
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Tel = 'tel',
  Text = 'text',
  Textarea = 'textarea'
}

type Props = {
  calculatedTotal?: string
  calculatedTotalSuffix?: string
  children?: React.ReactNode
  className?: string
  control?: Control                  // not currently used anywhere
  disabled?: boolean
  error?: React.ReactNode
  helperText?: React.ReactNode
  id?: string
  inputMode?: InputModes
  inputType?: InputTypes
  modifierClass?: string
  monthFirst?: boolean
  name?: string
  onBlur?: () => void
  register?: any
  registerConfig?: any //RegisterOptions
  step?: string
  suffix?: string
  triggerValidation?: boolean
  wrapperTag?: string
}

const Input:React.FC<Props> = ({
  calculatedTotal,
  calculatedTotalSuffix,
  children,
  className,
  control,
  error,
  helperText,
  inputType,
  modifierClass,
  monthFirst,
  name,
  register,
  registerConfig,
  suffix,
  triggerValidation,
  wrapperTag,
  ...rest
}) => {
  const formContext = useFormContext();

  const InputGroup = suffix ? 'div' : React.Fragment;
  const WrapperTag = wrapperTag ? wrapperTag as React.ElementType : 'label';

  let contextRegister;
  let errors;

  if (!isNull(formContext)) {
    contextRegister = get(formContext, 'register');
    errors = get(formContext, 'formState.errors');
  }

  const finalRegister = !isUndefined(contextRegister)
    ? contextRegister(name, registerConfig)
    : register;

  const finalError =
    isUndefined(error) && !isUndefined(name) && !isUndefined(errors)
      ? errors[name] && <InputError message={errors[name].message} />
      : error;

  const onChange = (e: any) => {
    finalRegister.onChange(e);

    if(triggerValidation) {
      formContext.trigger(name);
    }
  }

  const renderInput = () => {
    switch(inputType) {
      case InputTypes.Textarea:
        return (
          <textarea
            className={`Input ${isUndefined(className) ? '' : className}`}
            name={name}
            {...finalRegister}
            onChange={onChange}
            {...rest}
          />
        );

      default:
        return (
          <input
            className={`Input ${isUndefined(className) ? '' : className}`}
            type={inputType ? inputType : InputTypes.Text}
            name={name}
            {...finalRegister}
            onChange={onChange}
            {...rest}
          />
        );
    }
  }

  return (
    <WrapperTag className={`Label ${modifierClass || ''}`}>
      {children}

      <InputGroup {...(suffix ? { className: 'Input-suffixGroup' } : {})}>
        {renderInput()}

        {suffix && <span className="Input-suffix">{suffix}</span>}
      </InputGroup>

      {calculatedTotal && (
        <div className="Input-calculatedTotal">
          {calculatedTotal}

          {calculatedTotalSuffix && (
            <span className="Input-calculatedTotal-suffix">
              {calculatedTotalSuffix}
            </span>
          )}
        </div>
      )}

      {helperText && <em>{helperText}</em>}

      {finalError}
    </WrapperTag>
  )
}

export default Input
