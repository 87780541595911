import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Auth from '../components/Auth/Auth0'
import { API_PATHS } from '@pv3-constants'
import Request from '../utils/request'
import { errorFromAxios, errorFromAuth0 } from './mappers'

const auth0 = new Auth()
const request = new Request()

export const createAuth0Account = createAsyncThunk(
  'session/createAuth0Account',
  async ({ email, password, country, region }, { rejectWithValue }) => {
    try {
      const response = await auth0.signup(email, password, {
        countryCode: country,
        stateProvinceCode: region,
      })

      return response
    } catch (error) {
      return rejectWithValue(errorFromAuth0(error))
    }
  }
)

export const signInCoin = createAsyncThunk(
  'session/signInCoin',
  async ({ accessToken, idToken, expiresAt }, { rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.SIGN_IN, {
        accessToken,
        expiresAt,
        idToken,
      })

      return response.data
    } catch (error) {
      return rejectWithValue(errorFromAxios(error))
    }
  }
)

export const signOutCoin = createAsyncThunk(
  'session/signOutCoin',
  async (_, { rejectWithValue }) => {
    try {
      await request.post(API_PATHS.COIN.SIGN_OUT)
    } catch (error) {
      return rejectWithValue(errorFromAxios(error))
    }
  }
)

export const initialState = {
  clients: {
    coin: {},
  },
  errors: [],
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    addError: (state, action) => {
      state.errors = [...state.errors, action.payload]
    },
    clearErrors: (state, action) => {
      state.errors = []
    },
  },
  extraReducers: {
    [createAuth0Account.rejected]: (state, action) => {
      state.errors = [action.payload, ...state.errors]
    },
    [createAuth0Account.fulfilled]: (state, action) => {
      state.clients.auth0 = action.payload
    },
    [signInCoin.fulfilled]: (state, action) => {
      state.clients.coin.userId = action.payload.userId
    },
  },
})

export const { addError, clearErrors } = sessionSlice.actions

export default sessionSlice.reducer
