import React from 'react'

const ExclamationCircle = ({ width = 49, height = 48, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 8C15.6634 8 8.5 15.1634 8.5 24C8.5 32.8366 15.6634 40 24.5 40C33.3366 40 40.5 32.8366 40.5 24C40.5 19.7565 38.8143 15.6869 35.8137 12.6863C32.8131 9.68571 28.7435 8 24.5 8ZM22.5133 16C22.5133 14.8954 23.4088 14 24.5133 14C25.6179 14 26.5133 14.8954 26.5133 16V25.1867C26.5133 26.2912 25.6179 27.1867 24.5133 27.1867C23.4088 27.1867 22.5133 26.2912 22.5133 25.1867V16ZM22.2067 31.7067C22.2067 32.9732 23.2334 34 24.5 34C25.7666 34 26.7933 32.9732 26.7933 31.7067C26.7933 30.4401 25.7666 29.4133 24.5 29.4133C23.2334 29.4133 22.2067 30.4401 22.2067 31.7067Z"
        fill="#E00043"
      />
    </svg>
  )
}

export default ExclamationCircle
