import React from 'react'

const CaretRight = ({
  fill = '#737373',
  width = '24',
  height = '24',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.631 6.243L16 12.003l-5.369 5.76a.714.714 0 01-1.083-.023.918.918 0 01.02-1.203l4.23-4.534-4.23-4.54a.918.918 0 01-.02-1.203.714.714 0 011.083-.022v.005z"
        fill={fill}
      />
    </svg>
  )
}

export default CaretRight
