import './myReferrals.scss';

import * as React from 'react';
import { useMemo } from "react";
import { sortBy } from "lodash";

import { isArrayNullOrEmpty } from '../../../../../utils/utils';
import { ReferralRewardActionSubPrograms } from "../../../../../utils/enums";

import { useTypedSelector } from "../../../../../ducks";

import MyReferralsInfo from "./components/MyReferralsInfo";
import MyReferralsList from "./components/MyReferralsList";
import useDocTitle from '../../../../../hooks/useDocTitle';

const MyReferrals: React.FC = () => {
  useDocTitle('My Referrals');

  const { referralRewardActions } = useTypedSelector((state) => state.rewards);

  const levels = useMemo(
    () => {
      if(isArrayNullOrEmpty(referralRewardActions)) {
        return {
          invitedLevels: [],
          publisherLevels: [],
        };
      }

      let levels = referralRewardActions.filter((action) => action.subProgram === ReferralRewardActionSubPrograms.ExistingUser);
      levels = sortBy(levels, 'code');

      const invitedLevels = levels.slice(0, 4);
      const publisherLevels = levels.slice(4, 8);

      return {
        invitedLevels,
        publisherLevels,
      };
    }, [referralRewardActions]
  );

  return (
    <div className="Referrals-Content Referrals-Content--myReferrals">

      <MyReferralsInfo
        levels={levels}
      />

      <MyReferralsList
        levels={levels}
      />
    </div>
  );
};

export default MyReferrals;
