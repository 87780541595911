import './pagination.scss';

import * as React from 'react';
import ReactPaginate from "react-paginate";

import Icon from '../Icons';

type Props = {
  breakLabel?: string
  className?: string
  forcePage?: number
  nextLabel?: string
  onPageChange: (page: { selected: number }) => void
  pageRangeDisplayed?: number
  pageCount: number
  previousLabel?: string
  renderOnZeroPageCount?: boolean
}

const Pagination: React.FC<Props> = ({
  breakLabel = '...',
  className,
  forcePage,
  nextLabel = 'Next',
  onPageChange,
  pageRangeDisplayed = 3,
  pageCount,
  previousLabel = 'Prev',
}) => {
  return (
    <div className={`Pagination ${className ? className : ''}`}>
      <ReactPaginate
        breakLabel={breakLabel}
        forcePage={forcePage}
        marginPagesDisplayed={1}
        nextLabel={(
          <div>
            {nextLabel}
            <Icon name="caretright" />
          </div>
        )}
        onPageChange={onPageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        previousLabel={(
          <div>
            <Icon name="caretright" />
            {previousLabel}
          </div>
        )}
      />
    </div>
  );
};

export default Pagination;
