import 'react-datepicker/dist/react-datepicker.css';

import React from 'react'
import ReactDOM from 'react-dom'
import store from '@pv3-ducks'
import { isNil } from 'lodash'
import TagManager from 'react-gtm-module';
import {Provider} from "react-redux";
import App from "./components/App";
import { BrowserRouter as Router, Route } from 'react-router-dom';

if(window.envConfig.COINLION_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: window.envConfig.COINLION_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

const rootEl = document.getElementById('root')

if (!isNil(rootEl)) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </Provider>
    </React.StrictMode>,
    rootEl
  )
}
