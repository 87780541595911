import './rewardsHeader.scss';

import * as React from 'react';
import { isNil } from "lodash";

import { APP_PATHS } from "../../../../constants";

import useModal from "../../../../hooks/useModal";
import { useTypedSelector } from "../../../../ducks";

import Button, { ButtonVariants } from "../../../shared/Button";
import BuySellModal from "../../../BuySellModal";
import HeroBanner from "../../../shared/HeroBanner";

const RewardsHeader: React.FC = () => {
  const modal = useModal({defaultScreen: 'BUY_SETUP'});

  const { userTotals } = useTypedSelector((state) => state.rewards);

  return (
    <header className="rewardsHeader">
      <HeroBanner
        headline="Rewards"
        subnav={[
          {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_HODL),
            label: 'HODL',
            path: APP_PATHS.REWARDS_HODL_OVERVIEW,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_REFERRALS),
            label: 'Referrals',
            path: APP_PATHS.REWARDS_REFERRALS_OVERVIEW,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_CLTB),
            label: 'LION Token Balancer',
            path: APP_PATHS.REWARDS_CLTB,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_DISCOUNTS),
            label: 'Trading Fees',
            path: APP_PATHS.REWARDS_DISCOUNTS_OVERVIEW,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_VOTING),
            label: 'Voting',
            path: APP_PATHS.REWARDS_VOTING,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_LIQUIDATOR),
            label: 'Liquidator',
            path: APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW,
          }, {
            isActive: (path) => path.includes(APP_PATHS.REWARDS_AGGREGATOR),
            label: 'Aggregator',
            path: APP_PATHS.REWARDS_AGGREGATOR,
          }
        ]}
      >
        {!isNil(userTotals.lionHodlLocked) && !isNil(userTotals.totalLionBalance) && (
          <div className="rewardsHeader-LionContainer">
            <div>
              <h2>
                {userTotals.totalLionBalance.toLocaleString()} LION
              </h2>

              <div className="rewardsHeader-LionContainer-amount">
                {userTotals.lionHodlLocked.toLocaleString()} in HODL
              </div>
            </div>

            <Button
              onClick={() => modal.setShow(true)}
              variant={ButtonVariants.Secondary}
            >
              Buy LION
            </Button>
          </div>
        )}
      </HeroBanner>

      <BuySellModal modal={modal} assetId="lion" />
    </header>
  );
};

export default RewardsHeader;
