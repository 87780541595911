import './strategyBacktestHistory.scss';
import React, {useMemo, useRef} from 'react';
import useClickAway from '@pv3-hooks/useClickAway'
import useStrategiesDuck from "../../hooks/useStrategiesDuck";
import Icon from "../shared/Icons";
import moment from 'moment';
import Table from "../shared/Table";
import { BACKTEST_DATE_FORMAT } from "../StrategyBacktest";
import Pin from "../shared/Icons/Pin";
import Button from "../shared/Button";
import useUiDuck from "../../hooks/useUiDuck";
import {LOADING_STATES} from "../../constants";

const StrategyBacktestHistory = ({onDismiss, setStrategy}) => {
  const { backtestHistory, pinBacktestHistoryItem, setBacktestEndDate, setBacktestStartDate, setBacktestSummaryFromHistory, setTemporaryStrategySettings, unpinBacktestHistoryItem } = useStrategiesDuck();
  const { loading } = useUiDuck();

  const ref = useRef();

  useClickAway([ref], () => onDismiss());

  const handlePinClick = (row) => {
    if(row.isSaved) {
      unpinBacktestHistoryItem({historyId: row.id, strategyId: row.strategyId});
    } else {
      pinBacktestHistoryItem({historyId: row.id, strategyId: row.strategyId});
    }
  };

  const handleRowClick = (row) => {
    setTemporaryStrategySettings(row.settings);
    setStrategy(row.settings);
    if(row.startDate) {
      setBacktestStartDate(moment(row.startDate.split('+')[0]).format(BACKTEST_DATE_FORMAT));
    }
    if(row.endDate) {
      setBacktestEndDate(moment(row.endDate.split('+')[0]).format(BACKTEST_DATE_FORMAT));
    }
    setBacktestSummaryFromHistory(row);
  };

  const columns = useMemo(
    () => {
      return [{
        Header: 'Date/Time',
        id: 'created',
        className: 'u-tu',
        accessor: (d) => d.created ? moment(d.created).format('M/D/YYYY h:mmA') : '--',
        width: 135,
        sortable: false,
      }, {
        Header: 'Qty',
        id: 'positionsOpened',
        className: 'u-tu u-justifyend',
        headerClassName: 'u-tr',
        accessor: (d) => d.summary?.positionsOpened ? d.summary.positionsOpened : '--',
        width: 40,
        sortable: false,
      }, {
        Header: 'ROI',
        id: 'roi',
        className: 'u-tu u-justifyend',
        headerClassName: 'u-tr',
        accessor: (d) => d.summary?.roiPercent ? d.summary.roiPercent.toFixed(2) + '%' : '--',
        width: 65,
        sortable: false,
      }, {
        Header: 'Avg',
        id: 'avg',
        className: 'u-tu u-justifyend',
        headerClassName: 'u-tr',
        accessor: (d) => d.summary?.averagePercentPerTrade ? d.summary.averagePercentPerTrade.toFixed(2) + '%' : '--',
        width: 70,
        sortable: false,
      }, {
        Header: '',
        id: 'pin',
        className: 'u-tu u-justifyend StrategyBacktestHistory-pin',
        headerClassName: 'u-tr StrategyBacktestHistory-pin',
        Cell: (props) => {
          return (
            <div className={`StrategyBacktestHistory-pin__inner ${props.original.isSaved ? 'StrategyBacktestHistory-pin__saved' : ''} ${loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] ? 'StrategyBacktestHistory-pin__saving' : ''}`}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePinClick(props.original);
                }}
                variant="icon"
              >
                <Pin />
              </Button>
            </div>
          );
        },
        width: 40,
        sortable: false,
      }]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  return (
    <section className="TradeDetail StrategyBacktestHistory" ref={ref}>
      <div className="TradeDetail-closeBar">
        <button
          onClick={onDismiss}
          className="u-buttonReset TradeDetail-closeBar-close"
        >
          <Icon name="x" width="36" height="36" />
        </button>
      </div>

      <div className="TradeDetail-content">
        <h1 className="TradeDetail-headline">
          Prior Backtesting Results
        </h1>
      </div>

      <Table
        columns={columns}
        data={backtestHistory}
        getTdProps={(state, rowInfo) => {
          return {
            onClick: () => {
              handleRowClick(rowInfo.original);
            }
          }
        }}
      />
    </section>
  );
};

export default StrategyBacktestHistory;
