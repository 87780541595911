import React, {useEffect, useMemo, useState} from 'react';
import './strategyBacktest.scss';
import useStrategiesDuck from "../../hooks/useStrategiesDuck";
import {useHistory, useParams} from "react-router-dom";
import Datepicker from "../shared/Datepicker";
import moment from "moment";
import Button from "../shared/Button";
import {LOADING_STATES} from "../../constants";
import Loading from "../shared/Loading";
import useUiDuck from "../../hooks/useUiDuck";
import SummaryPanel from "../shared/SummaryPanel";
import {gt, isEmpty, isNil, lt, map, replace, round, toNumber} from "lodash";
import {fixFloatingPointErrors, formatAsDollarAmount, formatNumber, isArrayNullOrEmpty, isObjectNullOrEmpty, renderTableAge} from "../../utils/utils";
import Table from "../shared/Table";
import useWindowWidth from "../../hooks/useWindowWidth";
import Logo from "../shared/Logo";
import Currency from "../shared/Currency";
import TooltipLight from "../shared/TooltipLight";
import useProfileDuck from "../../hooks/useProfileDuck";
import useWalletsDuck from "../../hooks/useWalletsDuck";
import {CSVLink} from "react-csv";
import {format} from "date-fns";
import useModal from "../../hooks/useModal";
import Modal from "../shared/Modal";
import Toggle from "../shared/Toggle";
import StrategyBacktestHistory from "../StrategyBacktestHistory";

export const BACKTEST_DATE_FORMAT = 'MM/DD/YYYY';

const SIGNAL_TYPE_LABELS = {
  TriggerBuy: 'Buy',
  TriggerSell: 'Sell',
  StopLoss: 'Sell',
  ToggleBuyEnabled: 'Buying Enabled',
  ToggleBuyDisabled: 'Buying Disabled',
  ToggleSellEnabled: 'Selling Enabled',
  ToggleSellDisabled: 'Selling Disabled',
}

const StrategyBacktest = () => {
  const { strategyId } = useParams();
  const { backtestData, backtestError, backtestHistory, backtestStartDate, backtestEndDate, backtestSummary: { actualMarketValue, averageLoss, averageMinutesPerLoss, averageLossPercent, averageProfit, averageMinutesPerWin, averageProfitPercent, fromHistory: backtestSummaryIsFromHistory, grossProfitLoss, lowestLossPercent, highestLossPercent, lossRate, quantityOfLosses, openPositionsProfit, openPositionsProfitPercent, openPositionsQuantity, roiPercent, lowestProfitPercent, highestProfitPercent, startingBalance, totalLosses, totalProfits, winRate, quantityOfWins, averagePercentPerTrade, }, cancelBacktest, clearBacktestError, clearBacktestData, getActualMarketPerformance, getBacktestHistoryForStrategy, getIndicators, indicators, numerifyStrategySettings, runStrategyBacktest, setBacktestStartDate, setBacktestEndDate, setBacktestSummary, strategies, structureTriggerFieldValues, temporaryStrategySettings } = useStrategiesDuck();
  const { profile: { homeQuoteAsset } } = useProfileDuck();
  const { loading } = useUiDuck();
  const isBig = useWindowWidth(992);
  const { usdPrices } = useWalletsDuck();
  const { totalValueInUSD, walletValuesInUSD: { lion: lionValueInUSD } } = useWalletsDuck();
  const totalValueInUSDMinusLion = totalValueInUSD - (lionValueInUSD || 0);

  const [strategy, setStrategy] = useState({});
  const [hasRunBacktest, setHasRunBacktest] = useState(false);
  const [showAllSignals, setShowAllSignals] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  const allocationModal = useModal({
    onClose: () => history.push(`/strategies/${strategyId}/details`)
  });
  const balanceModal = useModal();
  const backtestErrorModal = useModal();
  const history = useHistory();

  const calculatedStartingBalance = useMemo(
    () => {
      if(isObjectNullOrEmpty(strategy) || totalValueInUSDMinusLion === 0) {
        return 0;
      }

      if(startingBalance && strategy.holdingPercent) {
        return startingBalance * (toNumber(strategy.holdingPercent) / 100);
      }

      return totalValueInUSDMinusLion * (toNumber(strategy.holdingPercent) / 100);
    }, [startingBalance, strategy, totalValueInUSDMinusLion]
  );

  const handleSettingBacktestSummary = async (data) => {
    if(!strategy.baseAsset) {
      return;
    }

    try {
      let actualMarketPerformance = await getActualMarketPerformance({
        market: `${strategy.baseAsset}_${homeQuoteAsset}`,
        startDate: backtestStartDate,
        endDate: backtestEndDate,
      });

      setBacktestSummary({backtestData: data, baseAsset: strategy.baseAsset, usdPrices, actualMarketPerformance});
    } catch(err) {
      console.error('StrategyBacktest handleSettingBacktestSummary err', err);
    }
  };

  const roiPercentage = useMemo(
    () => {
      if(roiPercent) {
        return roiPercent;
      }

      if(isNil(grossProfitLoss) || isNil(calculatedStartingBalance)) {
        return '--';
      }

      return ((grossProfitLoss / calculatedStartingBalance) * 100).toFixed(2)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatedStartingBalance, grossProfitLoss, roiPercent]
  );

  useEffect(() => {
    if(calculatedStartingBalance <= 0 && totalValueInUSD != null && !isNil(strategy.holdingPercent) && strategy.holdingPercent > 0) {
      balanceModal.setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedStartingBalance]);

  useEffect(() => {
    if (isEmpty(indicators)) {
      getIndicators();
    }

    if(isArrayNullOrEmpty(strategies)) {
      return;
    }

    if(!isObjectNullOrEmpty(temporaryStrategySettings)) {
      setStrategy(temporaryStrategySettings);
    } else {
      setStrategy(strategies.find((s) => s.id === strategyId));
    }

    return () => {
      cancelBacktest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategies]);

  useEffect(() => {
    if(isObjectNullOrEmpty(strategy)) {
      return;
    }

    if(isNil(strategy.holdingPercent) || toNumber(strategy.holdingPercent) === 0) {
      allocationModal.setShow(true);
    } else {
      getBacktestHistoryForStrategy({strategyId: strategy.id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy]);

  useEffect(() => {
    if(isObjectNullOrEmpty(backtestError)) {
      return;
    }
    setHasRunBacktest(false);
    backtestErrorModal.setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backtestError]);

  const handleRunBacktest = () => {
    clearBacktestData({preserveStartEndDates: true});
    const data = {
      startingBalance: totalValueInUSDMinusLion,
      startDate: backtestStartDate,
      endDate: backtestEndDate,
      strategySettings: numerifyStrategySettings(strategy),
    }

    runStrategyBacktest(data);
    setHasRunBacktest(true);
  }

  const filteredBacktestData = useMemo(
    () => {
      if(isArrayNullOrEmpty(backtestData)) {
        return [];
      }

      if(showAllSignals) {
        handleSettingBacktestSummary(backtestData);
        return backtestData;
      } else {
        let filtered = backtestData.filter((fa) => /TriggerBuy|TriggerSell|StopLoss/.test(fa.type));
        handleSettingBacktestSummary(filtered);
        return filtered;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backtestData, showAllSignals, strategy]
  );

  const backtestCsvData = useMemo(
    () => {
      let marketId = strategy.baseAsset + '_' + homeQuoteAsset;

      return map(backtestData, (row) => {
        let data = {
          Time: moment(row.generatedAt).format('h:mm a MM/DD/YY'),
          Market: marketId.toUpperCase(),
          Side: row.type === 'TriggerBuy' ? 'Buy' : 'Sell',
          Quantity: formatNumber(round(row.amount, 6)) + ' ' + strategy.baseAsset?.toUpperCase(),
          Price: 0,
          Cost: homeQuoteAsset === 'usd' ? formatAsDollarAmount(row.openPrice * row.amount) : `${fixFloatingPointErrors(row.openPrice * row.amount)} ${homeQuoteAsset.toUpperCase()}`,
          GainLoss: row.type === 'TriggerBuy' ? '--' : (homeQuoteAsset === 'usd' ?  formatAsDollarAmount((row.closePrice - row.openPrice) * row.amount) : `${(row.closePrice - row.openPrice) * row.amount} ${homeQuoteAsset.toUpperCase()}`)
        };

        if(!row.openPrice) {
          data.Price = '--';
        } else if (homeQuoteAsset === 'usd') {
          data.Price = formatAsDollarAmount(row.openPrice);
        } else {
          data.Price = `${round(row.openPrice, 6)} ${homeQuoteAsset}`;
        }

        return data;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backtestData, strategy]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => {
      let marketId = strategy.baseAsset + '_' + homeQuoteAsset;

      return isBig
        ? [
// Desktop columns
          {
            Header: 'Trade Details',
            id: 'tradeDetails',
            className: 'TradeHistory-details StrategyBacktest-details',
            headerClassName: 'StrategyBacktest-details',
            sortable: false,
            accessor: 'generatedAt',
            Cell: ({original}) => {

              let currency = (
                <Logo />
              )
              if (strategy.baseAsset !== 'lion') {
                currency = (
                  <Currency type={strategy.baseAsset} />
                )
              }

              return (
                <React.Fragment>
                  <time dateTime={original.generatedAt}>
                    {moment(original.generatedAt).format('M/DD/YY')}
                    <br />
                    {moment(original.generatedAt).format('h:mm A')}
                  </time>
                  <div className="TradeHistory--iconContainer">
                    {currency}
                    <p className="TradeHistory--iconText">
                      {replace(marketId, '_', '-')}
                    </p>
                  </div>
                  <span>{SIGNAL_TYPE_LABELS[original.type]}</span>
                </React.Fragment>
              )
            },
          },
          {
            Header: 'Age',
            id: 'age',
            className: 'u-tu u-justifyend',
            headerClassName: 'u-tr',
            accessor: 'generatedAt',
            Cell: ({original}) => {
              if(original.type === 'TriggerBuy' || isNil(original.generatedAt) || isNil(original.created) || new Date(original.created).getTime() <= 0) {
                return '--';
              }

              return renderTableAge((new Date(original.generatedAt).getTime() - new Date(original.created).getTime()) / (1000 * 60));
            },
          },
          {
            Header: 'Quantity',
            id: 'quantity',
            className: 'u-tu u-justifyend',
            headerClassName: 'u-tr',
            accessor: 'requestedAmount',
            Cell: ({original}) => {
              if(/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled/.test(original.type)) {
                return '--';
              }

              return (
                <span>
                    {formatNumber(round(original.amount, 6))}{' '}
                  {strategy.baseAsset}
                  </span>
              );
            },
          },
          {
            Header: 'Price',
            id: 'requestedPrice',
            className: 'u-tu u-justifyend',
            headerClassName: 'u-tr',
            Cell: ({original}) => {
              const price = original.closePrice || original.openPrice;

              if(!price) {
                return '--';
              } else if (homeQuoteAsset === 'usd') {
                return formatAsDollarAmount(price)
              } else {
                return `${round(price, 6)} ${homeQuoteAsset}`
              }
            },
          },
          {
            Header: 'Cost',
            id: 'cost',
            className: 'u-tu u-justifyend',
            headerClassName: 'u-tr',
            accessor: 'quoteAmount',
            Cell: ({original}) => {
              if(/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled/.test(original.type)) {
                return '--';
              }

              if (homeQuoteAsset === 'usd') {
                return formatAsDollarAmount(original.openPrice * original.amount)
              } else {
                return `${(original.openPrice * original.amount).toFixed(2)} ${homeQuoteAsset}`
              }
            },
          },
          {
            Header: 'Gain/Loss',
            id: 'gainLoss',
            className: 'u-justifyend',
            headerClassName: 'u-tr',
            accessor: (d) => (d.closePrice - d.openPrice) * d.amount,
            Cell: ({original, value}) => {
              if(/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled|TriggerBuy/.test(original.type)) {
                return '--';
              }

              const quoteCurrency = homeQuoteAsset
              let calculatedGainLoss = value
              let formattedGainLoss =
                quoteCurrency === 'usd'
                  ? formatAsDollarAmount(calculatedGainLoss)
                  : `${calculatedGainLoss.toFixed(2)} ${quoteCurrency.toUpperCase()}`
              if (value > 0) {
                return (
                  <div className="TradeHistory-gain">
                    {formattedGainLoss}
                  </div>
                )
              } else if (value < 0) {
                return (
                  <div className="TradeHistory-loss">
                    {formattedGainLoss}
                  </div>
                )
              }
              return formattedGainLoss
            },
          },
          {
            Header: '%',
            id: 'gainLossPercent',
            className: 'u-tu u-justifyend StrategyBacktest-gainLossPercent',
            headerClassName: 'u-tr StrategyBacktest-gainLossPercent',
            accessor: (d) => (d.closePrice - d.openPrice) * d.amount,
            Cell: ({original, value}) => {
              if(original.type === 'TriggerBuy' || isNil(value) || value === 0 || isNil(original.openPrice) || isNil(original.amount)) {
                return '';
              }

              let cost = original.openPrice * original.amount;

              return ((value / cost) * 100).toFixed(2) + '%';
            },
          },
          {
            Header: '',
            className: 'StrategyBacktest-tooltipContainer',
            id: 'triggers',
            width: 48,
            Cell: ({original}) => {
              if(isArrayNullOrEmpty(original.triggerData) && isNil(original.validationError)) {
                return null;
              }

              let triggers = [];

              if(!isArrayNullOrEmpty(original.triggerData)) {
                triggers = original.triggerData.map((trigger) => {
                  return structureTriggerFieldValues(trigger);
                });
              }

              return (
                <div className="StrategyBacktest-triggerColumn">
                  <TooltipLight modalTitle="Trading currency" top>
                    {!isArrayNullOrEmpty(triggers) && triggers.map((i, index) => (
                      <div>
                        {i.name} - {i.fields.join(', ')}
                      </div>
                    ))}

                    {!isNil(original.validationErrors) && original.validationErrors !== '' && (
                      <div>
                        {original.validationErrors}
                      </div>
                    )}
                  </TooltipLight>
                </div>
              );
            }
          }
        ] : [
          {
            Header: '',
            id: 'icon',
            className: 'MobileCell',
            Cell: ({original}) => {
              let currency = (
                <Logo />
              )
              if (strategy.baseAsset !== 'lion') {
                currency = (
                  <Currency type={strategy.baseAsset} />
                )
              }

              return (
                <React.Fragment>
                  {currency}
                  <div>
                    <div className="u-tu">
                      {replace(marketId, '_', '-')}
                    </div>

                    <div className="MobileCell-small">
                      {/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled/.test(original.type) ? (
                        <React.Fragment>--</React.Fragment>
                        ) : (
                          <React.Fragment>
                            {formatNumber(round(original.amount, 6))}{' '}{strategy.baseAsset && strategy.baseAsset.toUpperCase()}
                          </React.Fragment>
                        )}
                    </div>

                    <div className="MobileCell-small MobileCell-light">
                      {SIGNAL_TYPE_LABELS[original.type]}
                      {/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled/.test(original.type) ?
                        null : (
                        <React.Fragment>
                          : {homeQuoteAsset === 'usd' ? formatAsDollarAmount(original.openPrice * original.amount) : `${fixFloatingPointErrors(original.openPrice * original.amount)} ${homeQuoteAsset}`}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            },
          },
          {
            Header: '',
            id: 'right',
            className: 'MobileCell u-justifyend',
            accessor: 'acceptedAt',
            Cell: ({original}) => {
              let formattedGainLoss = '--'
              let calculatedGainLoss = 0;
              let formattedGainLossPercent = '';
              if (/ToggleBuyEnabled|ToggleBuyDisabled|ToggleSellEnabled|ToggleSellDisabled|TriggerBuy/.test(original.type) === false) {
                calculatedGainLoss = (original.closePrice - original.openPrice) * original.amount;
                formattedGainLoss = homeQuoteAsset === 'usd'
                  ? formatAsDollarAmount(calculatedGainLoss)
                  : `${calculatedGainLoss} ${homeQuoteAsset.toUpperCase()}`;

                let cost = original.openPrice * original.amount;

                formattedGainLossPercent = ((calculatedGainLoss / cost) * 100).toFixed(2) + '%';
              }

              return (
                <div>
                  {formattedGainLoss === '--' ? (
                    <div>
                      {formattedGainLoss}
                    </div>
                  ) : (
                    <React.Fragment>
                      {calculatedGainLoss > 0 ? (
                        <div className="TradeHistory-gain">{formattedGainLoss} {formattedGainLossPercent ? ` | ${formattedGainLossPercent}` : ``}</div>
                      ) : (
                        <div className="TradeHistory-loss">{formattedGainLoss} {formattedGainLossPercent ? ` | ${formattedGainLossPercent}` : ``}</div>
                      )}
                    </React.Fragment>
                  )}

                  <time className="MobileCell-small MobileCell-light" dateTime={original.generatedAt}>
                    {moment(original.generatedAt).format('M/DD/YY')}
                    <br />
                    {moment(original.generatedAt).format('h:mm A')}
                  </time>
                </div>
              )
            }
          }
        ]
    },
  );

  return (
    <div className="StrategySignalsList StrategyBacktest Strategy-content u-container">
      <div className="MyPortfolioCard StrategyBacktest-disclaimer">
        Backtesting is a very helpful tool for seeing if the strategy you have designed has any chance of being profitable.  However, just because a strategy works well based on historical data it does not mean that it will perform well in the future.  Past success never dictates future success. This tool also takes roughly 30 seconds to process every 30 days worth of data.

        {!isArrayNullOrEmpty(backtestHistory) && (
          <React.Fragment>
            <p>
              <br />
              <Button onClick={() => setHistoryOpen(true)}>
                Show History
              </Button>
            </p>
          </React.Fragment>
        )}
      </div>
      <div className="MyPortfolioCard">
        <div className="StrategySignalsList-filters">
          <div className="StrategySignalsList-filter">
            <label htmlFor="startDate">
              Start Date
            </label>

            <Datepicker
              id="startDate"
              dataTestId="backtest_startDate"
              maxDate={backtestEndDate ? backtestEndDate : moment().format(BACKTEST_DATE_FORMAT)}
              onChange={(newDate) => setBacktestStartDate(moment(newDate).format(BACKTEST_DATE_FORMAT))}
              value={backtestStartDate}
            />
          </div>

          <div className="StrategySignalsList-filter">
            <label htmlFor="endDate">
              End Date
            </label>

            <Datepicker
              id="endDate"
              dataTestId="backtest_endDate"
              maxDate={moment().format(BACKTEST_DATE_FORMAT)}
              minDate={backtestStartDate ? backtestStartDate : null}
              onChange={(newDate) => setBacktestEndDate(moment(newDate).format(BACKTEST_DATE_FORMAT))}
              value={backtestEndDate}
            />
          </div>

          <div className="StrategySignalsList-filter StrategySignalsList-filter--startingBalance">
            <label htmlFor="startingBalance">
              Starting Balance
            </label>

            <div className="StrategySignalsList-startingBalance" data-testid="backtest_starting_balance">
              {calculatedStartingBalance ? formatAsDollarAmount(calculatedStartingBalance) : '--'}
            </div>
          </div>

          <div className="StrategySignalsList-filter">
            {loading[LOADING_STATES.RUNNING_BACKTEST] ? (
              <Button
                data-testid="cancel_backtest_btn"
                onClick={() => {
                  setHasRunBacktest(false);
                  cancelBacktest();
                }}
              >
                Cancel Backtest
              </Button>
            ) : (
              <Button
                disabled={calculatedStartingBalance <= 0}
                onClick={handleRunBacktest}
                data-testid="run_backtest_btn"
              >
                Run Backtest
              </Button>
            )}
          </div>

          <div className="StrategySignalsList-filter StrategySignalsList-filter--downloadReport">
            <div className="StrategySignalsList-showAllToggle">
              <Toggle
                value={showAllSignals}
                handleChange={() => setShowAllSignals(!showAllSignals)}
                label="Show All Signals Generated"
              />
            </div>

            <CSVLink
              data={backtestCsvData}
              className="DownloadReportLink"
              filename={`BacktestData_${strategy.name || 'UnsavedStrategy'}_${format(
                new Date(),
                'MM-dd-yy'
              )}.csv`}
            >
              Download data
            </CSVLink>
          </div>
        </div>

        <section className="StrategyBacktest-tableContainer">
          {loading[LOADING_STATES.RUNNING_BACKTEST] && (
            <Loading />
          )}
        </section>

        {(!isArrayNullOrEmpty(backtestData) || backtestSummaryIsFromHistory) && (
          <section className="StrategyBacktest-summary">
            <SummaryPanel
              rows={[
                [
                  {
                    dataTestId: 'summary_profit/loss',
                    label: 'Gross Profit/Loss',
                    value: !isNil(grossProfitLoss) ? formatAsDollarAmount(grossProfitLoss) : '--',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(grossProfitLoss, 0)) return 'loss'
                        if (gt(grossProfitLoss, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  }, {
                    dataTestId: 'summary_roi',
                    label: 'ROI %',
                    value: roiPercentage + '%',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(grossProfitLoss, 0)) return 'loss'
                        if (gt(grossProfitLoss, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  }, {
                    dataTestId: 'summary_avg/trade',
                    label: 'Avg % per trade',
                    value: !isNil(averagePercentPerTrade) && !isNaN(averagePercentPerTrade) ? `${averagePercentPerTrade.toFixed(2)}%` : '--',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(grossProfitLoss, 0)) return 'loss'
                        if (gt(grossProfitLoss, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  }, {
                    dataTestId: 'summary_market_performance',
                    label: 'Actual Market Performance',
                    value: !isNil(actualMarketValue) && !isNaN(actualMarketValue) ? `${actualMarketValue.toFixed(2)}%` : '--',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(actualMarketValue, 0)) return 'loss'
                        if (gt(actualMarketValue, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  },
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                ], [
                  {
                    dataTestId: 'summary_win_rate',
                    label: 'Win Rate',
                    value: !isNil(winRate) ? `${Math.round(winRate)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_qty_wins',
                    label: 'Qnty of Wins',
                    value: !isNil(quantityOfWins) ? quantityOfWins : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_age_wins',
                    label: 'Avg Age of Wins',
                    value: !isNil(averageMinutesPerWin) && quantityOfWins > 0 ? renderTableAge(averageMinutesPerWin) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_total_profits',
                    label: 'Total Profits',
                    value: !isNil(totalProfits) ? formatAsDollarAmount(totalProfits) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_profit',
                    label: 'Avg Profit',
                    value: !isNil(averageProfit) ? formatAsDollarAmount(averageProfit) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_profit%',
                    label: 'Avg Profit %',
                    value: !isNil(averageProfitPercent) ? `${averageProfitPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_lowest_win%',
                    label: 'Lowest %',
                    value: !isNil(lowestProfitPercent) ? `${lowestProfitPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_highest_win%',
                    label: 'Highest %',
                    value: !isNil(highestProfitPercent) ? `${highestProfitPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  },
                ], [
                  {
                    dataTestId: 'summary_loss_rate',
                    label: 'Loss Rate',
                    value: !isNil(lossRate) ? `${Math.round(lossRate)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_qty_losses',
                    label: 'Qnty of Losses',
                    value: !isNil(quantityOfLosses) ? quantityOfLosses : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_age_losses',
                    label: 'Avg Age of Losses',
                    value: !isNil(averageMinutesPerLoss) && quantityOfLosses > 0 ? renderTableAge(averageMinutesPerLoss) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_total_losses',
                    label: 'Total Losses',
                    value: !isNil(totalLosses) ? formatAsDollarAmount(totalLosses) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_loss',
                    label: 'Avg Loss',
                    value: !isNil(averageLoss) ? formatAsDollarAmount(averageLoss) : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_avg_loss%',
                    label: 'Avg Loss %',
                    value: !isNil(averageLossPercent) ? `${averageLossPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_lowest_loss%',
                    label: 'Lowest %',
                    value: !isNil(highestLossPercent) ? `${highestLossPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_highest_loss%',
                    label: 'Highest %',
                    value: !isNil(lowestLossPercent) ? `${lowestLossPercent.toFixed(2)}%` : '--',
                    variant: ['secondary'],
                  },
                ], [
                  {
                    dataTestId: 'summary_qty_open',
                    label: 'Qty Open',
                    value: openPositionsQuantity != null ? openPositionsQuantity : '--',
                    variant: ['secondary'],
                  }, {
                    dataTestId: 'summary_open_gain/loss',
                    label: 'Gain/Loss $',
                    value: !isNil(openPositionsProfit) && !isNaN(openPositionsProfit) ? formatAsDollarAmount(openPositionsProfit) : '--',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(openPositionsProfit, 0)) return 'loss'
                        if (gt(openPositionsProfit, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  }, {
                    dataTestId: 'summary_open_avg_gain/loss',
                    label: 'Avg % Gain/Loss',
                    value: !isNil(openPositionsProfitPercent) && !isNaN(openPositionsProfitPercent) ? `${openPositionsProfitPercent.toFixed(2)}%` : '--',
                    variant: [
                      'secondary',
                      (() => {
                        if (lt(openPositionsProfitPercent, 0)) return 'loss'
                        if (gt(openPositionsProfitPercent, 0)) return 'gain'
                        return 'neutral'
                      })(),
                    ],
                  },
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                  {label: '', value: '', className: 'empty',},
                ],
              ]}
            />
          </section>
        )}
        {!isArrayNullOrEmpty(backtestData) ? (
          <section className="StrategyBacktest-table">
            <Table
              columns={columns}
              data={filteredBacktestData}
              sortable={false}
            />
          </section>
        ) : (
          <React.Fragment>
            {hasRunBacktest && !loading[LOADING_STATES.RUNNING_BACKTEST] && (
              <p data-testid="backtest_no_result_message">
                There were no buys/sell that occurred with this strategy for the given period of time.
              </p>
            )}
          </React.Fragment>
        )}
      </div>

      <div className="StrategyBacktest-tooltipBuffer" />

      {historyOpen && (
        <StrategyBacktestHistory
          onDismiss={() => setHistoryOpen(false)}
          setStrategy={setStrategy}
        />
      )}

      <Modal
        modal={allocationModal}
        screens={{
          MAIN: {
            heading: () => <h4>Allocated Percent</h4>,
            body: () => (
              <React.Fragment>
                <p>Strategy allocation percent needs to be greater than zero.</p>
                <Button
                  className="sumsub-results-button"
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={() => allocationModal.setShow(false)}
                >
                  OK
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />

      <Modal
        modal={balanceModal}
        screens={{
          MAIN: {
            heading: () => <h4>Available Balance</h4>,
            body: () => (
              <React.Fragment>
                <p>Your available balance isn't high enough to run a backtest for this strategy.</p>
                <Button
                  className="sumsub-results-button"
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={() => balanceModal.setShow(false)}
                >
                  OK
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />

      <Modal
        modal={backtestErrorModal}
        screens={{
          MAIN: {
            heading: () => <h4>There was an error</h4>,
            body: () => (
              <React.Fragment>
                <p>The backtesting ending prematurely. Please try again.</p>
                <Button
                  className="sumsub-results-button"
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={() => {
                    backtestErrorModal.setShow(false);
                    clearBacktestError();
                  }}
                >
                  OK
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />
    </div>
  )
};

export default StrategyBacktest;
