import * as React from 'react';
import { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const Referral: React.FC = () => {
  const history = useHistory();
  const { referralCode } = useParams() as { referralCode: string };

  useEffect(() => {
    if(referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }

    history.push('/');
  }, [history, referralCode]);

  return (
    <div />
  );
};

export default Referral;
