import React, { useEffect, useState } from 'react'
import {get, isEmpty} from 'lodash'
import Icon from '@pv3-shared-components/Icons'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import { useForm } from 'react-hook-form'
import Input from '@pv3-shared-components/Input'
import InputError from '@pv3-shared-components/InputError'
import Button from '@pv3-shared-components/Button'
import {KYC_VERIFICATION_LEVELS, KYC_VERIFICATION_STATUSES} from "../../constants";
import Sumsub from "../shared/Sumsub";
import Modal from '@pv3-shared-components/Modal'
import useModal from '@pv3-hooks/useModal'

const FieldsBusiness = () => {
  const [isEditingContact, setIsEditingContact] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [title, setTitle] = useState('');
  const [result, setResult] = useState('');

  const {
    countries,
    getCountries,
    profile,
    renderAddressDisplay,
    renderPhoneNumberDisplay,
    updateKycVerificationStatus,
    updateProfile,
  } = useProfileDuck()

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm()

  const modal = useModal()
  const sumsubModal = useModal()

  useEffect(() => {
    if (!isEmpty(countries)) return
      ;(async function requestCountries() {
      try {
        getCountries();
      } catch (error) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  const handleFormSubmit = async (fields, e) => {
    e.preventDefault()

    try {
      setIsSubmitting(true)

      await updateProfile({
        ...profile,
        ...fields,
      })

      setIsEditingContact(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isEditingContact) {
    return (
      <form
        className="AcctSettings-form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <fieldset className="Fieldset AcctSettings-fieldset">
          <legend className="Legend AcctSettings-legend">Contact info</legend>

          {profile?.kycVerificationStatus !== KYC_VERIFICATION_STATUSES.PENDING && (
            <p style={{clear: 'both'}}>
              <Button
                variant="secondary"
                size="small"
                type="button"
                onClick={() => sumsubModal.setShow(true)}
              >
                Change Address
              </Button>
            </p>
          )}

          <Input
            type="text"
            defaultValue={get(profile, 'contactName')}
            register={{
              ...register('contactName', {
                required: 'Primary contact is required',
              }),
            }}
            error={
              errors.contactName && (
                <InputError message={errors.contactName.message} />
              )
            }
          >
            Primary contact
          </Input>

          <Input
            type="tel"
            defaultValue={get(profile, 'cellNumber')}
            register={{
              ...register('cellNumber', {
                required: 'Primary contact phone is required',
              }),
            }}
            error={
              errors.cellNumber && <InputError message={errors.cellNumber.message} />
            }
          >
            Primary contact phone
          </Input>

          <Button
            variant="primary"
            size="small"
            type="submit"
            className="AcctSettings-save"
            isLoading={isSubmitting}
          >
            Save
          </Button>

          <Button
            variant="secondary"
            size="small"
            type="button"
            className="AcctSettings-cancel"
            onClick={() => setIsEditingContact(false)}
          >
            Cancel
          </Button>
        </fieldset>

        <Modal
          modal={modal}
          screens={{
            MAIN: {
              heading: () => <h4>{title}</h4>,
              body: () => (
                <React.Fragment>
                  <p>
                    {result}
                  </p>
                  <Button
                    className="sumsub-results-button"
                    variant="primary"
                    size="large"
                    type="button"
                    onClick={() => {
                      modal.setShow(false);
                    }}
                  >
                    OK
                  </Button>
                </React.Fragment>
              ),
            },
          }}
        />

        <Modal
          modal={sumsubModal}
          screens={{
            MAIN: {
              body: () => (
                <React.Fragment>
                  <Sumsub
                    levelName={KYC_VERIFICATION_LEVELS.PROOF_OF_ADDRESS_BUSINESS}
                    onFailure={(message) => {
                      setTitle('There was an issue');
                      setResult(message || 'We were unable to verify your information. Please try again.');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                    onPending={() => {
                      setTitle('Verification is pending');
                      setResult('Thank you for submitting your information. The verification process can take up to a full business day.');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                    onSuccess={() => {
                      setTitle('Success!');
                      setResult('Your address change has been successfully verified.');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                  />
                </React.Fragment>
              ),
            },
          }}
        />
      </form>
    )
  }

  return (
    <section className="Fieldset AcctSettings-fieldset">
      <h1 className="Legend AcctSettings-legend">Contact info</h1>

      {profile?.kycVerificationStatus === KYC_VERIFICATION_STATUSES.VERIFIED && (
        <button
          className="u-buttonReset AcctSettings-edit"
          type="button"
          onClick={() => setIsEditingContact(!isEditingContact)}
        >
          Edit
          <Icon aria-hidden="true" name="pencil" />
        </button>
      )}

      <dl className="AcctSettings-list">
        {renderAddressDisplay()}
        <dt>Primary contact</dt>
        <dd>{profile.contactName || '--'}</dd>
        {renderPhoneNumberDisplay()}
      </dl>
    </section>
  )
}

export default FieldsBusiness
