import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import moment from 'moment'
import Currency from '../shared/Currency'
import {
  lt,
  gt,
  get,
  eq,
  startsWith,
  replace,
  map,
  toUpper,
  isNull,
  upperCase,
  isUndefined,
  round,
  find,
  filter, sortBy, isEmpty, isNil, sum, floor,
} from 'lodash'
import {
  format,
  isBefore,
} from 'date-fns'
import clone from 'clone';
import SummaryPanel from '@pv3-shared-components/SummaryPanel'
import useTradesDuck from '@pv3-hooks/useTradesDuck'
import Select from '@pv3-shared-components/Select'
import TradeDetail from '@pv3-components/TradeDetail'
import Table from '@pv3-shared-components/Table'
import './tradeHistory.scss'
import { CSVLink } from 'react-csv'
import ComponentLoader from '@pv3-components/ComponentLoader'
import { LOADING_STATES, APP_PATHS, API_PATHS } from '../../constants'
import { Link, useParams } from 'react-router-dom'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import useDocTitle from '@pv3-hooks/useDocTitle'
import Request from '../../utils/request'
import {
  fixFloatingPointErrors,
  formatAsDollarAmount,
  formatNumber, renderTableAge,
} from '../../utils/utils'
import {isArrayNullOrEmpty, isObjectNullOrEmpty} from "../../utils/utils";
import Logo from "../shared/Logo";
import useStrategiesDuck from "../../hooks/useStrategiesDuck";

const request = new Request()

const TradeHistory = () => {
  useDocTitle('Trade History')
  const { strategyId } = useParams();
  const {
    getIndicators,
    indicators,
    strategies,
    setStrategyTradeHistoryFilters,
    setStrategyTradeHistoryStrategyId,
    strategyTradeHistoryFilters,
    strategyTradeHistoryStrategyId,
  } = useStrategiesDuck();

  const [tradeDetailOpen, setTradeDetailOpen] = useState(false)
  const [selectedAutotradeOrManualForFilter, setSelectedAutotradeOrManualForFilter] = useState('');
  const [selectedPublisherForFilter, setSelectedPublisherForFilter] = useState('');
  const [selectedStrategyForFilter, setSelectedStrategyForFilter] = useState(strategyId || '');
  const [selectedTradeTypeForFilter, setSelectedTradeTypeForFilter] = useState(strategyId ? strategyTradeHistoryFilters.tradeType : '');
  const [tradeHistoryFilterForDate, setTradeHistoryFilterForDate] =
    useState(strategyId ? strategyTradeHistoryFilters.timePeriod : 'this_week')
  const [tradeHistoryFilterForPair, setTradeHistoryFilterForPair] =
    useState(null)
  const isBig = useWindowWidth()
  const [tradeHistoryFilters, setTradeHistoryFilters] = useState({
    StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z',
    Statuses: [2,3],
    AutoTrade: true,
    IncludePartialFills: true,
    IsHideCanceled: false,
  })
  const {
    getTradeHistoryFromFilter,
    tradeHistoryFromFilter,
  } = useTradesDuck()
  const [strategy, setStrategy] = useState(null)
  const [tradeDetails, setTradeDetails] = useState({})
  const [
    tradeHistoryFiltersForPairOptions,
    setTradeHistoryFiltersForPairOptions,
  ] = useState([])
  const [intervalTimer, setIntervalTimer] = useState()

  const csvLink = useRef()

  const tradeHistoryFiltersForDateOptions = [
    { label: '7 Days', value: 'this_week' },
    { label: '30 Days', value: 'last_30_days' },
    { label: '3 Months', value: 'last_90_days' },
    { label: '6 Months', value: 'last_6_months' },
    { label: 'YTD', value: 'this_year' },
    { label: '12 Months', value: 'last_12_months' },
    { label: 'Lifetime', value: 'lifetime' },
  ]

  const applyFiltersToData = (data) => {
    let filtered = clone(data);

    if(tradeHistoryFilterForPair) {
      filtered = filter(filtered, (t) => t.instrument === tradeHistoryFilterForPair);
    }

    const selectedStrategyId = strategyId || selectedStrategyForFilter;

    if(strategyId || (selectedStrategyForFilter && selectedAutotradeOrManualForFilter !== 'manual')) {
      filtered = filter(filtered, (t) => t.position && t.position.strategyBasic && t.position.strategyBasic.id === selectedStrategyId)
    }

    if(selectedPublisherForFilter && selectedAutotradeOrManualForFilter !== 'manual') {
      filtered = filter(filtered, (t) => t.position && t.position.strategyBasic && t.position.strategyBasic.publisherUserId === selectedPublisherForFilter)
    }

    if(selectedAutotradeOrManualForFilter) {
      if(selectedAutotradeOrManualForFilter === 'autotrade') {
        filtered = filter(filtered, (t) => !isObjectNullOrEmpty(t.position));
      } else {
        filtered = filter(filtered, (t) => isObjectNullOrEmpty(t.position));
      }
    }

    if(selectedTradeTypeForFilter) {
      if(selectedTradeTypeForFilter === 'buy') {
        filtered = filter(filtered, (t) => t.side === 0);
      } else {
        filtered = filter(filtered, (t) => t.side === 1);
      }
    }

    return filtered;
  }

  const handleTradeHistoryFilterForAutotradeAndManualChange = (event) => {
    const { value } = event.target
    setSelectedAutotradeOrManualForFilter(value)
    if(value === 'manual') {
      setSelectedPublisherForFilter('');
      setSelectedStrategyForFilter('');
    }
  }

  const handleTradeHistoryFilterForDateChange = (event) => {
    const { value } = event.target
    setTradeHistoryFilterForDate(value)
  }

  const handleTradeHistoryFilterForPairChange = (event) => {
    const { value } = event.target
    setTradeHistoryFilterForPair(value)
  }

  const handleTradeHistoryFilterForPublisherChange = (event) => {
    const { value } = event.target
    setSelectedPublisherForFilter(value)
  }

  const handleTradeHistoryFilterForStrategyChange = (event) => {
    const { value } = event.target
    setSelectedStrategyForFilter(value)
  }

  const handleTradeHistoryFilterForTradeTypeChange = (event) => {
    const { value } = event.target
    setSelectedTradeTypeForFilter(value);

    if(strategyId) {
      setStrategyTradeHistoryFilters({
        ...strategyTradeHistoryFilters,
        tradeType: value,
      });
    }
  }

  const handleRowClick = (_, rowInfo) => {
    return {
      className:
        tradeDetailOpen && eq(rowInfo.original, tradeDetails)
          ? 'is-selected'
          : '',
      onClick: (e) => {
        setTradeDetails(rowInfo.original)
        setTradeDetailOpen(true)
      },
    }
  }

  const fetchMarketOptions = useCallback(async () => {
    try {
      let { data: marketOptions } = await request.get(
        API_PATHS.COIN.MARKET_OPTIONS
      )
      marketOptions = sortBy(marketOptions);
      var marketOptionObjects = map(marketOptions, (option) => ({
        label: toUpper(replace(option, '_', '-')),
        value: option,
      }));
      setTradeHistoryFiltersForPairOptions([
        {label: 'All trading pairs', value: ''},
        ...marketOptionObjects,
      ]);
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (isEmpty(tradeHistoryFiltersForPairOptions)) {
      fetchMarketOptions()
    }

    if (isEmpty(indicators)) {
      getIndicators()
    }
  }, [fetchMarketOptions, getIndicators, indicators, tradeHistoryFiltersForPairOptions])

  useEffect(() => {
    if (isNil(strategies) || isNil(strategyId)) return

    setStrategy(find(strategies, { id: strategyId }))
  }, [strategies, strategyId])

  const columns = useMemo(
    () =>
      isBig
        ? [
            // Desktop columns
            {
              Header: 'Trade Details',
              id: 'tradeDetails',
              className: 'TradeHistory-details',
              sortMethod: (a, b) => {
                const date1 = new Date(a)
                const date2 = new Date(b)

                return isBefore(date1, date2) ? -1 : 1
              },
              accessor: 'acceptedAt',
              Cell: (props) => {
                let currency = (
                  <Logo />
                )
                if (!startsWith(props.original.instrument, 'lion_')) {
                  currency = (
                    <Currency type={props.original.instrument.split('_')[0]} />
                  )
                }

                return (
                  <React.Fragment>
                    <time dateTime={props.original.acceptedAt}>
                      {moment(props.original.acceptedAt).format('M/DD/YY')}
                      <br />
                      {moment(props.original.acceptedAt).format('h:mm:ss A')}
                    </time>
                    <div className="TradeHistory--iconContainer">
                      {currency}
                      <p className="TradeHistory--iconText">
                        {replace(get(props, 'original.instrument'), '_', '-')}
                      </p>
                    </div>
                    <span>{props.original.side === 0 ? 'Buy' : 'Sell'}</span>
                    {get(props, 'original.position') &&
                      !eq(
                        get(props, 'original.position.strategyBasic.userId'),
                        get(props, 'original.position.strategyBasic.publisherUserId')
                      ) && <em className="BadgeCopyCat">Copycat</em>}
                  </React.Fragment>
                )
              },
            },
            {
              Header: 'Quantity',
              id: 'quantity',
              className: 'u-tu u-justifyend',
              headerClassName: 'u-tr',
              accessor: 'requestedAmount',
              Cell: (props) => (
                <span>
                  {formatNumber(round(props.original.baseAmount, 6))}{' '}
                  {props.original.instrument.split('_')[0]}
                </span>
              ),
            },
            {
              Header: 'Price',
              id: 'requestedPrice',
              className: 'u-tu u-justifyend',
              headerClassName: 'u-tr',
              accessor: (d) => d.quoteAmount / d.baseAmount,
              Cell: (props) => {
                if(props.original.baseAmount === 0) {
                  return '--';
                } else if (props.original.instrument.split('_')[1] === 'usd') {
                  return formatAsDollarAmount(
                    props.original.quoteAmount / props.original.baseAmount
                  )
                } else {
                  return `${round(
                    props.original.quoteAmount / props.original.baseAmount,
                    6
                  ).toLocaleString()} ${props.original.instrument.split('_')[1]}`
                }
              },
            },
            {
              Header: 'Cost',
              id: 'cost',
              className: 'u-tu u-justifyend tradeHistory-cost',
              headerClassName: 'u-tr tradeHistory-cost',
              accessor: 'quoteAmount',
              Cell: (props) => {
                if (props.original.instrument.split('_')[1] === 'usd') {
                  return formatAsDollarAmount(props.original.quoteAmount)
                } else {
                  return `${round(props.original.quoteAmount, 3).toLocaleString()} ${props.original.instrument.split('_')[1]}`
                }
              },
            },
            {
              Header: 'Gain / Loss',
              id: 'gainLoss',
              className: 'u-justifyend',
              headerClassName: 'u-tr',
              accessor: (d) =>
                d.gainLoss,
              Cell: (props) => {
                if (!props.original.positionId || props.original.side === 0) {
                  return '--'
                }
                const quoteCurrency = props.original.instrument.split('_')[1]
                let formattedGainLoss =
                  quoteCurrency === 'usd'
                    ? formatAsDollarAmount(props.original.gainLoss)
                    : `${round(props.original.gainLoss, 3).toLocaleString()} ${quoteCurrency.toUpperCase()}`
                if (props.original.gainLoss > 0) {
                  return (
                    <div className="TradeHistory-gain">{formattedGainLoss}</div>
                  )
                } else if (props.original.gainLoss < 0) {
                  return (
                    <div className="TradeHistory-loss">{formattedGainLoss}</div>
                  )
                }
                return formattedGainLoss
              },
            },
          ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'icon',
              className: 'MobileCell',
              Cell: (props) => {
                let currency = (
                  <Logo />
                )
                if (!startsWith(props.original.instrument, 'lion_')) {
                  currency = (
                    <Currency type={props.original.instrument.split('_')[0]} />
                  )
                }

                return (
                  <React.Fragment>
                    {currency}
                    <div>
                      <div className="u-tu">
                        {replace(get(props, 'original.instrument'), '_', '-')}
                      </div>
                      <div className="MobileCell-small">
                        {formatNumber(round(props.original.requestedAmount, 6))}{' '}
                        {upperCase(props.original.instrument.split('_')[0])}
                      </div>
                      <div className="MobileCell-small MobileCell-light">
                        {props.original.instrument.split('_')[1] === 'usd'
                          ? formatAsDollarAmount(
                              props.original.quoteAmount /
                                props.original.baseAmount
                            )
                          : `${
                              round(props.original.quoteAmount / props.original.baseAmount, 3).toLocaleString()
                            } ${props.original.instrument.split('_')[1]?.toUpperCase()}`}
                      </div>
                    </div>
                  </React.Fragment>
                )
              },
            },
            {
              Header: '',
              id: 'right',
              className: 'MobileCell u-justifyend',
              accessor: 'acceptedAt',
              sortMethod: (a, b) => {
                const date1 = new Date(a)
                const date2 = new Date(b)

                return isBefore(date1, date2) ? -1 : 1
              },
              Cell: (props) => {
                const datetime = (
                  <time
                    dateTime={props.original.acceptedAt}
                    className="MobileCell-small MobileCell-light"
                  >
                    <div>
                      {moment(props.original.acceptedAt).format('M/DD/YYYY')}
                    </div>
                    <div>
                      {moment(props.original.acceptedAt).format('h:mm A')}
                    </div>
                  </time>
                )

                if (!props.original.positionId || props.original.side === 0) {
                  return '--'
                }
                const quoteCurrency = props.original.instrument.split('_')[1]
                let formattedGainLoss =
                  quoteCurrency === 'usd'
                    ? formatAsDollarAmount(props.original.gainLoss)
                    : `${round(props.original.gainLoss, 3).toLocaleString()} ${quoteCurrency.toUpperCase()}`
                if (props.original.gainLoss > 0) {
                  return (
                    <div>
                      <div className="TradeHistory-gain">
                        {formattedGainLoss}
                      </div>
                      {datetime}
                    </div>
                  )
                } else if (props.original.gainLoss < 0) {
                  return (
                    <div>
                      <div className="TradeHistory-loss">
                        {formattedGainLoss}
                      </div>
                      {datetime}
                    </div>
                  )
                }
                return (
                  <div>
                    {formattedGainLoss}
                    {datetime}
                  </div>
                )
              },
            },
          ],
    [isBig]
  )

  const tableData = useMemo(
    () => {
      if(!selectedAutotradeOrManualForFilter && !selectedPublisherForFilter && !selectedStrategyForFilter && !selectedTradeTypeForFilter && !tradeHistoryFilterForPair) {
        return tradeHistoryFromFilter;
      }

      let filtered = applyFiltersToData(tradeHistoryFromFilter);

      return filtered;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAutotradeOrManualForFilter, selectedPublisherForFilter, selectedStrategyForFilter, selectedTradeTypeForFilter, tradeHistoryFilterForPair, tradeHistoryFromFilter]
  )



  const publisherOptionsForFilter = useMemo(
    () => {
      let publisherOptions = [{label: 'All publishers', value: ''}];

      if(isArrayNullOrEmpty(tradeHistoryFromFilter)) {
        return publisherOptions;
      }

      tradeHistoryFromFilter.forEach((t) => {

        if(isObjectNullOrEmpty(t.position) || isObjectNullOrEmpty(t.position.strategyBasic)) {
          return;
        }

        if(!find(publisherOptions, {publisherUserId: t.position.strategyBasic.publisherUserId})) {
          let strategy = clone(t.position.strategyBasic);
          strategy.label = strategy.publisherDisplayName || `${strategy.publisherFirstName} ${strategy.publisherLastName}`;
          strategy.value = strategy.publisherUserId;
          publisherOptions.push(strategy);
        }
      });

      if(selectedPublisherForFilter && !publisherOptions.find((p) => p.value === selectedPublisherForFilter)) {
        setSelectedStrategyForFilter('');
      }

      return publisherOptions;
    }, [selectedPublisherForFilter, tradeHistoryFromFilter]
  );

  const strategyOptionsForFilter = useMemo(
    () => {
      if(!isNil(strategy)) {
        return [{
          label: strategy.name,
          value: strategy.id,
        }];
      }

      let strategyOptions = [];

      tradeHistoryFromFilter.forEach((t) => {
        if(isObjectNullOrEmpty(t.position) || isObjectNullOrEmpty(t.position.strategyBasic)) {
          return;
        }

        if(!find(strategyOptions, {id: t.position.strategyBasic.id})) {
          let strategy = clone(t.position.strategyBasic);
          strategy.label = strategy.name;
          strategy.value = strategy.id;
          strategyOptions.push(strategy);
        }
      });

      strategyOptions = sortBy(strategyOptions, 'name');
      strategyOptions = [
        {label: 'Any strategies', value: ''},
        ...strategyOptions
      ];

      if(isNil(strategyId) && selectedStrategyForFilter && !strategyOptions.find((s) => s.value === selectedStrategyForFilter)) {
        setSelectedStrategyForFilter('');
      }

      return strategyOptions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategy, tradeHistoryFromFilter]
  )

  useEffect(() => {
    ;(function setTradeHistoryFiltersOnDateChange() {
      const range = tradeHistoryFilterForDate

      let today = moment().add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      let startDate;
      let endDate = today;

      if (eq(range, 'this_week')) {
        startDate = moment(today).subtract(7, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (eq(range, 'last_30_days')) {
        startDate = moment(today).subtract(30, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (eq(range, 'last_90_days')) {
        startDate = moment(today).subtract(90, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (eq(range, 'last_6_months')) {
        startDate = moment(today).subtract(6, 'months').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (eq(range, 'last_12_months')) {
        startDate = moment(today).subtract(12, 'months').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (eq(range, 'this_year')) {
        startDate = moment().startOf('year').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      if (
        eq(startDate, tradeHistoryFilters.StartDate) &&
        eq(endDate, tradeHistoryFilters.EndDate)
      )
        return

      setTradeHistoryFilters({
        ...tradeHistoryFilters,
        StartDate: startDate,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeHistoryFilterForDate])

  useEffect(() => {
    if (!isUndefined(intervalTimer)) clearInterval(intervalTimer)

    if(strategyId) {
      // convoluted... I know. We're trying to prevent a GET on load if its the same strategyId as before.
      if(strategyId !== strategyTradeHistoryStrategyId) {
        setStrategyTradeHistoryStrategyId(strategyId);
        getTradeHistoryFromFilter({ filters: tradeHistoryFilters });
      } else {
        if(tradeHistoryFilterForDate !== strategyTradeHistoryFilters.timePeriod) {
          setStrategyTradeHistoryFilters({
            ...strategyTradeHistoryFilters,
            timePeriod: tradeHistoryFilterForDate,
          });

          getTradeHistoryFromFilter({ filters: tradeHistoryFilters });
        }
      }
    } else {
      getTradeHistoryFromFilter({filters: tradeHistoryFilters})
    }

    const timer = setInterval(
      () => getTradeHistoryFromFilter({ filters: tradeHistoryFilters }),
      600000
    )
    setIntervalTimer(timer)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeHistoryFilters])

  const tradeHistoryReport = useMemo(
    () => {
      let filtered = applyFiltersToData(tradeHistoryFromFilter);

      return map(filtered, (t) => {
        const [purchaseAsset, asset] = t.instrument.split('_')
        return {
          time: format(new Date(t.acceptedAt), 'h:mm a MM/dd/yy'),
          pair: `${toUpper(purchaseAsset)}-${toUpper(asset)}`,
          amount: `${t.baseAmount}`,
          'purchase asset': toUpper(purchaseAsset),
          side: eq(t.side, 0) ? 'Buy' : 'Sell',
          price: eq(asset, 'usd')
            ? formatAsDollarAmount(t.quoteAmount / t.baseAmount)
            : `${round(t.quoteAmount / t.baseAmount, 6)} ${asset}`,
          'USD value': eq(asset, 'usd')
            ? formatAsDollarAmount(t.quoteAmount)
            : `${fixFloatingPointErrors(t.quoteAmount)} ${asset}`,
          fee:
            eq(asset, 'usd') && eq(t.side, 1)
              ? formatAsDollarAmount(t.commission)
              : `${t.commission}`,
          'fee asset': toUpper(eq(t.side, 0) ? purchaseAsset : asset),
          'gain / loss':
            isNull(t.positionId) || eq(t.side, 0)
              ? 'na'
              : eq(asset, 'usd')
                ? formatAsDollarAmount(t.gainLoss)
                : `${t.gainLoss} ${toUpper(asset)}`,
          'strategy name': !isNull(t.position)
            ? t.position.strategy
              ? t.position.strategy.name
              : t.position.strategyBasic
                ? t.position.strategyBasic.name
                : '--'
            : '--',
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAutotradeOrManualForFilter, selectedPublisherForFilter, selectedStrategyForFilter, selectedTradeTypeForFilter, tradeHistoryFromFilter]
  )

  const {avgLoss, avgLossAge, avgLossPercentage, avgProfit, avgProfitAge, avgProfitPercentage, grossProfitLoss, lossLowestPercent, lossHighestPercent, lossRate, lossQuantity, profitPerTradePercentage, roiPercentage, winLowestPercent, winHighestPercent, totalLoss, totalProfit, winRate, winQuantity} = useMemo(
    () => {
      if(isArrayNullOrEmpty(tableData)) {
        return {};
      }

      let summary = {
        grossProfitLoss: 0,
        profitPerTradePercentage: 0,
        winRate: 0,
        winQuantity: 0,
        totalProfit: 0,
        avgProfit: 0,
        avgProfitAge: null,
        avgProfitPercentage: 0,
        winLowestPercent: null,
        winHighestPercent: null,
        lossRate: 0,
        lossQuantity: 0,
        totalLoss: 0,
        avgLoss: 0,
        avgLossPercentage: 0,
        avgLossAge: null,
        lossLowestPercent: null,
        lossHighestPercent: null,
        openPositionsQuantity: 0,
        openPositionsProfit: 0,
        openPositionsProfitPercent: 0,
      };

      let profitPercentages = [];
      let lossPercentages = [];
      let totalSellValue = 0;
      let totalProfitAge = 0;
      let totalLossAge = 0;

      tableData.forEach(({acceptedAt, baseAmount, gainLoss, instrument, position, side}) => {
        const quoteCurrency = instrument.split('_')[1];

        if(side === 1 && ['usd', 'usdc', 'usdt'].includes(quoteCurrency) && !isObjectNullOrEmpty(position)) {
          let profitPercentage = gainLoss / (position.openPrice * baseAmount);
          let age = new Date(acceptedAt).getTime() - new Date(position.created).getTime();

          totalSellValue += ((baseAmount * position.openPrice) + gainLoss);

          summary.grossProfitLoss += gainLoss;

          if(gainLoss >= 0) {
            summary.winQuantity++;
            summary.totalProfit += gainLoss;
            profitPercentages.push(profitPercentage);
            totalProfitAge += age;

            if(summary.winLowestPercent == null || summary.winLowestPercent > profitPercentage) {
              summary.winLowestPercent = profitPercentage;
            }

            if(summary.winHighestPercent == null || summary.winHighestPercent < profitPercentage) {
              summary.winHighestPercent = profitPercentage;
            }
          } else {
            summary.lossQuantity++;
            summary.totalLoss += gainLoss;
            lossPercentages.push(profitPercentage);
            totalLossAge += age;

            if(summary.lossLowestPercent == null || summary.lossLowestPercent > profitPercentage) {
              summary.lossLowestPercent = profitPercentage;
            }

            if(summary.lossHighestPercent == null || summary.lossHighestPercent < profitPercentage) {
              summary.lossHighestPercent = profitPercentage;
            }
          }
        }
      });

      if(summary.winQuantity) {
        summary.winRate = summary.winQuantity / (summary.winQuantity + summary.lossQuantity);
        summary.avgProfit = summary.totalProfit / summary.winQuantity;
        summary.avgProfitPercentage = sum(profitPercentages) / profitPercentages.length;
        summary.avgProfitAge = floor((totalProfitAge / summary.winQuantity) / (1000 * 60));
      }

      if(summary.lossQuantity) {
        summary.lossRate = summary.lossQuantity / (summary.winQuantity + summary.lossQuantity);
        summary.avgLoss = summary.totalLoss / summary.lossQuantity;
        summary.avgLossPercentage = sum(lossPercentages) / lossPercentages.length;
        summary.avgLossAge = floor((totalLossAge / summary.lossQuantity) / (1000 * 60));
      }

      summary.profitPerTradePercentage = ((sum(lossPercentages) + sum(profitPercentages)) / (lossPercentages.length + profitPercentages.length)) * 100;
      summary.roiPercentage = (summary.grossProfitLoss / totalSellValue) * 100;

      return summary;
    }, [tableData]
  );

  return (
    <div className="Content Content--tradeHistory">
      <ComponentLoader loadingStates={[LOADING_STATES.FETCH_TRADE_HISTORY]} />
      <h2 className="Activity-headline h4">Trade History</h2>


      <SummaryPanel
        rows={[
          [
            {
              label: 'Gross Profit/Loss',
              value: !isNil(grossProfitLoss) ? formatAsDollarAmount(grossProfitLoss) : '--',
              variant: [
                'secondary',
                (() => {
                  if (lt(grossProfitLoss, 0)) return 'loss'
                  if (gt(grossProfitLoss, 0)) return 'gain'
                  return 'neutral'
                })(),
              ],
            }, {
            label: 'ROI %',
            value: !isNil(roiPercentage) && !isNaN(roiPercentage) ? `${roiPercentage.toFixed(2)}%` : '--',
            variant: [
              'secondary',
              (() => {
                if (lt(roiPercentage, 0)) return 'loss'
                if (gt(roiPercentage, 0)) return 'gain'
                return 'neutral'
              })(),
            ],
          }, {
            label: 'Avg % per trade',
            value: !isNil(profitPerTradePercentage) && !isNaN(profitPerTradePercentage) ? `${profitPerTradePercentage.toFixed(2)}%` : '--',
            variant: [
              'secondary',
              (() => {
                if (lt(grossProfitLoss, 0)) return 'loss'
                if (gt(grossProfitLoss, 0)) return 'gain'
                return 'neutral'
              })(),
            ],
          },
            {label: '', value: '', className: 'empty',},
            {label: '', value: '', className: 'empty',},
            {label: '', value: '', className: 'empty',},
            {label: '', value: '', className: 'empty',},
            {label: '', value: '', className: 'empty',},
          ], [
            {
              label: 'Win Rate',
              value: !isNil(winRate) ? `${Math.round(winRate * 100)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Qnty of Wins',
              value: !isNil(winQuantity) ? winQuantity : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Age of Wins',
              value: !isNil(avgProfitAge) ? renderTableAge(avgProfitAge) : '--',
              variant: ['secondary'],
            }, {
              label: 'Total Profits',
              value: !isNil(totalProfit) ? formatAsDollarAmount(totalProfit) : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Profit',
              value: !isNil(avgProfit) ? formatAsDollarAmount(avgProfit) : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Profit %',
              value: !isNil(avgProfitPercentage) ? `${(avgProfitPercentage * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Lowest %',
              value: !isNil(winLowestPercent) ? `${(winLowestPercent * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Highest %',
              value: !isNil(winHighestPercent) ? `${(winHighestPercent * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            },
          ], [
            {
              label: 'Loss Rate',
              value: !isNil(lossRate) ? `${Math.round(lossRate * 100)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Qnty of Losses',
              value: !isNil(lossQuantity) ? lossQuantity : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Age of Losses',
              value: !isNil(avgLossAge) ? renderTableAge(avgLossAge) : '--',
              variant: ['secondary'],
            }, {
              label: 'Total Losses',
              value: !isNil(totalLoss) ? formatAsDollarAmount(totalLoss) : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Loss',
              value: !isNil(avgLoss) ? formatAsDollarAmount(avgLoss) : '--',
              variant: ['secondary'],
            }, {
              label: 'Avg Loss %',
              value: !isNil(avgLossPercentage) ? `${(avgLossPercentage * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Lowest %',
              value: !isNil(lossHighestPercent) ? `${(lossHighestPercent * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            }, {
              label: 'Highest %',
              value: !isNil(lossLowestPercent) ? `${(lossLowestPercent * 100).toFixed(2)}%` : '--',
              variant: ['secondary'],
            },
          ]
        ]}
      >
        <CSVLink
          data={tradeHistoryReport}
          ref={csvLink}
          className="DownloadReportLink"
          filename={`Trade-History-Report_${format(
            new Date(),
            'MM-dd-yy'
          )}.csv`}
        >
          Download report
        </CSVLink>

        <div className="TradeHistory-filters FlexRow--desktop">
          <div className="FilterBar">
            <Select
              ariaLabel="Select trade history duration"
              options={tradeHistoryFiltersForDateOptions}
              id="tradeHistoryFilterForDate"
              name="tradeHistoryFilterForDate"
              defaultValue={tradeHistoryFilterForDate}
              onChange={handleTradeHistoryFilterForDateChange}
            />
            {isNil(strategyId) && (
              <Select
                ariaLabel="Select trading pair to filter trade history"
                options={tradeHistoryFiltersForPairOptions}
                id="tradeHistoryFilterForPair"
                name="tradeHistoryFilterForPair"
                defaultValue={tradeHistoryFilterForPair}
                onChange={handleTradeHistoryFilterForPairChange}
              />
            )}
            <Select
              ariaLabel="Select trade type to filter trade history"
              options={[
                {label: 'All Trades', value: ''},
                {label: 'Buys', value: 'buy'},
                {label: 'Sells', value: 'sell'},
              ]}
              id="tradeHistoryFilterForTradeType"
              name="selectedTradeTypeForFilter"
              defaultValue={selectedTradeTypeForFilter}
              onChange={handleTradeHistoryFilterForTradeTypeChange}
            />
            {isNil(strategyId) && (
              <React.Fragment>
                <Select
                  ariaLabel="Select autotrade or manual to filter trade history"
                  options={[
                    {label: 'Autotrade & Manual', value: ''},
                    {label: 'Autotrade', value: 'autotrade'},
                    {label: 'Manual', value: 'manual'},
                  ]}
                  id="tradeHistoryFilterForAutotradeAndManual"
                  name="selectedAutotradeOrManualForFilter"
                  defaultValue={selectedAutotradeOrManualForFilter}
                  onChange={handleTradeHistoryFilterForAutotradeAndManualChange}
                />
                <Select
                  ariaLabel="Select strategy to filter trade history"
                  disabled={selectedAutotradeOrManualForFilter === 'manual'}
                  options={strategyOptionsForFilter}
                  id="tradeHistoryFilterForStrategy"
                  name="selectedStrategyForFilter"
                  value={selectedStrategyForFilter}
                  onChange={handleTradeHistoryFilterForStrategyChange}
                />
                <Select
                  ariaLabel="Select publisher to filter trade history"
                  disabled={selectedAutotradeOrManualForFilter === 'manual'}
                  options={publisherOptionsForFilter}
                  id="publisherFilterForPublisher"
                  name="selectedPublisherForFilter"
                  value={selectedPublisherForFilter}
                  onChange={handleTradeHistoryFilterForPublisherChange}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </SummaryPanel>
      {tableData && (
        <Table
          className="TradeHistory-table"
          columns={columns}
          data={tableData}
          defaultSorted={[
            {
              id: 'tradeDetails',
              desc: true,
            },
            {
              id: 'right',
              desc: true,
            },
          ]}
          getTrProps={handleRowClick}
          emptyHeadline="No trades to show"
          emptyDescription="Get started with CopyCat Auto-trading."
          emptyCta={
            <Link className="Button Button--primary" to={APP_PATHS.MARKETPLACE}>
              Find a trader to copy
            </Link>
          }
          loadingStates={[LOADING_STATES.FETCH_TRADE_HISTORY]}
        />
      )}
      {tradeDetailOpen && (
        <TradeDetail
          tradeDetails={tradeDetails}
          onDismiss={() => setTradeDetailOpen(false)}
        />
      )}
    </div>
  )
}

export default TradeHistory
