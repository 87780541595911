import React, { useState, useEffect, useMemo } from 'react'
import { isNil, isNull } from 'lodash'
import Button from '@pv3-shared-components/Button'
import Modal from '@pv3-shared-components/Modal'
import Input from '@pv3-shared-components/Input'
import Tooltip from '@pv3-shared-components/Tooltip'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import useBalancerDuck from '@pv3-hooks/useBalancerDuck'
import { useForm } from 'react-hook-form'
import { formatAsDollarAmount, isObjectNullOrEmpty } from '../../utils/utils'
import './tokenBalancer.scss'
import useModal from '../../hooks/useModal'
import useDocTitle from '@pv3-hooks/useDocTitle'
import { useTypedSelector } from '../../ducks';
import useProfileDuck from '../../hooks/useProfileDuck';
import { KycVerificationStatuses } from '../../utils/enums';

const balancerDefaults = {
  minimumLionBalancePercent: 5,
  lionTradePercentGainLoss: 5,
}

const TokenBalancer = () => {
  useDocTitle('Token Balancer')
  const [
    calculatedMinimumLionBalanceAmount,
    setCalculatedMinimumLionBalanceAmount,
  ] = useState(null)
  const modal = useModal()
  const {
    handleSubmit,
    register,
    watch,
  } = useForm()
  const {
    formState: { errors: errors2, isDirty: isDirty2 },
    handleSubmit: handleSubmit2,
    register: register2,
    reset: reset2,
    watch: watch2,
  } = useForm()
  const { totalPortfolioValueMinusLion, usdPrices } = useWalletsDuck()
  const {
    updateBalancer,
    minimumLionBalancePercent,
    lionTradePercentGainLoss,
    isLionBalancerEnabled,
  } = useBalancerDuck()
  const { profile } = useProfileDuck();

  const { userTotals } = useTypedSelector((state) => state.rewards);

  const watchAgree = watch('agree')
  const watchMinimumLionBalancePercent = watch2('minimumLionBalancePercent')

  const valueOfLionBalanceInUsd = useMemo(
    () => {
      if(isObjectNullOrEmpty(userTotals) || isObjectNullOrEmpty(usdPrices)) return 0;

      return ((userTotals?.totalLionBalance - userTotals?.lionInOpenOrders) * usdPrices?.lion);
    }, [usdPrices, userTotals]
  );

  const handleConfirmActivateBalancer = () => {
    updateBalancer({
      minimumLionBalancePercent: (isNull(minimumLionBalancePercent) || minimumLionBalancePercent < balancerDefaults.minimumLionBalancePercent)
        ? balancerDefaults.minimumLionBalancePercent
        : minimumLionBalancePercent,
      lionTradePercentGainLoss: (isNull(lionTradePercentGainLoss) || lionTradePercentGainLoss < balancerDefaults.lionTradePercentGainLoss)
        ? balancerDefaults.lionTradePercentGainLoss
        : lionTradePercentGainLoss,
      lionBalancerEnabled: true,
    })

    modal.setShow(false)
  }

  const handleSettingsFormSubmit = ({
    lionTradePercentGainLoss,
    minimumLionBalancePercent,
  }) => {
    updateBalancer({
      lionBalancerEnabled: true,
      lionTradePercentGainLoss,
      minimumLionBalancePercent,
    })
  }

  const handleDeactivateTokenBalancer = () => {
    updateBalancer({
      lionBalancerEnabled: false,
      lionTradePercentGainLoss,
      minimumLionBalancePercent,
    })
  }

  const handleActivateClick = () => {
    modal.setShow(true)
  }

  useEffect(() => {
    if (isNil(minimumLionBalancePercent)) return
    setCalculatedMinimumLionBalanceAmount(
      formatAsDollarAmount(
        totalPortfolioValueMinusLion * (watchMinimumLionBalancePercent * 0.01)
      )
    )
  }, [
    watchMinimumLionBalancePercent,
    totalPortfolioValueMinusLion,
    minimumLionBalancePercent,
  ])

  useEffect(() => {
    reset2({
      lionTradePercentGainLoss,
      minimumLionBalancePercent,
    })
  }, [lionTradePercentGainLoss, minimumLionBalancePercent, reset2])

  if (isNil(isLionBalancerEnabled)) return <React.Fragment />

  return (
    <div className="TokenBalancer-content">
      <p>
        As long as you have 5% in both of the fields below, you will be eligible for trading fee discounts on each transaction fee. Token Balancer will automatically purchase the necessary amount of CoinLion Tokens to balance your account.
      </p>
      {!isLionBalancerEnabled && profile?.kycVerificationStatus === KycVerificationStatuses.Verified && (
        <React.Fragment>
          <Button
            onClick={handleActivateClick}
            style={{ width: '422px' }}
            variant="primary"
            size="large"
          >
            Activate LION Token Balancer
          </Button>
        </React.Fragment>
      )}
      {isLionBalancerEnabled && (
        <React.Fragment>
          <form onSubmit={handleSubmit2(handleSettingsFormSubmit)}>
            <Input
              error={errors2?.minimumLionBalancePercent?.message}
              id="minimumLionBalancePercent"
              type="number"
              step="0.01"
              min="5"
              register={register2('minimumLionBalancePercent', {
                max: {
                  value: 100,
                  message: 'You cannot set this value higher than 100%.',
                }
              })}
              defaultValue="5"
              className={`Input u-tr`}
              suffix="%"
              helperText="5% minimum required to receive discount"
              calculatedTotal={calculatedMinimumLionBalanceAmount}
              wrapperTag="div"
              inputMode="decimal"
            >
              <label htmlFor="minimumLionBalancePercent">
                CoinLion Token portfolio balance
              </label>
              <Tooltip modalTitle="CoinLion Token portfolio balance">
                This is the total value of this account’s CoinLion Token as a
                percent of the rest of your portfolio. To receive the 25%
                discount on trading fees, LION must make up at least 5% of
                your portfolio value.
              </Tooltip>
            </Input>
            <Input
              error={errors2?.lionTradePercentGainLoss?.message}
              id="lionTradePercentGainLoss"
              type="number"
              step="0.01"
              register={register2('lionTradePercentGainLoss', {
                max: {
                  value: 100,
                  message: 'You cannot set this value higher than 100%.',
                }
              })}
              defaultValue="5"
              name="lionTradePercentGainLoss"
              className={`Input u-tr`}
              suffix="%"
              helperText="Must be equal to or greater than CoinLion Token portfolio balance."
              wrapperTag="div"
              inputMode="decimal"
            >
              <label htmlFor="lionTradePercentGainLoss">
                CoinLion Token trade with each Automatic trade
              </label>
            </Input>
            <div className="TokenBalancer-buttonGroup">
              {isDirty2 && (
                <React.Fragment>
                  <Button variant="primary">Save Changes</Button>
                  <Button type="button" onClick={() => reset2()} variant="secondary">
                    Discard Changes
                  </Button>
                </React.Fragment>
              )}
              <Button
                type="button"
                onClick={handleDeactivateTokenBalancer}
                variant="tertiary"
              >
                Deactivate LION Token Balancer
              </Button>
            </div>
          </form>
        </React.Fragment>
      )}
      <Modal
        modal={modal}
        screens={{
          MAIN: {
            heading: 'LION Token Balancer',
            body: () => {
              const purchasePercentDisplay = ((!isNil(minimumLionBalancePercent) && minimumLionBalancePercent > balancerDefaults.minimumLionBalancePercent) ? minimumLionBalancePercent : balancerDefaults.minimumLionBalancePercent);
              const purchasePercent = ((!isNil(minimumLionBalancePercent) && minimumLionBalancePercent > balancerDefaults.minimumLionBalancePercent) ? minimumLionBalancePercent / 100 : balancerDefaults.minimumLionBalancePercent / 100);
              return (
                <form
                  onSubmit={handleSubmit(handleConfirmActivateBalancer)}
                  className="TokenBalancerModal-body"
                >
                  <h4>Activate LION Token Balancer</h4>
                  <p>
                    Token Balancer will automatically
                    purchase {purchasePercentDisplay}%
                    of your
                    portfolio’s worth as CoinLion Token.
                  </p>
                  <div className="TokenBalancerModal-purchaseTable">
                    <h6 style={{marginBottom: '2rem'}}>
                      CoinLion Token Purchase
                    </h6>
                    <div>
                      <span>Auto-tradable Balance</span>
                      <span>
                        {totalPortfolioValueMinusLion
                          ? `${formatAsDollarAmount(
                            totalPortfolioValueMinusLion
                          )}`
                          : '--'}
                      </span>
                    </div>
                    <div>
                      <span>{purchasePercentDisplay}%</span>
                      <span>
                        {totalPortfolioValueMinusLion
                          ? `${formatAsDollarAmount(
                            totalPortfolioValueMinusLion * purchasePercent
                          )}`
                          : '--'}
                      </span>
                    </div>
                    <div>
                      <span>Current LION balance</span>
                      <span>
                        {totalPortfolioValueMinusLion &&
                          !isObjectNullOrEmpty(userTotals) &&
                          !isObjectNullOrEmpty(usdPrices)
                          ? `${formatAsDollarAmount(valueOfLionBalanceInUsd)}`
                          : '--'}
                      </span>
                    </div>
                    <hr/>
                    <div>
                      <span>Total Purchase</span>
                      <span>
                        {totalPortfolioValueMinusLion &&
                        !isObjectNullOrEmpty(userTotals)
                          ? `${formatAsDollarAmount(
                            Math.max(
                              totalPortfolioValueMinusLion * purchasePercent -
                              (valueOfLionBalanceInUsd || 0),
                              0
                            )
                          )}`
                          : '--'}
                      </span>
                    </div>
                  </div>
                  <div style={{marginBottom: '7rem'}}>
                    <label className="Label">
                      <input {...register('agree')} type="checkbox"/>
                      <span style={{width: '91%'}}>
                        I understand that Token Balancer will automatically buy
                        and sell enough CoinLion Token to equal {purchasePercentDisplay}% of my
                        portfolio balance until I deactivate it.
                      </span>
                    </label>
                  </div>
                  <Button
                    disabled={!watchAgree}
                    style={{width: '100%'}}
                    variant="primary"
                    size="large"
                  >
                    Confirm Activation
                  </Button>
                </form>
              );
            },
          },
        }}
      />
    </div>
  )
}

export default TokenBalancer
