import React from 'react'

const Failed = ({ fill = '#E00043', width = '20', height = '20', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Failed</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.683 2.806c1.19.533 2.42.97 3.678 1.305l.417.106V8.85c0 7.444-7.511 10.25-7.59 10.25l-.188.067-.189-.067-.003-.001c-.187-.07-7.586-2.869-7.586-10.249V4.217l.417-.106a24.328 24.328 0 0 0 3.683-1.3A25.001 25.001 0 0 0 9.7 1.028l.3-.195.306.19a24.998 24.998 0 0 0 3.377 1.783Zm-.066 10.277a.786.786 0 0 0 0-1.11L11.11 9.443l2.517-2.516a.786.786 0 0 0-1.111-1.111L10 8.333 7.483 5.817a.786.786 0 1 0-1.11 1.11l2.516 2.517-2.517 2.517a.778.778 0 0 0 0 1.111.794.794 0 0 0 1.111 0L10 10.556l2.505 2.527a.786.786 0 0 0 1.112 0Z"
        fill={fill}
      />
    </svg>
  )
}

export default Failed
