import React from 'react'

const Verified = ({ width = '20', height = '20', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Verified</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.683 2.806c1.19.533 2.42.97 3.678 1.305l.417.106V8.85c0 7.444-7.511 10.25-7.59 10.25l-.188.067-.189-.067-.003-.001c-.187-.07-7.586-2.869-7.586-10.249V4.217l.417-.106a24.328 24.328 0 0 0 3.683-1.3A25.001 25.001 0 0 0 9.7 1.028l.3-.195.306.19a24.998 24.998 0 0 0 3.377 1.783ZM8.617 13.833l6.205-6.027.028-.028a.786.786 0 0 0-1.111-1.111l-5.106 5-2.416-2.461a.786.786 0 0 0-1.111 1.11l3.51 3.517Z"
        fill="url(#verifiedBadge)"
      />
      <defs>
        <linearGradient
          id="verifiedBadge"
          x1="18.711"
          y1="9.93"
          x2="2.222"
          y2="9.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#500078" />
          <stop offset="1" stopColor="#9C3C9A" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Verified
