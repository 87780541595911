import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@pv3-shared-components/Button'
import { eq, isNull } from 'lodash'
import useDocTitle from '@pv3-hooks/useDocTitle'

const EmailVerified = () => {
  useDocTitle('Email Verification')
  const { loginWithRedirect } = useAuth0()
  const [isSuccessful, setIsSuccessful] = useState(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('success')) {
      setIsSuccessful(eq(urlParams.get('success'), 'true'))
    }
    if (urlParams.has('message')) {
      setMessage(urlParams.get('message'))
    }
  }, [])

  if (isNull(isSuccessful)) return <main className="SinglePanel" />

  return (
    <main className="SinglePanel">
      <div className="SinglePanel-panel">
        {!isSuccessful && (
          <React.Fragment>
            <h1 className="SinglePanel-title">Unable to verify email</h1>
            <p className="u-textCenter">
              <strong>{message}</strong>
            </p>
            <p className="u-textCenter">
              If you have any questions, please{' '}
              <a href="https://help.coinlion.com/kb-tickets/new">
                contact CoinLion support
              </a>
            </p>
          </React.Fragment>
        )}

        {isSuccessful && (
          <React.Fragment>
            <h1 className="SinglePanel-title">Email verified</h1>
            <p className="u-textCenter">Please sign in to continue</p>
            <p className="u-textCenter">
              <Button
                variant="primary"
                size="large"
                type="button"
                onClick={loginWithRedirect}
              >
                Sign in
              </Button>
            </p>
          </React.Fragment>
        )}
      </div>
    </main>
  )
}

export default EmailVerified
