import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isUndefined, get as _get, map, eq, isNil, sum, orderBy, floor, sortBy } from 'lodash'
import Request from '../utils/request'
import {API_PATHS, STATS_TIME_FRAMES} from '../constants'
import {convertRequiredSignalCountsToInts, formatNumber, isObjectNullOrEmpty} from "../utils/utils";
import moment from 'moment';
import clone from 'clone';
import TokenRequest from "../utils/tokenRequest";

const request = new Request();
let controller, signal;

const generateStrategySignalsFilters = () => {
  return {
    endDate: moment().format('MM/DD/YYYY'),
    startDate: moment().subtract(2, 'days').format('MM/DD/YYYY'),
    selectedEventType: '',
  };
};

const generateStrategyTradeHistoryFilters = () => {
  return {
    timePeriod: 'this_week',
    tradeType: '',
  };
};

export const createApiKey = createAsyncThunk(
  'strategies/createApiKey',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const v2Request = new TokenRequest(getState().account.idToken);
      const response = await v2Request.post(API_PATHS.COIN.API_KEY_GENERATE)
      return response.data
    } catch (e) {
      const error = _get(e, 'response.data.errors[0]')

      if (isUndefined(error)) return rejectWithValue('Server error')

      const message = error.message

      return rejectWithValue({ message })
    }
  }
)

export const createApiSecret = createAsyncThunk(
  'strategies/createApiSecret',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const v2Request = new TokenRequest(getState().account.idToken);
      const response = await v2Request.post(API_PATHS.COIN.API_SECRET_CYCLE)
      return response.data
    } catch (e) {
      const error = _get(e, 'response.data.errors[0]')

      if (isUndefined(error)) return rejectWithValue('Server error')

      const message = error.message

      return rejectWithValue({ message })
    }
  }
)

export const createStrategy = createAsyncThunk(
  'strategies/createStrategy',
  async (strategy, { rejectWithValue, dispatch }) => {
    try {
      strategy = convertRequiredSignalCountsToInts(strategy);
      const response = await request.post(API_PATHS.COIN.STRATEGIES, strategy)
      dispatch(getBuilder())
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data.errors);
    }
  }
)

export const updateStrategy = createAsyncThunk(
  'strategies/updateStrategy',
  async (strategy, { rejectWithValue, dispatch }) => {
    try {
      strategy = convertRequiredSignalCountsToInts(strategy);
      await request.put(`${API_PATHS.COIN.STRATEGIES}/${strategy.id}`, strategy)
      dispatch(getBuilder())
      return strategy
    } catch (e) {
      return rejectWithValue(e.response.data.errors);
    }
  }
)

export const deleteStrategy = createAsyncThunk(
  'trades/deleteStrategy',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await request.delete(API_PATHS.COIN.DELETE_STRATEGY(id))
      dispatch(getStrategies())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getActualMarketPerformance = createAsyncThunk(
  'strategies/getActualMarketPerformance',
  async ({market, startDate, endDate}, { getState, rejectWithValue }) => {
    try {
      const response = await new TokenRequest(getState().account.idToken).get(API_PATHS.COIN.ACTUAL_MARKET_PERFORMANCE(market, startDate, endDate));
      return response.data;
    } catch (e) {
      console.log(e)
      rejectWithValue(e.message);
    }
  }
)

export const getApiKey = createAsyncThunk(
  'strategies/getApiKey',
  async (_, { getState, rejectWithValue }) => {
    try {
      const v2Request = new TokenRequest(getState().account.idToken);
      const response = await v2Request.get(API_PATHS.COIN.API_KEY)
      return response.data;
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const getBacktestHistoryForStrategy = createAsyncThunk(
  'strategies/getBacktestHistoryForStrategy',
  async ({strategyId}, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.GET_BACKTEST_HISTORY(strategyId));
      return response.data.filter((d) => d.isAutomatic !== true);
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const getBacktestScenarios = createAsyncThunk(
  'strategies/getBacktestScenarios',
  async (_, { getState, rejectWithValue }) => {
    try {
      const v2Request = new TokenRequest(getState().account.idToken);
      const response = await v2Request.get(API_PATHS.COIN.BACKTEST_SCENARIOS);
      return sortBy(response.data, 'name');
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const getDashboardBacktestHistories = createAsyncThunk(
  'strategies/getDashboardBacktestHistories',
  async (_, { getState, rejectWithValue }) => {
    try {
      const v2Request = new TokenRequest(getState().account.idToken);
      const response = await v2Request.get(API_PATHS.COIN.DASHBOARD_BACKTEST_HISTORIES);
      return response.data;
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const pinBacktestHistoryItem = createAsyncThunk(
  'strategies/pinBacktestHistoryItem',
  async ({historyId, strategyId}, { dispatch, rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.PIN_BACKTEST_HISTORY(strategyId, historyId));
      dispatch(getBacktestHistoryForStrategy({strategyId}));
      return response;
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const unpinBacktestHistoryItem = createAsyncThunk(
  'strategies/unpinBacktestHistoryItem',
  async ({historyId, strategyId}, { dispatch, rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.UNPIN_BACKTEST_HISTORY(strategyId, historyId));
      dispatch(getBacktestHistoryForStrategy({strategyId}));
      return response;
    } catch(err) {
      rejectWithValue(err);
    }
  }
)

export const getStrategies = createAsyncThunk(
  'strategies/getStrategies',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.STRATEGIES)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const getStrategySignals = createAsyncThunk(
  'strategies/getStrategySignals',
  async ({strategyId, startDate, endDate}, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.STRATEGY_SIGNALS(strategyId, startDate, endDate));
      return { data: response.data, strategyId };
    } catch (e) {
      console.log(e)
    }
  }
)

export const getSupportedIntervals = createAsyncThunk(
  'strategies/getSupportedIntervals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.SUPPORTED_INTERVALS)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const getIndicators = createAsyncThunk(
  'strategies/getIndicators',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.STRATEGIES_INDICATORS)
      return sortBy(response.data, 'displayName');
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getDashboardStats = createAsyncThunk(
  'strategies/getDashboardStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.TRADING_STATS_DASHBOARD)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const getDynamicValuesForIndicatorSettings = createAsyncThunk(
  'strategies/getDynamicValuesForIndicatorSettings',
  async (data, { dispatch, getState, rejectWithValue }) => {
    const v2Request = new TokenRequest(getState().account.idToken);

    var requests = [];
    var indexes = [];
    data.forEach((d, i) => {
      if(d.hasValue === false) {
        requests.push(v2Request.post(API_PATHS.COIN.DYNAMIC_VALUES_FOR_INDICATOR_SETTINGS, d))
        indexes.push(i);
      }
    });


    return Promise.all(requests)
      .then((responses) => {
        let clonedData = clone(data);
        requests.forEach((r, i) => {
          clonedData[indexes[i]].hasValue = false;
          clonedData[indexes[i]].hasAllValues = true;
          clonedData[indexes[i]].values = [];
          responses[i].data.forEach((d) => {
            if(d.value) {
              clonedData[indexes[i]].values.push(formatNumber(d.value, 2, 2));
              clonedData[indexes[i]].hasValue = true;
            } else {
              clonedData[indexes[i]].hasAllValues = false;
            }
          });
        });

        return clonedData;
      })
      .catch((err) => {
        console.log('getDynamicValuesForIndicatorSettings error', err);
      });
  }
)

export const getBuilder = createAsyncThunk(
  'strategies/getBuilder',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.STRATEGIES_BUILDER)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
)

export const initiateManualBuyForStrategy = createAsyncThunk(
  'strategies/initiateManualBuyForStrategy',
  async ({strategyId}, { rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.MANUAL_BUY_FOR_STRATEGY(strategyId));
      return response;
    } catch (e) {
      console.log(e);
    }
  }
);

export const runStrategyBacktest = createAsyncThunk(
  'strategies/runBacktest',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      if(controller) {
        controller.abort();
      }

      controller = new AbortController();
      signal = controller.signal;
      const response = await fetch(`${window.envConfig.COINLION_API_URL}frontoffice/api/${API_PATHS.COIN.STRATEGY_BACKTEST}`, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        responseType: 'stream',
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      // eslint-disable-next-line no-undef
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let message = '';

      while (true) {
        const {value, done} = await reader.read();

        if (done) {
          try {
            const parsed = JSON.parse(message);
            let fullArray = [
              ...getState().strategies.backtestData,
              ...parsed,
            ];
            fullArray = orderBy(fullArray, ['generatedAt'], ['desc']);
            dispatch(setBacktestData(fullArray));
            dispatch(getBacktestHistoryForStrategy({strategyId: data.strategySettings.id}));
            return;
          } catch(err) {
            //console.log('incomplete data');
            throw err;
          }
        }

        message += value;
      }
    } catch(e) {
      //console.log('error in backtest', e.name);
      return rejectWithValue(e);
    }
  }
)

const strategiesSlice = createSlice({
  name: 'strategies',
  initialState: {
    apiKey: '',
    apiSecret: '',
    strategies: null,
    strategySignals : [],
    indicators: [],
    supportedIntervals: [],
    dashboardStats: {},
    backtestData: [],
    backtestHistory: [],
    backtestScenarios: [],
    backtestError: undefined,
    backtestSummary: {},
    backtestStartDate: moment().subtract(30, 'days').format('MM/DD/YYYY'),
    backtestEndDate: moment().format('MM/DD/YYYY'),
    builder: {},
    dashboardBacktestHistories: [],
    dynamicValuesSettings: [],
    isGettingApiKey: false,
    strategyTimePeriodFilter: STATS_TIME_FRAMES[0].value,
    strategySignalsFilters: generateStrategySignalsFilters(),
    strategyTradeHistoryFilters: generateStrategyTradeHistoryFilters(),
    temporaryStrategySettings: {},
  },
  reducers: {
    cancelBacktest: () => {
      if(controller) {
        controller.abort();
      }
    },
    clearApiSecret: (state) => {
      state.apiSecret = '';
    },
    clearBacktestData: (state, action) => {
      state.backtestData = [];
      state.backtestSummary = {};

      if(action.payload && action.payload.preserveStartEndDates !== true) {
        state.backtestStartDate = moment().subtract(30, 'days').format('MM/DD/YYYY');
        state.backtestEndDate = moment().format('MM/DD/YYYY');
      }
    },
    clearBacktestError: (state) => {
      state.backtestError = undefined;
    },
    clearDynamicValuesSettings: (state) => {
      state.dynamicValuesSettings = [];
    },
    clearSaveStrategyErrors: (state) => {
      state.saveStrategyErrors = undefined;
    },
    clearStrategyFiltersData: (state) => {
      state.strategySignalsFilters = generateStrategySignalsFilters();
      state.strategyTradeHistoryFilters = generateStrategyTradeHistoryFilters();
      state.strategySignalsStrategyId = undefined;
      state.strategyTradeHistoryStrategyId = undefined;
    },
    clearStrategySignals: (state) => {
      state.strategySignals = [];
    },
    setBacktestData: (state, action) => {
      state.backtestData = action.payload;
    },
    setBacktestSummary: (state, action) => {
      const { actualMarketPerformance, backtestData, baseAsset, usdPrices } = action.payload;

      let summary = {
        grossProfitLoss: 0,
        profitPerTradePercentage: 0,
        winRate: 0,
        quantityOfWins: 0,
        totalProfits: 0,
        averageProfit: 0,
        averageMinutesPerWin: null,
        averageProfitPercent: 0,
        lowestProfitPercent: null,
        highestProfitPercent: null,
        lossRate: 0,
        quantityOfLosses: 0,
        totalLosses: 0,
        averageLoss: 0,
        averageLossPercent: 0,
        averageMinutesPerLoss: null,
        lowestLossPercent: null,
        highestLossPercent: null,
        openPositionsQuantity: 0,
        openPositionsProfit: 0,
        openPositionsProfitPercent: 0,
        strategyTimePeriodFilter: 3,
        strategyOrderStatusFilter: '',
        strategyPublisherFilter: '',
        strategyTradeTypeFilter: '',
        strategySortByFilter: 'name',
        strategyMarketsFilter: '',
        actualMarketValue: actualMarketPerformance,
      };

      let profitPercentages = [];
      let lossPercentages = [];
      let totalProfitsAge = 0;
      let totalLossesAge = 0;
      let positions = {};

      backtestData.forEach((item, index) => {
        if(item.type === 'TriggerBuy') {
          positions[item.positionId] = clone(item);
        }
      });

      backtestData.forEach(({amount, closePrice, created, generatedAt, openPrice, positionId}) => {
        if(!isNil(closePrice) && !isNil(openPrice)) {
          let profit = closePrice - openPrice;
          let profitPercentage = profit / openPrice;
          let age = new Date(generatedAt).getTime() - new Date(created).getTime();

          if(profit >= 0) {
            summary.quantityOfWins++;
            summary.totalProfits += (profit * amount);
            profitPercentages.push(profitPercentage);
            totalProfitsAge += age;

            if(summary.lowestProfitPercent == null || summary.lowestProfitPercent > profitPercentage) {
              summary.lowestProfitPercent = profitPercentage;
            }

            if(summary.highestProfitPercent == null || summary.highestProfitPercent < profitPercentage) {
              summary.highestProfitPercent = profitPercentage;
            }
          } else {
            summary.quantityOfLosses++;
            summary.totalLosses += (profit * amount);
            lossPercentages.push(profitPercentage);
            totalLossesAge += age;

            if(summary.lowestLossPercent == null || summary.lowestLossPercent > profitPercentage) {
              summary.lowestLossPercent = profitPercentage;
            }

            if(summary.highestLossPercent == null || summary.highestLossPercent < profitPercentage) {
              summary.highestLossPercent = profitPercentage;
            }
          }


          if(positions[positionId]) {
            try {
              positions[positionId].amount = positions[positionId].amount - amount;

              if(positions[positionId].amount === 0) {
                delete positions[positionId];
              }
            } catch(err) {
              console.log(err);
            }
          }
        }
      });

      if(!isObjectNullOrEmpty(positions)) {
        summary.openPositionsQuantity = Object.keys(positions).length;
        let positionsPercentGainLoss = 0;

        Object.keys(positions).forEach((key) => {
          let profit = positions[key].amount * (usdPrices[baseAsset] - positions[key].openPrice);
          summary.openPositionsProfit += profit;
          positionsPercentGainLoss += (usdPrices[baseAsset] - positions[key].openPrice) / positions[key].openPrice;
        })

        summary.openPositionsProfitPercent = (positionsPercentGainLoss / Object.keys(positions).length) * 100;
      }


      if(summary.quantityOfWins) {
        summary.winRate = (summary.quantityOfWins / (summary.quantityOfWins + summary.quantityOfLosses)) * 100;
        summary.averageProfit = summary.totalProfits / summary.quantityOfWins;
        summary.averageProfitPercent = (sum(profitPercentages) / profitPercentages.length) * 100;
        summary.averageMinutesPerWin = floor((totalProfitsAge / summary.quantityOfWins) / (1000 * 60));
      }

      if(summary.quantityOfLosses) {
        summary.lossRate = (summary.quantityOfLosses / (summary.quantityOfWins + summary.quantityOfLosses)) * 100;
        summary.averageLoss = summary.totalLosses / summary.quantityOfLosses;
        summary.averageLossPercent = (sum(lossPercentages) / lossPercentages.length) * 100;
        summary.averageMinutesPerLoss = floor((totalLossesAge / summary.quantityOfLosses) / (1000 * 60));
      }

      summary.lowestProfitPercent = summary.lowestProfitPercent * 100;
      summary.highestProfitPercent = summary.highestProfitPercent * 100;

      summary.lowestLossPercent = summary.lowestLossPercent * 100;
      summary.highestLossPercent = summary.highestLossPercent * 100;

      summary.grossProfitLoss = summary.totalProfits + summary.totalLosses;

      summary.averagePercentPerTrade = ((sum(lossPercentages) + sum(profitPercentages)) / (lossPercentages.length + profitPercentages.length)) * 100;

      state.backtestSummary = summary;
    },
    setBacktestSummaryFromHistory: (state, action) => {
      let summary = {...action.payload.summary};
      summary.startingBalance = action.payload.startingBalance;
      summary.fromHistory = true;
      state.backtestSummary = summary;
      state.backtestData = [];
    },
    setBacktestStartDate: (state, action) => {
      state.backtestStartDate = action.payload;
    },
    setBacktestEndDate: (state, action) => {
      state.backtestEndDate = action.payload;
    },
    setDynamicValuesSettings: (state, action) => {
      state.dynamicValuesSettings = action.payload;
    },
    setStrategyTimePeriodFilter: (state, action) => {
      state.strategyTimePeriodFilter = action.payload;
    },
    setStrategyOrderStatusFilter: (state, action) => {
      state.strategyOrderStatusFilter = action.payload;
    },
    setStrategyPublisherFilter: (state, action) => {
      state.strategyPublisherFilter = action.payload;
    },
    setStrategyTradeTypeFilter: (state, action) => {
      state.strategyTradeTypeFilter = action.payload;
    },
    setStrategySortByFilter: (state, action) => {
      state.strategySortByFilter = action.payload;
    },
    setStrategyMarketsFilter: (state, action) => {
      state.strategyMarketsFilter = action.payload;
    },
    setStrategySignalsFilters: (state, action) => {
      state.strategySignalsFilters = action.payload;
    },
    setStrategyTradeHistoryFilters: (state, action) => {
      state.strategyTradeHistoryFilters = action.payload;
    },
    setStrategyTradeHistoryStrategyId: (state, action) => {
      state.strategyTradeHistoryStrategyId = action.payload;
    },
    setTemporaryStrategySettings: (state, action) => {
      state.temporaryStrategySettings = action.payload;
    },
  },
  extraReducers: {
    [createApiKey.fulfilled]: (state, action) => {
      state.apiKey = action.payload
    },
    [createApiSecret.fulfilled]: (state, action) => {
      state.apiSecret = action.payload
    },
    [getApiKey.pending]: (state) => {
      state.isGettingApiKey = true;
    },
    [getApiKey.fulfilled]: (state, action) => {
      state.apiKey = action.payload;
      state.isGettingApiKey = false;
    },
    [getBacktestHistoryForStrategy.fulfilled]: (state, action) => {
      state.backtestHistory = action.payload;
    },
    [getBacktestScenarios.fulfilled]: (state, action) => {
      state.backtestScenarios = action.payload;
    },
    [getDashboardBacktestHistories.fulfilled]: (state, action) => {
      state.dashboardBacktestHistories = action.payload;
    },
    [getDynamicValuesForIndicatorSettings.fulfilled]: (state, action) => {
      state.dynamicValuesSettings = action.payload;
    },
    [getStrategies.fulfilled]: (state, action) => {
      state.strategies = action.payload;
      // This requestTime was added to limit the number of times getStrategies is called. CLV3-908
      state.getStrategiesRequestTime = new Date().getTime();
    },
    [getStrategySignals.fulfilled]: (state, action) => {
      state.strategySignals = action.payload.data;
      state.strategySignalsStrategyId = action.payload.strategyId;
    },
    [getSupportedIntervals.fulfilled]: (state, action) => {
      state.supportedIntervals = action.payload
    },
    [getIndicators.fulfilled]: (state, action) => {
      state.indicators = action.payload
    },
    [getDashboardStats.fulfilled]: (state, action) => {
      state.dashboardStats = action.payload
    },
    [getBuilder.fulfilled]: (state, action) => {
      state.builder = action.payload
    },
    [createStrategy.pending]: (state, action) => {
      state.saveStrategyErrors = undefined;
    },
    [createStrategy.fulfilled]: (state, action) => {
      state.strategies = [...state.strategies, action.payload]
    },
    [createStrategy.rejected]: (state, action) => {
      state.saveStrategyErrors = action.payload
    },
    [updateStrategy.pending]: (state, action) => {
      state.saveStrategyErrors = undefined;
    },
    [updateStrategy.fulfilled]: (state, action) => {
      state.strategies = map(state.strategies, (strategy) =>
        eq(strategy.id, action.payload.id) ? action.payload : strategy
      )
    },
    [updateStrategy.rejected]: (state, action) => {
      state.saveStrategyErrors = action.payload;
    },
    [runStrategyBacktest.pending]: (state, action) => {
      state.backtestError = undefined;
      state.backtestData = [];
    },
    [runStrategyBacktest.rejected]: (state, action) => {
      // If the error is not an abort error, we want to set the error state to true.
      // The error will be an AbortError if a user cancels a backtest.
      if(action.payload.name !== 'AbortError') {
        state.backtestError = {hasError: true};
      }
    },
  },
})

export const { cancelBacktest, clearApiSecret, clearBacktestData, clearBacktestError, clearDynamicValuesSettings, clearSaveStrategyErrors, clearStrategyFiltersData, clearStrategySignals, setBacktestData, setBacktestSummary, setBacktestSummaryFromHistory, setBacktestStartDate, setBacktestEndDate, setDynamicValuesSettings, setStrategySignalsFilters, setStrategyTradeHistoryFilters, setStrategyTradeHistoryStrategyId, setStrategyTimePeriodFilter, setStrategyPublisherFilter, setStrategyTradeTypeFilter, setStrategySortByFilter, setStrategyMarketsFilter, setStrategyOrderStatusFilter, setTemporaryStrategySettings } = strategiesSlice.actions

export default strategiesSlice.reducer
