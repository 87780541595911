import React from 'react'
import { map, eq, upperCase } from 'lodash'
import Currency from '../shared/Currency'
import Logo from "../shared/Logo";
import useWalletsDuck from '../../hooks/useWalletsDuck';
import Tooltip from '../shared/Tooltip';

const SelectableAssetList = ({ assets, disableAssetsWithoutUsdPrice, onSelect }) => {
  const { usdPrices } = useWalletsDuck();

  const isAssetDisabled = (asset) => {
    if(!disableAssetsWithoutUsdPrice) return false;
    return !usdPrices[asset.id];
  }

  return (
    <React.Fragment>
      {map(assets, (asset) => {
        const isDisabled = isAssetDisabled(asset);

        return (
          <div
            key={asset.id}
            className={`ModalCryptoList-row ${isDisabled ? 'disabled' : 'clickable'}`}
            onClick={isDisabled ? null : () => onSelect(asset)}
            role="button"
          >
            <div className="ModalCryptoList-currencyContainer">
              {eq(asset.id, 'lion') ? (
                <Logo/>
              ) : (
                <Currency type={asset.id}/>
              )}
            </div>
            <p>
              {asset.asset_name} ({upperCase(asset.id)})
            </p>

            {isDisabled && (
              <Tooltip>
                  This asset is temporarily disabled due to technical reasons. Please check back later.
              </Tooltip>
            )}
          </div>
        )
      })}
    </React.Fragment>
  )
}

export default SelectableAssetList
