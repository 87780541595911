import React from 'react'
import StrategyThird from '@pv3-components/StrategyThird'
import Tooltip from '@pv3-shared-components/Tooltip'
import Input from '@pv3-shared-components/Input'
import InputError from '@pv3-shared-components/InputError'
import { useFormContext } from 'react-hook-form'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import { eq, toNumber, isNull, round } from 'lodash'
import { formatAsDollarAmount } from '../../utils/utils'
import Loading from '@pv3-shared-components/Loading'
import { OrderTypes } from '../../utils/enums';

const StrategyThirds = ({strategyId}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const { builder } = useStrategiesDuck()
  const {
    totalValueInUSD,
    walletValuesInUSD: { lion: lionValueInUSD },
  } = useWalletsDuck()
  const totalValueInUSDMinusLion = totalValueInUSD - (lionValueInUSD || 0)

  /*
 * This is a hack to get the checkboxes to work with react-hook-form and string values
 * checked === OrderTypes.Market
 * unchecked === OrderTypes.Limit
 */
  const watchedBuyOrderType = watch('buyOrderType');
  const watchedSellOrderType = watch('sellOrderType');
  const watchedStopLossOrderType = watch('stopLossOrderType');

  const watchedDisableBuyAfterBuy = watch('disableBuyAfterBuy');
  const watchedDisableBuyAfterSell = watch('disableBuyAfterSell');
  const watchedDisableBuyAfterStopLoss = watch('disableBuyAfterStopLoss');

  const calculateMaxStrategyAmount = (holdingPercent, totalValueInUSD) => {
    if (!totalValueInUSD) {
      return 0
    }
    return totalValueInUSD * (toNumber(holdingPercent) / 100)
  }

  return (
    <React.Fragment>
      <div className="u-container MyPortfolioCard StrategyNotes">
        <h2 className="h4">Notes</h2>

        <Input
          inputType="textarea"
          autoComplete="off"
          name="notes"
        />
      </div>

      <div className="Strategy-thirds">
        <StrategyThird title="Portfolio allocation">
          {isNull(totalValueInUSD) && (
            <div className="ComponentLoader">
              <Loading />
            </div>
          )}
          <Input
            id="allocation_percent_input"
            data-testid="allocation_percent_input"
            type="number"
            min="0"
            max="100"
            name="holdingPercent"
            registerConfig={{
              required: 'Allocation percent is required',
            }}
            className={`Input u-tr ${
              errors.holdingPercent ? 'Input--error' : ''
            }`}
            suffix="%"
            helperText="Portion of your auto-tradable balance that will use this strategy to autotrade"
            error={
              errors.holdingPercent && (
                <InputError message={errors.holdingPercent.message} />
              )
            }
            calculatedTotal={
              !eq(watch('holdingPercent'), '') &&
              formatAsDollarAmount(
                calculateMaxStrategyAmount(
                  watch('holdingPercent'),
                  totalValueInUSDMinusLion
                )
              )
            }
            inputMode="decimal"
          >
            Allocation percent
          </Input>
          <section className="Strategy-third-portfolio">
            <h3 className="h6">My Portfolio</h3>
            <div>
              <strong className="Strategy-third-portfolio-availablePercent">
                {round(builder.portfolioPercentAvailable, 2)}%
              </strong>{' '}
              Available
            </div>
            <div>
              <strong>{round(builder.portfolioPercentAllocated, 2)}%</strong>{' '}
              Allocated
            </div>
            <hr />
            <strong className="Strategy-third-portfolio-autoTradable">
              <Tooltip modalTitle="Auto-tradable balance">
                Your auto-tradable balance is the total value of all your assets
                that can be auto-traded. Generally speaking, this is usually your
                total account value not including LION.
              </Tooltip>
              {formatAsDollarAmount(totalValueInUSDMinusLion)}
            </strong>
            Auto-tradable balance
          </section>

          <section className="Strategy-third-tradeType">
            <label className="SinglePanel-checkbox">
              <input
                  id="buy-order-type-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setValue('buyOrderType', e.target.checked ? OrderTypes.Market : OrderTypes.Limit);
                  }}
                  checked={watchedBuyOrderType === OrderTypes.Market}
              />
              Use Market Orders for all Buys
            </label>

            <label className="SinglePanel-checkbox">
              <input
                  id="sell-order-type-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setValue('sellOrderType', e.target.checked ? OrderTypes.Market : OrderTypes.Limit);
                  }}
                  checked={watchedSellOrderType === OrderTypes.Market}
              />
              Use Market Orders for all Sells
            </label>

            <label className="SinglePanel-checkbox">
              <input
                  id="stop-loss-order-type-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    setValue('stopLossOrderType', e.target.checked ? OrderTypes.Market : OrderTypes.Limit);
                  }}
                  checked={watchedStopLossOrderType === OrderTypes.Market}
              />
              Use Market Orders for all Stop Losses
            </label>
          </section>
        </StrategyThird>
        <StrategyThird title="Strategy settings">
          {isNull(totalValueInUSD) && (
            <div className="ComponentLoader">
              <Loading />
            </div>
          )}
          <Input
            type="number"
            min="0"
            wrapperTag="div"
            id="max_open_positions_input"
            data-testid="max_open_positions_input"
            name="maxOpenPositions"
            registerConfig={{
              required: 'Maximum number of open positions is required',
            }}
            className={`Input u-tr ${
              errors.maxOpenPositions ? 'Input--error' : ''
            }`}
            error={
              errors.maxOpenPositions && (
                <InputError message={errors.maxOpenPositions.message} />
              )
            }
            calculatedTotal={
              !eq(watch('holdingPercent'), '') &&
              !eq(watch('maxOpenPositions'), '') &&
              formatAsDollarAmount(
                calculateMaxStrategyAmount(
                  watch('holdingPercent'),
                  totalValueInUSDMinusLion
                ) / watch('maxOpenPositions')
              )
            }
            calculatedTotalSuffix="ea."
            inputMode="number"
          >
            <label htmlFor="maxOpenPositions">
              Maximum number of open positions
            </label>
            <Tooltip modalTitle="Maximum number of open positions">
              A single strategy can open up more than one position. This field
              indicates the maximum number of positions that can be open at the
              same time. The other fields within this section will help you
              control when positions are opened and closed.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            step="0.01"
            wrapperTag="div"
            id="sell_profit_percent_input"
            data-testid="sell_profit_percent_input"
            name="sellProfitPercent"
            className="Input u-tr"
            suffix="%"
            inputMode="decimal"
          >
            <label htmlFor="sellProfitPercent">Goal % gain on each trade</label>
            <Tooltip modalTitle="Goal % gain on each trade">
              This is the minimum desired profit per closed position. Example: If
              you put a 1 in this field, the position will automatically sell when
              it has a profit of 1% or greater. If sell signals are enabled below,
              this field will be ignored, even if you enter a number if this
              field.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            step="0.01"
            wrapperTag="div"
            id="stoploss_input"
            data-testid="stoploss_input"
            name="stopLoss"
            className="Input u-tr"
            suffix="%"
            inputMode="decimal"
          >
            <label htmlFor="stopLoss">
              Close my position if it is down more than
            </label>
            <Tooltip modalTitle="Close my position if it is down more than">
              This will sell a position if down by this percent or greater
              regardless of other sell settings. Example: If you enter a 3 into
              this field, then the system will sell when a position is down 3% or
              greater. Important Note: This will sell even if "Enable Selling" is
              off and will override all other sell settings. It will always sell
              when the target is met. If the market is dropping quickly, losses
              may exceed the % specified in this field.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            step="0.01"
            wrapperTag="div"
            id="iceberg_percentage_input"
            data-testid="iceberg_percentage_input"
            name="icebergPercentage"
            suffix="%"
            registerConfig={{
              required:
                '"Open add\'l positions when price is better by:" is required',
            }}
            className={`Input u-tr ${
              errors.icebergPercentage ? 'Input--error' : ''
            }`}
            error={
              errors.icebergPercentage && (
                <InputError message={errors.icebergPercentage.message} />
              )
            }
            inputMode="decimal"
          >
            <label htmlFor="icebergPercentage">
              Open add'l positions when price is better by
            </label>
            <Tooltip modalTitle="Open add'l positions when price is better by">
              Commonly known as the Iceberg Rule, this will only allow another
              position to be opened if the price is less than the price of the
              previous position by the % specified. Example: If you place 0.50 in
              this field, the strategy will only open a new position if the
              pricing is 0.50% better than the prior position opened. This field
              becomes useless and is ignored if you have a “1” in the “Maximum
              number of open positions” field.
            </Tooltip>
          </Input>
        </StrategyThird>
        <StrategyThird title="Cool down">
          <Input
            type="number"
            min="0"
            wrapperTag="div"
            id="after_open_minutes_input"
            data-testid="after_open_minutes_input"
            name="afterOpenMinutes"
            registerConfig={{
              required: 'Time after a buy, before the next buy is required',
            }}
            className={`Input u-tr ${
              errors.afterOpenMinutes ? 'Input--error' : ''
            }`}
            error={
              errors.afterOpenMinutes && (
                <InputError message={errors.afterOpenMinutes.message}/>
              )
            }
            suffix="minutes"
            inputMode="decimal"
          >
            <label htmlFor="afterOpenMinutes">
              Time after a buy, before the next buy
            </label>
            <Tooltip modalTitle="Time after a buy, before the next buy">
              If your strategy allows for more than 1 position to be open at a
              time, the system will wait at least this long before it opens
              another position. This allows you to set a “cool down” between buys.
              People usually use this field or the “Open Add’l positions when
              price is better by” field, but not both.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            wrapperTag="div"
            id="after_close_prevent_open_minutes_input"
            data-testid="after_close_prevent_open_minutes_input"
            name="afterClosePreventOpenMinutes"
            registerConfig={{
              required: 'Time after a sell, before a buy is required',
            }}
            className={`Input u-tr ${
              errors.afterClosePreventOpenMinutes ? 'Input--error' : ''
            }`}
            error={
              errors.afterClosePreventOpenMinutes && (
                <InputError
                  message={errors.afterClosePreventOpenMinutes.message}
                />
              )
            }
            suffix="minutes"
            inputMode="decimal"
          >
            <label htmlFor="afterClosePreventOpenMinutes">
              Time after a sell, before a buy
            </label>
            <Tooltip modalTitle="Time after a sell, before a buy">
              Small, but quick market fluctuations can sometimes trigger sell and
              buy signals almost simultaneously. Use this field to avoid buying
              right back in after a sell. After placing a sell, the system will
              wait at least the time specified here before placing a buy. This is
              particularly useful if you are using EMAs for buy and sell signals.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            wrapperTag="div"
            id="after_open_prevent_close_minutes_input"
            data-testid="after_open_prevent_close_minutes_input"
            name="afterOpenPreventCloseMinutes"
            registerConfig={{
              required: 'Time after a buy, before a sell is required',
            }}
            className={`Input u-tr ${
              errors.afterOpenPreventCloseMinutes ? 'Input--error' : ''
            }`}
            error={
              errors.afterOpenPreventCloseMinutes && (
                <InputError
                  message={errors.afterOpenPreventCloseMinutes.message}
                />
              )
            }
            suffix="minutes"
            inputMode="decimal"
          >
            <label htmlFor="afterOpenPreventCloseMinutes">
              Time after a buy, before a sell
            </label>
            <Tooltip modalTitle="Time after a buy, before a sell">
              Small, but quick market fluctuations can sometimes trigger buy and
              sell signals almost simultaneously. Use this field to avoid selling
              a position right after it was opened. After placing a buy, the
              system will wait for the amount of time specified here before
              placing a sell. This is particularly useful if you are using EMAs
              for buy and sell signals.
            </Tooltip>
          </Input>
          <Input
            type="number"
            min="0"
            wrapperTag="div"
            id="time_after_a_stop_loss_before_a_buy"
            data-testid="time_after_a_stop_loss_before_a_buy"
            name="afterStopLossPreventOpenMinutes"
            registerConfig={{
              required: 'Time after a stop loss, before a buy',
            }}
            className={`Input u-tr ${
              errors.afterStopLossPreventOpenMinutes ? 'Input--error' : ''
            }`}
            error={
              errors.afterStopLossPreventOpenMinutes && (
                <InputError
                  message={errors.afterStopLossPreventOpenMinutes.message}
                />
              )
            }
            suffix="minutes"
            inputMode="decimal"
          >
            <label htmlFor="afterOpenPreventCloseMinutes">
              Time after a stop loss, before a buy
            </label>
            <Tooltip modalTitle="Time after a buy, before a sell">
              This will pause buying for this strategy after a stop loss has
              occurred for the period of time you specify. This cool down will
              trigger for all stop losses, whether it's from a percent loss or
              stop loss signal.
            </Tooltip>
          </Input>


          <section className="Strategy-third-tradeType">
            <label className="SinglePanel-checkbox">
              <input
                id="buy-order-type-checkbox"
                type="checkbox"
                onChange={(e) => {
                  setValue('disableBuyAfterBuy', e.target.checked);
                }}
                checked={watchedDisableBuyAfterBuy}
              />
              Disable Buying on a Successful Buy
            </label>

            <label className="SinglePanel-checkbox">
              <input
                id="buy-order-type-checkbox"
                type="checkbox"
                onChange={(e) => {
                  setValue('disableBuyAfterSell', e.target.checked);
                }}
                checked={watchedDisableBuyAfterSell}
              />
              Disable Buying on a Successful Sell
            </label>

            <label className="SinglePanel-checkbox">
              <input
                id="buy-order-type-checkbox"
                type="checkbox"
                onChange={(e) => {
                  setValue('disableBuyAfterStopLoss', e.target.checked);
                }}
                checked={watchedDisableBuyAfterStopLoss}
              />
              Disable Buying on a Successful Stop Loss
            </label>
          </section>
        </StrategyThird>
      </div>
    </React.Fragment>
  )
}

export default StrategyThirds
