import './ProgressBar.scss';

import React from 'react';

const ProgressBar = ({
    progress = 30
}) => {
    return (
        <div className="ProgressBar">
            <div
                className="ProgressBar__inner"
                style={{width: `${progress}%`}}
            />
        </div>
    );
};

export default ProgressBar;
