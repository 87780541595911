import './liquidatorForm.scss';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { calculateStep, fixFloatingPointErrors } from "../../../../../../../utils/utils";

import {
  enrollInLiquidator,
  getLiquidatorEnrollments,
  updateLiquidatorEnrollment
} from "../../../../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../../../../ducks";
import useMarketsDuck from "../../../../../../../hooks/useMarketsDuck";
import useProfileDuck from "../../../../../../../hooks/useProfileDuck";

import Button, { ButtonTypes, ButtonVariants } from "../../../../../../shared/Button";
import { InputTypes } from "../../../../../../shared/Input";
import Select from "../../../../../../shared/Select";
import Tooltip from '../../../../../../shared/Tooltip';
import { KycVerificationStatuses } from "../../../../../../../utils/enums";

const LiquidatorForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const { pairs } = useMarketsDuck();
  const { profile } = useProfileDuck();

  const [programId, setProgramId] = useState<string>('');
  const [lionQty, setLionQty] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sellMinimum, setSellMinimum] = useState<number | undefined>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const { liquidatorEnrollment, liquidatorPrograms, userTotals } = useTypedSelector((state) => state.rewards);

  const lionMarket = pairs?.lion_usdc;

  const handleMaxPress = () => {
    setLionQty(fixFloatingPointErrors(userTotals.totalLionBalance - userTotals.lionHodlLocked - userTotals.lionInOpenOrders, 8));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!programId || !lionQty || !sellMinimum) {
      return;
    }

    try {
      setLoading(true);

      if(liquidatorEnrollment?.id) {
        await dispatch(updateLiquidatorEnrollment({ enrollmentId: liquidatorEnrollment.id, lionQty, programId, sellMinimum, isActive: liquidatorEnrollment.isActive })).unwrap();
      } else {
        await dispatch(enrollInLiquidator({ lionQty, programId, sellMinimum, isActive: true })).unwrap();
      }

      await dispatch(getLiquidatorEnrollments()).unwrap();
      setShowSuccessMessage(true);
    } catch(err) {
      console.log('LiquidatorForm handleSubmit error', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!liquidatorEnrollment?.id) {
      return;
    }

    setProgramId(liquidatorEnrollment.programId);
    setLionQty(liquidatorEnrollment.remainingLion);
    setSellMinimum(liquidatorEnrollment.sellMinimum);
  }, [liquidatorEnrollment]);

  return (
    <form
      className="LiquidatorForm"
      onSubmit={handleSubmit}
    >

      <div className="LiquidatorForm-LionQty">
        <div className="LiquidatorForm-LionQtyValue">
          {liquidatorEnrollment?.remainingLion ? `${liquidatorEnrollment.remainingLion.toLocaleString()} LION` : '0 LION'}
        </div>

        <div className="LiquidatorForm-LionQtyLabel">
          Currently in the Liquidator

          <Tooltip>
            Future Tooltip
          </Tooltip>
        </div>
      </div>

      <div className="LiquidatorForm-Inputs">
        <div className="LiquidatorForm-Input">
          <label className="Label">
            Liquidator Mode

            <Select
              onChange={(e) => setProgramId(e.target.value)}
              value={programId}
            >
              <option value="">Select a mode</option>
              {liquidatorPrograms.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </label>
        </div>

        <div className="LiquidatorForm-Input">
          <label className="Label">
            Never Sell Below

            <input
              className="Input"
              name="sellMinimum"
              onChange={(e) => setSellMinimum(Number(e.target.value))}
              step={0.01}
              type={InputTypes.Number}
              value={sellMinimum}
            />
          </label>
        </div>

        <div className="LiquidatorForm-Input">
          <label className="Label">
            Amount of LION <span>you want in the Liquidator</span>

            <input
              className="Input"
              name="lionQty"
              onChange={(e) => setLionQty(Number(e.target.value))}
              max={fixFloatingPointErrors(userTotals.totalLionBalance - userTotals.lionInOpenOrders, lionMarket.priceScale)}
              step={lionMarket ? calculateStep(lionMarket.priceScale) : '0.01'}
              type={InputTypes.Number}
              value={lionQty}
            />
          </label>

          <Button
            className="AddLionToHodl-maxButton"
            onClick={handleMaxPress}
            type={ButtonTypes.Button}
            variant={ButtonVariants.Blank}
          >
            max
          </Button>
        </div>
      </div>

      <div className="LiquidatorForm-Buttons">
        <Button
          className="LiquidatorForm-Button"
          disabled={!lionQty || !programId || !sellMinimum || profile?.kycVerificationStatus !== KycVerificationStatuses.Verified}
          isLoading={loading}
          type={ButtonTypes.Submit}
          variant={ButtonVariants.Primary}
        >
          {liquidatorEnrollment?.id ? 'Update' : 'Add'}
        </Button>
      </div>

      {showSuccessMessage && (
        <div className="LiquidatorForm-SuccessMessage">
          Your updates will be used on the next run of the Liquidator Service.
        </div>
      )}
    </form>
  );
};

export default LiquidatorForm;
