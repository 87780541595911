import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { API_PATHS } from '../constants'

const request = new Request()

export const fetchAssetsInfo = createAsyncThunk(
  'assets/fetchAssetsInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.ASSETS_INFO)
      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
  },
  reducers: {},
  extraReducers: {
    [fetchAssetsInfo.fulfilled]: (state, action) => ({
      assets: action.payload.data,
    }),
  },
})

export default assetsSlice.reducer
