import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { API_PATHS } from '@pv3-constants'

const request = new Request()

export const getBalancer = createAsyncThunk(
  'balancer/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.BALANCER)
      return response.data
    } catch (e) {
      rejectWithValue(e.message)
    }
  }
)

export const updateBalancer = createAsyncThunk(
  'balancer/update',
  async (settings, { rejectWithValue }) => {
    try {
      const response = await request.put(API_PATHS.COIN.BALANCER, settings)
      return response.data
    } catch (e) {
      rejectWithValue(e.message)
    }
  }
)

const balancerSlice = createSlice({
  name: 'balancer',
  initialState: {},
  extraReducers: {
    [getBalancer.fulfilled]: (state, action) => action.payload,
    [updateBalancer.fulfilled]: (state, action) => action.payload,
  },
})

export default balancerSlice.reducer
