import React from 'react'
import {map, gt, find} from 'lodash'
import './strategySignalSettings.scss'
import { useFormContext, useFieldArray } from 'react-hook-form'
import StrategySignalSelector from '@pv3-components/StrategySignalSelector'
import StrategySignalSettingsRow from '@pv3-components/StrategySignalSettingsRow'

const REMOVE_OTHERS_ERROR_STRING = 'You can\'t use a webhook in conjunction with other signals. Please remove the others to add a webhook.';
const REMOVE_WEBHOOK_ERROR_STRING = 'You can\'t use a webhook in conjunction with other signals. Please remove the webhook to add others.';

const StrategySignalSettings = ({initializeApiKey, isEditing}) => {
  const { control } = useFormContext()

  const {
    fields: orderSizeFields,
    prepend: osPrepend,
    remove: osRemove,
  } = useFieldArray({
    name: 'orderSizeIndicatorTriggerSettings.triggerIndicatorSettings',
  })
  const {
    fields: buyFields,
    prepend: bPrepend,
    remove: bRemove,
  } = useFieldArray({
    name: 'buyIndicatorTriggerSettings.triggerIndicatorSettings',
  })
  const {
    fields: sellFields,
    prepend: sPrepend,
    remove: sRemove,
  } = useFieldArray({
    control,
    name: 'sellIndicatorTriggerSettings.triggerIndicatorSettings',
  })
  const {
    fields: stopLossFields,
    prepend: slPrepend,
    remove: slRemove,
  } = useFieldArray({
    control,
    name: 'stopLossIndicatorTriggerSettings.triggerIndicatorSettings',
  })
  const {
    fields: pauseBuyFields,
    prepend: pbPrepend,
    remove: pbRemove,
  } = useFieldArray({
    control,
    name: 'buyIndicatorManagementSettings.disableIndicatorSettings',
  })
  const {
    fields: resumeBuyFields,
    prepend: rbPrepend,
    remove: rbRemove,
  } = useFieldArray({
    control,
    name: 'buyIndicatorManagementSettings.enableIndicatorSettings',
  })
  const {
    fields: pauseSellFields,
    prepend: psPrepend,
    remove: psRemove,
  } = useFieldArray({
    control,
    name: 'sellIndicatorManagementSettings.disableIndicatorSettings',
  })
  const {
    fields: resumeSellFields,
    prepend: rsPrepend,
    remove: rsRemove,
  } = useFieldArray({
    control,
    name: 'sellIndicatorManagementSettings.enableIndicatorSettings',
  })

  const testAddingSignal = (indicator, fields) => {
    if(indicator === 'Webhook' && gt(fields.length, 0)) {
      setTimeout(() => window.alert(REMOVE_OTHERS_ERROR_STRING), 100);
      return true;
    } else if(find(fields, (f) => f.indicator === 'Webhook')) {
      setTimeout(() => window.alert(REMOVE_WEBHOOK_ERROR_STRING), 100);
      return true;
    } else if(indicator === 'Webhook' && initializeApiKey) {
      initializeApiKey();
    }

    return false;
  }

  return (
    <div className="StrategySignals">

      <div className="u-container StrategySignals-section Strategy-content StrategySignals-section--orderSizing">
        <div className="StrategySignals-top">
          <h3 className="StrategySignals-heading StrategySignals-heading--green-circle">
            Position Sizing
          </h3>
          <div className="StrategySignals-subheading">
            This Section will increase or decrease the Allocation %. In theory, you want your Allocation to decrease in a bear market and increase in a bull market. In a Bull market you might want 100% of your normal allocation and in a bear market you might want 50%. This section manages this concept.
          </div>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="orderSize"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, orderSizeFields)) {
                return;
              }

              osPrepend(indicator)
            }}
            fields={orderSizeFields}
          />
        </div>
        {orderSizeFields &&
          map(orderSizeFields, ({id, indicator, ...values}, index) => (
            <div
              key={id}
              className="StrategySignals-signal StrategySignals-panel"
            >
              <StrategySignalSettingsRow
                index={index}
                isEditing={isEditing}
                closeFn={() => osRemove(index)}
                signalType="orderSize"
                indicator={indicator}
                values={values}
              />
            </div>
          ))}
      </div>

      <hr className="StrategySignals-divider"/>

      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h3 className="StrategySignals-heading StrategySignals-heading--green-circle">
            Buy Signals
          </h3>
          <div className="StrategySignals-subheading">
            At least one Buy Signal is required for each strategy. Additional
            signals are optional.
          </div>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="buy"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, buyFields)) {
                return;
              }

              bPrepend(indicator)
            }}
            fields={buyFields}
          />
        </div>
        {buyFields &&
          map(buyFields, ({id, indicator, ...values}, index) => (
            <div
              key={id}
              className="StrategySignals-signal StrategySignals-panel"
            >
              <StrategySignalSettingsRow
                index={index}
                isEditing={isEditing}
                closeFn={() => bRemove(index)}
                signalType="buy"
                indicator={indicator}
                values={values}
              />
            </div>
          ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h3 className="StrategySignals-heading StrategySignals-heading--red-circle">
            Sell Signals
          </h3>
          <div className="StrategySignals-subheading">
            Adding Sell Signals will override your Goal % gain
          </div>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="sell"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, sellFields)) {
                return;
              }

              sPrepend(indicator)
            }}
            fields={sellFields}
          />
        </div>
        {map(sellFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => sRemove(index)}
              indicator={indicator}
              signalType="sell"
              values={values}
            />
          </div>
        ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h3 className="StrategySignals-heading StrategySignals-heading--red-circle">
            Stop Loss Signals
          </h3>
          <div className="StrategySignals-subheading">
            Adding Stop Loss Signals will sell the position as soon as this criteria is met
          </div>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="stopLoss"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, stopLossFields)) {
                return;
              }

              slPrepend(indicator)
            }}
            fields={stopLossFields}
          />
        </div>
        {map(stopLossFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => slRemove(index)}
              indicator={indicator}
              signalType="stopLoss"
              values={values}
            />
          </div>
        ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h3 className="StrategySignals-heading">Trade Management Settings</h3>
          <div className="StrategySignals-subheading">
            These optional signals allow you to fine tune your strategy to
            maximize profit in a volatile market.
          </div>
          <h4 className="StrategySignals-heading StrategySignals-heading--green-pause u-smaller u-topMargin">
            Pause Buy Signals
          </h4>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="pauseBuy"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, pauseBuyFields)) {
                return;
              }

              pbPrepend(indicator)
            }}
            fields={pauseBuyFields}
          />
        </div>
        {map(pauseBuyFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => pbRemove(index)}
              indicator={indicator}
              signalType="pauseBuy"
              values={values}
            />
          </div>
        ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h4 className="StrategySignals-heading StrategySignals-heading--green-resume u-smaller">
            Resume Buy Signals
          </h4>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="resumeBuy"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, resumeBuyFields)) {
                return;
              }

              rbPrepend(indicator)
            }}
            fields={resumeBuyFields}
          />
        </div>
        {map(resumeBuyFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => rbRemove(index)}
              indicator={indicator}
              signalType="resumeBuy"
              values={values}
            />
          </div>
        ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h4 className="StrategySignals-heading StrategySignals-heading--red-pause u-smaller">
            Pause Sell Signals
          </h4>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="pauseSell"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, pauseSellFields)) {
                return;
              }

              psPrepend(indicator)
            }}
            fields={pauseSellFields}
          />
        </div>
        {map(pauseSellFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => psRemove(index)}
              indicator={indicator}
              signalType="pauseSell"
              values={values}
            />
          </div>
        ))}
      </div>
      <hr className="StrategySignals-divider"/>
      <div className="u-container StrategySignals-section Strategy-content">
        <div className="StrategySignals-top">
          <h4 className="StrategySignals-heading StrategySignals-heading--red-resume u-smaller">
            Resume Sell Signals
          </h4>
        </div>
        <div className="StrategySignals-control StrategySignals-panel">
          <StrategySignalSelector
            type="resumeSell"
            onAddSignal={(indicator) => {
              if (testAddingSignal(indicator.indicator, resumeSellFields)) {
                return;
              }

              rsPrepend(indicator)
            }}
            fields={resumeSellFields}
          />
        </div>
        {map(resumeSellFields, ({id, indicator, ...values}, index) => (
          <div
            key={id}
            className="StrategySignals-signal StrategySignals-panel"
          >
            <StrategySignalSettingsRow
              index={index}
              isEditing={isEditing}
              closeFn={() => rsRemove(index)}
              indicator={indicator}
              signalType="resumeSell"
              values={values}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default StrategySignalSettings
