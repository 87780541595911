import './Imx.scss';

import * as React from 'react';

const Imx: React.FC = () => {
  return (
    <svg viewBox="0 0 139 113">
      <g>
        <defs>
          <rect id="SVGID_1_" width="139" height="113"/>
        </defs>
        <clipPath id="SVGID_00000035518931339590433170000002358037249938198420_">
		      <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}}/>
	      </clipPath>
        <g style={{clipPath: 'url(#SVGID_00000035518931339590433170000002358037249938198420_)'}}>
          <path className="st1" d="M124.1,0h14.6L79.5,67.6c-1.5,1.7-1.6,4.3-0.1,6.1l34.1,39.7H98.3L66.8,76.6l-1.6-2c-1.5-1.7-1.4-4.4,0.1-6.1
            L124.1,0z"/>
          <path className="st1" d="M91.2,73.7c-1-1.1-1.3-2.7-1-4.1c0.3-0.8,0.8-1.6,1.4-2.2l3.9-4.3l43.5,50.3h-13.8C125.2,113.4,99.7,83.6,91.2,73.7z"/>
          <path className="st1" d="M15.2,113.4H0l46.6-53.9c1.5-1.7,1.5-4.3,0-6L0.4,0H15l46.5,53.9c1.2,1.6,1.4,3.9,0.1,5.4C49.4,73.4,15.2,113.4,15.2,113.4z"/>
          <path className="st1"
                d="M40.6,0h-14l43.1,49.9c0,0,0.1-0.1,4.9-5.8c1.3-1.5,1.6-3.4,0.1-4.9C65.7,30,40.6,0,40.6,0z"/>
          <path className="st1"
                d="M82.6,34.8L112.5,0H98.3L82.7,18.2l-4.5,5.3c-1.5,1.7-1.6,4.3-0.1,6.1C79.2,30.9,82.6,34.8,82.6,34.8z"/>
          <path className="st1"
                d="M56.6,78.6l-29.9,34.8h14.1l15.6-18.2l4.5-5.3c1.5-1.7,1.6-4.3,0.1-6.1C59.9,82.5,56.6,78.6,56.6,78.6z"/>
          </g>
        </g>
    </svg>
  );
};

export default Imx;
