import React from 'react'
import './fourzerofour.scss'
import useDocTitle from '@pv3-hooks/useDocTitle'
import { APP_PATHS } from '@pv3-constants'
import { Link } from 'react-router-dom'

const FourZeroFour = () => {
  useDocTitle('Page Not Found')

  return (
    <div className="Content Content--FourZeroFour">
      <h1 className="FourZeroFour-headline">404</h1>
      <p className="FourZeroFour-copy">Oops, that address can’t be found.</p>
      <Link className="Button Button--secondary" to={APP_PATHS.BASE}>
        Back to Dashboard
      </Link>
    </div>
  )
}

export default FourZeroFour
