import React, { useEffect, useMemo } from 'react'
import { filter, find, get, gt, isNil, toNumber, orderBy } from 'lodash'
import useStrategiesDuck from '../../hooks/useStrategiesDuck'
import DashboardStrategy from '@pv3-components/DashboardStrategy'
import useMarketplaceDuck from '@pv3-hooks/useMarketplaceDuck'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clone from 'clone';
import useTradesDuck from "../../hooks/useTradesDuck";
import {isArrayNullOrEmpty} from "../../utils/utils";

const Strategies = ({ markets, orderStatus, publisher, selectedBacktestScenario, showBacktestResults, sortBy, statsTimeFrame, tradeType }) => {
  const  { getStrategies, getStrategiesRequestTime, strategies, dashboardStats } = useStrategiesDuck()
  const { openPositions } = useTradesDuck();
  const { publishers, subscriptions } = useMarketplaceDuck()
  const homeQuoteAsset = useSelector((state) =>
    get(state, 'profile.data.homeQuoteAsset')
  )

  useEffect(() => {
    // This check was added to limit the number of times getStrategies is called. CLV3-908
    if(new Date().getTime() - 60000 > getStrategiesRequestTime) {
      getStrategies()
    }
    const timer = setInterval(() => getStrategies(), 60000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const strategiesToDisplay = useMemo(
    () => {
      if((publisher && publisher !== 'myself') || isArrayNullOrEmpty(strategies)) {
        return [];
      }

      let filteredStrategies = clone(strategies);

      if(tradeType) {
        filteredStrategies = filteredStrategies.filter((s) => {
          return (
            (tradeType === 'buy' && s.isBuyActive === true && s.isSellActive === false) ||
            (tradeType === 'sell' && s.isSellActive === true && s.isBuyActive === false) ||
            (tradeType === 'both' && s.isBuyActive === true && s.isSellActive === true)
          );
        });
      }

      if(markets) {
        let values = markets.map((p) => p.value);
        filteredStrategies = filteredStrategies.filter((s) => {
          return values.includes(s.baseAsset);
        });
      }

      if(orderStatus) {
        if(orderStatus === 'open') {
          filteredStrategies = filteredStrategies.filter((s) => {
            return openPositions.filter((op) => get(op, 'position.strategyBasic.id') === s.id).length > 0;
          });
        }
      }

      if(sortBy) {
        let keyName = sortBy;
        let order = 'desc';
        let secondaryKeyName, secondaryOrder;
        if(sortBy === 'date') {
          keyName = 'created';
        }

        if(sortBy === 'name') {
          keyName = strategy => (strategy?.name || '').toString().toLowerCase();
          order = 'asc';
        }

        if(sortBy === 'holdingPercent') {
          secondaryKeyName = strategy => (strategy?.name || '').toString().toLowerCase();
          secondaryOrder = 'asc';
        }

        filteredStrategies = orderBy(filteredStrategies, [keyName, secondaryKeyName], [order, secondaryOrder]);
      }


      return filteredStrategies;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardStats, markets, openPositions, orderStatus, publisher, sortBy, strategies, tradeType]
  );

  const subscriptionsToDisplay = useMemo(
    () => {
      if((publisher && publisher === 'myself') || isArrayNullOrEmpty(subscriptions)) {
        return [];
      }

      let filteredSubscriptions = clone(subscriptions);

      if(publisher) {
        filteredSubscriptions = filteredSubscriptions.filter((s) => s.publisherUserId === publisher)
      }

      if(markets) {
        let values = markets.map((p) => p.value);
        filteredSubscriptions = filteredSubscriptions.filter((s) => {
          let found = false;
          let baseCurrencies = s.baseCurrencies;
          if(!isNil(baseCurrencies)) {
            baseCurrencies = baseCurrencies.toLowerCase().split(',');

            baseCurrencies.forEach((bc) => {
                if(values.includes(bc)) {
                    found = true;
                }
            });
          }
          return found;
        });
      }

      if(orderStatus) {
        if(orderStatus === 'open') {
          filteredSubscriptions = filteredSubscriptions.filter((s) => {
            return openPositions.filter((op) => get(op, 'position.strategyBasic.publisherUserId') === s.publisherUserId).length > 0;
          });
        }
      }


      if(sortBy) {
        let keyName = sortBy;
        let order = 'desc';
        let secondaryKeyName, secondaryOrder;
        if(sortBy === 'date') {
          keyName = 'created';
        }

        if(sortBy === 'name') {
          keyName = strategy => (strategy.publisherDisplayName || '').toLowerCase();
          order = 'asc';
        }

        if(sortBy === 'holdingPercent') {
          secondaryKeyName = strategy => (strategy.publisherDisplayName || '').toLowerCase();
          secondaryOrder = 'asc';
        }

        filteredSubscriptions.forEach((s) => {
          s.name = s.displayName;
        })

        filteredSubscriptions = orderBy(filteredSubscriptions, [keyName, secondaryKeyName], [order, secondaryOrder]);
      }

      return filteredSubscriptions;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [dashboardStats, markets, openPositions, orderStatus, publisher, sortBy, subscriptions]
  );

  return (
    <React.Fragment>
      {subscriptionsToDisplay && subscriptionsToDisplay.map((subscription) => {
        let stats = filter(get(dashboardStats, 'publisherTradingStats'), {
          publisherUserId: get(subscription, 'publisherUserId'),
          timeFrame: toNumber(statsTimeFrame),
        })

        if (gt(stats.length, 0)) {
          stats = stats[0]
        } else {
          stats = {}
        }

        return (
          <DashboardStrategy
            id={get(subscription, 'publisherUserId')}
            selectedBacktestScenario={selectedBacktestScenario}
            showBacktestResults={showBacktestResults}
            strategyName={subscription.publisherDisplayName || `${subscription.publisherFirstName} ${subscription.publisherLastName}`}
            isPublic={false}
            isCopyCat={true}
            stats={stats}
            key={get(subscription, 'publisherUserId')}
            baseCurrencies={get(subscription, 'baseCurrencies')}
            subscription={subscription}
            publisher={find(publishers, {
              publisherUserId: get(subscription, 'publisherUserId'),
            })}
          />
        )
      })}
      {strategiesToDisplay && strategiesToDisplay.map(
        ({
          id,
          isPublic,
          isBuyActive,
          isSellActive,
          name,
          holdingPercent,
          baseAsset,
        }) => {

          let stats = filter(get(dashboardStats, 'strategyTradingStats'), {
            strategyId: id,
            timeFrame: toNumber(statsTimeFrame),
          })

          if (gt(stats.length, 0)) {
            stats = stats[0]
          } else {
            stats = {}
          }

          return (
            <DashboardStrategy
              id={id}
              selectedBacktestScenario={selectedBacktestScenario}
              showBacktestResults={showBacktestResults}
              strategyName={name}
              sellingEnabled={isSellActive}
              buyingEnabled={isBuyActive}
              isPublic={isPublic}
              isCopyCat={false}
              stats={stats}
              key={id}
              holdingPercent={holdingPercent}
              homeQuoteAsset={homeQuoteAsset}
              baseAsset={baseAsset}
            />
          )
        }
      )}
    </React.Fragment>
  )
}

Strategies.propTypes = {
  statsTimeFrame: PropTypes.number,
}

export default Strategies
