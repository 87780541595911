import React, { useEffect, useMemo, useState } from 'react'
import Input from '@pv3-shared-components/Input'
import './achdepositform.scss'
import { FormProvider, get, useForm } from 'react-hook-form'
import { formatAsDollarAmount } from '../../utils/utils'
import Button from '@pv3-shared-components/Button'
import Icon from '@pv3-shared-components/Icons'
import Currency from '../shared/Currency'
import Select from '@pv3-shared-components/Select'
import useAchDuck from '../../hooks/useAchDuck'
import { chain, eq, find, isNil, isUndefined, map } from 'lodash'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import InputError from '@pv3-shared-components/InputError'

const AchDepositForm = ({ deposit, submitAction }) => {
  const formMethods = useForm({
    defaultValues: isNil(deposit) ? undefined : deposit,
  })
  const { watch, handleSubmit, setFocus, register, formState } = formMethods
  const { errors } = formState
  const { items: achItems } = useAchDuck()
  const { balances } = useWalletsDuck()

  const accounts = useMemo(
    () =>
      chain(achItems)
        .filter(({ relinkRequired }) => !relinkRequired)
        .flatMap(({ accounts }) => accounts)
        .value(),
    [achItems]
  )

  const [showFormattedAmount, setShowFormattedAmount] = useState(false)
  const amount = watch('amount')

  const handleFormSubmit = (data) => {
    const depositAccount = find(accounts, ({ accountId }) =>
      eq(accountId, data.accountId)
    )
    submitAction(data, depositAccount)
  }

  useEffect(() => {
    setFocus('amount')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <p className="AchDepositForm-amountLabel">Amount</p>
          <div className="AchDepositForm-amountInputContainer">
            {showFormattedAmount && (
              <div className="AchDepositForm-formattedAmountOverlay">
                <p>{formatAsDollarAmount(amount, true)}</p>
              </div>
            )}
            <Input
              type="number"
              className="AchDepositForm-bigNumberInput"
              inputMode="decimal"
              name="amount"
              registerConfig={{
                required: 'Amount is required',
                min: {
                  value: 6,
                  message:
                    'Deposit must be greater than the deposit fee, which is $5',
                },
              }}
              onBlur={() => setShowFormattedAmount(true)}
              onFocus={() => setShowFormattedAmount(false)}
              error={
                !isUndefined(get(formState, 'errors.amount')) && (
                  <InputError message={formState.errors.amount.message} />
                )
              }
            />
          </div>
          <p className="AchDepositForm-currencyLabel">USD</p>
          <p className="AchDepositForm-partyBoxLabel">From</p>
          <div
            className={`AchDepositForm-partyBox ${
              !isUndefined(errors.accountId)
                ? 'AchDepositForm-partyBoxError'
                : ''
            }`}
          >
            <div className="AchDepositForm-bankIcon">
              <Icon name="bank" height="30" width="30" />
            </div>
            <Select
              register={{
                ...register('accountId', {
                  validate: (value) => value !== '' || 'Account is required',
                }),
              }}
              className="AchDepositForm-accountSelect"
              containerClassName="AchDepositForm-accountSelectContainer"
            >
              <option value={''}>Choose account...</option>
              {map(accounts, ({ accountId, name, mask }) => (
                <option value={accountId} key={accountId}>
                  {name} x{mask}
                </option>
              ))}
            </Select>
          </div>
          <p className="AchDepositForm-partyBoxLabel">To</p>
          <div className="AchDepositForm-partyBox u-unchanging">
            <div className="AchDepositForm-partyBoxIcon">
              <Currency type="usd" width="30" height="30" />{' '}
            </div>
            <div className="AchDepositForm-partyBoxWalletDescription">
              <p className="wallet">USD Wallet</p>
              <p className="walletAmount">
                ({formatAsDollarAmount(balances.usd, true)} in wallet)
              </p>
            </div>
          </div>
          <Button
            variant="primary"
            size="large"
            className="AchDepositForm-submitButton"
          >
            Preview
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default AchDepositForm
