import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import OnboardingInfo from '@pv3-components/OnboardingInfo'
import ChooseAccountType from '@pv3-components/AccountType'
import OnboardingTerms from '@pv3-components/OnboardingTerms'
import { APP_PATHS } from '@pv3-constants'

const Onboarding = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <main className="SinglePanel">
      <Switch>
        <Route
          path={APP_PATHS.ONBOARDING_ACCOUNT_TYPE}
          component={ChooseAccountType}
        />
        <Route path={APP_PATHS.ONBOARDING_TERMS} component={OnboardingTerms} />
        <Route path={APP_PATHS.ONBOARDING_INFO} component={OnboardingInfo} />
        <Redirect to={APP_PATHS.ONBOARDING_ACCOUNT_TYPE} />
      </Switch>
    </main>
  )
}

export default Onboarding
