import './aggregator.scss';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { isObjectNullOrEmpty } from '../../../../utils/utils';

import Loading from '../../../shared/Loading';
import {
  getAggregatorEnrollments,
  getAggregatorEvents,
  getAggregatorPrograms,
  getHodlUserTotals,
} from "../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../ducks";

import AggregatorForm from "./components/AggregatorForm";

const RewardsAggregator: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userTotals } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsLoading(isObjectNullOrEmpty(userTotals));
        await Promise.all([
          dispatch(getHodlUserTotals()).unwrap(),
          dispatch(getAggregatorEnrollments()).unwrap(),
          dispatch(getAggregatorEvents()).unwrap(),
          dispatch(getAggregatorPrograms()).unwrap(),
        ]);
      } catch(err) {
        console.log('RewardsAggregator initialize error', err);
      } finally {
        setIsLoading(false);
      }
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Content Content-aggregator">
      <h2 className="rewards-title">
        Aggregator
      </h2>

      {isLoading || isObjectNullOrEmpty(userTotals) ? (
        <Loading />
      ) : (
        <div className="rewards-content">
          <div className="Aggregator Liquidator">

            <div className="Aggregator-Forms Liquidator-Forms">
              <AggregatorForm />
            </div>

            <div className="Liquidator-Description">
              <p>
                One of the key features of the Coin Lion platform is the Token Balancer (TB), which offers you a discount on trading fees when you engage in purchasing LION. Traditionally, the TB has executed purchases of LION via market orders. However, moving forward, it will employ the Aggregator to facilitate these purchases.
              </p>

              <p>
                The Aggregator functions similarly to the Liquidator, but it operates on the buying side of the market rather than the selling side.
              </p>

              <p>
                Designed to enhance buying strategies, the Aggregator will execute purchases using both market and limit orders. The system allocates a percentage of funds to the Aggregator, which then places a market order every 24 hours, followed by staggered limit orders on the buy side of the LION order book.
              </p>

              <p>
                Additionally, the Aggregator will strategically place smaller orders at the top of the order book, followed by progressively larger orders further down. This setup does not guarantee the highest price for buy orders but varies the order placement based on the amount of LION you intend to purchase, ranging from 5-8 orders near the top to 20-30 deeper in the book.
              </p>

              <p>
                The Aggregator offers three operational modes: Aggressive, Moderate, and Passive.
              </p>

              <p>
                In Aggressive mode, the Aggregator places the most competitively priced orders, decreasing the price by 3.5%-4.5% as it moves down the order book. This mode ensures quicker LION purchases, with approximately 8-12% of the funds being used for market orders every 24 hours, and the remainder for limit orders.
              </p>

              <p>
                In Moderate mode, a smaller percentage of funds, about 6-8%, is allocated to market orders with less aggressive pricing on the buy side compared to Aggressive mode. Limit orders placed will decrease in price by 4-6%.
              </p>

              <p>
                In Passive mode, an even smaller proportion, 4-5%, is allocated to market orders, with the least aggressive pricing on the buy side among the three modes. Prices of limit orders in this mode decrease by 6-10%.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RewardsAggregator;
