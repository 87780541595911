import './rewardsReferrals.scss';

import * as React from 'react';
import { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";

import { APP_PATHS } from "../../../../constants";
import { isArrayNullOrEmpty } from '../../../../utils/utils';

import { getReferralRewardActions, getReferralRewards } from "../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../ducks";

import Loading from "../../../shared/Loading";
import MyReferrals from "./MyReferrals";
import ReferralsOverview from "./ReferralsOverview";
import RewardsHistory from "./RewardsHistory";

const RewardsReferrals: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { referralRewardActions } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsLoading(isArrayNullOrEmpty(referralRewardActions));
        await Promise.all([
          dispatch(getReferralRewardActions()).unwrap(),
          dispatch(getReferralRewards()).unwrap(),
        ]);
      } catch (err) {
        console.log('RewardsReferrals initialize error', err);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Content Content--Referrals">
      <h2 className="rewards-title">
        Refer Friends and Earn Together
      </h2>

      <nav className="rewards-tabs">
        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_REFERRALS_OVERVIEW ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_REFERRALS_OVERVIEW}
        >
          Overview
        </Link>

        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_REFERRALS_ACTIVITY ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_REFERRALS_ACTIVITY}
        >
          My Referrals
        </Link>

        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_REFERRALS_HISTORY ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_REFERRALS_HISTORY}
        >
          Rewards History
        </Link>
      </nav>

      {isLoading ? (
        <Loading/>
      ) : (
        <div className="rewards-content">
          <Switch>
            <Route
              path={APP_PATHS.REWARDS_REFERRALS_OVERVIEW}
              component={ReferralsOverview}
            />

            <Route
              path={APP_PATHS.REWARDS_REFERRALS_ACTIVITY}
              component={MyReferrals}
            />

            <Route
              path={APP_PATHS.REWARDS_REFERRALS_HISTORY}
              component={RewardsHistory}
            />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default RewardsReferrals;
