import * as signalR from "@microsoft/signalr";

export default class Websocket {
    constructor(url) {
        this.attempts = 0;
        this.channels = {};
        this.connected = false;

        this.hub = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(signalR.LogLevel.Error)
            .build();
    }

    connect = (onConnected) => {
        return this.hub.start()
            .then(() => {
                this.attempts = 0;
                this.connected = true;
                if(onConnected) {
                    onConnected();
                }
            })
            .catch((err) => {
                this.connected = false;
                this.reconnect();
            });
    };

    disconnect = () => {
        this.hub.stop()
            .then(() => console.log('disconnected'))
            .catch((err) => console.log('disconnect error'));
    };

    reconnect = () => {
        if (this.attempts < 10) {
            setTimeout(() => {
                this.connect();
                this.attempts++;
            }, 1000);
        }
    };

    subscribe = (channelName, params, onEnter, onItem, onError) => {
        if(this.connected === false && this.attempts < 10) {
            this.attempts++;
            return this.connect()
                .then(() => {
                    this.subscribe(channelName, params, onEnter);
                })
        } else if(this.connected === true) {
            let channel;
            if(params != null) {
                channel = this.hub.stream(channelName, params);
            } else {
                channel = this.hub.stream(channelName)
            }

            channel.subscribe({
                next: (item) => {
                    if(onItem != null) {
                        onItem(item)
                    }
                }
            });

            if(onEnter != null) {
                onEnter();
            }

            this.channels[channelName] = {
                stream: channel
            };

            return channel;
        }
    };

    command = (name, params, onItem) => {
        this.hub.invoke(name, params)
            .then(data => onItem(data))
            .catch(err => console.log('invoke error', err));
    };

    unsubscribe = (channelName) => {
        if(this.channels[channelName] != null && this.channels[channelName].dispose != null) {
            this.channels[channelName].stream.dispose();
            delete this.channels[channelName];
        }

        return channelName;
    };

    onClose = (onConnected) => {
        this.hub.onclose(() => {
            this.connect(onConnected);
        });
    }
}
