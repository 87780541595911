import './FormValidationError.scss';

import React from 'react';

const FormValidationError = ({
  error,
  messages,
}) => {
  if(!error || !messages[error]) {
    return null;
  }

  return (
    <div className="FormValidationError">
      {messages[error]}
    </div>
  );
};

export default FormValidationError;
