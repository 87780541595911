import React, { useEffect, useMemo } from 'react'
import Table from '@pv3-shared-components/Table'
import './asksandbidstable.scss'
import { formatNumber } from '../../utils/utils'
import { eq, isUndefined } from 'lodash'

const AsksAndBidsTable = ({ lastPrice, asks, bids }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Price',
        id: 'price',
        accessor: (d) => `${formatNumber(d.price)}`,
        sortable: false,
        Cell: ({ original }) => (
          <div
            className={`${
              original.isAsk
                ? 'AsksAndBidsTable-textRed'
                : 'AsksAndBidsTable-textGreen'
            }`}
          >
            {formatNumber(original.price)}
          </div>
        ),
      },
      {
        Header: 'Size',
        id: 'size',
        className: 'AsksAndBidsTable-rightAlignedData',
        sortable: false,
        accessor: (d) => `${formatNumber(d.amount)}`,
      },
      {
        Header: 'Total',
        id: 'total',
        className: 'AsksAndBidsTable-rightAlignedData',
        sortable: false,
        accessor: (d) => `${formatNumber(d.price * d.amount)}`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastPrice]
  )

  useEffect(() => {
    const target = document
      .getElementById('asksScrollContainer')
      .getElementsByClassName('rt-tbody')[0]

    if (isUndefined(target)) return

    target.scrollTop = target.scrollHeight
  }, [])

  return (
    <React.Fragment>
      <div
        className={`AsksTable-container ${eq(asks.length, 0) ? 'empty' : ''}`}
        id="asksScrollContainer"
      >
        <Table columns={columns} data={asks} noDataText="No asks" showIfEmpty />
      </div>
      <div
        className={`BidsTable-container ${eq(bids.length, 0) ? 'empty' : ''}`}
      >
        <div className="AsksAndBidsTable-marketPrice">
          {formatNumber(lastPrice)}
        </div>
        <Table columns={columns} data={bids} noDataText="No bids" showIfEmpty />
      </div>
    </React.Fragment>
  )
}

export default AsksAndBidsTable
