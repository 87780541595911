import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { eq, filter, get } from 'lodash'
import Request from '../utils/request'
import { API_PATHS } from '../constants'

const request = new Request()
const config = { baseURL: window.envConfig.COINLION_API_URL + 'frontoffice/' }

export const linkAch = createAsyncThunk(
  'ach/link',
  async (publicToken, { rejectWithValue }) => {
    try {
      const response = await request.post(
        API_PATHS.COIN.ACH,
        { publicToken },
        config
      )

      return response.data
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const unlinkAch = createAsyncThunk(
  'ach/unlink',
  async (itemId, { rejectWithValue }) => {
    try {
      await request.delete(API_PATHS.COIN.DELETE_ACH(itemId), config)
      return itemId
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const relinkAch = createAsyncThunk(
  'ach/relink',
  async (itemId, { rejectWithValue }) => {
    try {
      const { data } = await request.put(
        API_PATHS.COIN.RELINK_ACH(itemId),
        { relinkRequired: false },
        config
      )
      return data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const getRelinkToken = createAsyncThunk(
  'ach/getRelinkToken',
  async (itemId, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        API_PATHS.COIN.ACH_PUBLIC_TOKEN(itemId),
        config
      )
      return { itemId, publicToken: data.publicToken }
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const getAchItems = createAsyncThunk(
  'ach/getItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.ACH, config)
      return response.data.items
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const initiateAchDeposit = createAsyncThunk(
  'ach/deposit',
  async (deposit, { rejectWithValue }) => {
    try {
      await request.post(API_PATHS.COIN.DEPOSIT_FIAT, deposit)
    } catch (e) {
      const message = get(e, 'response.data.message')
      return rejectWithValue(message || 'There was an error depositing funds.')
    }
  }
)

export const initiateAchWithdrawal = createAsyncThunk(
  'ach/withdrawal',
  async (withdrawal, { rejectWithValue }) => {
    try {
      await request.post(API_PATHS.COIN.WITHDRAWAL_FIAT, withdrawal)
    } catch (e) {
      const errorMessage = get(e, 'response.data.errors[0].message')
      return rejectWithValue(errorMessage || 'Withdrawal error')
    }
  }
)

const achSlice = createSlice({
  name: 'ach',
  initialState: {
    items: [],
    relinkTokens: {},
  },
  reducers: {},
  extraReducers: {
    [getAchItems.fulfilled]: (state, action) => {
      state.items = action.payload
    },
    [linkAch.fulfilled]: (state, action) => {
      state.items = [...state.items, action.payload]
    },
    [unlinkAch.fulfilled]: (state, action) => {
      state.items = filter(state.items, (item) => !eq(item.id, action.payload))
    },
    [getRelinkToken.fulfilled]: (state, action) => {
      state.relinkTokens[action.payload.itemId] = action.payload.publicToken
    },
  },
})

export default achSlice.reducer
