import React, { useState } from 'react';
import useProfileDuck from "../../hooks/useProfileDuck";
import Toggle from "../shared/Toggle";
import Loading from "../shared/Loading";


const PublicProfile = () => {
  const { getProfile, profile, updateProfile } = useProfileDuck();

  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

  const handleDarkModeToggle = async (hasDarkTheme) => {
    try {
      setIsUpdatingProfile(true);

      const newProfile = {
        ...profile,
        hasDarkTheme
      };

      await updateProfile(newProfile);

      getProfile();
    } catch(err) {

    } finally {
      setIsUpdatingProfile(false);
    }
  }

  return (
    <section className="Fieldset AcctSettings-fieldset AcctSettings-fieldset--profile AcctSettings-fieldset--preferences">
      <h1 className="Legend AcctSettings-legend">
        Preferences
      </h1>

      <div className="AcctSettings-fieldset--preferences-toggle">
        <Toggle
          disabled={isUpdatingProfile}
          value={profile.hasDarkTheme}
          handleChange={() => handleDarkModeToggle(!profile.hasDarkTheme)}
          label="Dark Mode"
        />

        {isUpdatingProfile && <Loading />}
      </div>
    </section>
  );
};

export default PublicProfile;
