import * as React from 'react';
import { useEffect, useState } from "react";

import { isObjectNullOrEmpty } from '../../../../utils/utils';

import { getTradingFeeDiscountsInfo } from "../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../ducks";

import DiscountsOverview from "./DiscountsOverview";
import Loading from '../../../shared/Loading';

const RewardsDiscounts: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { discountsInfo } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsLoading(isObjectNullOrEmpty(discountsInfo));
        await dispatch(getTradingFeeDiscountsInfo()).unwrap();
      } catch (err) {
        console.log('RewardsDiscounts initialize error', err);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Content Content--Referrals">
      <h2 className="rewards-title">
        Trading Fees
      </h2>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rewards-content">
          <DiscountsOverview />
        </div>
      )}
    </div>
  );
};

export default RewardsDiscounts;
