import React from 'react'
import { Link } from 'react-router-dom'
import './dashboardstrategy.scss'
import {
  eq,
  get,
  isEmpty,
  isNil,
  isUndefined,
  lt,
  map,
  split,
  truncate,
  upperCase,
} from 'lodash'
import PropTypes from 'prop-types'
import CurrenciesTooltip from '@pv3-shared-components/CurrenciesTooltip'
import SubscriptionModal from '@pv3-components/SubscriptionModal'
import useModal from '@pv3-hooks/useModal'
import { formatAsDollarAmount, formatNumber } from '../../utils/utils'
import Logo from "../shared/Logo";
import useStrategiesDuck from '../../hooks/useStrategiesDuck';

const DashboardStrategy = ({
  id,
  strategyName,
  sellingEnabled,
  selectedBacktestScenario,
  showBacktestResults,
  buyingEnabled,
  isPublic,
  isCopyCat,
  stats,
  baseCurrencies,
  subscription,
  publisher,
  holdingPercent,
  homeQuoteAsset,
  baseAsset,
}) => {
  const { dashboardBacktestHistories } = useStrategiesDuck();

  const modal = useModal()
  const statValue = (property) => {
    if(showBacktestResults) {
      const backtestHistory = dashboardBacktestHistories.find((history) => history.strategyId === id);
      if(!backtestHistory) return '--';

      const scenario = backtestHistory.histories.find((history) => history.scenarioId === selectedBacktestScenario);
      if(!scenario || !scenario.summary) return '--';

      const grossProfitLoss = get(scenario.summary, 'grossProfitLoss');
      const averagePercentPerTrade = get(scenario.summary, 'averagePercentPerTrade');
      const quantityOfWins = get(scenario.summary, 'quantityOfWins');
      const quantityOfLosses = get(scenario.summary, 'quantityOfLosses');

      if(property === 'tradesClosed') {
        if(isUndefined(quantityOfWins) || isUndefined(quantityOfLosses)) return '--';
        return quantityOfWins + quantityOfLosses;
      } else if(property === 'avgProfitClosedPercent') {
        if(isUndefined(averagePercentPerTrade)) return '--';
        return averagePercentPerTrade;
      } else if(property === 'totalProfitClosed') {
        if(isUndefined(grossProfitLoss)) return '--'
        return grossProfitLoss;
      }
    } else {
      const value = get(stats, property);
      if (isUndefined(value)) return '--'

      return value;
    }
  }

  let assets = []

  if (isCopyCat && !eq(statValue('baseCurrencies'), '--')) {
    assets = map(split(statValue('baseCurrencies'), ','), (value) => {
      let percentSplit = split(value, ' ')

      if (percentSplit.length > 1) {
        return {
          name: percentSplit[0],
          value: percentSplit[1],
        }
      } else {
        return {
          name: percentSplit[0],
        }
      }
    })
  } else if (!isNil(baseCurrencies)) {
    assets = map(split(baseCurrencies, ','), (value) => {
      return {
        name: value,
      }
    })
  }

  const CtaTag = isCopyCat ? 'div' : Link

  return (
    <section key={id} className="StrategyItem">
      <CtaTag
        className="StrategyItem-cta"
        to={!isCopyCat ? `strategies/${id}/details` : undefined}
        onClick={
          isCopyCat
            ? () => {
                modal.setShow(true)
              }
            : undefined
        }
        tabIndex={isCopyCat ? '0' : undefined}
        role={isCopyCat ? 'button' : undefined}
      >
        <div className="StrategyItem-main">
          <header className="StrategyItem-titleBlock">
            <h1 className="StrategyItem-prominentText" data-testid={`dashboard_${strategyName}`}>
              {truncate(strategyName, { length: 37, omission: '…' })}
            </h1>
            <div className="StrategyItem-icons">
              <ul className="StrategyItem-badgeList">
                {isCopyCat && (
                  <li className="StrategyItem-badge copycat">
                    <Logo />
                    Copycat
                  </li>
                )}
                {isPublic && (
                  <li className="StrategyItem-badge public">Public</li>
                )}
                {isCopyCat &&
                  map(split(baseCurrencies, ','), (asset) => (
                    <li
                      className="StrategyItem-badge asset"
                      key={isCopyCat ? asset : asset[0]}
                    >
                      {isCopyCat ? asset : upperCase(asset[0])}
                    </li>
                  ))}
                {!isCopyCat && (
                  <React.Fragment>
                    <li className="StrategyItem-badge asset">
                      {homeQuoteAsset}
                    </li>
                    <li className="StrategyItem-badge asset">{baseAsset}</li>
                  </React.Fragment>
                )}
              </ul>
              {isCopyCat && !isEmpty(assets) && (
                <CurrenciesTooltip currencies={assets} />
              )}
            </div>
          </header>
          {!isCopyCat && (
            <ul className="StrategyItem-onOffIndicators">
              <li
                className={`StrategyItem-onOffIndicator ${
                  buyingEnabled ? 'u-on' : 'u-off'
                }`}
              >
                Buy
                <span className="u-a11yOnly">
                  is {buyingEnabled ? 'active' : 'inactive'}
                </span>
              </li>
              <li
                className={`StrategyItem-onOffIndicator ${
                  sellingEnabled ? 'u-on' : 'u-off'
                }`}
              >
                Sell
                <span className="u-a11yOnly">
                  is {sellingEnabled ? 'active' : 'inactive'}
                </span>
              </li>
            </ul>
          )}
          <div className="StrategyItem-totalAllocation">
            <strong className="StrategyItem-totalAllocation-percent">
              {isCopyCat
                ? Math.round(get(subscription, 'portfolioPercent') * 100) / 100
                : Math.round(holdingPercent * 100) / 100}
              %
            </strong>
            <p className="StrategyItem-label">Total Allocation</p>
          </div>
        </div>
        <div className="StrategyItem-stats">
          <div className="StrategyItem-stat">
            <b>{statValue('tradesClosed')}</b> Position
            {!eq(statValue('tradesClosed'), 1) && 's'}
          </div>
          <div
            className={`StrategyItem-stat ${
              !eq(statValue('avgProfitClosedPercent'), '--') &&
              (lt(statValue('avgProfitClosedPercent'), 0)
                ? 'StrategyItem-stat--loss'
                : 'StrategyItem-stat--gain')
            }`}
          >
            <b>
              {!eq(statValue('avgProfitClosed'), '--')
                ? `${formatNumber(statValue('avgProfitClosedPercent'), 2, 2)}%`
                : '--'}
            </b>{' '}
            Avg Profit/Loss
          </div>
          <div
            className={`StrategyItem-stat ${
              !eq(statValue('totalProfitClosed'), '--') &&
              (lt(statValue('totalProfitClosed'), 0)
                ? 'StrategyItem-stat--loss'
                : 'StrategyItem-stat--gain')
            }`}
          >
            <b>
              {!eq(statValue('totalProfitClosed'), '--')
                ? formatAsDollarAmount(statValue('totalProfitClosed'))
                : '--'}
            </b>{' '}
            Gain/Loss
          </div>
        </div>
      </CtaTag>
      {modal.show && (
        <SubscriptionModal
          modal={modal}
          publisher={publisher}
          activeSubscription={subscription}
        />
      )}
    </section>
  )
}

DashboardStrategy.propTypes = {
  id: PropTypes.string,
  strategyName: PropTypes.string,
  sellingEnabled: PropTypes.bool,
  buyingEnabled: PropTypes.bool,
  isPublic: PropTypes.bool,
  isCopyCat: PropTypes.bool,
  stats: PropTypes.object,
  baseCurrencies: PropTypes.string,
}

export default DashboardStrategy
