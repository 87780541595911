import React from 'react'
import PropTypes from 'prop-types'
import './strategythird.scss'

const StrategyThird = ({ children, title }) => {
  return (
    <div className="u-container StrategyThird">
      <h2 className="h4">{title}</h2>
      {children}
    </div>
  )
}

StrategyThird.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default StrategyThird
