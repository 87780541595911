import React, {useEffect, useState} from 'react'
import { eq, map, gt } from 'lodash'
import { PlaidLink } from 'react-plaid-link'
import './banksettings.scss'
import useAchDuck from '../../hooks/useAchDuck'
import BankTransactionsTable from '../BankTransactionsTable'
import {APP_PATHS, KYC_VERIFICATION_STATUSES, LOADING_STATES} from '../../constants'
import Icon from '@pv3-shared-components/Icons'
import { Link } from 'react-router-dom'
import Button from '@pv3-shared-components/Button'
import ComponentLoader from '@pv3-components/ComponentLoader'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import useDocTitle from '@pv3-hooks/useDocTitle'
import useProfileDuck from '../../hooks/useProfileDuck'
import useModal from '../../hooks/useModal'
import Modal from '@pv3-shared-components/Modal'
import { isUndefined } from 'lodash'
import AddFundsModal from '@pv3-components/AddFundsModal'
import RemoveFundsModal from '@pv3-components/RemoveFundsModal'

const BankSettings = () => {
  useDocTitle('Bank Settings')
  const { getUsdAllowed, kycVerificationStatus } = useProfileDuck()
  const { getAchItems, linkAch, unlinkAch, relinkAch, items, relinkTokens, getRelinkToken } =
    useAchDuck()
  const [stagedForUnlink, setStagedForUnlink] = useState()

  const modal = useModal()
  const addFundsModal = useModal()
  const removeFundsModal = useModal()

  useEffect(() => {
    if (gt(kycVerificationStatus, 1)) {
      getAchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycVerificationStatus]);

  const onAccountLinkSuccess = (publicToken) => linkAch(publicToken)

  const handleClickUnlinkAch = (itemId, name) => {
    setStagedForUnlink({ itemId, name })
    modal.setShow(true)
  }

  const handleConfirmUnlink = () => {
    unlinkAch(stagedForUnlink.itemId)
    modal.setShow(false)
  }

  const onRelinkSuccess = (itemId) => relinkAch(itemId)

  const handleDepositClick = () => {
    addFundsModal.setShow(true)
  }

  const handleWithdrawClick = () => {
    removeFundsModal.setShow(true)
  }

  const canTradeUsd = getUsdAllowed();

  return (
    <div className="Content BackSettings-Content">
      <ComponentLoader loadingStates={[LOADING_STATES.ACH]} />

      <div className="BankSettings-headerRow BankSettings-flexRow">
        <h4>Your linked accounts</h4>
        <Button onClick={handleDepositClick}>Deposit</Button>
        <Button onClick={handleWithdrawClick}>Withdraw</Button>
        {gt(items.length, 0) && (
          <PlaidLink
            clientName="CoinLion"
            className="Button Button--primary"
            product={['auth', 'transactions']}
            env={window.envConfig.COINLION_PLAID_ENV}
            publicKey={window.envConfig.COINLION_PLAID_KEY}
            onSuccess={onAccountLinkSuccess}
            style={{
              background: undefined,
              padding: undefined,
              outline: undefined,
              border: undefined,
              borderRadius: undefined,
            }}
          >
            Add account
          </PlaidLink>
        )}
      </div>

      {canTradeUsd ? (
        <React.Fragment>
          {kycVerificationStatus !== KYC_VERIFICATION_STATUSES.VERIFIED ? (
            <EnhancedFlash
              variant="warning"
              heading="Your identity verification process has not been completed. Please go to the Account Settings screen and get verified. If you have attempted multiple times without success then please reach out to support."
              className="BankSettings-noUsdFlash"
              subheading={
                <span>
              For more information, see our{' '}
                  <a
                    href="https://help.coinlion.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                help page
              </a>
            </span>
              }
            />
          ) : (
            <React.Fragment>
              {eq(items.length, 0) ? (
                <div className="EmptyTable BankSettings-flexRow">
                  <div className="EmptyBankSettings-flexLeft">
                    <h1 className="EmptyTable-headline">No accounts connected</h1>
                    <p className="EmptyTable-description">
                      Securely connect your bank account to make easy cash transfers to
                      and from your CoinLion account
                    </p>
                    <PlaidLink
                      clientName="CoinLion"
                      className="Button Button--primary"
                      product={['auth', 'transactions']}
                      env={window.envConfig.COINLION_PLAID_ENV}
                      publicKey={window.envConfig.COINLION_PLAID_KEY}
                      onSuccess={onAccountLinkSuccess}
                      style={{
                        background: undefined,
                        padding: undefined,
                        outline: undefined,
                        border: undefined,
                        borderRadius: undefined,
                      }}
                    >
                      Connect an account
                    </PlaidLink>
                  </div>
                  <div className="EmptyBankSettings-flexRight">
                    <img src="/img/banking.svg" alt="Banking illustration" />
                  </div>
                </div>
              ) : (
                <div className="BankSettings-content">
                  <div className="BankSettings-items">
                    {map(items, ({ id, name, accounts, relinkRequired }) => (
                      <div className="BankSettings-item" key={id}>
                        <div className="BankSettings-itemHeaderRow BankSettings-flexRow">
                          <h4 className="BankSettings-itemHeader">{name}</h4>
                          <button
                            type="button"
                            className="u-buttonReset"
                            title="Unlink account"
                            onClick={() => handleClickUnlinkAch(id, name)}
                          >
                            <Icon aria-hidden="true" name="trash" />
                            <span className="u-a11yOnly">Unlink account</span>
                          </button>
                        </div>
                        {relinkRequired ? (
                          <EnhancedFlash
                            variant="warning"
                            heading="Bank unlinked"
                            subheading={
                              <p className="BankSettings-relinkRequiredSubheading">
                                We are unable to connect to your bank. <br />
                                Transfers will not be possible until you relink.
                              </p>
                            }
                            cta={
                              <PlaidLink
                                clientName="CoinLion"
                                className="Button Button--primary BankSettings-relinkRequiredButton Button--large"
                                product={['auth', 'transactions']}
                                env={window.envConfig.COINLION_PLAID_ENV}
                                publicKey={window.envConfig.COINLION_PLAID_KEY}
                                onSuccess={() => onRelinkSuccess(id)}
                                onEvent={(_, e) => {
                                  if (eq(e.error_code, 'INVALID_PUBLIC_TOKEN')) {
                                    getRelinkToken(id)
                                  }
                                }}
                                token={relinkTokens[id]}
                                style={{
                                  background: undefined,
                                  padding: undefined,
                                  outline: undefined,
                                  border: undefined,
                                  borderRadius: undefined,
                                }}
                              >
                                Relink
                              </PlaidLink>
                            }
                          />
                        ) : (
                          <React.Fragment>
                            <h5 className="BankSettings-accountsLabel">Accounts</h5>
                            <div role="table" aria-label="Accounts">
                              {map(accounts, ({ name, subType, mask }) => (
                                <div
                                  key={name}
                                  className="BankSettings-accountRow BankSettings-flexRow"
                                  role="row"
                                >
                                  <p className="description" role="cell">
                                    {name} - {subType}
                                  </p>
                                  <p className="number" role="cell">
                                    XXXXXXX{mask}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="BankSettings-tableContainer">
                    <div className="BankSettings-tableHeader BankSettings-flexRow">
                      <h4>Recent Bank Transactions</h4>
                      <Link to={APP_PATHS.ACTIVITY_DEPOSITS_WITHDRAWALS}>
                        All activity <Icon name="arrowright" />
                      </Link>
                    </div>
                    <BankTransactionsTable />
                  </div>

                  <Modal
                    modal={modal}
                    screens={{
                      MAIN: {
                        heading: <h4>Disconnect Bank Account</h4>,
                        body: (
                          <div>
                            {!isUndefined(stagedForUnlink) && (
                              <p className="BankSettings-modalText">
                                Are you sure you want to disconnect{' '}
                                <span className="BankSettings-modalBankAccountText">
                          “{stagedForUnlink.name}”
                        </span>{' '}
                                from your CoinLion account”?
                              </p>
                            )}
                            <Button
                              variant="primary"
                              size="large"
                              className="BankSettings-modalButton"
                              onClick={handleConfirmUnlink}
                            >
                              Disconnect
                            </Button>
                            <Button
                              variant="quaternary"
                              size="large"
                              className="BankSettings-modalButton"
                              onClick={() => modal.setShow(false)}
                            >
                              Nevermind, keep connected
                            </Button>
                          </div>
                        ),
                      },
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <EnhancedFlash
          variant="warning"
          heading="You are in a jurisdiction where transactions with USD are not available."
          className="BankSettings-noUsdFlash"
          subheading={
            <span>
              For more information, see our{' '}
              <a
                href="https://help.coinlion.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                help page
              </a>
            </span>
          }
        />
      )}

      <AddFundsModal modal={addFundsModal} />
      <RemoveFundsModal modal={removeFundsModal} />
    </div>
  )
}

export default BankSettings
