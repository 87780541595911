import React from 'react'

const ErrorIcon = ({
  fill = '#E00043',
  width = '20',
  height = '20',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zm-.828 3.334a.833.833 0 111.667 0v3.828a.833.833 0 01-1.667 0V6.667zm-.127 6.544a.956.956 0 101.91 0 .956.956 0 00-1.91 0z"
        fill={fill}
      />
    </svg>
  )
}

export default ErrorIcon
