import React, { useState } from 'react'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import _uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import Icon from '@pv3-shared-components/Icons'
import './tooltip.scss'

const Tooltip = ({ children, left, modalTitle, small }) => {
  const [id] = useState(_uniqueId('tooltip-'))
  const isBig = useWindowWidth()
  const [toolTipModalOpen, setToolTipModalOpen] = useState(false)
  const [offsetStyle, setOffsetStyle] = useState({})
  const [offsetStyleCaret, setOffsetStyleCaret] = useState({})
  const [marketplacePosition, setMarketplacePosition] = useState('')

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      handleToolTipClose()
    }
  }

  const handleToolTipClose = () => {
    window.removeEventListener('keyup', handleEsc)
    setToolTipModalOpen(false)
  }

  const handleToolTipClick = () => {
    if (!isBig) {
      setOffsetStyle({})
      setOffsetStyleCaret({})
      setToolTipModalOpen(true)
      window.addEventListener('keyup', handleEsc)
    }
  }

  const handleToolTipButtonHover = (e) => {
    if (isBig) {
      // Find if needs to be offset from edge of viewport
      const bodyRect = document.body.getBoundingClientRect()
      const elemRect = e.currentTarget.getBoundingClientRect()
      const offsetLeft = elemRect.left - bodyRect.left
      const offsetRight = elemRect.right - bodyRect.right
      const offsetTop = elemRect.top - bodyRect.top

      if (offsetRight > -150) {
        setOffsetStyle({ left: '-' + (150 + offsetRight) + 'px' })
        setOffsetStyleCaret({
          transform: 'translateX(' + (160 + offsetRight) + 'px)',
        })
      } else if (offsetLeft < 150) {
        setOffsetStyle({ left: 170 - offsetLeft + 'px' })
        setOffsetStyleCaret({
          transform: 'translateX(-' + (160 - offsetLeft) + 'px)',
        })
      } else {
        setOffsetStyle({})
        setOffsetStyleCaret({})
      }

      if (offsetTop > 1112) {
        setMarketplacePosition('Tooltip--marketplaceFixed')
      } else {
        setMarketplacePosition('')
      }

      setToolTipModalOpen(true)
    }
  }

  const handleToolTipButtonLeave = () => {
    if (isBig) setToolTipModalOpen(false)
  }

  return (
    <div
      className={`Tooltip${
        toolTipModalOpen ? ' Tooltip--modalOpen' : ''
      } ${left ? 'Tooltip--left' : ''} ${marketplacePosition}`}
    >
      <button
        type="button"
        className={`u-buttonReset Tooltip-button${
          small ? ' Tooltip-button--small' : ''
        }`}
        aria-label="Help?"
        aria-expanded={toolTipModalOpen ? true : false}
        aria-describedby={id}
        onFocus={handleToolTipButtonHover}
        onBlur={handleToolTipButtonLeave}
        onMouseOver={handleToolTipButtonHover}
        onMouseOut={handleToolTipButtonLeave}
        onClick={handleToolTipClick}
      >
        <Icon name="info" />
      </button>
      <section
        id={id}
        role="tooltip"
        className="Tooltip-content"
        style={offsetStyle}
      >
        <header className="Tooltip-content-header">
          <h3 className="Tooltip-content-title">{modalTitle}</h3>
          <button
            type="button"
            className="u-buttonReset Tooltip-content-close"
            aria-label="Close Modal"
            onClick={handleToolTipClose}
          >
            <Icon name="x" fill="#500078" />
          </button>
        </header>
        {children}
        <div className="Tooltip-caret" style={offsetStyleCaret} />
      </section>
      <div className="Tooltip-modalBg" onClick={handleToolTipClose} />
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  modalTitle: PropTypes.string,
  small: PropTypes.bool,
}

export default Tooltip
