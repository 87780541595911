import React, { useMemo } from 'react'
import Table from '@pv3-shared-components/Table'
import Button from '@pv3-shared-components/Button'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import { format, subMinutes } from 'date-fns'
import { fixFloatingPointErrors, formatAsDollarAmount, formatNumber } from '../../utils/utils'
import { eq, get, isUndefined, upperCase } from 'lodash'
import AddFundsModal from '@pv3-components/AddFundsModal'
import './depositswithdrawalstable.scss'
import useModal from '../../hooks/useModal'

const DepositsWithdrawalsTable = ({ transfers }) => {
  const isBig = useWindowWidth()
  const isBig992 = useWindowWidth(992)
  const isBig1200 = useWindowWidth(1200)
  const modal = useModal()

  const handleDepositClick = () => modal.setShow(true)

  const columns = useMemo(
    () =>
      isBig
        ? [
            // Desktop columns
            {
              Header: 'Date',
              id: 'date',
              className: 'DepositsWithdrawalsTable-dateCell',
              accessor: 'date',
              maxWidth: 90,
              Cell: ({ value }) => {
                const date = new Date(value)

                const localTimezoneOffset = new Date().getTimezoneOffset()

                return (
                  <React.Fragment>
                    {format(subMinutes(date, localTimezoneOffset), 'MM/dd/yy')}
                    <br />
                    {format(subMinutes(date, localTimezoneOffset), 'h:mm a')}
                  </React.Fragment>
                )
              },
            },
            {
              Header: 'Operation',
              id: 'operation',
              accessor: (d) => `${d.type}`,
            },
            {
              Header: 'Asset',
              id: 'asset',
              maxWidth: 75,
              accessor: (d) => `${upperCase(d.asset)}`,
            },
            {
              Header: 'amount',
              id: 'amount',
              headerClassName: 'DepositsWithdrawalsTable-amountCellHeader',
              className: 'DepositsWithdrawalsTable-amountCell',
              width: 150,
              accessor: 'amount',
              Cell: ({ original, value }) =>
                `${
                  eq(original.asset, 'usd')
                    ? formatAsDollarAmount(value)
                    : formatNumber(value, 0, 9)
                }`,
            },
            {
              Header: 'Status',
              id: 'status',
              width: 130,
              accessor: 'status',
              sortable: false,
              Cell: ({ original }) => (
                <div className={`DepositWithdrawalStatus ${original.status}`}>
                  {eq(original.status, 'AwaitingConfirmation')
                    ? 'Pending'
                    : original.status}
                </div>
              ),
            },
            {
              Header: isBig1200 ? 'Confirmations' : 'CFMs',
              id: 'confirmations',
              minWidth: isBig1200 ? 140 : 84,
              sortable: false,
              accessor: ({ data }) => {
                const confirmations = get(data, 'Confirmations')
                const confirmationsRequired = get(data, 'ConfirmationsRequired')
                return isUndefined(confirmationsRequired) ||
                  isUndefined(confirmations)
                  ? '--'
                  : `${confirmations} of ${confirmationsRequired}`
              },
            },
            {
              Header: isBig992 ? 'Description' : '',
              id: 'from',
              minWidth: 140,
              sortable: false,
              Cell: ({ original }) => {
                const link = get(original, 'data.Link')

                if (eq(original.asset, 'usd')) {
                  if (isBig992) {
                    return <React.Fragment>ACH transfer</React.Fragment>
                  }
                  return <React.Fragment>ACH</React.Fragment>
                }
                if (isUndefined(link))
                  return <React.Fragment>--</React.Fragment>

                if (isBig992) {
                  return (
                    <a href={link} target="_blank" rel="noreferrer noopener">
                      View transaction
                    </a>
                  )
                }
                return (
                  <a href={link} target="_blank" rel="noreferrer noopener">
                    View Tx
                  </a>
                )
              },
            },
          ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'left',
              accessor: 'date',
              className: 'MobileCell',
              Cell: ({ original }) => {
                const date = new Date(original.date)

                const localTimezoneOffset = new Date().getTimezoneOffset()

                const { data } = original
                const confirmations = get(data, 'Confirmations')
                const confirmationsRequired = get(data, 'ConfirmationsRequired')
                let status
                if (
                  isUndefined(confirmationsRequired) ||
                  isUndefined(confirmations)
                ) {
                  status = eq(original.status, 'AwaitingConfirmation')
                    ? 'Pending'
                    : original.status
                } else {
                  status = `${confirmations} of ${confirmationsRequired}`
                }

                return (
                  <div>
                    <div>
                      {format(
                        subMinutes(date, localTimezoneOffset),
                        'MM/dd/yy'
                      )}
                    </div>
                    <div className="MobileCell-small MobileCell-light">
                      {format(subMinutes(date, localTimezoneOffset), 'h:mm a')}
                    </div>
                    <div
                      className={`DepositWithdrawalStatus MobileCell-small ${original.status}`}
                    >
                      {status}
                    </div>
                  </div>
                )
              },
            },
            {
              Header: '',
              id: 'right',
              className: 'MobileCell  u-justifyend u-tr',
              Cell: ({ original }) => {
                const link = get(original, 'data.Link')
                return (
                  <div>
                    <div>
                      {eq(original.asset, 'usd')
                        ? formatAsDollarAmount(original.amount)
                        : `${fixFloatingPointErrors(
                            original.amount,
                            9
                          )} ${upperCase(original.asset)}`}
                    </div>
                    <div className="MobileCell-small MobileCell-light">
                      {original.type}
                    </div>
                    {!isUndefined(link) && (
                      <a
                        href={link}
                        className="MobileCell-small"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ fontWeight: '600' }}
                      >
                        View Transaction
                      </a>
                    )}
                  </div>
                )
              },
            },
          ],
    [isBig, isBig992, isBig1200]
  )

  return (
    <div className="DepositsWithdrawalsTable">
      <Table
        columns={columns}
        data={transfers}
        emptyHeadline="No deposits &amp; withdrawals to show"
        emptyDescription="Get started by adding funds to your account."
        emptyCta={<Button onClick={handleDepositClick}>Deposit</Button>}
        defaultSorted={[
          {
            id: 'date',
            desc: true,
          },
          {
            id: 'left',
            desc: true,
          },
        ]}
      />
      <AddFundsModal modal={modal} />
    </div>
  )
}

export default DepositsWithdrawalsTable
