import React from 'react'

const CheckmarkSmall = ({
  fill = '#00854d',
  width = '24',
  height = '24',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12A12 12 0 0012 0C5.373 0 0 5.373 0 12zm19.23-4.16a1.414 1.414 0 010 2l-8.78 8.77L5 13.16a1.414 1.414 0 012-2l3.46 3.45 6.77-6.77a1.414 1.414 0 012 0z"
        fill="#00854D"
      />
    </svg>
  )
}

export default CheckmarkSmall
