import React from 'react'

const Person = ({ fill = '#500078', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.222 8.888a6.222 6.222 0 11-12.444 0 6.222 6.222 0 0112.444 0zM16 16.887c4.258 0 8.321 1.78 11.209 4.908.216.24.34.548.347.871v5.333c0 .737-.597 1.334-1.334 1.334H5.778c-.73 0-1.324-.586-1.334-1.316v-5.333c.003-.329.127-.645.347-.89A15.253 15.253 0 0116 16.888z"
        fill={fill}
      />
    </svg>
  )
}

export default Person
