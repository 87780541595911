import React from 'react';
import Linkify from "react-linkify";

const ParagraphsFromNewlines = ({
  className = '',
  linkify,
  text,
}) => {
  if (!text) {
    return null;
  }

  const renderContent = () => (
    <div className={`ParagraphsFromNewlines ${className}`}>
      {text.split('\n').map((paragraph, index) => {
        if (!paragraph) {
          return null;
        }

        return (
          <p key={index}>
            {paragraph}
          </p>
        );
      })}
    </div>
  );

  if (linkify) {
    return (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => {
          if(/youtube\.com|twitter\.com/.test(decoratedHref)) {
            return (
              <a target="_blank" rel='noopener noreferrer' href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            );
          } else {
            return decoratedHref;
          }
        }}
      >
        {renderContent()}
      </Linkify>
    );
  } else {
    return renderContent();
  }
};

export default ParagraphsFromNewlines;
