import React from 'react'

const Clock = ({ fill = '#500078', width = '36', height = '36', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2C9.163 2 2 9.163 2 18s7.163 16 16 16 16-7.163 16-16A16 16 0 0018 2zm-4.796 7.15A9.94 9.94 0 008 17.94a10 10 0 008 9.76l-.27 1.53a11.55 11.55 0 118.53-21l-.85 1.36a9.94 9.94 0 00-10.206-.44zm5.716 1.6v7.65l5.01 3.41a1 1 0 11-1.11 1.66l-5.9-4v-8.72a1 1 0 112 0zM4 18c0 7.732 6.268 14 14 14a14 14 0 0014-14c0-7.732-6.268-14-14-14S4 10.268 4 18z"
        fill={fill}
      />
    </svg>
  )
}

export default Clock
