import React from 'react'
import Button from '@pv3-shared-components/Button'
import { useDispatch } from 'react-redux'
import { signInCoin } from '../../ducks/account'
import { APP_PATHS } from '@pv3-constants'
import{ useHistory } from 'react-router'


const LoadTest = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const handleAuth0Bypass = async () => {
        try {
            let response = await fetch('https://mockserver.qa.coinlion.io/oauth/token', { method: 'GET', mode: 'cors', headers: {'Access-Control-Allow-Origin': '*' } } );
            const token = await response.json();
            const accessToken = token.access_token;
            const expiresAt = token.expires_in;
            const idToken = token.id_token;
            
            await dispatch(signInCoin({ accessToken, idToken, expiresAt, loadTest: true }))

            history.push(APP_PATHS.BASE)


            } catch (e) {
            console.log(e)
             }

    }

    return(
        <div>
            <h2>Auto login for load testing</h2>
            <Button
                variant="primary"
                size="large"
                className="u-buttonReset"
                onClick={handleAuth0Bypass}
                >
                Sign In
            </Button>
        </div>
        )
}

export default LoadTest
