import React from 'react'

const Pending = ({
  fill = '#FCAB10',
  width = '20',
  height = '20',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Pending</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 1.11108C5.09078 1.11108 1.11108 5.09078 1.11108 9.99997C1.11108 14.9092 5.09078 18.8889 9.99997 18.8889C14.9092 18.8889 18.8889 14.9092 18.8889 9.99997C18.8889 7.64249 17.9524 5.38157 16.2854 3.71458C14.6184 2.04759 12.3575 1.11108 9.99997 1.11108ZM13.4444 12.8778C13.3629 13.0011 13.2356 13.0869 13.0906 13.1161C12.9457 13.1453 12.7951 13.1155 12.6722 13.0333L9.39442 10.8111V5.9722C9.39442 5.66537 9.64315 5.41664 9.94997 5.41664C10.2568 5.41664 10.5055 5.66537 10.5055 5.9722V10.2222L13.2833 12.1055C13.4076 12.1862 13.4945 12.3131 13.5247 12.4581C13.555 12.6031 13.5261 12.7542 13.4444 12.8778ZM6.37405 4.79423C8.42209 3.37195 11.1144 3.28501 13.25 4.57219L13.7222 3.81108C11.2823 2.32784 8.19757 2.42135 5.85199 4.04966C3.50641 5.67797 2.34038 8.53535 2.87704 11.3398C3.41369 14.1443 5.55227 16.3693 8.33331 17.0166L8.50553 16.1444C6.08103 15.562 4.22457 13.6102 3.76436 11.1595C3.30415 8.70892 4.32601 6.21651 6.37405 4.79423Z"
        fill={fill}
      />
    </svg>
  )
}

export default Pending
