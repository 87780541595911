import './Currency.scss';

import React from 'react';
import PropTypes from 'prop-types';

import bat from 'cryptocurrency-icons/svg/color/bat.svg';
import bch from 'cryptocurrency-icons/svg/color/bch.svg';
import bnt from 'cryptocurrency-icons/svg/color/bnt.svg';
import brd from 'cryptocurrency-icons/svg/color/brd.svg';
import btc from 'cryptocurrency-icons/svg/color/btc.svg';
import cvc from 'cryptocurrency-icons/svg/color/cvc.svg';
import elf from 'cryptocurrency-icons/svg/color/elf.svg';
import eth from 'cryptocurrency-icons/svg/color/eth.svg';
import gnt from 'cryptocurrency-icons/svg/color/gnt.svg';
import knc from 'cryptocurrency-icons/svg/color/knc.svg';
import link from 'cryptocurrency-icons/svg/color/link.svg';
import ltc from 'cryptocurrency-icons/svg/color/ltc.svg';
import nas from 'cryptocurrency-icons/svg/color/nas.svg';
import omg from 'cryptocurrency-icons/svg/color/omg.svg';
import poly from 'cryptocurrency-icons/svg/color/poly.svg';
import powr from 'cryptocurrency-icons/svg/color/powr.svg';
import qrl from 'cryptocurrency-icons/svg/color/qrl.svg';
import rdn from 'cryptocurrency-icons/svg/color/rdn.svg';
import rep from 'cryptocurrency-icons/svg/color/rep.svg';
import snt from 'cryptocurrency-icons/svg/color/snt.svg';
import storj from 'cryptocurrency-icons/svg/color/storj.svg';
import storm from 'cryptocurrency-icons/svg/color/storm.svg';
import tusd from 'cryptocurrency-icons/svg/color/tusd.svg';
import usd from 'cryptocurrency-icons/svg/color/usd.svg';
import usdc from 'cryptocurrency-icons/svg/color/usdc.svg';
import usdt from 'cryptocurrency-icons/svg/color/usdt.svg';
import xrp from 'cryptocurrency-icons/svg/color/xrp.svg';
import zil from 'cryptocurrency-icons/svg/color/zil.svg';
import zrx from 'cryptocurrency-icons/svg/color/zrx.svg';
import Logo from "../Logo";
import ada from 'cryptocurrency-icons/svg/color/ada.svg';
import sol from 'cryptocurrency-icons/svg/color/sol.svg';
import doge from 'cryptocurrency-icons/svg/color/doge.svg';
import avax from 'cryptocurrency-icons/svg/color/avax.svg';
import dot from 'cryptocurrency-icons/svg/color/dot.svg';
import matic from 'cryptocurrency-icons/svg/color/matic.svg';
import uni from 'cryptocurrency-icons/svg/color/uni.svg';
import Shib from './Shib';
import Near from './Near';
import Imx from './Imx';

const currencyTypes = {
    bat,
    bch,
    bnt,
    brd,
    btc,
    cvc,
    elf,
    eth,
    gnt,
    knc,
    link,
    ltc,
    nas,
    omg,
    poly,
    powr,
    qrl,
    rdn,
    rep,
    snt,
    storj,
    storm,
    tusd,
    usd,
    usdc,
    usdt,
    zil,
    zrx,
    xrp,
    ada,
    sol,
    avax,
    dot,
    doge,
    matic,
    uni
};

const Currency = ({className, type}) => {
    if (currencyTypes[type] != null) {
        let svgCode = currencyTypes[type].replace('<svg ', '<svg viewBox="0 0 32 32" ');
        return (
            <div
                className={`c-currency a-currency--${type} ${className || ''}`}
                dangerouslySetInnerHTML={{__html: svgCode}}
            />
        );
    } else if(type === 'lion') {
        return (
            <div className={`c-currency a-currency--${type} ${className || ''}`}>
                <Logo />
            </div>
        );
    } else if(type === 'imx') {
        return (
            <div className={`c-currency a-currency--${type} ${className || ''}`}>
                <Imx />
            </div>
        );
    } else if(type === 'near') {
        return (
            <div className={`c-currency a-currency--${type} ${className || ''}`}>
                <Near />
            </div>
        );
    } else if(type === 'shib') {
      return (
        <div className={`c-currency a-currency--${type} ${className || ''}`}>
          <Shib />
        </div>
      );
    } else {
        return (
            <div className={`c-currency a-currency--${type} ${className || ''}`} />
        );
    }
};

Currency.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([
        'bat',
        'bch',
        'bnt',
        'bokky',
        'brd',
        'btc',
        'cvc',
        'elf',
        'eth',
        'gnt',
        'knc',
        'lion',
        'link',
        'ltc',
        'nas',
        'omg',
        'poly',
        'powr',
        'qrl',
        'rdn',
        'rep',
        'snt',
        'storj',
        'storm',
        'tusd',
        'usd',
        'usdc',
        'usdt',
        'zil',
        'zrx',
        'xrp',
        'ada',
        'sol',
        'avax',
        'near',
        'dot',
        'doge',
        'shib',
        'imx',
        'matic',
        'uni'
    ]).isRequired
};

export default Currency;
