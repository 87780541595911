import './StrategySignalWebhookBody.scss';

import React, { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRouteMatch } from "react-router";

import { LOADING_STATES } from "../../constants";

import useStrategiesDuck from "../../hooks/useStrategiesDuck";
import useUiDuck from "../../hooks/useUiDuck";
import useModal from '../../hooks/useModal'

import Button from '@pv3-shared-components/Button'
import Flash from "@pv3-shared-components/Flash";
import Icon from '@pv3-shared-components/Icons'
import Loading from "@pv3-shared-components/Loading";
import CycleAPISecretModal from '../shared/CycleAPISecretModal';


const StrategySignalWebhookBody = ({isEditing, signalType, strategyId}) => {
  const { params } = useRouteMatch();
  const modal = useModal();

  const { apiKey, apiSecret } = useStrategiesDuck();
  const { loading } = useUiDuck();

  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false);

  const generateUrl = () => {
    const signalTypeStrings = {
      buy: 'buy',
      sell: 'sell',
      pauseBuy: 'disable-buy',
      resumeBuy: 'enable-buy',
      pauseSell: 'disable-sell',
      resumeSell: 'enable-sell',
      stopLoss: 'stop-loss',
    };

    return `${window.envConfig.COINLION_API_URL}webhook/api/trading_tool/strategies/${params.strategyId}/signals/${signalTypeStrings[signalType]}/?apiKey=${apiKey}&apiSecret=[ADD_API_SECRET_HERE]`
  }

  return (
    <div className="webhook">
      {isEditing ? (
        <React.Fragment>
          {loading[LOADING_STATES.FETCH_API_KEY] ? (
            <div className="ComponentLoader">
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <p className="webhook-warning"  data-testid="webhook-warning-text">
                You must use extreme caution with this link. The link below can be used to trigger this section of your strategy from an outside source such as TradingView. Do not provide this link to anyone else. If someone else has access to this link then you risk them triggering an activity on your account. You also need to spend significant time validating when and how the outside source is going to be triggering this section of your strategy. CoinLion has zero ability to validate the conditions behind the outside source. At present, this functionality is limited to TradingView but CoinLion is open to exploring additional outside sources upon request.
              </p>

              <div className="webhook-link"  data-testid="webhook-link-text">
                <textarea
                  disabled
                  value={generateUrl()}
                />

                <CopyToClipboard
                  text={generateUrl()}
                  options={{ format: 'text/plain' }}
                  onCopy={() => {
                    setShowCopiedToClipboard(true)
                    setTimeout(() => setShowCopiedToClipboard(false), 2000)
                  }}
                >
                  <Button variant="quinary" className="WireTransfer-copyButton Webhook-copyButton" type="button"  data-testid="webhook-copy-to-clipboard">
                    <Icon name="copy" />
                  </Button>
                </CopyToClipboard>

                <div className="DepositCrypto-copiedToClipboardAlertContainer">
                  <div
                    className={`WireTransfer-copiedToClipboardAlert ${
                      showCopiedToClipboard ? 'visible' : ''
                    }`}
                  >
                    <Flash
                      className="Flash--success"
                      hideCloseButton
                      iconSize={20}
                      iconFill="#00854d"
                    >
                      Link copied
                    </Flash>
                  </div>
                </div>
              </div>

              {apiKey && !apiSecret ? (
                <p className="webhook-warning"  data-testid="webhook-security-warning-text-1">
                  For security reasons, we don't allow you to view your API secret. If you no longer have access to it saved in a secure location, <span className="c-link" onClick={() => modal.setShow(true)} role="button">click here</span> to create a new one. WARNING: This action will disable all existing webhooks.
                </p>
              ) : (
                <p className="webhook-warning"  data-testid="webhook-security-warning-text-2">
                  For security reasons, we won't be able to display your API secret if you leave and come back. Please make sure to store it securely.
                </p>
              )}

              <CycleAPISecretModal modal={modal} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          Your strategy must be saved in order for our system to generate your Webhook URL. Please complete your strategy and press save above.
        </React.Fragment>
      )}
    </div>
  );
};

export default StrategySignalWebhookBody;
