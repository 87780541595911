import React, {useEffect, useState} from 'react';
import useProfileDuck from "../../hooks/useProfileDuck";
import {get as _get, isNil} from "lodash";
import Icon from "../shared/Icons";
import {useForm} from "react-hook-form";
import Input from "../shared/Input";
import InputError from "../shared/InputError";
import Button from "../shared/Button";
import FileUpload from "../shared/FileUpload";
import ParagraphsFromNewlines from "../shared/ParagraphsFromNewlines";

const PublicProfile = () => {
  const { avatarUploadPercentage, getProfile, profile, updateProfile, uploadAvatar } = useProfileDuck();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  useEffect(() => {
    if(profile.avatarImageUrl) {
      setCachedImageUrl(profile.avatarImageUrl);
    }
  }, [profile.avatarImageUrl]);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isSubmittingDisplayName, setIsSubmittingDisplayName] = useState(false);
  const [cachedImageUrl, setCachedImageUrl] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handleFormSubmit = async (data, e) => {
    e.preventDefault()

    try {
      setIsSubmittingDisplayName(true);

      const newProfile = {
        ...profile,
        description: data.description,
        displayName: data.displayName,
      };

      await updateProfile(newProfile);

      getProfile();
    } finally {
      setIsSubmittingDisplayName(false);
    }
  };

  const handleImageDrop = async (file) => {
    try {
      setIsUploadingImage(true);
      setPreview(file);

      await uploadAvatar(file);

    } catch(err) {
      setPreview(null);
      console.log('PublicProfile handleImageDrop err', err);
    } finally {
      setIsUploadingImage(false);
    }
  };

  return (
    <section className="Fieldset AcctSettings-fieldset AcctSettings-fieldset--profile AcctSettings-fieldset--public">
        <h1 className="Legend AcctSettings-legend">
          Public profile
        </h1>

        <FileUpload
          disabled={isUploadingImage}
          imageUrl={cachedImageUrl}
          isUploading={isUploadingImage}
          onDrop={handleImageDrop}
          preview={preview}
          progress={avatarUploadPercentage}
        />

        <dl className="AcctSettings-list">
          <dt>
            Display name

            {!isEditingName && (
              <button
                className="u-buttonReset AcctSettings-editSingle"
                title="Edit display name"
                type="button"
                onClick={() => setIsEditingName(!isEditingName)}
              >
                <span className="u-a11yOnly">
                  Edit display name
                </span>
                <Icon name="pencilfilled" />
              </button>
            )}
          </dt>

          <dd>
            {isEditingName ? (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Input
                  type="text"
                  autoComplete="off"
                  register={{
                    ...register('displayName', {
                      required: 'Display name is required to save',
                    }),
                  }}
                  error={
                    errors.displayName && (
                      <InputError message={errors.displayName.message} />
                    )
                  }
                  defaultValue={profile.displayName}
                />

                <div className="AcctSettings-descriptionContainer">
                  Description <br />
                  <Input
                    inputType="textarea"
                    autoComplete="off"
                    register={{
                      ...register('description', {}),
                    }}
                    defaultValue={profile.description}
                  />
                </div>

                <div className="AcctSettings-list-updateButtons">
                  <Button
                    variant="primary"
                    size="small"
                    type="submit"
                    isLoading={isSubmittingDisplayName}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    size="small"
                    type="button"
                    onClick={() => setIsEditingName(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            ) : !isNil(_get(profile, 'displayName')) ? (
              _get(profile, 'displayName')
            ) : (
              '--'
            )}
          </dd>

          {!isEditingName && (
            <React.Fragment>
              <dt>
                Description
              </dt>

              <dd className="AcctSettings--Description">
                {!isNil(_get(profile, 'description')) ? (
                  <ParagraphsFromNewlines
                    linkify
                    text={_get(profile, 'description')}
                  />
                ) : (
                  '--'
                )}
              </dd>
            </React.Fragment>
          )}
        </dl>
    </section>
  )
};

export default PublicProfile;
