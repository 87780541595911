import './rewardsVoting.scss';

import * as React from 'react';

const RewardsVoting: React.FC = () => {
    return (
      <div className="Content Content--voting">
        <h2 className="rewards-title">
          When You HODL You Have a Voice to Vote
        </h2>

        <div className="Voting-Content">
          <p>
            Many of the HODL program’s rules and settings can be modified if the community (you) decide they should be modified. The intent is to allow all users who have HODL’d their LION to have a voice on program modifications. We do this by rewarding votes. You’ll get one vote for every one LION you earn from HODLing. For example, if you receive 2,300 LION from HODLing, then you will be able to cast 2,300 votes toward the program’s rules.
          </p>

          <p>
            The voting system is designed to be fair and transparent. All votes are recorded on the blockchain and can be verified by anyone. The voting system is also designed to be secure. Votes are cast from your wallet and are protected by your wallet’s security measures.
          </p>

          <p>
            Here are some examples of what you might be able to vote on:
            <ul>
              <li>Annual allocation of LION in the HODL program. This can move up or down by 20% each year.</li>
              <li>Extending the number of years that LION is allocated to the HODL program. It is currently set to 20 years.</li>
              <li>Modifying the LION Rewards allocated to the 6-month, 12-month, and 18-month programs.</li>
              <li>Introducing a 24-month or 36-month HODL program.</li>
              <li>Changing the referral program to incentivize different behavior.</li>
            </ul>
          </p>
        </div>
      </div>
    );
};

export default RewardsVoting;
