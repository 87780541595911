import React, { useEffect } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Route } from 'react-router-dom'
import { isUndefined, get } from 'lodash'
import Auth0Error from '../Auth0Error'
import useOnboardingRedirect from '@pv3-hooks/useOnboardingRedirect'
import Splash from '../Splash'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../../ducks/profile'
import useAccount from '../../hooks/useAccount'

const ProtectedRoute = ({ component, ...args }) => {
  const dispatch = useDispatch()
  const { hasCoinSession, signInCoin, signOut } = useAccount()
  const showSplash = useOnboardingRedirect()
  const { error, isAuthenticated } = useAuth0()
  const backendSessionExpired = useSelector((state) =>
    get(state, 'ui.backendSessionExpired')
  )

  useEffect(() => {
    if (!isAuthenticated || hasCoinSession) return
    signInCoin()
  }, [isAuthenticated, hasCoinSession, signInCoin])

  useEffect(() => {
    if (!hasCoinSession) return
    dispatch(getProfile())
  }, [dispatch, hasCoinSession])

  useEffect(() => {
    if (backendSessionExpired) signOut()
  }, [backendSessionExpired, signOut])

  if (!isUndefined(error)) return <Route component={Auth0Error} />

  return (
    <Route
      component={withAuthenticationRequired(showSplash ? Splash : component)}
      {...args}
    />
  )
}

export default ProtectedRoute
