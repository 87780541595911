import React, { useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { eq, isNil, startsWith } from 'lodash'
import useClickAway from '@pv3-hooks/useClickAway'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import './header.scss'
import useAccount from '@pv3-hooks/useAccount'
import { APP_PATHS } from '@pv3-constants'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import Loading from '@pv3-shared-components/Loading'
import useCurrencyCount from '@pv3-hooks/useCurrencyCount'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import Logo from "../Logo";

const Header = () => {
  const { pathname } = useLocation()
  const headerNavSecondaryRef = useRef(null)
  const headerProfileDropdownTrigger = useRef(null)
  const [primaryMenuOpen, setPrimaryMenu] = useState(false)
  const [profileMenuOpen, setProfileMenu] = useState(false)
  const { signOut } = useAccount()
  const { profileNameInitials } = useProfileDuck()
  const { accountBalances } = useWalletsDuck()
  const portfolioValue = useCurrencyCount(accountBalances?.totalAccountBalanceUsd ? accountBalances.totalAccountBalanceUsd : null)
  const isBig = useWindowWidth()

  const handlePrimaryTriggerClick = () => setPrimaryMenu(!primaryMenuOpen)

  const handleProfileTriggerClick = () => setProfileMenu(!profileMenuOpen)

  const handleLogoutButtonClick = () => {
    signOut()
  }

  useClickAway([headerProfileDropdownTrigger, headerNavSecondaryRef], () =>
    setProfileMenu(false)
  )

  if (eq(pathname, APP_PATHS.ONBOARDING)) return <React.Fragment />

  return (
    <header
      className={`Header ${primaryMenuOpen ? 'Header--primaryNavOpen' : ''} ${
        profileMenuOpen ? 'Header--secondaryNavOpen' : ''
      }`}
    >
      <div className="Header-content u-container">
        <Link to="/" className="Header-logo" tabIndex="1">
          <Logo />
        </Link>
        <button
          id="profile_menu_btn"
          data-testid="profile_menu_btn"
          className="u-buttonReset Header-profileDropdown"
          type="button"
          ref={headerProfileDropdownTrigger}
          onClick={handleProfileTriggerClick}
          tabIndex="3"
        >
          <strong className="Header-initials">{profileNameInitials}</strong>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.68 8.987L12 16.934 4.32 8.987a1.134 1.134 0 111.633-1.573L12 13.674l6.053-6.26a1.134 1.134 0 111.634 1.573h-.007z"
            />
          </svg>
        </button>
        <button
          className="Header-menuButton"
          type="button"
          onClick={handlePrimaryTriggerClick}
        >
          {primaryMenuOpen ? (
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Close Menu</title>
              <path
                d="M21.253 20l6.48-6.48a.889.889 0 00-1.253-1.253L20 18.747l-6.48-6.49a.889.889 0 00-1.253 1.254L18.747 20l-6.49 6.48a.889.889 0 101.254 1.253L20 21.253l6.48 6.48a.889.889 0 001.253-1.253L21.253 20z"
                fill="#500078"
              />
            </svg>
          ) : (
            <svg
              width="40"
              height="40"
              viewBox="-2 -2 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Open Menu</title>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 9H4a1 1 0 010-2h28a1 1 0 110 2zm0 10H4a1 1 0 110-2h28a1 1 0 110 2zM4 29h28a1 1 0 100-2H4a1 1 0 100 2z"
                fill="#500078"
              />
            </svg>
          )}
        </button>
        <nav className="HeaderNav">
          <ul className="HeaderNav-primary">
            <li
              className={`HeaderNav-primary-item ${
                eq(pathname, APP_PATHS.BASE)
                  ? 'HeaderNav-primary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.BASE}
                className="HeaderNav-primary-link"
                tabIndex={(() => {
                  if (isBig) return '2'
                  return null
                })()}
              >
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={`HeaderNav-primary-item ${
                startsWith(pathname, APP_PATHS.MARKETPLACE)
                  ? 'HeaderNav-primary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.MARKETPLACE}
                className="HeaderNav-primary-link"
                tabIndex={(() => {
                  if (isBig) return '2'
                  return null
                })()}
              >
                <span>Trading</span>
              </Link>
            </li>
            <li
              className={`HeaderNav-primary-item ${
                startsWith(pathname, APP_PATHS.ACTIVITY)
                  ? 'HeaderNav-primary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.ACTIVITY}
                className="HeaderNav-primary-link"
                tabIndex={(() => {
                  if (isBig) return '2'
                  return null
                })()}
              >
                <span>Activity</span>
              </Link>
            </li>
            <li
              className={`HeaderNav-primary-item ${
                startsWith(pathname, APP_PATHS.REWARDS)
                  ? 'HeaderNav-primary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.REWARDS_HODL_OVERVIEW}
                className="HeaderNav-primary-link"
                tabIndex={(() => {
                  if (isBig) return '2'
                  return null
                })()}
              >
                <span>Rewards</span>
              </Link>
            </li>
            <li
              className={`HeaderNav-primary-item HeaderNav-primary-item--mobile ${
                eq(pathname, APP_PATHS.ACCOUNT)
                  ? 'HeaderNav-primary-item--active'
                  : ''
              }`}
            >
              <Link to={APP_PATHS.ACCOUNT} className="HeaderNav-primary-link">
                <span>Account Settings</span>
              </Link>
            </li>
          </ul>
          <ul ref={headerNavSecondaryRef} className="HeaderNav-secondary">
            <li
              className={`HeaderNav-secondary-item HeaderNav-secondary-item--desktop ${
                eq(pathname, APP_PATHS.ACCOUNT)
                  ? 'HeaderNav-secondary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.ACCOUNT}
                className="HeaderNav-secondary-link"
                tabIndex="4"
                data-testid="account-settings_menu_btn"
              >
                <span>Account Settings</span>
              </Link>
            </li>
            <li
              className={`HeaderNav-secondary-item HeaderNav-secondary-item--desktop ${
                eq(pathname, APP_PATHS.ACCOUNT_BANK)
                  ? 'HeaderNav-secondary-item--active'
                  : ''
              }`}
            >
              <Link
                to={APP_PATHS.ACCOUNT_BANK}
                className="HeaderNav-secondary-link"
                tabIndex="4"
                data-testid="bank-settings_menu_btn"
              >
                <span>Bank Settings</span>
              </Link>
            </li>
            <li className="HeaderNav-secondary-item">
              <a
                href="https://help.coinlion.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="HeaderNav-secondary-link"
                tabIndex={(() => {
                  if (isBig) return '4'
                  return null
                })()}
              >
                <span>Help</span>
              </a>
            </li>
            <li className="HeaderNav-secondary-item">
              <button
                id="logout_btn"
                data-testid="logout_btn"
                onClick={handleLogoutButtonClick}
                className="u-buttonReset HeaderNav-secondary-link"
                type="button"
                tabIndex={(() => {
                  if (isBig) return '4'
                  return null
                })()}
              >
                <span>Log Out</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="Header-totalValue">
          {!isNil(accountBalances?.totalAccountBalanceUsd) ? (
            <strong className="Header-totalValue-value">
              {portfolioValue || '$0.00'}
            </strong>
          ) : (
            <Loading />
          )}
          <small className="Header-totalValue-text">Total value</small>
        </div>
      </div>
    </header>
  )
}

export default Header
