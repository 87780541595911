import React from 'react'
import Header from '@pv3-shared-components/Header'
import Footer from '@pv3-shared-components/Footer'
import Main from '@pv3-components/Main'

const Base = () => {
  return (
    <React.Fragment>
      <Header />
      <Main />
      <Footer />
    </React.Fragment>
  )
}

export default Base
