import React from 'react'

const Down = ({ fill = '#E00043', width, height, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.4357 8H5.56426C5.32828 8 5.26393 8.14141 5.41409 8.32323L11.8713 15.9394C11.9356 16.0202 12.0643 16.0202 12.1287 15.9394L18.5859 8.32323C18.736 8.14141 18.6717 8 18.4357 8V8Z"
        fill={fill}
      />
    </svg>
  )
}

export default Down
