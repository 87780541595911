import * as React from 'react';

import Icon from '../Icons';

type Props = {
    className?: string
    message?: string
}

const InputError: React.FC<Props> = ({
    className = '',
    message,
}) => {
  if (!message) return null;

  return (
    <em className={`InputFeedback InputFeedback-error ${className}`}>
      <Icon name="error" />
      {message}
    </em>
  );
}

export default InputError;
