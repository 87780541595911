import * as React from 'react';
import { useEffect, useMemo, useState } from "react";

import { formatAsDollarAmount, formatNumber, isObjectNullOrEmpty } from '../../../../../../utils/utils';
import { KycVerificationStatuses } from "../../../../../../utils/enums";
import useProfileDuck from "../../../../../../hooks/useProfileDuck";
import useWalletsDuck from "../../../../../../hooks/useWalletsDuck";

import {
  enrollInAggregator,
  getAggregatorEnrollments,
  updateAggregatorEnrollment,
} from "../../../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../../../ducks";

import Button, { ButtonTypes, ButtonVariants } from "../../../../../shared/Button";
import { InputTypes } from "../../../../../shared/Input";
import Select from "../../../../../shared/Select";
import Tooltip from '../../../../../shared/Tooltip';

const AggregatorForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [buyMaximum, setBuyMaximum] = useState<number | undefined>();
  const [initialUserFunds, setInitialUserFunds] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [programId, setProgramId] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const { aggregatorEnrollment, aggregatorPrograms } = useTypedSelector((state) => state.rewards);
  const { walletValuesInUSD } = useWalletsDuck();
  const { profile } = useProfileDuck();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(buyMaximum == null || !programId || initialUserFunds == null || profile?.kycVerificationStatus !== KycVerificationStatuses.Verified) {
      return;
    }

    try {
      setLoading(true);

      if(aggregatorEnrollment?.id) {
        await dispatch(updateAggregatorEnrollment({ enrollmentId: aggregatorEnrollment.id, buyMaximum, userFunds: initialUserFunds, programId, isActive: true })).unwrap();
      } else {
        await dispatch(enrollInAggregator({ buyMaximum, programId, userFunds: initialUserFunds, isActive: true })).unwrap();
      }

      await dispatch(getAggregatorEnrollments()).unwrap();
      setShowSuccessMessage(true);
    } catch(err) {
      console.log('AggregatorForm handleSubmit error', err);
    } finally {
      setLoading(false);
    }
  };

  const totalAvailable = useMemo(
    () => {
      if(isObjectNullOrEmpty(walletValuesInUSD)) {
        return undefined;
      }

      let total = walletValuesInUSD.usdc;

      if(profile.homeQuoteAsset === 'usd') {
        total = total + walletValuesInUSD.usd;
      } else if(profile.homeQuoteAsset === 'usdt') {
        total = total + walletValuesInUSD.usdt;
      }

      if(total < 0) {
        return 0;
      }

      return total;
    }, [profile.homeQuoteAsset, walletValuesInUSD]
  )

  const totalInAggregator = useMemo(
    () => {
      if(isObjectNullOrEmpty(aggregatorEnrollment)) {
        return 0;
      }

      return aggregatorEnrollment.remainingUserFunds + aggregatorEnrollment.tokenBalancerFunds;
    }, [aggregatorEnrollment]
  );

  useEffect(() => {
    if(!aggregatorEnrollment?.id) {
      return;
    }

    setProgramId(aggregatorEnrollment.programId);
    setInitialUserFunds(aggregatorEnrollment.remainingUserFunds);
    setBuyMaximum(aggregatorEnrollment.buyMaximum);
  }, [aggregatorEnrollment]);

  return (
    <form
      className="AggregatorForm LiquidatorForm"
      onSubmit={handleSubmit}
    >
      <div className="AggregatorForm-TotalInAggregator LiquidatorForm-LionQty">
        <div className="AggregatorForm-TotalInAggregatorValue LiquidatorForm-LionQtyValue">
          {formatAsDollarAmount(totalInAggregator)}
        </div>

        <div className="AggregatorForm-TotalInAggregatorLabel LiquidatorForm-LionQtyLabel">
          Total in Aggregator

          <Tooltip>
            Future Tooltip
          </Tooltip>
        </div>
      </div>

      <div className="LiquidatorForm-Inputs">
        <div className="LiquidatorForm-Input">
          <label className="Label">
            Aggregator Mode

            <Select
              onChange={(e) => setProgramId(e.target.value)}
              value={programId}
            >
              <option value="">Select a mode</option>

              {aggregatorPrograms.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </label>
        </div>


        <div className="LiquidatorForm-Input AggregatorForm-DollarSignInput">
          <label className="Label">
            Never Buy Above

            <input
              className="Input"
              min={0.01}
              name="buyMaximum"
              onChange={(e) => setBuyMaximum(e.target.value && Number(e.target.value))}
              step="0.01"
              type={InputTypes.Number}
              value={buyMaximum}
            />

            <div className="Aggregator-DollarSign">
              $
            </div>
          </label>
        </div>

        <div className="LiquidatorForm-Input AggregatorForm-DollarSignInput">
          <label className="Label">
            USD Value you want in the Aggregator

            <input
              className="Input"
              min={0}
              name="initialUserFunds"
              onChange={(e) => setInitialUserFunds(e.target.value && Number(e.target.value))}
              max={totalAvailable}
              step="0.01"
              type={InputTypes.Number}
              value={initialUserFunds}
            />

            <div className="Aggregator-DollarSign">
              $
            </div>
          </label>
          <div className="Aggregator-AvailableBalance">
            Available Balance to buy LION: {totalAvailable === undefined ? '--' : formatAsDollarAmount(totalAvailable)}
          </div>
        </div>
      </div>

      <div className="LiquidatorForm-Buttons">
        <Button
          className="LiquidatorForm-Button"
          disabled={buyMaximum == null || !programId || initialUserFunds == null || profile?.kycVerificationStatus !== KycVerificationStatuses.Verified}
          isLoading={loading}
          type={ButtonTypes.Submit}
          variant={ButtonVariants.Primary}
        >
          {aggregatorEnrollment?.id ? 'Update' : 'Save'}
        </Button>
      </div>

      <div className="LiquidatorForm-Input AggregatorForm-DollarSignInput AggregatorForm-DisabledInput">
        <label className="Label">
          Amount being purchased by the Token Balancer

          <input
            className="Input"
            disabled
            type="text"
            value={aggregatorEnrollment?.tokenBalancerFunds ? formatNumber(aggregatorEnrollment.tokenBalancerFunds, 2, 2) : '--'}
          />

          <div className="Aggregator-DollarSign">
            $
          </div>
        </label>
      </div>

      {showSuccessMessage && (
        <div className="LiquidatorForm-SuccessMessage">
          Your updates will be used on the next run of the Aggregator Service.
        </div>
      )}
    </form>
  );
};

export default AggregatorForm;
