import React from 'react'

const Trash = ({ fill = '#323232', width = '24', height = '24', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.334 3.907h-1.267v-1.24H9.934v1.24H8.667v-1.24c0-.71.556-1.297 1.267-1.333h4.133c.71.036 1.268.622 1.267 1.333v1.24zm-6.667 4.76H10v10H8.667v-10zm6.667 0H14v10h1.334v-10zm5.186-2.666H3.334a.667.667 0 010-1.334H20.52a.667.667 0 110 1.334zM5.907 22.667h12.187a1.953 1.953 0 001.906-2V7.487h-1.333v13.18a.62.62 0 01-.573.667H5.907a.62.62 0 01-.573-.667V7.487H4v13.18a1.953 1.953 0 001.907 2z"
        fill={fill}
      />
    </svg>
  )
}

export default Trash
