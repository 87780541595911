//import './Createmodal.scss';

import * as React from 'react';
import { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";

import { LOADING_STATES } from "../../../constants";

import useModal from "../../../hooks/useModal";
import useStrategiesDuck from '../../../hooks/useStrategiesDuck';
import useUiDuck from "../../../hooks/useUiDuck";

import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from '../Button';
import Icon from '../Icons';
import Modal from "../Modal";
import Flash from "../Flash";

type Props = {
  modal: ReturnType<typeof useModal>
}

const CreateAPIKeyModal: React.FC<Props> = ({
  modal,
}) => {
  const { apiKey, apiSecret, clearApiSecret, createApiKey, createApiSecret } = useStrategiesDuck();
  const { loading, setLoading } = useUiDuck();

  const [showCopiedKeyToClipboard, setShowCopiedKeyToClipboard] = useState(false);
  const [showCopiedSecretToClipboard, setShowCopiedSecretToClipboard] = useState(false);

  const handleGenerateApiKeyAndSecret = async () => {
    try {
      setLoading({context: LOADING_STATES.CYCLE_API_SECRET, isLoading: true})
      await createApiKey();
      let secretRes = await createApiSecret();
      return secretRes;
    } catch(err) {
      console.log('handleGenerateApiKeyAndSecret err', err);
      setLoading({context: LOADING_STATES.CYCLE_API_SECRET, isLoading: false})
    }
  }

  return (
    <Modal
      modal={modal}
      screens={{
        MAIN: {
          heading: 'API Key and Secret',
          body: (push) => (
            <div className="NoBuyTriggerModal-body">
              <div className="NoBuyTriggerModal-iconContainer">
                <Icon name="exclamation circle" />
              </div>
              <p className="NoBuyTriggerModal-description">
                You don't have an API key and secret to use with webhooks. Click below to create one.
              </p>
              <Button
                variant={ButtonVariants.Primary}
                className="NoBuyTriggerModal-button"
                isLoading={loading[LOADING_STATES.CYCLE_API_SECRET]}
                onClick={async () => {
                  let result = await handleGenerateApiKeyAndSecret();
                  if(result.error) {
                    push('ERROR')
                  } else {
                    push('SUCCESS')
                  }
                }}
                size={ButtonSizes.Large}
                type={ButtonTypes.Button}
              >
                Generate API Key and Secret
              </Button>
            </div>
          ),
        },
        SUCCESS: {
          heading: 'Success!',
          body: (
            <div className="NoBuyTriggerModal-body">
              <p className="NoBuyTriggerModal-description">
                Your API secret has been generated. We won't be able to show it to you again, so please store it in a secure place.
              </p>

              <p>
                <div className="webhook-link">

                  API Key: {apiKey}

                  <CopyToClipboard
                    text={apiKey}
                    options={{ format: 'text/plain' }}
                    onCopy={() => {
                      setShowCopiedKeyToClipboard(true)
                      setTimeout(() => setShowCopiedKeyToClipboard(false), 2000)
                    }}
                  >
                    <Button variant={ButtonVariants.Quinary} className="WireTransfer-copyButton Webhook-copyButton" type={ButtonTypes.Button}>
                      <Icon name="copy" />
                    </Button>
                  </CopyToClipboard>

                  <div className="DepositCrypto-copiedToClipboardAlertContainer">
                    <div
                      className={`WireTransfer-copiedToClipboardAlert ${
                        showCopiedKeyToClipboard ? 'visible' : ''
                      }`}
                    >
                      <Flash
                        className="Flash--success"
                        hideCloseButton
                        iconSize={20}
                        iconFill="#00854d"
                      >
                        API key copied
                      </Flash>
                    </div>
                  </div>
                </div>
              </p>

              <p>
                <div className="webhook-link ">
                  API Secret: {apiSecret}

                  <CopyToClipboard
                    text={apiSecret}
                    options={{ format: 'text/plain' }}
                    onCopy={() => {
                      setShowCopiedSecretToClipboard(true)
                      setTimeout(() => setShowCopiedSecretToClipboard(false), 2000)
                    }}
                  >
                    <Button variant={ButtonVariants.Quinary} className="WireTransfer-copyButton Webhook-copyButton" type={ButtonTypes.Button}>
                      <Icon name="copy" />
                    </Button>
                  </CopyToClipboard>

                  <div className="DepositCrypto-copiedToClipboardAlertContainer">
                    <div
                      className={`WireTransfer-copiedToClipboardAlert ${
                        showCopiedSecretToClipboard ? 'visible' : ''
                      }`}
                    >
                      <Flash
                        className="Flash--success"
                        hideCloseButton
                        iconSize={20}
                        iconFill="#00854d"
                      >
                        API secret copied
                      </Flash>
                    </div>
                  </div>
                </div>
              </p>

              <div className="NoBuyTriggerModal-buttonContainer">
                <Button
                  variant={ButtonVariants.Primary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={() => {
                    modal.setShow(false);
                    clearApiSecret();
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  OK
                </Button>
              </div>
            </div>
          ),
          noBackButton: true,
        },
        ERROR: {
          heading: 'An Error Has Occurred',
          body: (
            <div className="ConfirmApiSecretCycleModal-body">
              <p className="ConfirmApiSecretCycleModal-description">
                There was an error generating your API secret. Please try again.
              </p>

              <div className="NoBuyTriggerModal-buttonContainer">
                <Button
                  variant={ButtonVariants.Primary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={() => {
                    modal.setShow(false);
                    clearApiSecret();
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  OK
                </Button>
              </div>
            </div>
          ),
          noBackButton: true,
        },
      }}
    />
  );
};

export default CreateAPIKeyModal;
