import React from 'react'

const Copy = ({ fill = '#F5F7F9', width = '16', height = '8', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#a)">
        <path
          fill={fill}
          stroke="#A0A8B0"
          d="M8 .707 15.293 8 8 15.293.707 8z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v8H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Copy
