import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Icon from '@pv3-shared-components/Icons'
import Currency from '../shared/Currency'
import Button from '@pv3-shared-components/Button'
import './tradedetail.scss'
import {
  cloneDeep,
  startsWith,
  replace,
  get,
  isNil,
  eq,
  gt,
  lt,
  isUndefined,
  round,
} from 'lodash'
import {
  fixFloatingPointErrors,
  formatAsDollarAmount,
  isArrayNullOrEmpty,
  isObjectNullOrEmpty,
  scientificToDecimal
} from '../../utils/utils'
import useClickAway from '@pv3-hooks/useClickAway'
import Logo from "../shared/Logo";
import useStrategiesDuck from "../../hooks/useStrategiesDuck";
import {Link} from "react-router-dom";
import {TRIGGER_SETTINGS_ACCESS_STRINGS} from "../../constants";

const TradeDetail = ({
  tradeDetails,
  onDismiss,
  onSellClick,
  onHoldClick,
  onCancelClick,
}) => {
  const ref = useRef()
  useClickAway([ref], () => onDismiss())

  const { clearDynamicValuesSettings, dynamicValuesSettings, getDynamicValuesForIndicatorSettings, setTemporaryStrategySettings, structureTriggerFieldValues, testSettingsForDynamicValues } = useStrategiesDuck();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onDismiss()
      }
    }
    window.addEventListener('keyup', handleEsc)
    return () => {
      window.removeEventListener('keyup', handleEsc)
    }
  }, [onDismiss])

  useEffect(() => {
    if(isObjectNullOrEmpty(tradeDetails)) {
      return;
    }

    let builtSettings = [];
    const strategy = tradeDetails.position?.strategy;

    if(isObjectNullOrEmpty(strategy)) {
      return;
    }

    TRIGGER_SETTINGS_ACCESS_STRINGS.forEach((ss) => {
      let settings = get(tradeDetails.position.strategy, ss);
      if(!isNil(settings)) {
        settings.forEach((s, i) => {
          let testResults = testSettingsForDynamicValues(s, `${ss}[${i}]`, {baseAsset: strategy.marketId.split('_')[0], homeQuoteAsset: strategy.marketId.split('_')[1]});

          if(testResults) {
            builtSettings.push(testResults);
          }
        });
      }
    });

    getDynamicValuesForIndicatorSettings(builtSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeDetails]);

  useEffect(() => {
    return () => {
      clearDynamicValuesSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pair
  if (isUndefined(tradeDetails.instrument)) {
    pair = tradeDetails.market.toLowerCase().split('-')
  } else {
    pair = tradeDetails.instrument.split('_')
  }

  let strategy = tradeDetails.position
    ? tradeDetails.position.strategy || tradeDetails.position.strategyBasic
    : null

  const renderFilledPercentage = () => {
    let percentage =
      ((tradeDetails.requestedAmount - tradeDetails.remainingAmount) /
        tradeDetails.requestedAmount) *
      100

    return `${fixFloatingPointErrors(percentage, 2)}%`
  }

  const renderTakeProfit = () => {
    if (
      !tradeDetails.requestedPrice ||
      !tradeDetails.position ||
      !tradeDetails.position.openPrice
    ) {
      return null
    }
    let profit =
      (tradeDetails.requestedPrice - tradeDetails.position.openPrice) /
      tradeDetails.position.openPrice
    return (profit * 100).toFixed(2) + '%'
  }

  const renderClosedPrice = () => {
    if(
      (!eq(get(tradeDetails, 'side'), 1)) ||
      (tradeDetails.position && !tradeDetails.position.isClosed) ||
      (!tradeDetails.position && !tradeDetails.requestedPrice)
    ) {
      return null;
    }

    let price;

    if (pair[1] === 'usd') {
      price = formatAsDollarAmount(tradeDetails.requestedPrice)
    } else {
      price = `${tradeDetails.requestedPrice} ${pair[1].toUpperCase()}`
    }

    return (
      <React.Fragment>
        <dt>{tradeDetails.positionId ? 'Closed ' : 'Sell '}price</dt>
        <dd>
          {price}
          <small>
            {moment(tradeDetails.acceptedAt).format('M/DD/YYYY')}
          </small>
          <small>
            {moment(tradeDetails.acceptedAt).format('h:mm:ss A')}
          </small>
        </dd>
      </React.Fragment>
    );
  }

  const renderCurrentPrice = () => {
    if (!tradeDetails.position || !tradeDetails.position.calculatedPrice) {
      return null
    }

    if (pair[1] === 'usd') {
      return formatAsDollarAmount(tradeDetails.position.calculatedPrice)
    } else {
      return `${tradeDetails.position.calculatedPrice} ${pair[1].toUpperCase()}`
    }
  }

  const renderPurchasePrice = () => {
    let price;
    if (!isNil(tradeDetails.position) && tradeDetails.position.openPrice) {
      price = tradeDetails.position.openPrice;
    } else if(tradeDetails.quoteAmount) {
      price = tradeDetails.quoteAmount;
    }

    if (!price) {
      return null
    }

    if (pair[1] === 'usd') {
      price = formatAsDollarAmount(price, false, 4)
    } else {
      price = `${price} ${pair[1].toUpperCase()}`
    }

    return (
      <React.Fragment>
        <dt>Purchase price</dt>

        <dd>
          {price}

          {(!isNil(tradeDetails.position) || !isNil(tradeDetails.acceptedAt)) && (
            <>
              <small>
                {moment(tradeDetails.position ? tradeDetails.position.created : tradeDetails.acceptedAt).format('M/DD/YYYY')}
              </small>

              <small>
                {moment(tradeDetails.position ? tradeDetails.position.created : tradeDetails.acceptedAt).format('h:mm:ss A')}
              </small>
            </>
          )}
        </dd>
      </React.Fragment>
    )
  }

  const renderTakeProfitPrice = () => {
    if (
      !tradeDetails.position ||
      !tradeDetails.position.openPrice ||
      !strategy ||
      !strategy.sellProfitPercent
    ) {
      return null
    }

    let value =
      tradeDetails.position.openPrice +
      tradeDetails.position.openPrice * strategy.sellProfitPercent * 0.01

    if (pair[1] === 'usd') {
      return formatAsDollarAmount(value)
    } else {
      return `${value} ${pair[1].toUpperCase()}`
    }
  }

  const renderAge = (date, date2) => {
    const msInDay = 86400000
    const msInHour = 3600000
    const msInMinute = 60000
    let diffInMs

    if (date2) {
      diffInMs = moment(date2).diff(moment(date), 'seconds') * 1000
    } else {
      diffInMs = moment().diff(moment(date), 'seconds') * 1000
    }

    let days = Math.floor(diffInMs / msInDay)

    diffInMs = diffInMs % msInDay

    let hours = Math.floor(diffInMs / msInHour)

    diffInMs = diffInMs % msInHour

    let minutes = Math.floor(diffInMs / msInMinute)

    if (gt(days, 0)) {
      return `${days}days ${hours}hrs ${minutes}min`
    }

    if (gt(hours, 0)) {
      return `${hours}hrs ${minutes}min`
    }

    return `${minutes}min`
  }

  const renderHoldTime = () => {
    if (isNil(get(tradeDetails, 'position.created'))) {
      return null
    }
    if (tradeDetails.acceptedAt) {
      return renderAge(tradeDetails.position.created, tradeDetails.acceptedAt)
    }
    return renderAge(tradeDetails.position.created)
  }

  const renderSignal = (signal) => {
    let indicatorTriggers = [];

    if(tradeDetails.side === 0 && /StopLoss|TriggerSell/.test(signal.type)) {
      // Don't show sell triggers on buy orders [CLV3-1086]
      return null;
    }

    if(!isArrayNullOrEmpty(signal.triggerData)) {
      indicatorTriggers = signal.triggerData.map((trigger) => {
        return structureTriggerFieldValues(trigger);
      });
    }

    return (
      <div className="TradeDetail-signal">
        <dl className="TradeDetail-details">
          <dt className="TradeDetail-details-signal-date">
            {moment(signal.created).format('MM/DD/YYYY h:mm:ss A')}
          </dt>

          <dd className="TradeDetail-details-signal-type">
            {signal.type}
          </dd>

          {!isArrayNullOrEmpty(indicatorTriggers) && indicatorTriggers.map((i, index) => (
              <React.Fragment key={index}>
                <dt className="TradeDetail-indicatorfields">

                </dt>
                <dd className="TradeDetail-indicatorfields">
                  {i.name} - {i.fields.join(', ')}
                </dd>
              </React.Fragment>
            )
          )}

          {!isNil(signal.validationErrors) && signal.validationErrors !== '' && (
            <React.Fragment>
              <dt className="TradeDetail-indicatorfields">

              </dt>
              <dd className="TradeDetail-indicatorfields">
                {signal.validationErrors}
              </dd>
            </React.Fragment>
          )}

        </dl>
      </div>
    );
  };

  const renderStopLoss = () => {
    if (strategy.stopLoss != null) {
      return strategy.stopLoss
    }
    else {
      return 0
    }
  }

  const renderStrategyTriggersForOpenPosition = () => {
    if(!isNil(tradeDetails.side) || isNil(get(tradeDetails, 'position.strategy'))) {
      return null;
    }

    const strategyDetails = get(tradeDetails, 'position.strategy');

    const triggerLabels = [
      'Buy trigger',
      'Sell trigger',
      'Stop loss trigger',
      'Buy enable',
      'Buy disable',
      'Sell enable',
      'Sell disable',
    ]
    let triggers = [];

    TRIGGER_SETTINGS_ACCESS_STRINGS.forEach((accessString, i) => {
      if(!isArrayNullOrEmpty(get(strategyDetails, accessString))) {
        let settings = get(strategyDetails, accessString).map((t) => t.indicator);

        if(settings.length > 0 && dynamicValuesSettings) {
          settings.forEach((s, i) => {
            const dynamicValues = dynamicValuesSettings?.find((dvs) => dvs?.accessString === `${accessString}[${i}]`);

            //only show dynamic values for sell and stop loss triggers [CLV3-1164]
            if(dynamicValues?.hasValue && (accessString.includes('sellIndicatorTriggerSettings') || accessString.includes('stopLossIndicatorTriggerSettings'))) {
              settings[i] = `${settings[i]}=${dynamicValues.values.join(',')}`;
            }
          });

          triggers.push({
            label: triggerLabels[i],
            value: settings.join(', ')
          });
        }
      }
    });

    return (
      <React.Fragment>
        {triggers.map((t) => (
          <React.Fragment>
            <dt className="TradeDetail-openPositionsTriggerList--label">
              {t.label}
            </dt>

            <dd className="TradeDetail-openPositionsTriggerList--value">
              {t.value}
            </dd>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  const signals = useMemo(
    () => {
      let rawSignals = cloneDeep(get(tradeDetails, 'position.signals'));

      return rawSignals;
    }, [tradeDetails]
  );

  return (
    <section className="TradeDetail" ref={ref}>
      <div className="TradeDetail-closeBar">
        <button
          onClick={onDismiss}
          className="u-buttonReset TradeDetail-closeBar-close"
        >
          <Icon name="x" width="36" height="36" />
        </button>
      </div>
      <div className="TradeDetail-content">
        <header className="TradeDetail-header">
          <h1 className="TradeDetail-headline">
            {tradeDetails.positionId ? 'Automated ' : 'Manual '}
            {eq(tradeDetails.side, 0) || tradeDetails.isOpenPosition
              ? 'buy '
              : 'sell '}
            order
          </h1>
          <div className="TradeDetail-summary">
            <div className="TradeDetail-currency">
              {startsWith(tradeDetails.instrument, 'lion_') ? (
                <Logo />
              ) : (
                <Currency type={pair[0]} />
              )}
              <div className="TradeDetail-currency-details">
                <strong>
                  {tradeDetails.requestedAmount} {pair[0]}
                </strong>
                {replace(get(tradeDetails, 'instrument'), '_', '-')}
              </div>
            </div>
            <div
              className={`TradeDetail-gainLoss
                ${
                  get(tradeDetails, 'position.isClosed')
                    ? gt(tradeDetails.gainLoss, 0)
                      ? `TradeDetail-gainLoss--gain`
                      : ''
                    : ''
                }
                ${
                  get(tradeDetails, 'position.isClosed')
                    ? lt(tradeDetails.gainLoss, 0)
                      ? `TradeDetail-gainLoss--loss`
                      : ''
                    : ''
                }
              `}
            >
              {!isNil(tradeDetails.gainLossAmount) ? (
                <React.Fragment>
                  {/* Used on Open Positions */}
                  {gt(tradeDetails.gainLossAmount, 0) && '+'}
                  {pair[1] === 'usd'
                    ? `${formatAsDollarAmount(
                        tradeDetails.gainLossAmount
                      )} ${pair[1].toUpperCase()}`
                    : `${fixFloatingPointErrors(
                        tradeDetails.gainLossAmount,
                        2
                      )} ${pair[1].toUpperCase()}`}
                  <small>Unrealized</small>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {tradeDetails.positionId && tradeDetails.side !== 0 && tradeDetails.gainLoss ? (
                    <React.Fragment>
                      {gt(tradeDetails.gainLoss, 0) && '+'}
                      {pair[1] === 'usd'
                        ? formatAsDollarAmount(tradeDetails.gainLoss)
                        : `${round(
                            tradeDetails.gainLoss,
                            2
                          )} ${pair[1].toUpperCase()}`}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      --
                    </React.Fragment>
                  )}
                  {tradeDetails.position && eq(tradeDetails.side, 1) && (
                    <small>
                      {tradeDetails.position.isClosed
                        ? 'Realized'
                        : 'Unrealized'}
                    </small>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          {!isNil(renderTakeProfit()) && eq(get(tradeDetails, 'side'), 1) && (
            <React.Fragment>
              <span className="TradeDetail-takeProfit">Take profit</span>
              <span className="TradeDetail-takeProfitPercent">
                {renderTakeProfit()}
              </span>
            </React.Fragment>
          )}
        </header>
        <dl className="TradeDetail-details">
          {!isUndefined(tradeDetails.requestedAmount) &&
            !isUndefined(tradeDetails.remainingAmount) && (
              <React.Fragment>
                <dt>Filled</dt>
                <dd>
                  {renderFilledPercentage()}
                  <small>
                    {(
                      tradeDetails.requestedAmount -
                      tradeDetails.remainingAmount
                    ).toLocaleString()}{' '}
                    {pair[0]}
                  </small>
                </dd>
              </React.Fragment>
            )}

          {renderClosedPrice()}

          {renderPurchasePrice()}

          {isNil(get(tradeDetails, 'position.isClosed')) && (
            <React.Fragment>
              {!isNil(renderCurrentPrice()) && (
                <React.Fragment>
                  <dt>Current price</dt>
                  <dd>{renderCurrentPrice()}</dd>
                </React.Fragment>
              )}
              {!isNil(renderTakeProfitPrice()) &&
                eq(get(tradeDetails, 'side'), 1) && (
                  <React.Fragment>
                    <dt>Take Profit Price</dt>
                    <dd>{renderTakeProfitPrice()}</dd>
                  </React.Fragment>
                )}
            </React.Fragment>
          )}
          {!tradeDetails.isOpenPosition && (
            <React.Fragment>
              <dt>Fee</dt>
              <dd className="u-tu">
                {(() => {
                  if (isNil(tradeDetails.commission)) return '--'

                  if (eq(pair[tradeDetails.side], 'usd')) {
                    return '$' + scientificToDecimal(tradeDetails.commission)
                  }

                  return (
                    scientificToDecimal(tradeDetails.commission) +
                    ' ' +
                    pair[tradeDetails.side]
                  )
                })()}
              </dd>
            </React.Fragment>
          )}
          {!isNil(renderHoldTime()) && eq(tradeDetails.side, 1) && (
            <React.Fragment>
              <dt>Hold time</dt>
              <dd>{renderHoldTime()}</dd>
            </React.Fragment>
          )}
        </dl>
        <div className="TradeDetail-sellHold">
          {onSellClick && !onCancelClick && (
            <Button onClick={onSellClick} variant="quinary">
              Sell
            </Button>
          )}
          {onHoldClick && !onCancelClick && (
            <Button onClick={onHoldClick} variant="quinary">
              Hold
            </Button>
          )}
          {onCancelClick && (
            <React.Fragment>
              <p className="TradeDetail--orderStatus">
                <em>Attempting to sell...</em>
              </p>
              <Button
                onClick={onCancelClick}
                variant="quinary"
                className="u-smallMargin"
              >
                Cancel Sell
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      {strategy && (
        <div className="TradeDetail-strategy">
          <h2 className="TradeDetail-strategy-title">Strategy Details</h2>
          {get(tradeDetails, 'position') &&
          !eq(
            get(tradeDetails, 'position.strategy.userId'),
            get(tradeDetails, 'position.strategy.publisherUserId')
          ) ? (
            <React.Fragment>
              <em className="BadgeCopyCat BadgeCopyCat--dark">Copycat</em>
              <strong className="TradeDetail-name">
                {strategy.publisherDisplayName || `${strategy.publisherFirstName} ${strategy.publisherLastName}`}
              </strong>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <strong className="TradeDetail-name">{strategy.name}</strong>
              <dl className="TradeDetail-details">
                {renderStrategyTriggersForOpenPosition()}

                <dt>Take profit goal</dt>
                <dd>{strategy.sellProfitPercent}%</dd>
                {!isNil(renderStopLoss()) && (
                  <React.Fragment>
                    <dt>Stop Loss</dt>
                    <dd>{renderStopLoss()}%</dd>
                  </React.Fragment>
                )}

                {!isNil(strategy.id) && eq(strategy.publisherUserId, strategy.userId) && (
                  <React.Fragment>
                    <dt />
                    <dd>
                      <Link
                        onClick={() => {
                          if(strategy.isDeleted) {
                            setTemporaryStrategySettings(strategy);
                          }
                        }}
                        to={`/strategies/${strategy.id}/details`}
                      >
                        View strategy
                      </Link>
                    </dd>
                  </React.Fragment>
                )}
              </dl>
            </React.Fragment>
          )}
        </div>
      )}

      {!isArrayNullOrEmpty(signals) && (
        <div className="TradeDetail-strategy">
          <h2 className="TradeDetail-strategy-title">Signal Details</h2>


          {signals.map((signal) => renderSignal(signal))}
        </div>
      )}
    </section>
  )
}

TradeDetail.propTypes = {
  tradeDetails: PropTypes.object.isRequired,
  onDismiss: PropTypes.func,
  onSellClick: PropTypes.func,
  onHoldClick: PropTypes.func,
  onCancelClick: PropTypes.func,
}

export default TradeDetail
