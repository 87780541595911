import React from 'react'

const InfoOutline = ({
  fill = '#848d96',
  width = '20',
  height = '20',
  ...rest
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 9.99967C3.33325 6.31778 6.31802 3.33301 9.99992 3.33301C11.768 3.33301 13.4637 4.03539 14.714 5.28563C15.9642 6.53587 16.6666 8.23156 16.6666 9.99967C16.6666 13.6816 13.6818 16.6663 9.99992 16.6663C6.31802 16.6663 3.33325 13.6816 3.33325 9.99967ZM10.7388 6.61079C10.7388 7.04034 10.3906 7.38856 9.96103 7.38856C9.53148 7.38856 9.18325 7.04034 9.18325 6.61079C9.18325 6.18123 9.53148 5.83301 9.96103 5.83301C10.3906 5.83301 10.7388 6.18123 10.7388 6.61079ZM9.99992 15.5552C6.93167 15.5552 4.44436 13.0679 4.44436 9.99967C4.44436 6.93143 6.93167 4.44412 9.99992 4.44412C13.0682 4.44412 15.5555 6.93143 15.5555 9.99967C15.5555 11.4731 14.9702 12.8862 13.9283 13.928C12.8864 14.9699 11.4733 15.5552 9.99992 15.5552ZM10.5555 12.7775H11.6666C11.9734 12.7775 12.2221 13.0262 12.2221 13.333C12.2221 13.6398 11.9734 13.8886 11.6666 13.8886H8.33325C8.02643 13.8886 7.7777 13.6398 7.7777 13.333C7.7777 13.0262 8.02643 12.7775 8.33325 12.7775H9.44436V9.44412H8.88881C8.58198 9.44412 8.33325 9.19539 8.33325 8.88856C8.33325 8.58174 8.58198 8.33301 8.88881 8.33301H10.5555V12.7775Z"
        fill="#323232"
      />
    </svg>
  )
}

export default InfoOutline
