import React, {useEffect} from 'react'
import { Router, Route, Switch, useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { APP_PATHS } from '@pv3-constants'
import ProtectedRoute from '@pv3-components/ProtectedRoute'
import Onboarding from '@pv3-components/Onboarding'
import EmailVerified from '@pv3-components/EmailVerified'
import CreateAccount from '@pv3-components/CreateAccount'
import Base from '@pv3-components/Base'
import LoadTest from '@pv3-components/LoadTest'
import { get } from 'lodash'
import './app.scss'
import useAccount from "../../hooks/useAccount";
import {isWindows} from "react-device-detect";
import Referral from '../Referral';

const App = () => {
  const history = useHistory()

  const { loadTesting } = useAccount()

  useEffect(() => {
    if(isWindows) {
      document.querySelector(':root').classList.add('is-windows');
    }
  }, []);

  const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    history.replace(get(appState, 'returnTo') || window.location.pathname)
  }

  //console.log('Current build: ')
  return (
    <Auth0Provider
      domain={window.envConfig.COINLION_AUTH0_DOMAIN}
      clientId={window.envConfig.COINLION_V3_AUTH0_CLIENTID}
      redirectUri={window.envConfig.COINLION_V3_AUTH0_CALLBACKURL}
      onRedirectCallback={onRedirectCallback}
    >
      <Router history={history}>
        <Switch>
          <Route path={APP_PATHS.REFERRAL} component={Referral} />
          <Route path={APP_PATHS.CREATE_ACCOUNT} component={CreateAccount} />
          <Route path={APP_PATHS.EMAIL_VERIFIED} component={EmailVerified} />
          <Route path={APP_PATHS.LOAD_TEST_SIGN_IN} component={LoadTest} />
          <ProtectedRoute path={APP_PATHS.ONBOARDING} component={Onboarding} />
          {loadTesting ? (
            <Route path={APP_PATHS.BASE} component={Base} />
          ) : (
            <ProtectedRoute path={APP_PATHS.BASE} component={Base} />
          )}
        </Switch>
      </Router>
    </Auth0Provider>
  )
}

export default App
