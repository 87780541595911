import React, {useEffect, useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Button from '@pv3-shared-components/Button'
import MyPortfolioCard from '@pv3-components/MyPortfolioCard'
import StrategyThirds from '@pv3-components/StrategyThirds'
import './strategy.scss'
import { APP_PATHS } from '@pv3-constants'
import { FormProvider, get, useForm } from 'react-hook-form'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import { formatISO } from 'date-fns'
import useDocTitle from '@pv3-hooks/useDocTitle'
import StrategySignalSettings from '@pv3-components/StrategySignalSettings'
import { eq, isNil, isEmpty } from 'lodash'
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";
import clone from "clone";
import {TRIGGER_SETTINGS_ACCESS_STRINGS} from "../../constants";
import {unwrapResult} from "@reduxjs/toolkit";
import { OrderTypes } from '../../utils/enums';

const CreateStrategy = () => {
  useDocTitle('New Strategy')
  const history = useHistory()

  const [saveErrorMessage, setSaveErrorMessage] = useState();

  const {
    clearDynamicValuesSettings,
    clearSaveStrategyErrors,
    createStrategy,
    getDynamicValuesForIndicatorSettings,
    indicators,
    getIndicators,
    getStrategies,
    numerifyStrategySettings,
    setTemporaryStrategySettings,
    temporaryStrategySettings,
    testSettingsForDynamicValues,
  } = useStrategiesDuck()

  const dynamicVariableDebounce = useRef();
  const regetDebounce = useRef();

  const { handleSubmit, ...formMethods } = useForm({
    defaultValues: !isObjectNullOrEmpty(temporaryStrategySettings) && isNil(temporaryStrategySettings.id)
      ?
      temporaryStrategySettings
      :
      {
        name: null,
        asset: null,
        currency: null,
        isBuyActive: true,
        isSellActive: true,
        isPublic: false,
        holdingPercent: 10,
        maxOpenPositions: 10,
        sellProfitPercent: 1,
        stopLoss: 0,
        icebergPercentage: 0,
        afterOpenMinutes: 5,
        afterClosePreventOpenMinutes: 5,
        afterOpenPreventCloseMinutes: 5,
        afterStopLossPreventOpenMinutes: 5,
        buyOrderType: OrderTypes.Limit,
        sellOrderType: OrderTypes.Limit,
        stopLossOrderType: OrderTypes.Limit,
      },
  })
  const { getValues, watch } = formMethods;

  const handleBuildDynamicValuesSettings = async (strategySettings) => {
    let builtSettings = [];

    if(!strategySettings) {
      strategySettings = getValues();
    }

    if(isObjectNullOrEmpty(strategySettings)) {
      return null;
    }

    TRIGGER_SETTINGS_ACCESS_STRINGS.forEach((ss) => {
      let settings = get(strategySettings, ss);

      if(!isNil(settings)) {
        settings.forEach((s, i) => {
          let testResults = testSettingsForDynamicValues(s, `${ss}[${i}]`, strategySettings);

          if(testResults) {
            builtSettings.push(testResults);
          }
        });
      }
    });

    try {
      let results = await getDynamicValuesForIndicatorSettings(builtSettings);
      results = unwrapResult(results);

      if(results.find((d) => d.hasValue === false)) {
        clearTimeout(regetDebounce.current);
        regetDebounce.current = setTimeout(() => handleBuildDynamicValuesSettings(), 5000);
      }
    } catch(err) {
      console.log('CreateStrategy handleBuildDynamicValuesSettings err', err);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      setSaveErrorMessage(undefined);

      const buyTriggers = get(
        values,
        'buyIndicatorTriggerSettings.triggerIndicatorSettings'
      )
      if (isNil(buyTriggers) || eq(buyTriggers.length, 0)) {
        values.isBuyActive = false;
      }

      values = numerifyStrategySettings(values);
      values.sellProfitPercent = values.sellProfitPercent || 0;
      values.stopLoss = values.stopLoss || 0;

      await createStrategy({
        created: formatISO(new Date()),
        updated: formatISO(new Date()),
        ...values,
      });

      getStrategies();
      history.push(APP_PATHS.BASE, { scrollToStrategies: true })
    } catch(err) {
      console.log(err);
      if(typeof err === 'string') {
        setSaveErrorMessage(err);
      } else {
        if(isArrayNullOrEmpty(err)) {
          setSaveErrorMessage('There was an error saving your strategy. Please try again.');
        } else {
          setSaveErrorMessage('There are invalid values in your strategy settings. Please see below.');
        }
      }
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if(!isObjectNullOrEmpty(value)) {
        if(isNil(type)) {
          //this is adding a row, so we need to clear the dynamic values so they dont display in the wrong place for a short time.
          clearDynamicValuesSettings();
        }

        clearTimeout(dynamicVariableDebounce.current);

        let clonedValue = clone(value);

        setTemporaryStrategySettings(clonedValue)

        if(isNil(type)) {
          handleBuildDynamicValuesSettings(clonedValue);
        } else {
          dynamicVariableDebounce.current = setTimeout(() => handleBuildDynamicValuesSettings(clonedValue),  250);
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators, watch]);

  useEffect(() => {
    clearDynamicValuesSettings();

    if (!isEmpty(indicators)) return

    getIndicators()

    return () => {
      clearSaveStrategyErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="StrategyDetails">
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="u-screenBottomPadding"
        >
          <div className="u-container Strategy-content">
            <div id="new_strategy_heading" className="Strategy-top">
              <h1 className="Strategy-heading">New Strategy</h1>
              <div className="Strategy-actions">
                <div />

                <Link
                  id="discard_strategy_header_link"
                  to={APP_PATHS.BASE}
                  className="Button Button--secondary Button--small"
                >
                  Discard
                </Link>
                <Button id="create_strategy_header_btn" data-testid="create_strategy_header_btn" size="small">
                  Create Strategy
                </Button>
              </div>
            </div>

            {!isNil(saveErrorMessage) && (
              <div className="Strategy-saveError">
                {saveErrorMessage}
              </div>
            )}

            <MyPortfolioCard isEditing={false} />
            <StrategyThirds />
          </div>
          <hr className="StrategySignals-divider" />
          <StrategySignalSettings />
        </form>
      </FormProvider>
    </div>
  )
}

export default CreateStrategy
