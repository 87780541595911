import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATES } from '../constants'
import { updateBusinessProfile } from './profile'
import { createAuth0Account, signInCoin } from './session'
import {
  deleteOpenPosition,
  fetchClosedPositions,
  getOpenPositions,
  sellPosition,
  getTradeHistoryFromFilter,
  getIncompleteTrades,
} from '@pv3-ducks/trades'
import {
  createSubscription,
  updateSubscription,
  unsubscribe,
} from '@pv3-ducks/marketplace'
import {
  getAchItems,
  initiateAchDeposit,
  initiateAchWithdrawal,
  unlinkAch,
} from './ach'
import { createOrder } from './wallets'
import {getStrategySignals, pinBacktestHistoryItem, runStrategyBacktest, unpinBacktestHistoryItem} from "./strategies";
import {getPublishers} from "./marketplace";

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    showCtaCards: {
      FUND_YOUR_ACCOUNT: false,
      START_COPYCAT_TRADING: false,
    },
    flashes: [
      {
        id: null,
      },
    ],
    loading: {
      [LOADING_STATES.GLOBAL]: true,
      [LOADING_STATES.SIGN_IN_WITH_AUTH_CREDENTIALS]: false,
      [LOADING_STATES.FETCH_CLOSED_POSITIONS]: false,
      [LOADING_STATES.SIGN_IN_COIN]: false,
      [LOADING_STATES.FETCH_OPEN_POSITIONS]: false,
      [LOADING_STATES.DELETE_OPEN_POSITION]: false,
      [LOADING_STATES.SELL_POSITION]: false,
      [LOADING_STATES.FETCH_TRADE_HISTORY]: false,
      [LOADING_STATES.FETCH_INCOMPLETE_ORDERS]: false,
      [LOADING_STATES.ACH]: false,
      [LOADING_STATES.SUBSCRIBE]: false,
      [LOADING_STATES.UNSUBSCRIBE]: false,
      [LOADING_STATES.ORDER]: false,
      [LOADING_STATES.FETCH_API_KEY]: false,
      [LOADING_STATES.CYCLE_API_SECRET]: false,
      [LOADING_STATES.GETTING_PUBLISHERS]: false,
      [LOADING_STATES.GETTING_STRATEGY_SIGNALS]: false,
      [LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM]: false,
    },
    initialLoadComplete: false,
    permissionEstablished: false,
    ctaCardsInitiated: false,
    backendSessionExpired: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading[action.payload.context] = action.payload.isLoading
    },
    pushFlash: (state, action) => {
      state.flashes = [...state.flashes, action.payload]
    },
    setShowCtaCard: (state, action) => {
      state.showCtaCards[action.payload.card] = action.payload.show
    },
    setInitialLoadComplete: (state, action) => {
      state.initialLoadComplete = true
    },
    setPermissionEstablished: (state, action) => {
      state.permissionEstablished = true
    },
    setCtaCardsInitiated: (state, action) => {
      state.ctaCardsInitiated = true
    },
  },
  extraReducers: {
    [createAuth0Account.pending]: (state, action) => {
      state.loading[LOADING_STATES.CREATE_AUTH0_ACCOUNT] = true
    },
    [createAuth0Account.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.CREATE_AUTH0_ACCOUNT] = false
    },
    [createAuth0Account.rejected]: (state, action) => {
      state.loading[LOADING_STATES.CREATE_AUTH0_ACCOUNT] = false
    },
    [fetchClosedPositions.pending]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_CLOSED_POSITIONS] = true
    },
    [fetchClosedPositions.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_CLOSED_POSITIONS] = false
    },
    [fetchClosedPositions.rejected]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_CLOSED_POSITIONS] = false
    },
    [getPublishers.pending]: (state, action) => {
      state.loading[LOADING_STATES.GETTING_PUBLISHERS] = true;
    },
    [getPublishers.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.GETTING_PUBLISHERS] = false;
    },
    [getPublishers.rejected]: (state, action) => {
      state.loading[LOADING_STATES.GETTING_PUBLISHERS] = false;
    },
    [signInCoin.pending]: (state, action) => {
      state.loading[LOADING_STATES.SIGN_IN_COIN] = true
    },
    [signInCoin.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.SIGN_IN_COIN] = false
    },
    [signInCoin.rejected]: (state, action) => {
      state.loading[LOADING_STATES.SIGN_IN_COIN] = false
    },
    [updateBusinessProfile.pending]: (state, action) => {
      state.loading[LOADING_STATES.GLOBAL] = true
    },
    [updateBusinessProfile.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.GLOBAL] = false
    },
    [updateBusinessProfile.rejected]: (state, action) => {
      state.loading[LOADING_STATES.GLOBAL] = false
    },
    [getOpenPositions.pending]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_OPEN_POSITIONS] = action.meta.arg !== true
    },
    [getOpenPositions.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_OPEN_POSITIONS] = false
    },
    [getOpenPositions.rejected]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_OPEN_POSITIONS] = false
    },
    [deleteOpenPosition.pending]: (state, action) => {
      state.loading[LOADING_STATES.DELETE_OPEN_POSITION] = true
    },
    [deleteOpenPosition.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.DELETE_OPEN_POSITION] = false
    },
    [deleteOpenPosition.rejected]: (state, action) => {
      state.loading[LOADING_STATES.DELETE_OPEN_POSITION] = false
    },
    [sellPosition.pending]: (state, action) => {
      state.loading[LOADING_STATES.SELL_POSITION] = true
    },
    [sellPosition.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.SELL_POSITION] = false
    },
    [sellPosition.rejected]: (state, action) => {
      state.loading[LOADING_STATES.SELL_POSITION] = false
    },
    [getTradeHistoryFromFilter.pending]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_TRADE_HISTORY] = true
    },
    [getTradeHistoryFromFilter.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_TRADE_HISTORY] = false
    },
    [getTradeHistoryFromFilter.rejected]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_TRADE_HISTORY] = false
    },
    [getIncompleteTrades.pending]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_INCOMPLETE_ORDERS] = true
    },
    [getIncompleteTrades.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_INCOMPLETE_ORDERS] = false
    },
    [getIncompleteTrades.rejected]: (state, action) => {
      state.loading[LOADING_STATES.FETCH_INCOMPLETE_ORDERS] = false
    },
    [unlinkAch.pending]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = true
    },
    [unlinkAch.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [unlinkAch.rejected]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [getAchItems.pending]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = true
    },
    [getAchItems.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [getAchItems.rejected]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [initiateAchDeposit.pending]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = true
    },
    [initiateAchDeposit.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [initiateAchDeposit.rejected]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [initiateAchWithdrawal.pending]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = true
    },
    [initiateAchWithdrawal.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [initiateAchWithdrawal.rejected]: (state, action) => {
      state.loading[LOADING_STATES.ACH] = false
    },
    [createSubscription.pending]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = true
    },
    [createSubscription.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = false
    },
    [createSubscription.rejected]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = false
    },
    [updateSubscription.pending]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = true
    },
    [updateSubscription.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = false
    },
    [updateSubscription.rejected]: (state, action) => {
      state.loading[LOADING_STATES.SUBSCRIBE] = false
    },
    [unsubscribe.pending]: (state, action) => {
      state.loading[LOADING_STATES.UNSUBSCRIBE] = true
    },
    [unsubscribe.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.UNSUBSCRIBE] = false
    },
    [unsubscribe.rejected]: (state, action) => {
      state.loading[LOADING_STATES.UNSUBSCRIBE] = false
    },
    [createOrder.pending]: (state, action) => {
      state.loading[LOADING_STATES.ORDER] = true
    },
    [createOrder.rejected]: (state, action) => {
      state.loading[LOADING_STATES.ORDER] = false
    },
    [createOrder.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.ORDER] = false
    },
    [getStrategySignals.pending]: (state, action) => {
      state.loading[LOADING_STATES.GETTING_STRATEGY_SIGNALS] = true;
    },
    [getStrategySignals.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.GETTING_STRATEGY_SIGNALS] = false;
    },
    [runStrategyBacktest.pending]: (state, action) => {
      state.loading[LOADING_STATES.RUNNING_BACKTEST] = true;
    },
    [runStrategyBacktest.fulfilled]: (state, action) => {
      state.loading[LOADING_STATES.RUNNING_BACKTEST] = false;
    },
    [runStrategyBacktest.rejected]: (state, action) => {
      state.loading[LOADING_STATES.RUNNING_BACKTEST] = false;
    },
    [pinBacktestHistoryItem.pending]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = true;
    },
    [pinBacktestHistoryItem.fulfilled]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = false;
    },
    [pinBacktestHistoryItem.rejected]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = false;
    },
    [unpinBacktestHistoryItem.pending]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = true;
    },
    [unpinBacktestHistoryItem.fulfilled]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = false;
    },
    [unpinBacktestHistoryItem.rejected]: (state) => {
      state.loading[LOADING_STATES.SAVING_BACKTEST_HISTORY_ITEM] = false;
    },
  },
})

export const {
  setLoading,
  pushFlash,
  setShowCtaCard,
  setInitialLoadComplete,
  setPermissionEstablished,
  setCtaCardsInitiated,
} = uiSlice.actions
export default uiSlice.reducer
