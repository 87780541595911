import React, {useEffect, useState} from 'react'
import './subscriptionmodal.scss'
import Modal from '@pv3-shared-components/Modal'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import Button from '@pv3-shared-components/Button'
import { formatAsDollarAmount, isObjectNullOrEmpty} from '../../utils/utils'
import Tooltip from '@pv3-shared-components/Tooltip'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import { eq, filter, get, gte, isEmpty, isNaN, isNil, map, toNumber } from 'lodash'
import InputError from '@pv3-shared-components/InputError'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import useMarketplaceDuck from '@pv3-hooks/useMarketplaceDuck'
import ComponentLoader from '@pv3-components/ComponentLoader'
import {
  APP_PATHS,
  LOADING_STATES,
  SUBSCRIPTION_POSITION_MIN,
  ELIGIBLE_SUBSCRIPTION_POSITION_MIN_RATIO,
} from '@pv3-constants'
import { useHistory } from 'react-router-dom'
import useUiDuck from '@pv3-hooks/useUiDuck'
import {StopLossWarning} from "../shared/StopLossTooltip";
import useProfileDuck from "../../hooks/useProfileDuck";
import Input from "../shared/Input";

const SubscriptionModal = ({ modal, publisher, activeSubscription }) => {
  const history = useHistory();
  const isEdit = !isEmpty(activeSubscription)
  const porfolioPercent = get(activeSubscription, 'portfolioPercent') || 0
  const {
    createSubscription,
    updateSubscription,
    unsubscribe,
    getPublisherStrategies,
  } = useMarketplaceDuck()
  const { activitySummary } = useProfileDuck();
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [subscriptionTooSmall, setSubscriptionTooSmall] = useState(false)
  const [isCheckingSubscriptionSize, setIsCheckingSubscriptionSize] =
    useState(false)
  const [isInitializing, setIsInitializing] = useState(false);
  const [minimumDollarAmount, setMinimumDollarAmount] = useState(null);
  const { builder } = useStrategiesDuck()
  const { subscribeToLoadingStates } = useUiDuck()
  const { profile: { homeQuoteAsset } } = useProfileDuck();

  const formMethods = useForm({
    defaultValues: {
      percentToAllocate: isEdit ? Math.round(porfolioPercent * 100) / 100 : 0,
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
  } = formMethods;

  const {
    totalValueInUSD,
    walletValuesInUSD: { lion: lionValueInUSD },
  } = useWalletsDuck()
  const totalValueInUSDMinusLion = totalValueInUSD - (lionValueInUSD || 0)
  const watchPercentToAllocate = watch('percentToAllocate')
  const isMobile =
    'ontouchstart' in document.documentElement &&
    /mobi/i.test(navigator.userAgent)
  const isSubscribing = subscribeToLoadingStates(LOADING_STATES.SUBSCRIBE)
  const isUnsubscribing = subscribeToLoadingStates(LOADING_STATES.UNSUBSCRIBE)

  useEffect(() => {
    calculateMinimumSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateMinimumSubscription = async () => {
    try {
      setIsInitializing(true);

      let {payload: strategies} = await getPublisherStrategies(
        isEdit
          ? get(activeSubscription, 'publisherUserId')
          : get(publisher, 'publisherUserId')
      );

      let smallestPosition = 100;
      strategies.forEach(({holdingPercent, maxOpenPositions}) => {
        if(holdingPercent === 0 || maxOpenPositions === 0) {
          return;
        }

        const positionSize = (holdingPercent / maxOpenPositions);
        if (positionSize < smallestPosition) {
          smallestPosition = positionSize;
        }
      });

      let total = Math.ceil(100 / smallestPosition) * SUBSCRIPTION_POSITION_MIN;
      let minPercent = (total / totalValueInUSDMinusLion) * 100;

      // saw a race condition that caused minPercent === Infinity
      if(!isFinite(minPercent) || isNaN(minPercent)) {
        minPercent = 1;
      }

      if(!isEdit) {
        // Task requirement: the percent value being set needs to be an int
        setValue('percentToAllocate', Math.ceil(minPercent));
        trigger();
      }

      if(isNil(homeQuoteAsset) || homeQuoteAsset === 'usd') {
        setMinimumDollarAmount(formatAsDollarAmount(total));
      } else {
        setMinimumDollarAmount(`${total.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${homeQuoteAsset.toUpperCase()}`);
      }
    } catch(err) {
      console.log(err);
      modal.setShow(false);
    } finally {
      setIsInitializing(false);
    }
  }

  const calculateMaxStrategyAmount = (holdingPercent, totalValueInUSD) => {
    if (!totalValueInUSD) {
      return 0
    }

    let maxStrategyAmount = totalValueInUSD * (toNumber(holdingPercent) / 100);

    if(minimumDollarAmount) {
      let min = minimumDollarAmount.replace('$', '').replace(',', '');
      min = min.split(' ')[0];
      setSubscriptionTooSmall(holdingPercent && maxStrategyAmount < Number(min));
    }

    return maxStrategyAmount;
  }

  const percentToAllocateRounding = () => {
    if (isNaN(toNumber(watchPercentToAllocate))) {
      return
    }
    setValue(
      'percentToAllocate',
      Math.round(watchPercentToAllocate * 100) / 100
    )
  }

  const handleFocus = (event) => {
    const el = event.target
    setTimeout(() => {
      el.select()
    }, 20)
  }

  const renderPublisherName = () => {
    if (isObjectNullOrEmpty(publisher)) {
      return null;
    }

    if(isEdit) {
      return activeSubscription.publisherDisplayName || `${activeSubscription.publisherFirstName} ${activeSubscription.publisherLastName}`;
    } else {
      return publisher.displayName || `${publisher.firstName} ${publisher.lastName}`;
    }
  }



  const portfolio = (isReview) => {
    return (
      <section className="Strategy-third-portfolio">
        <h3 className="h6">
          {isReview ? renderPublisherName() : 'My Portfolio'}
        </h3>
        <div>
          <strong className="Strategy-third-portfolio-availablePercent">
            {isReview
              ? watchPercentToAllocate
              : Math.round(
                  (builder.portfolioPercentAvailable +
                    porfolioPercent -
                    (toNumber(watchPercentToAllocate) || 0)) *
                    100
                ) / 100}
            %
          </strong>{' '}
          {isReview ? 'Subscription' : 'Available'}
        </div>
        <div>
          <strong>
            {Math.round(builder.portfolioPercentAllocated * 100) / 100}%
          </strong>{' '}
          Allocated
        </div>
        <hr />
        <strong className="Strategy-third-portfolio-autoTradable">
          <Tooltip modalTitle="Auto-tradable balance">
            Your auto-tradable balance is the total value of all your assets
            that can be auto-traded. Generally speaking, this is usually your
            total account value not including LION.
          </Tooltip>
          {formatAsDollarAmount(totalValueInUSDMinusLion)}
        </strong>
        Auto-tradable balance
      </section>
    )
  }

  const checkMinPositionRatio = (strategies) => {
    const dollarAmount = calculateMaxStrategyAmount(
      toNumber(watchPercentToAllocate) || 0,
      totalValueInUSDMinusLion
    )


    let positionAmounts = []
    map(strategies, ({ holdingPercent, maxOpenPositions }) => {
      if(holdingPercent === 0) {
        return;
      }
      const amount = (dollarAmount * (holdingPercent / 100)) / maxOpenPositions
      for (let i = 0; i < maxOpenPositions; i++) {
        positionAmounts.push(amount)
      }
    })

    const eligiblePositions = filter(positionAmounts, (amount) => {
      return gte(amount, SUBSCRIPTION_POSITION_MIN)
    })

    const ratioSufficient = gte(
      eligiblePositions.length / positionAmounts.length,
      ELIGIBLE_SUBSCRIPTION_POSITION_MIN_RATIO
    )

    return ratioSufficient
  }

  const formatCalculatedSubscriptionValue = (amount) => {
    if(isNil(homeQuoteAsset) || homeQuoteAsset === 'usd') {
      return formatAsDollarAmount(amount);
    } else {
      return `${amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${homeQuoteAsset.toUpperCase()}`;
    }
  }

  return (
    <Modal
      modal={modal}
      screens={{
        MAIN: {
          heading: () => <h4>CopyCat Trading</h4>,
          body: (push) => (
            <React.Fragment>
              <ComponentLoader
                isLoading={isInitializing}
              />
              <FormProvider {...formMethods}>
                <form
                  className="SubscriptionModal-body"
                  onSubmit={handleSubmit((data, e) => {
                    e.preventDefault()
                    setErrorMessage('')
                    setSubscriptionTooSmall(false)
                    setIsCheckingSubscriptionSize(true)
                    // Check if subscription amount is too small
                    getPublisherStrategies(
                      isEdit
                        ? get(activeSubscription, 'publisherUserId')
                        : get(publisher, 'publisherUserId')
                    )
                      .then(({ payload }) => {
                        setTimeout(() => {
                          // To keep edit save button loading state from momentary becoming enabled between API calls
                          setIsCheckingSubscriptionSize(false)
                        }, 20)
                        if (checkMinPositionRatio(payload)) {
                          if (isEdit) {
                            updateSubscription(
                              get(activeSubscription, 'publisherUserId'),
                              get(data, 'percentToAllocate')
                            )
                              .then(() => {
                                modal.setShow(false)
                              })
                              .catch((error) => {
                                setErrorMessage(error)
                              })
                            return
                          }
                          push('REVIEW')
                        }
                      })
                      .catch((error) => {
                        setIsCheckingSubscriptionSize(false)
                        setErrorMessage(error)
                      })
                  })}
                >
                  {isEdit ? (
                    <h4 className="u-tc">
                      {renderPublisherName()}
                    </h4>
                  ) : (
                    <h4 className="u-tc">
                      How much of your auto-tradable balance will copy the trading
                      strategies of {renderPublisherName()}?
                    </h4>
                  )}
                  <div className="SubscriptionModal-percentWrapper">
                    <div className="Label">
                      <div className="SubscriptionModal-percent">
                        <Input
                          id="percentToAllocate"
                          className="Input"
                          type="text"
                          inputMode="decimal"
                          name="percentToAllocate"
                          maxLength="5"
                          autoComplete="off"
                          register={{...register('percentToAllocate', {
                            required: {
                              value: true,
                              message: 'Allocation percentage is required',
                            },
                            max: {
                              value:
                                builder.portfolioPercentAvailable +
                                porfolioPercent +
                                25,
                              message:
                                'Total portfolio allocation cannot be greater than 125%',
                            },
                            min: {
                              value: 0.01,
                              message:
                                'Allocation percentage must be at least 0.01%',
                            },
                            validate: (value) => {
                              return (
                                !isNaN(toNumber(value)) ||
                                'Allocation percentage must be a number'
                              )
                            },
                          })}}
                          onBlur={percentToAllocateRounding}
                          onFocus={handleFocus}
                          autoFocus={
                            (isEdit ||
                              !gte(builder.portfolioPercentAllocated, 125)) &&
                            !isMobile
                          }
                          triggerValidation
                        />
                        <div className="SubscriptionModal-percent-suffix">
                          <span
                            aria-hidden="true"
                            className="SubscriptionModal-percent-filler"
                          >
                            {watchPercentToAllocate}
                          </span>
                          <span>%</span>
                        </div>
                      </div>
                      <div className="Input-calculatedTotal">
                        {formatCalculatedSubscriptionValue(
                          calculateMaxStrategyAmount(
                            toNumber(watchPercentToAllocate) || 0,
                            totalValueInUSDMinusLion
                          )
                        )}<br />
                      </div>
                      {!isNil(minimumDollarAmount) && (
                        <div className="SubscriptionModal-minimumAmountToSubscribe">
                          <strong>
                            {minimumDollarAmount}
                          </strong>
                          Minimum {homeQuoteAsset.toUpperCase()} Value to Copy this Trader
                        </div>
                      )}

                      {subscriptionTooSmall === true && (
                        <EnhancedFlash
                          variant="danger"
                          heading="Subscription minimum not met"
                          subheading="The value of your starting subscription must be equal to or greater than the subscription minimum."
                        />
                      )}
                    </div>
                  </div>
                  {isEdit && (
                    <Button
                      variant="quaternary"
                      size="large"
                      type="button"
                      className="SubscriptionModal-unsubscribe"
                      onClick={() => {
                        setErrorMessage('')
                        push('UNSUBCRIBE')
                      }}
                    >
                      Unsubscribe
                    </Button>
                  )}
                  {portfolio()}
                  {errorMessage && <InputError message={errorMessage} />}

                  {get(publisher, 'hasStopLoss') && (
                    <StopLossWarning />
                  )}

                  {isEdit ? (
                    <React.Fragment>
                      <Button
                        variant="primary"
                        size="large"
                        type="submit"
                        disabled={
                          eq(toNumber(watchPercentToAllocate), porfolioPercent) ||
                          !isEmpty(errors) ||
                          subscriptionTooSmall
                        }
                        isLoading={isSubscribing || isCheckingSubscriptionSize}
                      >
                        Save
                      </Button>
                      <Button
                        variant="quaternary"
                        size="large"
                        type="button"
                        onClick={() => modal.setShow(false)}
                      >
                        Cancel
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      variant="primary"
                      size="large"
                      type="submit"
                      disabled={
                        eq(toNumber(watchPercentToAllocate), 0) ||
                        !isEmpty(errors) ||
                        gte(builder.portfolioPercentAllocated, 125) ||
                        subscriptionTooSmall
                      }
                      isLoading={isCheckingSubscriptionSize}
                    >
                      Review & Finalize
                    </Button>
                  )}
                </form>
              </FormProvider>
            </React.Fragment>
          ),
        },
        REVIEW: {
          heading: <h4>CopyCat Trading</h4>,
          body: (push) => (
            <form
              className="SubscriptionModal-body"
              onSubmit={handleSubmit((data, e) => {
                e.preventDefault()
                setErrorMessage('')
                createSubscription(
                  get(publisher, 'publisherUserId'),
                  get(data, 'percentToAllocate')
                )
                  .then(() => {
                    if(activitySummary.hasEnabledCltb !== true) {
                      push('CLTB');
                    } else {
                      modal.setShow(false);
                    }
                  })
                  .catch((error) => {
                    setErrorMessage(error)
                  })
              })}
            >
              <h4>Review subscription</h4>
              {portfolio(true)}
              <label className="SubscriptionModal-checkbox">
                <input
                  type="checkbox"
                  onChange={() => setTermsAgreed(!termsAgreed)}
                  checked={termsAgreed}
                />
                I understand that this subscription will buy and sell crypto on my behalf until I cancel it, and that this trader may utilize stop loss settings now or in the future. I understand that while preventing total loss during a crash, stop-losses sell positions that are down and can increase risk of loss during typical market volatility.
              </label>
              {errorMessage && <InputError message={errorMessage} />}
              <Button
                variant="primary"
                size="large"
                type="submit"
                disabled={!termsAgreed}
                isLoading={isSubscribing}
              >
                Confirm Subscription
              </Button>
            </form>
          ),
        },
        CLTB: {
          heading: <h4>CopyCat Trading</h4>,
          body: () => (
            <form
              className="SubscriptionModal-body SubscriptionModal-cltbContainer"
              onSubmit={handleSubmit((_, e) => {
                e.preventDefault()
                modal.setShow(false)
              })}
            >
              <h4>SUCCESS!</h4>

              <div className="SubscriptionModal-cltb">
                <h6>
                  Save 25% on every Trade Fee
                </h6>
                <p>
                  When you use the CoinLion Token balancer. Your account will automatically manage your Lion coin and save you money with every trade.
                </p>
                <Button
                  variant={'quaternary'}
                  size="large"
                  type="button"
                  onClick={() => {
                    modal.setShow(false)
                    history.push(APP_PATHS.ACCOUNT_BALANCER);
                  }}
                >
                  Learn more
                </Button>
              </div>

              <Button
                variant={'quaternary'}
                size="large"
                type="submit"
              >
                Done
              </Button>
            </form>
          ),
          noBackButton: true,
        },
        UNSUBCRIBE: {
          heading: <h4>Unsubscribe</h4>,
          body: () => (
            <form
              className="SubscriptionModal-body SubscriptionModal-body--unsubscribe"
              onSubmit={handleSubmit((_, e) => {
                e.preventDefault()
                setErrorMessage('')
                unsubscribe(get(activeSubscription, 'publisherUserId'))
                  .then(() => {
                    modal.setShow(false)
                  })
                  .catch((error) => {
                    setErrorMessage(error)
                  })
              })}
            >
              <h4>
                Are you sure you want to unsubscribe from {renderPublisherName()}?
              </h4>
              <p>
                All open positions under this subscription will remain open
                until the strategy sell signal triggers or you close manually.
              </p>
              {errorMessage && <InputError message={errorMessage} />}
              <Button
                variant="primary"
                size="large"
                type="submit"
                isLoading={isUnsubscribing}
              >
                Unsubscribe
              </Button>
            </form>
          ),
        },
      }}
    />
  )
}

SubscriptionModal.propTypes = {
  modal: PropTypes.object,
  publisher: PropTypes.object,
  activeSubscription: PropTypes.object,
}

export default SubscriptionModal
