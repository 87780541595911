import './rewardsHodlGeometricChart.scss';

import * as React from 'react';
import { useMemo } from "react";

type Props = {
  max?: number
  min?: number
  numberOfBars?: number
  valueString?: string
  valueTitle?: string
  xAxisValues?: Array<number>
  yAxisValues?: Array<number>
}

const RewardsHodlGeometricChart: React.FC<Props> = ({
  max = 1000,
  min = 0,
  numberOfBars = 10,
  valueString,
  valueTitle,
  xAxisValues,
  yAxisValues,
}) => {
  const columns = useMemo(
    () => {
      const bars = [];
      for(var i = 0; i < numberOfBars; i++) {
        bars.push(1000 / (Math.pow(2, i)));
      }
      return bars;
    }, [numberOfBars]
  );

  return (
    <div className={`HodlGeometricChart ${xAxisValues ? 'HodlGeometricChart--hasXAxis' : ''} ${yAxisValues ? 'HodlGeometricChart--hasYAxis' : ''}`}>
      <div className="HodlGeometricChart-Chart">
        {columns.map((column, i) => (
          <div
            className="HodlGeometricChart-Column"
            key={column.toString()}
            style={{
                height: `${(column / max) * 100}%`,
                opacity: 1 - (i / numberOfBars),
            }}
          />
        ))}

        {valueString && valueTitle && (
          <div className="HodlGeometricChart-ValueContainer">
            <div className="HodlGeometricChart-ValueTitle">
              {valueTitle}
            </div>

            <div className="HodlGeometricChart-Value">
              {valueString}
            </div>
          </div>
        )}
      </div>

      {xAxisValues && (
        <div className="HodlGeometricChart-XAxis">
          {xAxisValues.map((v) => (
            <div className="HodlGeometricChart-AxisValue">
              {v}
            </div>
          ))}
        </div>
      )}

      {yAxisValues && (
        <div className="HodlGeometricChart-YAxis">
          {yAxisValues.map((v) => (
            <div className="HodlGeometricChart-AxisValue">
              {v.toLocaleString()}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RewardsHodlGeometricChart;
