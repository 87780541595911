import { useState, useEffect } from 'react'
import { USD_GEOGRAPHIC_ALLOWED } from '@pv3-constants'
import { findIndex, isUndefined, cloneDeep, sortBy } from 'lodash'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import useMarketsDuck from '@pv3-hooks/useMarketsDuck'
import {isArrayNullOrEmpty} from "../utils/utils";
import clone from 'clone';
import useAssetsDuck from "./useAssetsDuck";

const useFilterMarkets = () => {
  const { pairs, tickerData } = useMarketsDuck()
  const { assets } = useAssetsDuck();
  const { profile } = useProfileDuck()
  const [marketsData, setMarketsData] = useState([])
  const [combinedMarketsData, setCombinedMarketsData] = useState([]);

  const combineMarketsData = () => {
    if(isArrayNullOrEmpty(marketsData)) {
      return;
    }

    let combined = marketsData.filter((item) => {
      if(item.baseAsset === 'lion') {
        return item.quoteAsset === 'usdc';
      }

      return item.quoteAsset === 'usd';
    });

    marketsData.forEach((md) => {
      if(md.quoteAsset === 'usd') {
        return;
      }

      const foundIndex = findIndex(combined, (item) => item.baseAsset === md.baseAsset);
      if(foundIndex === -1) {
        combined.push(clone(md));
      } else {
        if(combined[foundIndex].tickerData) {
          combined[foundIndex].tickerData = clone(combined[foundIndex].tickerData);
          combined[foundIndex].tickerData.volume = combined[foundIndex].tickerData.volume + md.tickerData.volume;
        }
      }
    })

    combined = sortBy(combined, ['baseAsset']);
    return combined;
  }

  // TODO: INHERITED TECH DEBT, REFACTOR
  const filterMarkets = () => {
    if (isUndefined(pairs)) {
      return
    }

    let markets = cloneDeep(pairs)

    let marketsData = []

    let isKycVerified = profile.kycVerificationStatus === 3;
    let moveUsdToFront
    if (
      profile.state
    ) {
      moveUsdToFront =
        isKycVerified &&
        USD_GEOGRAPHIC_ALLOWED.indexOf(
          profile.state.toLowerCase()
        ) !== -1
    } else {
      moveUsdToFront = isKycVerified
    }

    // Filter out non-active markets
    markets = Object.keys(markets)
      .filter((key) => !pairs[key].hidden)
      .reduce((obj, key) => {
        obj[key] = markets[key]
        return obj
      }, {})

    Object.keys(markets).forEach((key) => {
      if (markets[key].hidden === 0) {
        let market = markets[key]
        market.pair = key

        for (var i = 0, l = tickerData.length; i < l; i++) {
          if (tickerData[i].instrument === market.pair) {
            market.tickerData = tickerData[i]
            break
          }
        }

        if(!isArrayNullOrEmpty(assets)) {
          market.assetInfo = assets.find((asset) => asset.id === market.baseAsset);
        }

        if (market.quoteAsset === 'usd' && moveUsdToFront) {
          marketsData.unshift(market)
        } else {
          marketsData.push(market)
        }
      }
    })

    return sortBy(marketsData, ['pair'])
  }

  useEffect(() => {
    setMarketsData(filterMarkets())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairs, tickerData])

  useEffect(() => {
    setCombinedMarketsData(combineMarketsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, marketsData]);

  return { combinedMarketsData, marketsData }
}

export default useFilterMarkets
