import './hodlUnlockRewards.scss';

import * as React from 'react';
import { useState } from "react";

import { getHodlUserTotals } from "../../../../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../../../../ducks";
import useTrades from '../../../../../../../hooks/useTradesDuck';

import Button from "../../../../../../shared/Button";
import Tooltip from '../../../../../../shared/Tooltip';
import { isNil } from "lodash";

const HodlUnlockRewards: React.FC = () => {
  const dispatch = useAppDispatch();
  const { deleteOrdersForMarketAndSide } = useTrades();

  const [isDeletingAllOrders, setIsDeletingAllOrders] = useState<boolean>(false);

  const { userTotals } = useTypedSelector((state) => state.rewards);

  const handleCancelAllOrders = async () => {
    try {
      setIsDeletingAllOrders(true);
      //let res = await deleteOrdersForMarketAndSide(`lion_${homeQuoteCurrency}`, 1);
      await deleteOrdersForMarketAndSide({marketId: `lion_usdc`, side: 1});
      await dispatch(getHodlUserTotals()).unwrap();
    } catch (err) {
      console.log('RewardsHodlHistory handleCancelAllOrders error', err);
    } finally {
      setIsDeletingAllOrders(false);
    }
  };

  const renderPercentCommited = () => {
    if(!userTotals.lionHodlLocked || !userTotals.totalLionBalance) {
      return '--';
    }
    const percent = (userTotals.lionHodlLocked / userTotals.totalLionBalance) * 100;

    if (percent < 10) {
      return `${percent.toFixed(2)}%`;
    }

    return `${Math.round(percent)}%`;
  };

  const renderPercentSold = () => {
    if(!userTotals.lionSoldLast12Months || !userTotals.totalLionBalance) {
      return '--';
    }

    const percent = (userTotals.lionSoldLast12Months / (userTotals.totalLionBalance + userTotals.lionSoldLast12Months)) * 100;

    if (percent < 10) {
      return `${percent.toFixed(2)}%`;
    }

    return `${Math.round(percent)}%`;
  }
  return (
    <div className="Hodl-UnlockContainer">
      <header>
        <h4>
          Unlock more Rewards
        </h4>

        <div className="Hodl-UnlockContainer-Amount">
          <div className="Hodl-UnlockContainer-AmountValue">
            {userTotals.lionInOpenOrders ? userTotals.lionInOpenOrders.toLocaleString() : 0} LION
          </div>

          Your LION on the book being sold
          <Tooltip>
            This is a tooltip explaining things.
          </Tooltip>
        </div>

        <div className="Hodl-UnlockContainer-Stats">
          <div className="Hodl-UnlockContainer-StatsColumn">
            <div className="Hodl-UnlockContainer-StatsAmount">
              {renderPercentCommited()}
            </div>

            % of Portfolio committed
            <Tooltip>
              This is a tooltip explaining things.
            </Tooltip>
          </div>

          <div className="Hodl-UnlockContainer-StatsColumn">
            <div className="Hodl-UnlockContainer-StatsAmount">
              {renderPercentSold()}
            </div>

            LION sold in the last 12M
            <Tooltip>
              This is a tooltip explaining things.
            </Tooltip>
          </div>
        </div>

        <Button
          disabled={isNil(userTotals.lionInOpenOrders) || userTotals.lionInOpenOrders === 0}
          isLoading={isDeletingAllOrders}
          onClick={handleCancelAllOrders}
        >
          Cancel All LION Sell Orders
        </Button>
      </header>
    </div>
  );
};

export default HodlUnlockRewards;
