import './rewardsHistory.scss';

import * as React from 'react';
import { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import * as moment from "moment";
import { map, sortBy } from "lodash";

import { isArrayNullOrEmpty } from '../../../../../utils/utils';

import useDocTitle from "../../../../../hooks/useDocTitle";
import { useTypedSelector } from "../../../../../ducks";

import Datepicker from '../../../../shared/Datepicker';
import Icon from '../../../../shared/Icons';
import Pagination from "../../../../shared/Pagination";
import Select, { SelectOption } from "../../../../shared/Select";

const PAGE_SIZE = 8;

const RewardsHistory: React.FC = () => {
  useDocTitle('Referral Rewards History');

  const [filterForActivity, setFilterForActivity] = useState<string>('');
  const [filterStartDate, setFilterStartDate] = useState<string | undefined>();
  const [filterEndDate, setFilterEndDate] = useState<string | undefined>();
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { referrals, referralRewardActions } = useTypedSelector((state) => state.rewards);

  const { filterForActivityOptions, formattedHistoryItems } = useMemo(
    () => {
      if(isArrayNullOrEmpty(referrals) || isArrayNullOrEmpty(referralRewardActions)) {
        return {
          filterForActivityOptions: [{
            label: 'All Activities',
            value: '',
          }],
          formattedHistoryItems: [],
        };
      }

      let historyItems = [];
      referrals.forEach((r) => {
        r.referralRewards.forEach((rr) => {
          if(rr.completedDate) {
            const program = referralRewardActions.find((a) => a.id === rr.programId);
            if(program) {
              historyItems.push({
                ...rr,
                program,
              });
            }
          }
        });
      });

      let filterForActivityOptions: Array<SelectOption> = [];

      historyItems.forEach((hi) => {
        filterForActivityOptions.push({
          label: hi.program.description,
          value: hi.program.code,
        });
      });

      filterForActivityOptions = [
        {
          label: 'All Activities',
          value: '',
        },
        ...sortBy(filterForActivityOptions, 'label'),
      ];

      return {
        filterForActivityOptions,
        formattedHistoryItems: sortBy(historyItems, 'completedDate').reverse(),
      };
    }, [referralRewardActions, referrals]
  );

  const historyToDisplay = useMemo(
    () => {
      if(isArrayNullOrEmpty(formattedHistoryItems)) {
        return [];
      }

      let historyItems = formattedHistoryItems;

      if(filterForActivity) {
       historyItems = historyItems.filter((hi) => hi.program.code === filterForActivity);
      }

      if(filterStartDate && filterEndDate) {
        historyItems = historyItems.filter((hi) => {
          return moment(hi.startDate).isBetween(moment(filterStartDate), moment(filterEndDate));
        });
      }

      return historyItems;
    }, [filterForActivity, filterStartDate, filterEndDate, formattedHistoryItems]
  );

  const pageOfData = useMemo(
    () => {
      if(isArrayNullOrEmpty(historyToDisplay)) {
        return [];
      }

      let list = historyToDisplay.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);

      if(list.length === 0 && pageNumber > 0) {
        setPageNumber(pageNumber - 1);
      }

      return list;
    }, [historyToDisplay, pageNumber]
  );

  const reportCsvData = useMemo(
    () => {
      return map(formattedHistoryItems, (row) => {
        return {
          Activity: row.program.description,
          Reward: `${row.lionQuantity.toLocaleString()} LION`,
          Date: moment(row.completedDate).format('M/DD/YY h:mm A'),
        };
      });
    }, [formattedHistoryItems]
  );

  return (
    <div className="Referrals-Content Referrals-Content--rewardsHistory">
      <div className="RewardsHistory-Header">
        <h2 className="rewards-title">
          Rewards History
        </h2>

        <p>
          List for all your referral & reward activity.
        </p>

        <div className="RewardsHistory-Filters">
          {/* @ts-ignore */}
          <Datepicker
            endDate={filterEndDate}
            maxDate={new Date()}
            placeholderText="Filter by Start Date"
            startDate={filterStartDate}
            selectsRange
            onChange={(dates) => {
              setFilterStartDate(dates[0]);
              setFilterEndDate(dates[1]);
            }}
            isClearable
          />

          {!isArrayNullOrEmpty(filterForActivityOptions) && (
            <Select
              onChange={(e) => setFilterForActivity(e.target.value)}
            >
              {filterForActivityOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          )}

          <CSVLink
            data={reportCsvData}
            className="HodlHistory-DownloadReport"
            filename={`RewardsHistory_${format(
              new Date(),
              'MM-dd-yy'
            )}.csv`}
          >
            <Icon name="arrowright"/> Download Report
          </CSVLink>
        </div>
      </div>

      <header className="RewardsHistory-ListHeader">
        <div className="RewardsHistory-ListHeaderField HistoryItem-Activity">
          Activity
        </div>

        <div className="RewardsHistory-ListHeaderField HistoryItem-Reward">
          Reward
        </div>

        <div className="RewardsHistory-ListHeaderField HistoryItem-Date">
          Date Joined
        </div>
      </header>

      <ul className="RewardsHistory-List">
        {pageOfData.map((item) => (
          <li className="HistoryItem">
            <div className="HistoryItem-Field HistoryItem-Activity">
              <div className="HistoryItem-Value">
                {item.program.description}
              </div>

              <div className="HistoryItem-Label">
                Activity
              </div>
            </div>

            <div className="HistoryItem-Field HistoryItem-Date">
              <div className="HistoryItem-Value">
                {moment(item.completedDate).format('M/D/YY hh:mm A')}
              </div>

              <div className="HistoryItem-Label">
                Date
              </div>
            </div>

            <div className="HistoryItem-Field HistoryItem-Reward">
              <div className="HistoryItem-Value">
                {item.lionQuantity.toLocaleString()} LION
              </div>

              <div className="HistoryItem-Label">
                Reward
              </div>
            </div>
          </li>
        ))}
      </ul>

      {formattedHistoryItems.length > PAGE_SIZE && (
        <Pagination
          forcePage={pageNumber}
          onPageChange={({selected}) => setPageNumber(selected)}
          pageCount={formattedHistoryItems.length / PAGE_SIZE}
        />
      )}
    </div>
  );
};

export default RewardsHistory;
