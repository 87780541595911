import './assetwalletitem.scss';

import * as React from 'react';
import { eq, find, isNil, isUndefined, upperCase } from 'lodash';

import { formatAsDollarAmount } from '../../utils/utils';

import useAssetsDuck from '../../hooks/useAssetsDuck';
import useProfileDuck from "../../hooks/useProfileDuck";
import useWalletsDuck from '../../hooks/useWalletsDuck';

import ComponentLoader from '../ComponentLoader';
import Currency from '../shared/Currency';
import Logo from "../shared/Logo";

type Props = {
  assetId: string
  buyAssetIsLion?: boolean
  className?: string
  showWalletBalance?: boolean
}

const AssetWalletItem: React.FC<Props> = ({
  assetId,
  buyAssetIsLion,
  className,
  showWalletBalance = false,
}) => {
  const { assets } = useAssetsDuck()
  const { balances, usdPrices } = useWalletsDuck()
  const { profile: { homeQuoteAsset } } = useProfileDuck();

  if (eq(assets.length, 0)) {
    return <ComponentLoader isLoading={true} />
  }

  const asset = find(assets, (a) => eq(a.id, assetId));

  if (isUndefined(asset)) {
    return <React.Fragment />
  }

  const { asset_name } = asset;

  const generateWalletValueToDisplay = () => {
    if(showWalletBalance) {
      let balance = balances[assetId] || 0;

      if(assetId === 'usd') {
        return formatAsDollarAmount(balance);
      }

      if(isNil(balances[assetId])) {
        return `0 ${assetId.toUpperCase()}`;
      }

      return `${balance.toLocaleString(undefined, { maximumFractionDigits: asset.scale, minimumFractionDigits: 0 })} ${assetId.toUpperCase()}`;
    }

    // Part of CLV3-889, I switched to using balances instead of walletValueInUSD due to
    // some validation failing when the pay asset was being used in open orders.
    if(isNil(usdPrices) || isNil(usdPrices[assetId])) {
      return null;
    }

    if(isUndefined(balances[assetId]) || balances[assetId] === 0) {
      return '$0';
    }

    if(!buyAssetIsLion) {
      return formatAsDollarAmount(balances[assetId] * usdPrices[assetId]);
    }

    // If LION is being purchased, do some hackery to display total of home quote currency + USDC
    // CLV3-889
    let value;

    if(homeQuoteAsset === 'usdc') {
      value = balances.usdc;
    } else if(homeQuoteAsset === 'usd') {
      value = (balances.usdc || 0) + (balances.usd || 0);
    } else if(homeQuoteAsset === 'usdt') {
      value = (balances.usdc || 0) + (balances.usdt || 0);
    }

    return formatAsDollarAmount(value);
  };

  const walletValueToDisplay = generateWalletValueToDisplay();

  return (
    <div
      className={`AssetWalletItem ${!isUndefined(className) ? className : ''}`}
    >
      <div className="AssetWalletItem-iconContainer">
        {eq(assetId, 'lion') ? (
          <Logo />
        ) : (
          <Currency type={assetId} />
        )}
      </div>

      <div>
        <p className="AssetWalletItem-assetName">
          {asset_name} ({upperCase(assetId)})
        </p>

        {walletValueToDisplay != null && (
          <p className="AssetWalletItem-walletAmount">
            {generateWalletValueToDisplay()}{' '}in wallet
          </p>
        )}
      </div>
    </div>
  )
}

export default AssetWalletItem
