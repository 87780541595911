import './searchableselect.scss';

import * as React from 'react';
import Select from 'react-select';

const SearchableSelect = ({ ...rest }) => {
  return (
    <Select
      className="Select SearchableSelect"
      classNamePrefix="Select"
      isClearable
      isSearchable
      {...rest}
    />
  )
}

export default SearchableSelect
