import React from 'react'

const TosIndividual = () => {
  return (
    <React.Fragment>
      <h3 className="h4">SECTION 1. GENERAL ACCEPTANCE OF USER AGREEMENT</h3>
      <p>
        Use of Coin Lion, LLC’s (“CoinLion”) services, products, tools, and
        website is an affirmative acceptance to all terms and conditions of this
        User Agreement. By establishing a CoinLion User account you attest that
        you are at least eighteen (18) years of age, that you understand the
        terms of this agreement, that you agree to be contractually bound by
        this user agreement, including any subsequent changes to this User
        Agreement. If this, any term, or condition of this contract is
        unacceptable please do not use or access CoinLion; use of the CoinLion
        platform or CoinLion tools is considered acceptance of the User
        agreement.
      </p>
      <p>
        Use of words “CoinLion,” “we,” “us,” or “our” in this User Agreement
        refers to CoinLion, LLC; use of words “user,” “users,” “you,” “your,”
        “consumer,” and “consumers” in this User Agreement refers to the
        individual or entity establishing a CoinLion User Account who must agree
        to this User Agreement.
      </p>
      <p>
        As a User, you must first place funds into your USD fiat wallet or your
        cryptocurrency wallet before you are capable of trading on our platform.
        A user may not have trade orders, singular or in aggregate, that exceed
        the balance of funds in the user’s wallets. When an order is placed on
        the CoinLion platform the CoinLion matching engine will search for a
        fulfilling order. If such an order is located the CoinLion matching
        engine will pair the two orders to complete a trade. If a fulfilling
        order cannot be located the initial order will be placed on the order
        book where it will sit until the matching engine can locate and pair a
        satisfying order.
      </p>
      <p>
        User’s affirmative acceptance (checking the “I Agree and Accept” box of
        this user agreement) acknowledges and waives any requirement for similar
        affirmative acceptance of future User Agreement revisions or updates.
        User understands, agrees, and attests that any time after the acceptance
        of this User Agreement, each log in or each use of User’s account on
        CoinLion platform is a renewal of acceptance of the current CoinLion
        user agreement. If User no longer accepts, agrees, or attests to the
        acceptance of the User Agreement, it must immediately withdraw all of
        its funds on the CoinLion platform and cancel its account with CoinLion
        customer support.
      </p>
      <p>
        To help governments fight the funding of terrorism and money laundering
        activities, CoinLion obtains, verifies, and records information that
        identifies each person who opens an account. What this means for you:
        When you open an account, we will ask for identifying information,
        including, but not limited to, your name, address, date of birth, social
        security number (or equivalent) and other information that will allow us
        to identify you. We may also ask to see your government or state
        authorized identification or other identifying documents.
      </p>
      <p>
        Users agree that in addition to accepting the User Agreement, users must
        meet eligibility requirements and use the CoinLion platform acceptably.
        Please see Eligibility Section and Acceptable Use Section.
      </p>
      <p>1.1 – Risk Disclosure</p>
      <p>
        As a User, you understand that CoinLion provides you the tools to trade
        and manage cryptocurrency, you agree that education and research are
        paramount to efficiently trade and manage cryptocurrency, and you agree
        that you, as the user, are completely responsible for not only your own
        education and research, but also for each and every deposit, withdraw,
        and cryptocurrency trade that you make.
      </p>
      <p>
        Your Account is not a checking or savings account, and it is not covered
        by insurance against losses. We will pledge, repledge, hypothecate,
        rehypothecate, sell, or otherwise transfer or use funds and
        cryptocurrency assets to counterparties, and such cryptocurrency assets
        will be exposed to various risks as a result of such transactions. In
        certain jurisdictions, cryptocurrency is not legal tender, and is not
        backed by the government. Accounts and value balances are not subject to
        Federal Deposit Insurance Corporation or Securities Investor Protection
        Corporation protections. Legislative and regulatory changes or actions
        at the state, federal, or international level may adversely affect the
        use, transfer, exchange, and value of cryptocurrency.
      </p>
      <p>
        Transactions in cryptocurrency may be irreversible, and, accordingly,
        losses due to fraudulent or accidental transactions may not be
        recoverable. Some cryptocurrency transactions shall be deemed to be made
        when recorded on a public ledger, which is not necessarily the date or
        time that the customer initiates the transaction. The value of
        cryptocurrency may be derived from the continued willingness of market
        participants to exchange government-issued currency for cryptocurrency,
        which may result in the potential for permanent and total loss of value
        of a particular cryptocurrency should the market for that cryptocurrency
        disappear.
      </p>
      <p>
        There is no assurance that a person who accepts a cryptocurrency as
        payment today will continue to do so in the future. The volatility and
        unpredictability of the price of cryptocurrency relative to
        government-issued currency may result in significant loss over a short
        period of time. The nature of cryptocurrency may lead to an increased
        risk of fraud or cyber-attack, including rollback attacks or blockchain
        reorganizations. The nature of cryptocurrency means that any
        technological difficulties experienced by CoinLion may prevent the
        access or use of a customer’s cryptocurrency. Any bond or trust account
        maintained by CoinLion for the benefit of its customers may not be
        sufficient to cover all losses incurred by customers. In light of these
        risks, you should carefully consider whether holding cryptocurrency in a
        CoinLion account is suitable.
      </p>
      <h3 className="h4">SECTION 2. DEFINITIONS</h3>
      <p>
        The following definitions used throughout the Terms have the following
        meaning:
      </p>
      <p>
        Accompanying Documents – other CoinLion documents detailing CoinLion
        policy, direction, or liability, including, but not limited to Privacy
        Policy and Disclaimers. In the case of a discrepancy between
        Accompanying Documents and the User Agreement, the User Agreement
        applies.
      </p>
      <p>
        Account – a User’s account on{' '}
        <a
          href="https://www.coinlion.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.coinlion.com/
        </a>
        , which is created and used to operate inside the CoinLion Platform. A
        user gains access to an account after CoinLion verification of the
        user’s identity. Only authorized users have a right to operate inside
        the CoinLion platform. Individuals or entities can establish an account.
      </p>
      <p>
        Affiliates – any person or entities that have a business relationship to
        CoinLion, including, but not limited to partners, employees, agents, and
        contractors of CoinLion. Users are not affiliates.
      </p>
      <p>
        Agreement – this User agreement and any other rules, policies, or
        procedures that may be issued by CoinLion and that may be issued from
        time to time on the CoinLion website (includes all accompanying
        documents).
      </p>
      <p>
        Business Account – is an account with the same access and abilities as
        an individual’s account, but it is established and maintained by and on
        behalf of an entity and not a person. Business accounts will require
        additional verification steps and requirements.
      </p>
      <p>
        CoinLion Platform – a social, internet based, cryptocurrency trading
        website, designated for the global trade of cryptocurrency, creation and
        exposure of cryptocurrency portfolios and automatic trading strategies,
        and the sharing of cryptocurrency research, portfolios, and trading
        strategies among users.
      </p>
      <p>
        Cryptocurrency – digital or virtual currency, e.g. Bitcoin (BTC) or
        Ethereum (ETH).
      </p>
      <p>
        ERC20 – is a technical standard used for smart contracts on the Ethereum
        blockchain for implementing tokens.
      </p>
      <p>
        Jurisdiction – a geographical determination of what legal and judicial
        practices apply.
      </p>
      <p>
        CoinLion Token – cryptographic token native to the CoinLion Platform,
        minted by CoinLion as a key tool to properly utilize the CoinLion
        Platform, e.g. to receive trading fee reductions. The CoinLion Token is
        a token for use on the CoinLion Platform, that should be accumulated and
        deployed as such. The CoinLion Token is not a security and shall not be
        considered as such. Users shall not accumulate and use CoinLion Token
        for any purpose beyond the uses it provides on the CoinLion Platform.
      </p>
      <p>
        Politically Exposed Person (PEP) – An individual who is or has been
        entrusted with a prominent public function; this term also includes that
        person’s family members and close associates.
      </p>
      <p>
        Matching Engine – The primary purpose of the CoinLion Platform is its
        matching engine. It matches traders of cryptocurrency for the Users.
      </p>
      <p>
        Trade Order – is a trade placed / requested by a User.. It can be either
        a buy or a sell order.
      </p>
      <h3 className="h4">SECTION 3. ACCEPTABLE USE</h3>
      <p>
        To use CoinLion services and tools you must create a CoinLion User
        Account. Account creation requires users to provide CoinLion with
        verifiable, identity information. You agree to:
      </p>
      <ul className="u-ul">
        <li>provide accurate information,</li>
        <li>
          maintain and update the identity information provided to CoinLion upon
          a change in circumstance,
        </li>
        <li>
          create a strong password that is unique for user’s use of CoinLion,
        </li>
        <li>
          secure your password integrity to protect your CoinLion account,
        </li>
        <li>
          notify CoinLion promptly if you discover or suspect any breach to your
          CoinLion account,
        </li>
        <li>
          accept all risk and take responsibility for all activity on your
          CoinLion Account to the extent permitted by laws.
        </li>
      </ul>
      <h3 className="h4">SECTION 4. ELIGIBILITY</h3>
      <p>
        4.1 Users must be eligible to use CoinLion services. By agreeing to this
        agreement, you attest that you are eligible to use CoinLion services.
        You further acknowledge and attest that if you are not eligible or
        subsequently determine you are not eligible it is your responsibility to
        cease CoinLion services and to close your CoinLion account.
      </p>
      <p>
        4.2 CoinLion may not provide services in or to all jurisdictions and all
        markets. Additionally, CoinLion may restrict, prohibit, or terminate
        services from certain U.S. states or foreign jurisdictions.
      </p>
      <p>
        4.3 To be eligible to trade on the CoinLion platform, you attest you
        are:
      </p>
      <ul className="u-ul">
        <li>18 years of age,</li>
        <li>
          of legal age and competency to enter into a legal contract within your
          jurisdiction,
        </li>
        <li>
          not domiciled in, located in, under the control of, or a national /
          resident / citizen of (i) any restricted jurisdiction or (ii) any
          country which the United States has trade and services sanctions,
        </li>
        <li>
          not an individual or a member of an entity sanctioned by the U.S.
          government,
        </li>
        <li>
          not a considered “Specially Designated Nationals” or “Blocked Person”
          by the U.S.
        </li>
        <li>Department of the Treasury,</li>
        <li>
          are not on the U.S. Department of Commerce’s Denied Persons List,
        </li>
        <li>
          have / do / will not use CoinLion services if any applicable laws in
          your local / state / territory / national jurisdiction prohibit you
          from doing so in accordance with these Terms.
        </li>
      </ul>
      <p>
        4.4 If you are an individual registering on the behalf of an eligible
        entity, you acknowledge, attest, and agree that you are duly authorized
        by the legal entity to act on its behalf and that you personally are or
        would be eligible to use CoinLion services.
      </p>
      <p>
        4.4.1 As a user of a Business Account you acknowledge, attest, agree,
        and represent that you are validly and duly organized under the
        applicable laws of the jurisdiction of your organization, that the
        jurisdiction of your organization do not preclude you from the use of
        CoinLion services, and that all individuals within your organization
        meet the eligibility requirements to use CoinLion services.
      </p>
      <h3 className="h4">SECTION 5. PROHIBITTED USE</h3>
      <p>
        5.1 Users shall not use CoinLion accounts or services for any prohibited
        use, including, but not limited to, unlawful activity, abusive activity,
        dissemination of misstatements, market manipulation, violation or
        avoidance of export controls, investment or credit services, the sale of
        contraband, multi-level marketing, gambling, fraud, acts detrimental to
        CoinLion, wash-trades, pre-arranged trades, simultaneous buy sell
        orders, front-running, order spoofing, or trade coordination. If you are
        uncertain if a specific action would be deemed a violation of this
        contract provision please seek legal advice or contact us at CoinLion,
        LLC Support. By opening an account you attest that you will not use
        CoinLion services against the interest of CoinLion, LLC or in any manner
        listed above or below.
      </p>
      <p>
        5.1.1 – Unlawful Activity – any activity that violates, would violate,
        or assists in the violation of any law, statute, ordinance, regulation,
        or sanction administered by jurisdictions where CoinLion directs its
        operations.
      </p>
      <p>
        5.1.2 – Abusive Activity – any activity that attempts to gain
        unauthorized access to CoinLion sites, services, products, users,
        employees, or information and includes activities that interfere with
        another individual’s or entity’s access or use of CoinLion services.
        Unauthorized access abuse includes, but is not limited to, attempts to
        overload, interfere with, intercept, or expropriates any CoinLion
        system, data, or information. Abusive activity that interferes with
        another individual’s or entity’s accessor use includes, but is not
        limited to, realized or attempts to defame, extort, harass, stalk,
        threaten, violate, or infringe their legal rights.
      </p>
      <p>
        5.1.3 – Dissemination of Misinformation – any misstatement of a material
        fact to CoinLion, its employee, officer, manager, director, or owner.
      </p>
      <p>
        5.1.4 – Market Manipulation – disruptive trading that is an artificial
        inflation or deflation of the price of cryptocurrency or otherwise
        influence the behavior of the market for personal gain. It is a
        violation of the CoinLion User Agreement to mislead or manipulate the
        CoinLion market.
      </p>
      <p>
        5.1.5 – Investment or Credit Services – Users are not authorized to use
        CoinLion services for purposes including, but not limited to, mortgage
        consulting and / or reduction services, credit counseling or repair,
        real estate opportunities, securities brokers and investment schemes.
        CoinLion does not offer or authorize any trades or sales of securities.
        Acceptance of the user agreement is an attestation by the User that they
        acknowledge that CoinLion does not offer securities.
      </p>
      <p>
        5.1.6 – Sale of Contraband – Users acknowledge, attest, and agree that
        they will not use funds traded to purchase, facilitate the use of, or
        support any item or services that is deemed illegal contraband within
        your jurisdiction.
      </p>
      <p>
        5.1.7 – Multi-Level Marketing – users are prohibited for using CoinLion
        services or funds derived therefrom for any network marketing, referral
        marketing programs, and / or any pyramid scheme.
      </p>
      <p>
        5.1.8 – Gambling – users are prohibited from using CoinLion services or
        funds derived therefrom to use for, finance, or support of gambling.
      </p>
      <p>
        5.1.9 – Fraud – users are prohibited from fraudulent activity including,
        but not limited to, any realized or attempt of any fraudulent act or
        carry out a scheme to defraud, deceive, trick CoinLion, LLC, CoinLion
        affiliates, or CoinLion users.
      </p>
      <p>
        5.1.10 – Acts Detrimental to CoinLion – are any activity that is not an
        intended or acceptable use of CoinLion services that does or could
        negatively affect CoinLion’s best interest.
      </p>
      <p>
        5.1.11 – Pre-Arranged Trades – Users shall not prearrange their
        transactions for the purpose of passing money between accounts. All
        trades through CoinLion services must be in good faith bona fide
        transactions.
      </p>
      <p>
        5.1.12 – Simultaneous Buy Sell Orders – Users are prohibited from
        affecting multiple trades that could potentially match against / with
        each other.
      </p>
      <p>
        5.1.13 – Front-Running – Users are prohibited from any activity when a
        user enters into a trade with foreknowledge of an upcoming subsequent
        transaction which will influence the price or value of the initial trade
        resulting in an economic gain for the trader.
      </p>
      <p>
        5.1.14 – Order Spoofing – Users shall initiate all trades or trade
        offers with the purpose of executing the trade; users shall not offer or
        bid on any trade with the intent to cancel the offer or bid prior to the
        trade’s execution.
      </p>
      <p>
        5.1.15 – Trade Coordination – Users shall not coordinate with other
        users for trade prices or price schemes.
      </p>
      <p>
        5.1.17 – Use in Restricted Jurisdictions – Users shall not use CoinLion
        services, tools, or products in jurisdictions that do not permit such
        use. If you are a citizen of, resident of, or located in any state,
        country, territory, or other jurisdiction that use of CoinLion services
        would be illegal, otherwise violate any applicable law, or that is
        embargoed by the United States, you represent, attest, and agree that
        you shall not use Coin Lion. services or products. You further
        represent, attest, and agree that if you are a citizen of, resident of,
        or located in any state, country, territory, or other jurisdiction that
        use of CoinLion services would be illegal, otherwise violate any
        applicable law, or that is embargoed by the United States your use is
        without CoinLion, LLC’s consent and is violation of this user agreement.
      </p>
      <h3 className="h4">SECTION 6. SUSPENSION AND TERMINATION OF USE</h3>
      <p>
        6.1 – CoinLion may suspend, deactivate, or terminate users access to any
        or all of CoinLion services if (i) CoinLion suspects a user is not
        abiding by or is violating terms of this User Agreement; (ii) CoinLion
        is obligated by valid subpoena, court order, or binding order of
        government authority, (iii) your action or involvement in litigation
        creates additional risk for CoinLion, or (iv) if CoinLion’s service
        providers / affiliates / partners are not able to support your use.
      </p>
      <p>
        6.2 – Notice of suspension, deactivation, or termination shall be
        provided to users in a timely manner unless CoinLion is barred from
        providing notice by government or judicial authority.
      </p>
      <p>
        6.2.1 – When not prohibited by government or judicial authority, Users
        will be permitted to withdraw amounts in their respective CoinLion
        wallets within 30 days. If Users are not permitted to withdraw amounts
        because of a government or judicial prohibition the 30 days will not
        begin to run until the prohibitive hold is withdrawn and User is
        notified of that the prohibition is withdrawn.
      </p>
      <h3 className="h4">SECTION 7. CREATING A COINLION USER ACCOUNT</h3>
      <p>
        In order to establish a CoinLion User Account, Users must acknowledge,
        agree, and attest to all terms of use of this User Agreement and users
        agree to provide all user information, subject to the Privacy Policy, to
        establish and verify users individually by clicking the “I agree and
        accept to the User Agreement and privacy” box upon login.
      </p>
      <h3 className="h4">SECTION 8. ACCOUNT FUNDS</h3>
      <p>
        8.1 – Cryptocurrency wallet/balance – CoinLion services allow you to
        track and store your cryptocurrencies within the CoinLion system. Only
        supported cryptocurrencies will be held by CoinLion. To be deemed
        supported, CoinLion will list the cryptocurrency on the CoinLion
        website; such a listing indicates that CoinLion has vetted the
        cryptocurrency to ensure that the cryptocurrency does not pose undue
        technical, security, or regulatory risks. CoinLion will not list any
        cryptocurrency determined to be a security by United States government.
        Users acknowledge, attest, and agree that any trade you make via
        CoinLion services is not a trade or sale of a security and furthermore,
        you attest and agree that you will not send or deliver a security to
        CoinLion, LLC or through any of the CoinLion services. CoinLion stores
        all private keys in our control in a combination of online and offline
        storage. CoinLion has no control over or liability for third party
        payment. Users may elect to use the cryptocurrency vault provided by
        CoinLion to store supported cryptocurrency.
      </p>
      <p>
        8.2 – USD Wallet – Your USD wallet, funded via wire or ACH from your
        bank account, allows you to hold and transfer USD; CoinLion maintains a
        separate ledger for each user’s USD. CoinLion maintains your USD funds
        in a pooled custodial account at a U.S. FDIC-insured bank. Any interest
        earned on the custodial account is the property of CoinLion. Pooled
        customer funds are at all times maintained separately than CoinLion’s
        corporate funds and CoinLion will never use pooled customer funds for
        its own behalf.
      </p>
      <p>
        8.3 – Taxes – CoinLion provides each User with its own transaction
        history, available in each User’s accounts. The transaction history is
        provided to assist in what is your sole responsibility to determine
        whether, and to what extent, taxes apply to any transactions you made
        through CoinLion services, and to withhold, collect, report, and remit
        the correct taxes to your appropriate tax authorities. However,
        CoinLion’s transaction history does not track your previous cost basis
        in any asset; it is the User’s sole responsibility to know the cost
        basis of each asset deposited into the CoinLion platform. Please consult
        with your tax advisor when filing your tax return.
      </p>
      <p>
        8.4 – Non-Solicitation and No Advice – Except as otherwise expressly
        noted, the Content and the Online Platform do not constitute an offer to
        buy or sell or a solicitation of an offer to buy or sell investments,
        loans, securities, partnership interests, commodities or any other
        financial instruments; the Content and the Online Platform also do not
        constitute, and may not be used for or in connection with, an offer or
        solicitation by anyone in any state or jurisdiction in which such an
        offer or solicitation is not authorized or permitted, or to any person
        to whom it is unlawful to make such offer or solicitation.
      </p>
      <p>
        WITHOUT LIMITING ANYTHING IN THE TERMS, COINLION MAKES NO WARRANTIES AND
        BEARS NO LIABILITY WITH RESPECT TO ANY FUND, ANY INVESTMENTS,
        SECURITIES, PARTNERSHIP INTERESTS, LOANS OR THE PERFORMANCE THEREOF.
      </p>
      <p>
        While CoinLion may make certain informational Content available to its
        users, under no circumstances does CoinLion provide legal, tax,
        investment, financial, estate-planning, accounting, or any other advice.
        CoinLion uses automated systems in conjunction with: the receipt and
        handling of orders; the reporting of order acknowledgements,
        cancelations, and executions; the settlement of transactions; tax and
        cost basis reporting; and similar record keeping and reporting services
        (collectively, “Automated Systems”). All CoinLion trades are executed
        using Automated System, based on the parameter of your order
        instructions, and you are solely responsible for determining whether
        investment, investment strategy, or related transactions is appropriate
        for you based on your personal financial objectives. The use of
        Automated Systems entails risks, including but not limited to
        interruption of service, systems of communications failures, delays in
        service, cyberattacks, and errors in the design or functionality of such
        Automated Systems that could cause damage, expense, or liability to the
        user. CoinLion makes no representations or warranty of any kind, express
        or implied, with respect to the selection, design, security,
        functionality, or operation of such Automated Systems. CoinLion
        expressly disclaims any representation that any Automated System will
        operate uninterrupted or be error free.
      </p>
      <p>
        Although CoinLion may provide information relating to trading approaches
        and opportunities to buy or sell assets, you shall not construe any
        features, tools, or other content as legal, tax, investment, financial,
        or other advice. Nothing contained in CoinLion’s Online Platform
        constitutes a solicitation, recommendation, endorsement, or offer by
        CoinLion or a third party service provider to buy or sell any asset or
        other financial instrument.
      </p>
      <p>
        The Content and the views expressed in the Content do not necessarily
        reflect the views of CoinLion as a whole, its directors, officers,
        employees, shareholders or any part or member thereof or of any third
        party.
      </p>
      <p>
        8.5 – Order Review – You acknowledge, attest, and agree that CoinLion
        does not perform a suitability review of any trades, orders, or
        purchases placed by users. You are solely and uniquely responsible for
        any of your actions on the CoinLion website or while using the CoinLion
        website.
      </p>
      <p>
        8.6 – Fees – You acknowledge, attest, and agree to pay all fees
        associated with use of the CoinLion Services. Users acknowledge, agree
        to, and authorize CoinLion to automatically charge and collect fees from
        your account. Users acknowledge, attest, and agree that in the event a
        fee is not automatically collected by CoinLion that you are bound to and
        will provide the fee upon request or discovery of non-collection.
        Additionally, you are aware that your external wallets and services
        providers may also charge a fee; those fees will not be reflected on any
        CoinLion transaction. For fee rates see Fee Schedule Explanation.
      </p>
      <h3 className="h4">SECTION 9. SUPPORTED CRYPTOCURRENCIES</h3>
      <p>
        9.1 – Supported Cryptocurrencies – Users acknowledge, agree, and attest
        that CoinLion will support various cryptocurrencies and cryptocurrency
        trading pairs; conversely, users acknowledge, agree, attest that
        CoinLion will not support certain cryptocurrencies and / or
        cryptocurrency trading pairs. You acknowledge, agree, and attest that
        CoinLion uniquely, solely, and completely has discretion, control, and
        the ability to determine which cryptocurrency or cryptocurrency trading
        pairs will be supported on the CoinLion platform.
      </p>
      <p>
        9.2 – CoinLion Receipt of Unsupported Cryptocurrency – You acknowledge,
        attest, and agree that you will confirm CoinLion supports a
        cryptocurrency prior to sending such cryptocurrency to a CoinLion
        wallet. In the event a user sends an unsupported cryptocurrency to a
        CoinLion wallet, you acknowledge, attest, and agree that CoinLion bears
        no responsibility to credit your user account with the cryptocurrency or
        the cryptocurrency’s value, to hold the cryptocurrency on behalf of the
        user, or to return the cryptocurrency to your external wallet. You
        acknowledge, attest, and agree that you no longer have any possessory
        right to or claim of any unsupported cryptocurrency sent to a CoinLion
        wallet once the cryptocurrency is received by CoinLion.
      </p>
      <p>
        9.3 – Forks – You acknowledge, attest, and agree that you understand
        that the CoinLion has zero control, responsibility, or liability if the
        underlying protocol of a supported cryptocurrency, with the exception of
        CoinLion Token, has a change in its operating rule, also known as a
        fork. You acknowledge, attest, and agree that forks may materially
        affect the value, function, name, or circulating amount of
        cryptocurrency. Users acknowledge and understand that in the event of a
        fork CoinLion may suspend and / or freeze all activity related to a
        forked cryptocurrency on the CoinLion platform.
      </p>
      <p>
        9.4 – CoinLion Token – Users acknowledge, attest and agree they
        understand that CoinLion Token is CoinLion’s native utility token and
        that the CoinLion Token’s purpose is to grant you full access and
        functionality of the CoinLion platform. You further acknowledge, attest,
        and agree that without the sufficient volume of CoinLion Token you may
        not have access to the complete array of CoinLion services or CoinLion
        tools.
      </p>
      <h3 className="h4">SECTION 10. PRIVACY POLICY</h3>
      <p>
        Please see our{' '}
        <a href="/privacy" target="_blank">
          Privacy Policy
        </a>{' '}
        for information about how we collect, use, and share any user
        information.
      </p>
      <p>
        10.1 – Record Keeping – You acknowledge, attest, and agree that in the
        interest of mutual protection CoinLion may electronically record and
        store any communication, including, but not limited to, telephone,
        email, video conference, or direct messages. You also acknowledge,
        attest, and agree that CoinLion may maintain and retain records of all
        information, activity, and communications relating to your User Account,
        use of CoinLion services, or accessing of the CoinLion website.
      </p>
      <h3 className="h4">SECTION 11. ACCOUNT CLOSURE</h3>
      <p>
        In the event you, as a CoinLion user, wish to end your obligations to
        CoinLion, you must close and delete your account. Unless specifically
        notified, CoinLion will retain your user information and even if
        specifically notified CoinLion may retain such information as long as
        deemed required by regulation.
      </p>
      <h3 className="h4">
        SECTION 12. COMMUNICATION AND E-SIGNATURE DISCLOSURE AGREEMENT
      </h3>
      <p>
        This agreement informs all CoinLion users how CoinLion delivers all
        communication and how users electronically sign agreements with
        CoinLion.
      </p>
      <p>
        12.1 – CoinLion, LLC Communication Delivery – You accept and consent to
        receive all communications from CoinLion in an electronic format. You
        understand that you have the right to withdraw your acceptance to
        receive all communication electronically, but you acknowledge, accept,
        and consent should you withdraw consent to receive electronically you
        must affirmatively withdraw consent by contact with COINLION SUPPORT;
        additionally you understand, accept, and consent that should you
        withdraw consent to receive communication electronically or refuse to
        accept and consent to receive communication electronically CoinLion
        reserves the right to immediately close your Account and / or charge you
        additional fees for paper copies.
      </p>
      <p>
        12.1.1 – Communication includes, but is not limited to, any messages,
        notifications, correspondence, agreements, documents, and disclosures
        between CoinLion and Users.
      </p>
      <p>
        12.2 – Multiple Communication delivery methods will be used by CoinLion,
        LLC; CoinLion’s delivery methods will include, but is not limited to,
        postings on the CoinLion website, emails directly to the user’s primary
        email on file with CoinLion, communication to users via an instant chat
        medium, and / or through text messages or mobile push notifications.
      </p>
      <p>
        12.3 – You agree to utilize a direct line of communication delivery
        method for all communication(s) to CoinLion. User acknowledges that
        CoinLion will not be determined to have received communication in group
        or public message forums / settings.
      </p>
      <p>
        12.4 – Hardware and Software Requirements – You acknowledge, attest and
        agree that you will need the following computer hardware and software to
        access and retain electronic Communications from CoinLion, LLC:
      </p>
      <ul className="u-ul">
        <li>An internet connected device;</li>
        <li>
          A current web browser that includes 256-bit encryption with cookies
          enabled
          <ul className="u-ul">
            <li>
              e.g. Microsoft Edge 17 and above, Mozilla Firefox version 63 and
              above, Google Chrome 70 and above, or Apple Safari 11 and above;
            </li>
          </ul>
        </li>
        <li>
          A valid email address;
          <ul className="u-ul">
            <li>
              Needs to be listed as your primary email address on file with
              CoinLion.
            </li>
          </ul>
        </li>
        <li>
          Available storage space to save past Communications or an available
          printer to print Communications as you receive them.
          <ul className="u-ul">
            <li>
              You acknowledge, accept, and agree to keep, maintain, and update
              your CoinLion primary email address and contact information. You
              acknowledge, attest, and agree that CoinLion is deemed to have
              provided communication to you if the communication was sent but
              you did not receive because your primary email address on file was
              incorrect, out of date, blocked by your service provider, or user
              was otherwise unable to receive communications.
            </li>
            <li>
              Users may update user contact information, including email, by
              logging into User’s account and visiting settings.
            </li>
          </ul>
        </li>
      </ul>
      <h3 className="h4">SECTION 13. LICENSING AND REGULATION</h3>
      <p>
        You acknowledge, attest, and agree that you do not have a right to
        CoinLion’s services and that the service CoinLion provides you is one
        that exists in a complex regulatory environment. You acknowledge,
        attest, and agree that CoinLion will not be held in fault or in the
        wrong if you are from certain jurisdiction that does not permit some or
        all of CoinLion services because of regulatory constraints. You
        acknowledge, attest, and agree that you will not provide false
        information to overcome any such regulatory bar to access of CoinLion
        services and that you bear responsibility to comply with your
        jurisdictional requirements.
      </p>
      <p>
        You acknowledge, attest, and agree that CoinLion’s restrictions could
        include, but are not limited to, the form of limits to amounts of funds
        that can be deposit or traded, to denial of certain categories of
        services based upon a user’s jurisdiction, or denial of certain
        categories of services based upon user’s verification tier. CoinLion
        designed its verification procedure based upon the best industry
        standards to meet the regulatory requirements while simultaneously
        creating a seamless user experience.
      </p>
      <h3 className="h4">SECTION 14. CUSTOMER SUPPORT</h3>
      <p>
        Contact information – If you have any questions, concerns, complaints,
        or feedback please contact CoinLion via our Customer Support webpage.
        When you contact us please provide us your name, address and all other
        information we may need to identify you and may need to understand your
        questions, concern, complaint, or feedback.
      </p>
      <p>
        14.1 – Arbitration; Waiver of Class Action – You acknowledge, attest,
        agree that in the event of a dispute between you and CoinLion, the
        proper first step of resolution is to attempt to resolve through the
        CoinLion Customer Support. If the dispute cannot be resolved through
        CoinLion Customer Support you agree that any dispute arising under this
        agreement shall be finally settled in binding arbitration, on an
        individual basis, in accordance with the American Arbitration
        Association’s rules for arbitration of consumer related disputes and you
        and CoinLion hereby expressly waive trial by jury and the right to
        participate in a class action lawsuit or classwide arbitration.
      </p>
      <p>
        14.2 – Relationship of the Parties – You agree and understand that
        nothing in this User Agreement shall be deemed to constitute, create,
        imply, give effect to, or otherwise recognize a partnership, employment,
        joint venture, or formal business entity of any kind; and the rights and
        obligations of the parties shall be limited to those expressly set forth
        herein. Except for the indemnity and exculpation provisions herein,
        nothing expressed in, mentioned in, or implied from this User Agreement
        is intended or shall be construed to give any person other than the
        parties hereto any legal or equitable right, remedy, or claim under or
        in respect to this User Agreement to enforce any of its terms which
        might otherwise be interpreted to confer such rights to such persons,
        and this User Agreement and all representations, warranties, covenants,
        conditions and provisions hereof are intended to be and are for the
        exclusive benefit of you and us.
      </p>
      <h3 className="h4">SECTION 15. ADDITIONAL PROVISIONS </h3>
      <p>
        15.1 – Indemnification – You agree, to defend, indemnify and hold
        harmless CoinLion, all CoinLion employees, owners, directors, agents,
        managers, and affiliates from any and all claims, demands, legal
        actions, damages, loss, cost or expense, reasonable attorney’s fees,
        arising out of or related to your use of CoinLion services or products,
        related to any feedback to you, your violation of this User Agreement,
        or any violation of any rights to any other person or entity. If you are
        required to indemnify us, you acknowledge, attest, and agree that
        CoinLion has the sole right and discretion to control any action or
        proceed and determine whether we wish to or how to settle it. You shall
        not have any claim of any nature whatsoever against CoinLion for any
        failure by us to carry out any of our obligations under this agreement
        as a result of causes beyond our control, including but not limited to
        any strike, lockout, shortage of labor or materials, delays in
        transport, hacker attacks on the Website, accidents of any kind, any
        default or delay by any subcontractor or supplier of ours, riot,
        political or civil disturbances, the elements, an act of government
        including regulatory action imposed, any delay in securing any permit,
        consent, or approval required by Us, or any other authority or any other
        cause whatsoever beyond our absolute and direct control.
      </p>
      <p>
        15.2 – Limited Liability; No Warranty – You acknowledge, attest, and
        agree that CoinLion has not made nor is expected to make any
        representations or warranties, whether express or implied, and assumes
        no liability or responsibility for the proper performance of any
        services, platforms, or the information, images, or audio contained or
        related to the Website. You use all of the mentioned at your own risk.
      </p>
      <p>
        15.3 – For the avoidance of doubt, neither CoinLion nor any CoinLion
        affiliate are giving investment advice, tax advice, legal advice, or
        other professional advice by allowing you to use CoinLion and the
        services CoinLion or any CoinLion affiliate provides, including, but not
        limited to, the ability to buy, sell, or store cryptocurrency or fiat
        currency. In addition, neither CoinLion or any CoinLion affiliate
        recommend, or endorse that you buy or sell cryptocurrency.
      </p>
      <p>
        15.4 – THE SERVICES COINLION AND COINLION AFFILIATES PROVIDE ARE
        PROVIDED TO YOU ON A STRICTLY “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE”
        BASIS. NEITHER COINLION NOR ANY COINLION AFFILIATES REPRESENT OR WARRANT
        TO THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT,
        MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF COINLION OR THE
        INFORMATION CONTAINED THEREIN OR SERVICES CONTAINED THEREON.
      </p>
      <p>
        15.5 – Acceptance of Risk – You acknowledge, attest, and agree that you
        shall access and use CoinLion services, the CoinLion platform, and
        execute any trade at your own risk. You understand trading
        cryptocurrency pairs and trading cryptocurrency for fiat pairs can have
        substantial risk. You acknowledge and understand that to mitigate such
        risk the burden is on you to do your own research and carefully consider
        whether each trade is suitable for you in light of your personal
        circumstances and financial resources. You acknowledge, attest, and
        agree that you are aware that under certain market conditions, you may
        find it difficult or even impossible to liquidate a position, that
        stop-limit or stop-loss orders will not necessarily limit your losses to
        the intended amounts since it is possible market conditions could make
        it impossible to execute those orders, or that you may sustain a total
        loss of your funds in your CoinLion user account.
      </p>
      <p>
        15.5.1 – USERS ACKNOWLEDGE, ATTEST, AND AGREE THAT THE POINTS LISTED
        ABOVE APPLY TO ALL CRYPTOCURRENCY TRADING PAIRS AND ALL CRYPTOCURRENCY
        AND FIAT TRADING PAIRS; USERS ACKNOWLEDGE, ATTEST, AND AGREE THAT THIS
        BRIEF STATEMENT CANNOT DISCLOSE ALL THE RISK AND OTHER ASPECT ASSOCIATED
        WITH CRYPTOCURRENCY TRADING AND IS NOT CONSIDERED BY YOU TO BE AN
        EXHAUSTIVE LIST.
      </p>
      <p>
        15.6 – Computer Viruses – You acknowledge, attest, and agree that
        CoinLion will take reasonable steps to exclude any viruses from the
        Website, but cannot guarantee or warrant that any material available for
        downloading from the Website will be free from infection, viruses, or
        other code that has contaminating or destructive properties. By using
        the Website, you acknowledge and understand this risk and agree to not
        hold the CoinLion liable for viruses.
      </p>
      <p>
        15.7 – Entire Agreement – This User Agreement, our Privacy Policy, and
        our API Agreement, incorporated by reference herein, comprise the entire
        understanding and agreement entered into by and between you and
        CoinLion, LLC as to the subject matter hereof, and supersede any and all
        prior discussions, agreements, and understandings of any kind (including
        without limitation any prior versions of this User Agreement), as well
        as every nature between and among you and us. If there is any conflict
        between the incorporated references this User Agreement takes
        precedence.
      </p>
      <p>
        15.8 – Assignment – You acknowledge, attest, and agree that this User
        Agreement, or your rights and obligations hereunder, may not be
        transferred by you, but may be assigned by CoinLion without restriction.
        Any attempted transfer or assignment by you in violation hereof shall be
        null and void. This User Agreement shall be binding and inure to the
        benefit of the parties hereto, our successors, and permitted assigns.
      </p>
      <p>
        15.9 – Governing Law – You acknowledge, attest, and agree that any
        questions concerning the construction, validity, enforcement, and
        interpretation of this User Agreement shall be governed by and construed
        and enforced in accordance with the laws of the State of South Dakota,
        United States of America.
      </p>
      <p>
        15.9.1 – To resolve any dispute, controversy, or claim between them
        arising out of or relating to this Agreement, or the breach thereof, you
        agree first to negotiate in good faith for a period of not less than
        sixty (60) days following written notification to CoinLion of such
        controversy or claim to CoinLion.
      </p>
      <p>
        15.9.2 – Arbitration Agreement – You acknowledge, attest, and agree that
        if the negotiations do not resolve the dispute, controversy, or claim to
        the reasonable satisfaction of all Parties (you and CoinLion) during
        such period, then the Parties irrevocably and unconditionally submit the
        respective claim to the binding arbitration in accordance with the
        American Arbitration Association rules, and judgment upon the award
        rendered by the arbitrator(s) may be entered in any court having
        jurisdiction thereof. Except for any disputes, claims, suits, actions,
        causes of action, demands, or proceedings in which either Party seeks
        injunctive or other equitable relief for the alleged unlawful use of
        intellectual property, including, without limitation, copyrights,
        trademarks, trade names, logos, trade secrets, or patents, You and
        CoinLion (a) waive Your and CoinLion’s respective rights to have any and
        all disputes arising from or related to these Terms resolved in a court,
        (b) waive your and CoinLion’s respective rights to a jury trial, (c) and
        waive your and CoinLion’s respective rights to the right to participate
        in a class action lawsuit or classwide arbitration. The substantive law
        shall be South Dakota Law (including all other operating rules,
        policies, and procedures that may be issued by CoinLion and published
        from time to time on the Website), without regard to conflict of law
        rules or principles (whether of the State of South Dakota or any other
        jurisdiction). The language of the arbitration shall be English.
      </p>
      <p>
        15.9.3 – Language – Notwithstanding any other provision of this User
        Agreement, any translation of this User Agreement is provided for your
        convenience. The meanings of terms, conditions, and representations
        herein are subject to their definitions and interpretations in the
        English language.
      </p>
      <p>
        15.10 – Severability – You acknowledge, attest, agree, and understand
        that if any provision of this User Agreement, or application thereof,
        shall be determined to be invalid or unenforceable under any rule, law,
        or regulation or by any governmental agency, local, state, or federal,
        such provision will be changed and interpreted to accomplish the
        objectives of the provision to the greatest extent possible under any
        applicable law. You further agree and understand that the validity of or
        enforceability of any other provision (or of such provision, to the
        extent its application is not invalid or unenforceable) of this User
        Agreement shall not be affected.
      </p>
      <p>
        15.11 – Force Majeure Events – Users acknowledge, attest, and agree that
        CoinLion shall not be liable for (1) any inaccuracy, error, delay in, or
        omission of (a) any information, or (b) the transmission or delivery of
        information; (2) any loss or damage arising from any event beyond Our
        reasonable control, including but not limited to flood, extraordinary
        weather conditions, earthquake, act of God, fire, war, insurrection,
        riot, labor dispute, accident, action of government, communications,
        power failure, equipment or software malfunction, or any other cause
        beyond Our reasonable control (each, a “Force Majeure Event”).
      </p>
      <p>
        15.12 – Headings – Headings of sections are for convenience only and
        shall not be used to limit or construe such sections. All the sections
        in the agreement shall survive any termination or expiration of the User
        Agreement.
      </p>
      <p>
        15.13 – Disclaimers – You acknowledge, attest, and agree that CoinLion
        is not responsible for the proper or complete transmission of the
        information contained in any electronic communication or of the
        electronic communication itself, nor for any delay in its delivery or
        receipt. Security measures have been implemented to ensure the safety
        and integrity of any of the services related to the CoinLion services
        and CoinLion website. However, despite this, information that is
        transmitted over the internet or Blockchain may be susceptible to
        unlawful access and monitoring.
      </p>
      <p>
        15.14 – Non-Waiver of Rights – This agreement shall not be construed to
        waive rights that cannot be waived under applicable state money
        transmission laws in the state where you are located.
      </p>
      <p>
        Intellectual property Notification: This document belongs to the Owner
        and is protected by copyright laws. Its copying or use by any third
        party in full or in part without prior written consent of the Owner is
        strictly prohibited.
      </p>
      <p>Publication Date: August 20, 2021</p>
    </React.Fragment>
  )
}

export default TosIndividual
