import React from 'react'

const TwoWayArrows = ({
  fill = '#500078',
  width = '36',
  height = '36',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3235 21.2897C18.6987 21.4455 18.9424 21.8128 18.94 22.2191C18.9363 22.4878 18.8246 22.7437 18.63 22.9291L12.49 29.0391L6.35003 22.9291C6.09637 22.6772 5.99635 22.3091 6.08765 21.9635C6.17895 21.6179 6.44769 21.3473 6.79265 21.2535C7.13761 21.1598 7.50637 21.2572 7.76003 21.5091L11.49 25.2191L11.49 7.33906C11.49 6.78678 11.9377 6.33906 12.49 6.33906C13.0423 6.33906 13.49 6.78678 13.49 7.33906L13.49 25.2191L17.23 21.5091C17.5161 21.2206 17.9483 21.1339 18.3235 21.2897ZM17.31 13.4491C16.9207 13.0542 16.9251 12.4184 17.32 12.0291L23.49 5.92906L29.6 12.0391C29.8537 12.2909 29.9537 12.659 29.8624 13.0046C29.7711 13.3502 29.5024 13.6209 29.1574 13.7146C28.8125 13.8083 28.4437 13.7109 28.19 13.4591L24.49 9.73906L24.49 27.6291C24.49 28.1813 24.0423 28.6291 23.49 28.6291C22.9377 28.6291 22.49 28.1813 22.49 27.6291L22.49 9.73906L18.73 13.4591C18.3351 13.8484 17.6994 13.8439 17.31 13.4491Z"
        fill={fill}
      />
    </svg>
  )
}

export default TwoWayArrows
