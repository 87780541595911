import { useDispatch, useSelector } from 'react-redux'
import {
  getPublishers as _getPublishers,
  getSubscriptions as _getSubscriptions,
  getPublisherStrategies as _getPublisherStrategies,
  getMarketplaceStats as _getMarketplaceStats,
  createSubscription as _createSubscription,
  updateSubscription as _updateSubscription,
  unsubscribe as _unsubscribe,
  unsubscribeFromAllPublishers as _unsubscribeFromAllPublishers,
} from '@pv3-ducks/marketplace'
import { get, isNull, mean } from 'lodash'
import { unwrapResult } from '@reduxjs/toolkit'
import useUiDuck from "./useUiDuck";
import {LOADING_STATES} from "../constants";

const useMarketplaceDuck = () => {
  const dispatch = useDispatch()

  const { loading } = useUiDuck();

  const { publishers, publisherStrategies, subscriptions, marketplaceStats } = useSelector((state) =>
    get(state, 'marketplace')
  )

  const getPublishers = () => {
    if (!isNull(publishers) || loading[LOADING_STATES.GETTING_PUBLISHERS]) return;

    dispatch(_getPublishers());
  }

  const getSubscriptions = () => {
    dispatch(_getSubscriptions())
  }

  const getPublisherStrategies = (publisherUserId) =>
    dispatch(_getPublisherStrategies(publisherUserId))

  const getMarketplaceStats = () => {
    dispatch(_getMarketplaceStats())
  }

  const getFormattedStopLossDataFromPublisherStrategies = (strategies) => {
    if(!strategies) {
      strategies = publisherStrategies;
    }

    if(!strategies || strategies.length === 0) {
      return [];
    }

    let data = {};

    strategies.forEach((strategy) => {
      if(!strategy.baseAsset) {
        return;
      }

      if(!data[strategy.baseAsset]) {
        data[strategy.baseAsset] = {
          count: 0,
          hasStopLoss: 0,
          thresholds: [],
          averageThreshold: 0,
          percentHaveStopLoss: 0,
        };
      }

      data[strategy.baseAsset].count++;
      if(strategy.stopLoss !== 0) {
        data[strategy.baseAsset].hasStopLoss ++;
        data[strategy.baseAsset].thresholds.push(strategy.stopLoss);
      }
    });

    Object.keys(data).forEach((key) => {
      if(data[key].thresholds.length > 0) {
        data[key].averageThreshold = Math.round(mean(data[key].thresholds));
        data[key].percentHaveStopLoss = Math.round((data[key].hasStopLoss / data[key].count) * 100);
      }
    });

    return data;
  }

  const createSubscription = async (publisherUserId, portfolioPercent) => {
    try {
      const result = await dispatch(
        _createSubscription({ publisherUserId, portfolioPercent })
      )
      unwrapResult(result)
    } catch (e) {
      throw e
    }
  }

  const updateSubscription = async (publisherUserId, portfolioPercent) => {
    try {
      const result = await dispatch(
        _updateSubscription({ publisherUserId, portfolioPercent })
      )
      unwrapResult(result)
    } catch (e) {
      throw e
    }
  }

  const unsubscribe = async (publisherUserId) => {
    try {
      const result = await dispatch(_unsubscribe(publisherUserId))
      unwrapResult(result)
      return
    } catch (e) {
      throw e
    }
  }

  const unsubscribeFromAllPublishers = async () => {
    try {
      await dispatch(_unsubscribeFromAllPublishers()).unwrap();
      return;
    } catch(e) {
      throw e;
    }
  }

  return {
    publishers,
    publisherStrategies,
    subscriptions,
    marketplaceStats,
    createSubscription,
    updateSubscription,
    unsubscribe,
    getMarketplaceStats,
    getFormattedStopLossDataFromPublisherStrategies,
    getSubscriptions,
    getPublishers,
    getPublisherStrategies,
    unsubscribeFromAllPublishers,
  }
}

export default useMarketplaceDuck;
