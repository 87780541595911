import './currenciestooltip.scss';

import * as React from 'react';
import { useState } from 'react';
import { eq, lowerCase, map, uniqueId as _uniqueId } from 'lodash';

import { isArrayNullOrEmpty } from "../../../utils/utils";

import useAssetsDuck from "../../../hooks/useAssetsDuck";

import Currency from '../Currency'
import Icon from '../Icons';
import Logo from "../Logo";
import ParagraphsFromNewlines from "../ParagraphsFromNewlines";

type Props = {
  currencies: Array<{ name: string; value?: number }>
  description?: string
}

const CurrenciesTooltip: React.FC<Props> = ({
  currencies ,
  description,
}) => {
  const { assets } = useAssetsDuck();

  const [id] = useState(_uniqueId('currencies-tooltip-'))
  const [toolTipModalOpen, setToolTipModalOpen] = useState(false)

  const getCurrencyName = (currency) => {
    const foundAsset = assets.find((asset) => eq(asset.id, currency));
    return foundAsset?.asset_name || currency.toUpperCase();
  }

  const handleToolTipButtonHover = () => setToolTipModalOpen(true)
  const handleToolTipButtonLeave = () => setToolTipModalOpen(false)

  return (
    <div
      className={`CurrenciesTooltip${
        toolTipModalOpen ? ' CurrenciesTooltip--modalOpen' : ''
      }`}
    >
      <button
        type="button"
        className="u-buttonReset CurrenciesTooltip-button"
        aria-label="Currency Allocation"
        aria-expanded={toolTipModalOpen ? true : false}
        aria-describedby={id}
        onFocus={handleToolTipButtonHover}
        onBlur={handleToolTipButtonLeave}
      >
        <Icon name="info" width="16" height="16" />
      </button>
      <section id={id} role="tooltip" className="CurrenciesTooltip-content">
        {!isArrayNullOrEmpty(currencies) && (
          <ul>
            {map(currencies, (currency) => (
              <li key={currency.name}>
                {eq(currency.name, 'LION') ? (
                  <Logo />
                ) : (
                  <Currency type={lowerCase(currency.name)} />
                )}
                <b>{currency.value}</b> {getCurrencyName(lowerCase(currency.name))}
              </li>
            ))}
          </ul>
        )}

        <ParagraphsFromNewlines
          linkify
          text={description}
        />

        <Icon
          className="CurrenciesTooltip-caret"
          name="currenciestooltipcaret"
        />
      </section>
    </div>
  )
}

export default CurrenciesTooltip
