import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HeroBanner from '@pv3-shared-components/HeroBanner'
import { APP_PATHS } from '@pv3-constants'
import MarketplaceCryptocurrencies from '@pv3-components/MarketplaceCryptocurrencies'
import MarketplaceCopycatTrading from '@pv3-components/MarketplaceCopycatTrading'

const Marketplace = () => {
  return (
    <React.Fragment>
      <HeroBanner
        headline="Marketplace"
        subnav={[
          {
            label: 'Automated CopyCat Trading',
            path: APP_PATHS.MARKETPLACE,
          },
          {
            label: 'Manual Trading',
            path: APP_PATHS.MARKETPLACE_CRYPTOCURRENCIES,
          },
        ]}
      />
      <Switch>
        <Route
          path={APP_PATHS.MARKETPLACE_CRYPTOCURRENCIES}
          component={MarketplaceCryptocurrencies}
        />
        <Route
          path={APP_PATHS.MARKETPLACE}
          component={MarketplaceCopycatTrading}
        />
      </Switch>
    </React.Fragment>
  )
}

export default Marketplace
