import React, { useEffect, useMemo, useState } from 'react'
import {
  cloneDeep,
  isEmpty,
} from 'lodash'
import Input from '@pv3-shared-components/Input'
import InputError from '@pv3-shared-components/InputError'
import Button from '@pv3-shared-components/Button'
import { useForm } from 'react-hook-form'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import Icon from '@pv3-shared-components/Icons'
import Modal from '@pv3-shared-components/Modal'
import useModal from '@pv3-hooks/useModal'
import Sumsub from "../shared/Sumsub";
import { KYC_VERIFICATION_LEVELS, KYC_VERIFICATION_STATUSES } from "../../constants";
import Select from "../shared/Select";
import { isArrayNullOrEmpty } from "../../utils/utils";

const FieldsPersonal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditingContact, setIsEditingContact] = useState(false)
  const [title, setTitle] = useState('');
  const [result, setResult] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {
    addresses,
    countries,
    getCountries,
    profile,
    renderAddressDisplay,
    renderPhoneNumberDisplay,
    updateKycVerificationStatus,
    updateProfile
  } = useProfileDuck()

  const {
    formState: {errors},
    handleSubmit,
    register,
  } = useForm()

  const addressOptions = useMemo(
    () => {
      if (isArrayNullOrEmpty(addresses)) {
        return [];
      }

      return addresses.map((address) => {
        let label = '';
        if(address.buildingNumber) label += `${address.buildingNumber} `;
        if(address.flatNumber) label += `${address.flatNumber} `;
        if(address.subStreet) label += `${address.subStreet} `;
        label += `${address.street}, ${address.town}, ${address.state}`;
        return {
          ...address,
          label,
          value: address.id,
        };
      });
    }, [addresses]
  );

  useEffect(() => {
    if(isArrayNullOrEmpty(addresses)) {
      return;
    }

    const foundAddress = addresses.find((address) => address.street === profile.street && address.town === profile.town);

    if(foundAddress) {
      setSelectedAddress(foundAddress.id.toString());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    if (!isEmpty(countries)) return
      ;
    (async function requestCountries() {
      try {
        getCountries();
      } catch (error) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  const modal = useModal()

  const sumsubModal = useModal()
  sumsubModal.wide = true;

  const handleAddressChange = (e) => {
    setSelectedAddress(e.target.value);
  };

  const handleFormSubmit = async (fields, e) => {
    e.preventDefault()

    try {
      setIsSubmitting(true)

      // For legacy v2 accounts, remove prior versions of these objects
      let clonedProfile = cloneDeep(profile)

      clonedProfile = {
        ...clonedProfile,
        ...fields,
      };

      let address = addresses.find((a) => a.id === parseInt(selectedAddress));

      if(address && (address.street !== clonedProfile.street || address.city !== clonedProfile.city)) {
        let clonedAddress = cloneDeep(address);

        const foundCountry = countries.find((country) => country.kyc === clonedAddress.country);

        if(foundCountry) {
          clonedAddress.country = foundCountry.code;
        } else {
          delete clonedAddress.country;
        }

        delete clonedAddress.id;
        delete clonedAddress.label;
        delete clonedAddress.value;

        clonedProfile = {
          ...clonedProfile,
          ...clonedAddress,
          selectedAddress: true,
        };
      }

      await updateProfile(clonedProfile);

      setIsEditingContact(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isEditingContact) {
    return (
      <form
        className="AcctSettings-form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <fieldset className="Fieldset AcctSettings-fieldset">
          <legend className="Legend AcctSettings-legend">Contact info</legend>

          {profile?.kycVerificationStatus !== KYC_VERIFICATION_STATUSES.PENDING && (
            <>
              {!isArrayNullOrEmpty(addresses) && (
                <>
                  <p style={{clear: 'both'}}>
                    <Select
                      onChange={handleAddressChange}
                      options={addressOptions}
                      value={selectedAddress}
                    />
                  </p>

                  <p>
                    You can select your current address from the list of addresses that were pulled from your Identity
                    Verification check. If your current address is not listed please click on the ‘Add New Address’ button.
                  </p>
                </>
              )}

              <p style={{clear: 'both'}}>
                <Button
                  variant="secondary"
                  size="small"
                  type="button"
                  onClick={() => sumsubModal.setShow(true)}
                >
                  {isArrayNullOrEmpty(addresses) ? 'Change' : 'Add New'} Address
                </Button>
              </p>
            </>
          )}

          <Input
            type="tel"
            defaultValue={profile.cellNumber}
            register={{
              ...register('cellNumber', {
                required: 'This field is required',
              }),
            }}
            error={
              errors['cellNumber'] && (
                <InputError
                  message={
                    errors['cellNumber'].message
                  }
                />
              )
            }
          >
            Cell Number
          </Input>

          <Button
            variant="primary"
            size="small"
            type="submit"
            className="AcctSettings-save"
            isLoading={isSubmitting}
          >
            Save
          </Button>
          <Button
            variant="secondary"
            size="small"
            type="button"
            className="AcctSettings-cancel"
            onClick={() => setIsEditingContact(false)}
          >
            Cancel
          </Button>
        </fieldset>

        <Modal
          modal={modal}
          screens={{
            MAIN: {
              heading: () => <h4>{title}</h4>,
              body: () => (
                <React.Fragment>
                  <p>
                    {result}
                  </p>
                  <Button
                    className="sumsub-results-button"
                    variant="primary"
                    size="large"
                    type="button"
                    onClick={() => {
                      modal.setShow(false);
                    }}
                  >
                    OK
                  </Button>
                </React.Fragment>
              ),
            },
          }}
        />

        <Modal
          modal={sumsubModal}
          screens={{
            MAIN: {
              body: () => (
                <React.Fragment>
                  <Sumsub
                    levelName={KYC_VERIFICATION_LEVELS.PROOF_OF_ADDRESS}
                    onFailure={(message) => {
                      setTitle('There was an issue');
                      setResult(message || 'We were unable to verify your information. Please try again');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                    onPending={() => {
                      setTitle('Verification is pending');
                      setResult('Thank you for submitting your information. The verification process can take up to a full business day.');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                    onSuccess={() => {
                      setTitle('Success!');
                      setResult('Your address change has been successfully verified.');
                      sumsubModal.setShow(false);
                      modal.setShow(true);
                      updateKycVerificationStatus();
                    }}
                  />
                </React.Fragment>
              ),
            },
          }}
        />
      </form>
    )
  }

  return (
    <section className="Fieldset AcctSettings-fieldset">
      <h1 className="Legend AcctSettings-legend">Contact info</h1>
      {profile?.kycVerificationStatus === KYC_VERIFICATION_STATUSES.VERIFIED && (
        <button
          className="u-buttonReset AcctSettings-edit"
          type="button"
          onClick={() => setIsEditingContact(!isEditingContact)}
        >
          Edit
          <Icon aria-hidden="true" name="pencil"/>
        </button>
      )}
      <dl className="AcctSettings-list">
        {renderAddressDisplay()}

        {renderPhoneNumberDisplay()}
      </dl>
    </section>
  )
}

export default FieldsPersonal
