import { eq, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { get } from 'react-hook-form'

type UseModalProps = {
    defaultScreen?: string
    onClose?: () => void
};

const useModal = (options?: UseModalProps) => {
  const [defaultScreen, setDefaultScreen] = useState<string | undefined>(options?.defaultScreen ? options.defaultScreen : undefined);
  const [show, setShow] = useState<boolean | undefined>()
  const [wide, setWide] = useState<boolean>(false)

  useEffect(() => {
    if (!isUndefined(get(options, 'onClose')) && eq(show, false))
      options.onClose()

    if (!show) setWide(false)
  }, [options, show])

  return { defaultScreen, setDefaultScreen, show, setShow, handleClose: () => setShow(false), wide, setWide }
}

export default useModal
