import { isUndefined } from 'lodash'
import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    if (isUndefined(element)) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      },
      { rootMargin }
    )

    element && observer.observe(element)

    return () => observer.unobserve(element)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element])

  return isVisible
}

export default useIntersection
