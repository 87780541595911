import Websocket from '../utils/websocket'

export const wsAccount = new Websocket(
  `${window.envConfig.COINLION_API_URL}frontoffice/ws/account`
)

export const startWsAccount = (
  balancesCallback,
  openOrdersCallback,
  transfersCallback
) => {
  wsAccount.connect().then(() => {
    subscribeToBalance(balancesCallback)
    subscribeToOpenOrders(openOrdersCallback)
    subscribeToTransfers(transfersCallback)
  })
}

const subscribeToBalance = (callback) => {
  wsAccount.subscribe('Balance', null, null, callback)
}

const subscribeToOpenOrders = (callback) => {
  wsAccount.subscribe('OpenOrders', null, null, callback)
}

const subscribeToTransfers = (callback) => {
  wsAccount.subscribe('TransfersList', { count: 100 }, null, callback)
}
