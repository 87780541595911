import React from 'react'

const CheckmarkOutline = ({
  fill = '#00854D',
  width = '36',
  height = '36',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 6C11.373 6 6 11.373 6 18s5.373 12 12 12 12-5.373 12-12A12 12 0 0 0 18 6Zm0 22c-5.523 0-10-4.477-10-10S12.477 8 18 8s10 4.477 10 10a10 10 0 0 1-10 10Zm-6.66-9.26 5 5 8.19-8.19a1 1 0 0 0-1.41-1.41l-6.78 6.78-3.59-3.59a1 1 0 0 0-1.41 1.41Z"
        fill={fill}
      />
    </svg>
  )
}

export default CheckmarkOutline
