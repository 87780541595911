import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTaxDocuments as _getTaxDocuments,
  getTaxDocumentTypes as _getTaxDocumentTypes,
  requestTaxDocument as _requestTaxDocument,
  getTaxDocumentUrl as _getTaxDocumentUrl,
} from '@pv3-ducks/reports'
import { isNull, isEmpty } from 'lodash'

const useReportsDuck = () => {
  const dispatch = useDispatch()

  const { taxDocuments, taxDocumentTypes } = useSelector(
    (state) => state.reports
  )

  const getTaxDocuments = () => {
    dispatch(_getTaxDocuments())
  }

  useEffect(() => {
    if (!isNull(taxDocuments)) return
    getTaxDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxDocuments])

  const getTaxDocumentTypes = () => {
    dispatch(_getTaxDocumentTypes())
  }

  useEffect(() => {
    if (!isEmpty(taxDocumentTypes)) return
    getTaxDocumentTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxDocuments])

  const requestTaxDocument = async (type, year) => {
    try {
      await dispatch(_requestTaxDocument({ type, year }))
    } catch (e) {
      throw e
    }
  }

  const getTaxDocumentUrl = (s3ObjectKey) =>
    dispatch(_getTaxDocumentUrl(s3ObjectKey))

  return {
    taxDocuments,
    taxDocumentTypes,
    requestTaxDocument,
    getTaxDocuments,
    getTaxDocumentUrl,
  }
}

export default useReportsDuck;
