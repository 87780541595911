import './tooltiplight.scss';

import * as React from 'react';
import { useState } from 'react';
import { uniqueId as _uniqueId } from 'lodash';

import Icon from '../Icons';

type Props = {
  children: React.ReactElement
  className?: string
  icon?: string
  top?: boolean
}

const TooltipLight: React.FC<Props> = ({
  children,
  className = '',
  icon = 'info',
  top,
}) => {
  const [id] = useState(_uniqueId('tooltiplight-'));
  const [toolTipModalOpen, setToolTipModalOpen] = useState(false);
  const [offsetStyle, setOffsetStyle] = useState({});
  const [offsetStyleCaret, setOffsetStyleCaret] = useState({});

  const handleToolTipButtonHover = (e) => {
    // Find if needs to be offset from edge of viewport
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = e.currentTarget.getBoundingClientRect();
    const offsetLeft = elemRect.left - bodyRect.left;
    const offsetRight = elemRect.right - bodyRect.right;

    if (offsetRight > -134) {
      setOffsetStyle({ left: '-' + (134 + offsetRight) + 'px' });
      setOffsetStyleCaret({
        transform: `translateX(${142 + offsetRight}px)${top ? ' rotateZ(180deg)' : ''}`,
      });
    } else if (offsetLeft < 134) {
      setOffsetStyle({ left: 154 - offsetLeft + 'px' });
      setOffsetStyleCaret({
        transform: `translateX(${142 - offsetLeft}px)${top ? ' rotateZ(180deg)' : ''}`,
      });
    } else {
      setOffsetStyle({});
      setOffsetStyleCaret({});
    }

    setToolTipModalOpen(true);
  }

  const handleToolTipButtonLeave = () => setToolTipModalOpen(false);

  return (
    <div
      className={`TooltipLight ${className}${
        toolTipModalOpen ? ' TooltipLight--modalOpen' : ''
      }${
        top ? ' TooltipLight--top' : ''
      }`}
    >
      <button
        type="button"
        className="u-buttonReset TooltipLight-button"
        aria-expanded={toolTipModalOpen}
        aria-describedby={id}
        onFocus={handleToolTipButtonHover}
        onBlur={handleToolTipButtonLeave}
        onMouseOver={handleToolTipButtonHover}
        onMouseOut={handleToolTipButtonLeave}
      >
        <Icon name={icon} width="16" height="16" />
      </button>

      <section
        id={id}
        role="tooltip"
        className="TooltipLight-content"
        style={offsetStyle}
      >
        {children}

        <Icon
          className="CurrenciesTooltip-caret"
          name="currenciestooltipcaret"
          style={offsetStyleCaret}
        />
      </section>
    </div>
  )
}

export default TooltipLight;
