import './createaccount.scss';

import * as React from 'react';
import { useState } from 'react';
import { isNil } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { FormProvider, useForm } from 'react-hook-form';
import { Route, Switch, useHistory } from 'react-router-dom';

import { APP_PATHS, PASSWORD_MAX, PASSWORD_MIN } from '../../constants';

import useAuthorizedRedirect from '../../hooks/useAuthorizedRedirect';
import useDocTitle from '../../hooks/useDocTitle';

import Auth from '../Auth/Auth0'
import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from '../shared/Button';
import CountryAndRegionSelect from "../shared/CountryAndRegionSelect";
import CreateAccountDisclaimer from "../CreateAccountDisclaimer";
import Flash from '../shared/Flash';
import Icon from '../shared/Icons';
import Input, { InputTypes } from '../shared/Input';
import InputError from '../shared/InputError';
import VerifyEmail from '../VerifyEmail';

const auth0 = new Auth()

const CreateAccountForm: React.FC = () => {
  useDocTitle('Create Account');

  const { loginWithRedirect } = useAuth0();
  const formMethods = useForm();
  const history = useHistory();

  const [error, setError] = useState<string | undefined>(undefined);
  const [disableContinue, setDisableContinue] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const handleFormFocus = () => {
    setError(undefined);
  };

  const handleFormSubmit = async ({ email, password, country, state }: {email?: string, password?: string, country?: string, state?: string}) => {
    try {
      await auth0.signup(email, password, {
        countryCode: country,
        stateProvinceRegion: state,
      });

      history.push(APP_PATHS.CREATE_ACCOUNT_VERIFY_EMAIL, { email });
    } catch (error: any) {
      setError(error.description);
    }
  };

  return (
    <>
      {!isNil(error) && <Flash danger>{error}</Flash>}

      <form
        className="SinglePanel-panel"
        onFocus={handleFormFocus}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <h1 className="SinglePanel-logo">
          <Icon name="coinlionlogo" />
        </h1>

        <h2 className="SinglePanel-title">Create account</h2>

        <Input
          id="create_email"
          className={`Input ${errors.email ? 'Input--error' : ''}`}
          inputType={InputTypes.Email}
          register={{
            ...register('email', { required: 'Email is required' }),
          }}
          error={errors.email && <InputError message={errors.email.message as string} />}
        >
          Email
        </Input>

        <Input
          id="create_password"
          className={`Input ${errors.password ? 'Input--error' : ''}`}
          inputType={InputTypes.Password}
          register={{
            ...register('password', {
              required: 'Password is required',
              minLength: {
                value: PASSWORD_MIN,
                message: `Password must be at least ${PASSWORD_MIN} characters`,
              },
              maxLength: {
                value: PASSWORD_MAX,
                message: `Password must be at most ${PASSWORD_MAX} characters`,
              },
              pattern: {
                value: /^(?!.*?(.)\1\1)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*,]).{8,}$/,
                message:
                  'Password must contain a lowercase, uppercase, number, special character (e.g. !@#$), and must not contain more than two identical characters in a row',
              },
            }),
          }}
          helperText={(<ul className="CreatePasswordRequirements"><li>12–64 characters</li><li>At least one lowercase and uppercase letter, number, and special character</li><li>No more than two identical characters in a row</li><li>Do not use common words like password or testing</li></ul>)}
          error={
            errors.password && <InputError message={errors.password.message as string} />
          }
        >
          Password
        </Input>

        <FormProvider
          {...formMethods}
        >
          <CountryAndRegionSelect setDisableContinue={setDisableContinue} />
        </FormProvider>

        <Button
          id="create_button"
          variant={ButtonVariants.Primary}
          size={ButtonSizes.Large}
          type={ButtonTypes.Submit}
          isLoading={isSubmitting}
          disabled={disableContinue}
        >
          Continue
        </Button>
      </form>

      <p className="SinglePanel-addendum SinglePanel-addendum--createAccount">
        Already have an account?{' '}
        <button className="u-buttonReset" onClick={loginWithRedirect}>
          Sign in
        </button>
      </p>
    </>
  )
}

const CreateAccount = () => {
  useAuthorizedRedirect()

  return (
    <main className="SinglePanel">
      <Switch>
        <Route exact path={APP_PATHS.CREATE_ACCOUNT} component={CreateAccountDisclaimer} />
        <Route
          path={APP_PATHS.CREATE_ACCOUNT_VERIFY_EMAIL}
          component={VerifyEmail}
        />
        <Route path={APP_PATHS.CREATE_ACCOUNT_SIGNUP} component={CreateAccountForm} />
      </Switch>
    </main>
  )
}

export default CreateAccount
