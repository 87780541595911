import React from 'react'

const Pencil = ({ fill = '#323232', width = '20', height = '20', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.817 5.178 15.555 1.9a1.15 1.15 0 0 0-1.622 0L2.372 13.444 1.316 18a1.145 1.145 0 0 0 1.112 1.389c.079.008.159.008.238 0l4.606-1.056L18.816 6.8a1.15 1.15 0 0 0 0-1.622Zm-12.1 12.155L2.4 18.24l.983-4.234 8.65-8.616 3.334 3.333-8.65 8.611Zm6.06-12.75 3.334 3.334 1.878-1.923-3.278-3.333-1.933 1.922Z"
        fill={fill}
      />
    </svg>
  )
}

export default Pencil
