import React, { useEffect, useMemo } from 'react'
import {
  find,
  isUndefined,
  get,
  toLower,
  filter,
  eq,
  map,
  isNull, isNil,
} from 'lodash'
import Button from '@pv3-shared-components/Button'
import Select from '@pv3-shared-components/Select'
import Icon from '@pv3-shared-components/Icons'
import './strategySignalSettingsRow.scss'
import { useFormContext } from 'react-hook-form'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import Tooltip from '@pv3-shared-components/Tooltip'
import StrategySignalWebhookBody from "../StrategySignalWebhookBody";
import { calculateStep, isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";
import InputError from "../shared/InputError";
import Loading from "../shared/Loading";

const attributePrefix = {
  orderSize: 'orderSizeIndicatorTrigger',
  buy: 'buyIndicatorTrigger',
  sell: 'sellIndicatorTrigger',
  stopLoss: 'stopLossIndicatorTrigger',
  pauseBuy: 'buyIndicatorManagement',
  resumeBuy: 'buyIndicatorManagement',
  pauseSell: 'sellIndicatorManagement',
  resumeSell: 'sellIndicatorManagement',
}

const triggerListAttribute = {
  orderSize: 'triggerIndicatorSettings',
  buy: 'triggerIndicatorSettings',
  sell: 'triggerIndicatorSettings',
  stopLoss: 'triggerIndicatorSettings',
  pauseBuy: 'disableIndicatorSettings',
  resumeBuy: 'enableIndicatorSettings',
  pauseSell: 'disableIndicatorSettings',
  resumeSell: 'enableIndicatorSettings',
}

const allocationMultiplierOptions = [
  { label: '0.10', value: .1 },
  { label: '0.20', value: .2 },
  { label: '0.30', value: .3 },
  { label: '0.40', value: .4 },
  { label: '0.50', value: .5 },
  { label: '0.60', value: .6 },
  { label: '0.70', value: .7 },
  { label: '0.80', value: .8 },
  { label: '0.90', value: .9 },
  { label: '1.0', value: 1 },
]

const StrategySignalSettingsRow = ({ index, isEditing, closeFn, signalType, indicator, values }) => {
  const { formState: { errors }, getValues, register, setValue, trigger, watch } = useFormContext()
  const { dynamicValuesSettings, indicators, saveStrategyErrors, supportedIntervals } = useStrategiesDuck()

  const isComparingToCurrent = watch(
    `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.ShouldCompareToCurrentPrice`
  )

  useEffect(() => {
    const subscription = watch(() => null);
    return () => subscription.unsubscribe();
  }, [watch]);

  const { intervalOptions, indicatorObj, chartPeriodField, displayName } = useMemo(() => {
    const indicatorObj = find(indicators, { indicatorName: indicator })
    const chartPeriodField = find(get(indicatorObj, 'fields'), {
      name: 'interval',
    })
    const displayName = get(indicatorObj, 'displayName') || get(indicatorObj, 'indicatorName')

    const intervalOptions = !isObjectNullOrEmpty(chartPeriodField) && chartPeriodField.type === 'enum' ? chartPeriodField.values : supportedIntervals;

    return {
      intervalOptions,
      indicatorObj,
      chartPeriodField,
      displayName
    }
  }, [indicator, indicators, supportedIntervals])

  const hasFieldLabel = (field) => {
    if (isUndefined(isComparingToCurrent)) return true

    return !eq(field.name, 'additionalPeriod') || !isComparingToCurrent
  }

  const { onChange, onBlur, name: fieldName, ref } = register(
    `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}[${index}].required`
  );

  const renderDynamicValuesDisplay = () => {
    if(isArrayNullOrEmpty(dynamicValuesSettings)) {
      return null;
    }

    const accessString = `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}[${index}]`;
    const dynamicValues = dynamicValuesSettings.find((dvs) => dvs.accessString === accessString);

    if(dynamicValues?.shouldGetDynamicValues) {
      if(dynamicValues.hasAllValues) {
        return (
          <div className="StrategySignal-dynamicValues">
            Current Value{dynamicValues.values.length !== 1 ? 's' : ''}: <strong>{dynamicValues.values.join(', ')}{indicatorObj?.calculatedValueSuffix || ''}</strong>
          </div>
        );
      } else if(dynamicValues.hasValue) {
        return (
          <div className="StrategySignal-dynamicValues">
            Current
            Values: <strong>{dynamicValues.values.join(', ')}{indicatorObj?.calculatedValueSuffix || ''}</strong> and{' '}
            <strong>Calculating</strong>{' '}
            <Loading/>
          </div>
        );
      }

      return (
        <div className="StrategySignal-dynamicValues">
          Current Value: <strong>Calculating</strong>{' '}
          <Loading />
        </div>
      );
    }

    return null;
  };

  const renderErrorMessage = () => {
    if(isArrayNullOrEmpty(saveStrategyErrors)) {
      return null;
    }

    const accessString = `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}[${index}]`;
    const found = saveStrategyErrors.find((s) => s.selector === accessString);

    if(!found) {
      return null;
    }

    return (
      <InputError
        className="StrategySignal-Error"
        message={found.message}
      />
    )
  };

  const testDisplayConditions = ({ displayConditions, name }) => {
    if (isObjectNullOrEmpty(displayConditions) || isArrayNullOrEmpty(displayConditions.conditions)) {
      return false;
    }

    let meets = [];

    displayConditions.conditions.forEach((condition) => {
      const fieldName = condition.field;
      let currentValue = getValues(`${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.${fieldName}`);

      if (!isNaN(parseFloat(currentValue))) {
        currentValue = parseFloat(currentValue);
      }

      if (currentValue === 'true') {
        currentValue = true;
      }

      if (currentValue === 'false') {
        currentValue = false;
      }

      meets.push(currentValue === condition.value);
    });

    if (displayConditions.match === 'and') {
      return isNil(meets.find((m) => m === false));
    } else {
      return !isNil(meets.find((m) => m === true));
    }
  }

  return (
    <div className={`StrategySignal StrategySignal--${signalType}`}>
      <div className="StrategySignal-top">
        <h4 data-testid={`indicatorName_${displayName}`} className="StrategySignal-heading">{displayName}</h4>
        <input
          type="text"
          defaultValue={indicator}
          hidden
          {...register(
            `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.indicator`
          )}
        />

        {renderDynamicValuesDisplay()}

        {(indicator !== 'Webhook') && (
          <React.Fragment>
            {!isObjectNullOrEmpty(chartPeriodField) && chartPeriodField.row === 0 && (
              <label className="Label" style={{ width: '21rem' }}>
                Chart period
                <Select
                  id={`${signalType}_chart_period_${indicator}`}
                  data-testid={`${signalType}_chart_period_${indicator}`}
                  required
                  register={register(
                    `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.interval`
                  )}
                  defaultValue={
                    get(values, 'interval') || get(chartPeriodField, 'default')
                  }
                >
                  {Object.keys(intervalOptions).map((key) => (
                    <option value={key}>
                      {intervalOptions[key]}
                    </option>
                  ))}
                </Select>
              </label>
            )}

            <label className="Label Label--signalRequired">
              <input
                onChange={async (e) => {
                  e.target.value = e.target.checked;
                  onChange(e);
                  trigger();
                }}
                onBlur={onBlur}
                name={fieldName}
                ref={ref}
                id={`${signalType}_${indicator}_signal_required_box`}
                data-testid={`${signalType}_${indicator}_signal_required_box`}
                type="checkbox"
                defaultChecked={values && values['required']}
              />
              Make this signal required
            </label>
          </React.Fragment>
        )}

        {signalType === 'orderSize' && (
          <div className="StrategySignal-allocationMultiplier">
            <label className="Label" style={{ width: '21rem' }}>
              Allocation Multiplier
              <Select
                id={`${signalType}_allocation_multiplier_${indicator}`}
                data-testid={`${signalType}_allocation_multiplier_${indicator}`}
                required
                register={register(
                  `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.positionSizeModifier`
                )}
                defaultValue={
                  get(values, 'positionSizeModifier') || '1.0'
                }
              >
                {allocationMultiplierOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </label>
          </div>
        )}

        <Button
          id={`${signalType}_${indicator}_remove_btn`}
          data-testid={`${signalType}_${indicator}_remove_btn`}
          className="StrategySignal-trash"
          onClick={closeFn}
          variant="quaternary"
          title="Remove signal"
        >
          <Icon name="trash" fill="#500078" />
        </Button>
      </div>
      <hr
        className="u-divider u-divider--inner u-divider--light"
        style={{ marginTop: 0, marginBottom: '2rem' }}
      />
      <div
        className={`StrategySignal-bottom StrategySignal-bottom--${toLower(
          indicator
        )}`}
      >
        {indicator !== 'Webhook' ? (
          <React.Fragment>
            {map(
              filter(get(indicatorObj, 'fields'), (i) => {
                if(!eq(i.name, 'interval')) {
                  return true;
                }

                return !eq(i.row, 0);
              }),
              ({
                 allowZero,
                 allowNegative,
                 displayConditions,
                 maximumValue,
                 minimumValue,
                 name,
                 label,
                 scale,
                 type,
                 values: selectValues,
                 default: defaultValue,
                toolTip
              }) => {
                const { onChange, onBlur, name: fieldName, ref } = register(
                  `${attributePrefix[signalType]}Settings.${
                    triggerListAttribute[signalType]
                  }.${index}.${name}`
                );

                const meetsDisplayConditions = testDisplayConditions({displayConditions, name});

                if(meetsDisplayConditions && displayConditions.style === 'hidden') {
                  return null;
                }

                let errorObj = get(errors, `${attributePrefix[signalType]}Settings.${
                  triggerListAttribute[signalType]
                }.${index}.${name}`)

                return (
                  <React.Fragment
                    key={`signals-indicator-${indicator}-${index}-${name}`}
                  >
                    {{
                      boolean: (
                        <label
                          className="Label"
                          data-testid={`${indicator}_${signalType}_${label}_label`}
                          style={{
                            flexWrap: 'nowrap',
                            flexDirection: 'row',
                          }}
                        >
                          <input
                            disabled={meetsDisplayConditions && displayConditions.style === 'disabled'}
                            id={`${indicator}_${signalType}_${label}_box`}
                            data-testid={`${indicator}_${signalType}_${label}_box`}
                            style={{ flexShrink: 0 }}
                            type="checkbox"
                            onChange={(e) => {
                              e.target.value = e.target.checked;
                              onChange(e);
                            }}
                            onBlur={onBlur}
                            name={fieldName}
                            ref={ref}
                            defaultChecked={get(values, `${name}`)}
                          />
                          <span style={{ marginLeft: '1rem' }}>{label}</span>
                          {!isNull(toolTip) && <Tooltip>{toolTip}</Tooltip>}
                        </label>
                      ),
                      enum: (
                        <label className="Label" data-testid={`${indicator}_${signalType}_label_${name}`}>
                          {label}&nbsp;
                          {!isNull(toolTip) && <Tooltip>{toolTip}</Tooltip>}
                          <Select
                            disabled={meetsDisplayConditions && displayConditions.style === 'disabled'}
                            id={`${indicator}_${signalType}_operator_${name}`}
                            data-testid={`${indicator}_${signalType}_operator_${name}`}
                            required
                            defaultValue={
                              isUndefined(get(values, `${name}`))
                                ? defaultValue
                                : get(values, `${name}`)
                            }
                            onChange={(e) => {
                              onChange(e);

                              if(indicator === 'EMA' && name === 'compareDirection') {
                                let newValue = parseInt(e.target.value, 10);

                                if(newValue > 1) {
                                  // We want to ensure the "Use Current Price" checkbox is unchecked
                                  // if the value of the compareDirection select box
                                  // isn't "greater than" (0) or "less than" (1)
                                  setValue(`${attributePrefix[signalType]}Settings.${
                                    triggerListAttribute[signalType]
                                  }.${index}.ShouldCompareToCurrentPrice`, false);
                                }
                              }
                            }}
                            register={register(
                              `${attributePrefix[signalType]}Settings.${
                                triggerListAttribute[signalType]
                              }.${index}.${name}`
                            )}
                          >
                            {map(selectValues, ({ label, value, type }) => (
                              <option
                                id={`${indicator}_${signalType}_operator_${label}`}
                                data-testid={`${indicator}_${signalType}_operator_${label}`}
                                key={`${indicator}-${index}-${name}-${value}`}
                                value={value}
                              >
                                {label}
                              </option>
                            ))}
                          </Select>
                        </label>
                      ),
                      float: (
                        <label className="Label" data-testid={`${indicator}_${signalType}_${label}_label`}>
                          {label}
                          {!isNull(toolTip) && <Tooltip>{toolTip}</Tooltip>}
                          <input
                            disabled={meetsDisplayConditions && displayConditions.style === 'disabled'}
                            id={`${indicator}_${signalType}_${label}_input`}
                            data-testid={`${indicator}_${signalType}_${label}_input`}
                            type="number"
                            className="Input"
                            min={allowNegative === false ? 0 : minimumValue}
                            max={maximumValue}
                            step={calculateStep(scale)}
                            inputMode="decimal"
                            required
                            {...register(
                              `${attributePrefix[signalType]}Settings.${
                                triggerListAttribute[signalType]
                              }.${index}.${name}`,
                              {
                                validate: (value) => {
                                  if(!/float|integer/.test(type)) {
                                    return true;
                                  }

                                  return (allowZero !== false || parseFloat(value) !== 0) || 'This field cannot be zero'
                                }
                              }
                            )}
                            defaultValue={
                              isUndefined(get(values, `${name}`))
                                ? defaultValue
                                : get(values, `${name}`)
                            }
                          />

                          {!isObjectNullOrEmpty(errorObj) && (
                            <InputError message={errorObj.message} />
                          )}
                        </label>
                      ),
                      // same as float, but doesn't convert it to a number on save
                      decimal: (
                        <label className="Label" data-testid={`${indicator}_${signalType}_${label}_label`}>
                          {label}
                          {!isNull(toolTip) && <Tooltip>{toolTip}</Tooltip>}
                          <input
                            disabled={meetsDisplayConditions && displayConditions.style === 'disabled'}
                            id={`${indicator}_${signalType}_${label}_input`}
                            data-testid={`${indicator}_${signalType}_${label}_input`}
                            type="number"
                            className="Input"
                            min={allowNegative === false ? 0 : minimumValue}
                            max={maximumValue}
                            step={calculateStep(scale)}
                            inputMode="decimal"
                            required
                            {...register(
                              `${attributePrefix[signalType]}Settings.${
                                triggerListAttribute[signalType]
                              }.${index}.${name}`,
                              {
                                validate: (value) => {
                                  if(!/float|integer/.test(type)) {
                                    return true;
                                  }

                                  return (allowZero !== false || parseFloat(value) !== 0) || 'This field cannot be zero'
                                }
                              }
                            )}
                            defaultValue={
                              isUndefined(get(values, `${name}`))
                                ? defaultValue
                                : get(values, `${name}`)
                            }
                          />

                          {!isObjectNullOrEmpty(errorObj) && (
                            <InputError message={errorObj.message} />
                          )}
                        </label>
                      ),
                      interval: (
                        <label className="Label" style={{ width: '21rem' }} data-testid={`${indicator}_${signalType}_${label}_label`}>
                          {label}

                          <Select
                            id={`${indicator}_${signalType}_${label}_interval`}
                            data-testid={`${indicator}_${signalType}_${label}_interval`}
                            required
                            register={register(
                              `${attributePrefix[signalType]}Settings.${triggerListAttribute[signalType]}.${index}.${name}`
                            )}
                            defaultValue={
                              isUndefined(get(values, `${name}`))
                                ? defaultValue
                                : get(values, `${name}`)
                            }
                          >
                            {Object.keys(intervalOptions).map((key) => (
                              <option value={key}>
                                {intervalOptions[key]}
                              </option>
                            ))}
                          </Select>
                        </label>
                      ),
                      integer: hasFieldLabel({ name }) ? (
                        <label className="Label" data-testid={`${indicator}_${signalType}_${name}_label`}>
                          {label}
                          {!isNull(toolTip) && <Tooltip>{toolTip}</Tooltip>}
                          <input
                            disabled={meetsDisplayConditions && displayConditions.style === 'disabled'}
                            id={`${indicator}_${signalType}_${label}_${name}_input`}
                            data-testid={`${indicator}_${signalType}_${label}_${name}_input`}
                            min={allowNegative === false ? 0 : minimumValue}
                            max={maximumValue}
                            type="number"
                            inputMode="decimal"
                            className="Input"
                            required
                            {...register(
                              `${attributePrefix[signalType]}Settings.${
                                triggerListAttribute[signalType]
                              }.${index}.${name}`
                            )}
                            defaultValue={
                              isUndefined(get(values, `${name}`))
                                ? defaultValue
                                : get(values, `${name}`)
                            }
                          />

                          {!isObjectNullOrEmpty(errorObj) && (
                            <InputError message={errorObj.message} />
                          )}
                        </label>
                      ) : (
                        <p className="StrategySignal-fieldReplacer">
                          <em>Current price</em>
                        </p>
                      ),
                    }[type] || null}
                  </React.Fragment>
                )
              }
            )}
          </React.Fragment>
        ) : (
          <StrategySignalWebhookBody
            isEditing={isEditing}
            signalType={signalType}
          />
        )}
      </div>

      {renderErrorMessage()}
    </div>
  )
}

export default StrategySignalSettingsRow
