import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { APP_PATHS } from '@pv3-constants'
import HeroBanner from '@pv3-shared-components/HeroBanner'
import BankSettings from '@pv3-components/BankSettings'
import AccountSettings from '@pv3-components/AccountSettings'
import TaxDocuments from '@pv3-components/TaxDocuments'

const Account = () => {
  return (
    <React.Fragment>
      <HeroBanner
        headline="Account"
        subnav={[
          {
            label: 'Account Settings',
            path: APP_PATHS.ACCOUNT,
          },
          {
            label: 'Bank Settings',
            path: APP_PATHS.ACCOUNT_BANK,
          },
          {
            label: 'Tax Documents',
            path: APP_PATHS.TAX_DOCUMENTS,
          },
        ]}
      />
      <Switch>
        <Route path={APP_PATHS.TAX_DOCUMENTS} component={TaxDocuments} />
        <Route path={APP_PATHS.ACCOUNT_BANK} component={BankSettings} />
        <Route path={APP_PATHS.ACCOUNT} component={AccountSettings} />
      </Switch>
    </React.Fragment>
  )
}

export default Account
