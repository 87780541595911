import axios from 'axios';

export default class Request {
  constructor(useBackofficeUrl, useMarketsUrl) {
    this.config = {
      baseURL: `${window.envConfig.COINLION_API_URL}${useBackofficeUrl ? 'back-api/backoffice/' : (useMarketsUrl ? 'marketdata/' : `frontoffice/api/`)}`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => isRequestSuccessful(status)
    };

    this.request = axios.create(this.config);
  }

  get = (url, config) => this.request.get(url, config);

  post = (url, data, config) => this.request.post(url, data, config);

  patch = (url, data, config) => this.request.patch(url, data, config);

  delete = (url, config) => this.request.delete(url, config);

  put = (url, data, config) => this.request.put(url, data, config);
}

export const isRequestSuccessful = (status) => status >= 200 && status < 300;
