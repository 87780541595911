import './discountsOverview.scss';

import * as React from 'react';
import { useHistory } from "react-router-dom";

import { APP_PATHS } from "../../../../../constants";

import useDocTitle from '../../../../../hooks/useDocTitle';
import { useTypedSelector } from "../../../../../ducks";
import useWindowWidth from '../../../../../hooks/useWindowWidth';

import Button from "../../../../shared/Button";
import Tooltip from '../../../../shared/Tooltip';

const DiscountsOverview: React.FC = () => {
  useDocTitle('Discounts Overview');

  const history = useHistory();

  const isBig = useWindowWidth(1160);

  const { discountsInfo } = useTypedSelector((state) => state.rewards);

  const calculateTier1Discount = () => {
    if(!discountsInfo.standardFeePercent || !discountsInfo.tier1FeePercent) {
      return '--';
    }

    return Math.floor(((discountsInfo.standardFeePercent - discountsInfo.tier1FeePercent) / discountsInfo.standardFeePercent) * 100) + '%';
  };

  const calculateTier2Discount = () => {
    if(!discountsInfo.standardFeePercent || !discountsInfo.tier2FeePercent) {
      return '--';
    }

    return Math.floor(((discountsInfo.standardFeePercent - discountsInfo.tier2FeePercent) / discountsInfo.standardFeePercent) * 100) + '%';
  };

  const calculateTier3Discount = () => {
    if(!discountsInfo.standardFeePercent || !discountsInfo.tier3FeePercent) {
      return '--';
    }

    return Math.floor(((discountsInfo.standardFeePercent - discountsInfo.tier3FeePercent) / discountsInfo.standardFeePercent) * 100) + '%';
  };

  const renderStatus = (type: string) => {
    switch(type) {
      case 'cltb':
        return (
          <div className="DiscountsOverview-FeeRuleStatus">
            <div className={`DiscountsOverview-FeeRuleStatusBadge ${discountsInfo.tokenBalancerEnabled ? 'DiscountsOverview-FeeRuleStatusBadge--active' : ''}`}>
              {discountsInfo.tokenBalancerEnabled ? 'Active' : 'Inactive'}
            </div>

            <div className="DiscountsOverview-FeeRuleStatusLabel">
              Token Balancer is enabled
            </div>
          </div>
        );

      case 'hodl60':
        return (
          <div className="DiscountsOverview-FeeRuleStatus">
            <div className={`DiscountsOverview-FeeRuleStatusBadge ${discountsInfo.has60PercentHodl ? 'DiscountsOverview-FeeRuleStatusBadge--active' : ''}`}>
              {discountsInfo.has60PercentHodl ? 'Active' : 'Inactive'}
            </div>

            <div className="DiscountsOverview-FeeRuleStatusLabel">
              60% of your LION balance is being HODL’d
            </div>
          </div>
        );

        case 'hodl80':
          return (
            <div className="DiscountsOverview-FeeRuleStatus">
              <div className={`DiscountsOverview-FeeRuleStatusBadge ${discountsInfo.has80PercentHodl ? 'DiscountsOverview-FeeRuleStatusBadge--active' : ''}`}>
                {discountsInfo.has80PercentHodl ? 'Active' : 'Inactive'}
              </div>

              <div className="DiscountsOverview-FeeRuleStatusLabel">
                80% of your LION balance is being HODL’d
              </div>
            </div>
          );

      default:
        return null;
    }
  }

  return (
    <div className="Discount-Content Discount-Content--overview">
      <section className="DiscountsOverview-Fees">
        <div className="DiscountsOverview-Fee">
          <div className="DiscountsOverview-FeeTitle">
            STANDARD FEE (starting Feb 1, 2024)
          </div>

          <div className="DiscountsOverview-FeeValue">
            {discountsInfo.standardFeePercent ? `${discountsInfo.standardFeePercent}%` : '--'}
          </div>

          <div className="DiscountsOverview-FeeLabel">
            This equates to a trading fee of ${discountsInfo.standardFeePercent} for every $100 traded

            <Tooltip>
              Tooltip coming soon
            </Tooltip>
          </div>
        </div>

        <div className="DiscountsOverview-Fee">
          <div className="DiscountsOverview-FeeTitle">
            YOUR FEE
          </div>

          <div className="DiscountsOverview-FeeValue">

            {discountsInfo.userFeePercent ? `${discountsInfo.userFeePercent}%` : '--'}
          </div>

          <div className="DiscountsOverview-FeeLabel">
            Your Current Trading Fees

            <Tooltip>
              Tooltip coming soon
            </Tooltip>
          </div>
        </div>
      </section>

      <section className="DiscountsOverview-FeeRulesContainer">
        <h4>
          Discount Fee Rules
        </h4>

        <div className="DiscountsOverview-FeeRules">
          <div className="DiscountsOverview-FeeRule">
            <div className="DiscountsOverview-FeeRuleValueContainer">
              <div className="DiscountsOverview-FeeValue">
                {calculateTier1Discount()}
              </div>

              <div className="DiscountsOverview-FeeLabel">
                Discount
              </div>

              <div className="DiscountsOverview-FeeLabel DiscountsOverview-FeeAmount">
                This equates to a trading fee of ${discountsInfo.tier1FeePercent} for every $100 traded
              </div>
            </div>

            <div className="DiscountsOverview-FeeRuleStatusList">
              {renderStatus('cltb')}
            </div>

            <Button
              onClick={() => history.push(APP_PATHS.REWARDS_CLTB)}
            >
              Go To {isBig ? 'the Token Balancer' : 'CLTB'}
            </Button>
          </div>

          <div className="DiscountsOverview-FeeRule">
            <div className="DiscountsOverview-FeeRuleValueContainer">
              <div className="DiscountsOverview-FeeValue">
                {calculateTier2Discount()}
              </div>

              <div className="DiscountsOverview-FeeLabel">
                Discount
              </div>

              <div className="DiscountsOverview-FeeLabel DiscountsOverview-FeeAmount">
                This equates to a trading fee of ${discountsInfo.tier2FeePercent} for every $100 traded
              </div>
            </div>


            <div className="DiscountsOverview-FeeRuleStatusList">
              {renderStatus('cltb')}

              {renderStatus('hodl60')}
            </div>

            <Button
              onClick={() => history.push(APP_PATHS.REWARDS_HODL_OVERVIEW)}
            >
              Go HODL
            </Button>
          </div>

          <div className="DiscountsOverview-FeeRule">
            <div className="DiscountsOverview-FeeRuleValueContainer">
              <div className="DiscountsOverview-FeeValue">
                {calculateTier3Discount()}
              </div>

              <div className="DiscountsOverview-FeeLabel">
                Discount
              </div>

              <div className="DiscountsOverview-FeeLabel DiscountsOverview-FeeAmount">
                This equates to a trading fee of ${discountsInfo.tier3FeePercent} for every $100 traded
              </div>
            </div>

            <div className="DiscountsOverview-FeeRuleStatusList">

              {renderStatus('cltb')}

              {renderStatus('hodl80')}
            </div>

            <Button
              onClick={() => history.push(APP_PATHS.REWARDS_HODL_OVERVIEW)}
            >
              Go HODL
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DiscountsOverview;
