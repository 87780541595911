import { useDispatch, useSelector } from 'react-redux'
import { fetchAssetsInfo as _fetchAssetsInfo } from '../ducks/assets'

const useAssetsDuck = () => {
  const dispatch = useDispatch()
  const assets = useSelector((state) => state.assets.assets)

  const fetchAssetsInfo = () => dispatch(_fetchAssetsInfo())

  return { assets, fetchAssetsInfo }
}

export default useAssetsDuck;
