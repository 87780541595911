import React, { useState } from 'react'
import Modal from '@pv3-shared-components/Modal'
import { get, round, upperCase } from 'lodash'
import './holdopenpositionmodal.scss'
import Button from '@pv3-shared-components/Button'
import useTradesDuck from '../../hooks/useTradesDuck'

const HoldOpenPositionModal = ({ modal, openPosition }) => {
  const [isLoading, setIsLoading] = useState(false) // eslint-disable-line no-unused-vars
  const { deleteOpenPosition } = useTradesDuck()

  const handleCloseClick = () => {
    modal.setShow(false)
  }

  const handleConfirmClick = async () => {
    setIsLoading(true)
    await deleteOpenPosition(get(openPosition, 'id'))
    setIsLoading(false)
    modal.setShow(false)
  }

  return (
    <Modal
      modal={modal}
      screens={{
        MAIN: {
          heading: <h4>Hold</h4>,
          body: (
            <div>
              <h4>
                {round(get(openPosition, 'openAmount'), 2)}{' '}
                {upperCase(get(openPosition, 'assetId'))} will be held in your
                wallet instead of being sold automatically.
              </h4>
              <p>
                Holding an open position removes it from the auto-trading queue
                and reduces your overall auto-trading balance. Funds will be
                held in your wallet until you manually sell or withdraw them.
              </p>
              <div className="HoldOpenPositionModal-buttonContainer">
                <Button
                  variant="primary"
                  className="HoldOpenPositionModal-button"
                  onClick={handleConfirmClick}
                  size="large"
                >
                  Hold crypto and remove position
                </Button>
                <Button
                  variant="quaternary"
                  className="HoldOpenPositionModal-button"
                  onClick={handleCloseClick}
                  size="large"
                >
                  Nevermind
                </Button>
              </div>
            </div>
          ),
        },
      }}
    />
  )
}

export default HoldOpenPositionModal
