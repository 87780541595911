import { format } from 'date-fns'
import { eq, isUndefined, lte, maxBy } from 'lodash'
import { map } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from 'recharts'
import './closedpositionschart.scss'

const getPath = ({ x, y, width, height }) => {
  if (height > 0) {
    const startingPoint = `M${x - width / 2},${y + 3}`
    const verticalLine1 = `v${height - 3}`
    const horizontalLine1 = `h${width}`
    const verticalLine2 = `v${(height - 3) * -1}`
    const bezierCurve1 = `Q${x + width / 2},${y} ${x + (3 * width) / 8},${y}`
    const horizontalLine2 = `h${(-3 * width) / 4}`
    const bezierCurve2 = `Q${x - width / 2},${y} ${x - width / 2},${y + 3}`

    return `
      ${startingPoint}
      ${verticalLine1}
      ${horizontalLine1}
      ${verticalLine2}
      ${bezierCurve1}
      ${horizontalLine2}
      ${bezierCurve2}
      z
    `
  } else if (height < 0) {
    const startingPoint = `M${x - width / 2},${y - 3}`
    const verticalLine1 = `v${height + 3}`
    const horizontalLine1 = `h${width}`
    const verticalLine2 = `v${(height + 3) * -1}`
    const bezierCurve1 = `Q${x + width / 2},${y} ${x + (3 * width) / 8},${y}`
    const horizontalLine2 = `h${(-3 * width) / 4}`
    const bezierCurve2 = `Q${x - width / 2},${y} ${x - width / 2},${y - 3}`

    return `
      ${startingPoint}
      ${verticalLine1}
      ${horizontalLine1}
      ${verticalLine2}
      ${bezierCurve1}
      ${horizontalLine2}
      ${bezierCurve2}
      z
    `
  }
}

const RoundedRectangle = (props) => {
  if (eq(props.height, 0)) return <React.Fragment />
  return <path d={getPath(props)} fill={props.fill} />
}

const xAxisLabel = (date, chartTimeframe) => {
  if (eq(chartTimeframe, 'day')) {
    return format(new Date(date), 'h:mma')
  } else if (eq(chartTimeframe, 'week')) {
    return format(new Date(date), 'ccc')
  } else {
    return format(new Date(date), 'L/d')
  }
}

const ClosedPostitionsChart = ({ data, timeframe }) => {
  const [chartTimeframe, setChartTimeframe] = useState()
  useEffect(() => {
    setChartTimeframe(timeframe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const dataPoints = map(data, (item) => {
    return {
      ...item,
      datetime: xAxisLabel(item.datetime, chartTimeframe),
    }
  })

  const maxGain = useMemo(() => {
    return maxBy(dataPoints, (dataPoint) => dataPoint.percentChange)
  }, [dataPoints])

  const maxLoss = useMemo(() => {
    return maxBy(dataPoints, (dataPoint) => dataPoint.percentChange * -1)
  }, [dataPoints])

  const showZeroLabel = useMemo(() => {
    const range = maxGain.percentChange - maxGain.percentChange

    if (lte(maxGain.percentChange / range, 0.15)) return false
    if (lte(Math.abs(maxLoss.percentChange) / range, 0.15)) return false

    return true
  }, [maxGain, maxLoss])

  return (
    <div id="ClosedPositionsChart">
      <ResponsiveContainer height={126} width="100%" minWidth={800}>
        <BarChart width={500} height={200} data={dataPoints}>
          <XAxis
            dataKey="datetime"
            domain={['dataMin', 'dataMax']}
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 10, fill: '#4D5257', fontWeight: 'lighter' }}
            interval={
              {
                day: 'preserveStartEnd',
                week: 24,
                month: 96,
              }[chartTimeframe]
            }
            padding={{ left: 15, right: 15 }}
          />
          <YAxis axisLine={false} tick={false} width={40} />
          {!isUndefined(maxGain) && !eq(maxGain.percentChange, 0) && (
            <ReferenceLine
              y={maxGain.percentChange}
              strokeDasharray="4 5"
              stroke="#DDE3E9"
            >
              <Label
                position="left"
                value={`${maxGain.percentChange}%`}
                fill="#4D5257"
                fontSize="10"
                fontWeight="lighter"
              />
            </ReferenceLine>
          )}
          {!isUndefined(maxLoss) && !eq(maxLoss.percentChange, 0) && (
            <ReferenceLine
              y={maxLoss.percentChange}
              strokeDasharray="4 5"
              stroke="#DDE3E9"
            >
              <Label
                position="left"
                value={`${maxLoss.percentChange}%`}
                fill="#4D5257"
                fontSize="10"
                fontWeight="lighter"
              />
            </ReferenceLine>
          )}
          <ReferenceLine y={0} stroke="#DDE3E9">
            {showZeroLabel && (
              <Label
                position="left"
                value={'0%'}
                fill="#4D5257"
                fontSize="10"
                fontWeight="lighter"
              />
            )}
          </ReferenceLine>
          <Bar
            dataKey="percentChange"
            shape={<RoundedRectangle fill="#500078" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ClosedPostitionsChart
