import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  addHours,
  formatISO,
  startOfHour,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import {
  includes,
  toPairs,
  has,
  each,
  eq,
  get as _get,
  isNull,
  isUndefined,
  map,
  reduce,
  round,
  filter,
  find,
} from 'lodash'
import Request from '../utils/request'
import { API_PATHS } from '@pv3-constants'
import { closedPositionsByHour } from '../utils/utils'
import {get} from "lodash/object";
import TokenRequest from '../utils/tokenRequest';

const request = new Request()

export const closeAllPositions = createAsyncThunk(
  'trades/closeAllPositions',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await new TokenRequest(getState().account.idToken).delete(API_PATHS.COIN.CLOSE_ALL_POSITIONS);
    } catch(e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const deleteIncompleteTrade = createAsyncThunk(
  'trades/deleteIncompleteTrade',
  async (orderId, { rejectWithValue }) => {
    try {
      await request.delete(API_PATHS.COIN.DELETE_ORDER(orderId))
      return orderId
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteOrdersForMarketAndSide = createAsyncThunk(
  'trades/deleteOrdersForMarketAndSide',
  async ({marketId, side}, { rejectWithValue }) => {
    try {
      await request.delete(API_PATHS.COIN.DELETE_ORDERS_FOR_MARKET_AND_SIDE(marketId, side))
      return { marketId, side };
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const getIncompleteTrades = createAsyncThunk(
  'trades/getIncompleteTrades',
  async ({ status }, { rejectWithValue }) => {
    try {
      if (!includes(['WORKING', 'CANCELLED', 'ALL'], status)) {
        throw new TypeError(
          "getOrderHistory parameter 'status' must be 'WORKING', 'CANCELLED', or 'ALL'"
        )
      }

      const params = {
        WORKING: { status: 0, IncludePartialFills: false },
        CANCELLED: { status: 2 },
        ALL: (() => {
          let params = new URLSearchParams()
          each([0, 2], (e) => params.append('status', e))
          return params
        })(),
      }[status]

      const response = await request.get(API_PATHS.COIN.TRADE_TOOLS_TRADES, {
        params,
      })

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchClosedPositions = createAsyncThunk(
  'trades/fetchClosedPositions',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const chartTimeframe = _get(
        state,
        'trades.closedPositionsChart.timeframe'
      )
      if (isUndefined(chartTimeframe)) {
        throw new Error({ message: 'Script error' })
      }

      const endDate = startOfHour(new Date())
      let startDate

      if (eq(chartTimeframe, 'day')) {
        startDate = subDays(addHours(endDate, 1), 1)
      } else if (eq(chartTimeframe, 'week')) {
        startDate = subWeeks(addHours(endDate, 1), 1)
      } else {
        startDate = subMonths(addHours(endDate, 1), 1)
      }

      const filters = {
        StartDate: formatISO(startDate),
        EndDate: formatISO(endDate),
        Status: 3,
        IsAutoTrade: true,
      }

      const response = await request.get(
        API_PATHS.COIN.TRADE_HISTORY(`?${new URLSearchParams(filters)}`)
      )

      const closedPositions = filter(
        response.data,
        ({ side, completed }) => completed && eq(side, 1)
      )

      const totalGainLoss = reduce(
        closedPositions,
        (acc, closedPosition) => acc + closedPosition.gainLoss,
        0
      )

      const cpsByHour = closedPositionsByHour(response.data, startDate, endDate)

      const hourlyData = map(cpsByHour, (interval) => {
        if (eq(interval.closedPositions.length, 0)) {
          return {
            datetime: formatISO(interval.datetime),
            percentChange: 0,
          }
        }

        const totalChange = reduce(
          interval.closedPositions,
          (acc, closedPosition) => {
            return acc + closedPosition.gainLoss
          },
          0
        )

        const totalOpenValue = reduce(
          interval.closedPositions,
          (acc, closedPosition) => {
            if (isNull(closedPosition.position)) return acc
            return (
              acc +
              closedPosition.position.openAmount *
                closedPosition.position.openPrice
            )
          },
          0
        )

        return {
          datetime: formatISO(interval.datetime),
          percentChange: round((100 * totalChange) / totalOpenValue, 1),
        }
      })

      return {
        totalGainLoss,
        hourlyData,
        closedPositionCount: closedPositions.length,
      }
    } catch (e) {
      console.log(e)
      return rejectWithValue(e.message)
    }
  }
)

export const getLionSellLimit = createAsyncThunk(
  'trades/getLionSellLimit',
  async (marketId, { getState, rejectWithValue }) => {
    try {
      let res = await new TokenRequest(getState().account.idToken).get(API_PATHS.COIN.LION_SELL_LIMIT);
      return res.data;
    } catch(e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const getTradeHistoryFromFilter = createAsyncThunk(
  'trades/getTradeHistory',
  async ({ filters }, { rejectWithValue, getState }) => {
    let params = new URLSearchParams()

    if (has(filters, 'Markets')) {
      each(filters['Markets'], (market) => {
        params.append('Market', market.value)
      })
    }

    if (has(filters, 'Statuses')) {
      each(filters['Statuses'], (status) => {
        params.append('Status', status)
      })
    }

    each(toPairs(filters), (pair) => {
      if (eq(pair[0], 'Markets') || eq(pair[0], 'Statuses')) return
      params.append(pair[0], pair[1])
    })

    try {
      const tradeHistoryResponse = await request.get(
        API_PATHS.COIN.TRADE_HISTORY(),
        {
          params,
        }
      )

      return tradeHistoryResponse.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.message)
    }
  }
)

export const getOpenPositions = createAsyncThunk(
  'trades/getOpenPositions',
  async (hideSpinner, { rejectWithValue }) => {
    try {
      const response = await request.get(API_PATHS.COIN.OPEN_POSITIONS)
      return response.data
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const liquidateAssets = createAsyncThunk(
  'trades/liquidateAssets',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await new TokenRequest(getState().account.idToken).post(API_PATHS.COIN.LIQUIDATE_ASSETS);
    } catch(e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const deleteOpenPosition = createAsyncThunk(
  'trades/deleteOpenPosition',
  async (id, { rejectWithValue }) => {
    try {
      await request.delete(API_PATHS.COIN.DELETE_OPEN_POSITION(id))
      return id
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const sellAllPositions = createAsyncThunk(
  'trades/sellAllPositions',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await new TokenRequest(getState().account.idToken).post(API_PATHS.COIN.SELL_ALL_POSITIONS);
    } catch(e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const sellPosition = createAsyncThunk(
  'trades/sellPosition',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      await new TokenRequest(getState().account.idToken).post(API_PATHS.COIN.SELL_POSITION(id))
      dispatch(getOpenPositions())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    historyFromFilter: [],
    closedPositionsChart: {
      hourlyData: [],
      timeframe: 'week',
      totalGainLoss: 0,
      closedPositionCount: 0,
    },
    openPositions: null,
    incompleteTrades: [],
    openOrders: [],
  },
  reducers: {
    removeIncompleteTrade: (state, action) => {
      state.incompleteTrades = filter(
        state.incompleteTrades,
        (t) => !eq(t.id, action.payload)
      )
    },
    setClosedPositionsChartTimeframe: (state, action) => {
      state.closedPositionsChart.timeframe = action.payload
    },
    setOpenPositions: (state, action) => {
      state.openPositions = map(state.openPositions, (op) => {
        const updates = find(action.payload, ({ positionUpdate }) =>
          eq(positionUpdate.positionId, op.position.id)
        )

        if (isUndefined(updates)) {
          return op
        } else if(get(updates, 'positionUpdate.isClosed') === true) {
          return null
        }

        return {
          ...op,
          orders: updates.orders,
          position: {
            ...op.position,
            ...updates.positionUpdate,
          },
        }
      })
    },
    updateOpenOrders: (state, action) => {
      const updatedOpenOrders = map(state.openOrders, (openOrder) => {
        const update = find(action.payload, ({ orderId }) =>
          eq(orderId, openOrder.orderId)
        )
        return isUndefined(update) ? openOrder : update
      })

      state.openOrders = [
        ...filter(action.payload, (openOrder) => {
          const existingOpenOrder = find(state.openOrders, ({ orderId }) =>
            eq(orderId, openOrder.orderId)
          )
          return isUndefined(existingOpenOrder)
        }),
        ...filter(updatedOpenOrders, ({ isPending }) => isPending),
      ]
    },
  },
  extraReducers: {
    [fetchClosedPositions.fulfilled]: (state, action) => {
      state.closedPositionsChart.totalGainLoss = action.payload.totalGainLoss
      state.closedPositionsChart.hourlyData = action.payload.hourlyData
      state.closedPositionsChart.closedPositionCount =
        action.payload.closedPositionCount
    },
    [getTradeHistoryFromFilter.fulfilled]: (state, action) => {
      state.historyFromFilter = action.payload
    },
    [getOpenPositions.fulfilled]: (state, action) => {
      state.openPositions = action.payload
    },
    [deleteOpenPosition.fulfilled]: (state, action) => {
      state.openPositions = filter(
        state.openPositions,
        (op) => !eq(op.position.id, action.payload)
      )
    },
    [getIncompleteTrades.fulfilled]: (state, action) => {
      state.incompleteTrades = action.payload
    },
    [getIncompleteTrades.rejected]: (state, action) => {
      // TODO: HANDLE REJECTION
    },
    [getLionSellLimit.fulfilled]: (state, action) => {
      state.lionSellLimit = action.payload;
    },
    [deleteIncompleteTrade.fulfilled]: (state, action) => {
      state.incompleteTrades = filter(
        state.incompleteTrades,
        (it) => !eq(it.orderId, action.payload)
      )

      if (!isNull(state.openPositions)) {
        state.openPositions = map(state.openPositions, (op) => {
          if (eq(op.orders.length, 0)) {
            return op
          } else {
            return {
              ...op,
              orders: filter(
                op.orders,
                (order) => !eq(order.orderId, action.payload)
              ),
            }
          }
        })
      }
    },
  },
})

export const {
  setClosedPositionsChartTimeframe,
  removeIncompleteTrade,
  setOpenPositions,
  updateOpenOrders,
} = tradesSlice.actions
export default tradesSlice.reducer
