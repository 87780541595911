import React from 'react'

const ExclamationTriangle = ({ fill = '#FCAB10', ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8501 14.1881L11.4389 4.22148C11.1471 3.6853 10.5855 3.35156 9.97505 3.35156C9.3646 3.35156 8.80299 3.6853 8.51116 4.22148L3.0945 14.1881C2.80599 14.706 2.81426 15.3381 3.11622 15.8483C3.41818 16.3584 3.96838 16.6697 4.56116 16.6659H15.3834C15.9711 16.6665 16.5157 16.3574 16.8166 15.8525C17.1175 15.3476 17.1302 14.7215 16.8501 14.2048V14.1881ZM9.1445 7.07704C9.1445 6.6168 9.51759 6.2437 9.97783 6.2437C10.4381 6.2437 10.8112 6.6168 10.8112 7.07704V10.9048C10.8112 11.3651 10.4381 11.7381 9.97783 11.7381C9.51759 11.7381 9.1445 11.3651 9.1445 10.9048V7.07704ZM9.0445 13.627C9.0445 14.1548 9.47231 14.5826 10.0001 14.5826C10.5278 14.5826 10.9556 14.1548 10.9556 13.627C10.9556 13.0993 10.5278 12.6715 10.0001 12.6715C9.47231 12.6715 9.0445 13.0993 9.0445 13.627Z"
        fill={fill}
      />
    </svg>
  )
}

export default ExclamationTriangle
