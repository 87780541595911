import * as React from 'react';
import { useState } from 'react';
import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from "../shared/Button";
import useMarketplaceDuck from "../../hooks/useMarketplaceDuck";
import useTradesDuck from "../../hooks/useTradesDuck";
import Modal from "../shared/Modal";
import useModal from "../../hooks/useModal";

const AdminTools: React.FC = () => {
  const { closeAllPositions, liquidateAssets, sellAllPositions } = useTradesDuck();
  const { unsubscribeFromAllPublishers } = useMarketplaceDuck();
  const successModal = useModal();

  const [activeAction, setActiveAction] = useState<'convertAssets' | 'deletePositions' | 'disableLion' | 'disablePublishers' | 'sellAll' | undefined>();

  const handleConvertAllAssetsToHomeQuoteCurrency = async () => {
    try {
      setActiveAction('convertAssets');
      await liquidateAssets();
      successModal.setShow(true);
    } catch(err) {
      console.log('AdminTools handleConvertAllAssetsToHomeQuoteCurrency error', err);
    } finally {
      setActiveAction(undefined);
    }
  };

  const handleDeleteAllOpenPositions = async () => {
    try {
      setActiveAction('deletePositions');
      await closeAllPositions();
      successModal.setShow(true);
    } catch(err) {
      console.log('AdminTools handleDeleteAllOpenPositions error', err);
    } finally {
      setActiveAction(undefined);
    }
  };

  const handleDisableLionFunctionality = async () => {
    try {
      setActiveAction('disableLion');
    } catch(err) {
      console.log('AdminTools handleDisableLionFunctionality error', err);
    } finally {
      setActiveAction(undefined);
    }
  };

  const handleDisablePublishersAndStrategies = async () => {
    try {
      setActiveAction('disablePublishers');
      await unsubscribeFromAllPublishers();
      successModal.setShow(true);
    } catch(err) {
      console.log('AdminTools handleDisablePublishersAndStrategies error', err);
    } finally {
      setActiveAction(undefined);
    }
  };

  const handleSellAllOpenPositions = async () => {
    try {
      setActiveAction('sellAll');
      await sellAllPositions();
      successModal.setShow(true);
    } catch(err) {
      console.log('AdminTools handleSellAllOpenPositions error', err);
    } finally {
      setActiveAction(undefined);
    }
  };

  return (
    <>
      <section
        className="Fieldset AcctSettings-fieldset AcctSettings-fieldset--profile AcctSettings-fieldset--adminTools">
        <div>
          <h1 className="Legend AcctSettings-legend">
            Admin Tools
          </h1>

          <Button
            disabled={activeAction !== undefined}
            isLoading={activeAction === 'disablePublishers'}
            onClick={handleDisablePublishersAndStrategies}
          >
            Disable Publishers & Strategies
          </Button>

          <Button
            disabled={activeAction !== undefined}
            isLoading={activeAction === 'sellAll'}
            onClick={handleSellAllOpenPositions}
          >
            Sell all Open Positions
          </Button>

          <Button
            disabled={activeAction !== undefined}
            isLoading={activeAction === 'deletePositions'}
            onClick={handleDeleteAllOpenPositions}
          >
            Delete all Open Positions
          </Button>

          <Button
            disabled={activeAction !== undefined}
            isLoading={activeAction === 'convertAssets'}
            onClick={handleConvertAllAssetsToHomeQuoteCurrency}
          >
            Convert all assets to Home Quote Currency
          </Button>

          <Button
            disabled={activeAction !== undefined || true}
            isLoading={activeAction === 'disableLion'}
            onClick={handleDisableLionFunctionality}
          >
            Disable LION Functionality
          </Button>
        </div>
      </section>

      <Modal
        modal={successModal}
        screens={{
          MAIN: {
            heading: () => <h4>Success!</h4>,
            body: () => (
              <div className="AdminTools--successModal">
                <p>This action has been completed</p>
                <Button
                  variant={ButtonVariants.Primary}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  onClick={() => successModal.setShow(false)}
                >
                  OK
                </Button>
              </div>
            ),
          },
        }}
      />
    </>
  );
};

export default AdminTools;
