export enum FacialVerificationResults {
  Green = 'GREEN',
  Red = 'RED',
}

export enum KycVerificationLevels {
  Business = 'basic-kyb-level',
  FacialVerification = 'facial-auth',
  Individual = 'basic-kyc-level',
  IndividualInternational = 'basic-kyc-level-nonUS',
  ProofOfAddress = 'poa',
  ProofOfAddressBusiness = 'KYB-POA',
}

export enum KycVerificationStatuses {
  NotFound = 0,
  Init = 1,
  Pending = 2,
  Failed = 3,   // RED
  Verified = 4, // GREEN
}

export enum PermissionReasons {
  Onboarding = 'ONBOARDING',
  Region = 'REGION',
}

export enum ProfileTypes {
  Individual = 'Individual',
  Business = 'Business',
}

export enum OrderSides {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum OrderTypes {
  Limit = 0,
  Market = 1,
}

export enum ReferralRewardActionSubPrograms {
  ExistingUser = 'ExistingUser',
  ReferreeAction = 'ReferreeAction',
}
