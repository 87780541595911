import React, { useEffect, useMemo, useState } from 'react'
import Button from '@pv3-shared-components/Button'
import { PROFILE_TYPES, APP_PATHS } from '@pv3-constants'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import './accountsettings.scss'
import {
  eq,
  isNil,
  isNull,
  lt,
  pull,
  includes,
  toUpper, isEmpty, find, isUndefined, get as _get, clone,
} from 'lodash'
import Icon from '@pv3-shared-components/Icons'
import Modal from '@pv3-shared-components/Modal'
import useModal from '@pv3-hooks/useModal'
import useAccount from '@pv3-hooks/useAccount'
import useDocTitle from '@pv3-hooks/useDocTitle'
import FieldsPersonal from './FieldsPersonal'
import FieldsBusiness from './FieldsBusiness'
import Select from '@pv3-shared-components/Select'
import Input from '@pv3-shared-components/Input'
import InputError from '@pv3-shared-components/InputError'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { differenceInMinutes, parseISO } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { API_PATHS } from '@pv3-constants'
import Request from '../../utils/request'
import {KYC_VERIFICATION_STATUSES} from "../../constants";
import PublicProfile from "./PublicProfile";
import Preferences from "./Preferences";
import useWalletsDuck from "../../hooks/useWalletsDuck";
import {isObjectNullOrEmpty} from "../../utils/utils";
import Flash from "../shared/Flash";
import { getReferredBy, saveReferralCode } from '../../ducks/rewards';
import { useAppDispatch } from '../../ducks';
import useStrategiesDuck from '../../hooks/useStrategiesDuck';
import Loading from '../shared/Loading';
import { ButtonTypes, ButtonVariants } from '../shared/Button';
import CreateAPIKeyModal from '../shared/CreateAPIKeyModal';
import CycleAPISecretModal from '../shared/CycleAPISecretModal';
import { CopyToClipboard } from "react-copy-to-clipboard";
import AdminTools from './AdminTools';
import ATTEmailSettings from './ATTEmailSettings';

const { get } = new Request()

const AccountSettings = () => {
  useDocTitle('Account Settings')
  const modal = useModal()
  const createApiKeyModal = useModal();
  const cycleApiSecretModal = useModal();
  const convertModal = useModal();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    assetsForMigration,
    convertAssets,
    getAddresses,
    getProfile,
    profile,
    profileType,
    profileCountryCode,
    setAssetsForMigration,
    setHomeQuoteAsset,
    getUsdAllowed,
    setProfileTin,
  } = useProfileDuck()
  const { balances } = useWalletsDuck();
  const { signOut } = useAccount()
  const { apiKey, getApiKey, isGettingApiKey } = useStrategiesDuck();
  const [hasConvertError, setHasConvertError] = useState(false);
  const [isEditingHomeQuoteAsset, setIsEditingHomeQuoteAsset] = useState(false)
  const [isSubmittingQuoteAsset, setIsSubmittingQuoteAsset] = useState(false)
  const [isConvertingAssets, setIsConvertingAssets] = useState(false);
  const [isEditingTin, setIsEditingTin] = useState(false)
  const [isSubmittingTin, setIsSubmittingTin] = useState(false)
  const [isAddingReferralCode, setIsAddingReferralCode] = useState(false);
  const [isSubmittingReferralCode, setIsSubmittingReferralCode] = useState(false);
  const [statePermissions, setStatePermissions] = useState([])
  const [homeQuoteAssetOptions, setHomeQuoteAssetOptions] = useState([
    { label: 'USDC', value: 'usdc' },
    { label: 'USDT', value: 'usdt' },
  ])
  const [showCopiedKeyToClipboard, setShowCopiedKeyToClipboard] = useState(false);

  const { referredBy } = useSelector((state) => state.rewards);

  useEffect(() => {
    if(assetsForMigration && profile.homeQuoteAsset === assetsForMigration.newAsset && balances[assetsForMigration.oldAsset] > 0) {
      convertModal.setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsForMigration, profile.homeQuoteAsset])

  const usdAllowed = getUsdAllowed();

  const homeQuoteBaseOptions = [
    { label: 'USDC', value: 'usdc' },
    { label: 'USDT', value: 'usdt' },
  ]

  const lastProfileRetrieval = useSelector((state) =>
    _get(state, 'profile.lastProfileRetrieval')
  )

  const verificationStatus = useMemo(
    () => {
      let kycVerificationStatus = _get(profile, 'kycVerificationStatus');

      switch(kycVerificationStatus) {
        case KYC_VERIFICATION_STATUSES.PENDING:
          return [<div class="AcctSettings-status-red" > Pending </div>, kycVerificationStatus];

        case KYC_VERIFICATION_STATUSES.FAILED:
          return [<div class="AcctSettings-status-red"> Failed </div>, kycVerificationStatus];

        case KYC_VERIFICATION_STATUSES.VERIFIED:
          return [<div class="AcctSettings-status-green"> Verified </div>, kycVerificationStatus];

        default:
          return [<div class="AcctSettings-status-red"> Not Started </div>, kycVerificationStatus];
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.kycVerificationStatus]
  );

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm()

  const homeQuoteCanUsd = (country, region = null) => {
    if (eq(country, 'US') && !isEmpty(statePermissions)) {
      // Check if user can use USD
      const usState = find(statePermissions, ({ uspsId }) => eq(uspsId, region))
      if (!isUndefined(usState) && _get(usState, 'usdAllowed')) {
        if (!homeQuoteAssetOptions.some((option) => eq(option.value, 'usd'))) {
          setHomeQuoteAssetOptions([]);
          const homeQuoteAssetOptionsWithUsd = clone(homeQuoteBaseOptions)
          homeQuoteAssetOptionsWithUsd.unshift({ label: 'USD', value: 'usd' })
          setTimeout(() => {
            setHomeQuoteAssetOptions(homeQuoteAssetOptionsWithUsd)
          }, 0);
        }
      }
    }
  }

  const handleConvertAssets = async () => {
    try {
      setIsConvertingAssets(true);
      setHasConvertError(false);
      let res = await convertAssets({
        debitAsset: assetsForMigration.oldAsset,
        creditAsset: assetsForMigration.newAsset,
      });
      if(!res.error) {
        convertModal.setShow(false);
      } else {
        setHasConvertError(true);
      }
    } catch(err) {
      console.log('handleConvertAssets', err);
      setHasConvertError(true);
    } finally {
      setIsConvertingAssets(false);
    }
  }


  const handleFormSubmitQuoteAsset = async (data, e) => {
    e.preventDefault()

    try {
      setIsSubmittingQuoteAsset(true)
      const hqa = {oldAsset: _get(profile, 'homeQuoteAsset'), newAsset: _get(data, 'homeQuoteAsset')}
      setAssetsForMigration(hqa);
      await setHomeQuoteAsset(_get(data, 'homeQuoteAsset'))
      setIsEditingHomeQuoteAsset(false)
    } catch(err) {
      console.log(err);
    } finally {
      setIsSubmittingQuoteAsset(false);
    }
  }

  const handleFormSubmitReferralCode = async ({ referralCode }, e) => {
    e.preventDefault();

    try {
      setIsSubmittingReferralCode(true);
      await dispatch(saveReferralCode({ referralCode })).unwrap();
      await dispatch(getReferredBy()).unwrap();
      setIsAddingReferralCode(false);
    } catch(err) {
      console.log(err);
    } finally {
      setIsSubmittingReferralCode(false);
    }
  };

  const handleFormSubmitTin = async (data, e) => {
    e.preventDefault()

    try {
      setIsSubmittingTin(true)

      const tin =
        eq(profileType, PROFILE_TYPES.INDIVIDUAL) &&
        eq(profileCountryCode, 'US')
          ? _get(data, 'ssn')
          : _get(data, 'taxId')
      await setProfileTin(tin)

      dispatch(getProfile())

      setIsEditingTin(false)
    } finally {
      setIsSubmittingTin(false)
    }
  }

  useEffect(() => {
    if (
      usdAllowed &&
      !includes(homeQuoteAssetOptions, { label: 'USD', value: 'usd' })
    ) {
      homeQuoteAssetOptions.push({ label: 'USD', value: 'usd' })
      setHomeQuoteAssetOptions(homeQuoteAssetOptions)
      return
    }

    if (
      !usdAllowed &&
      includes(homeQuoteAssetOptions, { label: 'USD', value: 'usd' })
    ) {
      pull(homeQuoteAssetOptions, { label: 'USD', value: 'usd' })
      setHomeQuoteAssetOptions(homeQuoteAssetOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdAllowed])

  useEffect(() => {
    // Grab fresh profile, in case any tier levels have been updated
    getAddresses();
    const lprDate = parseISO(lastProfileRetrieval)
    dispatch(getReferredBy());
    getApiKey();
    if (lt(differenceInMinutes(new Date(), lprDate), 1)) return
    getProfile();

    return () => {
      setAssetsForMigration(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isNil(profileCountryCode) && !isEmpty(profileCountryCode)) {
      const stateProvinceCode =
        _get(profile, 'state')
      if (!isUndefined(stateProvinceCode)) {
        homeQuoteCanUsd(profileCountryCode, stateProvinceCode)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePermissions])

  useEffect(() => {
    ;(async function getStatePermissions() {
      try {
        const { data: usStates } = await get(API_PATHS.COIN.STATES)

        setStatePermissions(usStates)
      } catch (error) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="Content Content--acctSettings">
      <div className="Content--leftCol">
        <section className="Fieldset AcctSettings-fieldset AcctSettings-fieldset--profile">
          <div>
            <h1 className="Legend AcctSettings-legend">
              {eq(profileType, PROFILE_TYPES.BUSINESS) ? (
                _get(profile, 'companyName')
              ) : (
                <React.Fragment>
                  {_get(profile, 'firstName')}{' '}
                  {_get(profile, 'middleName')}{' '}
                  {_get(profile, 'lastName')}
                </React.Fragment>
              )}
            </h1>
            <dl className="AcctSettings-list">
              <dt
                className="AcctSettings-list-verification"
              >
                Identity Verification:

                {verificationStatus[0]}
              </dt>
              {(
                  _get(profile, 'kycVerificationStatus') < 2 ||
                  _get(profile, 'kycVerificationStatus') === 3
                ) && (
                  <dt>
                    <Button
                      className="AcctSettings-list-verification-verify"
                      onClick={() => history.push(eq(profileType, PROFILE_TYPES.BUSINESS) ? APP_PATHS.ONBOARDING_BUSINESS_INFO : APP_PATHS.ONBOARDING_INFO)}
                      variant="secondary"
                    >
                      Verify now
                    </Button>
                  </dt>
                )}

              <dd className="u-a11yOnly">
                {verificationStatus[1]}
              </dd>

              <dt>Email</dt>
              <dd>{_get(profile, 'email')}</dd>
              {eq(profileType, PROFILE_TYPES.BUSINESS) && (
                <React.Fragment>
                  <dt>
                    {isEditingTin
                      ? 'Tax ID number'
                      : 'Last four of tax ID number'}
                    {!isEditingTin && (
                      <button
                        className="u-buttonReset AcctSettings-editSingle"
                        title="Edit tax ID number"
                        type="button"
                        onClick={() => setIsEditingTin(!isEditingTin)}
                      >
                        <span className="u-a11yOnly">Edit tax ID number</span>
                        <Icon name="pencilfilled" />
                      </button>
                    )}
                  </dt>
                  <dd>
                    {isEditingTin ? (
                      <form onSubmit={handleSubmit(handleFormSubmitTin)}>
                        <Input
                          type="text"
                          autoComplete="off"
                          register={{
                            ...register('taxId', {
                              required: 'Tax ID number is required',
                            }),
                          }}
                          error={
                            errors.taxId && (
                              <InputError message={errors.taxId.message} />
                            )
                          }
                        />
                        <div className="AcctSettings-list-updateButtons">
                          <Button
                            variant="primary"
                            size="small"
                            type="submit"
                            isLoading={isSubmittingTin}
                          >
                            Save
                          </Button>
                          <Button
                            variant="secondary"
                            size="small"
                            type="button"
                            onClick={() => setIsEditingTin(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    ) : !isNull(_get(profile, 'taxIdentificationLastFour')) ? (
                      _get(profile, 'taxIdentificationLastFour')
                    ) : (
                      '--'
                    )}
                  </dd>
                </React.Fragment>
              )}

              {eq(profileType, PROFILE_TYPES.INDIVIDUAL) &&
                eq(profileCountryCode, 'US') && (
                  <React.Fragment>
                    <dt>
                      Social Security Number
                      {!isEditingTin && (
                        <button
                          className="u-buttonReset AcctSettings-editSingle"
                          title="Edit social security number"
                          type="button"
                          onClick={() => setIsEditingTin(!isEditingTin)}
                        >
                          <span className="u-a11yOnly">
                            Edit social security number
                          </span>
                          <Icon name="pencilfilled" />
                        </button>
                      )}
                    </dt>
                    <dd>
                      {isEditingTin ? (
                        <form onSubmit={handleSubmit(handleFormSubmitTin)}>
                          <Input
                            type="text"
                            autoComplete="off"
                            maxLength="11"
                            inputMode="numeric"
                            register={{
                              ...register('ssn', {
                                required: 'Social Security number is required',
                                validate: (value) => {
                                  const ssnRegEx =
                                    /^(?!0{3})(?!6{3})[0-8]\d{2}-?(?!0{2})\d{2}-?(?!0{4})\d{4}$/
                                  return (
                                    ssnRegEx.test(value) ||
                                    'Please enter a valid Social Security number'
                                  )
                                },
                              }),
                            }}
                            error={
                              errors.ssn && (
                                <InputError message={errors.ssn.message} />
                              )
                            }
                          />
                          <div className="AcctSettings-list-updateButtons">
                            <Button
                              variant="primary"
                              size="small"
                              type="submit"
                              isLoading={isSubmittingTin}
                            >
                              Save
                            </Button>
                            <Button
                              variant="secondary"
                              size="small"
                              type="button"
                              onClick={() => setIsEditingTin(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </form>
                      ) : !isNil(_get(profile, 'taxIdentificationLastFour')) ? (
                        '###-##-' + _get(profile, 'taxIdentificationLastFour')
                      ) : (
                        '--'
                      )}
                    </dd>
                  </React.Fragment>
                )}
              <dt>
                Home Quote Currency
                {!isEditingHomeQuoteAsset && (
                  <button
                    className="u-buttonReset AcctSettings-editSingle"
                    title="Edit home quote currency"
                    type="button"
                    onClick={() =>
                      setIsEditingHomeQuoteAsset(!isEditingHomeQuoteAsset)
                    }
                  >
                    <span className="u-a11yOnly">Edit home quote currency</span>
                    <Icon name="pencilfilled" />
                  </button>
                )}
              </dt>
              <dd>
                {isEditingHomeQuoteAsset ? (
                  <form onSubmit={handleSubmit(handleFormSubmitQuoteAsset)}>
                    <Select
                      ariaLabel="Select home quote currency"
                      options={homeQuoteAssetOptions}
                      defaultValue={_get(profile, 'homeQuoteAsset')}
                      register={{
                        ...register('homeQuoteAsset', {
                          required: 'Home quote currency is required',
                        }),
                      }}
                    />
                    {errors.homeQuoteAsset && (
                      <InputError message={errors.homeQuoteAsset.message} />
                    )}
                    <div className="AcctSettings-list-updateButtons">
                      <Button
                        variant="primary"
                        size="small"
                        type="submit"
                        isLoading={isSubmittingQuoteAsset}
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        size="small"
                        type="button"
                        onClick={() => setIsEditingHomeQuoteAsset(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                ) : !isNil(_get(profile, 'homeQuoteAsset')) ? (
                  toUpper(_get(profile, 'homeQuoteAsset'))
                ) : (
                  '--'
                )}
              </dd>

              {verificationStatus[1] === KYC_VERIFICATION_STATUSES.VERIFIED && (
                <>
                  <dt>
                    My Referral Code
                  </dt>

                  <dd>
                    {profile.referralCode}
                  </dd>

                  <dt>
                    My API Key
                  </dt>

                  <dd>
                    {isGettingApiKey ? (
                      <Loading />
                    ) : (
                      <>
                        {apiKey ? (
                          <>
                            {apiKey}

                            <CopyToClipboard
                              text={apiKey}
                              options={{format: 'text/plain'}}
                              onCopy={() => {
                                setShowCopiedKeyToClipboard(true)
                                setTimeout(() => setShowCopiedKeyToClipboard(false), 2000)
                              }}
                            >
                              <Button variant={ButtonVariants.Quinary}
                                      className="WireTransfer-copyButton Webhook-copyButton" type={ButtonTypes.Button}>
                                <Icon name="copy"/>
                              </Button>
                            </CopyToClipboard>

                            <div className="DepositCrypto-copiedToClipboardAlertContainer">
                              <div
                                className={`WireTransfer-copiedToClipboardAlert ${
                                  showCopiedKeyToClipboard ? 'visible' : ''
                                }`}
                              >
                                <Flash
                                  className="Flash--success"
                                  hideCloseButton
                                  iconSize={20}
                                  iconFill="#00854d"
                                >
                                  API key copied
                                </Flash>
                              </div>
                            </div>

                            <p className="webhook-warning" data-testid="webhook-security-warning-text-1">
                              For security reasons, we don't allow you to view your API secret. If you no longer have access to it saved in a secure location, <span className="c-link" onClick={() => cycleApiSecretModal.setShow(true)} role="button">click here</span> to create a new one. WARNING: This action will disable all existing webhooks.
                            </p>
                          </>
                        ) : (
                          <>
                            No API key. <span className="c-link" onClick={() => createApiKeyModal.setShow(true)}
                                              role="button">Create one now?</span>
                          </>
                        )}
                      </>
                    )}
                  </dd>
                </>
              )}

              {new Date(profile.createdAt) >= new Date('2023-12-28') && (
                <>
                  <dt>
                    Referred By
                  </dt>

                  <dd>
                  {referredBy?.referredByCode && referredBy?.referredByName ? (
                      <>
                        {referredBy.referredByName} ({referredBy.referredByCode})
                      </>
                    ) : isAddingReferralCode ? (
                      <form onSubmit={handleSubmit(handleFormSubmitReferralCode)}>
                        <Input
                          type="text"
                          autoComplete="off"
                          inputMode="numeric"
                          placeholder="Referral Code"
                          register={{
                            ...register('referralCode', {
                              required: 'Referral code is required',
                            }),
                          }}
                          error={
                            errors.ssn && (
                              <InputError message={errors.referralCode.message} />
                            )
                          }
                        />
                        <div className="AcctSettings-list-updateButtons">
                          <Button
                            variant="primary"
                            size="small"
                            type="submit"
                            isLoading={isSubmittingReferralCode}
                          >
                            Save
                          </Button>
                          <Button
                            disabled={isSubmittingReferralCode}
                            variant="secondary"
                            size="small"
                            type="button"
                            onClick={() => setIsAddingReferralCode(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                      ) : (
                        <Button
                          variant="primary"
                          size="small"
                          type="button"
                          onClick={() => {
                            setIsAddingReferralCode(true);
                          }}
                        >
                          Add Code
                        </Button>
                      )}
                  </dd>
                </>
              )}
            </dl>
          </div>
          <div className="AcctSettings-ActionButtons">
            <a
              className="Button  Button--secondary Button--small"
              href="https://help.coinlion.com/how-to-change-your-email-associated-with-your-coinlion-account"
              target="_blank"
              rel="noopener noreferrer"
            >
              Change email
            </a>
            <Button
              variant="secondary"
              size="small"
              type="button"
              onClick={() => modal.setShow(true)}
            >
              Reset password
            </Button>
          </div>
        </section>
        {eq(profileType, PROFILE_TYPES.BUSINESS) ? (
          <FieldsBusiness />
        ) : (
          <FieldsPersonal />
        )}

        <Preferences />
      </div>

      <div className="Content--rightCol">
        <PublicProfile />

        <ATTEmailSettings />

        <AdminTools />
      </div>

      <Modal
        modal={modal}
        screens={{
          MAIN: {
            heading: () => <h4>Reset Password</h4>,
            body: () => (
              <React.Fragment>
                <p>
                  To reset your password, log out and click on the{' '}
                  <strong>Forgot password?</strong> link.
                </p>
                <Button
                  className="ResetPassword--logoutButton"
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={() => signOut()}
                >
                  Log Out
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />

      <Modal
        modal={convertModal}
        screens={{
          MAIN: {
            heading: () => <h4>Convert Assets</h4>,
            body: () => (
              <div className="Content--acctSettings--convertModal">
                {!isObjectNullOrEmpty(assetsForMigration) && (
                  <p>
                    Do you also want to convert your {assetsForMigration.oldAsset.toUpperCase()} balance to {assetsForMigration.newAsset.toUpperCase()}?
                  </p>
                )}

                {hasConvertError && (
                  <Flash
                    danger
                  >
                    We encountered an error converting the assets, please try again later. If the error persists, please contact support.
                  </Flash>
                )}

                <Button
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={handleConvertAssets}
                  isLoading={isConvertingAssets}
                >
                  Yes
                </Button>

                <Button
                  disabled={isConvertingAssets}
                  variant="secondary"
                  size="large"
                  type="button"
                  onClick={() => {
                    convertModal.setShow(false);
                    setAssetsForMigration(null);
                    setHasConvertError(false)
                  }}
                >
                  No
                </Button>
              </div>
            ),
          },
        }}
      />

      <CreateAPIKeyModal modal={createApiKeyModal} />
      <CycleAPISecretModal modal={cycleApiSecretModal} />
    </div>
  )
}

export default AccountSettings
