import { find, isArray, isUndefined } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoading as uiSetLoading,
  pushFlash as _pushFlash,
  setShowCtaCard as _setShowCtaCard,
} from '@pv3-ducks/ui'

const useUiDuck = () => {
  const dispatch = useDispatch()
  const { loading, flashes, showCtaCards } = useSelector((state) => state.ui)

  const setLoading = ({ context, isLoading }) =>
    dispatch(uiSetLoading({ context, isLoading }))

  const subscribeToLoadingStates = (loadingState) => {
    if (isArray(loadingState)) {
      return !isUndefined(
        find(loadingState, (loadingStateItem) => loading[loadingStateItem])
      )
    } else {
      return loading[loadingState]
    }
  }

  const pushFlash = ({ id }) => {
    if (find(flashes, { id: id })) return
    dispatch(_pushFlash({ id }))
  }

  const setShowCtaCard = (config) => {
    dispatch(_setShowCtaCard(config))
  }

  return {
    loading,
    flashes,
    showCtaCards,
    setLoading,
    subscribeToLoadingStates,
    pushFlash,
    setShowCtaCard,
  }
}

export default useUiDuck
