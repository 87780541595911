import './FileUpload.scss';

import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import Button from "../Button";
import Icon from "../Icons";
import ProgressBar from "../ProgressBar";
import heic2any from "heic2any";
import {resizeImage} from "../../../utils/utils";

const FileUpload = ({
                      buttonText,
                      disabled,
                      hideProgressBar,
                      imageUrl,
                      isUploading,
                      name,
                      onDelete,
                      onDrop,
                      preview,
                      progress,
                      secondaryText,
                      theme,
                      thisRef,
                      types = 'image/jpeg,image/png,image/gif,image/webp,image/heic,image/heif',
                    }) => {
  const [activeDrag, setActiveDrag] = useState(false);

  const handleDrop = async (goodFiles, badFiles) => {
    setActiveDrag(false);
    console.log(goodFiles, badFiles);

    if (badFiles && badFiles.length > 0) {
      return;
    } else {
      let file = goodFiles[0];
      let convertedImage;

      if (file.type.includes('image/')) {
        file = await resizeImage(goodFiles[0], 1000);
      }

      if (file.type.toLowerCase() === 'image/heic') {
        try {
          convertedImage = await heic2any({blob: file});
        } catch (err) {
          console.log(err);
        }
      }

      file.preview = URL.createObjectURL(convertedImage || file);

      onDrop(file);
    }
  };

  let classes = classNames(
    'FileUpload',
    {
      'FileUpload--active': activeDrag,
      'FileUpload--has-preview': (preview || imageUrl) && !isUploading,
      'FileUpload--is-uploading': isUploading,
      'FileUpload--disabled': disabled,
    }
  );

  if (theme) {
    classes += ` FileUpload--${theme}`;
  }

  return (
    <div className={classes}>
      <div className="FileUpload--inner">
        <Dropzone accept={types}
                  disabled={disabled}
                  multiple={false}
                  onDragEnter={() => setActiveDrag(true)}
                  onDragLeave={() => setActiveDrag(false)}
                  onDrop={handleDrop}
                  ref={thisRef}>
          {({getRootProps, getInputProps}) => (
            <div className="FileUpload__content" {...getRootProps()}>
              <input {...getInputProps()} />

              {isUploading ? (
                <div className="FileUpload__uploading">
                  <div className="FileUpload__uploading-inner">
                    Uploading
                  </div>

                  {!hideProgressBar && (
                    <ProgressBar
                      progress={progress}
                    />
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {preview || imageUrl ? (
                    <div className="FileUpload__preview" style={{backgroundImage: `url(${preview ? preview.preview : imageUrl})`}} />
                  ) : (
                    <React.Fragment>
                      <button
                        className="FileUpload__btn"
                      >
                        {buttonText || 'Add Image'}
                      </button>

                      <div className="FileUpload__text">
                        {secondaryText || 'or drop an image to upload'}
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {(preview || imageUrl) && !isUploading ? (
                <Button
                  className="FileUpload__edit"
                >
                  <Icon name="pencil"/>
                </Button>
              ) : null}
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  types: ""
};

export default FileUpload;
