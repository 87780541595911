import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from '@pv3-shared-components/Button'
import DashboardPanel from '@pv3-components/DashboardPanel'
import './portfolioDetails.scss'
import {eq, gt, isNil, map, isUndefined, lt, isNull, isEmpty, toUpper, uniqBy, sortBy} from 'lodash'
import AddFundsModal from '@pv3-components/AddFundsModal'
import WalletBalancesTable from '@pv3-components/WalletBalancesTable'
import Strategies from '@pv3-components/Strategies'
import { useHistory } from 'react-router'
import { APP_PATHS, STATS_TIME_FRAMES } from '@pv3-constants'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import { get } from 'react-hook-form'
import useIntersection from '@pv3-hooks/useIntersection'
import RemoveFundsModal from '../RemoveFundsModal'
import { Link } from 'react-router-dom'
import Select from '@pv3-shared-components/Select'
import useBalancerDuck from '@pv3-hooks/useBalancerDuck'
import Icon from '@pv3-shared-components/Icons'
import useModal from '../../hooks/useModal'
import useMarketplaceDuck from '@pv3-hooks/useMarketplaceDuck'
import PortfolioHoldings from '@pv3-components/PortfolioHoldings'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import {fixFloatingPointErrors, formatAsDollarAmount, isArrayNullOrEmpty} from '../../utils/utils'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import NetAssetsTooltip from '@pv3-components/NetAssetsTooltip'
import ComponentLoader from '@pv3-components/ComponentLoader'
import Loading from '@pv3-shared-components/Loading'
import MultiSelect from '@pv3-shared-components/MultiSelect'
import CaretDown from "../shared/Icons/CaretDown";
import { ButtonVariants } from '../shared/Button';

const PortfolioDetails = () => {
  const { isLionBalancerEnabled } = useBalancerDuck()
  const { backtestScenarios, dashboardBacktestHistories, getBacktestScenarios, getDashboardBacktestHistories } = useStrategiesDuck();
  const history = useHistory()
  const locationState = get(history, 'location.state')
  const [activeTab, setActiveTab] = useState('Autotrade')
  const [selectedBacktestScenario, setSelectedBacktestScenario] = useState()
  const [showBacktestResults, setShowBacktestResults] = useState(false);
  const portfolioDetailsHeaderRef = useRef()
  const tabsContainerRef = useRef();
  const inViewport = useIntersection(
    portfolioDetailsHeaderRef.current,
    '-200px'
  )
  const tabsInViewport = useIntersection(
    tabsContainerRef.current,
    '-200px'
  )
  const addFundsModal = useModal()
  const removeFundsModal = useModal()
  const isBig = useWindowWidth()
  const { roi, getRoi } = useProfileDuck()



  const { clearBacktestData, setStrategyTimePeriodFilter, setStrategyPublisherFilter, setStrategySortByFilter, setStrategyMarketsFilter, setStrategyOrderStatusFilter, setTemporaryStrategySettings, strategyTimePeriodFilter, strategyOrderStatusFilter, strategyPublisherFilter, strategyTradeTypeFilter, strategySortByFilter, strategyMarketsFilter, strategies } = useStrategiesDuck()

  const { subscriptions } = useMarketplaceDuck()

  const handleDepositClick = () => addFundsModal.setShow(true)

  const handleWithdrawClick = () => removeFundsModal.setShow(true)

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const [showFilters, setShowFilters] = useState(false);

  const handleBacktestScenarioChange = (event) => {
    const { value } = event.target;
    setSelectedBacktestScenario(value);
  };

  const handleStatsTimePeriodChange = (event) => {
    const { value } = event.target;
    setStrategyTimePeriodFilter(value);
  }

  const handleStrategyOrderStatusChange = (event) => {
    const { value } = event.target;
    setStrategyOrderStatusFilter(value);
  };

  const handleStrategyPublisherChange = (event) => {
    const { value } = event.target;
    setStrategyPublisherFilter(value);
  };

  const handleStrategySortByChange = (event) => {
    const { value } = event.target;
    setStrategySortByFilter(value);
  };

  const handleStrategyMarketChange = (options) => {
    setStrategyMarketsFilter(options);
  };

  useEffect(() => {
    if(isArrayNullOrEmpty(backtestScenarios)) {
      getBacktestScenarios();
    }

    if(isArrayNullOrEmpty(dashboardBacktestHistories)) {
      getDashboardBacktestHistories();
    }

    setStrategySortByFilter('name');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNil(locationState)) return

    if (locationState.scrollToStrategies && portfolioDetailsHeaderRef.current) {
      portfolioDetailsHeaderRef.current.scrollIntoView(true)
    }

    if(locationState.tab && tabsContainerRef.current) {
      setActiveTab(locationState.tab);
      setTimeout(() => tabsContainerRef.current.scrollIntoView(true), 200);
    }
  }, [locationState])

  useEffect(() => {
    if (isNil(inViewport) || isNil(locationState)) return

    if (inViewport) history.replace({ ...history.location, state: null })

    if (tabsInViewport) history.replace({ ...history.location, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport, locationState])

  useEffect(() => {
    // Poll for ROI updates while on component
    const timer = setInterval(() => getRoi(), 20000)

    // Clear interval when leaving component
    return () => clearInterval(timer)
  }, [getRoi])

  useEffect(() => {
    if(isArrayNullOrEmpty(backtestScenarios) || selectedBacktestScenario) {
      return;
    }

    setSelectedBacktestScenario(backtestScenarios.find((bs) => bs.days === 60)?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backtestScenarios]);

  const marketOptions = useMemo(
    () => {
      let pairs = [];

      strategies && strategies.forEach((s) => {
        if(!isNil(s.baseAsset)) {
          pairs.push({
            label: toUpper(s.baseAsset),
            value: s.baseAsset,
          });
        }
      });

      subscriptions && subscriptions.forEach((op) => {
        if(!isNil(op.baseCurrencies)) {
          let currencies = op.baseCurrencies.toLowerCase().split(',');
          currencies.forEach((c) => {
            pairs.push({
              label: toUpper(c),
              value: c,
            });
          });
        }
      });

      pairs = uniqBy(pairs, 'value');
      return sortBy(pairs, 'value');
    }, [subscriptions, strategies]
  );

  const publisherOptions = useMemo(
    () => {
      if(isArrayNullOrEmpty(subscriptions)) {
        return [];
      }

      return subscriptions.map((s) => ({
        label: (s.publisherFirstName || '') + ' ' + (s.publisherLastName || ''),
        value: s.publisherUserId,
      }))
    }, [subscriptions]
  );

  const tabs = {
    Autotrade: (
      <div className="Autotrade-container">
        <ComponentLoader isLoading={isNull(strategies)} />

        <div className="AutotradeHeader">
          <Link
            className="AutotradeHeader-create"
            id="portfolio_create_strategy_btn"
            data-testid="portfolio_create_strategy_btn"
            onClick={() => {
              setTemporaryStrategySettings({});
              clearBacktestData();
            }}
            to={APP_PATHS.STRATEGIES_NEW}
          >
            <Icon name="circleplus" />
            Create strategy
          </Link>
          <div className="AutotradeHeader-tokenBalancer">
            <Link
              to={APP_PATHS.ACCOUNT_BALANCER}
              className={`${isLionBalancerEnabled ? 'is-Active' : ''}`}
            >
              Token balancer
            </Link>
          </div>
          {((!isNil(strategies) && gt(strategies.length, 0)) ||
            (!isNil(subscriptions) && gt(subscriptions.length, 0))) && (
              <React.Fragment>
                <div className="AutotradeHeader-filters">
                  <div className="AutotradeHeader-filters-buttons">
                    <Button
                      className={`AutotradeHeader-filters-button ${!showBacktestResults ? 'is-active' : ''}`}
                      onClick={() => setShowBacktestResults(false)}
                      variant={ButtonVariants.Blank}
                    >
                      Trades
                    </Button>

                    <Button
                      disabled={isArrayNullOrEmpty(backtestScenarios) || isArrayNullOrEmpty(dashboardBacktestHistories)}
                      className={`AutotradeHeader-filters-button ${showBacktestResults ? 'is-active' : ''}`}
                      onClick={() => setShowBacktestResults(true)}
                      variant={ButtonVariants.Blank}
                    >
                      Backtests
                    </Button>
                  </div>

                  <label className="Label">
                    Show stats for
                    {showBacktestResults ? (
                      <Select
                        ariaLabel="Select backtest scenario to display"
                        value={selectedBacktestScenario}
                        onChange={handleBacktestScenarioChange}
                      >
                        {backtestScenarios.map((bs) => (
                          <option value={bs.id} key={bs.id}>{bs.name}</option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        ariaLabel="Select stat history duration"
                        options={STATS_TIME_FRAMES}
                        value={strategyTimePeriodFilter}
                        onChange={handleStatsTimePeriodChange}
                      />
                    )}

                  </label>
                </div>

                <div className="AutotradeHeader-filters-container">
                  <div
                    className="AutotradeHeader-show-filters-button"
                    onClick={() => setShowFilters(!showFilters)}
                    role="button"
                  >
                    <CaretDown className={showFilters ? 'rotated' : null} />
                    Filter & Sort
                  </div>

                  {showFilters && (
                    <React.Fragment>
                      <hr />
                      <div className="AutotradeHeader-filters-inner">
                        <Select
                          ariaLabel="Select how to sort strategies"
                          options={[
                            {label: 'Sort by...', value: ''},
                            {label: 'Sort by recently added', value: 'date'},
                            {label: 'Sort by most recently updated', value: 'updated'},
                            {label: 'Sort by strategy name', value: 'name'},
                            {label: 'Sort by % amount', value: 'holdingPercent'},
                          ]}
                          defaultValue={strategySortByFilter}
                          onChange={handleStrategySortByChange}
                        />

                        <MultiSelect
                          placeholder="All markets"
                          options={marketOptions}
                          id="strategyTradingPairs"
                          name="strategyTradingPairs"
                          defaultValue={strategyMarketsFilter}
                          onChange={handleStrategyMarketChange}
                          isSearchable={false}
                        />

                        <Select
                          ariaLabel="Select a publisher to filter by"
                          options={[
                            {label: 'All publishers', value: ''},
                            {label: 'Myself', value: 'myself'},
                            ...publisherOptions,
                          ]}
                          defaultValue={strategyPublisherFilter}
                          onChange={handleStrategyPublisherChange}
                        />

                        <Select
                          ariaLabel="Select an order status to filter by"
                          options={[
                            {label: 'Any order status', value: ''},
                            {label: 'Has open orders', value: 'open'},
                          ]}
                          defaultValue={strategyOrderStatusFilter}
                          onChange={handleStrategyOrderStatusChange}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
          )}
        </div>
        {(!isNil(strategies) && gt(strategies.length, 0)) ||
        (!isNil(subscriptions) && gt(subscriptions.length, 0)) ? (
          <Strategies
            markets={strategyMarketsFilter}
            orderStatus={strategyOrderStatusFilter}
            publisher={strategyPublisherFilter}
            selectedBacktestScenario={selectedBacktestScenario}
            showBacktestResults={showBacktestResults}
            sortBy={strategySortByFilter}
            statsTimeFrame={strategyTimePeriodFilter}
            tradeType={strategyTradeTypeFilter}
          />
        ) : (
          <div className="PortfolioDetailsTab-placeholder">
            <img
              className="PortfolioDetailsTab-img"
              src="/img/blocks.svg"
              alt="Trade Crypto Illustration"
            />
            <div className="PortfolioDetailsTab-placeholderText">
              <h2 className="PortfolioDetailsTab-placeholderTextHeader">
                Trade crypto while you sleep
              </h2>
              <p>
                Just like crypto, the CoinLion Auto-Trader runs 24/7. Find a
                trader to copy and you'll make the same trades they do. Or, take
                control of your trading by creating your own strategy.
              </p>
            </div>
          </div>
        )}
        <div className="PortfolioDetailsTabFooter">
          <div className="PortfolioDetailsTabFooter-buttonGroup">
            <Link
              className="Button Button--secondary Button--large"
              id="portfolio_explore_copycats_btn"
              to={APP_PATHS.MARKETPLACE}
            >
              Find a trader to copy
            </Link>
          </div>
        </div>
      </div>
    ),
    Wallets: <WalletBalancesTable />,
  }

  return (
    <div className="u-container">
      <DashboardPanel>
        <header
          className="PortfolioDetails-header"
          ref={portfolioDetailsHeaderRef}
        >
          <h1 className="PortfolioDetails-heading">Portfolio Details</h1>
          <div className="PortfolioSummary-content">
            {isEmpty(roi) ? (
              <Loading />
            ) : (
              <React.Fragment>
                <div className="PortfolioFigure">
                  <div className="PortfolioFigure-amount PortfolioFigure-amount--secondary">
                    {formatAsDollarAmount(get(roi, 'initialAccountValue'))}
                  </div>
                  <div className="PortfolioFigure-unit">
                    Net assets contributed
                    <NetAssetsTooltip
                      assetsIn={get(roi, 'assetsIn')}
                      assetsOut={get(roi, 'assetsOut')}
                      total={get(roi, 'initialAccountValue')}
                    />
                  </div>
                </div>
                <div className="PortfolioFigure">
                  <div
                    className={`PortfolioFigure-amount PortfolioFigure-amount--secondary ${
                      !eq(get(roi, 'totalAccruedProfit'), 0) &&
                      (lt(get(roi, 'totalAccruedProfit'), 0)
                        ? 'PortfolioFigure-amount--loss'
                        : 'PortfolioFigure-amount--gain')
                    }`}
                  >
                    {formatAsDollarAmount(get(roi, 'totalAccruedProfit'))}
                  </div>
                  <div className="PortfolioFigure-unit">Total gain/loss</div>
                </div>
                <div className="PortfolioFigure">
                  <div
                    className={`PortfolioFigure-amount PortfolioFigure-amount--secondary ${
                      !eq(get(roi, 'percentIncrease'), 0) &&
                      (lt(get(roi, 'percentIncrease'), 0)
                        ? 'PortfolioFigure-amount--loss'
                        : 'PortfolioFigure-amount--gain')
                    }`}
                  >
                    {!isUndefined(get(roi, 'percentIncrease')) &&
                      fixFloatingPointErrors(get(roi, 'percentIncrease'), 1)}
                    %
                  </div>
                  <div className="PortfolioFigure-unit">Total return %</div>
                </div>
              </React.Fragment>
            )}
            <div className="PortfolioDetails-buttonGroup">
              <Button size="small" onClick={handleDepositClick}>
                Deposit
              </Button>
              <AddFundsModal modal={addFundsModal} />
              <Button size="small" onClick={handleWithdrawClick}>
                Withdraw
              </Button>
              <RemoveFundsModal modal={removeFundsModal} />
            </div>
          </div>
          {isBig && <PortfolioHoldings />}
          <div className="PortfolioDetails-tabs">
            {map(Object.keys(tabs), (key) => (
              <button
                key={key}
                className={`u-buttonReset PortfolioDetails-tabs-button ${
                  eq(activeTab, key) ? 'is-active' : ''
                }`}
                onClick={() => handleTabClick(key)}
              >
                {key}
              </button>
            ))}
          </div>
        </header>
        <div className="PortfolioDetails-content" ref={tabsContainerRef}>{tabs[activeTab]}</div>
      </DashboardPanel>
    </div>
  )
}

export default PortfolioDetails
