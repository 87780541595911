import React from 'react';
import { useLocation } from 'react-router';

import useDocTitle from '../../hooks/useDocTitle';

const VerifyEmail = () => {
  useDocTitle('Verify Email');

  const {
    //@ts-ignore
    state: { email },
  } = useLocation();

  return (
    <div className="SinglePanel-panel">
      <h1 id="message_header" className="SinglePanel-title">
        Verify your email address
      </h1>

      <p id="message_email" className="u-textCenter">
        <strong>We sent an email to {email || 'your email'}</strong>
      </p>

      <p className="u-textCenter">
        Please click the link in your email to verify and finish account setup
      </p>
    </div>
  )
}

export default VerifyEmail;
