import './button.scss';

import * as React from 'react';

import Loading from "../Loading";

export enum ButtonSizes {
  Small = 'small',
  Large = 'large',
}

export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
}

export enum ButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
  Quinary = 'quinary',
  Senary = 'senary',
  Icon = 'icon',
  Blank = 'blank',
}

type Props = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  id?: string
  isLoading?: boolean
  onClick?: () => void
  size?: ButtonSizes
  style?: React.CSSProperties
  type?: ButtonTypes
  variant?: ButtonVariants
}

const Button: React.FC<Props> = ({
  children,
  className = '',
  disabled,
  isLoading,
  onClick,
  size,
  variant,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={`Button ${className} ${
        variant ? `Button--${variant}` : `Button--primary`
      } ${size ? `Button--${size}` : ''}`}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading ? <Loading /> : children}
    </button>
  )
}

export default Button;
