import './componentloader.scss';

import * as React from 'react';
import { find, isUndefined } from 'lodash';

import useUiDuck from '../../hooks/useUiDuck';

import Loading from '../shared/Loading';

type Props = {
  isLoading?: boolean
  loadingStates?: string[]
}

const ComponentLoader: React.FC<Props> = ({
  isLoading,
  loadingStates,
}) => {
  const { loading } = useUiDuck();

  const showLoader =
    (!isUndefined(isLoading) && isLoading) ||
    (!isUndefined(loadingStates) &&
      !isUndefined(
        find(loadingStates, (loadingState) => loading[loadingState])
      ));

  return (
    <>
      {showLoader && (
        <div className="ComponentLoader">
          <Loading />
        </div>
      )}
    </>
  );
}

export default ComponentLoader;
