import React, {useState, useMemo, useEffect, useRef} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import {
  isUndefined,
  lt,
  gt,
  eq,
  round,
  get,
  upperCase,
  isNil,
  isNull,
  isEmpty,
  isNaN,
  find,
} from 'lodash'
import useMarketsDuck from '../../hooks/useMarketsDuck'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import Input from '@pv3-shared-components/Input'
import { formatAsDollarAmount, formatNumber } from '../../utils/utils'
import { format } from 'date-fns'
import Button from '@pv3-shared-components/Button'
import AssetWalletItem from '../AssetWalletItem'
import Icon from '@pv3-shared-components/Icons'
import useFilterMarkets from '../../hooks/useFilterMarkets'
import { ButtonTypes, ButtonVariants } from '../shared/Button';
import moment from 'moment/moment';
import Select from '../shared/Select';
import { GOOD_TILL_OPTIONS } from '../../constants';
import Tooltip from '../shared/Tooltip';
import './manualsell.scss'
import { APP_PATHS } from "../../constants";
import useTradesDuck from '../../hooks/useTradesDuck';

const BadMarketBlocker = ({ hasMarket, children }) => {
  if (!hasMarket)
    return (
      <EnhancedFlash variant="warning" heading="This market is not available" />
    )

  return children
}

const ManualSellForm = ({
  sellAsset,
  receiveAsset,
  handleChooseSellAsset,
  handleChooseExchangeAsset,
  handleSubmitSellOrder,
  sellOrder,
  isLimit,
  onInstrumentChange,
}) => {
  const formMethods = useForm({
    defaultValues: isEmpty(sellOrder)
      ? undefined
      : {
          price: sellOrder.price,
          amount: sellOrder.amount,
          limitOrderAmount: sellOrder.limitOrderAmount,
        },
  })
  const { handleSubmit, watch, formState, setValue, setFocus, trigger, getValues } = formMethods
  const { errors } = formState

  const [limitOrderAmountSetFromSellMax, setLimitOrderAmountSetFromSellMax] = useState(false);

  const { calculateApproximateSellPrice, orderBooks, startMiniTicker } = useMarketsDuck()
  const { accountBalances, buySellPrices, buySellPricesUpdateDateTime, walletValuesInUSD, balances } = useWalletsDuck()
  const { getLionSellLimit, lionSellLimit } = useTradesDuck();

  const [displayError, setDisplayError] = useState()
  const [showFormattedAmount, setShowFormattedAmount] = useState(false)
  const [chooseAmountInUsd/*, setChooseAmountInUsd*/] = useState(false)
  const [limitOrderAmountFocused, setLimitOrderAmountFocused] = useState(false)
  const [totalFocused, setTotalFocused] = useState(false)
  const [priceFocused, setPriceFocused] = useState(false)
  const [goodTillValue, setGoodTillValue] = useState('90|days')

  const amount = watch('amount')
  const price = watch('price')
  const limitOrderAmount = watch('limitOrderAmount')
  const total = watch('total')

  let priceHasBeenTouched = useRef(false);
  let quantityHasBeenTouched = useRef(false);

  const sellAssetIsLion = eq('lion', sellAsset);

  const { marketsData } = useFilterMarkets()

  const { sellScale, receiveScale, hasMarket, instrument, type } =
    useMemo(() => {
      if (eq(marketsData.length))
        return {
          buyScale: null,
          payScale: null,
          hasMarket: false,
        }

      const marketPair1 = find(
        marketsData,
        ({ baseAsset, quoteAsset }) =>
          eq(baseAsset, sellAsset) && eq(quoteAsset, receiveAsset)
      )
      if (!isUndefined(marketPair1))
        return {
          sellScale: marketPair1.amountScale,
          receiveScale: marketPair1.priceScale,
          hasMarket: true,
          instrument: `${sellAsset}_${receiveAsset}`,
          type: 'sell',
        }

      return {
        sellScale: null,
        receiveScale: null,
        hasMarket: false,
      }
    }, [sellAsset, receiveAsset, marketsData])

  const amountAvailableOnBook = useMemo(
    () => {
      if(isNil(orderBooks) || isNil(instrument)) {
        return 0;
      }

      let book = orderBooks[/lion_usd|lion_usdt/.test(instrument) ? 'lion_usdc' : instrument];

      if(isNil(book)) {
        return 0;
      }

      let total = 0;
      book.bids.forEach((bid) => {
        total += bid.amount;
      });

      return total;
    }, [instrument, orderBooks]
  );

  const maxAmountLimit = useMemo(
    () => {
      if(sellAssetIsLion) {
        let max = accountBalances?.lionAvailableBalance || 0;

        if(!isNil(lionSellLimit)) {
          max = Math.min(max, lionSellLimit);
        }

        return max;
      }

      return balances[sellAsset] || 0;
    }, [accountBalances, balances, lionSellLimit, sellAsset, sellAssetIsLion]
  );

  const maxAmountMarket = useMemo(
    () => {
      if(sellAssetIsLion) {
        let max = accountBalances?.lionAvailableBalance || 0;

        if(!isNil(lionSellLimit)) {
          max = Math.min(max, lionSellLimit);
        }

        if(!isNil(amountAvailableOnBook)) {
          max = Math.min(max, amountAvailableOnBook);
        }

        return max;
      }

      return balances[sellAsset] || 0;
    }, [accountBalances, amountAvailableOnBook, balances, lionSellLimit, sellAsset, sellAssetIsLion]
  );

  const { marketExchangeRate, lastMarketExchangeRateUpdate } = useMemo(() => {
    if(isNil(buySellPricesUpdateDateTime)) {
      return {};
    }

    return {
      marketExchangeRate: buySellPrices[sellAsset].sellPrice,
      lastMarketExchangeRateUpdate: buySellPricesUpdateDateTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySellPrices, buySellPricesUpdateDateTime, instrument, sellAsset])

  useEffect(() => {
    if (!isUndefined(get(errors, 'amount.message')))
      return setDisplayError(errors.amount.message)

    if (!isUndefined(get(errors, 'assets.message')))
      return setDisplayError(errors.assets.message)

    setDisplayError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.amount, errors.assets])

  const handleFormSubmit = (data) => {
    // validate total even if it other fields have not been touched.
    if(isLimit && !quantityHasBeenTouched.current && !priceHasBeenTouched.current) {
      quantityHasBeenTouched.current = true;
      priceHasBeenTouched.current = true;
      trigger();

      const totalValue = getValues('total');
      if(isNil(totalValue) || !gt(parseFloat(totalValue), 0)) {
        return;
      }
    }

    if(isLimit) {
      handleSubmitSellOrder({
        isLimit,
        instrument,
        sellAsset,
        receiveAsset,
        type,
        marketExchangeRate,
        goodTill: moment().add(parseInt(goodTillValue.split('|')[0]), goodTillValue.split('|')[1]).format(),
        ...data,
      });
    } else {
      handleSubmitSellOrder({
        isLimit,
        sellAsset,
        receiveAsset,
        amount: data.amount,
      });
    }
  }

  const handleSellMax = () => {
    let amount = balances[sellAsset] || 0;

    if(sellAssetIsLion) {
      amount = maxAmountLimit;
    }

    setLimitOrderAmountSetFromSellMax(true);
    setValue('limitOrderAmount', Math.floor(amount * (10 ** sellScale)) / (10 ** sellScale));
  };

  const handleSellMaxMarket = () => {
    let balance = balances[sellAsset] || 0;

    if(sellAssetIsLion) {
      balance = maxAmountMarket;
    }

    setValue('amount', Math.floor(balance * (10 ** sellScale)) / (10 ** sellScale));
  };

  useEffect(() => {
    startMiniTicker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!hasMarket) return

    if (!isLimit) setFocus('amount')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMarket, isLimit])

  useEffect(() => {
    if (
      isUndefined(limitOrderAmount) ||
      eq(limitOrderAmount, '') ||
      isUndefined(price) ||
      isNull(receiveScale) ||
      totalFocused
    )
      return

    const calculatedTotal = Math.ceil((limitOrderAmount * price) * (10 ** receiveScale)) / (10 ** receiveScale);
    if (!eq(total, calculatedTotal)) setValue('total', calculatedTotal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitOrderAmount, price, receiveScale])

  useEffect(() => {
    if (
      isUndefined(total) ||
      eq(total, '') ||
      isUndefined(price) ||
      eq(price, 0) ||
      isNull(sellScale) ||
      priceFocused ||
      limitOrderAmountFocused
    )
      return

    const calculatedLimitOrderAmount = Math.floor((total / price) * (10 ** sellScale)) / (10 ** sellScale);
    if (!eq(limitOrderAmount, calculatedLimitOrderAmount) && !limitOrderAmountSetFromSellMax) {
      setValue('limitOrderAmount', calculatedLimitOrderAmount);
    }

    if(limitOrderAmountSetFromSellMax) {
      setLimitOrderAmountSetFromSellMax(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, price, sellScale])

  useEffect(() => {
    if (!isUndefined(get(errors, 'amount.message')))
      return setDisplayError(errors.amount.message)

    if (!isUndefined(get(errors, 'assets.message')))
      return setDisplayError(errors.assets.message)

    setDisplayError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.amount, errors.assets])

  useEffect(() => {
    if (eq(amount, 0)) return

    if (chooseAmountInUsd) {
      setValue('amount', amount * buySellPrices[sellAsset].sellPrice)
    } else {
      if(isLimit) {
        setValue('amount', amount / buySellPrices[sellAsset].sellPrice)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseAmountInUsd])

  useEffect(() => {
    onInstrumentChange(instrument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrument])

  useEffect(() => {
    if (isNil(marketExchangeRate) || isNil(receiveScale)) return
    if (eq(price, 0) || eq(price, '0') || isUndefined(price))
      setValue('price', Math.ceil(marketExchangeRate * (10 ** receiveScale)) / (10 ** receiveScale))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketExchangeRate, receiveScale])

  useEffect(() => {
    if(sellAsset === 'lion') {
      getLionSellLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellAsset]);

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <BadMarketBlocker hasMarket={hasMarket}>
            {isLimit ? (
              <React.Fragment>
                {sellAssetIsLion && !isUndefined(lionSellLimit) && (
                    <p className="Liquidator-contitional">Placing LION Token Sell orders is an enhanced feature for users leveraging the platform.  Only users who leverage the auto-trader, token balancer, and the rewards programs can help make the LION market.  All other users need to use the Liquidator to sell their LION.  Currently you have the ability to sell {formatNumber(lionSellLimit)} LION. (<a href={APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW}>Go to Liquidator</a>)</p>
                )}
                <h4 className="ManualBuyForm-headline">Limit Order</h4>
                <EnhancedFlash
                  variant="info"
                  heading={`Enter the minimum price you’re willing to receive per ${upperCase(
                    sellAsset
                  )}.`}
                />
                <div className="ManualBuyForm-limitOrderInputs">
                  <Input
                    name="price"
                    suffix={sellAssetIsLion ? 'USDC' : upperCase(receiveAsset)}
                    modifierClass="u-noMargin"
                    className="ManualBuyForm-limitOrderInput"
                    type="number"
                    onFocus={() => {
                      setPriceFocused(true)
                      priceHasBeenTouched.current = true;
                    }}
                    onBlur={() => {
                      setValue('price', Math.ceil(price * (10 ** receiveScale)) / (10 ** receiveScale))
                      setPriceFocused(false)
                      trigger();
                    }}
                    step="any"
                    min="0"
                    defaultValue="0"
                    inputMode="decimal"
                    triggerValidation
                  >
                    Price per {upperCase(sellAsset)}
                  </Input>
                  {!isUndefined(marketExchangeRate) &&
                    !isUndefined(lastMarketExchangeRateUpdate) && (
                      <p className="ManualBuyForm-inputSubtext">
                        {formatNumber(marketExchangeRate, true)} {sellAssetIsLion ? 'USDC' : upperCase(receiveAsset)}{' '}
                        = market price at{' '}
                        {format(lastMarketExchangeRateUpdate, "h:mmaaaaa'm'")}
                      </p>
                    )}

                  {lt(price, marketExchangeRate * 0.99) && !eq(price, '') && (
                    <EnhancedFlash
                      variant="danger"
                      heading={`You are offering to sell at ${round(
                        ((marketExchangeRate - price) / marketExchangeRate) *
                          100
                      )}% less than the current market price. `}
                      subheading="Review your order before proceeding"
                    />
                  )}

                  {gt(price, marketExchangeRate * 1.1) && !eq(price, '') && (
                    <EnhancedFlash
                      variant="warning"
                      heading={`You are asking ${round(
                        ((price - marketExchangeRate) / marketExchangeRate) *
                          100
                      )}% more than the current market price. `}
                      subheading="It may take a while to find a buyer at this price."
                    />
                  )}

                  <div className="DepositCrypto-amountContainer">
                    <Input
                      name="limitOrderAmount"
                      registerConfig={{
                        max: {
                          value: sellAssetIsLion ? maxAmountLimit : balances[sellAsset],
                          message: sellAssetIsLion ? `This amount is over the maximum LION sell limit of ${formatNumber(maxAmountLimit)}` : `You lack the necessary balance in your ${upperCase(
                            sellAsset
                          )} wallet`,
                        },
                      }}
                      suffix={upperCase(sellAsset)}
                      className="ManualBuyForm-limitOrderInput"
                      onFocus={() => {
                        setLimitOrderAmountFocused(true)
                        quantityHasBeenTouched.current = true;
                      }}
                      onBlur={() => {
                        setLimitOrderAmountFocused(false)
                        setValue(
                          'limitOrderAmount',
                          Math.floor(limitOrderAmount * (10 ** sellScale)) / (10 ** sellScale)
                        )
                        trigger();
                      }}
                      type="number"
                      step="any"
                      min="0"
                      defaultValue="0"
                      inputMode="decimal"
                      triggerValidation
                    >
                      Quantity
                    </Input>

                    <Button
                      className="DepositCrypto-maxButton"
                      onClick={handleSellMax}
                      type="button"
                      variant="blank"
                    >
                      Sell Max
                    </Button>
                  </div>

                  <Input
                    name="total"
                    registerConfig={{
                      validate: {
                        positive: (v) => {
                          return (
                            !priceHasBeenTouched.current ||
                            !quantityHasBeenTouched.current ||
                            gt(parseFloat(v), 0)
                          ) ||  'Amount must be greater than 0'
                        }
                      },
                    }}
                    suffix={sellAssetIsLion ? 'USD(C/T)' : upperCase(receiveAsset)}
                    className="ManualBuyForm-limitOrderInput"
                    onFocus={() => setTotalFocused(true)}
                    onBlur={() => {
                      setTotalFocused(false)
                      setValue('total', Math.ceil(total * (10 ** receiveScale)) / (10 ** receiveScale));
                      trigger();
                    }}
                    type="number"
                    step="any"
                    min="0"
                    inputMode="decimal"
                    triggerValidation
                  >
                    Total
                  </Input>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {sellAssetIsLion && !isUndefined(lionSellLimit) && (
                    <p className="Liquidator-contitional">Placing LION Token Sell orders is an enhanced feature for users leveraging the platform.  Only users who leverage the auto-trader, token balancer, and the rewards programs can help make the LION market.  All other users need to use the Liquidator to sell their LION.  Currently you have the ability to sell {formatNumber(lionSellLimit)} LION. (<a href={APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW}>Go to Liquidator</a>)</p>
                )}
                <p className="AchDepositForm-amountLabel">Amount</p>
                <div className="AchDepositForm-amountInputContainer">
                  {showFormattedAmount && (
                    <div className="AchDepositForm-formattedAmountOverlay">
                      {
                        <p>
                          {chooseAmountInUsd
                            ? isNaN(amount)
                              ? '$0'
                              : formatAsDollarAmount(amount, true)
                            : isNaN(amount)
                            ? 0
                            : formatNumber(amount)}
                        </p>
                      }
                    </div>
                  )}
                  <Input
                    type="number"
                    className="AchDepositForm-bigNumberInput"
                    inputMode="decimal"
                    name="amount"
                    step={
                      chooseAmountInUsd ? '.01' : String(10 ** (-1 * sellScale))
                    }
                    registerConfig={{
                      required: 'Amount is required',
                      max: {
                        value: chooseAmountInUsd
                          ? walletValuesInUSD[sellAsset]
                          : (sellAssetIsLion ? maxAmountMarket : balances[sellAsset]),
                        message: sellAssetIsLion ? `This amount is over the maximum LION sell limit of ${formatNumber(maxAmountMarket)}` : `You lack the necessary balance in your ${upperCase(
                          sellAsset
                        )} wallet`,
                      },
                      validate: {
                        positive: (v) =>
                          gt(parseFloat(v), 0) ||
                          'Amount must be greater than 0',
                      },
                    }}
                    onBlur={() => {
                      setShowFormattedAmount(true);
                      setValue(
                        'amount',
                        Math.floor(amount * (10 ** sellScale)) / (10 ** sellScale)
                      )
                    }}
                    onFocus={() => setShowFormattedAmount(false)}
                    error={<React.Fragment />}
                    defaultValue="0"
                  />
                </div>
                <p className="AchDepositForm-currencyLabel u-noMargin">
                  {chooseAmountInUsd ? 'USD' : upperCase(sellAsset)}

                  <Button
                      className="BuySellModal-maxButton"
                      onClick={handleSellMaxMarket}
                      type={ButtonTypes.Button}
                      variant={ButtonVariants.Blank}
                  >
                    Sell Max
                  </Button>
                </p>
                <div className="ManualBuyForm-dividerContainer">
                  <hr className="u-divider ManualBuyForm-divider" />
                  {/*<Button
                    variant="icon"
                    type="button"
                    onClick={() => setChooseAmountInUsd((v) => !v)}
                  >
                    <Icon name="twowayarrows" />
                  </Button>*/}
                </div>

                {chooseAmountInUsd ? (
                  <p className="ManualBuyForm-convertedAmount">
                    ≈{' '}
                    {isNaN(amount / buySellPrices[sellAsset].sellPrice)
                      ? 0
                      : formatNumber(amount / buySellPrices[sellAsset].sellPrice)}{' '}
                    {upperCase(sellAsset)}
                  </p>
                ) : (
                  <p className="ManualBuyForm-convertedAmount">
                    {sellAssetIsLion && amountAvailableOnBook < amount ? 'ERROR' : (
                      calculateApproximateSellPrice({ amount, instrument })
                    )}
                  </p>
                )}
              </React.Fragment>
            )}
          </BadMarketBlocker>

          {!isNil(displayError) && (
            <EnhancedFlash
              variant="danger"
              heading={displayError}
              className="ManualBuyForm-errorFlash"
            />
          )}

          {sellAssetIsLion && amountAvailableOnBook < amount && (
            <EnhancedFlash
              variant="danger"
              heading={`There is not enough LION on the book to fill your order. The max amount available is ${amountAvailableOnBook.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} LION.`}
              className="ManualBuyForm-errorFlash"
            />
          )}

          <div className="ManualBuyForm-currencySelectorBox">
            <div className="ManualBuyForm-currencySelectorBoxLabel">Sell</div>
            <Button
              variant="blank"
              className="ManualBuyForm-currencySelectorButton"
              type="button"
              onClick={handleChooseSellAsset}
            >
              <AssetWalletItem
                assetId={sellAsset}
                className="ManualBuyForm-currencySelectorButtonContent"
                showWalletBalance
              />
              <Icon name="caretright" />
            </Button>
          </div>

          {!sellAssetIsLion && (
            <div className={`ManualBuyForm-currencySelectorBox ${sellAssetIsLion ? 'ManualBuyForm-currencySelectorBox--disabled' : ''}`}>
              <div className="ManualBuyForm-currencySelectorBoxLabel">
                Receive
              </div>
              <Button
                variant="blank"
                className="ManualBuyForm-currencySelectorButton"
                type="button"
                onClick={handleChooseExchangeAsset}
                disabled={sellAssetIsLion}
              >
                <AssetWalletItem
                  assetId={receiveAsset}
                  className="ManualBuyForm-currencySelectorButtonContent"
                  buyAssetIsLion={sellAssetIsLion}
                  showWalletBalance
                />
                <Icon name="caretright" />
              </Button>
            </div>
          )}

          {isLimit && (
            <div className="DepositCrypto-amountContainer">
              <label className="Label Label--goodTill">
                Orders are good for:
                <Select
                  ariaLabel="Select how long a limit order is good for"
                  options={GOOD_TILL_OPTIONS}
                  defaultValue={sellAssetIsLion ? '30|days' : '90|days'}
                  onChange={(e) => setGoodTillValue(e.target.value)}
                />

                <Tooltip left>
                  If your order is not filled in the time frame then it will be canceled and removed from the book. This is to help keep a healthy and engaged group of users and an accurate book.
                </Tooltip>
              </label>
            </div>
          )}

          <div className="ManualBuyForm-submitButtonContainer">
            <Button
              type="submit"
              variant="primary"
              size="large"
              className="u-fullWidth"
              disabled={!hasMarket}
            >
              Preview Sell Order
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default ManualSellForm
