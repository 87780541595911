import React, { useMemo, useState } from 'react'
import { some } from 'lodash'
import Button from '@pv3-shared-components/Button'
import Icon from '@pv3-shared-components/Icons'
import './portfolioOnboarding.scss'
import useUiDuck from '../../hooks/useUiDuck'
import AddFundsModal from '@pv3-components/AddFundsModal'
import useModal from '../../hooks/useModal'
import { useHistory } from 'react-router-dom'
import { APP_PATHS } from '../../constants'
import Logo from "../shared/Logo";
import { useAppDispatch } from '../../ducks';
import { saveReferralCode } from '../../ducks/rewards';

const PortfolioOnboarding = () => {
  const dispatch = useAppDispatch();
  const { setShowCtaCard, showCtaCards } = useUiDuck()
  const history = useHistory()
  const addFundsModal = useModal()

  const [isSubmittingReferralCode, setIsSubmittingReferralCode] = useState(false);
  const [referralCode, setReferralCode] = useState('');

  const handlePanelCloseButtonClick = (card) => {
    // Ensure dismissed dashboard CTAs don't return upon refresh or logout/login
    let dismissedDashboardCtas =
      JSON.parse(localStorage.getItem('dismissedDashboardCtas')) || []
    dismissedDashboardCtas.push(card)
    localStorage.setItem(
      'dismissedDashboardCtas',
      JSON.stringify(dismissedDashboardCtas)
    )

    setShowCtaCard({ card, show: false })
  }

  const handleDepositFunds = () => addFundsModal.setShow(true)

  const handleFindATrader = () => history.push(APP_PATHS.MARKETPLACE)

  const handleSubmitReferralCode = async (e) => {
    try {
      e.preventDefault();
      setIsSubmittingReferralCode(true);
      await dispatch(saveReferralCode({ referralCode}));
      setReferralCode('');
      setShowCtaCard({ card: 'REFERRAL_CODE', show: false });
    } catch(err) {
      console.log('PortfolioOnboarding handleSubmitReferralCode err', err);
    } finally {
      setIsSubmittingReferralCode(false);
    }
  };

  const anyCtaCardIsVisible = useMemo(
    () => some(showCtaCards, (show) => show),
    [showCtaCards]
  )

  const numberOfVisibleCtaCards = useMemo(
    () => {
      let visibleCount = 0;
      for (const key in showCtaCards) {
        if (showCtaCards[key]) {
          visibleCount++;
        }
      }
      return visibleCount;
    }, [showCtaCards]
  )

  return (
    <React.Fragment>
      {anyCtaCardIsVisible && (
        <div className="PortfolioOnboarding u-container">
          <h2 className="PortfolioOnboarding-headline">
            <Logo />
            Get Started with CoinLion auto-trading
          </h2>
          <div className="PortfolioOnboarding-ctas">
            {showCtaCards.REFERRAL_CODE && (
              <aside className={`PortfolioOnboardingCta PortfolioOnboardingCta-wide${numberOfVisibleCtaCards} PortfolioOnboardingCta-ReferralCode`}>
                <button
                  onClick={() =>
                    handlePanelCloseButtonClick('REFERRAL_CODE')
                  }
                  className="u-buttonReset PortfolioOnboardingCta-close"
                >
                  <Icon name="x" width="32" height="32" />
                </button>
                <div className="PortfolioOnboardingCta-content">
                  <h1 className="PortfolioOnboardingCta-title">
                    You are missing out on Referral Rewards!
                  </h1>
                  <p className="PortfolioOnboardingCta-info">
                    Enter the Referral Code for who referred you to us and get Rewards!
                  </p>
                  <form
                    className="PortfolioOnboardingCta-ReferralCodeForm"
                    onSubmit={handleSubmitReferralCode}
                  >
                    <label className="Label">
                      Referral Code
                      <input
                        className="Input"
                        onChange={(e) => setReferralCode(e.target.value)}
                        type="text"
                        value={referralCode}
                      />
                    </label>

                    <Button
                      disabled={!referralCode}
                      isLoading={isSubmittingReferralCode}
                      variant="secondary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </aside>
            )}
            {showCtaCards.FUND_YOUR_ACCOUNT && (
              <aside className={`PortfolioOnboardingCta PortfolioOnboardingCta-wide${numberOfVisibleCtaCards}`}>
                <button
                  onClick={() =>
                    handlePanelCloseButtonClick('FUND_YOUR_ACCOUNT')
                  }
                  className="u-buttonReset PortfolioOnboardingCta-close"
                >
                  <Icon name="x" width="32" height="32" />
                </button>
                <div className="PortfolioOnboardingCta-content">
                  <h1 className="PortfolioOnboardingCta-title">
                    Fund your account
                  </h1>
                  <p className="PortfolioOnboardingCta-info">
                    Connect a bank account, or add funds via wire or crypto
                    transfer.
                  </p>
                  <Button onClick={handleDepositFunds} variant="secondary">
                    Deposit funds
                  </Button>
                </div>
                <div className="PortfolioOnboardingCta-figure">
                  <Icon name="bank" width="80" height="80" />
                </div>
              </aside>
            )}
            {showCtaCards.START_COPYCAT_TRADING && (
              <aside className={`PortfolioOnboardingCta PortfolioOnboardingCta-wide${numberOfVisibleCtaCards}`}>
                <button
                  onClick={() =>
                    handlePanelCloseButtonClick('START_COPYCAT_TRADING')
                  }
                  className="u-buttonReset PortfolioOnboardingCta-close"
                >
                  <Icon name="x" width="32" height="32" />
                </button>
                <div className="PortfolioOnboardingCta-content">
                  <h1 className="PortfolioOnboardingCta-title">
                    Start Copycat Trading
                  </h1>
                  <p className="PortfolioOnboardingCta-info">
                    Put your money to work, auto-trading in sync with an
                    experienced crypto trader.
                  </p>
                  <Button
                    onClick={() => handleFindATrader()}
                    variant="secondary"
                  >
                    Find a trader to copy
                  </Button>
                </div>
                <div className="PortfolioOnboardingCta-figure">
                  <Icon name="trading" width="80" height="80" />
                </div>
              </aside>
            )}
          </div>

          <AddFundsModal modal={addFundsModal} />
        </div>
      )}
    </React.Fragment>
  )
}

export default PortfolioOnboarding
