import React from 'react'
import './portfolioSummary.scss'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import Loading from '@pv3-shared-components/Loading'
import useCurrencyCount from '@pv3-hooks/useCurrencyCount'
import ClosedPositionsPanel from '../ClosedPositionsPanel'
import PortfolioOnboarding from '@pv3-components/PortfolioOnboarding'
import {isArrayNullOrEmpty} from "../../utils/utils";
import useMarketplaceDuck from "../../hooks/useMarketplaceDuck";
import LionSummaryPanel from "../LionSummaryPanel";
import { isNil } from 'lodash';

const PortfolioSummary = () => {
  const { accountBalances } = useWalletsDuck()
  const { strategies } = useStrategiesDuck()
  const { subscriptions } = useMarketplaceDuck();
  const portfolioValue = useCurrencyCount(accountBalances?.totalAccountBalanceUsd ? accountBalances.totalAccountBalanceUsd : null)

  return (
    <div className="PortfolioSummary-wrap">
      <section className="PortfolioSummary u-container">
        <div className="PortfolioSummary-headerWrap">
          <header className="PortfolioSummary-header">
            <h1 className="PortfolioSummary-heading">Portfolio Summary</h1>
            <div className="PortfolioSummary-totalDollars">
              {!isNil(accountBalances?.totalAccountBalanceUsd) ? (
                <strong className="PortfolioFigure-amount PortfolioFigure-amount--primary">
                  {portfolioValue || '$0.00'}
                </strong>
              ) : (
                <Loading />
              )}
              Total dollar value
            </div>
          </header>

          <LionSummaryPanel />
        </div>
        <PortfolioOnboarding />
        {(!isArrayNullOrEmpty(strategies) || !isArrayNullOrEmpty(subscriptions)) && <ClosedPositionsPanel />}
      </section>
    </div>
  )
}

export default PortfolioSummary
