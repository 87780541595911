import Websocket from '../utils/websocket'

export const subscribeToOpenPositionUpdates = (callback) => {
  const websocket = new Websocket(
    `${window.envConfig.COINLION_API_URL}frontoffice/ws/trading_tool`
  )
  websocket
    .connect()
    .then(() => {
      websocket.subscribe('PositionUpdates', null, null, callback)
    })
    .catch((error) => {
      console.log('error in trading tool websocket', error)
    })
}
