import { useDispatch, useSelector } from 'react-redux'
import {
  cancelBacktest as _cancelBacktest,
  clearApiSecret as _clearApiSecret,
  createApiKey as _createApiKey,
  clearBacktestData as _clearBacktestData,
  clearBacktestError as _clearBacktestError,
  clearDynamicValuesSettings as _clearDynamicValuesSettings,
  clearSaveStrategyErrors as _clearSaveStrategyErrors,
  clearStrategyFiltersData as _clearStrategyFiltersData,
  createApiSecret as _createApiSecret,
  createStrategy as _createStrategy,
  updateStrategy as _updateStrategy,
  deleteStrategy as _deleteStrategy,
  getActualMarketPerformance as _getActualMarketPerformance,
  getBacktestHistoryForStrategy as _getBacktestHistoryForStrategy,
  getBacktestScenarios as _getBacktestScenarios,
  getDashboardBacktestHistories as _getDashboardBacktestHistories,
  getIndicators as _getIndicators,
  getStrategies as _getStrategies,
  getStrategySignals as _getStrategySignals,
  runStrategyBacktest as _runStrategyBacktest,
  getSupportedIntervals as _getSupportedIntervals,
  getDashboardStats as _getDashboardStats,
  getDynamicValuesForIndicatorSettings as _getDynamicValuesForIndicatorSettings,
  getBuilder as _getBuilder,
  getApiKey as _getApiKey,
  initiateManualBuyForStrategy as _initiateManualBuyForStrategy,
  pinBacktestHistoryItem as _pinBacktestHistoryItem,
  unpinBacktestHistoryItem as _unpinBacktestHistoryItem,
  setBacktestStartDate as _setBacktestStartDate,
  setBacktestEndDate as _setBacktestEndDate,
  setBacktestSummary as _setBacktestSummary,
  setBacktestSummaryFromHistory as _setBacktestSummaryFromHistory,
  setDynamicValuesSettings as _setDynamicValuesSettings,
  setStrategyTimePeriodFilter as _setStrategyTimePeriodFilter,
  setStrategyPublisherFilter as _setStrategyPublisherFilter,
  setStrategyTradeTypeFilter as _setStrategyTradeTypeFilter,
  setStrategySortByFilter as _setStrategySortByFilter,
  setStrategyMarketsFilter as _setStrategyMarketsFilter,
  setStrategyOrderStatusFilter as _setStrategyOrderStatusFilter,
  setStrategySignalsFilters as _setStrategySignalsFilters,
  setStrategyTradeHistoryFilters as _setStrategyTradeHistoryFilters,
  setStrategyTradeHistoryStrategyId as _setStrategyTradeHistoryStrategyId,
  setTemporaryStrategySettings as _setTemporaryStrategySettings,
} from '@pv3-ducks/strategies'
import { unwrapResult } from '@reduxjs/toolkit'
import {isNil, startCase} from "lodash";
import clone from 'clone';

const useStrategiesDuck = () => {
  const dispatch = useDispatch()

  const {
    apiKey,
    apiSecret,
    backtestData,
    backtestError,
    backtestSummary,
    backtestStartDate,
    backtestEndDate,
    backtestHistory,
    backtestScenarios,
    dashboardBacktestHistories,
    dynamicValuesSettings,
    isGettingApiKey,
    strategies,
    builder,
    indicators,
    dashboardStats,
    createStrategyError,
    saveStrategyErrors,
    supportedIntervals,
    strategyTimePeriodFilter,
    strategyOrderStatusFilter,
    strategyPublisherFilter,
    strategyTradeTypeFilter,
    strategySortByFilter,
    strategyMarketsFilter,
    strategySignals,
    strategySignalsStrategyId,
    strategySignalsFilters,
    strategyTradeHistoryFilters,
    strategyTradeHistoryStrategyId,
    temporaryStrategySettings,
    getStrategiesRequestTime,
  } = useSelector((state) => state.strategies)

  const { data: profile } = useSelector((state) => state.profile);

  const cancelBacktest = () => {
    dispatch(_cancelBacktest());
  }

  const clearApiSecret = async () => {
    dispatch(_clearApiSecret());
  }

  const clearBacktestData = (data) => {
    dispatch(_clearBacktestData(data));
  }

  const clearBacktestError = () => {
    dispatch(_clearBacktestError());
  }

  const clearDynamicValuesSettings = () => {
    dispatch(_clearDynamicValuesSettings());
  }

  const clearSaveStrategyErrors = () => {
    dispatch(_clearSaveStrategyErrors());
  }

  const clearStrategyFiltersData = () => {
    dispatch(_clearStrategyFiltersData());
  }

  const createApiKey = async () => {
    dispatch(_createApiKey());
  }

  const createApiSecret = async () => {
    return await dispatch(_createApiSecret());
  }

  const createStrategy = async (strategy) => {
    const result = await dispatch(_createStrategy(strategy))
    unwrapResult(result)
    return result
  }

  const updateStrategy = (strategy) => dispatch(_updateStrategy(strategy))

  const deleteStrategy = (id) => dispatch(_deleteStrategy(id))

  const getActualMarketPerformance = async (data) => {
    return dispatch(_getActualMarketPerformance(data)).unwrap();
  }

  const getApiKey = async () => {
    return dispatch(_getApiKey());
  }

  const getBacktestHistoryForStrategy = (data) => {
    dispatch(_getBacktestHistoryForStrategy(data));
  }

  const getBacktestScenarios = () => {
    dispatch(_getBacktestScenarios());
  }

  const getDashboardBacktestHistories = () => {
    dispatch(_getDashboardBacktestHistories());
  }

  const getStrategies = async () => {
    const result = await dispatch(_getStrategies())
    unwrapResult(result)
  }

  const getStrategySignals = async (data) => {
    dispatch(_getStrategySignals(data));
  }

  const runStrategyBacktest = async (data) => {
    dispatch(_runStrategyBacktest(data));
  }

  const getSupportedIntervals = () => {
    dispatch(_getSupportedIntervals())
  }

  const getIndicators = () => {
    dispatch(_getIndicators())
  }

  const getDashboardStats = () => {
    dispatch(_getDashboardStats())
  }

  const getDynamicValuesForIndicatorSettings = (data) => {
    return dispatch(_getDynamicValuesForIndicatorSettings(data));
  };

  const getBuilder = () => {
    dispatch(_getBuilder())
  }

  const numerifyStrategySettings = (settings) => {
    const findFieldType = (indicatorName, fieldName) => {
      const indicator = indicators.find((ind) => ind.indicatorName === indicatorName);
      if(!indicator || !indicator.fields) {
        return;
      }
      const field = indicator.fields.find((f) => f.name === fieldName);
      return field?.type;
    };

    const traverseLevel = (obj, indicatorName) => {
      Object.keys(obj).forEach((key) => {
        if(!indicatorName && key === 'indicator') {
          indicatorName = obj[key];
        }
        if(!isNil(obj[key]) && typeof obj[key] === 'object') {
          traverseLevel(obj[key], indicatorName);
        } else {
          if(typeof(obj[key]) === 'string' && !isNaN(Number(obj[key]))) {
            if(key !== 'notes' && findFieldType(indicatorName, key) !== 'decimal') {
              obj[key] = Number(obj[key]);
            }
          }
        }
      });
    }

    settings = clone(settings);
    traverseLevel(settings);
    return settings;
  };

  const initiateManualBuyForStrategy = (data) => dispatch(_initiateManualBuyForStrategy(data));

  const structureTriggerFieldValues = (trigger) => {
    let structured = {};

    let indicator = indicators.find((ind) => ind.indicatorName === trigger.indicatorSettings?.indicator);

    if(!indicator) {
      return structured;
    }

    structured.name = indicator.displayName || indicator.indicatorName;
    structured.fields = [];

    if(trigger.value) {
      structured.fields.push(`Value=${trigger.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}`);
    }

    indicator.fields.forEach((field) => {
      let label = field.label;
      let value = trigger.indicatorSettings[field.name];

      if(field.name === 'interval') {
        value = supportedIntervals[value];
      }

      if(field.name === 'compareDirection') {
        label = 'Compare';
      }

      if(label === 'EMA Length') {
        label = 'Length';
      }

      if(field.type === 'enum') {
        const foundValue = field.values && field.values.find((v) => v.value === value);
        if(foundValue) {
          value = foundValue.label;
        }
      }

      if(!isNil(value) && value !== false) {
        structured.fields.push(`${startCase(label)}=${value}`);
      }
    });

    if(trigger.indicatorSettings.required) {
      structured.fields.push(`Required=true`);
    }

    return structured;
  }

  const pinBacktestHistoryItem = (data) => {
    dispatch(_pinBacktestHistoryItem(data));
  }

  const unpinBacktestHistoryItem = (data) => {
    dispatch(_unpinBacktestHistoryItem(data));
  }

  const setBacktestStartDate = (data) => {
    dispatch(_setBacktestStartDate(data));
  }

  const setBacktestEndDate = (data) => {
    dispatch(_setBacktestEndDate(data));
  }

  const setBacktestSummary = (data) => {
    dispatch(_setBacktestSummary(data));
  }

  const setBacktestSummaryFromHistory = (data) => {
    dispatch(_setBacktestSummaryFromHistory(data));
  }

  const setStrategyTimePeriodFilter = (data) => {
    dispatch(_setStrategyTimePeriodFilter(data));
  }

  const setDynamicValuesSettings = (data) => {
    dispatch(_setDynamicValuesSettings(data));
  }

  const setStrategyPublisherFilter = (data) => {
    dispatch(_setStrategyPublisherFilter(data));
  }

  const setStrategySignalsFilters = (data) => {
    dispatch(_setStrategySignalsFilters(data));
  }

  const setStrategyTradeHistoryFilters = (data) => {
    dispatch(_setStrategyTradeHistoryFilters(data));
  }

  const setStrategyTradeHistoryStrategyId = (data) => {
    dispatch(_setStrategyTradeHistoryStrategyId(data));
  }

  const setStrategyTradeTypeFilter = (data) => {
    dispatch(_setStrategyTradeTypeFilter(data));
  }

  const setStrategySortByFilter = (data) => {
    dispatch(_setStrategySortByFilter(data));
  }

  const setStrategyMarketsFilter = (data) => {
    dispatch(_setStrategyMarketsFilter(data));
  }

  const setStrategyOrderStatusFilter = (data) => {
    dispatch(_setStrategyOrderStatusFilter(data));
  }

  const setTemporaryStrategySettings = (data) => {
    dispatch(_setTemporaryStrategySettings(data));
  }

  const testSettingsForDynamicValues = (settingsToTest, accessString, { baseAsset, homeQuoteAsset }) => {


    return {
      accessString,
      indicatorSettings: settingsToTest,
      baseAsset: baseAsset,
      quoteAsset: homeQuoteAsset || profile.homeQuoteAsset,
      shouldGetDynamicValues: /PositionLossPercent|PositionProfitPercent|PercentGainLoss/.test(settingsToTest.indicator) === false,
      hasValue: false,
      value: null,
    };
  };

  return {
    apiKey,
    apiSecret,
    backtestData,
    backtestError,
    backtestStartDate,
    backtestEndDate,
    backtestSummary,
    backtestHistory,
    backtestScenarios,
    cancelBacktest,
    clearApiSecret,
    clearBacktestData,
    clearBacktestError,
    clearDynamicValuesSettings,
    clearSaveStrategyErrors,
    clearStrategyFiltersData,
    createApiKey,
    createApiSecret,
    createStrategy,
    dashboardBacktestHistories,
    dynamicValuesSettings,
    updateStrategy,
    deleteStrategy,
    getActualMarketPerformance,
    getApiKey,
    getBacktestHistoryForStrategy,
    getBacktestScenarios,
    getDashboardBacktestHistories,
    getStrategies,
    getStrategySignals,
    getSupportedIntervals,
    getDashboardStats,
    getDynamicValuesForIndicatorSettings,
    isGettingApiKey,
    initiateManualBuyForStrategy,
    strategies,
    getIndicators,
    indicators,
    dashboardStats,
    getBuilder,
    builder,
    createStrategyError,
    numerifyStrategySettings,
    supportedIntervals,
    strategySignals,
    strategySignalsStrategyId,
    structureTriggerFieldValues,
    runStrategyBacktest,
    pinBacktestHistoryItem,
    unpinBacktestHistoryItem,
    temporaryStrategySettings,
    saveStrategyErrors,
    setBacktestStartDate,
    setBacktestEndDate,
    setBacktestSummary,
    setBacktestSummaryFromHistory,
    setDynamicValuesSettings,
    setStrategyTimePeriodFilter,
    setStrategyPublisherFilter,
    setStrategyTradeTypeFilter,
    setStrategySortByFilter,
    setStrategyMarketsFilter,
    setStrategyOrderStatusFilter,
    setStrategySignalsFilters,
    setStrategyTradeHistoryFilters,
    setStrategyTradeHistoryStrategyId,
    setTemporaryStrategySettings,
    getStrategiesRequestTime,
    strategyTimePeriodFilter,
    strategyOrderStatusFilter,
    strategyPublisherFilter,
    strategyTradeTypeFilter,
    strategySortByFilter,
    strategyMarketsFilter,
    strategySignalsFilters,
    strategyTradeHistoryFilters,
    strategyTradeHistoryStrategyId,
    testSettingsForDynamicValues,
  }
}

export default useStrategiesDuck
