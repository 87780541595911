import './modal.scss';

import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { eq, gt, isNil, isUndefined } from 'lodash';

import useClickAway from '../../../hooks/useClickAway';
import useScrollLock from '../../../hooks/useScrollLock';

import Icon from '../Icons';
import Button, { ButtonVariants } from '../Button';

type Props = {
  className?: string
  modal: {
    show: boolean,
    handleClose: () => void,
    wide: boolean,
    setWide: (show: boolean) => void,
    defaultScreen: string,
  }
  onBackPress?: () => void
  screens: any
}

const Modal: React.FC<Props> = ({
  className,
  modal,
  onBackPress,
  screens,
}) => {
  const [stack, setStack] = useState([])
  const [heading, setHeading] = useState()
  const [body, setBody] = useState()
  const [lockScroll, unlockScroll] = useScrollLock(window.pageYOffset)
  const modalRef = useRef(null)
  const { show, handleClose, wide, setWide } = modal || {}

  useClickAway([modalRef], () => {
    if (show && !isNil(handleClose)) handleClose()
  })

  const hideBackButton = useMemo(() => {
    if (isUndefined(screens[stack[stack.length - 1]])) return
    return eq(stack.length, 1) || screens[stack[stack.length - 1]].noBackButton
  }, [stack, screens])

  const pushStack = (screenIndex) => {
    modal.setWide(false)
    setStack([...stack, screenIndex])
  }

  const currentScreen = (index) => {
    if (screens[index].body instanceof Function) {
      return screens[index].body(pushStack, handleBack)
    } else {
      return screens[index].body
    }
  }

  const handleBack = () => {
    setWide(false)
    if (gt(stack.length, 1)) {
      setStack(stack.slice(0, stack.length - 1))

      onBackPress && onBackPress();
    } else {
      handleClose()
    }
  }

  useEffect(() => {
    if (eq(stack.length, 0)) return
    setBody(currentScreen(stack[stack.length - 1]))
    setHeading(screens[stack[stack.length - 1]].heading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack])

  useEffect(() => {
    if (show) {
      setStack([modal.defaultScreen || 'MAIN'])
    } else {
      setStack([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    if (eq(stack.length, 0)) return
    setBody(currentScreen(stack[stack.length - 1]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener('keyup', handleEsc)
    return () => {
      window.removeEventListener('keyup', handleEsc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (show) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return unlockScroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  if (isUndefined(modal) || !show || eq(stack.length, 0)) return <React.Fragment />

  return (
    <div className={`Modal-overlay ${className || ''}`}>
      <div className={`Modal ${wide ? 'Modal--wide' : ''}`} ref={modalRef}>
        <div className="Modal-header">
          <Button
            className="Button--icon"
            onClick={handleBack}
            style={{
              visibility: `${hideBackButton ? 'hidden' : 'visible'}`,
            }}
            variant={ButtonVariants.Quaternary}
          >
            <Icon name="arrowleft" />
          </Button>
          {heading}
          <Button
            className="Button--icon"
            onClick={handleClose}
            variant={ButtonVariants.Quaternary}
          >
            <Icon name="x" fill="#500078" />
          </Button>
        </div>
        <div className="Modal-body">{body}</div>
      </div>
    </div>
  )
}

export default Modal
