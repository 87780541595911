import React from 'react'

const Bank = ({ fill = '#fff', width = '80', height = '80', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.778 32.355L40 13.845l32.222 18.51a2.222 2.222 0 002.222-3.844L40 8.71 5.555 28.51a2.222 2.222 0 102.223 3.844zM8.888 60c0 1.227.996 2.222 2.223 2.222h57.778a2.222 2.222 0 100-4.444h-6.667V41.4h-4.444v16.378H42.222V41.4h-4.444v16.378H22.222V41.4h-4.444v16.378H11.11A2.222 2.222 0 008.889 60zm60.045-26.667H11.155v4.445h57.778v-4.445zM6.667 66.667a2.222 2.222 0 100 4.444h66.666a2.222 2.222 0 100-4.445H6.667zm49.688-38.712h-7.133l-8.689-4.977-8.666 4.977h-7.134l14.934-8.577a1.777 1.777 0 011.777 0l14.911 8.577z"
        fill={fill}
      />
    </svg>
  )
}

export default Bank
