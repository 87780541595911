import './myReferralsInfo.scss';

import * as React from 'react';

import { useTypedSelector } from "../../../../../../../ducks";

import { ReferralRewardAction } from "../../../../../../../types/ReferralRewardAction";

type Props = {
  levels: {
    invitedLevels: Array<ReferralRewardAction>
    publisherLevels: Array<ReferralRewardAction>
  }
}

const MyReferralsInfo: React.FC<Props> = ({
  levels,
}) => {
  const { referralCount, referralTotalEarned} = useTypedSelector((state) => state.rewards);

  const { invitedLevels, publisherLevels } = levels;

  const generateInvitedTotal = () => {
    let total = 0;

    invitedLevels?.forEach((level) => {
      total += level.value1Numeric;
    });

    return total.toLocaleString();
  };

  const generatePublisherTotal = () => {
    let total = 0;

    publisherLevels?.forEach((level) => {
      total += level.value1Numeric;
    });

    return total.toLocaleString();
  };

  return (
    <div className="MyReferrals-Info">
      <h3>
        My Referrals
      </h3>

      <p>
        Each invited friend let you earn up to {generateInvitedTotal()} LION complete those steps.
      </p>

      <div className="MyReferrals-Table">
        {invitedLevels?.map((level) => (
          <div className="MyReferrals-TableRow">
            <div className="MyReferrals-TableValue">
              {level.value1Numeric.toLocaleString()} LION
            </div>

            <div className="MyReferrals-TableLabel">
              {level.description}
            </div>
          </div>
        ))}
      </div>

      <p>
        Each invited friend who become a Publisher let you earn up to {generatePublisherTotal()} LION complete those steps.
      </p>

      <div className="MyReferrals-Table">
        {publisherLevels.map((level) => (
          <div className="MyReferrals-TableRow">
            <div className="MyReferrals-TableValue">
              {level.value1Numeric.toLocaleString()} LION
            </div>

            <div className="MyReferrals-TableLabel">
              {level.description}
            </div>
          </div>
        ))}
      </div>

      <footer className="MyReferrals-Footer">
        <div className="MyReferrals-FooterStats">
          <div className="MyReferrals-FooterRow">
            <div className="MyReferrals-FooterValue">
              {referralTotalEarned.toLocaleString()}
            </div>

            <div className="MyReferrals-FooterLabel">
              Total Earned LION
            </div>
          </div>

          <div className="MyReferrals-FooterRow">
            <div className="MyReferrals-FooterValue">
              {referralCount.toLocaleString()}
            </div>

            <div className="MyReferrals-FooterLabel">
              Friends you have referred
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MyReferralsInfo;
