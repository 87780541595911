import { useEffect } from 'react'
import { isEmpty, round } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBalancer as _updateBalancer,
  getBalancer as _getBalancer,
} from '@pv3-ducks/balancer'

const useBalancerDuck = () => {
  const dispatch = useDispatch()
  const balancer = useSelector((state) => state.balancer)

  const updateBalancer = ({
    lionBalancerEnabled,
    minimumLionBalancePercent,
    lionTradePercentGainLoss,
  }) => {
    dispatch(
      _updateBalancer({
        lionBalancerEnabled,
        minimumLionBalancePercent: round(minimumLionBalancePercent / 100, 4),
        lionTradePercentGainLoss: round(lionTradePercentGainLoss / 100, 4),
      })
    )
  }

  useEffect(() => {
    if (!isEmpty(balancer)) return
    dispatch(_getBalancer())
  }, [balancer, dispatch])

  return {
    updateBalancer,
    isLionBalancerEnabled: balancer.lionBalancerEnabled,
    minimumLionBalancePercent: round(
      balancer.minimumLionBalancePercent * 100,
      2
    ),
    lionTradePercentGainLoss: round(balancer.lionTradePercentGainLoss * 100, 2),
  }
}

export default useBalancerDuck;
