import React from 'react'

const CaretLeft = ({
  fill = '#737373',
  width = '20',
  height = '21',
  ...rest
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.1408 5.70244L6.66663 10.5021L11.1408 15.3017C11.3948 15.5732 11.7989 15.5649 12.0436 15.283C12.2882 15.0011 12.2806 14.5525 12.0267 14.281L8.5021 10.5021L12.0267 6.71903C12.2806 6.44751 12.2882 5.9989 12.0436 5.71702C11.7989 5.43515 11.3948 5.42675 11.1408 5.69827V5.70244Z"
        fill="#500078"
      />
    </svg>
  )
}

export default CaretLeft
