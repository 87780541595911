import React from 'react'

const Copy = ({ fill = '#000', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 2C25.3284 2 26 2.67157 26 3.5V4H6V29H5.5C4.67157 29 4 28.3284 4 27.5V3.5C4 2.67157 4.67157 2 5.5 2H24.5ZM29.5 7H10.5C9.67157 7 9 7.67157 9 8.5V32.5C9 33.3284 9.67157 34 10.5 34H29.5C30.3284 34 31 33.3284 31 32.5V8.5C31 7.67157 30.3284 7 29.5 7ZM29 32H11V9H29V32Z"
        fill={fill}
      />
    </svg>
  )
}

export default Copy
