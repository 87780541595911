import React from 'react'

const Check = ({ fill = '#500078', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83998 16.6049C7.25438 16.6468 8.63023 16.1404 9.67998 15.1916C9.88096 14.9946 9.99597 14.7263 9.99998 14.4449C10.0072 14.1842 9.90808 13.9317 9.72532 13.7456C9.54255 13.5595 9.29193 13.4557 9.03109 13.4582C8.79981 13.4473 8.57338 13.527 8.39998 13.6805C7.69232 14.2699 6.79639 14.5854 5.87554 14.5694C4.30799 14.6293 2.96273 13.4629 2.79998 11.9027H9.49331C9.80829 11.9102 10.1125 11.7877 10.3344 11.5641C10.5564 11.3405 10.6765 11.0354 10.6666 10.7205C10.7252 9.3371 10.2131 7.99038 9.25012 6.99548C8.28715 6.00059 6.95785 5.44482 5.57331 5.45825C4.13801 5.48799 2.77531 6.09511 1.79333 7.14233C0.81135 8.18956 0.293018 9.58844 0.355536 11.0227C0.285574 12.5064 0.837543 13.9521 1.87854 15.0117C2.91954 16.0712 4.35525 16.6486 5.83998 16.6049ZM5.55554 7.45825C7.17331 7.45825 8.10665 8.6938 8.2222 10.2671H2.7822C3.00442 8.6138 4.05331 7.45825 5.55554 7.45825ZM11.4933 7.11158H30.2222C30.7131 7.11158 31.1111 7.50955 31.1111 8.00047V24.0005C31.1111 24.4914 30.7131 24.8894 30.2222 24.8894H1.77776C1.28684 24.8894 0.888869 24.4914 0.888869 24.0005V16.4982C1.41652 16.9846 2.0162 17.3863 2.66665 17.6894V23.1116H29.3333V8.88936H12.1955C12.0391 8.26897 11.803 7.67143 11.4933 7.11158ZM17.7778 18.6671H27.5555V20.4449H17.7778V18.6671ZM27.5555 14.2227H14.2222V16.0005H27.5555V14.2227Z"
        fill={fill}
      />
    </svg>
  )
}

export default Check
