import React from 'react'

const Plus = ({ fill = '#500078', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M26.6667 15.112H16.8889V5.3342C16.8889 4.84328 16.4909 4.44531 16 4.44531C15.5091 4.44531 15.1111 4.84328 15.1111 5.3342V15.112H5.33332C4.8424 15.112 4.44443 15.5099 4.44443 16.0009C4.43556 16.2346 4.52829 16.4607 4.69872 16.6209C4.86915 16.7811 5.10058 16.8597 5.33332 16.8364H15.1111V26.6675C15.1111 27.1585 15.5091 27.5564 16 27.5564C16.4909 27.5564 16.8889 27.1585 16.8889 26.6675V16.8898H26.6667C27.1576 16.8898 27.5555 16.4918 27.5555 16.0009C27.5555 15.5099 27.1576 15.112 26.6667 15.112Z"
        fill="#500078"
      />
    </svg>
  )
}

export default Plus
