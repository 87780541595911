import { useEffect, useState } from 'react'
import { eq, isNull } from 'lodash'
import { formatAsDollarAmount } from '../utils/utils'

const useCurrencyCount = (currencyAmount) => {
  const [value, setValue] = useState(currencyAmount)
  const [shownValue, setShownValue] = useState(
    formatAsDollarAmount(currencyAmount)
  )
  const easeOutQuad = (t) => t * (2 - t)
  const frameDuration = 1000 / 60
  let counter

  useEffect(() => {
    // H/T to https://jshakespeare.com/simple-count-up-number-animation-javascript-react/
    if (!eq(currencyAmount, value) || isNaN(value)) {
      clearInterval(counter)
      const priorValue = value
      const countTo = parseFloat(currencyAmount)
      setValue(parseFloat(countTo))

      if (isNull(priorValue) || !document.hasFocus()) {
        // Do not animate number if page is not in focus (or if prior number was null)
        clearInterval(counter)
        setShownValue(formatAsDollarAmount(countTo))
      } else {
        let frame = 0
        const totalFrames = Math.round(800 / frameDuration)

        // eslint-disable-next-line
        counter = setInterval(() => {
          frame++
          const progress = easeOutQuad(frame / totalFrames)
          const delta = countTo - value
          setShownValue(formatAsDollarAmount(priorValue + delta * progress))

          if (frame >= totalFrames) {
            clearInterval(counter)
          }
        }, frameDuration)
      }
    }
  }, [currencyAmount])

  return shownValue
}

export default useCurrencyCount
