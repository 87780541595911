import './hodlList.scss';

import * as React from 'react';
import { useMemo, useState } from 'react';
import * as moment from "moment";
import { isNil } from "lodash";

import HodlEnrollment from "../../../../../../../types/HodlEnrollment";
import HodlProgram from "../../../../../../../types/HodlProgram";

import { isArrayNullOrEmpty } from '../../../../../../../utils/utils';

import { deleteHodlEnrollment, getHodlUserTotals, upgradeHodlEnrollment } from "../../../../../../../ducks/rewards";
import useModal from "../../../../../../../hooks/useModal";
import { useAppDispatch, useTypedSelector } from "../../../../../../../ducks";

import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from "../../../../../../shared/Button";
import Datepicker from '../../../../../../shared/Datepicker';
import InputError from "../../../../../../shared/InputError";
import Modal from "../../../../../../shared/Modal";
import ProgressBar from "../../../../../../shared/ProgressBar";
import Pagination from "../../../../../../shared/Pagination";
import Select, { SelectOption } from "../../../../../../shared/Select";

const PAGE_SIZE = 8;

const filterForFundsSourceOptions: Array<SelectOption> = [
  {
    label: 'All Sources',
    value: ''
  }, {
    label: 'My Funds',
    value: 'account',
  }, {
    label: 'Rewards',
    value: 'rewards',
  },
];

const filterForStatusOptions: Array<SelectOption> = [
    {
        label: 'All Statuses',
        value: ''
    }, {
        label: 'Ready to Be Claimed',
        value: 'mature',
    }, {
        label: 'Still Pending',
        value: 'pending',
    },
];

const HodlList: React.FC = () => {
  const dispatch = useAppDispatch();

  const closeModal = useModal();
  const upgradeModal = useModal();

  const [enrollmentToMutate, setEnrollmentToMutate] = useState<HodlEnrollment | undefined>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [newProgram, setNewProgram] = useState<HodlProgram | undefined>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [filterForFundsSource, setFilterForFundsSource] = useState<string>('');
  const [filterForStatus, setFilterForStatus] = useState<string>('');
  const [filterStartDate, setFilterStartDate] = useState<string | undefined>();
  const [filterEndDate, setFilterEndDate] = useState<string | undefined>();

  const { enrollments, programs } = useTypedSelector((state) => state.rewards);

  const handleUpgradeClick = (row: HodlEnrollment, newDuration: string) => {
    setEnrollmentToMutate(row);
    setNewProgram(programs.find((program) => program.value1 === newDuration));
    upgradeModal.setShow(true);
  };

  const doClose = async () => {
    try {
      setIsMutating(true);
      setErrorMsg('');
      await dispatch(deleteHodlEnrollment({enrollmentId: enrollmentToMutate.id})).unwrap();
      await dispatch(getHodlUserTotals()).unwrap();
      closeModal.setShow(false);
      setEnrollmentToMutate(undefined);
    } catch(err) {
      console.log('HodlList doClose error', err);
      setErrorMsg('There was an error attempting to cancel this HODL. Please try again.');
    } finally {
      setIsMutating(false);
    }
  };

  const doUpgrade = async () => {
    try {
      setIsMutating(true);
      setErrorMsg('');
      await dispatch(upgradeHodlEnrollment({enrollmentId: enrollmentToMutate.id, newProgramId: newProgram.id})).unwrap();
      await dispatch(getHodlUserTotals()).unwrap();
      upgradeModal.setShow(false);
      setEnrollmentToMutate(undefined);
    } catch(err) {
      console.log('HodlList doUpgrade error', err);
      setErrorMsg('There was an error attempting to upgrade this HODL. Please try again.');
    } finally {
      setIsMutating(false);
    }
  };

  const filteredEnrollments = useMemo(
    () => {
      if(isArrayNullOrEmpty(enrollments)) {
        return [];
      }

      return enrollments.filter((enrollment) => enrollment.isActive && !enrollment.parentId);
    }, [enrollments]
  );

  const enrollmentsToDisplay = useMemo(
  () => {
      if(isArrayNullOrEmpty(filteredEnrollments)) {
        return [];
      }

      let enrollmentsList = filteredEnrollments;

    if(filterForFundsSource) {
      enrollmentsList = enrollmentsList.filter((enrollment) => {
        if(filterForFundsSource === 'account') {
          return isNil(enrollment.rewardId);
        } else if(filterForFundsSource === 'rewards') {
          return !isNil(enrollment.rewardId);
        }

        return true;
      });
    }

      if(filterForStatus) {
        enrollmentsList = enrollmentsList.filter((enrollment) => {
          if(filterForStatus === 'mature') {
            return moment(enrollment.matureDate).isBefore(moment());
          } else if(filterForStatus === 'pending') {
            return moment(enrollment.matureDate).isAfter(moment());
          }

          return true;
        });
      }

      if(filterStartDate && filterEndDate) {
        enrollmentsList = enrollmentsList.filter((enrollment) => {
          return moment(enrollment.startDate).isBetween(moment(filterStartDate), moment(filterEndDate));
        });
      }

      return enrollmentsList;
    }, [filteredEnrollments, filterForFundsSource, filterForStatus, filterEndDate, filterStartDate]
  );

  const pageOfData = useMemo(
    () => {
      if(isArrayNullOrEmpty(enrollmentsToDisplay)) {
        return [];
      }

      let enrollmentsList = enrollmentsToDisplay.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);

      if(enrollmentsList.length === 0 && pageNumber > 0) {
        setPageNumber(pageNumber - 1);
      }

      return enrollmentsList;
    }, [enrollmentsToDisplay, pageNumber]
  );

  return (
    <section className="HodlList">
      <div className="HodlList-Filters">
        {/* @ts-ignore */}
        <Datepicker
          endDate={filterEndDate}
          maxDate={new Date()}
          placeholderText="Filter by Start Date"
          startDate={filterStartDate}
          selectsRange
          onChange={(dates) => {
            setFilterStartDate(dates[0]);
            setFilterEndDate(dates[1]);
          }}
          isClearable
        />

        <Select
          onChange={(e) => setFilterForFundsSource(e.target.value)}
        >
          {filterForFundsSourceOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        <Select
          onChange={(e) => setFilterForStatus(e.target.value)}
        >
          {filterForStatusOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>

      <div className="HodlList-List">
        <div className="HodlList-Headers">
          <div className="HodlList-ListItem-Levels">
            <div className="HodlList-ListItem-Level">
              6 MONTH
            </div>

            <div className="HodlList-ListItem-Level">
              12 MONTH
            </div>

            <div className="HodlList-ListItem-Level">
              18 MONTH
            </div>
          </div>

          <div className="HodlList-ListItem-Details">
            REWARDS
          </div>
        </div>

        {pageOfData.length === 0 && (
          <p className="HodlList-EmptyState">
              {filteredEnrollments.length === 0 ? 'You have no LION HODL\'d yet' : 'No HODLs match your filters.'}
          </p>
        )}

        {pageOfData.map((enrollment) => {
          if(!enrollment.isActive || enrollment.parentId) {
            return null;
          }

          const program = programs.find((program) => program.id === enrollment.programId);

          let daysLeft = moment(enrollment.matureDate).diff(moment(), 'days');
          if(daysLeft < 0) {
            daysLeft = 0;
          }

          return (
            <div className="HodlList-ListItem" key={enrollment.id}>
              <div className="HodlList-ListItem-Levels">
                <div className="HodlList-ListItem-Level">
                  <div className="HodlList-ListItem-Level-Amount">
                    {program?.value1 === '6' ? `${enrollment.lionQuantity.toLocaleString(undefined, { maximumFractionDigits: 4 })} LION` : '--'}
                  </div>

                  <div className="HodlList-ListItem-Level-Label">
                    6 Month Program
                  </div>
                </div>

                <div className="HodlList-ListItem-Level">
                  <div className="HodlList-ListItem-Level-Amount">
                    {program?.value1 === '12' ? `${enrollment.lionQuantity.toLocaleString(undefined, { maximumFractionDigits: 4 })} LION` : '--'}
                  </div>

                  <div className="HodlList-ListItem-Level-Label">
                    12 Month Program
                  </div>

                  {program?.value1 === '6' && (
                    <div className="HodlList-ListItem-Level-Button">
                      <Button
                        onClick={() => handleUpgradeClick(enrollment, '12')}
                        variant={ButtonVariants.Secondary}
                      >
                        Upgrade
                      </Button>
                    </div>
                  )}
                </div>

                <div className="HodlList-ListItem-Level">
                  <div className="HodlList-ListItem-Level-Amount">
                    {program?.value1 === '18' ? `${enrollment.lionQuantity.toLocaleString(undefined, { maximumFractionDigits: 4 })} LION` : '--'}
                  </div>

                  <div className="HodlList-ListItem-Level-Label">
                    18 Month Program
                  </div>

                  {(program?.value1 === '6' || program?.value1 === '12') && (
                    <div className="HodlList-ListItem-Level-Button">
                      <Button
                        onClick={() => handleUpgradeClick(enrollment, '18')}
                        variant={ButtonVariants.Secondary}
                      >
                        Upgrade
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="HodlList-ListItem-Details">
                <div className="HodlList-ListItem-DetailsLeftCol">
                  <div className="HodlList-ListItem-RewardAmount">
                    {enrollment.totalRewardsLion ? enrollment.totalRewardsLion.toLocaleString() : '--'}
                  </div>

                  <div className="HodlList-ListItem-MaturityContainer">
                    <div className="HodlList-ListItem-DaysContainer">
                      <div>
                        Days left
                      </div>

                      <div className="HodlList-ListItem-DaysLeft">
                        {daysLeft}
                      </div>
                    </div>

                    <ProgressBar
                      progress={100 - (daysLeft / (moment(enrollment.matureDate).diff(moment(enrollment.startDate), 'days')) * 100)}
                    />

                    <div className="HodlList-ListItem-DaysContainer">
                      <div>
                        {moment(enrollment.startDate).format('MM.DD.YY HH:mm:ss')}
                      </div>

                      <div>
                        {moment(enrollment.matureDate).format('MM.DD.YY HH:mm:ss')}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="HodlList-ListItem-Button">
                  {daysLeft === 0 ? (
                    <Button
                      onClick={() => null}
                    >
                      Claim
                    </Button>
                  ) : (
                    <>
                      {!enrollment.isHardLocked && (
                        <Button
                          onClick={() => {
                            setEnrollmentToMutate(enrollment);
                            closeModal.setShow(true);
                          }}
                          variant={ButtonVariants.Secondary}
                        >
                          Close
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {filteredEnrollments.length > PAGE_SIZE && (
          <Pagination
            forcePage={pageNumber}
            onPageChange={({ selected }) => setPageNumber(selected)}
            pageCount={Math.ceil(enrollmentsToDisplay.length / PAGE_SIZE)}
          />
        )}
      </div>

      <Modal
        className="HodlList-modal"
        modal={closeModal}
        screens={{
          MAIN: {
            heading: () => <h4>Confirm HODL Cancellation</h4>,
            body: () => (
              <React.Fragment>
                <p>
                  If you cancel this HODL you will retain 100% of your principal LION amount of {enrollmentToMutate?.lionQuantity?.toLocaleString()}. However, you will forfeit all rewards tied to this specific HODL.
                </p>

                {errorMsg && (
                  <p>
                    <InputError message={errorMsg} />
                  </p>
                )}

                <Button
                  className="HodlList-modal-button"
                  isLoading={isMutating}
                  onClick={doClose}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  variant={ButtonVariants.Primary}
                >
                  OK
                </Button>

                <Button
                  disabled={isMutating}
                  onClick={() => {
                    closeModal.setShow(false);
                    setEnrollmentToMutate(undefined);
                    setErrorMsg('');
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  variant={ButtonVariants.Secondary}
                >
                  Cancel
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />

      <Modal
        className="HodlList-modal"
        modal={upgradeModal}
        screens={{
          MAIN: {
            heading: () => <h4>Confirm Upgrade</h4>,
            body: () => (
              <React.Fragment>
                <p>
                  By upgrading you will keep your initial start date; extend the end date; and participate in the rewards from this new {newProgram?.value1}mo program.
                </p>

                {errorMsg && (
                  <p>
                    <InputError message={errorMsg} />
                  </p>
                )}

                <Button
                  className="HodlList-modal-button"
                  isLoading={isMutating}
                  onClick={doUpgrade}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  variant={ButtonVariants.Primary}
                >
                  OK
                </Button>

                <Button
                  disabled={isMutating}
                  onClick={() => {
                    upgradeModal.setShow(false);
                    setEnrollmentToMutate(undefined);
                    setNewProgram(undefined);
                    setErrorMsg('');
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  variant={ButtonVariants.Secondary}
                >
                  Cancel
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />
    </section>
  );
};

export default HodlList;
