import './A.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const A = ({children, className, disabled, state, to, ...otherProps}) => {
    if(to != null) {
        return (
            <Link className={`c-link a-link--to ${className || ''} ${disabled ? 'a-link--disabled' : ''}`}
                  to={{
                      pathname: to,
                      state
                  }}
                  {...otherProps}>
                {children}
            </Link>
        );
    } else {
        return (
            <a className={`c-link a-link--href ${className || ''}`}
               {...otherProps} >
                {children}
            </a>
        );
    }
};

A.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    noLinkClass: PropTypes.bool,
    state: PropTypes.object,
    target: PropTypes.string,
    to: PropTypes.string
};

export default A;
