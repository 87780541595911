import './select.scss';

import * as React from 'react';
import { isNull, isUndefined, map } from 'lodash';
import { get, useFormContext } from "react-hook-form";
import InputError from '../InputError';

export type SelectOption = {
  label: string
  value: number | string
};

type Props = {
  ariaLabel?: string
  children?: React.ReactNode
  containerClassName?: string
  defaultValue?: string
  id?: string
  isForm?: boolean
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options?: Array<SelectOption>
  register?: any
  value?: string
}

const Select: React.FC<Props> = ({
  children,
  options,
  ariaLabel,
  isForm,
  name,
  register,
  containerClassName,
  ...rest
}) => {
  const formContext = useFormContext();

  let errors;

  if (!isNull(formContext)) {
    errors = get(formContext, 'formState.errors');
  }

  return (
    <div
      className={`Select ${isForm ? `Select--form` : ``} ${
        !isUndefined(containerClassName) ? containerClassName : ''
      }`}
    >
      <select
          aria-label={ariaLabel || undefined}
          {...register}
          {...rest}
      >
        {children
          ? children
          : map(options, ({ label, value }) => (
              <option
                  key={value}
                  value={value}
              >
                {label}
              </option>
            ))}
      </select>

      <svg
        aria-hidden="true"
        className="Select-caret"
        fill="none"
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.798 8.304l-4.8 4.474-4.8-4.474a.595.595 0 01.019-.903.765.765 0 011.002.017l3.779 3.524 3.783-3.524a.765.765 0 011.002-.017c.282.245.29.649.019.903h-.004z"
          fill="#323232"
        />
      </svg>

      {name && errors && errors[name] && <InputError message={errors[name].message} />}
    </div>
  )
}

export default Select
