import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Request from '../utils/request'
import { errorFromAxios } from './mappers'
import { API_PATHS } from '@pv3-constants'

const request = new Request()

export const signInCoin = createAsyncThunk(
  'session/signInCoin',
  async ({ accessToken, idToken, expiresAt, loadTest }, { rejectWithValue }) => {
    try {
      const response = await request.post(API_PATHS.COIN.SIGN_IN, {
        accessToken,
        expiresAt,
        idToken,
      })

      return { userId: response.data.userId, loadTest, accessToken, idToken, expiresAt };
    } catch (error) {
      return rejectWithValue(errorFromAxios(error))
    }
  }
)

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    accessToken: null,
    expiresAt: null,
    idToken: null,
    loadTesting: null,
    loggedInUserId: null,
  },
  reducers: {},
  extraReducers: {
    [signInCoin.fulfilled]: (state, action) => {
      state.loggedInUserId = action.payload.userId;
      state.loadTesting = action.payload.loadTest;
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt * 1000;
      state.idToken = action.payload.idToken;
    },
  },
})

export default accountSlice.reducer
