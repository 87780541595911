import {
  find,
  eq,
  split,
  upperCase,
  gt,
  round,
  isUndefined,
  gte,
  map,
  isNil,
  chain,
} from 'lodash'
import React, { useEffect, useMemo } from 'react'
import useMarketsDuck from '../../hooks/useMarketsDuck'
import { formatNumber } from '../../utils/utils'
import AsksAndBidsTable from '../AsksAndBidsTable'
import MarketTradesTable from '../MarketTradesTable'
import './orderbook.scss'
import ComponentLoader from '@pv3-components/ComponentLoader'

const OrderBook = ({ instrument, isInverted, isSellSide }) => {
  const {
    tickerData,
    orderBooks,
    trades: _trades,
    startOrderBook,
    startTradesByMarket,
  } = useMarketsDuck()


  // show LION-USDC order book if trading for LION with USD or USDT
  // CLV3-889
  if(/lion_usd|lion_usdt/.test(instrument)) {
    instrument = 'lion_usdc';
  }

  const [asset1, asset2] = useMemo(() => split(instrument, '_'), [instrument])

  const { tickerOpen, tickerClose, tickerVolume } = useMemo(() => {
    const tickerObject = find(tickerData, ({ instrument: tdItemInstrument }) =>
      eq(instrument, tdItemInstrument)
    )
    if (isUndefined(tickerObject)) return {}
    if (isInverted) {
      return {
        tickerOpen: eq(tickerObject.open, 0) ? 0 : 1 / tickerObject.open,
        tickerClose: eq(tickerObject.close, 0) ? 0 : 1 / tickerObject.close,
        tickerVolume: tickerObject.volume,
      }
    } else {
      return {
        tickerOpen: tickerObject.open,
        tickerClose: tickerObject.close,
        tickerVolume: tickerObject.volume,
      }
    }
  }, [instrument, isInverted, tickerData])

  const priceChangePercentage = useMemo(() => {
    if (isUndefined(tickerOpen)) return
    return gt(tickerOpen, 0)
      ? round(((tickerClose - tickerOpen) / tickerOpen) * 100, 2)
      : 0
  }, [tickerOpen, tickerClose])

  const { asks, bids } = useMemo(() => {
    if (isUndefined(orderBooks[instrument])) return {}

    if (isInverted) {
      return {
        asks: map(orderBooks[instrument]['bids'], ({ price, amount }) => ({
          price: eq(price, 0) ? 0 : 1 / price,
          amount: price * amount,
          isAsk: true,
        })),
        bids: map(orderBooks[instrument]['asks'], ({ price, amount }) => ({
          price: eq(price, 0) ? 0 : 1 / price,
          amount: price * amount,
          isAsk: false,
        })),
      }
    } else {
      return {
        asks: chain(orderBooks[instrument]['asks'])
          .map((asks) => ({
            ...asks,
            isAsk: true,
          }))
          .reverse()
          .value(),
        bids: chain(orderBooks[instrument]['bids'])
          .map((asks) => ({
            ...asks,
            isAsk: false,
          }))
          .reverse()
          .value(),
      }
    }
  }, [orderBooks, instrument, isInverted])

  const trades = useMemo(() => {
    if (isInverted) {
      return map(
        _trades[instrument],
        ({ executionPrice, amount, side, tradeTime }) => ({
          executionPrice: eq(executionPrice, 0) ? 0 : 1 / executionPrice,
          amount: executionPrice * amount,
          side: !side,
          tradeTime,
        })
      )
    } else {
      return _trades[instrument]
    }
  }, [_trades, instrument, isInverted])

  useEffect(() => {
    if (isUndefined(instrument)) return
    if (isUndefined(orderBooks[instrument])) startOrderBook(instrument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBooks, instrument])

  useEffect(() => {
    if (isUndefined(instrument)) return
    if (isUndefined(_trades[instrument])) startTradesByMarket(instrument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_trades, instrument])

  return (
    <div className="OrderBook-container">
      <h5 className="OrderBook-header">Order Book</h5>
      <div className="OrderBook-content">
        <div className="OrderBook-tickerData">
          <ComponentLoader isLoading={isUndefined(tickerClose)} />

          <h4 className="OrderBook-instrument">
            <span className="asset1">{isInverted ? asset2 : asset1}-</span>
            {isInverted ? asset1 : asset2}
          </h4>
          {!isUndefined(tickerClose) && (
            <React.Fragment>
              <div className="OrderBook-tickerDataRow">
                <p className="OrderBook-tickerDataRowLabel">Last Price</p>
                <p className="OrderBook-tickerDataRowValue">
                  <span className="OrderBook-darkGreyText">
                    {formatNumber(tickerClose)}
                  </span>{' '}
                  {upperCase(isInverted ? asset1 : asset2)}
                </p>
              </div>
              <div className="OrderBook-tickerDataRow">
                <p className="OrderBook-tickerDataRowLabel">Change % (24h):</p>
                <p
                  className={`OrderBook-tickerDataRowValue ${
                    gte(priceChangePercentage, 0)
                      ? 'OrderBook-greenText'
                      : 'OrderBook-redText'
                  }`}
                >
                  {gte(priceChangePercentage, 0) && '+'}
                  {priceChangePercentage}%
                </p>
              </div>
              <div className="OrderBook-tickerDataRow">
                <p className="OrderBook-tickerDataRowLabel">Volume (24h):</p>
                <p className="OrderBook-tickerDataRowValue">
                  <span className="OrderBook-darkGreyText">{tickerVolume}</span>{' '}
                  {upperCase(asset1)}
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="OrderBook-asksAndBidsTableContainer">
          <ComponentLoader isLoading={isNil(asks)} />

          {!isUndefined(asks) && !isUndefined(bids) && (
            <AsksAndBidsTable
              asks={asks}
              bids={bids}
              lastPrice={tickerClose}
              isSellSide={isSellSide}
            />
          )}
        </div>
        <hr className="u-divider OrderBook-divider" />
        <div className="OrderBook-marketTradesTableContainer">
          <ComponentLoader isLoading={isUndefined(trades)} />

          {!isUndefined(trades) && <MarketTradesTable trades={trades} />}
        </div>
      </div>
    </div>
  )
}

export default OrderBook
