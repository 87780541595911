import Websocket from '../utils/websocket'

export const subscribeToMiniTicker = (callback) => {
  const websocket = new Websocket(
    `${window.envConfig.COINLION_API_URL}marketdata/info`
  )
  websocket
    .connect()
    .then(() => {
      websocket.subscribe('MiniTicker', '3', null, callback)
    })
    .catch((error) => {
      console.log('error in markets', error)
    })
}

export const subscribeToOrderBook = (marketPair, callback) => {
  const websocket = new Websocket(
    `${window.envConfig.COINLION_API_URL}marketdata/info`
  )
  websocket
    .connect()
    .then(() => {
      websocket.subscribe('Book', marketPair, null, callback)
    })
    .catch((error) => {
      console.log('error in markets', error)
    })
}

export const subscribeToTradesByMarket = (marketPair, callback) => {
  const websocket = new Websocket(
    `${window.envConfig.COINLION_API_URL}marketdata/info`
  )
  websocket
    .connect()
    .then(() => {
      websocket.subscribe('Trades', marketPair, null, callback)
    })
    .catch((error) => {
      console.log('error in markets', error)
    })
}
