import React, { useEffect, useMemo, useState } from 'react'
import Table from '@pv3-shared-components/Table'
import Button from '@pv3-shared-components/Button'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import {
  entries,
  eq,
  find,
  get,
  gt, isNil,
  isNull,
  isUndefined,
  map,
  reduce,
  round,
  upperCase,
} from 'lodash'
import Currency from '../shared/Currency'
import useAssetsDuck from '../../hooks/useAssetsDuck'
import './walletbalancestable.scss'
import useWindowWidth from '../../hooks/useWindowWidth'
import BuySellModal from '../BuySellModal'
import useModal from '../../hooks/useModal'
import AddFundsModal from '@pv3-components/AddFundsModal'
import { formatAsDollarAmount, formatNumber } from '../../utils/utils'
import Tooltip from '../shared/Tooltip'
import { useSelector } from 'react-redux'
import ComponentLoader from '../ComponentLoader'
import Logo from "../shared/Logo";
import { useAppDispatch, useTypedSelector } from '../../ducks';
import { getHodlUserTotals } from '../../ducks/rewards';

const WalletBalancesTable = () => {
  const dispatch = useAppDispatch();
  const { balances, usdPrices } = useWalletsDuck()
  const { assets } = useAssetsDuck()
  const openOrders = useSelector((state) => state.trades.openOrders)
  const isBig = useWindowWidth()
  const modal = useModal()
  const [modalAsset, setModalAsset] = useState()
  const addFundsModal = useModal()

  const { userTotals } = useTypedSelector((state) => state.rewards);

  const handleClickBuyCrypto = () => addFundsModal.setShow(true)

  useEffect(() => {
    dispatch(getHodlUserTotals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }), []
  )

  const data = useMemo(
    () =>
      map(entries(balances), ([currency, amount]) => ({
        currency,
        amount: currency === 'lion' ? (userTotals?.totalLionBalance ? userTotals.totalLionBalance - userTotals.lionInOpenOrders : amount) : amount,
        name: get(
          find(assets, (asset) => asset.id === currency),
          'asset_name'
        ),
        dollarValue: () => {
          let amountForCalc = amount;
          if(currency === 'lion' && userTotals?.totalLionBalance) {
            amountForCalc = userTotals.totalLionBalance - userTotals.lionInOpenOrders;
          }

          return !isUndefined(usdPrices[currency])
            ? amountForCalc * usdPrices[currency]
            : ''
        },
        onOrder: reduce(
          openOrders,
          (total, { instrument, amount, price, type }) => {
            const [asset1, asset2] = instrument.split('_')
            if (eq(asset1, currency) && eq(type, 'sell')) {
              return total + amount
            } else if (eq(asset2, currency) && eq(type, 'buy')) {
              return total + (amount * price)
            } else {
              return total
            }
          },
          0
        ),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balances, assets, usdPrices, openOrders]
  )

  const columns = useMemo(
    () =>
      isBig
        ? [
            // Desktop columns
            {
              Header: '',
              id: 'icon',
              width: 54,
              className: 'ReactTable-currencyicon',
              Cell: (props) => {
                if (eq(props.original.currency, 'lion')) {
                  return (
                    <Logo />
                  )
                } else {
                  return (
                    <Currency
                      className="markets-table__coin"
                      type={props.original.currency}
                    />
                  )
                }
              },
            },
            {
              Header: 'Currency',
              id: 'name',
              width: 140,
              accessor: (d) => `${d.name}`,
            },
            {
              headerClassName: 'u-overflowVisible u-tr',
              Header: () => (
                <div className="WalletBalancesTable-columnHeaderWithTooltip">
                  Available Qty
                  <Tooltip modalTitle="Available Qty" small>
                    The Available Qty is the total quantity of your asset minus
                    any amount you have tied to a pending sell order.
                  </Tooltip>
                </div>
              ),
              id: 'amount',
              className: 'WalletBalancesTable--dataCell',
              accessor: (d) =>
                `${round(d.amount, 9).toLocaleString()} ${upperCase(
                  d.currency
                )}`,
            },
            {
              Header: 'Dollar value',
              id: 'value',
              maxWidth: 150,
              headerClassName: 'u-tr',
              className: 'WalletBalancesTable--dataCell',
              accessor: 'dollarValue',
              Cell: ({ value }) => `${currencyFormatter.format(value())}`,
            },
            {
              Header: 'On orders',
              id: 'onOrders',
              maxWidth: 150,
              headerClassName: 'u-tableCellMdUp u-tr',
              className: 'WalletBalancesTable--dataCell u-tableCellMdUp',
              accessor: 'onOrder',
              Cell: ({ value, original }) => {
                if (eq(original.currency, 'usd')) {
                  return `${formatAsDollarAmount(round(value, 2))}`
                } else {
                  return `${formatNumber(round(value, 9))}`
                }
              },
            },
            {
              Header: 'HODL\'d',
              id: 'lionHodlLocked',
              maxWidth: 150,
              headerClassName: 'u-tableCellMdUp u-tr',
              className: 'WalletBalancesTable--dataCell u-tableCellMdUp',
              accessor: 'lionHodlLocked',
              Cell: ({ value, original }) => {
                if(original.currency !== 'lion' || isNil(userTotals.lionHodlLocked)) {
                  return '--';
                }

                return userTotals.lionHodlLocked.toLocaleString();
              },
              sortable: false,
            },
            {
              Header: '',
              id: 'buttons',
              width: 90,
              sortable: false,
              className:
                'WalletBalancesTable--dataCell WalletBalancesTable--dataCell-buttons',
              Cell: (props) =>
                !eq(props.original.currency, 'usd') && (
                  <button
                    type="button"
                    className="u-buttonReset WalletBalancesTable-buySell"
                    onClick={() => {
                      setModalAsset(props.original.currency)
                      modal.setShow(true)
                    }}
                  >
                    Buy/Sell
                  </button>
                ),
            },
          ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'currency',
              className: 'WalletBalancesTable-currency',
              Cell: (props) => {
                if (props.original.currency === 'lion') {
                  return (
                    <React.Fragment>
                      <Logo />
                      {props.original.name}
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <Currency
                        className="markets-table__coin"
                        type={props.original.currency}
                      />
                      {props.original.name}
                    </React.Fragment>
                  )
                }
              },
            },
            {
              Header: '',
              id: 'rest',
              className: 'WalletBalancesTable-right',
              Cell: (props) => (
                <div>
                  {currencyFormatter.format(props.original.dollarValue())}
                  <small>
                    {round(props.original.amount, 9).toLocaleString()}{' '}
                    {upperCase(props.original.currency)}
                    {props.original.currency === 'lion' && !isNil(userTotals.lionHodlLocked) && (
                      <>
                        <br />
                        {userTotals.lionHodlLocked.toLocaleString()} HODL'd
                      </>
                    )}
                  </small>
                  {!eq(props.original.currency, 'usd') && (
                    <button
                      type="button"
                      className="u-buttonReset WalletBalancesTable-buySell"
                      onClick={() => {
                        setModalAsset(props.original.currency)
                        modal.setShow(true)
                      }}
                    >
                      Buy/Sell
                    </button>
                  )}
                </div>
              ),
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBig, data]
  )

  return (
    <div className="WalletBalancesTable-container">
      <ComponentLoader isLoading={isNull(balances)} />

      {modalAsset && (
        <BuySellModal modal={modal} assetId={modalAsset} />
      )}

      {!isUndefined(data) && gt(data.length, 0) ? (
        <div className={`WalletBalancesTable ${isBig ? '' : 'mobile'}`}>
          <Table
            columns={columns}
            data={data}
            className="WalletBalancesTable"
            defaultSorted={[{ id: 'name' }]}
          />
        </div>
      ) : (
        <div className="PortfolioDetailsTab-placeholder">
          <img
            className="PortfolioDetailsTab-img"
            src="/img/cryptoperson.svg"
            alt="Add Funds Illustration"
          />
          <div className="PortfolioDetailsTab-placeholderText">
            <h2 className="PortfolioDetailsTab-placeholderTextHeader">
              Add assets to your account
            </h2>
            <p>
              Securely add dollars or crypto to your account. In order to
              auto-trade, you must have USD, USDC, or USDT in your account to
              open new positions.
            </p>
          </div>
        </div>
      )}
      {isUndefined(data) ||
        (eq(data.length, 0) && (
          <div className="PortfolioDetailsTabFooter">
            <div className="PortfolioDetailsTabFooter-buttonGroup">
              <Button
                size="large"
                variant="secondary"
                onClick={handleClickBuyCrypto}
              >
                Buy crypto
              </Button>
            </div>
          </div>
        ))}
      <AddFundsModal modal={addFundsModal} />
    </div>
  )
}

export default WalletBalancesTable
