import React, { useState, useEffect } from 'react'
import useDocTitle from '@pv3-hooks/useDocTitle'
import Icon from '@pv3-shared-components/Icons'
import Button from '@pv3-shared-components/Button'
import Modal from '@pv3-shared-components/Modal'
import useModal from '@pv3-hooks/useModal'
import useReportsDuck from '@pv3-hooks/useReportsDuck'
import './taxdocuments.scss'
import { isNull, map, get, uniq, orderBy, eq, filter, find, isEmpty } from 'lodash'
import Loading from '@pv3-shared-components/Loading'
import { getYear } from 'date-fns'

const TaxDocuments = () => {
  useDocTitle('Tax Documents')
  const modal = useModal()
  const {
    taxDocuments,
    taxDocumentTypes,
    requestTaxDocument,
    getTaxDocuments,
    getTaxDocumentUrl,
  } = useReportsDuck()
  const [taxYears, setTaxYears] = useState([])
  const [submittingRequest, setSubmittingRequest] = useState(false)

  const documentTypeExists = (type, year) => {
    let found = find(taxDocuments, (td) => td.type === get(taxDocumentTypes, type) && td.taxYear === year)
    return found != null;
  }

  const handleReportClick = (type, year) => {
    setSubmittingRequest(true)

    requestTaxDocument(get(taxDocumentTypes, type), year ? year : (type === 'Lifetime' ? null : getYear(new Date())))
      .then(() => {
        modal.setShow(true)
        setSubmittingRequest(false)

        // Check for new documents shortly after request
        setTimeout(() => getTaxDocuments(), 800)
        setTimeout(() => getTaxDocuments(), 3000)
      })
      .catch(() => {
        setSubmittingRequest(false)
      })
  }

  const fetchReportUrl = (s3ObjectKey) => {
    getTaxDocumentUrl(s3ObjectKey)
      .then(({ payload }) => {
        window.location = payload
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    let years = map(taxDocuments, 'taxYear')
    //Ensure current year is present
    let thisYear = getYear(new Date())
    years.push(thisYear)

    let taxYearsList = uniq(years)

    if(!taxYearsList.includes(thisYear - 1)) {
      taxYearsList.push(thisYear - 1)
    }

    taxYearsList = orderBy(taxYearsList, null, 'desc')

    setTaxYears(taxYearsList)


  }, [taxDocuments])

  useEffect(() => {
    // Poll for tax docs while on page,
    // as users may wait for requested reports to appear
    const timer = setInterval(() => getTaxDocuments(), 45000)

    // Clear interval when leaving page
    return () => clearInterval(timer)
  }, [getTaxDocuments])

  return (
    <div className="Content">
      <h1 className="h4 TaxDocuments-heading">Tax Documents</h1>
      {isNull(taxDocuments) ? (
        <Loading />
      ) : (
        <dl className="TaxDocuments">
          {map(taxYears, (year, index) => {
            if(year === 0) {
              return null;
            }

            return (
              <React.Fragment key={index}>
                <dt className="TaxDocuments-year">{year}</dt>
                {eq(index, 0) && !isEmpty(taxDocumentTypes) && (
                  <React.Fragment>
                    <dd className="TaxDocuments-link">
                      <Button
                        variant="quinary"
                        disabled={submittingRequest}
                        onClick={() => handleReportClick('YearToDate')}
                      >
                        Generate YTD Report
                      </Button>
                    </dd>
                    <dd className="TaxDocuments-link">
                      <Button
                        variant="quinary"
                        disabled={submittingRequest}
                        onClick={() => handleReportClick('QuarterToDate')}
                      >
                        Generate QTD Report
                      </Button>
                    </dd>
                  </React.Fragment>
                )}

                {!eq(index, 0) && !isEmpty(taxDocumentTypes) && (
                  <dd className="TaxDocuments-link">
                    <Button
                      variant="quinary"
                      disabled={submittingRequest || documentTypeExists('Annual', year)}
                      onClick={() => handleReportClick('Annual', year)}
                    >
                      Generate {year} Tax Documents
                    </Button>
                  </dd>
                )}

                {orderBy(
                  filter(taxDocuments, { taxYear: year }),
                  'created',
                  'desc'
                ).map((document, index) => {
                  let s3ObjectKey = get(document, 's3ObjectKey');
                  return (
                    <dd className="TaxDocuments-link" key={index}>
                      {s3ObjectKey ? (
                        <button
                          className="u-buttonReset"
                          onClick={() =>
                            fetchReportUrl(s3ObjectKey)
                          }
                        >
                          <Icon aria-hidden="true" name="documentsmall" />
                          {get(document, 'title')}
                        </button>
                      ) : (
                        <React.Fragment>
                          <strong>(PENDING)</strong>&nbsp;{get(document, 'title')}
                        </React.Fragment>
                      )}
                    </dd>
                  )
                })}
              </React.Fragment>
            )
          })}

          <dt className="TaxDocuments-year">Lifetime</dt>

          <dd className="TaxDocuments-link">
            <Button
              variant="quinary"
              disabled={submittingRequest}
              onClick={() => handleReportClick('Lifetime')}
            >
              Generate Lifetime Statement
            </Button>
          </dd>

          {orderBy(
            filter(taxDocuments, { taxYear: 0 }),
            'created',
            'desc'
          ).map((document, index) => {
            let s3ObjectKey = get(document, 's3ObjectKey');
            return (
              <dd className="TaxDocuments-link" key={index}>
                {s3ObjectKey ? (
                  <button
                    className="u-buttonReset"
                    onClick={() =>
                      fetchReportUrl(s3ObjectKey)
                    }
                  >
                    <Icon aria-hidden="true" name="documentsmall" />
                    {get(document, 'title')}
                  </button>
                ) : (
                  <React.Fragment>
                    <strong>(PENDING)</strong>&nbsp;{get(document, 'title')}
                  </React.Fragment>
                )}
              </dd>
            )
          })}
        </dl>
      )}
      <Modal
        modal={modal}
        screens={{
          MAIN: {
            heading: () => <h4>Generate Report</h4>,
            body: () => (
              <React.Fragment>
                <h4 className="GenerateReportHeadline">
                  Your report is being generated
                </h4>
                <p>Your report will be added to the list when it is ready.</p>
                <Button
                  variant="primary"
                  size="large"
                  type="button"
                  className="Button--generateReportOk"
                  onClick={() => modal.setShow(false)}
                >
                  Okay
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />
    </div>
  )
}

export default TaxDocuments
