import React, { useMemo } from 'react'
import Table from '@pv3-shared-components/Table'
import './markettradestable.scss'
import { format } from 'date-fns'
import { formatNumber } from '../../utils/utils'
import useWindowWidth from '@pv3-hooks/useWindowWidth'

const MarketTradesTable = ({ trades }) => {
  const isBig = useWindowWidth()
  const columns = useMemo(
    () => [
      {
        Header: 'Price',
        id: 'price',
        className: 'MarketTradesTable-coloredText',
        width: isBig ? 110 : 100,
        accessor: (d) => `${formatNumber(d.executionPrice)}`,
      },
      {
        Header: 'Amount',
        id: 'amount',
        className: 'MarketTradesTable-rightAlignedData',
        width: isBig ? 110 : 100,
        accessor: (d) => `${formatNumber(d.amount)}`,
      },
      {
        Header: 'Side',
        id: 'side',
        className:
          'MarketTradesTable-rightAlignedData MarketTradesTable-coloredText',
        width: 50,
        accessor: (d) => (d.side ? 'Sell' : 'Buy'),
      },
      {
        Header: 'Date',
        id: 'date',
        className:
          'MarketTradesTable-rightAlignedData MarketTradesTable-dateCell',
        width: 62,
        accessor: (d) => {
          const date = new Date(d.tradeTime)
          return (
            <React.Fragment>
              <small>{format(date, 'MM/dd/yy')}</small>
              <small>{format(date, 'h:mm a')}</small>
            </React.Fragment>
          )
        },
      },
    ],
    [isBig]
  )

  return (
    <Table
      columns={columns}
      data={trades}
      getTrProps={(_, { original }) => {
        return {
          className: original.side
            ? 'MarketTradesTable-rowRed'
            : 'MarketTradesTable-rowGreen',
        }
      }}
      emptyHeadline="No history for this market"
    />
  )
}

export default MarketTradesTable
