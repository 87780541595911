import React from 'react'

const ArrowLeft = ({
  fill = '#500078',
  width = '32',
  height = '32',
  ...rest
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.8581 11.3425L9.31592 19.9291L17.8581 28.5158C18.0688 28.7925 18.4215 28.9209 18.7608 28.8444C19.1 28.7679 19.3635 28.5007 19.4352 28.1603C19.5068 27.82 19.3734 27.4692 19.0937 27.2625L12.7026 20.818H29.707C30.198 20.818 30.5959 20.4201 30.5959 19.9291C30.5959 19.4382 30.198 19.0403 29.707 19.0403H12.7026L19.0937 12.5958C19.4398 12.2473 19.4378 11.6841 19.0893 11.338C18.7407 10.9919 18.1776 10.9939 17.8315 11.3425H17.8581Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowLeft
