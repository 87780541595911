import React, { useState, useEffect, useMemo } from 'react'
import { eq, upperCase, get as _get, isEmpty } from 'lodash'
import { formatAsDollarAmount } from '../../utils/utils'
import Button from '@pv3-shared-components/Button'
import Modal from '@pv3-shared-components/Modal'
import Flash from '@pv3-shared-components/Flash'
import useTradesDuck from '@pv3-hooks/useTradesDuck'
import '@pv3-shared-components/Ledger/ledger.scss'
import './sellopenpositionmodal.scss'
import useWalletsDuck from '../../hooks/useWalletsDuck'

const SellOpenPositionModal = ({ modal, openPosition }) => {
  const { deleteOpenPosition, sellPosition } = useTradesDuck()
  const [isFlashActive, setIsFlashActive] = useState(false)
  const [insufficientResources, setInsufficientResources] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { usdPrices } = useWalletsDuck()

  const usdPrice = useMemo(() => {
    if (isEmpty(openPosition)) return

    return usdPrices[openPosition.assetId]
  }, [openPosition, usdPrices])

  const renderEstimatedSellFee = () => {
    return (
      _get(openPosition, 'openPrice') *
      _get(openPosition, 'openAmount') *
      _get(openPosition, 'takerFee')
    )
  }

  const total = useMemo(() => {
    return (
      usdPrice * _get(openPosition, 'openAmount') - renderEstimatedSellFee()
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdPrice, openPosition])

  const handleFlashCloseClick = () => {
    setIsFlashActive(false)
  }

  const handleCloseAfterInsufficientResources = () => {
    modal.setShow(false)
    setTimeout(() => setInsufficientResources(false), 500)
  }

  const handleCloseClick = () => {
    modal.setShow(false)
  }

  const handleSellButtonClick = async () => {
    setIsLoading(true)

    const response = await sellPosition(_get(openPosition, 'id'))

    setIsLoading(false)

    // TODO: REFACTOR
    if (!eq(_get(response, 'type'), 'trades/sellPosition/rejected')) {
      modal.setShow(false)
      return
    }

    if(eq(_get(response, 'type'), 'trades/sellPosition/rejected')) {
      setInsufficientResources(true)
      handleInsufficientResources()
    } else {
      setIsFlashActive(true)
    }
  }

  const handleInsufficientResources = async () => {
    setIsLoading(true)
    await deleteOpenPosition(_get(openPosition, 'id'))
    setIsLoading(false)
  }

  useEffect(() => {
    setIsFlashActive(false)
  }, [modal])

  return (
    <Modal
      className="test123123123"
      modal={modal}
      screens={{
        MAIN: {
          heading: <h4>Sell</h4>,
          body: (
            <div className="Ledger">
              {isFlashActive && (
                <Flash danger onDismiss={handleFlashCloseClick}>
                  An error occurred.
                </Flash>
              )}
              {insufficientResources && (
                <Flash danger onDismiss={handleCloseAfterInsufficientResources}>
                  Your account does not have enough {openPosition && openPosition.assetId ? openPosition.assetId.toUpperCase() : ''} to cover this position.  This Position will be removed from your list of Open Positions.
                </Flash>
              )}
              <h5 className="Ledger-heading">Sell order preview</h5>
              <div className="Ledger-rows">
                <div className="Ledger-row">
                  <div className="Ledger-label">Amount</div>
                  <div className="Ledger-value Ledger-value--strong">
                    {_get(openPosition, 'openAmount')}
                  </div>
                </div>
                <div className="Ledger-row">
                  <div className="Ledger-label">Price</div>
                  <div className="Ledger-value">
                    {formatAsDollarAmount(usdPrice)} /{' '}
                    {upperCase(_get(openPosition, 'assetId'))}
                  </div>
                </div>
                <div className="Ledger-row">
                  <div className="Ledger-label">
                    {!eq(renderEstimatedSellFee(), 0) && 'Estimated '}
                    Fee
                  </div>
                  <div className="Ledger-value">
                    {!eq(renderEstimatedSellFee(), 0) && '≈'}
                    {formatAsDollarAmount(renderEstimatedSellFee())}
                  </div>
                </div>
                <div className="Ledger-row Ledger-row--last">
                  <div className="Ledger-label">Total</div>
                  <div className="Ledger-value">
                    {formatAsDollarAmount(total)}
                  </div>
                </div>
              </div>
              <div className="SellOpenPositionModal-buttonContainer">
                {insufficientResources ? (
                  <Button
                    onClick={handleCloseAfterInsufficientResources}
                    size="large"
                  >
                    OK
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button
                      isLoading={isLoading}
                      onClick={handleSellButtonClick}
                      size="large"
                    >
                      Sell
                    </Button>
                    <Button
                      variant="quaternary"
                      onClick={handleCloseClick}
                      size="large"
                    >
                      Nevermind
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          ),
        },
      }}
    />
  )
}

export default SellOpenPositionModal
