import clone from 'clone';

import { addHours, isAfter, isBefore, isEqual } from 'date-fns'
import { eq, filter, gt, isNil, map } from 'lodash'

export const calculateStep = (scale) => {
  if(isNil(scale)) {
    return undefined;
  }

  let step = '.';
  let i = 0;
  while(i < scale - 1) {
    step += '0';
    i++;
  }
  step += '1';
  return step;
};

export const closedPositionsByHour = (
  closedPositions,
  datetimeStart,
  datetimeEnd
) => {
  const intervals = [datetimeStart]

  while (isBefore(intervals[intervals.length - 1], datetimeEnd)) {
    intervals.push(addHours(intervals[intervals.length - 1], 1))
  }

  return map(intervals, (interval) => ({
    datetime: interval,
    closedPositions: filter(closedPositions, ({ acceptedAt }) => {
      const acceptedAtDate = new Date(acceptedAt)
      return (
        isEqual(acceptedAtDate, interval) ||
        (isAfter(acceptedAtDate, interval) &&
          isBefore(acceptedAtDate, addHours(interval, 1)))
      )
    }),
  }))
}

export const toLeadingZero = (n) => {
  if (n < 10) return `0${n}`
  return n
}

export const toBirthDate = (personInfo) => {
  return `${personInfo.yearOfBirth}-${toLeadingZero(
    personInfo.monthOfBirth
  )}-${toLeadingZero(personInfo.dayOfBirth)}`
}

export const formatAsDollarAmount = (number, roundToDollar, numberOfDigits) => {
  if (number == null) {
    return null
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: roundToDollar ? 0 : numberOfDigits,
  }).format(number)
}

export const formatNumber = (
  number,
  minimumFractionDigits,
  maximumFractionDigits = 8
) => {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(number)
}

export const fixFloatingPointErrors = (number, precision = 14) => {
  let numberString = number.toString().split('.')[1]
  if (numberString && numberString.length > precision) {
    return parseFloat(number.toFixed(precision)).toString()
  }

  return number.toString()
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const convertRequiredSignalCountsToInts = (strategyToProcess) => {
  let strategy = clone(strategyToProcess);

  if(strategy.buyIndicatorManagementSettings) {
    if(strategy.buyIndicatorManagementSettings.enableIndicatorQuantity != null) {
      strategy.buyIndicatorManagementSettings.enableIndicatorQuantity = parseInt(strategy.buyIndicatorManagementSettings.enableIndicatorQuantity, 10);
    }
    if(strategy.buyIndicatorManagementSettings.disableIndicatorQuantity != null) {
      strategy.buyIndicatorManagementSettings.disableIndicatorQuantity = parseInt(strategy.buyIndicatorManagementSettings.disableIndicatorQuantity, 10);
    }
  }

  if(strategy.buyIndicatorTriggerSettings && strategy.buyIndicatorTriggerSettings.triggerIndicatorQuantity) {
    strategy.buyIndicatorTriggerSettings.triggerIndicatorQuantity = parseInt(strategy.buyIndicatorTriggerSettings.triggerIndicatorQuantity, 10);
  }

  if(strategy.sellIndicatorManagementSettings) {
    if(strategy.sellIndicatorManagementSettings.enableIndicatorQuantity != null) {
      strategy.sellIndicatorManagementSettings.enableIndicatorQuantity = parseInt(strategy.sellIndicatorManagementSettings.enableIndicatorQuantity, 10);
    }
    if(strategy.sellIndicatorManagementSettings.disableIndicatorQuantity != null) {
      strategy.sellIndicatorManagementSettings.disableIndicatorQuantity = parseInt(strategy.sellIndicatorManagementSettings.disableIndicatorQuantity, 10);
    }
  }

  if(strategy.sellIndicatorTriggerSettings && strategy.sellIndicatorTriggerSettings.triggerIndicatorQuantity) {
    strategy.sellIndicatorTriggerSettings.triggerIndicatorQuantity = parseInt(strategy.sellIndicatorTriggerSettings.triggerIndicatorQuantity, 10);
  }

  return strategy;
}

export const resizeImage = async (file, maxSize = 1000) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.onload = function (readerEvent) {
        let image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          let canvas = document.createElement('canvas'),
            mime = 'image/jpeg',
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const resizedImage = new File([blob], file.name, {
              type: mime,
              lastModified: Date.now()
            }); //output image as a file
            resolve(resizedImage);
          }, mime, .6);
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    } catch(err) {
      reject(err);
    }
  });
};

export const getImageDimensions = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      let img = document.createElement('img');
      let blob = URL.createObjectURL(file);
      img.src = blob;
      img.onload = () => {
        console.log(img.height, img.width);
        file.height = img.height;
        file.width = img.width;
        resolve(file);
      };
    } catch(err) {
      reject(err);
    }
  });
};

export const renderTableAge = (ageInMinutes) => {
  const days = Math.floor(ageInMinutes / 1440)
  const hours = Math.floor((ageInMinutes % 1440) / 60)
  const minutes = Math.floor(ageInMinutes % 60)

  return `${gt(days, 0) ? days + 'D' : ''} ${
    gt(hours, 0) ? hours + 'H' : ''
  } ${
    gt(minutes, 0) || (eq(days, 0) && eq(hours, 0))
      ? minutes + 'M'
      : ''
  }`
};

export const isArrayNullOrEmpty = (arr) => {
  return arr == null || arr.length === 0;
};

export const isObjectNullOrEmpty = (obj) => {
  return obj == null || Object.keys(obj).length === 0;
};

//From https://gist.github.com/jiggzson/b5f489af9ad931e3d186
export const scientificToDecimal = (num) => {
  const sign = Math.sign(num);
  //if the number is in scientific notation remove it
  if(/\d+\.?\d*e[+-]*\d+/i.test(num)) {
    const zero = '0';
    const parts = String(num).toLowerCase().split('e'); //split into coeff and exponent
    const e = parts.pop(); //store the exponential part
    let l = Math.abs(e); //get the number of zeros
    const direction = e/l; // use to determine the zeroes on the left or right
    const coeff_array = parts[0].split('.');

    if (direction === -1) {
      coeff_array[0] = Math.abs(coeff_array[0]);
      num = zero + '.' + new Array(l).join(zero) + coeff_array.join('');
    }
    else {
      const dec = coeff_array[1];
      if (dec) l = l - dec.length;
      num = coeff_array.join('') + new Array(l+1).join(zero);
    }
  }

  if (sign < 0) {
    num = -num;
  }

  return num;
};


