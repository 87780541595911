import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as clone from 'clone';
import { orderBy } from 'lodash';

import Recipient from "../types/Recipient";
import { RootState } from "./index";
import { Vasp } from "../types/Vasp";

import { API_PATHS } from '../constants';
import TokenRequest from '../utils/tokenRequest';

type DeleteRecipientProps = {
  recipientId: string
}

export const deleteRecipient = createAsyncThunk(
  'travelRule/deleteRecipient',
  async ({recipientId}: DeleteRecipientProps, { getState, rejectWithValue }) => {
    try {
      await new TokenRequest((getState() as RootState).account.idToken).delete(API_PATHS.COIN.DELETE_TRAVEL_RULE_RECIPIENT(recipientId));

      return { recipientId };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCustomerToken = createAsyncThunk(
  'travelRule/getCustomerToken',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.GET_TRAVEL_RULE_CUSTOMER_TOKEN);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getRecipients = createAsyncThunk(
  'travelRule/getRecipients',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await new TokenRequest((getState() as RootState).account.idToken).get(API_PATHS.COIN.GET_TRAVEL_RULE_RECIPIENTS);

      let recipients = clone(response.data);
      recipients.forEach((r: Recipient) => {
        r.label = r.recipientNickname || (r.beneficiaryBusinessName ? r.beneficiaryBusinessName : `${r.beneficiaryFirstName} ${r.beneficiaryLastName}`);
        r.value = r.recipientId;
      });

      return recipients;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getVasps = createAsyncThunk(
  'travelRule/getVasps',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const tokenResponse = await dispatch(getCustomerToken());

      // @ts-ignore
      if(!tokenResponse.error) {
        //@ts-ignore
        const vaspsResponse = await new TokenRequest(tokenResponse.access_token).get(API_PATHS.COIN.GET_VASPS, {baseURL: ''});
        return [
          {
            did: 'self-hosted-wallet',
            name: 'Self-Hosted Wallet',
          },
          ...vaspsResponse.data.vasps,
        ];
      } else {
        throw new Error('Unable to get customer token');
      }
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type SaveRecipientProps = {
  recipient: Recipient
}

export const saveRecipient = createAsyncThunk(
  'travelRule/saveRecipient',
  async ({recipient}: SaveRecipientProps, { getState, rejectWithValue }) => {
    try {
      let request = new TokenRequest((getState() as RootState).account.idToken);
      let reqFunc = request.post;
      let path = API_PATHS.COIN.SAVE_TRAVEL_RULE_RECIPIENT;
      if(recipient.recipientId) {
        reqFunc = request.put;
        path += `/${recipient.recipientId}`;
      } else {
        delete recipient.recipientId;
      }
      const response = await reqFunc(path, recipient);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

type ValidateTravelRuleProps = {
  recipient: Recipient
}

export const validateTravelRule = createAsyncThunk(
  'travelRule/validateTravelRule',
  async ({recipient}: ValidateTravelRuleProps, { getState, rejectWithValue }) => {
    try {
      const response = await new TokenRequest((getState() as RootState).account.idToken).post(API_PATHS.COIN.VALIDATE_TRAVEL_RULE, recipient);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

interface TravelRuleState {
  isDeletingRecipient: boolean
  recipients: Array<Recipient>
  vasps: Array<Vasp>
}

const initialState: TravelRuleState = {
  isDeletingRecipient: false,
  recipients: [],
  vasps: [],
};

const travelRuleSlice = createSlice({
  name: 'travelRule',
  initialState,
  reducers: {},
  extraReducers: ({addCase}) => {
    addCase(deleteRecipient.pending, (state, action) => {
      state.isDeletingRecipient = true;
    });
    addCase(deleteRecipient.fulfilled, (state, action) => {
      state.recipients = state.recipients.filter((r) => r.recipientId !== action.payload.recipientId);
      state.isDeletingRecipient = false;
    });
    addCase(deleteRecipient.rejected, (state, action) => {
      state.isDeletingRecipient = false
    });
    addCase(getRecipients.fulfilled, (state, action) => {
      state.recipients = orderBy(action.payload, 'recipientNickname');
    });
    addCase(getVasps.fulfilled, (state, action) => {
      state.vasps = action.payload;
    });
    addCase(saveRecipient.fulfilled, (state, action) => {
      const foundIndex = state.recipients.findIndex((r) => r.recipientId === action.payload.recipientId);

      if(foundIndex > -1) {
        let clonedRecipients = clone(state.recipients);
        clonedRecipients[foundIndex] = action.payload;
        state.recipients = clonedRecipients;
      } else {
        state.recipients = orderBy([...state.recipients, action.payload], 'recipientNickname');
      }
    });
  },
});

export default travelRuleSlice.reducer;
