import './radioGroup.scss';

import * as React from 'react';
import { isUndefined } from "lodash";
import { get, useFormContext } from "react-hook-form";
import InputError from "../InputError";

export type RadioOption = {
  label: string
  value: string
}

type Props = {
  error?: React.ReactNode
  name: string
  options: Array<RadioOption>
  register: any
  registerConfig?: any
}

const RadioGroup: React.FC<Props> = ({
  error,
  name,
  options,
  register,
  registerConfig,
}) => {
  const formContext = useFormContext();

  let errors = get(formContext, 'formState.errors');

  const finalError =
    isUndefined(error) && !isUndefined(name) && !isUndefined(errors)
      ? errors[name] && <InputError message={errors[name].message} />
      : error;

  return (
    <div className="RadioGroup-container">
      <div className="RadioGroup">
        {options.map(({ label, value }, index) => (
          <div className="RadioGroup-option">
            <input type="radio" value={value} name="duration" {...register(name, registerConfig)} id={`${label}${index}`} />
            <label htmlFor={`${label}${index}`}>
              {label}
            </label>
          </div>
        ))}
      </div>

      {finalError}
    </div>
  );
};

export default RadioGroup;
