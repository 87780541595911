import React from 'react'

const ArrowRight = ({
  fill = '#500078',
  width = '20',
  height = '20',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="m11.284 4.59 5.339 5.366-5.34 5.367a.555.555 0 1 1-.772-.784l3.995-4.027H3.878a.556.556 0 1 1 0-1.112h10.628L10.51 5.373a.556.556 0 0 1 .79-.784h-.017Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowRight
