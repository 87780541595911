import React from 'react'
import AddressCard from './AddressCard';
import Checkmark from './Checkmark'
import CheckmarkSmall from './CheckmarkSmall'
import CheckmarkOutline from './CheckmarkOutline'
import X from './X'
import Clock from './Clock'
import Person from './Person'
import Buildings from './Buildings'
import ErrorIcon from './Error'
import CoinLionLogo from './CoinLionLogo'
import Info from './Info'
import Image from './Image'
import Trash from './Trash'
import Bank from './Bank'
import Trading from './Trading'
import ArrowLeft from './ArrowLeft'
import Check from './Check'
import QR from './QR'
import ArrowRight from './ArrowRight'
import Up from './Up'
import Down from './Down'
import CaretLeft from './CaretLeft'
import CaretRight from './CaretRight'
import CaretDown from './CaretDown'
import CaretUp from './CaretUp'
import Copy from './Copy'
import ExclamationTriangle from './ExclamationTriangle'
import CurrenciesTooltipCaret from './CurrenciesTooltipCaret'
import ExclamationCircle from './ExclamationCircle'
import CirclePlus from './CirclePlus'
import InfoOutline from './InfoOutline'
import Plus from './Plus'
import Minus from './Minus'
import Verified from './Verified'
import Pending from './Pending'
import Failed from './Failed'
import Pencil from './Pencil'
import PencilFilled from './PencilFilled'
import TwoWayArrows from './TwoWayArrows'
import Document from './Document'
import DocumentSmall from './DocumentSmall'
import Calendar from "./Calendar";

const Icon = ({ name, ...rest }) => {
  switch (name) {
    case 'addresscard':
      return <AddressCard {...rest} />;
    case 'exclamation triangle':
      return <ExclamationTriangle {...rest} />
    case 'exclamation circle':
      return <ExclamationCircle {...rest} />
    case 'checkmark':
      return <Checkmark {...rest} />
    case 'checkmarksmall':
      return <CheckmarkSmall {...rest} />
    case 'checkmarkoutline':
      return <CheckmarkOutline {...rest} />
    case 'x':
      return <X {...rest} />
    case 'clock':
      return <Clock {...rest} />
    case 'person':
      return <Person {...rest} />
    case 'buildings':
      return <Buildings {...rest} />
    case 'error':
      return <ErrorIcon {...rest} />
    case 'coinlionlogo':
      return <CoinLionLogo {...rest} />
    case 'info':
      return <Info {...rest} />
    case 'infooutline':
      return <InfoOutline {...rest} />
    case 'caretright':
      return <CaretRight {...rest} />
    case 'caretdown':
      return <CaretDown {...rest} />
    case 'caretup':
      return <CaretUp {...rest} />
    case 'caretleft':
      return <CaretLeft {...rest} />
    case 'image':
      return <Image {...rest} />
    case 'trash':
      return <Trash {...rest} />
    case 'bank':
      return <Bank {...rest} />
    case 'trading':
      return <Trading {...rest} />
    case 'arrowleft':
      return <ArrowLeft {...rest} />
    case 'arrowright':
      return <ArrowRight {...rest} />
    case 'twowayarrows':
      return <TwoWayArrows {...rest} />
    case 'check':
      return <Check {...rest} />
    case 'qr':
      return <QR {...rest} />
    case 'up':
      return <Up {...rest} />
    case 'down':
      return <Down {...rest} />
    case 'copy':
      return <Copy {...rest} />
    case 'currenciestooltipcaret':
      return <CurrenciesTooltipCaret {...rest} />
    case 'circleplus':
      return <CirclePlus {...rest} />
    case 'plus':
      return <Plus {...rest} />
    case 'minus':
      return <Minus {...rest} />
    case 'verified':
      return <Verified {...rest} />
    case 'pending':
      return <Pending {...rest} />
    case 'failed':
      return <Failed {...rest} />
    case 'pencil':
      return <Pencil {...rest} />
    case 'pencilfilled':
      return <PencilFilled {...rest} />
    case 'document':
      return <Document {...rest} />
    case 'documentsmall':
      return <DocumentSmall {...rest} />
    case 'calendar':
      return <Calendar {...rest} />
    default:
      return <div />
  }
}

export default Icon
