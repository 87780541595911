import { useCallback } from 'react'

const useScrollLock = (fixedScrollPosition) => {
  const onScroll = useCallback(() => {
    window.scrollTo(0, fixedScrollPosition)
  }, [fixedScrollPosition])

  const lockScroll = useCallback(() => {
    window.addEventListener('scroll', onScroll)
  }, [onScroll])

  const unlockScroll = useCallback(() => {
    window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return [lockScroll, unlockScroll]
}

export default useScrollLock;
