import React, { useState, useLayoutEffect, useRef, useMemo, useEffect } from 'react'
import { filter, get, eq, map, reduce, isUndefined } from 'lodash'
import Icon from '@pv3-shared-components/Icons'
import './strategySignalSelector.scss'
import { useFormContext } from 'react-hook-form'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import useClickAway from '@pv3-hooks/useClickAway'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import FormValidationError from "../shared/FormValidationError";
import { isArrayNullOrEmpty } from "../../utils/utils";

const attributePrefix = {
  orderSize: 'orderSizeIndicatorTrigger',
  buy: 'buyIndicatorTrigger',
  sell: 'sellIndicatorTrigger',
  stopLoss: 'stopLossIndicatorTrigger',
  pauseBuy: 'buyIndicatorManagement',
  resumeBuy: 'buyIndicatorManagement',
  pauseSell: 'sellIndicatorManagement',
  resumeSell: 'sellIndicatorManagement',
}

const quantityAttribute = {
  orderSize: 'triggerIndicatorQuantity',
  buy: 'triggerIndicatorQuantity',
  sell: 'triggerIndicatorQuantity',
  stopLoss: 'triggerIndicatorQuantity',
  pauseBuy: 'disableIndicatorQuantity',
  resumeBuy: 'enableIndicatorQuantity',
  pauseSell: 'disableIndicatorQuantity',
  resumeSell: 'enableIndicatorQuantity',
}

const triggerListAttribute = {
  orderSize: 'triggerIndicatorSettings',
  buy: 'triggerIndicatorSettings',
  sell: 'triggerIndicatorSettings',
  stopLoss: 'triggerIndicatorSettings',
  pauseBuy: 'disableIndicatorSettings',
  resumeBuy: 'enableIndicatorSettings',
  pauseSell: 'disableIndicatorSettings',
  resumeSell: 'enableIndicatorSettings',
}

// Lookup list for strategyTriggerSettingsExclusions array
const exclusionNames = {
  orderSize: 'OrderSizeIndicatorTriggerSettings',
  buy: 'BuyTriggerSettings',
  sell: 'SellTriggerSettings',
  stopLoss: 'StopLossTriggerSettings',
  pauseBuy: 'EnableBuySettings',
  resumeBuy: 'EnableSellSettings',
  pauseSell: 'DisableBuySettings',
  resumeSell: 'DisableSellSettings',
}

const StrategySignalSelector = ({type, onAddSignal, fields}) => {
  const {indicators} = useStrategiesDuck()
  const {getValues, register, setValue, trigger, watch, formState: {errors}} = useFormContext()
  const [isOpen, setIsOpen] = useState(false)
  const [initialized, setInitialized] = useState(false);
  const [signalsRequired, setSignalsRequired] = useState(0);
  const openMenuRef = useRef(null)
  const menuButtonRef = useRef(null)
  const isBig = useWindowWidth()

  const relevantTriggerFields = watch(`${attributePrefix[type]}Settings`)
  const requiredValue = watch(`${attributePrefix[type]}Settings.${quantityAttribute[type]}`)
  const triggerIndicatorQuantity = get(
    relevantTriggerFields,
    quantityAttribute[type]
  )
  const triggerIndicatorSettings = get(
    relevantTriggerFields,
    triggerListAttribute[type]
  )

  // Had to break this out into it's own field because useEffect doesn't like
  // complex fields in it's dependency array
  const fieldsForWatching = relevantTriggerFields && relevantTriggerFields[`${triggerListAttribute[type]}`];

  const options = useMemo(
    () => {
      let optionsArray = [];
      indicators.forEach((ind) => {
        const foundIndex = ind.strategyTriggerSettingsExclusions.findIndex((ex) => ex === exclusionNames[type])
        if (foundIndex === -1) {
          optionsArray.push({
            indicatorName: get(ind, 'indicatorName'),
            displayName: get(ind, 'displayName'),
          })
        }
      });
      return optionsArray;
    }, [indicators, type]
  );

  const handleOptionClick = (indicator) => {
    if (triggerIndicatorSettings.length === 0) {
      setValue(
        `${attributePrefix[type]}Settings.${quantityAttribute[type]}`,
        1
      )
    }
    let indicatorObj = { indicator };
    const foundIndicator = indicators.find((ind) => ind.indicatorName === indicator);
    if (foundIndicator) {
      // We're building the indicator object with all default values
      // This is necessary due to the bug in CLV3-1240
      foundIndicator.fields.forEach((field) => {
        indicatorObj[field.name] = field.default;
      });
    }
    onAddSignal(indicatorObj);
    setIsOpen(false)
  }

  const handleButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const validateAtLeastAsManyAsAreRequired = (value) => {
    let fields = get(getValues(), `${attributePrefix[type]}Settings.${triggerListAttribute[type]}`);

    if (isArrayNullOrEmpty(fields)) {
      return true;
    }


    let requiredFields = filter(fields, (field) => field.required === true || field.required === 'true');

    return parseInt(value, 10) >= requiredFields.length;
  }

  useClickAway([openMenuRef, menuButtonRef], () => {
    setIsOpen(false)
  })

  useLayoutEffect(() => {
    if (isUndefined(triggerIndicatorSettings)) return

    // initialized being false means it doesn't allow it to run on first load.
    // signalsRequired !== 0 doesn't allow signals to go to zero programatically.
    //      this requires user's intentional setting
    if (!eq(signalsRequired, triggerIndicatorQuantity) && initialized && (signalsRequired !== 0)) {
      let currentValue = get(getValues(), `${attributePrefix[type]}Settings.${quantityAttribute[type]}`);
      if (typeof currentValue === 'string') {
        currentValue = parseInt(currentValue, 10);
      }

      let newValue = signalsRequired;

      // If the "Signals Required to Trigger Buy" are more than the number of selected required checkboxes
      // we want to decrement by only one.
      if (currentValue > signalsRequired && currentValue - signalsRequired > 1) {
        newValue = currentValue - 1;
      }

      setValue(
        `${attributePrefix[type]}Settings.${quantityAttribute[type]}`,
        newValue
      )
    } else {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalsRequired])


  useEffect(() => {
    const subscription = watch((value, {name, type: watchType}) => {
      if (name) {
        let names = name.split('.');

        if(names[0] === `${attributePrefix[type]}Settings`) {
          let settings = get(value, `${attributePrefix[type]}Settings.${triggerListAttribute[type]}`);

          if ((watchType === 'change' && names[2] === 'required') || (!names[2] && names[1] === triggerListAttribute[type])) {
            setSignalsRequired(reduce(
              settings,
              (count, {required}) => {
                if (required === true || required === 'true') {
                  return count + 1
                } else {
                  return count
                }
              },
              0
            ));
          }
        }
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trigger();

    let requiredValueParsed = parseInt(requiredValue);

    if (requiredValueParsed !== signalsRequired) {
      setSignalsRequired(requiredValueParsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredValue])

  useEffect(() => {
    // Watching settings here to see if the user has removed all the signals. [CLV3-1192]
    // If so, we're setting required to 0 to stop the errors in backtester.
    if (isArrayNullOrEmpty(get(getValues(), `${attributePrefix[type]}Settings.${triggerListAttribute[type]}`))) {
      setValue(
        `${attributePrefix[type]}Settings.${quantityAttribute[type]}`,
        0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsForWatching])

  return (
    <React.Fragment>
      <div className="StrategySignalSelector">
        <button
          data-testid={`new_${type}_signal_list`}
          id={`new_${type}_signal_list`}
          onClick={handleButtonClick}
          type="button"
          className={`u-buttonReset StrategySignalSelector-button ${
            isOpen ? 'StrategySignalSelector-button--active' : ''
          }`}
          ref={menuButtonRef}
        >
          <span>New signal</span>
          <Icon
            name={isOpen ? 'caretup' : 'caretdown'}
            fill={isOpen ? '#500078' : '#FFF'}
          />
        </button>
        {isOpen && (
          <div className="StrategySignalSelector-menu" ref={openMenuRef}>
            {map(options, (signal) => {
              return (
                <button
                  data-testid={`${signal.indicatorName}_${type}_indicator`}
                  id={`${signal.indicatorName}_${type}_indicator`}
                  key={signal.indicatorName}
                  onClick={() => handleOptionClick(signal.indicatorName)}
                  className="u-buttonReset StrategySignalSelector-item"
                  type="button"
                >
                  {signal.displayName || signal.indicatorName}
                </button>
              )
            })}
          </div>
        )}
        {(fields.length > 0 || isBig) && (
          <hr className="u-divider u-divider--vertical u-divider--light u-divider--sm-horizontal"/>
        )}
        {fields.length > 0 && (
          <label
            className="Label"
            style={{
              marginBottom: 0,
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}
          >
            <input
              {...register(
                `${attributePrefix[type]}Settings.${quantityAttribute[type]}`,
                {
                  validate: {
                    atLeastAsManyAsAreRequired: value => validateAtLeastAsManyAsAreRequired(value),
                    lessThanNumberOfFields: value => parseInt(value, 10) <= fields.length,
                    positive: value => parseInt(value, 10) >= 0,
                  }
                }
              )}
              data-testid={`${type}_signals`}
              id={`signals_required_to_trigger_${type}_input`}
              defaultValue={1}
              type="number"
              className="Input Input--signalsRequiredQty"
            />
            <span>signals required to trigger {type}</span>

            <FormValidationError
              error={get(errors, `${attributePrefix[type]}Settings.${quantityAttribute[type]}.type`)}
              messages={{
                atLeastAsManyAsAreRequired: 'The quantity of Signals Required is invalid. Please correct this before saving.',
                lessThanNumberOfFields: 'The quantity of Signals Required is invalid. Please correct this before saving.',
                positive: 'The quantity of Signals Required is invalid. Please correct this before saving.',
              }}
            />
          </label>
        )}
      </div>
    </React.Fragment>
  )
}

export default StrategySignalSelector
