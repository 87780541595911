import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { eq, isUndefined } from 'lodash'
import Button from '@pv3-shared-components/Button'
import useAccount from '../../hooks/useAccount'

const Auth0Error = () => {
  const { error } = useAuth0()
  const [text, setText] = useState()
  const { signOut } = useAccount()

  const handleLogoutButtonClick = () => {
    signOut()
  }

  useEffect(() => {
    if (eq(error.message, 'EMAIL_VERIFICATION_REQUIRED')) {
      return setText(
        'You must complete email verification before logging in. Please check your email.'
      )
    }
    setText(
      'There was an error authorizing your account. Please log out and try again.'
    )
  }, [error])

  return (
    <main className="SinglePanel">
      <div className="SinglePanel-panel">
        <p className="u-textCenter">{text}</p>
        {/* probably remove showing the error message for production */}
        {!isUndefined(error) && <p>{error.message}</p>}
        <Button
          variant="quaternary"
          size="large"
          type="button"
          onClick={handleLogoutButtonClick}
        >
          Log out
        </Button>
      </div>
    </main>
  )
}

export default Auth0Error
