import './rewardsHodlOverview.scss';

import * as React from 'react';
import { isNil } from "lodash";

import useDocTitle from "../../../../../hooks/useDocTitle";
import { useTypedSelector } from "../../../../../ducks";

import AddLionToHodl from "./components/AddLionToHodl";
import HodlList from "./components/HodlList";
import Tooltip from '../../../../shared/Tooltip';
import HodlUnlockRewards from "./components/HodlUnlockRewards";

const RewardsHodlOverview: React.FC = () => {
  useDocTitle('HODL Overview');

  const { globalTotals, userTotals } = useTypedSelector((state) => state.rewards);

  return (
    <div className="Hodl-Content Hodl-Content--overview">
      <section className="HodlData">
        <div className="HodlData-item">
          <dl className="HodlData-numbers">
            <dd>
              {globalTotals.hodlTotalAllUsers ? `${globalTotals.hodlTotalAllUsers.toLocaleString()} LION` : '--'}
            </dd>
            <dt>
              Total LION being HODL’d from all users. {globalTotals?.hodlTotalCoinLionUsers?.toLocaleString() || '0'} is from the CoinLion team who are not eligible to receive any Daily Rewards but can show their commitment by locking their LION up and not selling.

              <Tooltip>
                All Founders, Board members, Executives, Contractors, and Employees are unable to participate in the Rewards Program.  While it is not required we do request that all of those listed above HODL their LION to show their support and so that they can receive the Fee Discounts.  All of the individuals listed within are also not allowed to sell any more than 10% of their LION holdings in any given year.
              </Tooltip>
            </dt>

            <dd>
              {globalTotals.lionAllocatedForCurrentYear ? `${(globalTotals.lionAllocatedForCurrentYear / 365).toLocaleString()} LION` : '--'}
            </dd>
            <dt>
              Daily allocation of LION rewards for the HODL Programs

              <Tooltip>
                Total daily allocation of LION
              </Tooltip>
            </dt>
          </dl>
        </div>

        <div className="HodlData-item">
          <dl className="HodlData-numbers HodlData-numbers--your">
            <dd>
              {!isNil(userTotals.lionHodlLocked) ? `${userTotals.lionHodlLocked.toLocaleString()} LION` : '--'}
            </dd>
            <dt>
              Your LION Committed to HODL

              <Tooltip>
                The total amount of LION under HODL
              </Tooltip>
            </dt>

            <dd>
              {!isNil(userTotals.lionHodlLocked) && !isNil(userTotals.totalLionBalance) ? `${(userTotals.totalLionBalance - userTotals.lionHodlLocked).toLocaleString()} LION` : '--'}
            </dd>
            <dt>
              Your available LION Not Committed to HODL

              <Tooltip>
                Your LION Not Committed to HODL
              </Tooltip>
            </dt>
          </dl>
        </div>
      </section>

      <div className="HodlMyPlans">
        <AddLionToHodl />

        <HodlUnlockRewards />

        <HodlList />
      </div>
    </div>
  );
};

export default RewardsHodlOverview;
