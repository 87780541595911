import * as React from 'react';

type Props = {
  fill?: string
  height?: string
  width?: string
}

const AddressCard: React.FC<Props> = ({
  fill = '#500078',
  height = '32',
  width = '32',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 576 512"
      {...rest}
    >
      <path
        d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
        fill={fill}
      />
    </svg>
  )
}

export default AddressCard;
