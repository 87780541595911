import React from 'react'

const X = ({ fill = '#fff', width = '36', height = '36', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.41 18l7.29-7.29a1 1 0 00-1.41-1.41L18 16.59l-7.29-7.3A1 1 0 009.3 10.7l7.29 7.3-7.3 7.29a1 1 0 101.41 1.41l7.3-7.29 7.29 7.29a1 1 0 001.41-1.41L19.41 18z"
        fill={fill}
      />
    </svg>
  )
}

export default X
