import React, { useEffect } from 'react'
import useTradesDuck from '../../hooks/useTradesDuck'
import './closedpositionspanel.scss'
import ClosedPositionsChart from '@pv3-components/ClosedPositionsChart'
import Icon from '@pv3-shared-components/Icons'
import ComponentLoader from '@pv3-components/ComponentLoader'
import { APP_PATHS, LOADING_STATES } from '../../constants'
import { eq, gt, isEmpty, isNil, lt, isNull } from 'lodash'
import DashboardPanel from '../DashboardPanel'
import { Link } from 'react-router-dom'
import Loading from '@pv3-shared-components/Loading'
import { useDispatch } from 'react-redux'
import { fetchClosedPositions } from '../../ducks/trades'
import { Cell, Pie, PieChart } from "recharts";
import useStrategiesDuck from "../../hooks/useStrategiesDuck";

const ClosedPositionsPanel = () => {
  const dispatch = useDispatch()

  const {
    closedPositionsChartData,
    closedPositionsChartTimeframe,
    setClosedPositionsChartTimeframe,
    totalGainLoss,
    closedPositionCount,
    openPositions,
  } = useTradesDuck()
  const { builder } = useStrategiesDuck()

  useEffect(() => {
    dispatch(fetchClosedPositions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedPositionsChartTimeframe])

  const allocated = Math.round(builder.portfolioPercentAllocated * 100) / 100;
  const pieData = [{value: allocated}, {value: (100 - allocated)}];

  return (
    <DashboardPanel>
      <ComponentLoader
        loadingStates={[LOADING_STATES.FETCH_CLOSED_POSITIONS]}
      />
      <header className="ClosedPositionsPanel-header">
        <h1 className="ClosedPositionsPanel-heading">Auto-trading Activity</h1>
        <div className="ClosedPositionsPanel-timeframeOptions">
          <button
            className={`u-buttonReset  ClosedPositionsPanel-timeframeOption ${
              eq(closedPositionsChartTimeframe, 'day') ? 'active' : ''
            }`}
            onClick={() => setClosedPositionsChartTimeframe('day')}
          >
            24H
          </button>
          <button
            className={`u-buttonReset ClosedPositionsPanel-timeframeOption ${
              eq(closedPositionsChartTimeframe, 'week') ? 'active' : ''
            }`}
            onClick={() => setClosedPositionsChartTimeframe('week')}
          >
            Week
          </button>
          <button
            className={`u-buttonReset ClosedPositionsPanel-timeframeOption ${
              eq(closedPositionsChartTimeframe, 'month') ? 'active' : ''
            }`}
            onClick={() => setClosedPositionsChartTimeframe('month')}
          >
            Month
          </button>
        </div>
        <div className="ClosedPositionsPanel-topRightButtonContainer">
          <Link to={APP_PATHS.ACTIVITY_TRADE_HISTORY}>
            All trades <Icon name="arrowright" fill="#fff" />
          </Link>
        </div>
      </header>
      <div className="ClosedPositionsPanel-summaryData">
        <div className="ClosedPositionsPanel-summaryGroup">
          <strong className="ClosedPositionsPanel-figure ClosedPositionsPanel-figure--autoTrading">
            <PieChart width={24} height={24}>
              <Pie data={pieData} dataKey="value" cx="50%" cy="50%" outerRadius="12" startAngle={0} endAngle={-360}>
                {pieData.map((d, i) => <Cell stroke={0} fill={i === 0 ? '#500078': '#ffffff'} />)}
              </Pie>
            </PieChart>
            {allocated}%
          </strong>

          <span className="ClosedPositionsPanel-summaryLabel">Allocated to auto-trading</span>
        </div>

        <p className="ClosedPositionsPanel-summaryGroup">
          <strong className="ClosedPositionsPanel-figure">
            {closedPositionCount}
          </strong>
          <span className="ClosedPositionsPanel-summaryLabel">
            Closed positions
          </span>
        </p>

        <p className="ClosedPositionsPanel-summaryGroup">
          <strong className="ClosedPositionsPanel-figure ClosedPositionsPanel-figure--profitLoss">
            {gt(totalGainLoss, 0) && <Icon name="up" width="24" height="24" />}
            {lt(totalGainLoss, 0) && (
              <Icon name="down" width="24" height="24" />
            )}
            ${parseFloat(totalGainLoss.toFixed(2)).toLocaleString('en')}
          </strong>
          <span className="ClosedPositionsPanel-summaryLabel">Profit/Loss</span>
        </p>
        <Link
          className="ClosedPositionsPanel-summaryGroup PortfolioSummary-openPositions"
          to={APP_PATHS.ACTIVITY}
        >
          {isNull(openPositions) ? (
            <Loading />
          ) : (
            <strong className="ClosedPositionsPanel-figure">
              {openPositions.length}
            </strong>
          )}
          <span className="ClosedPositionsPanel-summaryLabel">
            Open positions
            <Icon name="arrowright" width="16" height="16" />
          </span>
        </Link>
      </div>

      {!isNil(closedPositionsChartData) &&
        !isEmpty(closedPositionsChartData) && (
          <ClosedPositionsChart
            data={closedPositionsChartData}
            timeframe={closedPositionsChartTimeframe}
          />
        )}
    </DashboardPanel>
  )
}

export default ClosedPositionsPanel
