import {  isNil } from 'lodash'
import { useHistory } from 'react-router'
import { APP_PATHS } from '@pv3-constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useOnboardingRedirect = () => {
  const [showSplash, setShowSplash] = useState(true)
  const { country, kycVerificationStatus } = useSelector((state) => state.profile.data);

  const history = useHistory()

  useEffect(() => {
    if (isNil(kycVerificationStatus)) {
      return setShowSplash(true)
    }

    // TODO: Leverage useMayVisitDashboard hook instead

    const mayVisitDashboard = !isNil(country);

    setShowSplash(false)

    if (!mayVisitDashboard) {
      history.replace(APP_PATHS.ONBOARDING_ACCOUNT_TYPE)
    }
  }, [country, history, kycVerificationStatus])

  return showSplash
}

export default useOnboardingRedirect
