import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import Icon from '@pv3-shared-components/Icons'
import './netassetstooltip.scss'
import { formatAsDollarAmount } from '../../utils/utils'
import PropTypes from 'prop-types'

const NetAssetsTooltip = ({ assetsIn, assetsOut, total }) => {
  const [id] = useState(_uniqueId('netassets-tooltip-'))
  const [toolTipModalOpen, setToolTipModalOpen] = useState(false)

  const handleToolTipButtonHover = () => setToolTipModalOpen(true)
  const handleToolTipButtonLeave = () => setToolTipModalOpen(false)

  return (
    <div
      className={`NetAssetsTooltip${
        toolTipModalOpen ? ' NetAssetsTooltip--modalOpen' : ''
      }`}
    >
      <button
        type="button"
        className="u-buttonReset NetAssetsTooltip-button"
        aria-label="Currency Allocation"
        aria-expanded={toolTipModalOpen ? true : false}
        aria-describedby={id}
        onFocus={handleToolTipButtonHover}
        onBlur={handleToolTipButtonLeave}
        onMouseOver={handleToolTipButtonHover}
        onMouseOut={handleToolTipButtonLeave}
      >
        <Icon name="info" width="16" height="16" />
      </button>
      <section id={id} role="tooltip" className="NetAssetsTooltip-content">
        <h1>Net Assets Contributed</h1>
        <table>
          <tbody>
            <tr>
              <th scope="row">value of assets in</th>
              <td>{formatAsDollarAmount(assetsIn)}</td>
            </tr>
            <tr>
              <th scope="row">value of assets out</th>
              <td>{formatAsDollarAmount(assetsOut)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th scope="row">total</th>
              <td>{formatAsDollarAmount(total)}</td>
            </tr>
          </tfoot>
        </table>
        <Icon
          className="NetAssetsTooltip-caret"
          name="currenciestooltipcaret"
        />
      </section>
    </div>
  )
}

NetAssetsTooltip.propTypes = {
  assetsIn: PropTypes.number,
  assetsOut: PropTypes.number,
  total: PropTypes.number,
}

export default NetAssetsTooltip
