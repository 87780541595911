import {isEmpty, get, eq, isNil, find} from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSumsubToken as _clearSumsubToken,
  convertAssets as _convertAssets,
  getCountries as _getCountries,
  getActivitySummary as _getActivitySummary,
  getAddresses as _getAddresses,
  getFacialVerificationResult as _getFacialVerificationResult,
  updateProfile as _updateProfile,
  getProfile as _getProfile,
  getStatePermissions as _getStatePermissions,
  updateBusinessProfile as _updateBusinessProfile,
  setAssetsForMigration as _setAssetsForMigration,
  setProfileType as _setProfileType,
  setHomeQuoteAsset as _setHomeQuoteAsset,
  setProfileTin as _setProfileTin,
  setProfileAcceptTerms as _setProfileAcceptTerms,
  setFacialVerificationApplicantActionId as _setFacialVerificationApplicantActionId,
  setFacialVerificationResult as _setFacialVerificationResult,
  getRoi as _getRoi,
  getSumsubToken as _getSumsubToken,
  testFacialVerificationExpiration as _testFacialVerificationExpiration,
  updateKycVerificationStatus as _updateKycVerificationStatus,
  uploadAvatar as _uploadAvatar,
} from './../ducks/profile'
import { unwrapResult } from '@reduxjs/toolkit'
import { PROFILE_TYPES } from '@pv3-constants'
import {isArrayNullOrEmpty, isObjectNullOrEmpty} from "../utils/utils";
import React from "react";

const useProfileDuck = () => {
  const dispatch = useDispatch()

  const {
    activitySummary,
    addresses,
    assetsForMigration,
    avatarUploadPercentage,
    data: profile,
    errors: profileErrors,
    permissions,
    statePermissions,
    roi,
    sumsubToken,
    countries,
    facialVerificationApplicantActionId,
    facialVerificationResult,
    facialVerificationTime,
  } = useSelector((state) => state.profile)

  const { usdAllowed, cryptoAllowed, reason } = permissions

  const {
    profileType,
    country: profileCountryCode,
    kycVerificationStatus,
  } = profile

  const profileErrorStatus = !isEmpty(profileErrors)
    ? get(profileErrors, '[0]["message"]')
    : ''

  const convertAssets = (data) => dispatch(_convertAssets(data))

  const getFacialVerificationResult = async (applicantActionId) => await dispatch(_getFacialVerificationResult(applicantActionId));

  const updateProfile = async (profile) => {
    const result = await dispatch(_updateProfile(profile))
    unwrapResult(result)
  }

  const profileNameInitials = useSelector((state) => {
    if (eq(PROFILE_TYPES.BUSINESS, get(profile, 'profileType'))) {
      if (isNil(get(profile, 'companyName'))) {
        return ''
      }

      return get(profile,'companyName').charAt(0)
    }

    const firstName = get(state,'profile.data.firstName')
    const lastName = get(state,'profile.data.lastName')

    if (isNil(firstName) || isNil(lastName)) {
      return ''
    }

    return firstName.charAt(0) + lastName.charAt(0)
  })

  const updateBusinessProfile = (updates) =>
    dispatch(_updateBusinessProfile(updates))

  const setProfileType = (profileType) => dispatch(_setProfileType(profileType))

  const setHomeQuoteAsset = (profileType) =>
    dispatch(_setHomeQuoteAsset(profileType))

  const setAssetsForMigration = (data) =>
    dispatch(_setAssetsForMigration(data))

  const setProfileTin = (profileTin) => dispatch(_setProfileTin(profileTin))

  const setProfileAcceptTerms = () => dispatch(_setProfileAcceptTerms())

  const getActivitySummary = () => dispatch(_getActivitySummary())

  const getAddresses = () => dispatch(_getAddresses())

  const getCountries = () => dispatch(_getCountries())

  const getProfile = () => dispatch(_getProfile())

  const getStatePermissions = () => dispatch(_getStatePermissions())

  const getRoi = () => {
    dispatch(_getRoi())
  }

  const getSumsubToken = (data) => dispatch(_getSumsubToken(data))

  const getUsdAllowed = () => {
    let country = get(profile, 'country');
    let state = get(profile, 'state');

    if(country != null && country !== 'US') {
      return false;
    }

    if(!state) {
      return true;
    }

    let statePermission = find(statePermissions, (st) => st.uspsId === state);
    return statePermission != null && get(statePermission, 'usdAllowed') === true;
  }

  const getCryptoAllowed = () => {
    let country = get(profile, 'country');
    let state = get(profile, 'state');

    if(country != null && country !== 'US') {
      return true;
    }

    if(!state) {
      return true;
    }

    let statePermission = find(statePermissions, (st) => st.uspsId === state);

    return statePermission != null && get(statePermission, 'cryptoAllowed') === true;
  }

  const renderAddressDisplay = () => {
    if(isObjectNullOrEmpty(profile)) {
      return null;
    }

    let { buildingNumber, country, street, state, subStreet, town, postCode, flatNumber } = profile;

    let countryName = ''
    if(country && !isArrayNullOrEmpty(countries)) {
      const foundCountry = find(countries, (c) => c.id === country);

      if(foundCountry) {
        countryName = foundCountry.name;
      }
    }

    // Settled on not showing address if there is no town available.
    // No idea if this is a good solution, and can easily change it.
    return (
      <React.Fragment>
        <dt>Address</dt>
        {!isNil(town) ? (
          <dd>
            {buildingNumber} {subStreet} {street} {flatNumber ? `#${flatNumber}` : ''}<br />
            {town}, {state} {postCode} {!isNil(countryName) ? (<React.Fragment><br /> {countryName}</React.Fragment>) : null}
          </dd>
        ) : (
          <dd>
            --
          </dd>
        )}
      </React.Fragment>
    );
  };

  const renderPhoneNumberDisplay = (phoneNumber) => {
    if(!phoneNumber && (isObjectNullOrEmpty(profile) || !profile.cellNumber)) {
      return null;
    }

    if(!phoneNumber) {
      phoneNumber = profile.cellNumber;
    }

    let splitPhoneNumber = phoneNumber.split(' ');
    if(splitPhoneNumber[0].indexOf('+') === 0) {
      splitPhoneNumber.splice(0, 1);
    }


    return (
      <React.Fragment>
        <dt>Cell phone</dt>
        <dd>
          {splitPhoneNumber.join(' ')}
        </dd>
      </React.Fragment>
    );
  };

  const clearSumsubToken = () => dispatch(_clearSumsubToken());
  const updateKycVerificationStatus = () => dispatch(_updateKycVerificationStatus());
  const setFacialVerificationApplicantActionId = (id) => dispatch(_setFacialVerificationApplicantActionId(id));
  const setFacialVerificationResult = (status) => dispatch(_setFacialVerificationResult(status));
  const testFacialVerificationExpiration = () => dispatch(_testFacialVerificationExpiration());

  const uploadAvatar = async (file) => await dispatch(_uploadAvatar(file));

  return {
    activitySummary,
    addresses,
    assetsForMigration,
    clearSumsubToken,
    convertAssets,
    getFacialVerificationResult,
    profile,
    profileCountryCode,
    profileNameInitials,
    updateProfile,
    setProfileType,
    setHomeQuoteAsset,
    setProfileTin,
    setProfileAcceptTerms,
    profileErrorStatus,
    profileType,
    updateBusinessProfile,
    cryptoAllowed,
    usdAllowed,
    roi,
    getActivitySummary,
    getAddresses,
    getCountries,
    getCryptoAllowed,
    getUsdAllowed,
    getProfile,
    getRoi,
    getSumsubToken,
    getStatePermissions,
    statePermissions,
    sumsubToken,
    kycVerificationStatus,
    reason,
    countries,
    renderAddressDisplay,
    renderPhoneNumberDisplay,
    updateKycVerificationStatus,
    uploadAvatar,
    avatarUploadPercentage,
    facialVerificationApplicantActionId,
    facialVerificationResult,
    facialVerificationTime,
    setAssetsForMigration,
    setFacialVerificationApplicantActionId,
    setFacialVerificationResult,
    testFacialVerificationExpiration,
  }
}

export default useProfileDuck;
