import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { formatAsDollarAmount, formatNumber } from '../../utils/utils'
import Input from '@pv3-shared-components/Input'
import {
  get,
  isUndefined,
  upperCase,
  isNil,
  isNaN,
  eq,
  gt,
  lt,
  round,
  isNull,
  isEmpty,
  find,
} from 'lodash'
import Button from '@pv3-shared-components/Button'
import Icon from '@pv3-shared-components/Icons'
import './manualbuyform.scss'
import AssetWalletItem from '../AssetWalletItem'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import { format } from 'date-fns'
import useMarketsDuck from '../../hooks/useMarketsDuck'
import useFilterMarkets from '../../hooks/useFilterMarkets'
import { ButtonTypes, ButtonVariants } from '../shared/Button';
import Select from '../shared/Select';
import { GOOD_TILL_OPTIONS } from '../../constants';
import moment from 'moment';
import Tooltip from '../shared/Tooltip';

const BadMarketBlocker = ({ hasMarket, children }) => {
  if (!hasMarket)
    return (
      <EnhancedFlash variant="warning" heading="This market is not available" />
    )

  return children
}

const ManualBuyForm = ({
  buyAsset,
  payAsset,
  handleChooseBuyAsset,
  handleChooseExchangeAsset,
  handleSubmitBuyOrder,
  buyOrder,
  onInstrumentChange,
  isLimit,
}) => {
  const { calculateApproximateBuyPrice, calculateBestAskPrice, findBuySellPrice, orderBooks, startMiniTicker } = useMarketsDuck()

  const formMethods = useForm({
    defaultValues: isEmpty(buyOrder)
      ? undefined
      : {
          price: buyOrder.price,
          amount: buyOrder.amount,
          limitOrderAmount: buyOrder.limitOrderAmount,
        },
  })
  const { handleSubmit, formState, watch, setFocus, setValue, setError, trigger, getValues } =
    formMethods
  const { errors } = formState

  const [displayError, setDisplayError] = useState()

  const [showFormattedAmount, setShowFormattedAmount] = useState(false)

  const [chooseAmountInUsd/*, setChooseAmountInUsd*/] = useState(false)

  const { balances, buySellPrices, buySellPricesUpdateDateTime, walletValuesInUSD } = useWalletsDuck()

  const [limitOrderAmountFocused, setLimitOrderAmountFocused] = useState(false)
  const [totalFocused, setTotalFocused] = useState(false)
  const [priceFocused, setPriceFocused] = useState(false)
  const [goodTillValue, setGoodTillValue] = useState('90|days')
  const [calculatedTotalPrice, setCalculatedTotalPrice] = useState();

  const { marketsData } = useFilterMarkets()

  const amount = watch('amount')
  const price = watch('price')
  const limitOrderAmount = watch('limitOrderAmount')
  const total = watch('total')

  let priceHasBeenTouched = useRef(false);
  let quantityHasBeenTouched = useRef(false);

  const buyAssetIsLion = eq('lion', buyAsset);

  const { buyScale, payScale, hasMarket, instrument, type } = useMemo(() => {
    if (eq(marketsData.length))
      return {
        buyScale: null,
        payScale: null,
        hasMarket: false,
      }

    const marketPair1 = find(
      marketsData,
      ({ baseAsset, quoteAsset }) =>
        eq(baseAsset, buyAsset) && eq(quoteAsset, payAsset)
    )
    if (!isUndefined(marketPair1))
      return {
        buyScale: marketPair1.amountScale,
        payScale: marketPair1.priceScale,
        hasMarket: true,
        instrument: `${buyAsset}_${payAsset}`,
        type: 'buy',
      }

    return {
      buyScale: null,
      payScale: null,
      hasMarket: false,
    }
  }, [buyAsset, payAsset, marketsData])

  const maxBuy = useMemo(
    () => {
      if(isNil(orderBooks) || isNil(instrument)) {
        return 0;
      }

      let book = orderBooks[/lion_usd|lion_usdt/.test(instrument) ? 'lion_usdc' : instrument];

      if(isNil(book)) {
        return 0;
      }

      let total = 0;
      book.asks.forEach((ask) => {
        total += ask.amount;
      });

      return total;
    }, [instrument, orderBooks]
  );

  const { marketExchangeRate, lastMarketExchangeRateUpdate } = useMemo(() => {
    if(isNil(buySellPricesUpdateDateTime)) {
      return {};
    }

    return {
      marketExchangeRate: buySellPrices[buyAsset].buyPrice,
      lastMarketExchangeRateUpdate: buySellPricesUpdateDateTime
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyAsset, buySellPrices, buySellPricesUpdateDateTime, instrument])

  const calculateMaxForMarketOrders = () => {
    const price = buyAssetIsLion ? calculateBestAskPrice({amount, instrument}) : findBuySellPrice(instrument);
    const balance = calculateTotalBalance();

    return Math.floor((balance / price) * (10 ** buyScale)) / (10 ** buyScale);
  };

  const calculateTotalBalance = () => {
    // If LION is being purchased, do some hackery to display total of home quote currency + USDC
    // CLV3-889

    if(buyAssetIsLion && ['usd', 'usdt'].includes(payAsset)) {
      return balances[payAsset] + (balances.usdc || 0);
    }

    return balances[payAsset];
  }

  const handleBuyMax = () => {
    const balance = calculateTotalBalance();
    let value = Math.floor(balance * 100) / 100;
    setValue('total', value);
  };

  const handleBuyMaxMarket = () => {
    if(buyAssetIsLion) {
      const book = orderBooks['lion_usdc'];
      if(isNil(book) || book.asks?.length === 0) {
        return 0;
      }

      const balance = calculateTotalBalance();

      let totalPrice = 0;
      let totalAmount = 0;

      for(let ask of book.asks) {
        if(totalPrice >= balance) {
          break;
        }

        if(balance > totalPrice + (ask.amount * ask.price)) {
          totalPrice += ask.amount * ask.price;
          totalAmount += ask.amount;
        } else {
          const amountRemaining = balance - totalPrice;
          totalPrice += amountRemaining;
          totalAmount += Math.floor(amountRemaining / ask.price);
          break;
        }
      }

      setCalculatedTotalPrice(formatAsDollarAmount(totalPrice));
      setValue('amount', totalAmount);
      return;
    }

    const amount = calculateMaxForMarketOrders();
    setValue('amount', amount);
  }

  const handleFormSubmit = (data) => {
    // validate total even if it other fields have not been touched.
    if(isLimit && !quantityHasBeenTouched.current && !priceHasBeenTouched.current) {
      quantityHasBeenTouched.current = true;
      priceHasBeenTouched.current = true;
      trigger();

      const totalValue = getValues('total');
      if(isNil(totalValue) || !gt(parseFloat(totalValue), 0)) {
        return;
      }
    }

    if (eq(buyAsset, payAsset))
      return setError('assets', {
        type: 'manual',
        message: 'You must choose different assets to Buy and Pay with',
      })

    if(isLimit) {
      handleSubmitBuyOrder({
        isLimit,
        buyAsset,
        payAsset,
        type,
        marketExchangeRate,
        goodTill: moment().add(parseInt(goodTillValue.split('|')[0]), goodTillValue.split('|')[1]).format(),
        ...data,
      });
    } else {
      handleSubmitBuyOrder({
        isLimit,
        buyAsset,
        payAsset,
        amount: data.amount,
      });
    }
  }

  useEffect(() => {
    startMiniTicker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!hasMarket) return

    if(!isLimit) setFocus('amount');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMarket, isLimit])

  useEffect(() => {
    if (
      isUndefined(limitOrderAmount) ||
      eq(limitOrderAmount, '') ||
      isUndefined(price) ||
      isNull(payScale) ||
      totalFocused
    )
      return

    const calculatedTotal = Math.ceil((limitOrderAmount * price) * (10 ** payScale)) / (10 ** payScale);
    if (!eq(total, calculatedTotal)) setValue('total', calculatedTotal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitOrderAmount, price, payScale])

  useEffect(() => {
    if (
      isUndefined(total) ||
      eq(total, '') ||
      isUndefined(price) ||
      eq(price, 0) ||
      isNull(buyScale) ||
      priceFocused ||
      limitOrderAmountFocused
    )
      return

    const calculatedLimitOrderAmount = Math.floor((total / price) * (10 ** buyScale)) / (10 ** buyScale);
    if (!eq(limitOrderAmount, calculatedLimitOrderAmount))
      setValue('limitOrderAmount', calculatedLimitOrderAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, price, buyScale])

  useEffect(() => {
    if (!isUndefined(get(errors, 'amount.message')))
      return setDisplayError(errors.amount.message)

    if (!isUndefined(get(errors, 'assets.message')))
      return setDisplayError(errors.assets.message)

    setDisplayError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.amount, errors.assets])

  useEffect(() => {
    if (eq(amount, 0)) return

    if (chooseAmountInUsd) {
      setValue('amount', amount * buySellPrices[buyAsset].buyPrice)
    } else {
      if(isLimit) {
        setValue('amount', amount / buySellPrices[buyAsset].buyPrice)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseAmountInUsd])

  useEffect(() => {
    onInstrumentChange(instrument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrument])

  useEffect(() => {
    if (isNil(marketExchangeRate) || isNil(payScale)) return
    if (eq(price, 0) || eq(price, '0') || isUndefined(price))
      setValue('price', Math.ceil(marketExchangeRate * (10 ** payScale)) / (10 ** payScale))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketExchangeRate, payScale])

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <BadMarketBlocker hasMarket={hasMarket}>
            {isLimit ? (
              <React.Fragment>
                <h4 className="ManualBuyForm-headline">Limit Order</h4>
                <EnhancedFlash
                  variant="info"
                  heading={`Enter the maximum price you’re willing to pay per ${upperCase(
                    buyAsset
                  )}.`}
                />
                <div className="ManualBuyForm-limitOrderInputs">
                  <Input
                    name="price"
                    suffix={buyAssetIsLion ? 'USDC' : upperCase(payAsset)}
                    modifierClass="u-noMargin"
                    className="ManualBuyForm-limitOrderInput"
                    type="number"
                    onFocus={() => {
                      setPriceFocused(true)
                      priceHasBeenTouched.current = true;
                    }}
                    onBlur={() => {
                      setPriceFocused(false)
                      setValue('price', Math.ceil(price * (10 ** payScale)) / (10 ** payScale));
                      trigger()
                    }}
                    min="0"
                    step="any"
                    defaultValue="0"
                    inputMode="decimal"
                    triggerValidation
                  >
                    Price per {upperCase(buyAsset)}
                  </Input>
                  {!isUndefined(marketExchangeRate) &&
                    !isUndefined(lastMarketExchangeRateUpdate) && (
                      <p className="ManualBuyForm-inputSubtext">
                        {formatNumber(marketExchangeRate)} {buyAssetIsLion ? 'USDC' : upperCase(payAsset)}{' '}
                        = market price at{' '}
                        {format(lastMarketExchangeRateUpdate, "h:mmaaaaa'm'")}
                      </p>
                    )}

                  {gt(price, marketExchangeRate * 1.01) &&
                    !eq(price, '') &&
                    !eq(marketExchangeRate, 0) && (
                      <EnhancedFlash
                        variant="danger"
                        heading={`You are offering to pay ${round(
                          ((price - marketExchangeRate) / marketExchangeRate) *
                          100
                        )}% more than the current market price.`}
                        subheading="Review your order before proceeding"
                      />
                    )}

                  {lt(price, marketExchangeRate * 0.9) && !eq(price, '') && (
                    <EnhancedFlash
                      variant="warning"
                      heading={`Your offer is ${round(
                        ((marketExchangeRate - price) / marketExchangeRate) *
                        100
                      )}% below the current market price.`}
                      subheading="It may take a while to find a seller at this price."
                    />
                  )}

                  <div className="DepositCrypto-amountContainer">
                    <Input
                      name="limitOrderAmount"
                      suffix={upperCase(buyAsset)}
                      className="ManualBuyForm-limitOrderInput"
                      onFocus={() => {
                        setLimitOrderAmountFocused(true)
                        quantityHasBeenTouched.current = true;
                      }}
                      onBlur={() => {
                        setLimitOrderAmountFocused(false)
                        setValue(
                          'limitOrderAmount',
                          Math.floor(limitOrderAmount * (10 ** buyScale)) / (10 ** buyScale)
                        )
                        trigger();
                      }}
                      type="number"
                      step={
                        chooseAmountInUsd ? '.01' : String(10 ** (-1 * buyScale))
                      }
                      min="0"
                      defaultValue="0"
                      inputMode="decimal"
                      triggerValidation
                    >
                      Quantity
                    </Input>

                    <Button
                      className="DepositCrypto-maxButton"
                      onClick={() => handleBuyMax()}
                      type="button"
                      variant="blank"
                    >
                      Buy Max
                    </Button>
                  </div>

                  <Input
                    name="total"
                    registerConfig={{
                      validate: {
                        positive: (v) => {
                          return (
                            !priceHasBeenTouched.current ||
                            !quantityHasBeenTouched.current ||
                            gt(parseFloat(v), 0)
                          ) || 'Amount must be greater than 0'
                        }
                      },
                      max: {
                        value: calculateTotalBalance(),
                        message: `You lack the necessary balance in your ${!buyAssetIsLion ? upperCase(
                          payAsset
                        ) : ''} wallet`,
                      },
                    }}
                    suffix={buyAssetIsLion ? 'USD(C/T)' : upperCase(payAsset)}
                    className="ManualBuyForm-limitOrderInput"
                    onFocus={() => setTotalFocused(true)}
                    onBlur={() => {
                      setTotalFocused(false)
                      setValue('total', Math.ceil(total * (10 ** payScale)) / (10 ** payScale));
                      trigger();
                    }}
                    type="number"
                    step="any"
                    min="0"
                    inputMode="decimal"
                    triggerValidation
                  >
                    Total
                  </Input>
                </div>
              </React.Fragment>
              ) : (
              <React.Fragment>
              <p className="AchDepositForm-amountLabel">Amount</p>
                <div className="AchDepositForm-amountInputContainer">
                  {showFormattedAmount && (
                    <div className="AchDepositForm-formattedAmountOverlay">
                      {
                        <p>
                          {chooseAmountInUsd
                            ? isNaN(amount)
                              ? '$0'
                              : formatAsDollarAmount(amount, true)
                            : isNaN(amount)
                            ? 0
                            : formatNumber(amount)}
                        </p>
                      }
                    </div>
                  )}
                  <Input
                    type="number"
                    className="AchDepositForm-bigNumberInput"
                    inputMode="decimal"
                    name="amount"
                    step={
                      chooseAmountInUsd ? '.01' : String(10 ** (-1 * buyScale))
                    }
                    registerConfig={{
                      required: 'Amount is required',
                      max: {
                        value: chooseAmountInUsd
                          ? walletValuesInUSD[payAsset]
                          : calculateMaxForMarketOrders(),
                        message: `You lack the necessary balance in your ${upperCase(
                          payAsset
                        )} wallet`,
                      },
                      validate: {
                        positive: (v) =>
                          gt(parseFloat(v), 0) ||
                          'Amount must be greater than 0',
                      },
                    }}
                    onBlur={() => {
                      setShowFormattedAmount(true);
                      setValue(
                        'amount',
                        Math.floor(amount * (10 ** buyScale)) / (10 ** buyScale)
                      )
                    }}
                    onFocus={() => setShowFormattedAmount(false)}
                    defaultValue="0"
                    error={<React.Fragment />}
                  />
                </div>
                <p className="AchDepositForm-currencyLabel u-noMargin">
                  {chooseAmountInUsd ? 'USD' : upperCase(buyAsset)}

                  <Button
                      className="BuySellModal-maxButton"
                      onClick={handleBuyMaxMarket}
                      type={ButtonTypes.Button}
                      variant={ButtonVariants.Blank}
                  >
                      Buy Max
                  </Button>
                </p>
                <div className="ManualBuyForm-dividerContainer">
                  <hr className="u-divider ManualBuyForm-divider" />
                  {/*<Button
                    variant="icon"
                    type="button"
                    onClick={() => setChooseAmountInUsd((v) => !v)}
                  >
                    <Icon name="twowayarrows" />
                  </Button>*/}
                </div>

                {chooseAmountInUsd ? (
                  <p className="ManualBuyForm-convertedAmount">
                    ≈{' '}
                    {isNaN(amount / buySellPrices[buyAsset].buyPrice)
                      ? 0
                      : formatNumber(amount / buySellPrices[buyAsset].buyPrice)}{' '}
                    {upperCase(buyAsset)}
                  </p>
                ) : (
                  <p className="ManualBuyForm-convertedAmount">
                    {buyAssetIsLion && maxBuy < amount ? 'ERROR' : (
                      !isNil(calculatedTotalPrice) ? calculatedTotalPrice : calculateApproximateBuyPrice({ amount, instrument })
                    )}
                  </p>
                )}
              </React.Fragment>
            )}
          </BadMarketBlocker>

          {!isNil(displayError) && (
            <EnhancedFlash
              variant="danger"
              heading={displayError}
              className="ManualBuyForm-errorFlash"
            />
          )}

          {buyAssetIsLion && (maxBuy < parseFloat(amount) || maxBuy === 0) && (
            <EnhancedFlash
              variant="danger"
              heading={`There is not enough LION on the book to fill your order. The max amount available is ${maxBuy.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} LION.`}
              className="ManualBuyForm-errorFlash"
            />
          )}

          <div className="ManualBuyForm-currencySelectorBox">
            <div className="ManualBuyForm-currencySelectorBoxLabel">Buy</div>
            <Button
              variant="blank"
              className="ManualBuyForm-currencySelectorButton"
              type="button"
              onClick={handleChooseBuyAsset}
            >
              <AssetWalletItem
                assetId={buyAsset}
                className="ManualBuyForm-currencySelectorButtonContent"
                showWalletBalance
              />
              <Icon name="caretright" />
            </Button>
          </div>

          {!buyAssetIsLion && (
            <div className={`ManualBuyForm-currencySelectorBox ${buyAssetIsLion ? 'ManualBuyForm-currencySelectorBox--disabled' : ''}`}>
              <div className="ManualBuyForm-currencySelectorBoxLabel">
                Pay with
              </div>
              <Button
                variant="blank"
                className="ManualBuyForm-currencySelectorButton"
                type="button"
                onClick={handleChooseExchangeAsset}
                disabled={buyAssetIsLion}
              >
                <AssetWalletItem
                  assetId={payAsset}
                  className="ManualBuyForm-currencySelectorButtonContent"
                  buyAssetIsLion={buyAssetIsLion}
                  showWalletBalance
                />
                <Icon name="caretright" />
              </Button>
            </div>
          )}

          {isLimit && (
            <div className="DepositCrypto-amountContainer">
              <label className="Label Label--goodTill">
                Orders are good for:
                <Select
                  ariaLabel="Select how long a limit order is good for"
                  options={GOOD_TILL_OPTIONS}
                  defaultValue="90|days"
                  onChange={(e) => setGoodTillValue(e.target.value)}
                />

                <Tooltip left>
                  If your order is not filled in the time frame then it will be canceled and removed from the book. This is to help keep a healthy and engaged group of users and an accurate book.
                </Tooltip>
              </label>
            </div>
          )}

          <div className="ManualBuyForm-submitButtonContainer">
            <Button
              type="submit"
              variant="primary"
              size="large"
              className="u-fullWidth"
              disabled={!hasMarket}
            >
            Preview Buy Order
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default ManualBuyForm
