import './myReferralsList.scss';

import * as React from 'react';
import { useMemo, useState } from "react";
import * as moment from "moment";

import { isArrayNullOrEmpty } from '../../../../../../../utils/utils';

import { ReferralRewardAction } from "../../../../../../../types/ReferralRewardAction";
import { useTypedSelector } from "../../../../../../../ducks";

import Icon from '../../../../../../shared/Icons';
import Pagination from "../../../../../../shared/Pagination";

const PAGE_SIZE = 8;

type Props = {
  levels: {
    invitedLevels: Array<ReferralRewardAction>
    publisherLevels: Array<ReferralRewardAction>
  }
}

const MyReferralsList: React.FC<Props> = ({
  levels,
}) => {
  const { referrals } = useTypedSelector((state) => state.rewards);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { invitedLevels, publisherLevels } = levels;

  const referralsToShow = useMemo(
    () => {
      if(isArrayNullOrEmpty(referrals)) {
        return [];
      }

      return referrals.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
    }, [pageNumber, referrals]
  );

  return (
    <div className="MyReferrals-ListContainer">
      <header className="MyReferrals-ListHeader">
          <div className="MyReferrals-ListHeaderField Referral-Name">
              Friend Name
          </div>

          <div className="MyReferrals-ListHeaderField Referral-Levels">
              Rewards Plan
          </div>

          <div className="MyReferrals-ListHeaderField Referral-Earned">
              Earned By Referral
          </div>

          <div className="MyReferrals-ListHeaderField Referral-Date">
              Date Joined
          </div>
      </header>

      <ul className="MyReferrals-List">
        {referralsToShow.map((r) => (
          <li className="Referral">
            <div className="Referral-Field Referral-Name">
              <div className="Referral-Value">
                {r.referreeDisplayName}
              </div>

              <div className="Referral-Label">
                User ID
              </div>
            </div>

            <div className="Referral-Field Referral-Date">
              <div className="Referral-Value">
                {moment(r.dateReferred).format('M/D/YY hh:mm A')}
              </div>

              <div className="Referral-Label">
                Date Joined
              </div>
            </div>

            <div className="Referral-Field Referral-Earned">
              <div className="Referral-Value">
                {r.earned.toLocaleString()} LION
              </div>

              <div className="Referral-Label">
                Earned by Referral
              </div>
            </div>

            <div className="Referral-Levels">
              <h6>
                Rewards Plan
              </h6>

              <div className="Referral-Level">
                <div className="Referral-Label Referral-LevelName">
                  Referral
                </div>

                <div className="Referral-LevelList Referral-LevelList--referral">
                  {invitedLevels.map((l) => {
                    const reward = r.referralRewards.find((rr) => rr.programId === l.id);
                    return (
                      <div className={`Referral-LevelBadge ${reward.completedDate ? 'Referral-LevelBadge--completed' : ''}`}>
                        {reward.completedDate && (
                          <Icon name="checkmarksmall" />
                        )}

                        {l.value1Numeric.toLocaleString()}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="Referral-Level">
                <div className="Referral-Label Referral-LevelName">
                  Publisher
                </div>

                <div className="Referral-LevelList Referral-LevelList--publisher">
                  {publisherLevels.map((l) => {
                    const reward = r.referralRewards.find((rr) => rr.programId === l.id);
                    return (
                      <div className={`Referral-LevelBadge ${reward.completedDate ? 'Referral-LevelBadge--completed' : ''}`}>
                        {reward.completedDate && (
                          <Icon name="checkmarksmall" />
                        )}

                        {l.value1Numeric.toLocaleString()}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {referrals.length > PAGE_SIZE && (
          <Pagination
              onPageChange={({ selected }) => setPageNumber(selected)}
              pageCount={Math.ceil(referrals.length / PAGE_SIZE)}
          />
      )}
    </div>
  );
};

export default MyReferralsList;
