import React, {useEffect, useMemo} from 'react';
import './strategySignals.scss';
import {useParams} from "react-router-dom";
import useStrategiesDuck from "../../hooks/useStrategiesDuck";
import moment from "moment";
import Datepicker from "../shared/Datepicker";
import useUiDuck from "../../hooks/useUiDuck";
import {LOADING_STATES} from "../../constants";
import Loading from "../shared/Loading";
import {isArrayNullOrEmpty} from "../../utils/utils";
import clone from 'clone';
import {isEmpty, isNil} from "lodash";
import Table from "../shared/Table";
import {format} from "date-fns";
import {CSVLink} from "react-csv";
import MultiSelect from "../shared/MultiSelect";

const DATE_FORMAT = 'MM/DD/YYYY';

const StrategySignals = () => {
  const { strategyId } = useParams();

  const {
    getIndicators,
    getStrategySignals,
    indicators,
    setStrategySignalsFilters,
    strategySignalsFilters,
    strategySignalsFilters: {
      startDate,
      endDate,
      selectedEventType,
    },
    strategySignals,
    strategySignalsStrategyId,
    structureTriggerFieldValues
  } = useStrategiesDuck();
  const { loading } = useUiDuck();

  useEffect(() => {
    if(strategyId === strategySignalsStrategyId) {
      return;
    }

    getUpdatedIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyId, strategySignalsStrategyId]);

  useEffect(() => {
    if (isEmpty(indicators)) {
      getIndicators()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUpdatedIndicators = (filters) => {
    if(isNil(filters)) {
      filters = strategySignalsFilters;
    }
    const { startDate, endDate } = filters;
    getStrategySignals({strategyId, startDate: moment(startDate).format(), endDate: moment(endDate).add(1, 'days').format()});
  };

  const handleEventTypeChange = (selected) => {
    let clonedStrategySignalsFilters = clone(strategySignalsFilters);
    clonedStrategySignalsFilters.selectedEventType = selected;
    setStrategySignalsFilters(clonedStrategySignalsFilters);
  };

  const filteredSignals = useMemo(
    () => {
      if(isArrayNullOrEmpty(strategySignals)) {
        return [];
      }

      let filtered = strategySignals;

      if(!isArrayNullOrEmpty(selectedEventType)) {
        filtered = filtered.filter((f) => {
          return !isNil(selectedEventType.find((type) => f.type === type.value));
        });
      }

      filtered = filtered.map((signal) => {
        let clonedSignal = clone(signal);

        let indicatorTriggers = [];

        if(!isArrayNullOrEmpty(clonedSignal.triggerData)) {
          indicatorTriggers = clonedSignal.triggerData.map((trigger) => {
            return structureTriggerFieldValues(trigger);
          });
        }

        clonedSignal.indicatorTriggers = indicatorTriggers;

        return clonedSignal;
      });

      return filtered;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indicators, selectedEventType, strategySignals]
  );

  const signalsReport = useMemo(
    () => {
      return filteredSignals.map((s) => {
        let triggers = s.indicatorTriggers.map((i) => `${i.name} - ${i.fields?.join(', ')}`);
        if(s.validationErrors) {
          triggers.push(s.validationErrors);
        }
        return {
          date: moment(s.created).format('M/D/YY hh:mm A'),
          type: s.type,
          cost: triggers.join('\n'),
        }
      })
    }, [filteredSignals]
  );

  const eventTypes = useMemo(
    () => {
      if(isArrayNullOrEmpty(filteredSignals)) {
        return [];
      }

      let eventTypeOptions = [];

      strategySignals.forEach((signal) => {
          if(!isNil(signal.type)) {
            let foundIndex = eventTypeOptions.findIndex((o) => o.value === signal.type);
            if(foundIndex === -1) {
              eventTypeOptions.push({
                label: signal.type,
                value: signal.type,
              });
            }
          }
      });

      return eventTypeOptions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategySignals]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        id: 'date',
        sortable: false,
        Cell: ({ original }) => (
          <React.Fragment>
            {moment(original.created).format('M/D/YY')} <br />
            {moment(original.created).format('hh:mm A')}
          </React.Fragment>
        ),
        width: 100,
      },
      {
        Header: 'Event Type',
        id: 'eventType',
        sortable: false,
        accessor: 'type',
        width: 200,
      },
      {
        Header: 'Detailed Description',
        id: 'values',
        sortable: false,
        Cell: ({original}) => {
          return (
            <div className="StrategySignalsList--triggerValues">
              {!isArrayNullOrEmpty(original.indicatorTriggers) && original.indicatorTriggers.map((i, index) => (
                <div>
                  {i.name} - {i.fields?.join(', ')}
                </div>
              ))}

              {!isNil(original.validationErrors) && original.validationErrors !== '' && (
                <div>
                  {original.validationErrors}
                </div>
              )}
            </div>
          );
        },
        minWidth: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [indicators]
  );

  return (
    <div className="StrategySignalsList Strategy-content u-container">
      <div className="MyPortfolioCard">
      <label>Only signals for the last 90 days are available</label>
        <div className="StrategySignalsList-filters">
          <div className="StrategySignalsList-filter">
            <label htmlFor="startDate">
              Start Date
            </label>

            <Datepicker
              id="startDate"
              maxDate={endDate ? endDate : moment().format(DATE_FORMAT)}
              minDate={moment().subtract(90, 'days').format(DATE_FORMAT)}
              onChange={(newDate) => {
                let clonedStrategySignalsFilters = clone(strategySignalsFilters);
                clonedStrategySignalsFilters.startDate = moment(newDate).format(DATE_FORMAT);
                setStrategySignalsFilters(clonedStrategySignalsFilters);
                getUpdatedIndicators(clonedStrategySignalsFilters);
              }}
              value={startDate}
            />
          </div>

          <div className="StrategySignalsList-filter">
            <label htmlFor="endDate">
              End Date
            </label>

            <Datepicker
              id="endDate"
              maxDate={moment().format(DATE_FORMAT)}
              minDate={startDate ? startDate : null}
              onChange={(newDate) => {
                let clonedStrategySignalsFilters = clone(strategySignalsFilters);
                clonedStrategySignalsFilters.endDate = moment(newDate).format(DATE_FORMAT);
                setStrategySignalsFilters(clonedStrategySignalsFilters);
                getUpdatedIndicators(clonedStrategySignalsFilters);
              }}
              value={endDate}
            />
          </div>

          <div className="StrategySignalsList-filter StrategySignalsList-filter--eventTypes">
            <label htmlFor="endDate">
              Event Type
            </label>

            <MultiSelect
              ariaLabel="Select event type to filter by"
              options={eventTypes}
              id="strategyEventTypes"
              name="eventType"
              defaultValue={selectedEventType}
              onChange={handleEventTypeChange}
            />
          </div>

          <div className="StrategySignalsList-filter StrategySignalsList-filter--download">
            <CSVLink
              data={signalsReport}
              className="StrategySignalsList-downloadResults"
              filename={`Trade-History-Report_${format(
                new Date(),
                'MM-dd-yy'
              )}.csv`}
            >
              Download Results
            </CSVLink>
          </div>
        </div>

        {loading[LOADING_STATES.GETTING_STRATEGY_SIGNALS] || isArrayNullOrEmpty(indicators) ? (
          <Loading />
        ) : (
          <div className="StrategySignalsList-container">
            {isArrayNullOrEmpty(strategySignals) ? (
              <div className="StrategySignalsList-empty">
                No logs for this strategy
              </div>
            ) : (
              <Table
                columns={columns}
                data={filteredSignals}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StrategySignals;
