import React from 'react'

const CirclePlus = ({
  fill = '#323232',
  width = '18',
  height = '18',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 .111A8.889 8.889 0 1 0 9 17.89 8.889 8.889 0 0 0 9 .11Zm0 16.667A7.778 7.778 0 1 1 9 1.222a7.778 7.778 0 0 1 0 15.556Zm.556-8.334h3.983a.556.556 0 0 1 0 1.112H9.556v3.983a.556.556 0 0 1-1.112 0V9.556H4.461a.556.556 0 0 1 0-1.112h3.983V4.461a.556.556 0 0 1 1.112 0v3.983Z"
        fill={fill}
      />
    </svg>
  )
}

export default CirclePlus
