import React from 'react'
import { Switch, Route } from 'react-router-dom'
import HeroBanner from '@pv3-shared-components/HeroBanner'
import TradeHistory from '@pv3-components/TradeHistory'
import { APP_PATHS } from '@pv3-constants'
import OpenPositions from '@pv3-components/OpenPositions'
import IncompleteOrders from '@pv3-components/IncompleteOrders'
import DepositsWithdrawals from '@pv3-components/DepositsWithdrawals'

const Activity = () => {
  return (
    <React.Fragment>
      <HeroBanner
        headline="Activity"
        subnav={[
          {
            label: 'Open Positions',
            path: APP_PATHS.ACTIVITY,
          },
          {
            label: 'Trade History',
            path: APP_PATHS.ACTIVITY_TRADE_HISTORY,
          },
          {
            label: 'Incomplete Orders',
            path: APP_PATHS.ACTIVITY_INCOMPLETE_ORDERS,
          },
          {
            label: 'Deposits & Withdrawals',
            path: APP_PATHS.ACTIVITY_DEPOSITS_WITHDRAWALS,
          },
        ]}
      />
      <Switch>
        <Route
          path={APP_PATHS.ACTIVITY_TRADE_HISTORY}
          component={TradeHistory}
        />
        <Route
          path={APP_PATHS.ACTIVITY_INCOMPLETE_ORDERS}
          component={IncompleteOrders}
        />
        <Route
          path={APP_PATHS.ACTIVITY_DEPOSITS_WITHDRAWALS}
          component={DepositsWithdrawals}
        />
        <Route path={APP_PATHS.ACTIVITY} component={OpenPositions} />
      </Switch>
    </React.Fragment>
  )
}

export default Activity
