import React, { useState, useEffect, useMemo } from 'react'
import clone from 'clone';
import useDocTitle from '@pv3-hooks/useDocTitle'
import './copycats.scss'
import Select from '@pv3-shared-components/Select'
import useMarketplaceDuck from '@pv3-hooks/useMarketplaceDuck'
import { STATS_TIME_FRAMES } from '@pv3-constants'
import {
  toNumber,
  orderBy,
  eq,
  get,
  find,
  isNil,
  isEmpty,
} from 'lodash'
import Loading from '@pv3-shared-components/Loading'
import useProfileDuck from "../../hooks/useProfileDuck";
import PublisherCard from '../PublisherCard';

const SORT_BY_OPTIONS = [
  { label: 'Annualized ROI', value: 'publisherRoiPercent' },
  { label: 'Strategy Performance', value: 'strategyPerformance' },
  { label: 'Tradable Assets', value: 'tradableAssetsUsd' },
  { label: 'Subscribers', value: 'numberOfSubscribers' },
  { label: 'Followers PnL', value: 'followersPnl' },
  { label: 'PnL per Trade', value: 'averageProfitCombined' },
];

const MarketplaceCopycatTrading = () => {
  useDocTitle('CopyCat Trading')
  const { publishers, subscriptions, marketplaceStats, getMarketplaceStats } =
    useMarketplaceDuck()
  const { getActivitySummary } = useProfileDuck();
  const { profile } = useProfileDuck();

  const [statsTimePeriod, setStatsTimePeriod] = useState('1')
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS[0].value);

  useEffect(() => {
    getActivitySummary();
    if (!isEmpty(marketplaceStats)) return
    getMarketplaceStats()
    const timer = setInterval(() => getMarketplaceStats(), 600000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatsTimePeriodChange = (event) => {
    const { value } = event.target
    setStatsTimePeriod(value)
  }

  const handleSortByChange = (event) => {
    const { value } = event.target
    setSortBy(value)
  }

  const publishersToDisplay = useMemo(
    () => {
      const calculateStrategyPerformance = (publisherStats) => {
        if (isNil(publisherStats) || isNil(publisherStats?.allProfitsCombined) || isNil(publisherStats?.tradableAssetsUsd)) {
          return '--';
        }

        return (publisherStats?.allProfitsCombined / publisherStats?.tradableAssetsUsd) * 100;
      };

      if(!publishers || ! marketplaceStats) {
        return [];
      }

      let clonedPublishers = clone(publishers);

      clonedPublishers.forEach((publisher) => {
        const stats = find(marketplaceStats, {
          publisherUserId: get(publisher, 'publisherUserId'),
          timeFrame: toNumber(statsTimePeriod),
        });

        const lifetimeStats = find(marketplaceStats, {
          publisherUserId: get(publisher, 'publisherUserId'),
          timeFrame: toNumber(STATS_TIME_FRAMES.find((stf) => stf.label === 'Lifetime').value),
        });

        publisher.stats = clone(stats);
        publisher.lifetimeStats = lifetimeStats;
        publisher.fullName = `${publisher.firstName} ${publisher.lastName}`;

        if(publisher.stats) {
          publisher.stats.strategyPerformance = calculateStrategyPerformance(publisher.stats);
          publisher.stats.followersPnl = publisher.stats.allProfitsCombined - publisher.stats.personalProfitsCombined;
        }
      });

      if(toNumber(statsTimePeriod) === 8 && sortBy === 'publisherRoiPercent') {
        clonedPublishers = orderBy(clonedPublishers, (publisher) => get(publisher.stats, 'strategyPerformance'), 'desc');
      } else {
        clonedPublishers = orderBy(clonedPublishers, (publisher) => get(publisher.stats, sortBy), 'desc');
      }

      return clonedPublishers;
    }, [marketplaceStats, publishers, sortBy, statsTimePeriod]
  );

  return (
    <div className="Content">
      <h2 className="h3 Copycat-headline">CopyCat trading</h2>
      <p className="Copycat-info">
        For optimal auto-trading performance, it is required that you have a minimum of {profile.homeQuoteAsset === 'usd' ? '$' : ''}1,000 {profile.homeQuoteAsset !== 'usd' ? profile.homeQuoteAsset.toUpperCase() : ''} in your account.  Additionally, the auto-trading platform will always use your Home Quote Currency (e.g. USD, USDC, or USDT) to place buys.  If you do not have a balance in that Home Quote Currency the auto-trader will not initiate any buys.  You can always modify the Home Quote Currency in your Account Settings for your account to start making trades.
      </p>
      {/* Commented out for CLV3-1424. Eric requested it stay commented out in case we want to bring it back. */}
      {/*<aside className="Copycat-featured">
        <h1 className="h4 Copycat-featured-title">Featured</h1>
        {isNull(publishers) ? (
          <Loading />
        ) : (
          orderBy(
            filter(
              cloneDeep(publishers).map((publisher) => {
                //TODO: Refactor orderBy, ideally without cloneDeep
                //Perhaps map() publishers and stats together prior?
                const stats = find(marketplaceStats, {
                  publisherUserId: get(publisher, 'publisherUserId'),
                  timeFrame: toNumber(statsTimePeriod),
                })
                const lifetimeStats = find(marketplaceStats, {
                  publisherUserId: get(publisher, 'publisherUserId'),
                  timeFrame: toNumber(STATS_TIME_FRAMES.find((stf) => stf.label === 'Lifetime').value),
                })
                set(
                  publisher,
                  'profitsClosed',
                  isUndefined(get(lifetimeStats, 'profitsClosed'))
                    ? Number.NEGATIVE_INFINITY
                    : get(lifetimeStats, 'profitsClosed')
                )
                set(
                  publisher,
                  'averageProfitCombined',
                  isUndefined(get(stats, 'averageProfitCombined'))
                    ? Number.NEGATIVE_INFINITY
                    : get(stats, 'averageProfitCombined')
                )
                set(
                  publisher,
                  'fullName',
                  get(publisher, 'firstName') + ' ' + get(publisher, 'lastName')
                )
                return publisher
              }),
              (o) => FEATURED_TRADERS.includes(o.publisherUserId)
            ),
            eq(sortBy, 'averageProfitClosed')
              ? ({ publisherUserId }) => {
                  const stats = find(marketplaceStats, {
                    publisherUserId,
                    timeFrame: toNumber(statsTimePeriod),
                  })
                  return get(stats, 'averageProfitClosed')
                }
              : sortBy,
            eq(sortBy, 'fullName') ? 'asc' : 'desc'
          ).map((publisher, index) => (
            <>
              <PublisherCard
                activeSubscription={find(subscriptions, {
                  publisherUserId: get(publisher, 'publisherUserId'),
                })}
                key={index}
                publisher={publisher}
                stats={find(marketplaceStats, {
                  publisherUserId: get(publisher, 'publisherUserId'),
                  timeFrame: toNumber(statsTimePeriod),
                })}
                timeFrame={toNumber(statsTimePeriod)}
              />
            </>
          ))
        )}
      </aside>*/}
      <div className="Copycat-filters">
        <fieldset className="Fieldset">
          <legend className="Legend">Display</legend>
          <label className="Label">
            Show stats for
            <Select
              ariaLabel="Select stat history duration"
              options={STATS_TIME_FRAMES}
              defaultValue={statsTimePeriod}
              onChange={handleStatsTimePeriodChange}
            />
          </label>
          <label className="Label">
            Sort by
            <Select
              ariaLabel="Select what to sort by"
              options={SORT_BY_OPTIONS}
              defaultValue={sortBy}
              onChange={handleSortByChange}
            />
          </label>
        </fieldset>
      </div>
      {!isNil(publishers) &&
      eq(publishersToDisplay.length, 0) ? (
        <section className="EmptyTable">
          <h1 className="EmptyTable-headline">
            No traders found within the selected filters
          </h1>
          <p className="EmptyTable-description">
            Use the filters above to select other options.
          </p>
        </section>
      ) : (
        <div className="Copycat-publishers">
          {isNil(publishers) ? (
            <Loading />
          ) : (
            <>
              {publishersToDisplay.map((publisher) => (
                <PublisherCard
                  activeSubscription={find(subscriptions, {
                    publisherUserId: get(publisher, 'publisherUserId'),
                  })}
                  key={publisher.publisherUserId}
                  publisher={publisher}
                  stats={publisher.stats}
                  timeFrame={toNumber(statsTimePeriod)}
                />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default MarketplaceCopycatTrading
