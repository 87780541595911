import React from 'react';
import {Switch, Route, Link, useParams, useLocation} from 'react-router-dom'
import StrategySignals from "../StrategySignals";
import StrategyDetails from "../StrategyDetails";
import { APP_PATHS } from "../../constants";
import { useMediaQuery } from 'react-responsive'
import Icon from "../shared/Icons";
import CreateStrategy from "../CreateStrategy";
import StrategyBacktest from "../StrategyBacktest";
import StrategyTradeHistory from "../StrategyTradeHistory";
import useStrategiesDuck from "../../hooks/useStrategiesDuck";

const Strategy = () => {
  const { pathname } = useLocation();
  const { strategyId } = useParams();
  const { clearBacktestData, clearStrategyFiltersData, setTemporaryStrategySettings } = useStrategiesDuck();
  const isBig = useMediaQuery({query: `(min-width: 800px)`})

  const EXISTING_STRATEGY_NAV_LINKS = [
    {
      mobileText: 'Config',
      text: 'Strategy Configuration',
      to: `/strategies/${strategyId}/details`,
    }, {
      mobileText: 'Backtest',
      text: 'Backtesting',
      to: `/strategies/${strategyId}/backtest`,
    }, {
      mobileText: 'Logs',
      text: 'Log Files',
      to: `/strategies/${strategyId}/signals`,
    }, {
      mobileText: 'History',
      text: 'Trade History',
      to: `/strategies/${strategyId}/history`,
    }
  ];

  const NEW_STRATEGY_NAV_LINKS = [
    {
      mobileText: 'Config',
      text: 'Strategy Configuration',
      to: `/strategies/new/details`,
    }, {
      mobileText: 'Backtest',
      text: 'Strategy Backtesting',
      to: `/strategies/new/backtest`,
    }
  ];

  const LINKS = strategyId ? EXISTING_STRATEGY_NAV_LINKS : NEW_STRATEGY_NAV_LINKS;

  return (
    <div className="Strategy">
      <header className="StrategyHeader">
        <div className="StrategyHeader-inner u-container">
          <nav className="StrategyNav">
            {LINKS.map((link) => (
              <Link
                data-testid={`nav_${link.text}`}
                className={`StrategyNav-link ${link.to === pathname ? 'StrategyNav-link--active' : ''}`}
                to={link.to}
              >
                {isBig ? link.text : link.mobileText}
              </Link>
            ))}
          </nav>

          <Link
            className="u-buttonReset Strategy-closeX"
            onClick={() => {
              setTemporaryStrategySettings({});
              clearBacktestData();
              clearStrategyFiltersData();
            }}
            to={APP_PATHS.BASE}
          >
            <Icon name="x" fill="#500078" />
          </Link>
        </div>
      </header>

      <Switch>
        <Route path={APP_PATHS.STRATEGIES_NEW} component={CreateStrategy} />
        <Route path={APP_PATHS.STRATEGIES_NEW_BACKTEST} component={StrategyBacktest} />
        <Route path={APP_PATHS.STRATEGY_DETAILS} component={StrategyDetails} />
        <Route path={APP_PATHS.STRATEGY_BACKTEST} component={StrategyBacktest} />
        <Route path={APP_PATHS.STRATEGY_SIGNALS} component={StrategySignals} />
        <Route path={APP_PATHS.STRATEGY_TRADE_HISTORY} component={StrategyTradeHistory} />
      </Switch>
    </div>
  )
};

export default Strategy;
