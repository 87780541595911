import './rewardsHodl.scss';

import * as React from 'react';
import { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";

import { APP_PATHS } from "../../../../constants";
import { isArrayNullOrEmpty } from '../../../../utils/utils';

import {
  getHodlAnnualAllocations,
  getHodlGlobalTotals,
  getHodlPrograms,
  getHodlUserTotals
} from "../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../ducks";

import Loading from "../../../shared/Loading";
import RewardsHodlActivity from "./HodlActivity";
import RewardsHodlInfo from "./HodlInfo";
import RewardsHodlOverview from "./HodlOverview";

const RewardsHodl: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { enrollments } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsLoading(isArrayNullOrEmpty(enrollments));
        await Promise.all([
          dispatch(getHodlAnnualAllocations()).unwrap(),
          dispatch(getHodlGlobalTotals()).unwrap(),
          dispatch(getHodlUserTotals()).unwrap(),
          dispatch(getHodlPrograms()).unwrap(),
        ]);
      } catch(err) {
        console.log('RewardsHodl initialize error', err);
      } finally {
        setIsLoading(false);
      }
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Content Content--hodl">
      <h2 className="rewards-title">
        HODL & Be Rewarded
      </h2>

      <nav className="rewards-tabs">
        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_HODL_OVERVIEW ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_HODL_OVERVIEW}
        >
          Overview
        </Link>

        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_HODL_ACTIVITY ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_HODL_ACTIVITY}
        >
          Activity
        </Link>

        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_HODL_INFO ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_HODL_INFO}
        >
          HODL Info
        </Link>
      </nav>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="rewards-content">
          <Switch>
            <Route
              path={APP_PATHS.REWARDS_HODL_OVERVIEW}
              component={RewardsHodlOverview}
            />

            <Route
              path={APP_PATHS.REWARDS_HODL_ACTIVITY}
              component={RewardsHodlActivity}
            />

            <Route
              path={APP_PATHS.REWARDS_HODL_INFO}
              component={RewardsHodlInfo}
            />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default RewardsHodl;
