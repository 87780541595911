import { chain, isNil, isUndefined, map } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, get, useForm } from 'react-hook-form'
import { formatAsDollarAmount } from '../../utils/utils'
import Input from '../shared/Input'
import InputError from '@pv3-shared-components/InputError'
import Icon from '@pv3-shared-components/Icons'
import Select from '@pv3-shared-components/Select'
import useAchDuck from '../../hooks/useAchDuck'
import Currency from '../shared/Currency'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import Button from '@pv3-shared-components/Button'
import { find, eq } from 'lodash'
import { useSelector } from 'react-redux'
import { isObjectNullOrEmpty } from "../../utils/utils";
import Tooltip from "../shared/Tooltip";

const AchWithdrawalForm = ({ withdrawal, submitAction }) => {
  const formMethods = useForm({
    defaultValues: isNil(withdrawal) ? undefined : withdrawal,
  })
  const { handleSubmit, watch, formState, register, setFocus, setValue } =
    formMethods
  const { errors } = formState
  const { items: achItems } = useAchDuck()
  const { balances, withdrawalLimits } = useWalletsDuck()
  const assets = useSelector((state) => state.assets.assets)

  const accounts = useMemo(
    () =>
      chain(achItems)
        .filter(({ relinkRequired }) => !relinkRequired)
        .flatMap(({ accounts }) => accounts)
        .value(),
    [achItems]
  )

  const [showFormattedAmount, setShowFormattedAmount] = useState(false)
  const amount = watch('amount')

  const maxWithdrawalAmountAllowed = useMemo(
    () => {
      let limit = 0;
      let type = 'balance';
      if(!isNil(get(balances, 'usd'))) {
        limit = get(balances, 'usd');
      }

      if(get(withdrawalLimits, 'withdrawalLimit') < limit) {
        limit = get(withdrawalLimits, 'withdrawalLimit');
        type = 'limit';
      }

      if(get(withdrawalLimits, 'currentWithdrawalAllowance') < limit) {
        limit = get(withdrawalLimits, 'currentWithdrawalAllowance');
        type = 'limit';
      }

      return { limit, type };
    }, [balances, withdrawalLimits]
  )

  const handleFormSubmit = (data) => {
    const withdrawalAccount = find(accounts, ({ accountId }) =>
      eq(accountId, data.accountId)
    )
    submitAction(data, withdrawalAccount)
  }

  const minimumWithdrawal = useMemo(() => {
    if (eq(assets.length, 0)) return
    const usdInfo = find(assets, ({ id }) => eq(id, 'usd'))
    if (isUndefined(usdInfo)) return
    return usdInfo.withdrawal_fee
  }, [assets])

  useEffect(() => {
    setValue('amount', null)
    setFocus('amount')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="CryptoWithdrawal-dataRow">
            <p className="label">Daily Withdraw Limit</p>
            <p className="value valueWithTooltip">
              {isObjectNullOrEmpty(withdrawalLimits) ? '--' : formatAsDollarAmount(withdrawalLimits.currentWithdrawalAllowance)}&nbsp;
              <Tooltip>The Daily Withdraw Limit is calculated by taking the greater of  1) $10,000  2) Your largest deposit ever 3) The largest month in which you made a deposit divided by 2.  (Example:  If you deposited $1,000 a day for 20 days in a single month then your Daily Withdraw limit would be $10,000).  If you have already withdrawn funds today then this number will decrease as you withdraw funds.  Tomorrow it will reset.</Tooltip>
            </p>
          </div>

          <p className="AchDepositForm-amountLabel">Amount</p>
          <div className="AchDepositForm-amountInputContainer">
            {showFormattedAmount && (
              <div className="AchDepositForm-formattedAmountOverlay">
                <p>{formatAsDollarAmount(amount, true)}</p>
              </div>
            )}
            <Input
              type="number"
              className="AchDepositForm-bigNumberInput"
              inputMode="decimal"
              name="amount"
              registerConfig={{
                required: 'Amount is required',
                min: {
                  value: minimumWithdrawal,
                  message: `Withdrawal must be greater than ${minimumWithdrawal}`,
                },
                max: {
                  value: maxWithdrawalAmountAllowed.limit,
                  message: maxWithdrawalAmountAllowed.type === 'balance' ? `Withdrawal must be no greater than the amount in USD wallet, ${formatAsDollarAmount(balances.usd)}` : `Your remaining daily withdrawal limit is ${maxWithdrawalAmountAllowed.limit}.`,
                },
              }}
              onBlur={() => setShowFormattedAmount(true)}
              onFocus={() => setShowFormattedAmount(false)}
              error={
                !isUndefined(get(formState, 'errors.amount')) && (
                  <InputError message={formState.errors.amount.message} />
                )
              }
            />
          </div>
          <p className="AchDepositForm-currencyLabel">USD</p>
          <p className="AchDepositForm-partyBoxLabel">To</p>
          <div
            className={`AchDepositForm-partyBox ${
              !isUndefined(errors.accountId)
                ? 'AchDepositForm-partyBoxError'
                : ''
            }`}
          >
            <div>
              <Icon name="bank" fill="#500078" height="30" width="30" />
            </div>
            <Select
              register={{
                ...register('accountId', {
                  validate: (value) => value !== '' || 'Account is required',
                }),
              }}
              className="AchDepositForm-accountSelect"
              containerClassName="AchDepositForm-accountSelectContainer"
            >
              <option value={''}>Choose account…</option>
              {map(accounts, ({ accountId, name, mask }) => (
                <option value={accountId} key={accountId}>
                  {name} x{mask}
                </option>
              ))}
            </Select>
          </div>
          <p className="AchDepositForm-partyBoxLabel">From</p>
          <div className="AchDepositForm-partyBox u-unchanging">
            <div className="AchDepositForm-partyBoxIcon">
              <Currency type="usd" width="30" height="30" />{' '}
            </div>
            <div className="AchDepositForm-partyBoxWalletDescription">
              <p className="wallet">USD Wallet</p>
              <p className="walletAmount">
                {formatAsDollarAmount(balances.usd, true)} in wallet
              </p>
            </div>
          </div>
          <Button
            variant="primary"
            size="large"
            className="AchDepositForm-submitButton"
          >
            Preview
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default AchWithdrawalForm
