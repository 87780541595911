import { useAuth0 } from '@auth0/auth0-react'
import { isNull } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../ducks'
import { signInCoin as _signInCoin } from './../ducks/account'
import { signOutCoin } from '../ducks/session'

const TEST_TIMEOUT = 5 * 60 * 1000; // 5 minutes

const useAccount = () => {
  const dispatch = useDispatch()
  const { logout, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const { idToken, loadTesting, loggedInUserId } = useSelector((state) => state.account);

  const hasCoinSession = !isNull(loggedInUserId)

  const signInCoin = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ignoreCache: true})
      const { __raw: idToken, exp: expiresAt } = await getIdTokenClaims()
      dispatch(_signInCoin({ accessToken, idToken, expiresAt }))
      setTimeout(testAuth0Access, TEST_TIMEOUT);
    } catch (e) {
      console.log(e)
    }
  }

  const signOut = async () => {
    await dispatch(signOutCoin())
    logout()
    dispatch(reset())
  }

  const testAuth0Access = async () => {
    await getAccessTokenSilently({ignoreCache: true});
    setTimeout(testAuth0Access, TEST_TIMEOUT);
  };

  return { hasCoinSession, idToken, loadTesting, loggedInUserId, signInCoin, signOut }
}

export default useAccount
