import { useDispatch, useSelector } from 'react-redux'
import {get, reduce, split, eq, includes, filter, isNil} from 'lodash'
import {
  closeAllPositions as _closeAllPositions,
  removeIncompleteTrade as _removeIncompleteTrade,
  getIncompleteTrades as _getIncompleteTrades,
  setClosedPositionsChartTimeframe as tradesSetClosedPositionsChartTimeframe,
  getLionSellLimit as _getLionSellLimit,
  getTradeHistoryFromFilter as _getTradeHistoryFromFilter,
  getOpenPositions as _getOpenPositions,
  liquidateAssets as _liquidateAssets,
  deleteOpenPosition as _deleteOpenPosition,
  deleteOrdersForMarketAndSide as _deleteOrdersForMarketAndSide,
  sellAllPositions as _sellAllPositions,
  sellPosition as _sellPosition,
  deleteIncompleteTrade as _deleteIncompleteTrade,
} from '@pv3-ducks/trades'
import { formatAsDollarAmount } from '../utils/utils'
import { unwrapResult } from '@reduxjs/toolkit'
import { subscribeToOpenPositionUpdates } from '../websockets/wsTradingTool'
import { setOpenPositions } from '../ducks/trades'

const useTradesDuck = () => {
  const dispatch = useDispatch()

  const { closedPositionsChart, lionSellLimit, openPositions } = useSelector(
    (state) => state.trades
  )

  const {
    hourlyData: closedPositionsChartData,
    timeframe: closedPositionsChartTimeframe,
    totalGainLoss,
    closedPositionCount,
  } = closedPositionsChart

  const setClosedPositionsChartTimeframe = (timeframe) => {
    dispatch(tradesSetClosedPositionsChartTimeframe(timeframe))
  }

  const getTradeHistoryFromFilter = ({ filters }) => {
    dispatch(_getTradeHistoryFromFilter({ filters }))
  }

  const historyFromFilter = useSelector(
    (state) => state.trades.historyFromFilter
  )

  const getOpenPositions = async (hideSpinner) => {
    const result = await dispatch(_getOpenPositions(hideSpinner))
    unwrapResult(result)
    if(!hideSpinner) {
      subscribeToOpenPositionUpdates((updates) => {
        dispatch(setOpenPositions(updates))
      })
    }
  }

  const deleteOpenPosition = async (id) => {
    try {
      const result = await dispatch(_deleteOpenPosition(id))
      unwrapResult(result)
      return
    } catch (e) {
      throw e
    }
  }

  const sellPosition = (id) => dispatch(_sellPosition(id))

  const tradeHistoryFromFilter = useSelector((state) =>
    get(state, 'trades.historyFromFilter')
  )

  /*
   * Determine if there is one unique quote currency, and if so
   * return a value in that currency with that currency label.
   * Eric requested it be rounded to two decimal places no matter what
   */

  const determineQuoteCurrency = (pair) => {
    if(!isNil(pair)) {
      return pair.split('_')[1];
    }

    return null;
  }

  const calculateProfitLoss = (tradeHistory, pair) => {
    if(!tradeHistory) {
      tradeHistory = historyFromFilter;
    }

    let pairQuoteCurrency = determineQuoteCurrency(pair);

    const total = reduce(
      tradeHistory,
      (total, trade) => {
        const quoteCurrency = split(get(trade, 'instrument'), '_')[1]

        if (eq(trade.side, 1) &&
          (
            (isNil(pairQuoteCurrency) && includes(['usd', 'usdc', 'usdt'], quoteCurrency)) ||
            pairQuoteCurrency === quoteCurrency
          )
        ) {
          total += trade.gainLoss
        }

        return total
      },
      0
    )

    if(isNil(pairQuoteCurrency) || pairQuoteCurrency === 'usd') {
      return formatAsDollarAmount(total);
    } else {
      return `${total.toFixed(2)} ${pair.toUpperCase()}`;
    }
  }

  const calculateTotalBuy = (tradeHistory, pair) => {
    if(!tradeHistory) {
      tradeHistory = historyFromFilter;
    }

    let pairQuoteCurrency = determineQuoteCurrency(pair);

    const total = reduce(
      tradeHistory,
      (total, trade) => {
        const quoteCurrency = split(get(trade, 'instrument'), '_')[1]

        if (eq(trade.side, 0) &&
          (
            (isNil(pairQuoteCurrency) && includes(['usd', 'usdc', 'usdt'], quoteCurrency)) ||
            pairQuoteCurrency === quoteCurrency
          )
        ) {
          total += trade.quoteAmount
        }

        return total
      },
      0
    )

    if(isNil(pairQuoteCurrency) || pairQuoteCurrency === 'usd') {
      return formatAsDollarAmount(total);
    } else {
      return `${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${pairQuoteCurrency.toUpperCase()}`;
    }
  }

  const calculateTotalBuys = (tradeHistory) => {
    if(!tradeHistory) {
      tradeHistory = historyFromFilter;
    }

    return filter(tradeHistory, { side: 0 }).length
  }

  const calculateTotalSell = (tradeHistory, pair) => {
    if(!tradeHistory) {
      tradeHistory = historyFromFilter;
    }

    let pairQuoteCurrency = determineQuoteCurrency(pair);

    const total = reduce(
      tradeHistory,
      (total, trade) => {
        const quoteCurrency = split(get(trade, 'instrument'), '_')[1]

        if (eq(trade.side, 1) &&
          (
            (isNil(pairQuoteCurrency) && includes(['usd', 'usdc', 'usdt'], quoteCurrency)) ||
            pairQuoteCurrency === quoteCurrency
          )
        ) {
          total += trade.quoteAmount;
        }

        return total
      },
      0
    )

    if(isNil(pairQuoteCurrency) || pairQuoteCurrency === 'usd') {
      return formatAsDollarAmount(total);
    } else {
      return `${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${pairQuoteCurrency.toUpperCase()}`;
    }
  }

  const calculateTotalSells = (tradeHistory) => {
    if(!tradeHistory) {
      tradeHistory = historyFromFilter;
    }

    return filter(tradeHistory, { side: 1 }).length
  }

  const getLionSellLimit = () =>
    dispatch(_getLionSellLimit())

  const getIncompleteTrades = ({ status }) =>
    dispatch(_getIncompleteTrades({ status }))

  const incompleteTrades = useSelector((state) =>
    get(state, 'trades.incompleteTrades')
  )

  const closeAllPositions = () =>
    dispatch(_closeAllPositions())

  const liquidateAssets = () =>
    dispatch(_liquidateAssets())

  const sellAllPositions = () =>
    dispatch(_sellAllPositions())

  const removeIncompleteTrade = (tradeId) =>
    dispatch(_removeIncompleteTrade(tradeId))

  const deleteIncompleteTrade = (tradeId) =>
    dispatch(_deleteIncompleteTrade(tradeId))

  const deleteOrdersForMarketAndSide = (data) =>
    dispatch(_deleteOrdersForMarketAndSide(data))

  return {
    calculateProfitLoss,
    calculateTotalBuy,
    calculateTotalBuys,
    calculateTotalSell,
    calculateTotalSells,
    getIncompleteTrades,
    incompleteTrades,
    removeIncompleteTrade,
    deleteIncompleteTrade,
    deleteOrdersForMarketAndSide,
    closedPositionsChartData,
    closedPositionsChartTimeframe,
    totalGainLoss,
    closedPositionCount,
    setClosedPositionsChartTimeframe,
    getLionSellLimit,
    getTradeHistoryFromFilter,
    historyFromFilter,
    tradeHistoryFromFilter,
    getOpenPositions,
    openPositions,
    deleteOpenPosition,
    sellPosition,
    lionSellLimit,
    closeAllPositions,
    sellAllPositions,
    liquidateAssets,
  }
}

export default useTradesDuck;
