import React, {useEffect, useState} from 'react'
import Icon from '@pv3-shared-components/Icons'
import Button from '@pv3-shared-components/Button'
import './chooseaccounttype.scss'
import { PROFILE_TYPES } from '@pv3-constants'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import { useHistory } from 'react-router-dom'
import { APP_PATHS } from '../../constants'
import { unwrapResult } from '@reduxjs/toolkit'
import useAccount from '@pv3-hooks/useAccount'
import useMayVisitDashboard from '@pv3-hooks/useMayVisitDashboard'
import { Link } from 'react-router-dom'
import useDocTitle from '@pv3-hooks/useDocTitle'
import { isNil } from 'lodash';
import CountryAndRegionSelect from "../shared/CountryAndRegionSelect";
import { FormProvider, useForm } from "react-hook-form";

const ChooseAccountType = () => {
  useDocTitle('Account Type')

  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [selectedProfileType, setSelectedProfileType] = useState(null);
  const [disableContinue, setDisableContinue] = useState(false);

  const { setProfileType, profile: { country: existingCountry, createdAt } } = useProfileDuck()
  const history = useHistory()
  const { signOut } = useAccount()
  const mayVisitDashboard = useMayVisitDashboard()

  const sumsubStartDate = new Date(2022, 6, 27);

  const {
    handleSubmit,
    ...formMethods
  } = useForm()

  const handleAccountTypeClick = async (profileType) => {
    try {
      if(showCountrySelect) {
        setSelectedProfileType(profileType);
      } else {
        const result = await setProfileType({profileType});
        unwrapResult(result)

        history.push(APP_PATHS.ONBOARDING_TERMS)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleLogoutButtonClick = () => {
    signOut()
  }

  const handleFormSubmit = async (values) => {
    const { country, state } = values;

    if(isNil(selectedProfileType) || isNil(country) || isNil(state)) {
      return;
    }

    try {
      const result = await setProfileType({
        profileType: selectedProfileType,
        country,
        state,
      });
      unwrapResult(result)

      history.push(APP_PATHS.ONBOARDING_TERMS)
    } catch(err) {
      console.log('AccountType handleFormSubmit err', err);
    }
  };

  useEffect(() => {
    if(isNil(existingCountry)) {
      setShowCountrySelect(true);
    }
  }, [existingCountry]);

  return (
    <section className="SinglePanel-panel SinglePanel-panel--acctType">
      <h1 className="SinglePanel-logo">
        <Icon name="coinlionlogo" />
      </h1>
      {sumsubStartDate > new Date(createdAt) ? (
        <React.Fragment>
          <h2 className="SinglePanel-title">Welcome Back!</h2>
          <p className="SinglePanel-content">
            CoinLion is working hard making sure your information is secure. In order to support that effort, we are having all our users re-verify their identity with our new identity verification partner. It only takes two minutes, and if you have any trouble going through the process, you can always contact support. We're here to help
          </p>
          <h4 className="SinglePanel-subTitle">Choose account type</h4>
        </React.Fragment>
      ) : (
        <h2 className="SinglePanel-title">Choose account type</h2>
      )}
      <Button
        className={selectedProfileType === PROFILE_TYPES.INDIVIDUAL ? 'ProfileType--selected' : ''}
        onClick={() => handleAccountTypeClick(PROFILE_TYPES.INDIVIDUAL)}
        variant="secondary"
        size="large"
      >
        <Icon className="SinglePanel-panel-typeIcon" name="person" />
        Individual
        <Icon className="SinglePanel-panel-caret" name="caretright" />
      </Button>
      <Button
        className={selectedProfileType === PROFILE_TYPES.BUSINESS ? 'ProfileType--selected' : ''}
        onClick={() => handleAccountTypeClick(PROFILE_TYPES.BUSINESS)}
        variant="secondary"
        size="large"
      >
        <Icon className="SinglePanel-panel-typeIcon" name="buildings" />
        Business
        <Icon className="SinglePanel-panel-caret" name="caretright" />
      </Button>

      <div className="SinglePanel-panel--acctType--business-info">
        Business Account requires 3 of the following documents:
        <ul>
          <li>Certificate of incorporation</li>
          <li>Articles of organization/incorporation</li>
          <li>Shareholder registry</li>
          <li>Corporate authorization to open this account</li>
        </ul>
      </div>

      {showCountrySelect && (
        <FormProvider {...formMethods}>
          <form
            className="CountryAndRegionSelect-form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <CountryAndRegionSelect setDisableContinue={setDisableContinue} />

            <Button
              disabled={isNil(selectedProfileType) || disableContinue}
              type="submit"
              variant="primary"
              size="large"
            >
              Continue
            </Button>
          </form>
        </FormProvider>
      )}

      {mayVisitDashboard ? (
        <Link
          to={APP_PATHS.BASE}
          className="SinglePanel-panel-logout Button Button--quaternary Button--large"
        >
          Return to dashboard
        </Link>
      ) : (
        <Button
          variant="quaternary"
          size="large"
          type="button"
          onClick={handleLogoutButtonClick}
          className="SinglePanel-panel-logout"
        >
          Log out and onboard later
        </Button>
      )}
    </section>
  )
}

export default ChooseAccountType
