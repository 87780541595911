import React from 'react'
import Loading from '@pv3-shared-components/Loading'
import './splash.scss'

const Splash = () => {
  return (
    <div className="Splash">
      <div className="Splash-content">
        <img src="/img/coinlion-logo.svg" alt="CoinLion Logo" />
        <Loading />
        Securely loading your portfolio…
      </div>
    </div>
  )
}

export default Splash
