import './hodlDailyAllocation.scss';

import * as React from 'react';
import { useMemo } from "react";
import * as moment from 'moment';

import { isArrayNullOrEmpty } from '../../../../../../../utils/utils';

import useWindowWidth from '../../../../../../../hooks/useWindowWidth';
import { useTypedSelector } from "../../../../../../../ducks";

import HodlGeometricChart from "../HodlGeometricChart";
import Tooltip from '../../../../../../shared/Tooltip';
import HodlUnlockRewards from "../../../HodlOverview/components/HodlUnlockRewards";

const HodlDailyAllocation: React.FC = () => {
  const isBig = useWindowWidth();

  const { annualAllocationsForHodl } = useTypedSelector((state) => state.rewards);

  const thisYearsAllocation = useMemo(
    () => {
      if(isArrayNullOrEmpty(annualAllocationsForHodl)) {
        return undefined;
      }

      const year = new Date().getFullYear();
      return annualAllocationsForHodl.find((allocation) => moment(allocation.endDate).subtract(1, 'day').year() === year);
    }, [annualAllocationsForHodl]
  );

  const calculateLeftPositionOfNow = () => {
    const totalNumberOfDays = moment('2043-12-31').diff(moment('2024-01-01'), 'days');
    const daysIn = moment().diff(moment('2024-01-01'), 'days');
    return `calc(${(daysIn / totalNumberOfDays) * 100}% + 40px)`;
  };

  const findAllocation = (type : 'annual' | 'daily') => {
    if(!thisYearsAllocation || !thisYearsAllocation.value1Numeric) {
      return;
    }

    if(type === 'annual') {
      return `${thisYearsAllocation.value1Numeric.toLocaleString()} LION`;
    }

    if(type === 'daily') {
      return `${(thisYearsAllocation.value1Numeric / 365).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} LION`;
    }
  }

  if(!thisYearsAllocation) {
    return null;
  }

  return (
    <div className="HodlInfo-Allocation">
      <h4>
        Daily Allocation of LION: {(thisYearsAllocation.value1Numeric / 365).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3})}
      </h4>

      <div className="Allocation-Amounts">
        <div className="Allocation-Amount">
          <div className="Allocation-Amount-Value">
            {((thisYearsAllocation.value1Numeric / 365) * .6).toLocaleString(undefined, {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })} LION
          </div>

          <div className="Allocation-Amount-Label">
            <div className="Allocation-Amount-LabelText">
              LION Daily Allocation on the 18M
            </div>

            <Tooltip>
              Tooltip coming soon
            </Tooltip>
          </div>
        </div>

        <div className="Allocation-Amount">
          <div className="Allocation-Amount-Value">
            {((thisYearsAllocation.value1Numeric / 365) * .3).toLocaleString(undefined, {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })} LION
          </div>

          <div className="Allocation-Amount-Label">
            <div className="Allocation-Amount-LabelText">
              LION Daily Allocation on the 12M
            </div>

            <Tooltip>
              Tooltip coming soon
            </Tooltip>
          </div>
        </div>

        <div className="Allocation-Amount">
          <div className="Allocation-Amount-Value">
            {((thisYearsAllocation.value1Numeric / 365) * .1).toLocaleString(undefined, {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })} LION
          </div>

          <div className="Allocation-Amount-Label">
            <div className="Allocation-Amount-LabelText">
              LION Daily Allocation on the 6M
            </div>

            <Tooltip>
              Tooltip coming soon
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="Allocation-Content">
        <p>
          The intent of the HODL program is to reward users who are the biggest advocates of the CoinLion Token (LION).
          Specifically, those users who wish to hold onto their LION long term and have a higher percent of their LION
          in the HODL program.
        </p>
        <p>
          As an example; a user who puts 90% of their LION into the HODL program will be rewarded at a higher rate than
          a user who HODLs 10% of their LION. Additionally, a user who is constantly selling their LION won’t have the
          same level of rewards as the user who is not selling.  See the grid below to see how this all maps out.
        </p>
        <p>
          Every day there is a daily allocation of LION that gets split between the 6 month, 12 month, and 18 month HODL Programs.  The 18month program is awarded the most LION (60%), the 12 month is allocated 30%, and the 6 month is allocated 10%.  Each user in that program is then allocated a percent of that allocation based upon the weighted described above and also in the grid below.
        </p>
        <p>
          There is no guaranteed rate of return.
        </p>
        <p>
          You can cancel your HODL program at any time and your “principal” amount of LION is never at risk of being penalized. Example: If you HODL 20,000 LION, you can get that 20,000 back whenever you want. But, you will forfeit 100% of the accrued rewards when removing LION from the HODL before the period has completed.
        </p>
        <p>
          If you enroll in the 6 or 12-month HODL program, you can always extend and “upgrade” to a longer HODL period without changing your start date. Your accrued rewards will not change when you upgrade to a longer period.
        </p>
        <p>
          Example: You'll keep any rewards you accrued during a 6-month program, but if you upgrade to the 12 or 18-month program, you will instantly be eligible for the higher quantity of LION rewards that comes with the longer HODL period.
        </p>
        <p>
          At the end of the year, all users who participated in the HODL program will be eligible to vote on key drivers within the CoinLion platform that directly impact CoinLion users. Your voting ability is based up on how much LION was rewarded to you through the HODL program. The more rewards from the HODL program means you have a heavier voting weight.
        </p>
      </div>

      <div className="HodlHistory-CommittedChart">
        <div className="HodlHistory-UnlockContainer-Table">
          <div className="HodlHistory-UnlockContainer-TableMobileLabel">
            OF PORTFOLIO COMMITTED
          </div>

          <div className="HodlHistory-UnlockContainer-TableHeader">
            <div className="HodlHistory-UnlockContainer-TableHeaderPercent">
              % {isBig && 'OF PORTFOLIO COMMITTED'}
            </div>

            <div className="HodlHistory-UnlockContainer-TableHeaderRight">
              <div className="HodlHistory-UnlockContainer-TableHeaderDescription">
                Trailing 12M Sell Volume of LION
              </div>

              <div className="HodlHistory-UnlockContainer-TableHeaderColumns">
                <div>
                  &lt;10%
                </div>

                <div>
                  &lt;20%
                </div>

                <div>
                  &lt;30%
                </div>

                <div>
                  &gt;30%
                </div>
              </div>
            </div>
          </div>

          <div className="HodlHistory-UnlockContainer-TableRow">
            <div className="HodlHistory-UnlockContainer-TableColumn">
              90+%
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              4x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              3x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              2x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.75x
            </div>
          </div>


          <div className="HodlHistory-UnlockContainer-TableRow">
            <div className="HodlHistory-UnlockContainer-TableColumn">
              70+%
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              3x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              2x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              1x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.5x
            </div>
          </div>


          <div className="HodlHistory-UnlockContainer-TableRow">
            <div className="HodlHistory-UnlockContainer-TableColumn">
              50+%
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              2x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              1x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.5x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.25x
            </div>
          </div>

          <div className="HodlHistory-UnlockContainer-TableRow">
            <div className="HodlHistory-UnlockContainer-TableColumn">
              30+%
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              1x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.5x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0.25x
            </div>

            <div className="HodlHistory-UnlockContainer-TableColumn">
              0x
            </div>
          </div>
        </div>
      </div>

      <HodlUnlockRewards />

      <div className="Allocation-ChartRow">
        <div className="Allocation-Chart">
          <div className="Allocation-Chart-Bar Allocation-Chart-18M">
            {isBig ? '18 month = 60% of allocation' : '18M'}
          </div>

          <div className="Allocation-Chart-Bar Allocation-Chart-12M">
            {isBig ? '12 month = 30% of allocation' : '12M'}
          </div>

          <div className="Allocation-Chart-Bar Allocation-Chart-6M">
            {isBig ? '6 month = 10% of allocation' : '6M'}
          </div>
        </div>
      </div>

      <h4>
        Total Allocation of LION tokens
      </h4>

      <div className="Allocation-Content">
        <p>
          There is an annual allocation of LION each and every year for 20 years.  This begins in 2024 with 1,000,000 LION being allocated for the entire year just for the HODL programs.  Every two years this allocation will decrease by 20%.  This means that in 2025 there will be another 1,000,000 LION allocated and then in 2026 and 2027 it will decrease to 800,000 each year.
        </p>
      </div>

      {!isArrayNullOrEmpty(annualAllocationsForHodl) && (
        <div className="Allocation-Charts">
          <div className="Allocation-AnnualChart">
            <HodlGeometricChart
              valueString={findAllocation('annual')}
              valueTitle="Annual Allocation"
              yAxisValues={[134218, 1000000]}
            />
          </div>

          <div className="Allocation-DailyChart">
            <HodlGeometricChart
              valueString={findAllocation('daily')}
              valueTitle="Daily Allocation"
              xAxisValues={[2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043]}
              yAxisValues={[367.72, 2739.73]}
            />
          </div>

          <div className="Allocation-Now" style={{left: calculateLeftPositionOfNow()}}>
            <div className="Allocation-NowText">Now</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HodlDailyAllocation;
