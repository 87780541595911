import React, { useMemo, useState } from 'react'
import Table from '@pv3-shared-components/Table'
import useTradesDuck from '../../hooks/useTradesDuck'
import {
  eq,
  find,
  forEach,
  get as _get,
  gt, isNil,
  isNull,
  lt,
  replace,
  round,
  toUpper,
  trim,
  upperCase,
} from 'lodash'
import {formatAsDollarAmount, formatNumber, renderTableAge} from '../../utils/utils'
import useWalletsDuck from '@pv3-hooks/useWalletsDuck'
import useMarketsDuck from '@pv3-hooks/useMarketsDuck'
import Icon from '@pv3-shared-components/Icons'
import { differenceInMinutes } from 'date-fns'
import Button from '@pv3-shared-components/Button'
import './openpositionstable.scss'
import Currency from '../shared/Currency'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import HoldOpenPositionModal from '@pv3-components/HoldOpenPositionModal'
import SellOpenPositionModal from '@pv3-components/SellOpenPositionModal'
import TradeDetail from '@pv3-components/TradeDetail'
import { LOADING_STATES } from '../../constants'
import { get } from 'react-hook-form'
import useModal from '../../hooks/useModal'
import { useSelector } from 'react-redux'
import Logo from "../shared/Logo";

const OpenPositionsTable = ({positionsToDisplay}) => {
  const [tradeDetailOpen, setTradeDetailOpen] = useState(false)
  const [tradeDetails, setTradeDetails] = useState({})
  const [holdPosition, setHoldPosition] = useState({})
  const [sellPosition, setSellPosition] = useState({})
  const { openPositions, deleteIncompleteTrade } = useTradesDuck()
  const { getMarketFee, getTakerFee } = useMarketsDuck()
  const { usdPrices } = useWalletsDuck()
  const openOrders = useSelector((state) => state.trades.openOrders)
  const isBig = useWindowWidth()
  const holdPositionModal = useModal()
  const sellPositionModal = useModal()

  const handleRowClick = (_, rowInfo, column) => {
    return {
      onClick: (e) => {
        if (!eq(column.className, 'OpenPositionsTable--actionCell')) {
          setTradeDetails(rowInfo.original)
          setTradeDetailOpen(true)
        }
      },
    }
  }

  const cancelOpenOrdersByPosition = (positionId) => {
    forEach(openOrders, (order) => {
      if (!eq(order.status, 'Cancelled') && eq(order.positionId, positionId))
        deleteIncompleteTrade(order.orderId)
    })
  }

  const columns = useMemo(
    () =>
      isBig
        ? [
            // Desktop columns
            {
              Header: 'Pair',
              id: 'positiondetails',
              headerClassName: 'OpenPositionsTable--dataCellCenteredHeader',
              className: 'OpenPositionsTable--dataCellCentered',
              width: 76,
              accessor: 'market',
              Cell: (props) => {
                if (eq(_get(props, 'original.assetId'), 'lion')) {
                  return (
                    <img
                      src="/img/lioncoin.svg"
                      alt="CoinLion Token"
                      width="30"
                      height="30"
                    />
                  )
                } else {
                  return (
                    <React.Fragment>
                      <div className="OpenPositionsTable--iconContainer">
                        <Currency type={_get(props, 'original.assetId')} />
                        <span className="OpenPositionsTable--iconText">
                          {_get(props, 'original.market')}
                        </span>
                      </div>
                      {get(props, 'original.position') &&
                        !eq(
                          get(props, 'original.position.strategyBasic.userId'),
                          get(
                            props,
                            'original.position.strategyBasic.publisherUserId'
                          )
                        ) && <em className="BadgeCopyCat">Copycat</em>}
                    </React.Fragment>
                  )
                }
              },
            },
            {
              Header: 'Quantity',
              id: 'quantity',
              headerClassName: 'u-tr',
              className: 'OpenPositionsTable--dataCell',
              accessor: (d) =>
                `${formatNumber(round(d.openAmount, 6))} ${upperCase(
                  d.assetId
                )}`,
            },
            {
              Header: 'Price',
              id: 'price',
              headerClassName: 'u-tableCellMdUp u-tr',
              className: 'OpenPositionsTable--dataCell u-tableCellMdUp',
              accessor: (d) => d.openPrice,
              Cell: (props) => (
                <React.Fragment>
                  {formatAsDollarAmount(props.value)}
                </React.Fragment>
              ),
            },
            {
              Header: 'Cost',
              id: 'cost',
              headerClassName: 'u-tr',
              className: 'OpenPositionsTable--dataCell',
              accessor: (d) => d.openAmount * d.openPrice,
              Cell: (props) => (
                <React.Fragment>
                  {formatAsDollarAmount(props.value)}
                </React.Fragment>
              ),
            },
            {
              Header: 'Gain/loss',
              id: 'gainLossRate',
              minWidth: 114,
              headerClassName: 'u-tr',
              className: 'OpenPositionsTable--dataCell',
              accessor: 'gainLossRate',
              Cell: (props) =>
                isNil(props.original.gainLossRate) ? (
                  <React.Fragment>
                    --
                  </React.Fragment>
                ) : (
                  <span>
                    {!eq(_get(props, 'original.gainLossRate'), 0) && (
                      <Icon
                        aria-hidden="true"
                        name={
                          gt(_get(props, 'original.gainLossRate'), 0)
                            ? 'up'
                            : 'down'
                        }
                        width="20"
                      />
                    )}
                    {Math.round(_get(props, 'original.gainLossRate') * 10000) /
                      100}
                    %
                  </span>
                ),
            },
            {
              Header: 'Age',
              id: 'age',
              accessor: (d) => d.age,
              width: 125,
              Cell: ({ value }) => renderTableAge(value),
            },
            {
              Header: '',
              id: 'actions',
              headerClassName: 'OpenPositionsTable--actionCellHeader',
              className: 'OpenPositionsTable--actionCell',
              width: 100,
              sortable: false,
              Cell: (props) => {
                if (_get(props, 'original.hasPendingOrders'))
                  return (
                    <div className="u-wrapRow">
                      <em className="OpenPositionsTable--actionReplacementText">
                        Attempting to sell…
                      </em>
                      <Button
                        variant="quaternary"
                        className="OpenPositionsTable--cancelSellButton"
                        onClick={() =>
                          cancelOpenOrdersByPosition(props.original.positionId)
                        }
                      >
                        Cancel Sell
                      </Button>
                    </div>
                  )

                return (
                  <div className="OpenPositionsTable--buttonContainer">
                    <Button
                      onClick={() => {
                        setSellPosition(_get(props, 'original'))
                        sellPositionModal.setShow(true)
                      }}
                      variant="quinary"
                    >
                      Sell
                    </Button>
                    <Button
                      variant="quinary"
                      onClick={() => {
                        setHoldPosition(_get(props, 'original'))
                        holdPositionModal.setShow(true)
                      }}
                    >
                      Hold
                    </Button>
                  </div>
                )
              },
            },
          ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'left',
              className: 'MobileCell',
              Cell: (props) => {
                return (
                  <React.Fragment>
                    {eq(_get(props, 'original.assetId'), 'lion') ? (
                      <Logo />
                    ) : (
                      <Currency type={_get(props, 'original.assetId')} />
                    )}
                    <div>
                      <div>{_get(props, 'original.market')}</div>
                      <div className="MobileCell-small">
                        {formatNumber(round(props.original.openAmount, 6))}{' '}
                        {upperCase(_get(props, 'original.assetId'))}
                      </div>
                      <div className="MobileCell-small MobileCell-light">
                        {formatAsDollarAmount(
                          _get(props, 'original.openAmount') *
                            _get(props, 'original.openPrice')
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
              },
            },
            {
              Header: '',
              id: 'right',
              className: 'MobileCell u-justifyend u-tr',
              accessor: 'gainLossRate',
              Cell: (props) => {
                const days = Math.floor(props.original.age / 1440)
                const hours = Math.floor((props.original.age % 1440) / 60)
                const minutes = props.original.age % 60

                const age = `${gt(days, 0) ? days + 'D' : ''} ${
                  gt(hours, 0) ? hours + 'H' : ''
                } ${
                  gt(minutes, 0) || (eq(days, 0) && eq(hours, 0))
                    ? minutes + 'M'
                    : ''
                }`

                return (
                  <div>
                    {isNil(props.original.gainLossRate) ? (
                      <React.Fragment>
                        --
                      </React.Fragment>
                    ) : (
                      <div
                        className={`u-justifyend ${
                          gt(_get(props, 'original.gainLossRate'), 0) &&
                          'TradeHistory-gain'
                        } ${
                          lt(_get(props, 'original.gainLossRate'), 0) &&
                          'TradeHistory-loss'
                        }`}
                      >
                        {Math.round(
                          _get(props, 'original.gainLossRate') * 10000
                        ) / 100}
                        %
                      </div>
                    )}
                    <div className="MobileCell-small MobileCell-light">
                      {trim(age)}
                    </div>
                  </div>
                )
              },
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBig, openPositions]
  )

  const data = useMemo(
    () =>
      positionsToDisplay.map((op) => {
        return {
          id: _get(op, 'position.id'),
          assetId: _get(op, 'position.assetId'),
          market: replace(toUpper(_get(op, 'position.marketId')), '_', '-'),
          openAmount: _get(op, 'position.amount'),
          openPrice: _get(op, 'position.openPrice'),
          fee: getMarketFee(_get(op, 'position.marketId')) || 0,
          takerFee: getTakerFee(_get(op, 'position.marketId')),
          gainLossRate: op.gainLossRate,
          gainLossAmount: op.gainLossAmount,
          age: differenceInMinutes(
            new Date(),
            new Date(_get(op, 'position.created'))
          ),
          hasPendingOrders: !!find(
            openOrders,
            (order) =>
              !eq(order.status, 'Cancelled') &&
              !eq(order.status, 'Completed') &&
              eq(order.side, 1) &&
              eq(order.positionId, _get(op, 'position.id'))
          ),
          orders: _get(op, 'orders'),
          position: _get(op, 'position'),
          positionId: _get(op, 'position.id'),
          isOpenPosition: true,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [positionsToDisplay, usdPrices, openOrders]
  )

  return (
    <React.Fragment>
      <div className="OpenPositionsTable">
        {!isNull(openPositions) && (
          <Table
            columns={columns}
            data={data}
            getTdProps={handleRowClick}
            emptyHeadline="No open positions to show"
            emptyDescription="Your strategies and subscriptions haven’t triggered any buys lately."
            loadingStates={[LOADING_STATES.FETCH_OPEN_POSITIONS]}
            defaultSorted={[
              {
                id: 'gainLossRate',
                desc: true,
              },
              {
                id: 'right',
                desc: true,
              },
            ]}
          />
        )}
      </div>
      {tradeDetailOpen && (
        <TradeDetail
          tradeDetails={tradeDetails}
          onDismiss={() => setTradeDetailOpen(false)}
          onSellClick={() => {
            setSellPosition(tradeDetails)
            sellPositionModal.setShow(true)
          }}
          onHoldClick={() => {
            setHoldPosition(tradeDetails)
            holdPositionModal.setShow(true)
          }}
          onCancelClick={
            get(tradeDetails, 'orders').length
              ? () => {
                  const orders = tradeDetails.orders
                  forEach(orders, (order) => {
                    deleteIncompleteTrade(order.orderId)
                  })
                }
              : null
          }
        />
      )}
      <HoldOpenPositionModal
        modal={holdPositionModal}
        openPosition={holdPosition}
      />
      <SellOpenPositionModal
        modal={sellPositionModal}
        openPosition={sellPosition}
      />
    </React.Fragment>
  )
}

export default OpenPositionsTable
