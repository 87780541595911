import React, { useEffect, useMemo, useState } from 'react'
import {
  split,
  get,
  toString,
  eq,
  replace,
  upperCase,
  startsWith,
  isUndefined,
  round,
} from 'lodash'
import { format as formatDate } from 'date-fns'
import Table from '@pv3-shared-components/Table'
import Button from '@pv3-shared-components/Button'
import Currency from '../shared/Currency'
import './incompleteOrdersTable.scss'
import useTradesDuck from '@pv3-hooks/useTradesDuck'
import ComponentLoader from '@pv3-components/ComponentLoader'
import { LOADING_STATES } from '../../constants'
import useWindowWidth from '@pv3-hooks/useWindowWidth'
import useDocTitle from '@pv3-hooks/useDocTitle'
import { formatAsDollarAmount, formatNumber } from '../../utils/utils'
import Logo from "../shared/Logo";

const IncompleteOrders = () => {
  useDocTitle('Incomplete Orders')
  const { getIncompleteTrades, incompleteTrades, deleteIncompleteTrade } =
    useTradesDuck()
  const isBig = useWindowWidth()
  const [filter, setFilter] = useState('WORKING')
  const [intervalTimer, setIntervalTimer] = useState()

  const handleCancelClick = async (tradeId) => {
    try {
      deleteIncompleteTrade(tradeId)
    } catch (error) {
      console.error(error)
    }
  }

  const selectedClass = (s) => (eq(s, filter) ? 'Button--selected' : '')

  useEffect(() => {
    if (!isUndefined(intervalTimer)) clearInterval(intervalTimer)
    getIncompleteTrades({ status: filter })
    const timer = setInterval(
      () => getIncompleteTrades({ status: filter }),
      600000
    )
    setIntervalTimer(timer)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const columns = useMemo(
    () =>
      isBig
        ? [
            // Desktop columns
            {
              Header: 'Order Details',
              id: 'orderDetails',
              headerClassName: 'IncompleteOrdersTable-row',
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--details',
              width: 260,
              accessor: 'acceptedAt',
              Cell: ({ original }) => {
                const date = formatDate(
                  new Date(original.acceptedAt),
                  'M/dd/yy'
                )
                const time = formatDate(new Date(original.acceptedAt), 'h:mm a')
                const side = {
                  0: 'Buy',
                  1: 'Sell',
                }[toString(original.side)]

                let currency = (
                  <Logo />
                )
                if (
                  !eq(original.instrument, 'string') &&
                  !startsWith(original.instrument, 'lion_')
                ) {
                  currency = (
                    <Currency type={original.instrument.split('_')[0]} />
                  )
                }

                return (
                  <React.Fragment>
                    <div className="IncompleteOrdersTable-datetime">
                      {date}
                      <br />
                      {time}
                    </div>
                    <div className="IncompleteOrdersTable-currency">
                      {currency}
                      <span>
                        {replace(get(original, 'instrument'), '_', '-')}
                      </span>
                    </div>
                    <div className="IncompleteOrdersTable-foo">{side}</div>
                    {get(original, 'position') &&
                      !eq(
                        get(original, 'position.strategy.userId'),
                        get(original, 'position.strategy.publisherUserId')
                      ) && <em className="BadgeCopyCat">Copycat</em>}
                  </React.Fragment>
                )
              },
            },
            {
              Header: 'Quantity',
              id: 'quantity',
              headerClassName: 'u-tr IncompleteOrdersTable-row',
              width: 125,
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--quantity u-justifyend',
              accessor: (d) => d.requestedAmount,
              Cell: (props) => (
                <React.Fragment>
                  {formatNumber(round(props.value, 6))}
                </React.Fragment>
              ),
            },
            {
              Header: 'Price',
              id: 'price',
              headerClassName: 'u-tr IncompleteOrdersTable-row',
              width: 125,
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--price u-justifyend',
              accessor: 'requestedPrice',
              Cell: ({ original, value }) => {
                const pair = split(get(original, 'instrument'), '_')

                return eq(pair[1], 'usd')
                  ? formatAsDollarAmount(value, false, 4)
                  : `${round(value, 3).toLocaleString()} ${upperCase(pair[1])}`
              },
            },
            {
              Header: 'Cost',
              id: 'cost',
              headerClassName: 'u-tr u-tableCellLgUp IncompleteOrdersTable-row',
              width: 125,
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--cost u-justifyend u-tableCellLgUp',
              accessor: (d) => d.requestedPrice * d.requestedAmount,
              Cell: ({ original, value }) => {
                const pair = split(get(original, 'instrument'), '_')

                return eq(pair[1], 'usd')
                  ? formatAsDollarAmount(value)
                  : `${round(value, 3).toLocaleString()} ${upperCase(pair[1])}`
              },
            },
            {
              Header: 'Filled',
              id: 'filled',
              width: 90,
              headerClassName: 'u-tr u-tableCellMdUp IncompleteOrdersTable-row',
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--filled u-justifyend u-tableCellMdUp',
              accessor: (d) => formatNumber(round(d.baseAmount, 6)),
            },
            {
              Header: 'Filled %',
              headerClassName: 'u-tr IncompleteOrdersTable-row',
              id: 'filledPercent',
              width: 92,
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--filledPercent u-justifyend',
              accessor: (d) => (d.baseAmount / d.requestedAmount) * 100,
              Cell: ({ value }) => `${round(value, 2)}%`,
            },
            {
              Header: '',
              id: 'cancel',
              headerClassName: 'IncompleteOrdersTable-row',
              className:
                'IncompleteOrdersTable-row IncompleteOrdersTable-row--cancel u-justifyend',
              show: eq(filter, 'WORKING'),
              sortable: false,
              width: 104,
              Cell: (order) => {
                return eq(filter, 'WORKING') &&
                  !isUndefined(get(order, 'original.orderId')) ? (
                  <Button
                    variant="quinary"
                    onClick={() => handleCancelClick(order.original.orderId)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <React.Fragment />
                )
              },
            },
          ]
        : [
            // Mobile columns
            {
              Header: '',
              id: 'mobile-left',
              className: 'MobileCell',
              accessor: 'acceptedAt',
              Cell: (props) => {
                const side = {
                  0: 'Buy',
                  1: 'Sell',
                }[toString(get(props, 'original.side'))]
                const date = formatDate(
                  new Date(get(props, 'original.acceptedAt')),
                  'M/dd/yy'
                )

                return (
                  <div>
                    <div>
                      {replace(
                        get(props, 'original.instrument'),
                        '_',
                        '-'
                      ).toUpperCase()}
                    </div>
                    <div className="MobileCell-small MobileCell-light">
                      {side}
                    </div>
                    <div className="MobileCell-small MobileCell-light">
                      {date}
                    </div>
                    {eq(filter, 'WORKING') &&
                      !isUndefined(get(props, 'original.orderId')) && (
                        <button
                          className="u-buttonReset IncompleteOrdersTable-cancelRed MobileCell-small"
                          onClick={() =>
                            handleCancelClick(get(props, 'original.orderId'))
                          }
                        >
                          Cancel
                        </button>
                      )}
                  </div>
                )
              },
            },
            {
              Header: '',
              id: 'mobile-right',
              className: 'MobileCell u-justifyend u-tr',
              Cell: (props) => {
                const pair = split(get(props, 'original.instrument'), '_')
                let percentage =
                  (get(props, 'original.baseAmount') /
                    get(props, 'original.requestedAmount')) *
                  100
                return (
                  <div>
                    <div>
                      {round(get(props, 'original.requestedAmount'), 3).toLocaleString()}
                    </div>
                    <div className="MobileCell-small">
                      {eq(pair[1], 'usd')
                        ? formatAsDollarAmount(
                            get(props, 'original.requestedPrice')
                          )
                        : `${round(get(props, 'original.requestedPrice'), 3).toLocaleString()} ${upperCase(
                            pair[1]
                          )}`}
                    </div>
                    <div className="MobileCell-small">
                      {round(percentage, 2)}%
                    </div>
                  </div>
                )
              },
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, isBig]
  )

  return (
    <div className="Content Content--incompleteOrders">
      <ComponentLoader
        loadingStates={[LOADING_STATES.FETCH_INCOMPLETE_ORDERS]}
      />
      <h2 className="Activity-headline h4">Incomplete Orders</h2>
      <div className="FilterBar">
        <Button
          onClick={() => setFilter('WORKING')}
          variant="tertiary"
          size="small"
          className={selectedClass('WORKING')}
        >
          Open
        </Button>
        <Button
          variant="tertiary"
          size="small"
          onClick={() => setFilter('CANCELLED')}
          className={selectedClass('CANCELLED')}
        >
          Cancelled
        </Button>
        <Button
          variant="tertiary"
          size="small"
          onClick={() => setFilter('ALL')}
          className={selectedClass('ALL')}
        >
          All
        </Button>
      </div>
      <Table
        columns={columns}
        data={incompleteTrades}
        emptyHeadline={
          {
            WORKING: 'No open orders to show',
            CANCELLED: 'No cancelled orders to show',
            ALL: 'No incomplete orders to show',
          }[filter]
        }
        emptyDescription="All your buys have been bought, all your sells have been sold."
        loadingStates={[LOADING_STATES.FETCH_INCOMPLETE_ORDERS]}
        defaultSorted={[
          {
            id: 'orderDetails',
            desc: true,
          },
          {
            id: 'mobile-left',
            desc: true,
          },
        ]}
      />
    </div>
  )
}

export default IncompleteOrders
