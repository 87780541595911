import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit'
import profileReducer from './profile'
import walletsReducer from './wallets'
import sessionReducer from './session'
import uiReducer from './ui'
import marketsReducer from './markets'
import tradesReducer from './trades'
import assetsReducer from './assets'
import strategiesReducer from './strategies'
import balancerReducer from './balancer'
import achReducer from './ach'
import marketplaceReducer from './marketplace'
import accountReducer from './account'
import { eq } from 'lodash'
import reportsReducer from './reports'
import travelRuleReducer from './travelRule';
import rewardsReducer from './rewards';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const combinedReducer = combineReducers({
  account: accountReducer,
  ach: achReducer,
  assets: assetsReducer,
  balancer: balancerReducer,
  marketplace: marketplaceReducer,
  markets: marketsReducer,
  profile: profileReducer,
  reports: reportsReducer,
  rewards: rewardsReducer,
  session: sessionReducer,
  strategies: strategiesReducer,
  trades: tradesReducer,
  travelRule: travelRuleReducer,
  ui: uiReducer,
  wallets: walletsReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const reset = createAction('reset');

const rootReducer = (state, action) => {
  // Reset redux state if signing out
  if (action.type === 'reset') {
    state = {
      ...state,
      value: undefined
    }
  }
  if (eq(action.payload, 'Request failed with status code 401')) {
    state = {
      ...state,
      ui: {
        ...state.ui,
        backendSessionExpired: true,
      },
    }
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
