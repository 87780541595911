//import './CycleAPISecretModal.scss';

import * as React from 'react';
import { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";

import { LOADING_STATES } from "../../../constants";

import useModal from "../../../hooks/useModal";
import useStrategiesDuck from '../../../hooks/useStrategiesDuck';
import useUiDuck from "../../../hooks/useUiDuck";

import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from "../Button";
import Flash from "../Flash";
import Icon from '../Icons';
import Modal from "../Modal";

type Props = {
  modal: ReturnType<typeof useModal>;
}

const CycleAPISecretModal: React.FC<Props> = ({
  modal,
}) => {
  const { apiSecret, clearApiSecret, createApiSecret } = useStrategiesDuck();
  const { loading, setLoading } = useUiDuck();

  const [showCopiedSecretToClipboard, setShowCopiedSecretToClipboard] = useState(false);

  const handleConfirmCycleClick = async () => {
    try {
      setLoading({context: LOADING_STATES.CYCLE_API_SECRET, isLoading: true})
      return await createApiSecret();
    } catch(err) {
      console.log('showConfirmModal err', err);
      setLoading({context: LOADING_STATES.CYCLE_API_SECRET, isLoading: false})
      throw err;
    }
  };

  return (
    <Modal
      modal={modal}
      screens={{
        MAIN: {
          heading: 'Cycle API Secret',
          body: (push) => (
            <div className="ConfirmApiSecretCycleModal-body">
              <div className="ConfirmApiSecretCycleModal-iconContainer">
                <Icon name="exclamation circle"/>
              </div>

              <p className="ConfirmApiSecretCycleModal-description" data-testid="webhook-cycle-secret-text">
                Are you sure you want to regenerate your API secret? This will break all existing webhook connections
                until you update the webhook URL in other servers.
              </p>

              <div className="ConfirmApiSecretCycleModal-buttonContainer">
                <Button
                  variant={ButtonVariants.Primary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={async () => {
                    let result = await handleConfirmCycleClick();
                    setLoading({context: LOADING_STATES.CYCLE_API_SECRET, isLoading: false})
                    if (result.error) {
                      push('ERROR')
                    } else {
                      push('SUCCESS')
                    }
                  }}
                  isLoading={loading[LOADING_STATES.CYCLE_API_SECRET]}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  Yes
                </Button>

                <Button
                  variant={ButtonVariants.Secondary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={() => modal.setShow(false)}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ),
          noBackButton: true,
        },
        SUCCESS: {
          heading: 'Success!',
          body: (
            <div className="ConfirmApiSecretCycleModal-body">
              <p className="ConfirmApiSecretCycleModal-description">
                Your API secret has been cycled. We won't be able to show it to you again, so please store it in a
                secure place.
              </p>

              <div className="webhook-link ">
                {apiSecret}

                <CopyToClipboard
                  text={apiSecret}
                  options={{format: 'text/plain'}}
                  onCopy={() => {
                    setShowCopiedSecretToClipboard(true)
                    setTimeout(() => setShowCopiedSecretToClipboard(false), 2000)
                  }}
                >
                  <Button variant={ButtonVariants.Quinary} className="WireTransfer-copyButton Webhook-copyButton" type={ButtonTypes.Button}>
                    <Icon name="copy"/>
                  </Button>
                </CopyToClipboard>

                <div className="DepositCrypto-copiedToClipboardAlertContainer">
                  <div
                    className={`WireTransfer-copiedToClipboardAlert ${
                      showCopiedSecretToClipboard ? 'visible' : ''
                    }`}
                  >
                    <Flash
                      className="Flash--success"
                      hideCloseButton
                      iconSize={20}
                      iconFill="#00854d"
                    >
                      API secret copied
                    </Flash>
                  </div>
                </div>
              </div>

              <div className="NoBuyTriggerModal-buttonContainer">
                <Button
                  variant={ButtonVariants.Primary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={() => {
                    modal.setShow(false);
                    clearApiSecret();
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  OK
                </Button>
              </div>
            </div>
          ),
          noBackButton: true,
        },
        ERROR: {
          heading: 'An Error Has Occurred',
          body: (
            <div className="ConfirmApiSecretCycleModal-body">
              <p className="ConfirmApiSecretCycleModal-description">
                There was an error cycling your API secret. Please try again.
              </p>

              <div className="NoBuyTriggerModal-buttonContainer">
                <Button
                  variant={ButtonVariants.Primary}
                  className="ConfirmApiSecretCycleModal-button"
                  onClick={() => {
                    modal.setShow(false);
                    clearApiSecret();
                  }}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                >
                  OK
                </Button>
              </div>
            </div>
          ),
          noBackButton: true,
        },
      }}
    />
  );
};

export default CycleAPISecretModal;
