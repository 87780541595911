import React from 'react'

const Info = ({ fill = '#848d96', width = '20', height = '20', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-1 -1 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 .166a8.889 8.889 0 100 17.778A8.889 8.889 0 009 .166zm-.055 2.933a1.111 1.111 0 110 2.223 1.111 1.111 0 010-2.223zM6.278 14.127c0 .43.348.778.778.778h3.889a.778.778 0 000-1.556H9.778V6.683H7.334a.778.778 0 000 1.555h.888v5.111H7.056a.778.778 0 00-.778.778z"
        fill={fill}
      />
      <rect x="-1" y="-1" width="20" height="20" fill="transparent" />
    </svg>
  )
}

export default Info
