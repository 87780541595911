import React from 'react'

const CaretUp = ({
  fill = '#737373',
  width = '24',
  height = '24',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M26.6356 21.0536L17.9963 13L9.35689 21.0536C8.86816 21.5107 8.88327 22.2382 9.39064 22.6785C9.89801 23.1188 10.7055 23.1052 11.1943 22.6481L17.9963 16.3039L24.8057 22.6481C25.2945 23.1052 26.102 23.1188 26.6094 22.6785C27.1167 22.2382 27.1318 21.5107 26.6431 21.0536H26.6356Z"
        fill={fill}
      />
    </svg>
  )
}

export default CaretUp
