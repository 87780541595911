import React from 'react'

const CaretDown = ({
  fill = '#737373',
  width = '24',
  height = '24',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M26.6356 14.9464L17.9963 23L9.35689 14.9464C8.86816 14.4893 8.88327 13.7618 9.39064 13.3215C9.89801 12.8812 10.7055 12.8948 11.1943 13.3519L17.9963 19.6961L24.8057 13.3519C25.2945 12.8948 26.102 12.8812 26.6094 13.3215C27.1167 13.7618 27.1318 14.4893 26.6431 14.9464H26.6356Z"
        fill={fill}
      />
    </svg>
  )
}

export default CaretDown
