import React from 'react'

const Document = ({
  fill = '#323232',
  width = '36',
  height = '36',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.89 4H7.83A1.88 1.88 0 0 0 6 5.91v24.18A1.88 1.88 0 0 0 7.83 32h20.34A1.88 1.88 0 0 0 30 30.09V11.92L21.89 4Zm-.3 2.49 6 5.9h-6v-5.9ZM8 6v24h20V14h-8V6H8Z"
        fill={fill}
      />
    </svg>
  )
}

export default Document
