import React, {useEffect, useState} from 'react'
import _uniqueId from 'lodash/uniqueId'
import Icon from '@pv3-shared-components/Icons'
import './stoplosstooltip.scss'
import PropTypes from 'prop-types'
import { eq, lowerCase } from 'lodash'
import Currency from '../Currency'
import StopLoss from "../Icons/StopLoss";
import A from "../A";
import useMarketplaceDuck from "../../../hooks/useMarketplaceDuck";
import ActivityIndicator from "../ActivityIndicator";
import {isObjectNullOrEmpty} from "../../../utils/utils";
import EnhancedFlash from "../EnhancedFlash";
import Logo from "../Logo";

export const StopLossWarning = () => {
  return (
    <EnhancedFlash
      subheading={(<A href="https://help.coinlion.com/how-do-stop-losses-work-within-copycat-subscriptions" target="_blank">Learn More</A>)}
      heading="This Trader has Stop Losses enabled"
      variant="stopLoss"
    />
  );
};

const StopLossTooltip = ({ publisherId }) => {
  const { getFormattedStopLossDataFromPublisherStrategies, getPublisherStrategies } = useMarketplaceDuck();
  const [id] = useState(_uniqueId('stoploss-tooltip-'))
  const [toolTipModalOpen, setToolTipModalOpen] = useState(false)
  const [isInitializing, setIsInitializing] = useState(false);
  const [formattedData, setFormattedData] = useState({});

  const handleToolTipButtonHover = () => setToolTipModalOpen(true)
  const handleToolTipButtonLeave = () => setToolTipModalOpen(false)

  useEffect(() => {
    if(toolTipModalOpen) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolTipModalOpen]);

  const initialize = async () => {
    if(isInitializing) {
      return;
    }

    setIsInitializing(true);

    try {
      let res = await getPublisherStrategies(publisherId);
      setFormattedData(getFormattedStopLossDataFromPublisherStrategies(res.payload));
    } catch(err) {
      console.log('StopLossTooltip initialize err', err);
    } finally {
      setIsInitializing(false);
    }
  }

  return (
    <div
      className={`CurrenciesTooltip StopLossTooltip${
        toolTipModalOpen ? ' CurrenciesTooltip--modalOpen' : ''
      }`}
      onMouseEnter={initialize}
    >
      <button
        type="button"
        className="u-buttonReset CurrenciesTooltip-button StopLossTooltip-button"
        aria-label="Currency Allocation"
        aria-expanded={toolTipModalOpen ? true : false}
        aria-describedby={id}
        onFocus={handleToolTipButtonHover}
        onBlur={handleToolTipButtonLeave}
      >
        <StopLoss />
      </button>
      <section id={id} role="tooltip" className="CurrenciesTooltip-content StopLossTooltip-content">
        <StopLossWarning />

        <h6>
          Stop Loss Summary By Asset
        </h6>

        {isInitializing && <ActivityIndicator />}

        {!isObjectNullOrEmpty(formattedData) && !isInitializing && (
          <ul>
            {Object.keys(formattedData).map((key) => (
              <li key={key}>
                {eq(key, 'LION') ? (
                  <Logo />
                ) : (
                  <Currency type={lowerCase(key)} />
                )}

                <div className="StopLossTooltip-row-item">
                  <strong>{formattedData[key].percentHaveStopLoss}%</strong> of strategies use Stop Loss
                </div>

                <StopLoss fill="#848D96" outline />

                <div className="StopLossTooltip-row-item">
                  <strong>{formattedData[key].averageThreshold}%</strong> average threshold
                </div>
              </li>
            ))}
          </ul>
        )}

        <Icon
          className="CurrenciesTooltip-caret"
          name="currenciestooltipcaret"
        />
      </section>
    </div>
  )
}

StopLossTooltip.propTypes = {
  currencies: PropTypes.array,
}

export default StopLossTooltip
