import React, { useEffect, useMemo, useState } from 'react'
import './buysellmodal.scss'
import Modal from '@pv3-shared-components/Modal'
import AssetWalletItem from '../AssetWalletItem'
import Button from '@pv3-shared-components/Button'
import Icon from '@pv3-shared-components/Icons'
import ManualBuyForm from '../ManualBuyForm'
import useAssetsDuck from '../../hooks/useAssetsDuck'
import SelectableAssetList from '../SelectableAssetList'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import {
  gt,
  filter,
  eq,
  find,
  upperCase,
  isNull,
  isUndefined,
  chain,
} from 'lodash'
import useMarketsDuck from '../../hooks/useMarketsDuck'
import useProfileDuck from '../../hooks/useProfileDuck'
import { formatNumber } from '../../utils/utils'
import ComponentLoader from '@pv3-components/ComponentLoader'
import {
  APP_PATHS,
  KYC_VERIFICATION_STATUSES,
  LOADING_STATES,
  PERMISSION_REASONS,
} from '../../constants'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import ManualSellForm from '../ManualSellForm'
import OrderBook from '../OrderBook'
import {isArrayNullOrEmpty} from "../../utils/utils";
import Toggle from '../shared/Toggle';
import { OrderSides } from '../../utils/enums';
import { useHistory } from 'react-router';

const BuySellModal = ({ modal, assetId }) => {
  const history = useHistory()
  const [currentAssetId, setCurrentAssetId] = useState(assetId)
  const { profile: { homeQuoteAsset }, getUsdAllowed, kycVerificationStatus, reason: permissionReason } = useProfileDuck()
  const { walletValuesInUSD, createOrder } = useWalletsDuck()

  const usdAllowed = getUsdAllowed();
  const assetIdIsLion = assetId === 'lion';

  const [currentExchangeAssetId, setCurrentExchangeAssetId] = useState(homeQuoteAsset);
  const { assets: assetsInfo } = useAssetsDuck()
  const { availableMarketAssetIds, calculateApproximateSellPrice, calculateApproximateBuyPrice, orderBooks, startOrderBook } = useMarketsDuck()
  const [submitError, setSubmitError] = useState(false)
  const [order, setOrder] = useState({})
  const [isLimit, setIsLimit] = useState(false)
  const [instrument, setInstrument] = useState()
  const {
    price,
    amount,
    limitOrderAmount,
    total,
    buyAsset,
    payAsset,
    sellAsset,
    receiveAsset,
    goodTill,
  } = order

  const assets = useMemo(
    () => {
      if (isArrayNullOrEmpty(availableMarketAssetIds) || isArrayNullOrEmpty(assetsInfo)) {
        return null;
      }

      return chain(availableMarketAssetIds)
        .map((key) => find(assetsInfo, ({id}) => eq(id, key)))
        .filter(({id}) => (usdAllowed ? true : !eq(id, 'usd')))
        .value()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assetsInfo, availableMarketAssetIds]
  )

  const assetsWithPositiveBalance = useMemo(
    () =>
      filter(
        assets,
        ({ id }) =>
          gt(walletValuesInUSD[id], 0) && (usdAllowed ? true : !eq(id, 'usd'))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets, walletValuesInUSD]
  )

  const sellAssetIsLion = eq('lion', sellAsset);

  useEffect(() => {
    setCurrentAssetId(assetId)
  }, [assetId])

  useEffect(() => {
    if (isUndefined(instrument)) return;

    const instrumentToUse = /lion_usd|lion_usdt/.test(instrument) ? 'lion_usdc' : instrument;

    if (isUndefined(orderBooks[instrumentToUse])) startOrderBook(instrumentToUse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBooks, instrument])

  const handleClickBuy = async () => {
    try {
      setSubmitError(false);

      let finalPrice
      let finalAmount
      if (isLimit) {
        finalPrice = price
        finalAmount = limitOrderAmount
      } else {
        // TODO: We'll have to eventually support market orders that set total dollar amount
        // it'll have to be an if else here
        finalPrice = undefined;
        finalAmount = amount;
      }

      await createOrder({
        isLimit,
        marketId: instrument === 'lion_usd' || instrument === 'lion_usdt' ? 'lion_usdc' : instrument,
        price: finalPrice,
        amount: finalAmount,
        side: OrderSides.Buy,
        goodTill,
      })

      modal.setShow(false)
    } catch (e) {
      console.log(e)
      setSubmitError(e)
    }
  }

  const handleClickSell = async () => {
    try {
      setSubmitError(false);

      let finalPrice
      let finalAmount

      if (isLimit) {
        finalPrice = price
        finalAmount = limitOrderAmount
      } else {
        // TODO: We'll have to eventually support market orders that set total dollar amount
        // it'll have to be an if else here
        finalPrice = undefined;
        finalAmount = amount;
      }

      await createOrder({
        isLimit,
        marketId: instrument === 'lion_usd' || instrument === 'lion_usdt' ? 'lion_usdc' : instrument,
        price: finalPrice,
        amount: finalAmount,
        side: OrderSides.Sell,
        goodTill,
      })

      modal.setShow(false)
    } catch (e) {
      console.log(e)
      setSubmitError(e)
    }
  }

  const profileIsVerified = kycVerificationStatus === KYC_VERIFICATION_STATUSES.VERIFIED;

  return (
    <Modal
      className="BuySellModal"
      modal={modal}
      onBackPress={() => setSubmitError(false)}
      screens={{
        MAIN: {
          heading: <h4>Buy/Sell</h4>,
          body: (push) => (
            <div className="BuySell-body">
              <AssetWalletItem assetId={assetId} />

              {!profileIsVerified && (
                <div className="BuySellModal-notVerifiedWarning">
                  <p>
                    Your identity verification process has not been completed.  Please go to the Account Settings screen and get verified.  If you have attempted multiple times without success then please reach out to support.
                  </p>

                  <Button
                    className="AddFunds-facialAuthButton"
                    onClick={() => history.push(APP_PATHS.ACCOUNT)}
                  >
                    Go to Account Settings
                  </Button>
                </div>
              )}

              <div className="BuySell-buttonContainer">
                <Button
                  disabled={!profileIsVerified}
                  variant="secondary"
                  size="large"
                  className="u-horizontalPadding u-fullWidth"
                  onClick={() => {
                    setIsLimit(false);
                    setOrder({})
                    setCurrentAssetId(assetId)
                    setCurrentExchangeAssetId(homeQuoteAsset)
                    push('BUY_SETUP')
                  }}
                >
                  <div className="AddFunds--buttonInterior">
                    <Icon name="plus" fill="#500078" height="32" width="32" />
                    <span className="AddFunds--buttonText">Buy</span>
                    <Icon name="caretright" fill="#500078" />
                  </div>
                </Button>

                <Button
                  disabled={!profileIsVerified}
                  variant="secondary"
                  size="large"
                  className="u-horizontalPadding u-fullWidth"
                  onClick={() => {
                    setIsLimit(false);
                    setOrder({})
                    setCurrentAssetId(assetId)
                    setCurrentExchangeAssetId(homeQuoteAsset)
                    push('SELL_SETUP')
                  }}
                >
                  <div className="AddFunds--buttonInterior">
                    <Icon name="minus" fill="#500078" height="32" width="32" />
                    <span className="AddFunds--buttonText">Sell</span>
                    <Icon name="caretright" fill="#500078" />
                  </div>
                </Button>
              </div>

              {!usdAllowed && eq(permissionReason, PERMISSION_REASONS.REGION) && (
                <div className="BuySellModal-enhancedFlashContainer">
                  <EnhancedFlash
                    variant="warning"
                    heading="You are in a jurisdiction that does not currently allow USD to Crypto trading."
                    subheading="You are allowed to trade using USDC and/or USDT for crypto to crypto trading only."
                  />
                </div>
              )}
            </div>
          ),
        },
        BUY_SETUP: {
          heading: <h4>Buy</h4>,
          body: (push) => (
            <div className="BuySellModal-expandableScreen">
              <div className="BuySellModal-expandableScreenLeft">
                <ManualBuyForm
                  buyAsset={currentAssetId}
                  payAsset={currentExchangeAssetId}
                  handleChooseBuyAsset={() => push('CHOOSE_BUY_ASSET')}
                  handleChooseExchangeAsset={() =>
                    push('CHOOSE_BUY_EXCHANGE_ASSET')
                  }
                  handleSubmitBuyOrder={(buyOrder) => {
                    buyOrder.isLimit = isLimit;
                    setOrder(buyOrder)
                    push('CONFIRM_BUY')
                  }}
                  buyOrder={order}
                  isLimit={isLimit}
                  onInstrumentChange={(i) => setInstrument(i)}
                />
                <div className="BuySellModal-underButtonOptions">
                  <Toggle
                    handleChange={() => setIsLimit(!isLimit)}
                    label={isLimit ? 'Limit Order' : 'Market Order'}
                    value={isLimit}
                  />

                  <Button
                    type="button"
                    variant="quaternary"
                    className="BuySellModal-toggleOrderBookButton"
                    onClick={() => modal.setWide((v) => !v)}
                    disabled={isUndefined(instrument)}
                  >
                    {modal.wide ? (
                      <Icon
                        className="BuySellModal-caret--up"
                        name="caretup"
                        fill="#500078"
                      />
                    ) : (
                      <Icon
                        className="BuySellModal-caret--down"
                        name="caretdown"
                        fill="#500078"
                      />
                    )}
                    {modal.wide && (
                      <Icon
                        className="BuySellModal-caret--left"
                        name="caretleft"
                        fill="#500078"
                      />
                    )}
                    {modal.wide ? ' Hide' : ' Show'} order book{' '}
                    {!modal.wide && (
                      <Icon
                        className="BuySellModal-caret--right"
                        name="caretright"
                        fill="#500078"
                      />
                    )}
                  </Button>
                </div>
              </div>

              {!isNull(instrument) && modal.wide && (
                <div className="BuySellModal-expandableScreenRight">
                  <OrderBook
                    instrument={instrument}
                    isInverted={eq(
                      instrument,
                      `${currentExchangeAssetId}_${currentAssetId}`
                    )}
                  />
                </div>
              )}
            </div>
          ),
        },
        SELL_SETUP: {
          heading: <h4>Sell</h4>,
          body: (push) => (
            <div className="BuySellModal-expandableScreen">
              <div className="BuySellModal-expandableScreenLeft">
                <ManualSellForm
                  sellAsset={currentAssetId}
                  receiveAsset={currentExchangeAssetId}
                  handleChooseSellAsset={() => push('CHOOSE_SELL_ASSET')}
                  handleChooseExchangeAsset={() =>
                    push('CHOOSE_SELL_EXCHANGE_ASSET')
                  }
                  handleSubmitSellOrder={(sellOrder) => {
                    setOrder(sellOrder)
                    push('CONFIRM_SELL')
                  }}
                  sellOrder={order}
                  isLimit={isLimit}
                  onInstrumentChange={(i) => setInstrument(i)}
                />

                <div className="BuySellModal-underButtonOptions">
                  <Toggle
                    handleChange={() => setIsLimit(!isLimit)}
                    label={isLimit ? 'Limit Order' : 'Market Order'}
                    value={isLimit}
                  />

                  <Button
                    type="button"
                    variant="quaternary"
                    className="BuySellModal-toggleOrderBookButton"
                    onClick={() => modal.setWide((v) => !v)}
                    disabled={isUndefined(instrument)}
                  >
                    {modal.wide ? (
                      <Icon
                        className="BuySellModal-caret--up"
                        name="caretup"
                        fill="#500078"
                      />
                    ) : (
                      <Icon
                        className="BuySellModal-caret--down"
                        name="caretdown"
                        fill="#500078"
                      />
                    )}
                    {modal.wide && (
                      <Icon
                        className="BuySellModal-caret--left"
                        name="caretleft"
                        fill="#500078"
                      />
                    )}
                    {modal.wide ? 'Hide' : 'Show'} order book{' '}
                    {!modal.wide && (
                      <Icon
                        className="BuySellModal-caret--right"
                        name="caretright"
                        fill="#500078"
                      />
                    )}
                  </Button>
                </div>
              </div>

              {!isNull(instrument) && modal.wide && (
                <div className="BuySellModal-expandableScreenRight">
                  <OrderBook
                    instrument={instrument}
                    isInverted={eq(
                      instrument,
                      `${currentExchangeAssetId}_${currentAssetId}`
                    )}
                    isSellSide
                  />
                </div>
              )}
            </div>
          ),
        },
        CHOOSE_BUY_ASSET: {
          heading: <h4>Buy</h4>,
          body: (_, back) => (
            <SelectableAssetList
              assets={assets}
              onSelect={(asset) => {
                setCurrentAssetId(asset.id)
                setOrder({ isLimit: order.isLimit })
                back()
              }}
            />
          ),
        },
        CHOOSE_BUY_EXCHANGE_ASSET: {
          heading: <h4>Buy</h4>,
          body: (_, back) => (
            <SelectableAssetList
              assets={assetsWithPositiveBalance}
              onSelect={(asset) => {
                setCurrentExchangeAssetId(asset.id)
                setOrder({ isLimit: order.isLimit })
                back()
              }}
            />
          ),
        },
        CHOOSE_SELL_ASSET: {
          heading: <h4>Sell</h4>,
          body: (_, back) => (
            <SelectableAssetList
              assets={assetsWithPositiveBalance}
              onSelect={(asset) => {
                setCurrentAssetId(asset.id)
                setOrder({ isLimit: order.isLimit })
                back()
              }}
            />
          ),
          preventBacktrack: true,
        },
        CHOOSE_SELL_EXCHANGE_ASSET: {
          heading: <h4>Sell</h4>,
          body: (_, back) => (
            <SelectableAssetList
              assets={assets}
              onSelect={(asset) => {
                setCurrentExchangeAssetId(asset.id)
                setOrder({ isLimit: order.isLimit })
                back()
              }}
            />
          ),
        },
        CONFIRM_BUY: {
          heading: <h4>Buy</h4>,
          body: (
            <div>
              <ComponentLoader loadingStates={[LOADING_STATES.ORDER]} />

              <h3>Buy order preview</h3>
              <div className="BuySellModal-dataRow BuySellModal-extraMarginBottom">
                <p className="DepositCrypto-labelText">Amount</p>
                <p className="DepositCrypto-dataText DepositCrypto-boldText">
                  {formatNumber(isLimit ? limitOrderAmount : amount)}{' '}
                  {upperCase(buyAsset)}
                </p>
              </div>
              <div className="BuySellModal-dataRow">
                <p className="DepositCrypto-labelText">Price</p>
                <p className="DepositCrypto-dataText">
                  {isLimit ? `${formatNumber(price)} ${assetIdIsLion ? 'USDC' : upperCase(payAsset)}` : calculateApproximateBuyPrice({ amount: 1, instrument })}
                  {` / ${upperCase(buyAsset)}`}
                </p>
              </div>
              <hr className="u-divider BuySellModal-divider" />
              <div className="BuySellModal-dataRow">
                <p className="DepositCrypto-labelText">Total Cost</p>
                <p className="DepositCrypto-dataText DepositCrypto-boldText">
                  {isLimit ? `${formatNumber(total)} ${assetIdIsLion ? 'USD(C/T)' : upperCase(receiveAsset)}` : calculateApproximateBuyPrice({ amount, instrument })}
                </p>
              </div>

              {submitError && (
                <EnhancedFlash
                  variant="danger"
                  heading={submitError}
                />
              )}

              <Button
                variant="primary"
                size="large"
                className="BuySellModal-buyButton u-fullWidth"
                onClick={handleClickBuy}
              >
                Buy
              </Button>
            </div>
          ),
        },
        CONFIRM_SELL: {
          heading: <h4>Sell</h4>,
          body: (
            <div>
              <ComponentLoader loadingStates={[LOADING_STATES.ORDER]} />

              <h3>Sell order preview</h3>
              <div className="BuySellModal-dataRow BuySellModal-extraMarginBottom">
                <p className="DepositCrypto-labelText">Amount</p>
                <p className="DepositCrypto-dataText DepositCrypto-boldText">
                  {formatNumber(isLimit ? limitOrderAmount : amount)}{' '}
                  {upperCase(sellAsset)}
                </p>
              </div>
              <div className="BuySellModal-dataRow">
                <p className="DepositCrypto-labelText">Price</p>
                <p className="DepositCrypto-dataText">
                  {isLimit ? `${formatNumber(price)} ${assetIdIsLion ? 'USDC' : upperCase(receiveAsset)}` : calculateApproximateSellPrice({ amount: 1, instrument })}
                  {` / ${upperCase(sellAsset)}`}
                </p>
              </div>
              <hr className="u-divider BuySellModal-divider" />
              <div className="BuySellModal-dataRow">
                <p className="DepositCrypto-labelText">Total</p>
                <p className="DepositCrypto-dataText DepositCrypto-boldText">
                  {isLimit ? `${formatNumber(total)} ${assetIdIsLion ? 'USD(C/T)' : upperCase(receiveAsset)}` : calculateApproximateSellPrice({ amount, instrument })}
                </p>
              </div>

              {submitError && (
                <EnhancedFlash
                  variant="danger"
                  heading={submitError}
                />
              )}

                {sellAssetIsLion && (
                    <p className="Liquidator-contitional">WARNING…  We strongly encourage that if you want to sell LION that you use the Liquidator and not a manual sell order.  It’s obviously your choice but with a low liquidity market the Liquidator is your best way to liquidate and sell LION orders. (<a href={APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW}>Go to Liquidator</a>)</p>
                )}

              <Button
                variant="primary"
                size="large"
                className="BuySellModal-buyButton u-fullWidth"
                onClick={handleClickSell}
              >
                Sell
              </Button>
            </div>
          ),
        },
      }}
    />
  )
}

export default BuySellModal
