import './rewards.scss';

import * as React from 'react';
import { Route, Switch } from "react-router-dom";

import { APP_PATHS } from "../../constants";

import RewardsAggregator from "./pages/Aggregator";
import RewardsDiscounts from "./pages/Discounts";
import RewardsHeader from "./components/RewardsHeader";
import RewardsHodl from "./pages/HODL";
import RewardsLiquidator from "./pages/Liquidator";
import RewardsReferrals from "./pages/Referrals";
import RewardsCLTB from "./pages/CLTB";
import RewardsVoting from "./pages/Voting";

const Rewards: React.FC = () => {
    return (
      <div className="Rewards">
        <RewardsHeader />

        <Switch>
          <Route
            path={APP_PATHS.REWARDS_REFERRALS}
            component={RewardsReferrals}
          />
          <Route
            path={APP_PATHS.REWARDS_HODL}
            component={RewardsHodl}
          />
          <Route
            path={APP_PATHS.REWARDS_DISCOUNTS}
            component={RewardsDiscounts}
          />
          <Route
            path={APP_PATHS.REWARDS_LIQUIDATOR}
            component={RewardsLiquidator}
          />
          <Route
            path={APP_PATHS.REWARDS_CLTB}
            component={RewardsCLTB}
          />
          <Route
            path={APP_PATHS.REWARDS_VOTING}
            component={RewardsVoting}
          />
          <Route
            path={APP_PATHS.REWARDS_AGGREGATOR}
            component={RewardsAggregator}
          />
        </Switch>
      </div>
    );
};

export default Rewards;
