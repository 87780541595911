import { useDispatch, useSelector } from 'react-redux'
import {
  getAchItems as _getAchItems,
  linkAch as _linkAch,
  unlinkAch as _unlinkAch,
  relinkAch as _relinkAch,
  getRelinkToken as _getRelinkToken,
  initiateAchDeposit as _initiateAchDeposit,
  initiateAchWithdrawal as _initiateAchWithdrawal,
} from '../ducks/ach'
import { unwrapResult } from '@reduxjs/toolkit'

const useAchDuck = () => {
  const dispatch = useDispatch()
  const { items, relinkTokens } = useSelector((state) => state.ach)

  const linkAch = (publicToken) => dispatch(_linkAch(publicToken))

  const unlinkAch = (itemId) => dispatch(_unlinkAch(itemId))

  const relinkAch = (itemId) => dispatch(_relinkAch(itemId))

  const getRelinkToken = (itemId) => dispatch(_getRelinkToken(itemId))

  const initiateAchDeposit = async (deposit) => {
    try {
      const result = await dispatch(_initiateAchDeposit(deposit))
      unwrapResult(result)
      return
    } catch (e) {
      throw e
    }
  }

  const initiateAchWithdrawal = async (withdrawal) => {
    const result = await dispatch(_initiateAchWithdrawal(withdrawal))
    unwrapResult(result)
    return
  }

  const getAchItems = () => dispatch(_getAchItems())

  return {
    linkAch,
    unlinkAch,
    relinkAch,
    items,
    relinkTokens,
    getRelinkToken,
    initiateAchDeposit,
    initiateAchWithdrawal,
    getAchItems,
  }
}

export default useAchDuck
