import * as React from 'react';
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { APP_PATHS } from "../../../../constants";
import Loading from '../../../shared/Loading';
import LiquidatorOverview from "./LiquidatorOverview";
import { useAppDispatch, useTypedSelector } from "../../../../ducks";
import {
  getHodlUserTotals,
  getLiquidatorEnrollments,
  getLiquidatorEvents,
  getLiquidatorPrograms
} from "../../../../ducks/rewards";
import { isObjectNullOrEmpty } from '../../../../utils/utils';
//import LiquidatorActivity from "./LiquidatorActivity";

const RewardsLiquidator: React.FC = () => {
  const dispatch = useAppDispatch();
  //const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userTotals } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsLoading(isObjectNullOrEmpty(userTotals));
        await Promise.all([
          dispatch(getHodlUserTotals()).unwrap(),
          dispatch(getLiquidatorEnrollments()).unwrap(),
          dispatch(getLiquidatorEvents()).unwrap(),
          dispatch(getLiquidatorPrograms()).unwrap(),
        ]);
      } catch(err) {
        console.log('RewardsLiquidator initialize error', err);
      } finally {
        setIsLoading(false);
      }
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Content Content--liquidator">
      <h2 className="rewards-title">
        Liquidator
      </h2>


      {/*<nav className="rewards-tabs">
        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW}
        >
          Overview
        </Link>*/}

        {/*<Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_LIQUIDATOR_ACTIVITY ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_LIQUIDATOR_ACTIVITY}
        >
          Activity
        </Link>

        <Link
          className={`rewards-tab ${location.pathname === APP_PATHS.REWARDS_LIQUIDATOR_INFO ? 'rewards-tab--active' : ''}`}
          to={APP_PATHS.REWARDS_LIQUIDATOR_INFO}
        >
          Liquidator Info
        </Link>*/}
      {/*</nav>*/}

      {isLoading || isObjectNullOrEmpty(userTotals) ? (
        <Loading />
      ) : (
        <div className="rewards-content">
          <Switch>
            <Route
              path={APP_PATHS.REWARDS_LIQUIDATOR_OVERVIEW}
              component={LiquidatorOverview}
            />

            {/*<Route
              path={APP_PATHS.REWARDS_LIQUIDATOR_ACTIVITY}
              component={LiquidatorActivity}
            />*/}
          </Switch>
        </div>
      )}
    </div>
  );
};

export default RewardsLiquidator;
