import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer className="Footer">
      <nav className="FooterNav u-container">
        <ul>
          <li>
            <a
              href="https://www.coinlion.com/user-agreement/"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterNav-link"
            >
              User Agreement
            </a>
          </li>
          <li>
            <a
              href="https://www.coinlion.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterNav-link"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://help.coinlion.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="FooterNav-link"
            >
              Help
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
