import './rewardsHodlInfo.scss';

import * as React from 'react';

import useDocTitle from '../../../../../hooks/useDocTitle';

import HodlDailyAllocation from "./components/HodlDailyAllocation";

const RewardsHodlInfo: React.FC = () => {
  useDocTitle('HODL Info');

  return (
    <section className="HodlInfo">
      <h3>
        HODL Info
      </h3>

      <HodlDailyAllocation/>
    </section>
  );
};

export default RewardsHodlInfo;
