import React from 'react'

const Image = ({ fill = '#500078', width = '36', height = '36', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 4H4a2 2 0 00-2 2v24a2 2 0 002 2h28a2 2 0 002-2V6a2 2 0 00-2-2zM8.92 14a3 3 0 100-6 3 3 0 000 6zm13.86 1.37l-5.4 5.4-4-4a1 1 0 00-1.41 0L5.92 22.9v2.83l6.79-6.79L16 22.18l-3.75 3.75H15l8.45-8.45L30 24v-2.82l-5.81-5.81a1 1 0 00-1.41 0zm-12.375-4.99A1.6 1.6 0 008.92 9.4v.01A1.6 1.6 0 007.33 11a1.6 1.6 0 103.075-.62zM4 6v24h28V6H4z"
        fill={fill}
      />
    </svg>
  )
}

export default Image
