import auth0 from 'auth0-js'

const v3 = process.env.COINLION_SITENAME === 'platformV3'

export default class Auth {
    auth0 = new auth0.WebAuth({
        domain: window.envConfig.COINLION_AUTH0_DOMAIN,
        clientID: v3
            ? window.envConfig.COINLION_V3_AUTH0_CLIENTID
            : window.envConfig.COINLION_AUTH0_CLIENTID,
        audience: window.envConfig.COINLION_AUTH0_AUDIENCE,
        redirectUri: v3
            ? window.envConfig.COINLION_V3_AUTH0_CALLBACKURL
            : window.envConfig.COINLION_AUTH0_CALLBACKURL,
        scope: window.envConfig.COINLION_AUTH0_SCOPE,
        responseType: 'token id_token',
    })

    constructor() {
        this.signin = this.signin.bind(this)
        this.signout = this.signout.bind(this)
        this.handleAuthentication = this.handleAuthentication.bind(this)
        this.login = this.login.bind(this)
    }

    login() {
        this.auth0.authorize()
    }

    signin(username, password, rememberMe) {
        return new Promise((resolve, reject) => {
            this.auth0.client.login(
                {
                    realm: v3
                        ? window.envConfig.COINLION_V3_AUTH0_REALM
                        : window.envConfig.COINLION_AUTH0_REALM,
                    username,
                    password,
                },
                (err, authResult) => {
                    if (err) {
                        console.log(err)
                        return reject(err)
                    }

                    if (rememberMe === true) {
                        this.setSession(authResult)
                    }

                    return resolve(authResult)
                }
            )
        })
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (
                    authResult &&
                    authResult.accessToken &&
                    authResult.idToken
                ) {
                    this.setSession(authResult)
                    return resolve()
                } else if (err) {
                    console.log(err)
                    return reject(err)
                }
            })
        })
    }

    sendforgotPasswordEmail(email) {
        return new Promise((resolve, reject) => {
            this.auth0.changePassword(
                {
                    connection: v3
                        ? window.envConfig.COINLION_V3_AUTH0_REALM
                        : window.envConfig.COINLION_AUTH0_REALM,
                    email,
                },
                (error, result) => {
                    if (error) {
                        console.log('forgotPassword error', error)
                        return reject(error)
                    }
                    return resolve(result)
                }
            )
        })
    }

    setSession(authResult) {
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        )
        localStorage.setItem('access_token', authResult.accessToken)
        localStorage.setItem('id_token', authResult.idToken)
        localStorage.setItem('expires_at', expiresAt)
    }

    signout() {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
    }

    signup(email, password, metadata) {
        return new Promise((resolve, reject) => {
            this.auth0.signup(
                {
                    connection: v3
                        ? window.envConfig.COINLION_V3_AUTH0_REALM
                        : window.envConfig.COINLION_AUTH0_REALM,
                    email,
                    password,
                    user_metadata: metadata,
                },
                (error, result) => {
                    if (error) {
                        //console.log('signup error', error);
                        return reject(error)
                    }
                    return resolve(result)
                }
            )
        })
    }
}
