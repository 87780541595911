import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { eq, map } from 'lodash'
import './herobanner.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  headline: string
  subnav?: Array<{
    isActive?: (path: string) => boolean
    label: string
    path: string
  }>
}

const HeroBanner: React.FC<Props> = ({
  children,
  className,
  headline,
  subnav,
}) => {
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <div className="HeroBanner">
        <div className={`u-container ${className ? className : ''}`}>
          <h1 className="HeroBanner-headline">{headline}</h1>

          {children}
        </div>
      </div>
      {subnav && (
        <nav className="HeroBannerNav u-container">
          <ul>
            {map(subnav, (item, index) => (
              <li key={index} className="HeroBannerNav-item">
                <Link
                  to={item.path}
                  className={`HeroBannerNav-link ${
                    eq(pathname, item.path) || (item.isActive && item.isActive(pathname)) ? 'HeroBannerNav-link--active' : ''
                  }`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </React.Fragment>
  )
}

export default HeroBanner;
