import './toggle.scss';

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { isNil } from "lodash";

const { useMemo } = React;

type Props = {
  disabled?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  value: boolean
}

const Toggle: React.FC<Props> = ({
  value,
  handleChange,
  disabled,
  label,
}) => {
  const id = useMemo(() => `toggle-${uuid()}`, []);

  return (
    <label
      className={`Toggle ${disabled ? 'Toggle-disabled' : ''}`}
      htmlFor={id}
    >
      <input
        type="checkbox"
        id={id}
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <div className="slider"></div>

      {!isNil(label) && <div className="Toggle-label">{label}</div>}
    </label>
  )
}

export default Toggle;
