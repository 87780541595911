import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNil } from "lodash";

const useMayVisitDashboard = () => {
  const [mayVisitDashboard, setMayVisitDashboard] = useState(false)
  const { country, kycVerificationStatus, state } = useSelector((state) => state.profile.data);

  useEffect(() => {
    setMayVisitDashboard(kycVerificationStatus >= 2 && !isNil(country) && !isNil(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, kycVerificationStatus, state])

  return mayVisitDashboard
}

export default useMayVisitDashboard
