import './ActivityIndicator.scss';

import React from 'react';

export default class ActivityIndicator extends React.PureComponent {
    render() {
        return (
            <div className="c-activity-indicator">
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid" className="lds-infinity">
                    <path d="M24.3,30C11.4,30,5,43.3,5,50s6.4,20,19.3,20c19.3,0,32.1-40,51.4-40 C88.6,30,95,43.3,95,50s-6.4,20-19.3,20C56.4,70,43.6,30,24.3,30z"
                          stroke="#3be8b0" strokeWidth="2" strokeDasharray="128.29446411132812 128.29446411132812" fillOpacity="0">
                        <animate attributeName="stroke-dashoffset" calcMode="linear" values="0;256.58892822265625"
                                 keyTimes="0;1" dur="0.8" begin="0s" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        );
    }
}
