import './onboardinginfo.scss';

import React, {useState} from 'react';
import { useHistory } from "react-router-dom";

import {APP_PATHS, PROFILE_TYPES} from "../../constants";

import useModal from "../../hooks/useModal";

import Button from '@pv3-shared-components/Button';
import Modal from '@pv3-shared-components/Modal'
import Sumsub from "../shared/Sumsub";
import useProfileDuck from "../../hooks/useProfileDuck";

const OnboardingInfo = () => {
  const history = useHistory();
  const modal = useModal();

  const { getProfile, profile } = useProfileDuck()

  const [title, setTitle] = useState('');
  const [result, setResult] = useState('Redirecting to dashboard');

  return (
    <div className="onboarding-info">
      <div className="SinglePanel-panel">
        <Sumsub
          onFailure={(message) => {
            setTitle('There was an issue');
            setResult(message || 'We were unable to verify your information. You will now be redirected to the dashboard with limited deposit/withdraw functionality. To correct, visit the Account page or contact support.');
            modal.setShow(true);
          }}
          onPending={() => {
            setTitle('Verification is pending');
            setResult(
              profile.profileType === PROFILE_TYPES.BUSINESS
                ? 'Thank you for submitting your information. The verification process can take up to a full business day. You will now be redirected to the dashboard with limited deposit/withdraw functionality.'
                : 'We are still processing the information you submitted. You will now be redirected to the dashboard with limited deposit/withdraw functionality.'
            );
            modal.setShow(true);
          }}
          onSuccess={() => {
            setTitle('Success!');
            setResult('You have been successfully verified and will now be redirected to our dashboard.');
            modal.setShow(true);
          }}
        />
      </div>

      <Modal
        modal={modal}
        screens={{
          MAIN: {
            heading: () => <h4>{title}</h4>,
            body: () => (
              <React.Fragment>
                <p>
                  {result}
                </p>
                <Button
                  className="sumsub-results-button"
                  variant="primary"
                  size="large"
                  type="button"
                  onClick={() => {
                    modal.setShow(false);
                    history.push(APP_PATHS.BASE)
                    getProfile();
                  }}
                >
                  OK
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />
    </div>
  );
};

export default OnboardingInfo;
