import './addLionToHodl.scss';

import * as React from 'react';
import { useMemo, useState } from 'react';
import { useForm } from "react-hook-form";

import { fixFloatingPointErrors, isArrayNullOrEmpty, isObjectNullOrEmpty } from '../../../../../../../utils/utils';

import {
  claimHodlEnrollment,
  enrollInHodl,
  getHodlGlobalTotals,
  getHodlUserTotals
} from "../../../../../../../ducks/rewards";
import { useAppDispatch, useTypedSelector } from "../../../../../../../ducks";

import Button, { ButtonSizes, ButtonTypes, ButtonVariants } from "../../../../../../shared/Button";
import Input, { InputTypes } from "../../../../../../shared/Input";
import InputError from '../../../../../../shared/InputError';
import RadioGroup, { RadioOption } from "../../../../../../shared/RadioGroup";
import Modal from '../../../../../../shared/Modal';
import useModal from "../../../../../../../hooks/useModal";
import Tooltip from '../../../../../../shared/Tooltip';

type FormData = {
  program: string
  amount: number
}

const AddLionToHodl: React.FC = () => {
  const dispatch = useAppDispatch();
  const form = useForm<FormData>();
  const { formState: { errors }, handleSubmit, register, setValue, trigger } = form;

  const modal = useModal();

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isClaimingAll, setIsClaimingAll] = useState<boolean>(false);
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);

  const { enrollments, programs, userTotals } = useTypedSelector((state) => state.rewards);
  const availableLionBalance = !isObjectNullOrEmpty(userTotals) ? userTotals.totalLionBalance - userTotals.lionHodlLocked - userTotals.lionInOpenOrders : 0;

  const durationOptions: Array<RadioOption> = useMemo(
    () => {
      if(isArrayNullOrEmpty(programs)) {
        return [];
      }

      return programs.map((program) => ({
        label: `${program.value1}M`,
        value: program.id,
      }));
    }, [programs]
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsEnrolling(true);
      setErrorMsg('');
      let enrollData = {
        lionQty: data.amount,
        programId: data.program,
      };

      await dispatch(enrollInHodl(enrollData)).unwrap();
      dispatch(getHodlUserTotals());
      setValue('amount', undefined);
      setValue('program', undefined)
    } catch(err) {
      console.log('AddLionToHodl onSubmit error', err);
      setErrorMsg('There was an error attempting to enroll in HODL. Please try again.');
    } finally {
      setIsEnrolling(false);
    }
  });

  const handleClaimAllPress = async () => {
    try {
      setIsClaimingAll(true);
      await Promise.all(
        matureEnrollments.map((enrollment) => dispatch(claimHodlEnrollment({ enrollmentId: enrollment.id })).unwrap())
      );
      await dispatch(getHodlUserTotals()).unwrap();
      await dispatch(getHodlGlobalTotals()).unwrap();
    } catch(err) {
      console.log('AddLionToHodl handleClaimAllPress error', err);
    } finally {
      setIsClaimingAll(false);
    }
  };

  const handleMaxPress = () => {
    if(availableLionBalance === 0) {
      modal.setShow(true);
      return;
    }

    setValue('amount', fixFloatingPointErrors(availableLionBalance, 4));
    trigger('amount');
  };

  const { matureEnrollments, totalMatureRewards } = useMemo(
    () => {
      if(isArrayNullOrEmpty(enrollments)) {
        return {
          matureEnrollments: [],
          totalMatureRewards: 0,
        };
      }

      const matureEnrollments = enrollments.filter((enrollment) => new Date() > new Date(enrollment.matureDate) && enrollment.isActive);

      let totalMatureRewards = 0;

      matureEnrollments.forEach((enrollment) => {
        totalMatureRewards += enrollment.totalRewardsLion;
      });

      return {
        matureEnrollments,
        totalMatureRewards,
      };
    }, [enrollments]
  );

  return (
    <section className="AddLionToHodl">
      <h4>
        Add LION To HODL
      </h4>

      <p>
        Select HODL Program Duration
      </p>

      <div className="AddLionToHodl-form">
        <form
          onSubmit={onSubmit}
        >
          <div className="AddLionToHodl-form-row">
            <div className="Label">
              Duration
            </div>

            <RadioGroup
              error={
                errors.program && <InputError message={errors.program.message} />
              }
              name="program"
              options={durationOptions}
              register={register}
              registerConfig={{
                required: 'Duration is required',
              }}
            />
          </div>

          <div className="AddLionToHodl-form-row">
            <Input
              inputType={InputTypes.Number}
              register={{
                ...register('amount', {
                  required: 'Amount is required',
                  validate: {
                    min: (value) => value > 0 || 'Amount must be greater than 0',
                  },
                }),
              }}
              error={
                errors.amount && <InputError message={errors.amount.message} />
              }
              step="0.0001"
            >
              Amount LION
            </Input>

            <Button
              className="AddLionToHodl-maxButton"
              onClick={handleMaxPress}
              type={ButtonTypes.Button}
              variant={ButtonVariants.Blank}
            >
              Max
            </Button>
          </div>

          <Button
            className="AddLionToHodl-submitButton"
            isLoading={isEnrolling}
            variant={ButtonVariants.Secondary}
          >
            Add
          </Button>
        </form>

        <InputError message={errorMsg} />
      </div>

      {!isArrayNullOrEmpty(matureEnrollments) && (
        <div className="AddLionToHodl-Claim">
          <div className="AddLionToHodl-ClaimContent">
            <h4>
              {totalMatureRewards.toLocaleString()} LION
            </h4>

            <div className="AddLionToHodl-ClaimLabel">
              Pending rewards

              <Tooltip>
                The total amount of LION under HODL
              </Tooltip>
            </div>
          </div>

          <Button
            className="AddLionToHodl-ClaimButton"
            isLoading={isClaimingAll}
            onClick={handleClaimAllPress}
            type={ButtonTypes.Button}
          >
            Claim All
          </Button>
        </div>
      )}

      <Modal
        className="HodlList-modal"
        modal={modal}
        screens={{
          MAIN: {
            heading: () => <h4>No Available LION</h4>,
            body: () => (
              <React.Fragment>
                <p>
                  You don't have any available LION. Buy LION to enroll in HODL.
                </p>

                <Button
                  className="HodlList-modal-button"
                  onClick={() => modal.setShow(false)}
                  size={ButtonSizes.Large}
                  type={ButtonTypes.Button}
                  variant={ButtonVariants.Primary}
                >
                  OK
                </Button>
              </React.Fragment>
            ),
          },
        }}
      />
    </section>
  );
};

export default AddLionToHodl;
