import React, {useEffect, useRef} from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { APP_PATHS } from '@pv3-constants'
import Marketplace from '@pv3-components/Marketplace'
import Activity from '@pv3-components/Activity'
import Portfolio from '@pv3-components/Portfolio'
import Account from '@pv3-components/Account'
import FourZeroFour from '@pv3-components/FourZeroFour'
import useUiDuck from '../../hooks/useUiDuck'
import { isNull, eq, get, gt, includes } from 'lodash'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import useProfileDuck from '../../hooks/useProfileDuck'
import useStrategiesDuck from '../../hooks/useStrategiesDuck'
import useAssetsDuck from '../../hooks/useAssetsDuck'
import { useSelector } from 'react-redux'
import { setCtaCardsInitiated, setInitialLoadComplete } from '../../ducks/ui'
import useMarketplaceDuck from '../../hooks/useMarketplaceDuck'
import useTradesDuck from '../../hooks/useTradesDuck'
import Strategy from "../Strategy";
import {useHistory} from "react-router";
import Rewards from '../Rewards';
import { getHodlUserTotals, getReferredBy, saveReferralCode } from '../../ducks/rewards';
import { isObjectNullOrEmpty } from '../../utils/utils';
import { useAppDispatch } from '../../ducks';

const Main = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const locationState = get(history, 'location.state')
  const { getRoi, getStatePermissions } = useProfileDuck()
  const { strategies, getStrategies, getSupportedIntervals, getDashboardStats, getBuilder } =
    useStrategiesDuck()
  const { fetchAssetsInfo } = useAssetsDuck()
  const { getAccountBalance, getBuySellPrices, getUsdPrices, getVolumes, totalValueInUSD, subscribeToAccountBalances } =
    useWalletsDuck()
  const { setShowCtaCard } = useUiDuck()
  const { getPublishers, getSubscriptions, subscriptions } = useMarketplaceDuck()
  const { pathname } = useLocation()
  const { getLionSellLimit, getOpenPositions } = useTradesDuck()
  const { profile } = useProfileDuck();

  let accountBalanceTimerRef = useRef(null);
  let hodlUserTotalsTimerRef = useRef(null);
  let openPositionsTimerRef = useRef(null);

  const initialLoadComplete = useSelector(
    (state) => state.ui.initialLoadComplete
  )
  const ctaCardsInitiated = useSelector((state) => state.ui.ctaCardsInitiated)
  const { referredBy } = useSelector((state) => state.rewards);

  //useEstablishPermission()

  const getAccountBalanceOnInterval = async () => {
    try {
      await getAccountBalance();
    } catch(err) {
      console.log('Main getAccountBalanceOnInterval err', err);
    } finally {
      openPositionsTimerRef.current = setTimeout(getAccountBalanceOnInterval, 120000);
    }
  };

  const getHodlUserTotalsOnInterval = async () => {
    try {
      await dispatch(getHodlUserTotals()).unwrap();
    } catch(err) {
      console.log('Main getHodlUserTotalsOnInterval err', err);
    } finally {
      hodlUserTotalsTimerRef.current = setTimeout(getOpenPositionsOnInterval, 120000);
    }
  };

  const getOpenPositionsOnInterval = async () => {
    try {
      await getOpenPositions(true);
    } catch(err) {
      console.log('Main getOpenPositionsOnInterval err', err);
    } finally {
      openPositionsTimerRef.current = setTimeout(getOpenPositionsOnInterval, 5000);
    }
  };

  useEffect(() => {
    if (initialLoadComplete) return
    fetchAssetsInfo()
    getAccountBalance()
    getUsdPrices()
    getVolumes()
    getBuySellPrices()
    setTimeout(() => getStrategies(), locationState && locationState.scrollToStrategies ? 500 : 0);
    getStatePermissions()
    getDashboardStats()
    getBuilder()
    getRoi()
    getSubscriptions()
    dispatch(setInitialLoadComplete())
    getOpenPositions()
    subscribeToAccountBalances()
    getSupportedIntervals();
    getPublishers();
    dispatch(getReferredBy());
    dispatch(getHodlUserTotals());

    setTimeout(() => getLionSellLimit(), 10000);

    accountBalanceTimerRef.current = setTimeout(getAccountBalanceOnInterval, 120000);
    hodlUserTotalsTimerRef.current = setTimeout(getHodlUserTotalsOnInterval, 120000);
    openPositionsTimerRef.current = setTimeout(getOpenPositionsOnInterval, 15000);

    const referralCode = localStorage.getItem('referralCode');

    if(referralCode && new Date(profile.createdAt) >= new Date('2023-12-28')) {
      dispatch(saveReferralCode({ referralCode }));
    }

    return () => {
      clearTimeout(openPositionsTimerRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isNull(totalValueInUSD) || isNull(strategies) || isNull(subscriptions))
      return

    const dismissedDashboardCtas = JSON.parse(
      localStorage.getItem('dismissedDashboardCtas')
    )

    if (
      gt(totalValueInUSD, 0) ||
      includes(dismissedDashboardCtas, 'FUND_YOUR_ACCOUNT')
    )
      setShowCtaCard({ card: 'FUND_YOUR_ACCOUNT', show: false })

    if (
      gt(strategies.length, 0) ||
      gt(subscriptions.length, 0) ||
      includes(dismissedDashboardCtas, 'START_COPYCAT_TRADING')
    )
      setShowCtaCard({ card: 'START_COPYCAT_TRADING', show: false })

    if (ctaCardsInitiated) return

    if (
      eq(totalValueInUSD, 0) &&
      !includes(dismissedDashboardCtas, 'FUND_YOUR_ACCOUNT')
    )
      setShowCtaCard({ card: 'FUND_YOUR_ACCOUNT', show: true })

    if (
      eq(strategies.length, 0) &&
      eq(subscriptions.length, 0) &&
      !includes(dismissedDashboardCtas, 'START_COPYCAT_TRADING')
    )
      setShowCtaCard({ card: 'START_COPYCAT_TRADING', show: true })

    if (
      isObjectNullOrEmpty(referredBy) &&
      !includes(dismissedDashboardCtas, 'REFERRAL_CODE') &&
      new Date(profile.createdAt) >= new Date('2023-12-28')
    ) {
      setShowCtaCard({card: 'REFERRAL_CODE', show: true})
    }

    dispatch(setCtaCardsInitiated())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referredBy, totalValueInUSD, strategies, subscriptions])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <main className="Main">
      <Switch>
        <Route path={APP_PATHS.STRATEGIES_NEW} component={Strategy} />
        <Route path={APP_PATHS.STRATEGIES_NEW_BACKTEST} component={Strategy} />
        <Route path={APP_PATHS.STRATEGY} component={Strategy} />
        <Route path={APP_PATHS.MARKETPLACE} component={Marketplace} />
        <Route path={APP_PATHS.ACTIVITY} component={Activity} />
        <Route path={APP_PATHS.REWARDS} component={Rewards} />
        <Route path={APP_PATHS.ACCOUNT} component={Account} />
        <Route path={APP_PATHS.BASE} component={Portfolio} exact />
        <Route path="*" component={FourZeroFour} />
      </Switch>
    </main>
  )
}

export default Main
