import './rewardsHodlActivity.scss';

import * as React from 'react';

import useDocTitle from "../../../../../hooks/useDocTitle";

import HodlHistory from "./components/HodlHistory";

const RewardsHodlActivity: React.FC = () => {
  useDocTitle('HODL Activity');

  return (
    <div className="Hodl-Content Hodl-Content--activity">
      <h3 className="rewards-title">
        Activity
      </h3>

      <HodlHistory />
    </div>
  );
};

export default RewardsHodlActivity;
