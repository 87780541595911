import React from 'react'

const Checkmark = ({ fill = '#fff', width = '32', height = '32', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16c0 8.837 7.163 16 16 16s16-7.163 16-16A16 16 0 0016 0C7.163 0 0 7.163 0 16zm25.64-5.547c.736.737.736 1.93 0 2.667L13.933 24.813l-7.266-7.266a1.886 1.886 0 012.666-2.667l4.614 4.6 9.026-9.027a1.886 1.886 0 012.667 0z"
        fill={fill}
        fillOpacity=".87"
      />
    </svg>
  )
}

export default Checkmark
