import React, {useState, useEffect, useRef} from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
  eq,
  get,
  isNil,
  isUndefined,
  find,
  isEmpty,
} from 'lodash'
import useStrategiesDuck from '@pv3-hooks/useStrategiesDuck'
import Button from '@pv3-shared-components/Button'
import MyPortfolioCard from '@pv3-components/MyPortfolioCard'
import StrategyThirds from '@pv3-components/StrategyThirds'
import StrategySignalSettings from '@pv3-components/StrategySignalSettings'
import { FormProvider, useForm } from 'react-hook-form'
import './../CreateStrategy/strategy.scss'
import { APP_PATHS } from '@pv3-constants'
import {formatISO} from 'date-fns'
import useModal from '../../hooks/useModal'
import useDocTitle from '@pv3-hooks/useDocTitle'
import {LOADING_STATES, TRIGGER_SETTINGS_ACCESS_STRINGS} from "../../constants";
import useUiDuck from "../../hooks/useUiDuck";
import clone from 'clone';
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";
import CreateAPIKeyModal from '../shared/CreateAPIKeyModal';

const StrategyDetails = () => {
  const history = useHistory()
  const { strategyId } = useParams()
  const [strategy, setStrategy] = useState(null)
  const [initialized, setInitialized] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState();
  const { apiKey, clearBacktestData, clearBacktestError, clearDynamicValuesSettings, clearSaveStrategyErrors, dynamicValuesSettings, getApiKey, getDynamicValuesForIndicatorSettings, getStrategies, isGettingApiKey, numerifyStrategySettings, strategies, supportedIntervals, updateStrategy, getIndicators, indicators, setTemporaryStrategySettings, temporaryStrategySettings, testSettingsForDynamicValues } = useStrategiesDuck()
  const { loading, setLoading } = useUiDuck();
  const modal = useModal({
    onClose: () => formMethods.clearErrors(['noBuyTrigger']),
  })
  const apiKeyModal = useModal();

  const dynamicVariableDebounce = useRef(null);
  const regetDebounce = useRef(null);
  let initializedCounter = useRef(0);

  const formMethods = useForm({
    defaultValues: {},
  })
  const { getValues, handleSubmit, reset, watch } = formMethods

  const noBuyTriggerError = get(formMethods, 'formState.errors.noBuyTrigger')

  const handleFormSubmit = async (values) => {
    try {
      clearTimeout(regetDebounce.current);
      setSaveErrorMessage(undefined);
      const buyTriggers = get(
        values,
        'buyIndicatorTriggerSettings.triggerIndicatorSettings'
      )

      if (isNil(buyTriggers) || eq(buyTriggers.length, 0)) {
        values.isBuyActive = false;
      }

      values = numerifyStrategySettings(values);
      values.sellProfitPercent = values.sellProfitPercent || 0;
      values.stopLoss = values.stopLoss || 0;

      await updateStrategy({
        id: strategyId,
        updated: formatISO(new Date()),
        ...values,
      }).unwrap();

      getStrategies();
      history.push(APP_PATHS.BASE, {scrollToStrategies: true});
    } catch(err) {
      if(typeof err === 'string') {
        setSaveErrorMessage(err);
      } else {
        if(isArrayNullOrEmpty(err)) {
          setSaveErrorMessage('There was an error saving your strategy. Please try again.');
        } else {
          setSaveErrorMessage('There are invalid values in your strategy settings. Please see below.');
        }
      }
    }
  }

  const handleBuildDynamicValuesSettings = async (strategySettings) => {
    let builtSettings = [];

    if(!strategySettings) {
      strategySettings = getValues();
    }

    TRIGGER_SETTINGS_ACCESS_STRINGS.forEach((ss) => {
      let settings = get(strategySettings, ss);

      if(!isNil(settings)) {
        settings.forEach((s, i) => {
          let testResults = testSettingsForDynamicValues(s, `${ss}[${i}]`, strategySettings);


          if(testResults) {
            builtSettings.push(testResults);
          }
        });
      }
    });

    try {
      getDynamicValuesForIndicatorSettings(builtSettings);
    } catch(err) {
      console.log('StrategyDetails handleBuildDynamicValuesSettings err', err);
    }
  };

  const initializeApiKey = async () => {
    try {
      if(!apiKey && !loading[LOADING_STATES.FETCH_API_KEY] && !isGettingApiKey) {
        setLoading({context: LOADING_STATES.FETCH_API_KEY, isLoading: true});
        let apiKeyRes = await getApiKey();
        if(!apiKeyRes || !apiKeyRes.payload) {
          apiKeyModal.setShow(true);
        }
      }
    } catch(err) {
      console.log('StrategySignalWebhookBody initialize err', err);
    } finally {
      setLoading({context: LOADING_STATES.FETCH_API_KEY, isLoading: false});
    }
  }

  const strategyHasWebhook = (strategyToTest) => {
    let triggers = [
      ...strategyToTest.orderSizeIndicatorTriggerSettings.triggerIndicatorSettings || [],
      ...strategyToTest.buyIndicatorTriggerSettings.triggerIndicatorSettings || [],
      ...strategyToTest.buyIndicatorManagementSettings.disableIndicatorSettings || [],
      ...strategyToTest.buyIndicatorManagementSettings.enableIndicatorSettings || [],
      ...strategyToTest.sellIndicatorTriggerSettings.triggerIndicatorSettings || [],
      ...strategyToTest.sellIndicatorManagementSettings.disableIndicatorSettings || [],
      ...strategyToTest.sellIndicatorManagementSettings.enableIndicatorSettings || [],
      ...strategyToTest.stopLossIndicatorTriggerSettings.triggerIndicatorSettings || [],
    ];

    return find(triggers, (t) => eq(t.indicator, 'Webhook')) != null;
  }

  useEffect(() => {
    clearTimeout(regetDebounce.current);

    if(dynamicValuesSettings?.find((d) => d.hasValue === false)) {
      regetDebounce.current = setTimeout(handleBuildDynamicValuesSettings, 5000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicValuesSettings]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if(!isObjectNullOrEmpty(value)) {
        if(initialized && isNil(type)) {
          //this is adding a row, so we need to clear the dynamic values so they dont display in the wrong place for a short time.
          clearDynamicValuesSettings();
        }

        clearTimeout(dynamicVariableDebounce.current);

        if(!initialized && TRIGGER_SETTINGS_ACCESS_STRINGS.includes(name) && isNil(type)) {
          initializedCounter.current++;

          if(initializedCounter.current === TRIGGER_SETTINGS_ACCESS_STRINGS.length) {
            setInitialized(true);
          }
        }

        let clonedValue = clone(value);
        clonedValue = {
          ...strategy,
          ...clonedValue,
        };
        setTemporaryStrategySettings(clonedValue);

        if(!isNil(type)) {
          clearBacktestData({preserveStartEndDates: true});
          clearBacktestError();
        }

        dynamicVariableDebounce.current = setTimeout(() => handleBuildDynamicValuesSettings(clonedValue),  250);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, watch]);

  useEffect(() => {
    if (isEmpty(indicators)) {
      getIndicators();
    }
    return () => {
      clearDynamicValuesSettings();
      clearSaveStrategyErrors();
      clearTimeout(dynamicVariableDebounce.current);
      clearTimeout(regetDebounce.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUndefined(noBuyTriggerError))
      return
    modal.setShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noBuyTriggerError])

  useEffect(() => {
    if (isNil(strategies)) return

    if(!isObjectNullOrEmpty(temporaryStrategySettings) && temporaryStrategySettings.id === strategyId) {
      setStrategy(temporaryStrategySettings);
      handleBuildDynamicValuesSettings(temporaryStrategySettings);
      //handleGetDynamicValuesForIndicatorSettings(temporaryStrategySettings);
    } else {
      let foundStrategy = find(strategies, { id: strategyId });
      setStrategy(foundStrategy);
      clearBacktestData();
      handleBuildDynamicValuesSettings(foundStrategy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategies, indicators])

  useEffect(() => {
    if (isNil(history.location.state)) return
    if (history.location.state.viewingNewlyCopiedStrategy) {
      window.alert('You are now editing the newly copied strategy.')
      history.replace({ ...history.location, state: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isNil(strategy) || isNil(supportedIntervals)) return

    const resetValues = {
      name: strategy.name,
      notes: strategy.notes,
      baseAsset: strategy.baseAsset,
      isBuyActive: strategy.isBuyActive,
      isSellActive: strategy.isSellActive,
      isPublic: strategy.isPublic,
      maxOpenPositions: strategy.maxOpenPositions,
      sellProfitPercent: strategy.sellProfitPercent || 0,
      stopLoss: strategy.stopLoss || 0,
      icebergPercentage: strategy.icebergPercentage,
      afterOpenMinutes: strategy.afterOpenMinutes,
      afterClosePreventOpenMinutes: strategy.afterClosePreventOpenMinutes,
      afterOpenPreventCloseMinutes: strategy.afterOpenPreventCloseMinutes,
      afterStopLossPreventOpenMinutes: strategy.afterStopLossPreventOpenMinutes,
      disableBuyAfterBuy: strategy.disableBuyAfterBuy,
      disableBuyAfterSell: strategy.disableBuyAfterSell,
      disableBuyAfterStopLoss: strategy.disableBuyAfterStopLoss,
      holdingPercent: strategy.holdingPercent,
      buyOrderType: strategy.buyOrderType,
      sellOrderType: strategy.sellOrderType,
      stopLossOrderType: strategy.stopLossOrderType,
      orderSizeIndicatorTriggerSettings: {
        triggerIndicatorQuantity:
        strategy.orderSizeIndicatorTriggerSettings.triggerIndicatorQuantity,
        triggerIndicatorSettings:
        strategy.orderSizeIndicatorTriggerSettings.triggerIndicatorSettings,
      },
      buyIndicatorTriggerSettings: {
        triggerIndicatorQuantity:
          strategy.buyIndicatorTriggerSettings.triggerIndicatorQuantity,
        triggerIndicatorSettings:
          strategy.buyIndicatorTriggerSettings.triggerIndicatorSettings,
      },
      sellIndicatorTriggerSettings: {
        triggerIndicatorQuantity:
        strategy.sellIndicatorTriggerSettings.triggerIndicatorQuantity,
        triggerIndicatorSettings:
          strategy.sellIndicatorTriggerSettings.triggerIndicatorSettings,
      },
      stopLossIndicatorTriggerSettings: {
        triggerIndicatorQuantity:
        strategy.stopLossIndicatorTriggerSettings.triggerIndicatorQuantity,
        triggerIndicatorSettings:
          strategy.stopLossIndicatorTriggerSettings.triggerIndicatorSettings,
      },
      buyIndicatorManagementSettings: {
        enableIndicatorQuantity:
          strategy.buyIndicatorManagementSettings.enableIndicatorQuantity,
        enableIndicatorSettings:
          strategy.buyIndicatorManagementSettings.enableIndicatorSettings,
        disableIndicatorQuantity:
          strategy.buyIndicatorManagementSettings.disableIndicatorQuantity,
        disableIndicatorSettings:
          strategy.buyIndicatorManagementSettings.disableIndicatorSettings,
      },
      sellIndicatorManagementSettings: {
        enableIndicatorQuantity:
          strategy.sellIndicatorManagementSettings.enableIndicatorQuantity,
        enableIndicatorSettings:
          strategy.sellIndicatorManagementSettings.enableIndicatorSettings,
        disableIndicatorQuantity:
          strategy.sellIndicatorManagementSettings.disableIndicatorQuantity,
        disableIndicatorSettings:
          strategy.sellIndicatorManagementSettings.disableIndicatorSettings,
      },
    }

    reset(resetValues)

    if(strategyHasWebhook(strategy)) {
      initializeApiKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, supportedIntervals])

  useDocTitle('Edit Strategy')

  return (
    <div className="StrategyDetails">
      <CreateAPIKeyModal modal={apiKeyModal} />

      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="u-screenBottomPadding"
        >
          <div className="u-container Strategy-content">
            <div className="Strategy-top">
              <h1 className="Strategy-heading">Edit Strategy</h1>
              <div className="Strategy-actions">
                <div className="Strategy-actions">
                  <div />

                  <Link
                    onClick={() => setTemporaryStrategySettings({})}
                    to={APP_PATHS.BASE}
                    className="Button Button--secondary Button--small"
                    data-testid="discard-strategy-btn"
                  >
                    Discard changes
                  </Link>
                  <Button size="small" type="submit" data-testid="save-strategy-btn">
                    Save strategy
                  </Button>
                </div>
              </div>
            </div>

            {!isNil(saveErrorMessage) && (
              <div className="Strategy-saveError">
                {saveErrorMessage}
              </div>
            )}
            <MyPortfolioCard isDeleted={strategy?.isDeleted} isEditing={true} isPublic={strategy ? strategy.isPublic : ''} />

            <StrategyThirds strategyId={strategy?.id} />
          </div>
          <hr className="StrategySignals-divider" />
          <StrategySignalSettings
            initializeApiKey={initializeApiKey}
            isEditing={true}
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default StrategyDetails
