import './liquidatorOverview.scss';

import * as React from 'react';
import { useEffect, useState } from 'react';

import useDocTitle from "../../../../../hooks/useDocTitle";
import { useAppDispatch, useTypedSelector } from "../../../../../ducks";

import LiquidatorForm from "./components/LiquidatorForm";
import Toggle from "../../../../shared/Toggle";
import Tooltip from '../../../../shared/Tooltip';
import { getLiquidatorEnrollments, updateLiquidatorEnrollment } from "../../../../../ducks/rewards";

const LiquidatorOverview: React.FC = () => {
  const dispatch = useAppDispatch();
  useDocTitle('Liquidator Overview');

  const [active, setActive] = useState<boolean>(false);

  const { liquidatorEnrollment, userTotals } = useTypedSelector((state) => state.rewards);

  useEffect(() => {
    if(liquidatorEnrollment?.id) {
      setActive(liquidatorEnrollment.isActive);
    } else {
      setActive(true);
    }
  }, [liquidatorEnrollment]);

  const handleIsActiveChange = async () => {
    const isActive = !active;
    try {
      setActive(isActive);
      await dispatch(updateLiquidatorEnrollment({
        enrollmentId: liquidatorEnrollment.id,
        lionQty: liquidatorEnrollment.totalLion,
        programId: liquidatorEnrollment.programId,
        sellMinimum: liquidatorEnrollment.sellMinimum,
        isActive,
      })).unwrap();
      dispatch(getLiquidatorEnrollments()).unwrap();
    } catch(err) {
      console.log('LiquidatorOverview handleIsActiveChange error', err);
      setActive(!isActive);
    }
  };

  return (
    <div className="Liquidator-Content Liquidator-Content--overview">
      <div className="Liquidator">

        <Toggle
          disabled={!liquidatorEnrollment?.id}
          handleChange={handleIsActiveChange}
          label="Active"
          value={active}
        />

        <p></p>

        <div className="Liquidator-Forms">
          <LiquidatorForm />
        </div>

        <div className="Liquidator-Description">
          <p>
            The Coin Lion token currently has limited liquidity and the intent behind the Liquidator is to assist in selling your LION in a fashion that is beneficial to yourself and all other users who desire to sell their LION.  If a user chooses to sell 100,000 LION this can often clog the book and other users just place smaller orders below it which doesn’t allow a user to sell off larger quantities of their LION.
          </p>

          <p>
            The Liquidator is ultimately going to place small orders at the bottom of the book and then place additional orders, increasing in size, up the book.  “Bottom of the book” doesn’t necessarily mean that it will always be the absolute lowest priced order.  If there is a super small order that is priced extremely low then the liquidator will ignore those small orders.   Depending on how much LION you are trying to liquidate it could be 5-8 additional orders up the book or it could be 20-30 orders.
          </p>

          <p>
            The Liquidator has 3 different modes:  Aggressive, Moderate, and Passive.
          </p>

          <p>
            When you choose Aggressive it means that it will be the most aggressively priced order by the Liquidator.  It will then place additional orders up the book increasing the price by 3.5% - 4.5%.  This mode will sell your LION faster than if you choose the other modes.
          </p>

          <p>
            When you choose Moderate  it means that it will be priced above the Aggressive Liquidator orders.  It will then place additional orders up the book increasing the price by 7% - 9%.
          </p>

          <p>
            When you choose Passive it means that it will be the most profitable priced order by the Liquidator.  It will then place additional orders up the book increasing the price by 9% - 10%.  If you are not in a hurry but would love to see some of your LION liquidated with time in the most profitable way; this is definitely the mode to use!
          </p>

          <p>
            Each of these modes also have an order size limit that is adjusted based upon how much movement is happening in the book.  As Liquidity increases; the order sizes increase.
          </p>

          <p>
            It should also be noted that placing manual sell orders for LION and also using the Liquidator are not encouraged.  It’s wise to just leverage the Liquidator or to just place manual orders.
          </p>
        </div>

        <div className="Liquidator-Avoid">
          <h5>
            HODL. Don't Sell.
          </h5>

          <div className="Liquidator-AvoidContent">
            <p>
              Some of your LION is currently in the HODL program. To maximize your HODL rewards, keep your trailing 12-month of LION sold below 10% of your total LION balance. You are currently at {((userTotals.lionSoldLast12Months / userTotals.totalLionBalance) * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2})}%.
            </p>

            <div className="Liquidator-AvoidValue">
              {userTotals.lionSoldLast12Months.toLocaleString()} LION
            </div>

            <div className="Liquidator-AvoidLabel">
              Impact Level

              <Tooltip>
                The total amount of LION under HODL
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiquidatorOverview;
