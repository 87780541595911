import React, {useEffect, useRef} from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { get } from 'lodash';
import './sumsub.scss';

import useProfileDuck from '@pv3-hooks/useProfileDuck';
import ActivityIndicator from "../ActivityIndicator";
import {KYC_VERIFICATION_LEVELS} from "../../../constants";

const Sumsub = ({levelName, onApplicantLoaded, onFailure, onPending, onSuccess}) => {
  const { clearSumsubToken, getSumsubToken, profile, sumsubToken } = useProfileDuck();

  let modalTimer = useRef().current;

  useEffect(() => {
    return () => {
      clearTimeout(modalTimer);
      clearSumsubToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleExpiration = async () => {
    let res = await getSumsubToken({levelName});
    return res.payload.token;
  };

  const handleMessage = (data, payload) => {
    console.log('handleMessage', data, payload);

    if(data === 'idCheck.applicantStatus') {
      switch(get(payload, 'reviewStatus')) {
        case 'completed':
        case 'onHold':
          clearTimeout(modalTimer);
          if(get(payload, 'reviewResult.reviewAnswer') === 'GREEN') {
            modalTimer = setTimeout(onSuccess, 500);
          } else if(get(payload, 'reviewResult.reviewRejectType') !== 'RETRY') {
            modalTimer = setTimeout(() => onFailure(get(payload, 'reviewResult.moderationComment')), 500);
          }
          break;

        case 'pending':
        case 'prechecked':
        case 'queued':
          clearTimeout(modalTimer);
          modalTimer = setTimeout(onPending, 500);
          break;

        default:
          return;
      }
    } else if(data === 'idCheck.actionCompleted') {
      // most likely start pending timer here?

      if(payload.applicantActionId) {
        onApplicantLoaded(payload.applicantActionId);
      }

      if(get(payload, 'answer')) {
        clearTimeout(modalTimer);
        onSuccess(get(payload, 'answer'));
      }
    } else if(data === 'idCheck.livenessCompleted' && levelName === KYC_VERIFICATION_LEVELS.FACIAL_VERIFICATION) {
      if(get(payload, 'answer')) {
        clearTimeout(modalTimer);
        onSuccess(get(payload, 'answer'));
      }
    }
  };

  const handleError = (e) => {
    console.log('handleError', e);
  };

  const config = {
    lang: 'en',
    email: profile.email,
  };

  const options = {
    addViewportTag: false,
    adaptIframeHeight: true,
  };

  useEffect(() => {
    getSumsubToken({levelName});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelName]);

  return (
    <div className="sumsub-container">
      {!sumsubToken ? (
        <div className="sumsub-activity-indicator">
          <ActivityIndicator />
        </div>
      ) : (
        <SumsubWebSdk
          accessToken={sumsubToken}
          expirationHandler={handleExpiration}
          config={config}
          options={options}
          onMessage={handleMessage}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default Sumsub;
