import React from 'react'

const Buildings = ({
  fill = '#500078',
  width = '32',
  height = '32',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44 2.666h10.453c1.041 0 1.885.844 1.885 1.884v24.783h-4.445v-2.667H8v2.667H3.556V4.55c0-1.04.843-1.884 1.884-1.884zm.782 19.556H8v-1.778H6.222v1.778zM8 17.777H6.222V16H8v1.778zm-1.778-4.444H8v-1.778H6.222v1.778zM8 8.888H6.222V7.11H8v1.778zm1.778 13.334h1.777v-1.778H9.779v1.778zm1.777-4.445H9.779V16h1.777v1.778zm-1.777-4.444h1.777v-1.778H9.779v1.778zm1.777-4.445H9.779V7.11h1.777v1.778zm1.778 13.334h1.778v-1.778h-1.778v1.778zm1.778-4.445h-1.778V16h1.778v1.778zm-1.778-4.444h1.778v-1.778h-1.778v1.778zm1.778-4.445h-1.778V7.11h1.778v1.778zm4.444-1.778h8c.982 0 1.778.796 1.778 1.778v20.445h-9.777V7.11zm3.556 15.112h-1.778v-1.778h1.778v1.778zm-1.778-4.445h1.778V16h-1.778v1.778zm1.778-4.444h-1.778v-1.778h1.778v1.778zm1.778 8.889h1.778v-1.778h-1.778v1.778zm1.778-4.445h-1.778V16h1.778v1.778zm-1.778-4.444h1.778v-1.778h-1.778v1.778z"
        fill={fill}
      />
    </svg>
  )
}

export default Buildings
