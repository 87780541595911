import './Datepicker.scss';

import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import Icon from "../Icons";

const Datepicker = ({
  customInput,
  className,
  dateFormat,
  dataTestId,
  label,
  maxDate,
  minDate,
  required,
  value,
  ...otherProps
}) => {
  let classes = classNames('DatepickerContainer', className, {
    'Datepicker--disabled': otherProps.disabled === true,
  });

  if (dateFormat == null) {
    dateFormat = 'MM/dd/yyyy';
  }

  if (value && typeof value === 'string') {
    value = Date.parse(value);
  }

  if (maxDate && typeof maxDate === 'string') {
    maxDate = Date.parse(maxDate);
  }

  if (minDate && typeof minDate === 'string') {
    minDate = Date.parse(minDate);
  }

  return (
    <div className={classes}>
      <DatePicker
        customInput={<input data-testid={dataTestId} type="text" />}
        className="Datepicker Input"
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        selected={value}
        {...otherProps}
      />

      {!(otherProps.selectsRange && otherProps.isClearable && otherProps.startDate && otherProps.endDate) && (
        <Icon name="calendar"/>
      )}
    </div>
  );
};

export default Datepicker;
