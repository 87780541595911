import './removefundsmodal.scss';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { eq, filter, find, get, gt, isNil, isUndefined, map, round, some, upperCase } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import clone from 'clone';
import { unwrapResult } from "@reduxjs/toolkit";

import AchAccount from "../../types/AchAccount";
import Asset from "../../types/Asset";
import InputError from '../shared/InputError';
import Recipient from "../../types/Recipient";
import { Vasp } from "../../types/Vasp";

import useAccount from "../../hooks/useAccount";
import useAchDuck from '../../hooks/useAchDuck';
import useAssetsDuck from "../../hooks/useAssetsDuck";
import useProfileDuck from "../../hooks/useProfileDuck";
import useWalletsDuck from '../../hooks/useWalletsDuck';

import { deleteRecipient, getRecipients, getVasps, saveRecipient } from "../../ducks/travelRule";
import { useAppDispatch, useTypedSelector } from "../../ducks";

import { API_PATHS, APP_PATHS, FACE_AUTH_RESULTS_CONTENT, FACE_AUTH_RESULTS_TITLE } from '../../constants';
import {
  FacialVerificationResults,
  KycVerificationLevels,
  KycVerificationStatuses,
  PermissionReasons, ProfileTypes
} from "../../utils/enums";
import { formatAsDollarAmount, formatNumber, isArrayNullOrEmpty, isObjectNullOrEmpty } from '../../utils/utils';
import TokenRequest from "../../utils/tokenRequest";
import Request from '../../utils/request';

import AchWithdrawalForm from '../AchWithdrawalForm';
import Button, { ButtonVariants } from "../shared/Button";
import Currency from '../shared/Currency';
import EnhancedFlash, { EnhancedFlashVariants } from "../shared/EnhancedFlash";
import Flash from '../shared/Flash';
import Icon from "../shared/Icons";
import Input, { InputModes, InputTypes } from '../shared/Input';
import Logo from "../shared/Logo";
import Modal from "../shared/Modal";
import SearchableSelect from "../shared/SearchableSelect";
import Select, { SelectOption } from '../shared/Select';
import SelectableAssetList from '../SelectableAssetList';
import Sumsub from "../shared/Sumsub";
import Toggle from '../shared/Toggle';
import Tooltip from "../shared/Tooltip";

const request = new Request();

const RemoveFundsModal = ({modal}) => {
  const dispatch = useAppDispatch();
  const formMethods = useForm({
    defaultValues: {
      isMe: true,
    },
  });
  const {
    formState,
    handleSubmit,
    register,
    reset: resetCryptoWithdrawalForm,
    setValue,
    trigger,
    watch
  } = formMethods;
  const history = useHistory();

  const {assets} = useAssetsDuck();
  const {balances, getWithdrawalLimits, usdPrices, withdrawalLimits} = useWalletsDuck();
  const {
    countries,
    facialVerificationApplicantActionId,
    facialVerificationResult,
    profile,
    reason,
    getCountries,
    getCryptoAllowed,
    getFacialVerificationResult,
    getUsdAllowed,
    statePermissions,
    kycVerificationStatus,
    setFacialVerificationApplicantActionId,
    setFacialVerificationResult,
    testFacialVerificationExpiration
  } = useProfileDuck();
  const {getAchItems, items: achItems, initiateAchWithdrawal} = useAchDuck();
  const {idToken} = useAccount();

  const {isDeletingRecipient, recipients, vasps} = useTypedSelector((state) => state.travelRule);

  const [achWithdrawal, setAchWithdrawal] = useState<{ accountId: string, amount: number | string }>();
  const [cantWithdrawReason, setCantWithdrawReason] = useState<string | undefined>();
  const [cryptoWithdrawalError, setCryptoWithdrawalError] = useState<string | undefined>();
  const [isBusinessRecipient, setIsBusinessRecipient] = useState<boolean>(false);
  const [isSubmittingWithdrawal, setIsSubmittingWithdrawal] = useState<boolean>(false);
  const [result, setResult] = useState<string>('');
  const [selectedRecipient, setSelectedRecipient] = useState<string>('');
  const [selectedVasp, setSelectedVasp] = useState<Vasp | undefined>();
  const [showVaspRequiredError, setShowVaspRequiredError] = useState<boolean>(false);
  const [states, setStates] = useState<Array<{ name: string, uspsId: string }>>([]);
  const [title, setTitle] = useState<string>('');
  const [withdrawalAccount, setWithdrawalAccount] = useState<AchAccount | undefined>();
  const [withdrawAsset, setWithdrawAsset] = useState<Asset | undefined>();
  const [withdrawalError, setWithdrawalError] = useState();

  const {post} = new TokenRequest(idToken);

  const amount = watch('amount');
  const isMe = watch('isMe');
  const watchedBeneficiaryCountry = watch('beneficiaryCountry');

  const keys = ['beneficiaryCountry', 'recipientNickname', 'beneficiaryFirstName', 'beneficiaryLastName', 'beneficiaryAccountNumber', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryOwnershipProof', 'beneficiaryBusinessName', 'beneficiaryState'];

  const checkFacialVerificationStatus = async () => {
    if (!facialVerificationApplicantActionId || facialVerificationResult in FacialVerificationResults) {
      return;
    }

    await getFacialVerificationResult(facialVerificationApplicantActionId);
  };

  const amountAfterFee = useMemo(() => {
    if (isUndefined(withdrawAsset) || !amount) return

    const {scale, withdrawal_fee} = withdrawAsset
    return round(amount - withdrawal_fee, scale)
  }, [amount, withdrawAsset])

  const allowedCryptoAssets: Array<Asset> = useMemo(() => {
    if (isNil(assets) || isNil(balances)) return []

    return filter(
      assets,
      ({can_withdraw, is_fiat, id}) =>
        can_withdraw && !is_fiat && gt(balances[id], 0)
    )
  }, [assets, balances])

  const currentBalance = useMemo(() => {
    if (isUndefined(withdrawAsset) || isNil(balances)) return

    return balances[withdrawAsset.id]
  }, [withdrawAsset, balances])

  const usdWithdrawalFee = useMemo(() => {
    if (eq(assets.length, 0)) return
    const usdInfo = find(assets, ({id}) => eq(id, 'usd'))
    if (isUndefined(usdInfo)) return
    return usdInfo.withdrawal_fee
  }, [assets])

  const clearRecipient = () => {
    setSelectedRecipient('');
    keys.forEach((key) => {
      setValue(key, '');
    });
  };

  const handleCryptoWithdrawalWithoutRecipient = async (data) => {
    return await post(API_PATHS.COIN.WITHDRAWAL, {
      ...data,
      address: data.address.trim(),
      amount,
      assetId: withdrawAsset.id,
      applicantActionId: facialVerificationApplicantActionId,
    })
  };

  const handleCryptoWithdrawalWithRecipient = async (data) => {
    try {
      const foundRecipient = find(recipients, (recipient) => eq(recipient.recipientId, selectedRecipient));
      let recipientData = clone(data);
      delete recipientData.amount;
      delete recipientData.address;
      delete recipientData.isMe;
      recipientData.userId = profile.userId;
      recipientData.vaspDid = selectedVasp?.did || foundRecipient?.vaspDid;
      recipientData.recipientId = selectedRecipient;
      recipientData.recipientType = isBusinessRecipient ? 'business' : 'individual';

      let withdrawalData = {
        address: data.address.trim(),
        amount,
        assetId: withdrawAsset.id,
        applicantActionId: facialVerificationApplicantActionId,
        tag: data.tag,
      };

      let res = dispatch(saveRecipient({recipient: recipientData})).unwrap();
      await post(API_PATHS.COIN.WITHDRAWAL, withdrawalData);
      return res;
    } catch(err) {
      console.log('RemoveFundsModal handleCryptoWithdrawalWithRecipient error', err);
      throw err;
    }
  };

  const handleDeleteRecipient = async () => {
    try {
      let res = await dispatch(deleteRecipient({recipientId: selectedRecipient}));
      unwrapResult(res);
      clearRecipient();
    } catch(err) {
      console.log('RemoveFundsModal handleDeleteRecipient error', err);
    }
  }

  const handleWithdrawMax = () => {
    setValue('amount', !isObjectNullOrEmpty(maxCryptoWithdrawalAmount) ? maxCryptoWithdrawalAmount.limit : 0)
  };

  const submitAchWithdrawal = async (callback) => {
    if (isNil(achWithdrawal)) {
      return;
    }

    try {
      setIsSubmittingWithdrawal(true)
      await initiateAchWithdrawal({
        accountId: achWithdrawal.accountId,
        amount: round(Number(achWithdrawal.amount) - usdWithdrawalFee, 2),
        applicantActionId: facialVerificationApplicantActionId,
      })
      callback()
    } catch (e) {
      setWithdrawalError(e)
    } finally {
      setIsSubmittingWithdrawal(false)
    }
  }

  const withdrawalPermissionsCheck = () => {
    // Can't withdraw if not verified
    if (isNil(kycVerificationStatus) || kycVerificationStatus !== 4) {
      setCantWithdrawReason('TIER2');
      return false;
    }

    // Can't withdraw if USD, USDC, or USDT balances are negative
    if (
      isObjectNullOrEmpty(balances) ||
      (!isNil(balances.usd) && balances.usd < 0) ||
      (!isNil(balances.usdc) && balances.usdc < 0) ||
      (!isNil(balances.usdt) && balances.usdt < 0)
    ) {
      setCantWithdrawReason('NEGATIVE');
      return false;
    }

    setCantWithdrawReason(null);
    return true;
  }

  const maxCryptoWithdrawalAmount: { limit?: number, type?: string, cantWithdraw?: boolean } = useMemo(
    () => {
      if (isObjectNullOrEmpty(usdPrices) || !withdrawAsset || !usdPrices[withdrawAsset.id]) {
        return { cantWithdraw: true };
      }

      let balanceInUsd = usdPrices[withdrawAsset.id] * currentBalance;
      return withdrawalLimits.currentWithdrawalAllowance < balanceInUsd
        ? {limit: withdrawalLimits.currentWithdrawalAllowance / usdPrices[withdrawAsset.id], type: 'limit'}
        : {limit: currentBalance, type: 'balance'};
    }, [currentBalance, usdPrices, withdrawAsset, withdrawalLimits]
  );

  const canWithdrawUsd = useMemo(
    () => {
      const canWithdraw = withdrawalPermissionsCheck();
      const usdAllowed = getUsdAllowed();
      return canWithdraw && usdAllowed;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balances, statePermissions, kycVerificationStatus]
  );

  const canWithdrawCrypto = useMemo(
    () => {
      const canWithdraw = withdrawalPermissionsCheck();
      const cryptoAllowed = getCryptoAllowed();
      return canWithdraw && cryptoAllowed;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balances, statePermissions, kycVerificationStatus]
  );

  //@ts-ignore
  const pastRecipients: Array<Recipient> = useMemo(
    () => {
      return [
        {
          label: 'Select One',
          value: '',
        },
        ...recipients,
      ];
    }, [recipients]
  );

  useEffect(() => {
    if (modal.show) {
      getWithdrawalLimits();
      testFacialVerificationExpiration();

      if (isArrayNullOrEmpty(vasps)) {
        dispatch(getVasps());
      }

      if(isArrayNullOrEmpty(countries)) {
        getCountries();
      }

      if (gt(kycVerificationStatus, 1)) {
        getAchItems();
      }
    } else {
      setCryptoWithdrawalError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show]);

  useEffect(() => {
    if(!watchedBeneficiaryCountry || watchedBeneficiaryCountry !== 'US') {
      return;
    }

    ;(async function requestCountries() {
      try {
        const {data: usStates} = await request.get(API_PATHS.COIN.STATES);
        setStates(usStates);
      } catch (err) {

      }
    })();
  }, [watchedBeneficiaryCountry]);

  useEffect(() => {
    if(isObjectNullOrEmpty(selectedRecipient)) {
      return;
    }
    const foundRecipient = find(pastRecipients, (recipient) => eq(recipient.recipientId, selectedRecipient));

    keys.forEach((key) => {
      if(foundRecipient?.[key]) {
        setTimeout(() => setValue(key, foundRecipient[key]), key === 'beneficiaryState' ? 500 : 0);
      } else {
        setValue(key, '');
      }
    });

    setIsBusinessRecipient(foundRecipient.recipientType === 'business');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastRecipients, selectedRecipient]);

  const hasCompletedFacialVerification = facialVerificationResult === 'GREEN' || facialVerificationResult === 'YELLOW';
  const isBusinessProfile = profile.profileType === ProfileTypes.Business;
  const profileIsVerified = kycVerificationStatus === KycVerificationStatuses.Verified;

  return (
    <Modal
      className="RemoveFundsModal"
      modal={modal}
      screens={{
        MAIN: {
          heading: <h4>Withdraw</h4>,
          body: (push) => (
            <div className="AddFunds-body">
              <h3 className="AddFunds-header">Withdraw funds to</h3>
              {!profileIsVerified ? (
                <React.Fragment>
                  <p>
                    Your identity verification process has not been completed. Please go to the Account Settings screen
                    and get verified. If you have attempted multiple times without success then please reach out to
                    support.
                  </p>

                  <Button
                    className="AddFunds-facialAuthButton"
                    onClick={() => history.push(APP_PATHS.ACCOUNT)}
                  >
                    Go to Account Settings
                  </Button>
                </React.Fragment>
              ) : (
                !isBusinessProfile && (
                  <React.Fragment>
                    {hasCompletedFacialVerification ? (
                      <p>
                        You have a {facialVerificationResult === 'GREEN' ? 'valid' : 'pending'} facial verification.
                        Please continue.
                      </p>
                    ) : (
                      <p>
                        Facial Verification is required in order to proceed with any of the items below. Click below to
                        launch facial authorization process.
                      </p>
                    )}

                    <Button
                      className="AddFunds-facialAuthButton"
                      disabled={hasCompletedFacialVerification}
                      onClick={() => push('FACIAL_VERIFICATION')}
                    >
                      Begin Facial Verification
                    </Button>
                  </React.Fragment>
                )
              )}

              {facialVerificationResult === 'RED' && (
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading={FACE_AUTH_RESULTS_TITLE.RED}
                  subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                />
              )}

              <Button
                variant="secondary"
                size="large"
                className={`u-horizontalPadding ${
                  (!canWithdrawUsd || !hasCompletedFacialVerification) && !isBusinessProfile ? 'AddFunds--disabledButton' : ''
                }`}
                onClick={() => {
                  if (
                    eq(achItems.length, 0) ||
                    !some(achItems, ({relinkRequired}) => !relinkRequired)
                  ) {
                    modal.setShow(false)
                    history.push(APP_PATHS.ACCOUNT_BANK)
                  }
                  push('ACH_1')
                  checkFacialVerificationStatus();
                }}
              >
                <div className="AddFunds--buttonInterior">
                  <Icon
                    name="bank"
                    fill={canWithdrawUsd ? '#500078' : '#848D96'}
                    height="32"
                    width="32"
                  />
                  <span className="AddFunds--buttonText">Bank Account</span>
                  <Icon
                    name="caretright"
                    fill={canWithdrawUsd ? '#500078' : '#848D96'}
                  />
                </div>
              </Button>
              <Button
                size="large"
                variant="secondary"
                className={`u-horizontalPadding ${
                  (!canWithdrawCrypto || !hasCompletedFacialVerification) && !isBusinessProfile ? 'AddFunds--disabledButton' : ''
                }`}
                onClick={() => {
                  push('CRYPTO_ADDRESS_1');
                  checkFacialVerificationStatus();
                }}
              >
                <div className="AddFunds--buttonInterior">
                  <Icon
                    name="qr"
                    fill={canWithdrawCrypto ? '#500078' : '#848D96'}
                    height="32"
                    width="32"
                  />
                  <span className="AddFunds--buttonText">
                    External Crypto Address
                  </span>
                  <Icon
                    name="caretright"
                    fill={canWithdrawCrypto ? '#500078' : '#848D96'}
                  />
                </div>
              </Button>

              {!canWithdrawUsd && isNil(cantWithdrawReason) && (
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading={
                    eq(reason, PermissionReasons.Onboarding)
                      ? 'Transactions with USD are not available until you complete onboarding.'
                      : 'You are in a jurisdiction where transactions with USD are not available.'
                  }
                  subheading={
                    <span>
                      For more information, see our{' '}
                      <a
                        href="https://help.coinlion.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        help page
                      </a>
                    </span>
                  }
                />
              )}
              {!canWithdrawCrypto && isNil(cantWithdrawReason) && (
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading={'You are in a jurisdiction where crypto withdrawals are not available.'}
                  subheading={
                    <span>
                      For more information, see our{' '}
                      <a
                        href="https://help.coinlion.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        help page
                      </a>
                    </span>
                  }
                />
              )}
              {(!canWithdrawCrypto || !canWithdrawUsd) && eq(cantWithdrawReason, 'NEGATIVE') && (
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading={'If your USD/USDT/USDC balance is less than zero you cannot withdraw any funds.'}
                  subheading={
                    <span>
                      For more information, see our{' '}
                      <a
                        href="https://help.coinlion.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        help page
                      </a>
                    </span>
                  }
                />
              )}
              {(!canWithdrawCrypto || !canWithdrawUsd) && eq(cantWithdrawReason, 'TIER2') && (
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading={'You must have your Identification Verification process fully completed prior to withdrawing any funds.'}
                  subheading={
                    <span>
                      For more information, see our{' '}
                      <a
                        href="https://help.coinlion.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        help page
                      </a>
                    </span>
                  }
                />
              )}
            </div>
          ),
        },
        CRYPTO_ADDRESS_1: {
          heading: <h4>Withdraw</h4>,
          body: (push) => (
            <SelectableAssetList
              assets={allowedCryptoAssets}
              onSelect={(asset) => {
                setWithdrawAsset(asset)
                resetCryptoWithdrawalForm()
                push('CRYPTO_ADDRESS_2')
                checkFacialVerificationStatus();
              }}
              disableAssetsWithoutUsdPrice
            />
          ),
        },
        CRYPTO_ADDRESS_2: {
          heading: <h4>Withdraw</h4>,
          body: (push) =>
            maxCryptoWithdrawalAmount.cantWithdraw ? (
              <div>
                <EnhancedFlash
                  variant={EnhancedFlashVariants.Warning}
                  heading="There was a problem"
                  subheading="We are unable to process this transaction right now, please try again later."
                />
              </div>
            ) : isUndefined(withdrawAsset) ? (
              <React.Fragment/>
            ) : (
              <FormProvider {...formMethods}>
                <form
                  onSubmit={handleSubmit(async (data) => {
                    if(!selectedVasp) {
                      setShowVaspRequiredError(true);
                      return;
                    }

                    try {
                      await handleCryptoWithdrawalWithoutRecipient(data);
                      push('CRYPTO_ADDRESS_3')
                    } catch (e) {
                      const errorMessage = get(
                        e,
                        'response.data.errors[0].message'
                      )
                      isNil(errorMessage)
                        ? setCryptoWithdrawalError('There was an error')
                        : setCryptoWithdrawalError(errorMessage)
                    }
                  })}
                >
                  <div className="CryptoWithdrawal-topRow">
                    <div className="ModalCryptoList-row">
                      <div className="ModalCryptoList-currencyContainer">
                        {eq(withdrawAsset.id, 'lion') ? (
                          <Logo/>
                        ) : (
                          <Currency type={withdrawAsset.id}/>
                        )}
                      </div>
                      <p>
                        {withdrawAsset.asset_name} (
                        {upperCase(withdrawAsset.id)})
                      </p>
                    </div>
                  </div>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">Daily Withdraw Limit</p>
                    <p className="value valueWithTooltip">
                      {isObjectNullOrEmpty(withdrawalLimits) ? '--' : formatAsDollarAmount(withdrawalLimits.currentWithdrawalAllowance)}&nbsp;
                      <Tooltip>The Daily Withdraw Limit is calculated by taking the greater of 1) $10,000 2) Your
                        largest deposit ever 3) The largest month in which you made a deposit divided by 2. (Example: If
                        you deposited $1,000 a day for 20 days in a single month then your Daily Withdraw limit would be
                        $10,000). If you have already withdrawn funds today then this number will decrease as you
                        withdraw funds. Tomorrow it will reset.</Tooltip>
                    </p>
                  </div>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">Pending ACH Value that cannot be withdrawn:</p>
                    <p className="value valueWithTooltip">
                      {isObjectNullOrEmpty(withdrawalLimits) ? '--' : formatAsDollarAmount(withdrawalLimits.fiatDeposits30Days)}&nbsp;
                      <Tooltip>If you deposit any funds via ACH you will be unable to withdraw those funds via a crypto
                        withdraw for 30 days. Example: If you have a balance of $10,000 and you add $1,000 via ACH
                        deposit while your balance may be $11,000 you can only withdraw $10,000 via crypto. You can
                        always withdraw the $1,000 via ACH.</Tooltip>
                    </p>
                  </div>
                  <hr className="u-divider u-lightGrey"/>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">Current {upperCase(withdrawAsset.id)} balance</p>
                    <p className="value">{currentBalance}</p>
                  </div>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">Minimum withdrawal</p>
                    <p className="value">
                      {withdrawAsset.minimum_withdrawal +
                        withdrawAsset.withdrawal_fee}
                    </p>
                  </div>
                  <hr className="u-divider u-lightGrey"/>
                  <React.Fragment>
                    <p>
                      <label className="SinglePanel-checkbox">
                        <input
                          disabled={formState.isSubmitting}
                          id="enable_buying_box"
                          type="checkbox"
                          {...register('isMe')}
                        />
                        I am the recipient of these funds
                      </label>
                    </p>
                    <p>
                      <SearchableSelect
                        disabled={formState.isSubmitting}
                        getOptionLabel={(option: Vasp) => option.name}
                        getOptionValue={(option: Vasp) => option.did}
                        placeholder="Find your exchange"
                        options={vasps as Array<Vasp>}
                        value={selectedVasp}
                        onChange={(selectedValue: Vasp) => {
                          setSelectedVasp(selectedValue)
                          setShowVaspRequiredError(false);
                        }}
                      />

                      {showVaspRequiredError && <InputError className="SelectVasp-error" message="Exchange is required" />}
                    </p>

                    <Input
                      disabled={formState.isSubmitting}
                      inputType={InputTypes.Textarea}
                      name="address"
                      register={{...register('address', {required: 'Withdraw Address is required'})}}
                      triggerValidation
                    >
                      {upperCase(withdrawAsset.id)} Withdraw Address
                    </Input>

                    {withdrawAsset.requires_tag === true && (
                      <>
                        <Input
                            disabled={formState.isSubmitting}
                            inputType={InputTypes.Text}
                            name="tag"
                            register={{...register('tag')}}
                            triggerValidation
                        >
                            Memo/Tag
                        </Input>

                        <p className="DepositCrypto-tagWarning">
                            There is a very high probability that you need a memo/tag in order for this withdrawal to be completed succcessfully. Please make sure you add this to your transaction or you risk losing the transaction.
                        </p>
                      </>
                    )}

                    <div className={`DepositCrypto-amountContainer`}>
                      <Input
                        disabled={formState.isSubmitting}
                        inputType={InputTypes.Number}
                        name="amount"
                        register={{
                          ...register('amount', {
                            required: 'Amount is required',
                            min: {
                              value: withdrawAsset.minimum_withdrawal + withdrawAsset.withdrawal_fee,
                              message: `Withdrawal must be at least ${withdrawAsset.minimum_withdrawal + withdrawAsset.withdrawal_fee}`,
                            },
                            max: {
                              value: maxCryptoWithdrawalAmount.limit,
                              message: maxCryptoWithdrawalAmount.type === 'balance' ? `Withdraw amount cannot exceed your current balance` : `Withdraw amount cannot exceed your daily withdraw limit`,
                            }
                          })
                        }}
                        step="any"
                        onBlur={() => {
                          setValue('amount', round(amount, withdrawAsset.scale))
                        }}
                        inputMode={InputModes.Decimal}
                        triggerValidation
                      >
                        Amount
                      </Input>

                      <Button
                        className="DepositCrypto-maxButton"
                        onClick={() => handleWithdrawMax()}
                        variant="blank"
                      >
                        Withdraw Max
                      </Button>
                    </div>
                  </React.Fragment>
                  <hr className="u-divider u-lightGrey"/>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">Transaction fee</p>
                    <p className="value">
                      {withdrawAsset.withdrawal_fee}{' '}
                      {upperCase(withdrawAsset.id)}
                    </p>
                  </div>
                  <div className="CryptoWithdrawal-dataRow">
                    <p className="label">You will get</p>
                    <p className="value">
                      {isUndefined(amountAfterFee)
                        ? '--'
                        : `${formatNumber(amountAfterFee)} ${upperCase(
                          withdrawAsset.id
                        )}`}
                    </p>
                  </div>

                  {!isBusinessProfile && facialVerificationResult !== 'GREEN' && (
                    <EnhancedFlash
                      variant={EnhancedFlashVariants.Warning}
                      heading={FACE_AUTH_RESULTS_TITLE.RED}
                      subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                    />
                  )}

                  {!isNil(cryptoWithdrawalError) && (
                      <Flash
                          danger
                          onDismiss={() => setCryptoWithdrawalError(null)}
                      >
                          {cryptoWithdrawalError}
                      </Flash>
                  )}

                  {isMe ? (
                    <Button
                      variant="primary"
                      type="submit"
                      size="large"
                      disabled={!isBusinessProfile && facialVerificationResult !== 'GREEN'}
                      className="CryptoWithdrawal-submitButton"
                      isLoading={formState.isSubmitting}
                    >
                      Send
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      type="button"
                      size="large"
                      disabled={!isBusinessProfile && facialVerificationResult !== 'GREEN'}
                      className="CryptoWithdrawal-submitButton"
                      onClick={() => {
                        if(!selectedVasp) {
                          setShowVaspRequiredError(true);
                          return;
                        }

                        if (!formState.isValid && !isObjectNullOrEmpty(formState.errors)) {
                          trigger();
                          return;
                        }
                        dispatch(getRecipients());
                        push('CRYPTO_ADDRESS_TRAVEL_RULE')
                        setCryptoWithdrawalError(undefined);
                      }}
                    >
                      Next
                    </Button>
                  )}

                  <EnhancedFlash
                    variant={EnhancedFlashVariants.Warning}
                    heading="Do not withdraw directly to a crowdfund or ICO."
                    subheading="We will not credit your account with tokens from that sale."
                  />
                </form>
              </FormProvider>
            ),
        },
        CRYPTO_ADDRESS_TRAVEL_RULE: {
          heading: <h4>Withdraw - Recipient info</h4>,
          body: (push) => (
            <div className="CryptoWithdrawal-recipientInfo">
              {!isArrayNullOrEmpty(recipients) && (
                <label className="Label">
                  Past Recipients
                  <Select
                    options={pastRecipients as Array<SelectOption>}
                    value={selectedRecipient}
                    onChange={(e) => {
                      setSelectedRecipient(e.target.value);
                    }}
                  />
                </label>
              )}

              <p>
                <Button
                    className="CryptoWithdrawal-clearButton"
                    onClick={() => {
                      setSelectedRecipient('');
                      setIsBusinessRecipient(false);
                      clearRecipient();
                    }}
                    variant={ButtonVariants.Blank}
                >
                  Clear Recipient Data
                </Button>
              </p>

              <FormProvider {...formMethods}>
                <form
                  onSubmit={handleSubmit(async (data) => {
                    try {
                      await handleCryptoWithdrawalWithRecipient(data);
                      push('CRYPTO_ADDRESS_3')
                    } catch (e) {
                      const errorMessage = get(
                        e,
                        'response.data.errors[0].message'
                      )
                      isNil(errorMessage)
                        ? setCryptoWithdrawalError('There was an error')
                        : setCryptoWithdrawalError(errorMessage)
                        push('CRYPTO_ADDRESS_2');
                    }
                    //data is still within context
                  })}
                >
                  <Input
                    inputType={InputTypes.Text}
                    name="recipientNickname"
                    register={{
                      ...register('recipientNickname', {
                        required: 'Nickname is required to save address',
                      })
                    }}
                    triggerValidation
                  >
                    Nickname for this wallet/recipient
                  </Input>

                  <Toggle
                    handleChange={() => setIsBusinessRecipient(!isBusinessRecipient)}
                    label={isBusinessRecipient ? 'The recipient is a business not a person' : 'The recipient is a person not a business'}
                    value={isBusinessRecipient}
                  />

                  <Input
                    inputType={InputTypes.Text}
                    name="beneficiaryAccountNumber"
                    register={{
                      ...register('beneficiaryAccountNumber', {})
                    }}
                  >
                    Recipient's Account Number
                  </Input>

                  {isBusinessRecipient ? (
                    <Input
                      inputType={InputTypes.Text}
                      name="beneficiaryBusinessName"
                      register={{
                        ...register('beneficiaryBusinessName', {
                          required: 'Business name is required',
                        })
                      }}
                      triggerValidation
                    >
                      Business Name
                    </Input>
                  ) : (
                    <>
                      <Input
                        inputType={InputTypes.Text}
                        name="beneficiaryFirstName"
                        register={{
                          ...register('beneficiaryFirstName', {
                            required: 'First name is required',
                          })
                        }}
                        triggerValidation
                      >
                        First Name
                      </Input>

                      <Input
                        inputType={InputTypes.Text}
                        name="beneficiaryLastName"
                        register={{
                          ...register('beneficiaryLastName', {
                            required: 'Last name is required',
                          })
                        }}
                        triggerValidation
                      >
                        Last Name
                      </Input>
                    </>
                  )}

                  <hr className="u-divider u-lightGrey"/>

                  <label className="Label">
                    Country

                    <Select
                      ariaLabel="Select country"
                      containerClassName={`${formState.errors.beneficiaryCountry ? 'Input--error' : ''}`}
                      defaultValue=""
                      id="beneficiaryCountry"
                      name="beneficiaryCountry"
                      register={{
                        ...register('beneficiaryCountry', {
                          required: 'Country is required',
                        }),
                      }}
                    >
                      <option disabled value="" key="select">
                        Select…
                      </option>

                      {map(countries, ({ name, code }) => (
                        <option id={`{country_${code}}`} key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </label>

                  {watchedBeneficiaryCountry === 'US' && (
                    <label className="Label">
                      State or territory
                      <Select
                        ariaLabel="Select state or territory"
                        id="state_select"
                        containerClassName={`${formState.errors.beneficiaryState ? 'Input--error' : ''}`}
                        register={{
                          ...register('beneficiaryState', {
                            required: 'State is required',
                          }),
                        }}
                        defaultValue=""
                      >
                        <option value="" disabled key="select">
                          Select…
                        </option>

                        {map(states, ({ name, uspsId }) => (
                          <option
                            id={`{state_${name}}`}
                            key={uspsId}
                            value={uspsId}
                          >
                            {name}
                          </option>
                        ))}
                      </Select>
                    </label>
                  )}

                  <Input
                    inputType={InputTypes.Text}
                    name="beneficiaryAddress"
                    register={{
                      ...register('beneficiaryAddress', {
                        required: 'Address is required',
                      })
                    }}
                    triggerValidation
                  >
                    Recipient Street Address
                  </Input>

                  <Input
                    inputType={InputTypes.Text}
                    name="beneficiaryCity"
                    register={{
                      ...register('beneficiaryCity', {
                        required: 'City is required',
                      })
                    }}
                    triggerValidation
                  >
                    Recipient City
                  </Input>

                  <p>
                    <label className="SinglePanel-checkbox Validated-checkbox">
                      <input
                        id="enable_buying_box"
                        type="checkbox"
                        {...register('beneficiaryOwnershipProof', {
                          required: 'You must validate that this recipient owns this account.',
                        })}
                      />
                      I have validated that this recipient owns this account.
                    </label>
                  </p>

                  <Button
                    className="CryptoWithdrawal-submitButton"
                    disabled={watch('beneficiaryOwnershipProof') !== true || (!isBusinessProfile && facialVerificationResult !== 'GREEN')}
                    isLoading={formState.isSubmitting}
                    size="large"
                    type="submit"
                    variant="primary"
                  >
                    Send
                  </Button>

                  <Button
                    className="CryptoWithdrawal-submitButton"
                    disabled={!selectedRecipient}
                    isLoading={isDeletingRecipient}
                    onClick={handleDeleteRecipient}
                    size="large"
                    type="button"
                    variant="primary"
                  >
                    Delete
                  </Button>
                </form>
              </FormProvider>
            </div>
          ),
        },
        CRYPTO_ADDRESS_3: {
          heading: <h4>Withdraw</h4>,
          body: (
            <div>
              <h1 className="CryptoWithdrawal-successHeader">Success!</h1>
              {!isUndefined(withdrawAsset) && !isUndefined(amount) && (
                <p className="CryptoWithdrawal-successDescription">
                  Your withdrawal of {formatNumber(amount)}{' '}
                  {upperCase(withdrawAsset.id)} has been initiated. You can view
                  your deposit and withdrawal history on the Activity screen
                </p>
              )}
              <Button
                variant="primary"
                type="submit"
                size="large"
                className="CryptoWithdrawal-submitButton"
                onClick={() => modal.setShow(false)}
              >
                Close
              </Button>
            </div>
          ),
          noBackButton: true,
        },
        ACH_1: {
          heading: <h4>Withdraw</h4>,
          body: (push) => (
            <AchWithdrawalForm
              withdrawal={achWithdrawal}
              submitAction={(formData, selectedWithdrawalAccount) => {
                setAchWithdrawal(formData)
                setWithdrawalAccount(selectedWithdrawalAccount)
                setWithdrawalError(null)
                push('ACH_2')
                checkFacialVerificationStatus();
              }}
            />
          ),
        },
        ACH_2: {
          heading: <h4>Withdraw</h4>,
          body: (push) =>
            !isNil(achWithdrawal) &&
            !isNil(withdrawalAccount) && (
              <div>
                {!isNil(withdrawalError) && (
                  <Flash danger onDismiss={() => setWithdrawalError(null)}>
                    {withdrawalError}
                  </Flash>
                )}

                <div>
                  <div className="DepositCrypto-dataRow">
                    <p className="DepositCrypto-labelText">Available</p>
                    <p className="DepositCrypto-dataText">4 days</p>
                  </div>
                  <div className="DepositCrypto-dataRow">
                    <p className="DepositCrypto-labelText">Amount</p>
                    <p className="DepositCrypto-dataText">
                      {formatAsDollarAmount(achWithdrawal.amount, true)}
                    </p>
                  </div>
                  <div className="DepositCrypto-dataRow">
                    <p className="DepositCrypto-labelText">Fee</p>
                    <p className="DepositCrypto-dataText">
                      {isUndefined(usdWithdrawalFee)
                        ? '--'
                        : formatAsDollarAmount(usdWithdrawalFee)}
                    </p>
                  </div>
                </div>
                <hr className="u-divider DepositCrypto-divider"/>
                <div className="DepositCrypto-dataRow">
                  <p className="DepositCrypto-labelText">Total</p>
                  <p className="DepositCrypto-dataText DepositCrypto-boldText">
                    {isUndefined(usdWithdrawalFee)
                      ? '--'
                      : formatAsDollarAmount(
                        Number(achWithdrawal.amount) - usdWithdrawalFee
                      )}
                  </p>
                </div>

                <div className="AchDepositForm-partyBox u-unchanging">
                  <div>
                    <Icon name="bank" fill="#500078" height="30" width="30"/>
                  </div>
                  <p className="DepositCrypto-selectedAccount">
                    {withdrawalAccount.name} x{withdrawalAccount.mask}
                  </p>
                </div>

                <EnhancedFlash
                  variant={EnhancedFlashVariants.Info}
                  heading="ACH Withdrawals take 2–4 business days"
                  subheading="Funds will become available in your bank account when they are received."
                />

                {!isBusinessProfile && facialVerificationResult !== 'GREEN' && (
                  <EnhancedFlash
                    variant={EnhancedFlashVariants.Warning}
                    heading={FACE_AUTH_RESULTS_TITLE.RED}
                    subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                  />
                )}

                <Button
                  variant="primary"
                  size="large"
                  className="DepositCrypto-primaryButton u-marginTop"
                  disabled={!isBusinessProfile && facialVerificationResult !== 'GREEN'}
                  onClick={() => {
                    submitAchWithdrawal(() => push('ACH_3'))
                  }}
                  isLoading={isSubmittingWithdrawal}
                >
                  Confirm withdrawal
                </Button>
              </div>
            ),
        },
        ACH_3: {
          heading: <h4>Withdraw</h4>,
          body: !isNil(achWithdrawal) && (
            <div className="AddFundsModal-confirmScreenContent">
              <Icon
                name="checkmark"
                width="30"
                height="30"
                fill="#00854D"
                className="AddFundsModal-marginBottom"
              />
              <h2 className="AddFundsModal-confirmScreenHeader">
                Withdrawal initiated
              </h2>
              <p className="AddFundsModal-confirmScreenDescription">
                Your withdrawal of {formatAsDollarAmount(achWithdrawal.amount)}{' '}
                will be available <br/> in 2–4 business days
              </p>
              <Button
                variant="primary"
                size="large"
                className="DepositCrypto-primaryButton"
                onClick={() => modal.setShow(false)}
              >
                Done
              </Button>
            </div>
          ),
          noBackButton: true,
        },
        FACIAL_VERIFICATION: {
          heading: <h4>Withdraw</h4>,
          body: (push) => (
            <div className="AddFundsModal-sumsub-facial-verification">
              <Sumsub
                levelName={KycVerificationLevels.FacialVerification}
                onApplicantLoaded={(applicantActionId) => setFacialVerificationApplicantActionId(applicantActionId)}
                onFailure={(message) => {
                  setTitle('There was an issue');
                  setResult(message || 'We were unable to verify you. Please try again or contact customer support.');
                  push('FACIAL_VERIFICATION_RESULT');
                }}
                onPending={() => {
                  setTitle(FACE_AUTH_RESULTS_TITLE.YELLOW);
                  setResult(FACE_AUTH_RESULTS_CONTENT.YELLOW);
                  push('FACIAL_VERIFICATION_RESULT');
                }}
                onSuccess={(result) => {
                  setFacialVerificationResult(result);
                  setTitle(FACE_AUTH_RESULTS_TITLE[result]);
                  setResult(FACE_AUTH_RESULTS_CONTENT[result]);
                  push('FACIAL_VERIFICATION_RESULT');
                }}
              />
            </div>
          ),
          noBackButton: true,
        },
        FACIAL_VERIFICATION_RESULT: {
          heading: <h4>Withdraw</h4>,
          body: (push) => (
            <div className="AddFundsModal-sumsub-facial-verification">
              <h3>
                {title}
              </h3>

              <p>
                {result}
              </p>

              <Button
                variant="primary"
                size="large"
                className="DepositCrypto-primaryButton"
                onClick={() => {
                  push('MAIN');
                  checkFacialVerificationStatus();
                }}
              >
                OK
              </Button>
            </div>
          ),
          noBackButton: true,
        },
      }}
    />
  )
}

export default RemoveFundsModal
