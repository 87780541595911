import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId, isNil, map } from 'lodash'
import './summaryPanel.scss'
import Loading from '@pv3-shared-components/Loading'

const SummaryFigure = ({ className, dataTestId, variant, value, label, loading }) => {
  return (
    <div className={`SummaryFigure ${className || ''}`}>
      <div
        className={`SummaryFigure-value${
          !isNil(variant)
            ? map(variant, (v) => ` SummaryFigure-value--${v}`).join('')
            : ''
        }`} 
        data-testid={dataTestId}
      >
        {loading ? <Loading /> : value}
      </div>
      <div className="SummaryFigure-label">{label}</div>
    </div>
  )
}

SummaryFigure.propTypes = {
  dataTestId: PropTypes.string,
  variant: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

const SummaryPanel = ({ children, figures, rows }) => {
  return (
    <div className={`SummaryPanel ${!isNil(rows) ? 'SummaryPanel--hasRows' : ''}`}>
      {!isNil(figures) && (
        <React.Fragment>
          {map(figures, (figure, index) => (
            <SummaryFigure
              key={`${uniqueId('summarypanel-')}-${index}`}
              {...figure}
            />
          ))}
        </React.Fragment>
      )}

      {!isNil(rows) && (
        <React.Fragment>
          {map(rows, (row, index) => (
            <div className="SummaryPanel-row" key={`${uniqueId('summarypanel-row-')}-${index}`}>
              {map(row, (figure, index) => (
                <SummaryFigure
                  className={figure.className}
                  key={`${uniqueId('summarypanel-')}-${index}`}
                  {...figure}
                />
              ))}
            </div>
          ))}
        </React.Fragment>
      )}

      {children}
    </div>
  )
}

SummaryPanel.propTypes = {
  figures: PropTypes.arrayOf(
    PropTypes.shape({
      dataTestId: PropTypes.string,
      variant: PropTypes.array,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      loading: PropTypes.bool,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        dataTestId: PropTypes.string,
        variant: PropTypes.array,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        loading: PropTypes.bool,
      })
    )
  ),
}

export default SummaryPanel
