import React from 'react'
import Select from 'react-select'
import './multiselect.scss'

const MultiSelect = ({ ...rest }) => {
  return (
    <Select
      className="MultiSelect"
      classNamePrefix="MultiSelect"
      isMulti={true}
      {...rest}
    />
  )
}

export default MultiSelect
