import React from 'react'

const Pin = ({fill = '#500078', width = '24', height = '32', ...rest}) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      {...rest}
    >
      <path
        fill={fill}
        d="M64 0H32V64H64 93.5L82.1 212.1C23.7 240.7 0 293 0 320v32H384V320c0-22.5-23.7-76.5-82.1-106.7L290.5 64H320h32V0H320 64zm96 480v32h64V480 384H160v96z"/>
    </svg>
  );
}

export default Pin;
