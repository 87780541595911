import React, { useEffect, useMemo, useState } from 'react'
import './addfundsmodal.scss'
import Button from '@pv3-shared-components/Button'
import Modal from '@pv3-shared-components/Modal'
import Icon from '@pv3-shared-components/Icons'
import EnhancedFlash from '@pv3-shared-components/EnhancedFlash'
import ComponentLoader from '@pv3-components/ComponentLoader'
import WireTransfer from '../WireTransfer'
import useProfileDuck from '@pv3-hooks/useProfileDuck'
import useAssetsDuck from '../../hooks/useAssetsDuck'
import {eq, filter, gt, isNil, isNull, isUndefined, some, upperCase} from 'lodash'
import Currency from '../shared/Currency'
import moment from 'moment';
import {
  API_PATHS,
  APP_PATHS,
  FACE_AUTH_RESULTS_CONTENT,
  FACE_AUTH_RESULTS_TITLE,
  KYC_VERIFICATION_LEVELS, KYC_VERIFICATION_STATUSES, PROFILE_TYPES
} from '../../constants'
import CopyToClipboard from 'react-copy-to-clipboard'
import QRCode from 'qrcode.react'
import useAchDuck from '../../hooks/useAchDuck'
import { useHistory } from 'react-router'
import AchDepositForm from '../AchDepositForm'
import { formatAsDollarAmount } from '../../utils/utils'
import Flash from '@pv3-shared-components/Flash'
import SelectableAssetList from '../SelectableAssetList'
import Sumsub from "../shared/Sumsub";
import Logo from "../shared/Logo";
import TokenRequest from "../../utils/tokenRequest";
import useAccount from "../../hooks/useAccount";

const AddFundsModal = ({ modal }) => {
  const { facialVerificationApplicantActionId, facialVerificationResult, getCryptoAllowed, getFacialVerificationResult, getUsdAllowed, kycVerificationStatus, setFacialVerificationApplicantActionId, setFacialVerificationResult, testFacialVerificationExpiration, profile } = useProfileDuck()
  const { assets } = useAssetsDuck()
  const { idToken } = useAccount();
  const [depositAsset, setDepositAsset] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [addressRetrievalError, setDepositRetrievalError] = useState(false)
  const [address, setAddress] = useState()
  const [tag, setTag] = useState();
  const { getAchItems, items: achItems, initiateAchDeposit } = useAchDuck()
  const history = useHistory()
  const [achDeposit, setAchDeposit] = useState()
  const [depositAccount, setDepositAccount] = useState()
  const [depositError, setDepositError] = useState()
  const [isSubmittingDeposit, setIsSubmittingDeposit] = useState(false)
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false)
  const [title, setTitle] = useState('');
  const [result, setResult] = useState('');

  const { post } = new TokenRequest(idToken);

  const checkFacialVerificationResult = async () => {
    if(!facialVerificationApplicantActionId || /GREEN|RED/.test(facialVerificationResult)) {
      return;
    }

    await getFacialVerificationResult(facialVerificationApplicantActionId);
  };

  const fetchAddress = async () => {
    setAddress(null)
    setDepositRetrievalError(false)
    try {
      setIsLoading(true)
      const response = await post(API_PATHS.COIN.DEPOSIT, {
        AssetId: depositAsset.id,
        applicantActionId: facialVerificationApplicantActionId,
      })

      if (!isNull(response.data.error)) throw new Error(response.data.error)

      setAddress(response.data.address)
      setTag(response.data.tag);
    } catch (e) {
      setDepositRetrievalError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const allowedCryptoAssets = useMemo(() => {
    return filter(
      assets,
      ({ can_deposit, is_fiat, is_disabled }) =>
        can_deposit && !is_fiat && !is_disabled
    )
  }, [assets])

  const submitAchDeposit = async (callback) => {
    try {
      setIsSubmittingDeposit(true)
      await initiateAchDeposit({
        ...achDeposit,
        applicantActionId: facialVerificationApplicantActionId,
      })
      callback()
    } catch (e) {
      setDepositError(e)
      document.querySelector('.Modal').scrollTo(0, 0);
    } finally {
      setIsSubmittingDeposit(false)
    }
  }

  useEffect(() => {
    if (isUndefined(depositAsset)) return

    fetchAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositAsset])

  useEffect(() => {
    setAchDeposit(null)

    if(modal.show) {
      testFacialVerificationExpiration();

      if (gt(kycVerificationStatus, 1)) {
        getAchItems();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show])

  const canDepositUsd = getUsdAllowed();
  const canDepositCrypto = getCryptoAllowed();
  const hasCompletedFacialVerification = facialVerificationResult === 'GREEN' || facialVerificationResult === 'YELLOW';
  const isBusinessProfile = profile.profileType === PROFILE_TYPES.BUSINESS;
  const profileIsVerified = kycVerificationStatus === KYC_VERIFICATION_STATUSES.VERIFIED;

  return (
    <React.Fragment>
      <Modal
        modal={modal}
        screens={{
          MAIN: {
            heading: <h4>Deposit</h4>,
            body: (push) => (
              <div className="AddFunds-body">
                <h3 className="AddFunds-header">Deposit to CoinLion from</h3>
                {!profileIsVerified ? (
                  <React.Fragment>
                    <p>
                      Your identity verification process has not been completed.  Please go to the Account Settings screen and get verified.  If you have attempted multiple times without success then please reach out to support.
                    </p>

                    <Button
                      className="AddFunds-facialAuthButton"
                      onClick={() => history.push(APP_PATHS.ACCOUNT)}
                    >
                      Go to Account Settings
                    </Button>
                  </React.Fragment>
                ) : (
                  !isBusinessProfile && (
                    <React.Fragment>
                      {hasCompletedFacialVerification ? (
                        <p>
                          You have a valid facial verification. Please continue.
                        </p>
                      ) : (
                        <p>
                          Facial Verification is required in order to proceed with any of the items below.  Click below to launch facial authorization process.
                        </p>
                      )}

                      <Button
                        className="AddFunds-facialAuthButton"
                        disabled={facialVerificationResult === 'GREEN'}
                        onClick={() => push('FACIAL_VERIFICATION')}
                      >
                        Begin Facial Verification
                      </Button>
                    </React.Fragment>
                  )
                )}

                {facialVerificationResult === 'RED' && (
                  <EnhancedFlash
                    variant="warning"
                    heading={FACE_AUTH_RESULTS_TITLE.RED}
                    subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                  />
                )}

                <Button
                  variant="secondary"
                  size="large"
                  className={`u-horizontalPadding ${
                    ((!canDepositUsd || !hasCompletedFacialVerification) && !isBusinessProfile) || !profileIsVerified ? 'AddFunds--disabledButton' : ''
                  }`}
                  onClick={() => {
                    if (
                      eq(achItems.length, 0) ||
                      !some(achItems, ({ relinkRequired }) => !relinkRequired)
                    ) {
                      modal.setShow(false)
                      history.push(APP_PATHS.ACCOUNT_BANK)
                    }
                    push('ACH_1')
                    checkFacialVerificationResult();
                  }}
                  disabled={((!canDepositUsd || !hasCompletedFacialVerification) && !isBusinessProfile) || !profileIsVerified}
                >
                  <div className="AddFunds--buttonInterior">
                    <Icon
                      name="bank"
                      fill={canDepositUsd ? '#500078' : '#848D96'}
                      height="32"
                      width="32"
                    />
                    <span className="AddFunds--buttonText">Bank Account</span>
                    <Icon
                      name="caretright"
                      fill={canDepositUsd ? '#500078' : '#848D96'}
                    />
                  </div>
                </Button>
                <Button
                  size="large"
                  variant="secondary"
                  className={`u-horizontalPadding ${
                    ((!canDepositUsd || !hasCompletedFacialVerification) && !isBusinessProfile) || !profileIsVerified ? 'AddFunds--disabledButton' : ''
                  }`}
                  onClick={() => {
                    push('WIRE_TRANSFER')
                    checkFacialVerificationResult();
                  }}
                  disabled={((!canDepositUsd || !hasCompletedFacialVerification) && !isBusinessProfile) || !profileIsVerified}
                >
                  <div className="AddFunds--buttonInterior">
                    <Icon
                      name="check"
                      fill={canDepositUsd ? '#500078' : '#848D96'}
                      height="32"
                      width="32"
                    />
                    <span className="AddFunds--buttonText">Wire Transfer</span>
                    <Icon
                      name="caretright"
                      fill={canDepositUsd ? '#500078' : '#848D96'}
                    />
                  </div>
                </Button>
                <Button
                  size="large"
                  variant="secondary"
                  className={`u-horizontalPadding ${
                    ((!canDepositCrypto || !hasCompletedFacialVerification) && !isBusinessProfile) || !profileIsVerified ? 'AddFunds--disabledButton' : ''
                  }`}
                  onClick={() => {
                    push('CRYPTO_ADDRESS_1')
                    checkFacialVerificationResult();
                  }}
                  disabled={(!hasCompletedFacialVerification && !isBusinessProfile) || !profileIsVerified}
                >
                  <div className="AddFunds--buttonInterior">
                    <Icon
                      name="qr"
                      fill={canDepositUsd ? '#500078' : '#848D96'}
                      height="32"
                      width="32"
                    />
                    <span className="AddFunds--buttonText">
                      External Crypto Address
                    </span>
                    <Icon name="caretright" fill="#500078" />
                  </div>
                </Button>

                {!canDepositUsd && (
                  <EnhancedFlash
                    variant="warning"
                    heading="You are in a jurisdiction where transactions with USD are not available."
                    subheading={
                      <span>
                        For more information, see our{' '}
                        <a
                          href="https://help.coinlion.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          help page
                        </a>
                      </span>
                    }
                  />
                )}

                {!canDepositCrypto && (
                  <EnhancedFlash
                    variant="warning"
                    heading="You are in a jurisdiction where crypto transactions are not available."
                    subheading={
                      <span>
                        For more information, see our{' '}
                        <a
                          href="https://help.coinlion.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          help page
                        </a>
                      </span>
                    }
                  />
                )}
              </div>
            ),
          },
          WIRE_TRANSFER: {
            heading: <h4>Deposit</h4>,
            body: isBusinessProfile || facialVerificationResult === 'GREEN' ? <WireTransfer /> : (
              <EnhancedFlash
                variant="warning"
                heading={FACE_AUTH_RESULTS_TITLE.RED}
                subheading={FACE_AUTH_RESULTS_CONTENT.RED}
              />
            ),
          },
          CRYPTO_ADDRESS_1: {
            heading: <h4>Deposit</h4>,
            body: (push) => {
              if(isBusinessProfile || facialVerificationResult === 'GREEN') {
                return (
                  <SelectableAssetList
                    assets={allowedCryptoAssets}
                    onSelect={(asset) => {
                      setDepositRetrievalError(false);
                      setDepositAsset(asset)
                      push('CRYPTO_ADDRESS_2')
                      checkFacialVerificationResult();
                    }}
                  />
                );
              } else {
                return (
                  <EnhancedFlash
                    variant="warning"
                    heading={FACE_AUTH_RESULTS_TITLE.RED}
                    subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                  />
                );
              }
            },
          },
          CRYPTO_ADDRESS_2: {
            heading: <h4>Deposit</h4>,
            body: isUndefined(depositAsset) ? (
              <React.Fragment />
            ) : (
              <div className="DepositCrypto-modalContent">
                <ComponentLoader isLoading={isLoading} />
                <div className="ModalCryptoList-row">
                  <div className="ModalCryptoList-currencyContainer">
                    {eq(depositAsset.id, 'lion') ? (
                      <Logo />
                    ) : (
                      <Currency type={depositAsset.id} />
                    )}
                  </div>
                  <p>
                    {depositAsset.asset_name} ({upperCase(depositAsset.id)})
                  </p>
                </div>
                {!isNil(address) && (
                  <React.Fragment>
                    <EnhancedFlash
                      variant="warning"
                      heading={`Send only ${upperCase(
                        depositAsset.id
                      )} to this deposit address.`}
                      subheading="Sending any other currency to this address will result in the loss of your deposit"
                    />
                    <hr className="u-divider u-lightGrey" />
                    <CopyToClipboard
                      text={address}
                      options={{ format: 'text/plain' }}
                      onCopy={() => {
                        setShowCopiedToClipboard(true)
                        setTimeout(() => setShowCopiedToClipboard(false), 2000)
                      }}
                    >
                      <div className="DepositCrypto-container">
                        <p className="DepositCrypto-addressLabel">
                          {upperCase(depositAsset.id)} Deposit Address
                        </p>
                        <div className="DepositCrypto-addressBox">{address}</div>
                        <Button
                          variant="blank"
                          className="DepositCrypto-copyToClipboard"
                        >
                          <Icon name="copy" width="24" />
                        </Button>
                      </div>
                    </CopyToClipboard>

                    {!isNil(tag) && (
                      <>
                        <CopyToClipboard
                          text={tag}
                          options={{ format: 'text/plain' }}
                          onCopy={() => {
                            setShowCopiedToClipboard(true)
                            setTimeout(() => setShowCopiedToClipboard(false), 2000)
                          }}
                        >
                          <div className="DepositCrypto-container">
                            <p className="DepositCrypto-addressLabel">
                              Memo/Tag
                            </p>
                            <div className="DepositCrypto-addressBox">{tag}</div>
                            <Button
                              variant="blank"
                              className="DepositCrypto-copyToClipboard"
                            >
                              <Icon name="copy" width="24" />
                            </Button>
                          </div>
                        </CopyToClipboard>

                        <p className="DepositCrypto-tagWarning">
                          There is a very high probability that you need a memo/tag in order for this deposit to be credited to your account. Please make sure you add this to your transaction or you risk losing the transaction.
                        </p>
                      </>
                    )}

                    <div className="DepositCrypto-copiedToClipboardAlertContainer">
                      <div
                        className={`DepositCrypto-copiedToClipboardAlert ${
                          showCopiedToClipboard ? 'visible' : ''
                        }`}
                      >
                        <Flash
                          className="Flash--success"
                          hideCloseButton
                          iconSize={20}
                          iconFill="#00854d"
                        >
                          Successfully copied to clipboard
                        </Flash>
                      </div>
                    </div>


                    {isNil(tag) && (
                      <>
                        <hr className="u-divider u-lightGrey" />

                        <div className="DepositCrypto-qrContainer">
                          <QRCode size={190} value={address} renderAs="svg" />
                        </div>
                      </>
                    )}
                  </React.Fragment>
                )}
                {addressRetrievalError && (
                  <p className="DepositCrypto-errorText">
                    There was an error retrieving the address for deposit. Please
                    try again later.
                  </p>
                )}
              </div>
            ),
          },
          ACH_1: {
            heading: <h4>Deposit</h4>,
            body: (push) => (
              <AchDepositForm
                deposit={achDeposit}
                submitAction={(formData, selectedDepositAccount) => {
                  setAchDeposit(formData)
                  setDepositAccount(selectedDepositAccount)
                  setDepositError(null)
                  push('ACH_2')
                }}
              />
            ),
          },
          ACH_2: {
            heading: <h4>Deposit</h4>,
            body: (push) =>
              !isNil(achDeposit) &&
              !isNil(depositAccount) && (
                <div>
                  {!isNil(depositError) && (
                    <Flash danger onDismiss={() => setDepositError(null)}>
                      {depositError}
                    </Flash>
                  )}
                  <div>
                    <div className="DepositCrypto-dataRow">
                      <p className="DepositCrypto-labelText">Available</p>
                      <p className="DepositCrypto-dataText">1 Day</p>
                    </div>
                    <div className="DepositCrypto-dataRow">
                      <p className="DepositCrypto-labelText">Amount</p>
                      <p className="DepositCrypto-dataText">
                        {formatAsDollarAmount(achDeposit.amount, true)}
                      </p>
                    </div>
                    <div className="DepositCrypto-dataRow">
                      <p className="DepositCrypto-labelText">Fee</p>
                      <p className="DepositCrypto-dataText">--</p>
                    </div>
                  </div>
                  <hr className="u-divider DepositCrypto-divider" />
                  <div className="DepositCrypto-dataRow">
                    <p className="DepositCrypto-labelText">Total</p>
                    <p className="DepositCrypto-dataText DepositCrypto-boldText">
                      {formatAsDollarAmount(achDeposit.amount)}
                    </p>
                  </div>

                  <div className="AchDepositForm-partyBox u-unchanging">
                    <div className="AchDepositForm-bankIcon">
                      <Icon name="bank" height="30" width="30" />
                    </div>
                    <p className="DepositCrypto-selectedAccount">
                      {depositAccount.name} x{depositAccount.mask}
                    </p>
                  </div>

                  <EnhancedFlash
                    variant="info"
                    heading="ACH Deposits take 1 calendar day"
                  >
                    <p>ACH daily cutoff is 2:30 PM CT.</p>
                    <p>ACH deposits will be credited to your CoinLion account one calendar day after the 2:30 PM CT upload time. This ACH transaction will be taken from the account you specified between {moment().format('MM/DD')} and {moment().add(2, 'days').format('MM/DD')}.</p>
                    <p>To protect against fraud and chargeback schemes, ACH deposits cannot be withdrawn from the platform in the form of crypto within 30 days of deposit.</p>
                    <p>Any deposit that is reversed as an intentional chargeback will result in that account being locked and assets frozen until further notice. This does not include a failed ACH transaction. You can read our full policy on withdrawals and charge backs here.</p>
                    <p>By pressing "Confirm Deposit", you are authorizing Coin Lion, LLC to initiate a debit of the bank account listed above and you also agree that this ACH transaction complies with all applicable laws. Once the payment is authorized, there cannot be any changes or corrections. To revoke “reverse” this transaction you can withdraw funds from your Coin Lion account by initiating a separate ACH withdraw transaction once the funds have reached your Coin Lion account. For questions contact <a href="mailto:info@coinlion.com">info@coinlion.com</a>.</p>
                  </EnhancedFlash>

                  {!isBusinessProfile && facialVerificationResult !== 'GREEN' && (
                    <EnhancedFlash
                      variant="warning"
                      heading={FACE_AUTH_RESULTS_TITLE.RED}
                      subheading={FACE_AUTH_RESULTS_CONTENT.RED}
                    />
                  )}

                  <Button
                    disabled={!isBusinessProfile && facialVerificationResult !== 'GREEN'}
                    variant="primary"
                    size="large"
                    className="DepositCrypto-primaryButton u-marginTop"
                    onClick={() => {
                      submitAchDeposit(() => push('ACH_3'))
                    }}
                    isLoading={isSubmittingDeposit}
                  >
                    Confirm deposit
                  </Button>
                </div>
              ),
          },
          ACH_3: {
            heading: <h4>Deposit</h4>,
            body: !isNil(achDeposit) && (
              <div className="AddFundsModal-confirmScreenContent">
                <Icon
                  name="checkmark"
                  width="30"
                  height="30"
                  fill="#00854D"
                  className="AddFundsModal-marginBottom"
                />
                <h2 className="AddFundsModal-confirmScreenHeader">
                  Deposit initiated
                </h2>
                <Button
                  variant="primary"
                  size="large"
                  className="DepositCrypto-primaryButton"
                  onClick={() => modal.setShow(false)}
                >
                  Done
                </Button>
              </div>
            ),
            noBackButton: true,
          },
          FACIAL_VERIFICATION: {
            heading: <h4>Deposit</h4>,
            body: (push) => (
              <div className="AddFundsModal-sumsub-facial-verification">
                <Sumsub
                  levelName={KYC_VERIFICATION_LEVELS.FACIAL_VERIFICATION}
                  onApplicantLoaded={(applicantActionId) => setFacialVerificationApplicantActionId(applicantActionId)}
                  onFailure={(message) => {
                    setTitle('There was an issue');
                    setResult(message || 'We were unable to verify you. Please try again or contact customer support.');
                    push('FACIAL_VERIFICATION_RESULT');
                  }}
                  onPending={() => {
                    setTitle(FACE_AUTH_RESULTS_TITLE.YELLOW);
                    setResult(FACE_AUTH_RESULTS_CONTENT.YELLOW);
                    push('FACIAL_VERIFICATION_RESULT');
                  }}
                  onSuccess={(result) => {
                    setFacialVerificationResult(result);
                    setTitle(FACE_AUTH_RESULTS_TITLE[result]);
                    setResult(FACE_AUTH_RESULTS_CONTENT[result]);
                    push('FACIAL_VERIFICATION_RESULT');
                  }}
                />
              </div>
            ),
            noBackButton: true,
          },
          FACIAL_VERIFICATION_RESULT: {
            heading: <h4>Deposit</h4>,
            body: (push) => (
              <div className="AddFundsModal-sumsub-facial-verification">
                <h3>
                  {title}
                </h3>

                <p>
                  {result}
                </p>

                <Button
                  variant="primary"
                  size="large"
                  className="DepositCrypto-primaryButton"
                  onClick={() => {
                    push('MAIN');
                    checkFacialVerificationResult();
                  }}
                >
                  OK
                </Button>
              </div>
            ),
            noBackButton: true,
          },
        }}
      />
    </React.Fragment>
  )
}

export default AddFundsModal
