import { format, subMinutes } from 'date-fns'
import { chain, eq, isUndefined } from 'lodash'
import React, { useMemo } from 'react'
import useWalletsDuck from '../../hooks/useWalletsDuck'
import Table from '@pv3-shared-components/Table'
import { fixFloatingPointErrors, formatAsDollarAmount } from '../../utils/utils'
import './banktransactionstable.scss'
import useWindowWidth from '@pv3-hooks/useWindowWidth'

const BankTransactionsTable = () => {
  const { transfers } = useWalletsDuck()
  const isBig = useWindowWidth()

  const bankTransfers = useMemo(
    () =>
      chain(transfers)
        .filter((transfer) => eq(transfer.asset, 'usd'))
        .take(5)
        .value(),
    [transfers]
  )

  const columns = useMemo(
    () =>
      isBig
        ? [
            {
              Header: 'Date',
              id: 'date',
              className: 'BankTransactionsTable-dateCell',
              width: 100,
              accessor: (d) => {
                const date = new Date(d.date)

                const localTimezoneOffset = new Date().getTimezoneOffset()

                return (
                  <React.Fragment>
                    {format(subMinutes(date, localTimezoneOffset), 'MM/dd/yy')}
                    <br />
                    {format(subMinutes(date, localTimezoneOffset), 'h:mm a')}
                  </React.Fragment>
                )
              },
            },
            {
              Header: 'Operation',
              id: 'operation',
              width: 125,
              accessor: (d) => `${d.type}`,
            },
            {
              Header: 'Status',
              id: 'status',
              width: 140,
              Cell: ({ original }) => (
                <div className={`DepositWithdrawalStatus ${original.status}`}>
                  {eq(original.status, 'AwaitingConfirmation')
                    ? 'Pending'
                    : original.status}
                </div>
              ),
            },
            {
              Header: 'amount',
              id: 'amount',
              className: 'DepositsWithdrawalsTable-amountCell',
              accessor: (d) =>
                `${
                  eq(d.asset, 'usd')
                    ? formatAsDollarAmount(d.amount)
                    : fixFloatingPointErrors(d.amount, 2)
                }`,
            },
          ]
        : [
            {
              Header: '',
              id: 'left',
              className: 'MobileCell',
              accessor: (d) => {
                const date = new Date(d.date)
                return (
                  <div className="BankTransactionsTable-mobileDateContainer">
                    {format(date, 'MM/dd/yy')}
                    <br />
                    {format(date, 'h:mm a')}
                  </div>
                )
              },
            },
            {
              Header: '',
              id: 'right',
              className: 'MobileCell  u-justifyend u-tr',
              Cell: ({ original }) => (
                <div>
                  <p className="u-3xsmallMarginBottom">{original.type}</p>
                  <div className={`DepositWithdrawalStatus ${original.status}`}>
                    {eq(original.status, 'AwaitingConfirmation')
                      ? 'Pending'
                      : original.status}
                  </div>
                  <p>
                    {eq(original.asset, 'usd')
                      ? formatAsDollarAmount(original.amount)
                      : fixFloatingPointErrors(original.amount, 2)}
                  </p>
                </div>
              ),
            },
          ],
    [isBig]
  )

  return (
    <div className="BankTransactionsTable">
      {!isUndefined(bankTransfers) && (
        <Table
          columns={columns}
          data={bankTransfers}
          emptyHeadline="No deposits &amp; withdrawals to show"
        />
      )}
    </div>
  )
}

export default BankTransactionsTable
