import './lionSummaryPanel.scss';

import React, {useEffect, useMemo} from 'react';
import moment from 'moment';
import {Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Logo from '../shared/Logo';
import Button from "../shared/Button";
import useMarketsDuck from "../../hooks/useMarketsDuck";
import {isObjectNullOrEmpty} from "../../utils/utils";
import useWalletsDuck from "../../hooks/useWalletsDuck";
import BuySellModal from "../BuySellModal";
import useModal from "../../hooks/useModal";
import ArrowRight from "../shared/Icons/ArrowRight";
import useProfileDuck from "../../hooks/useProfileDuck";

const periods = ['24H', 'W', 'M', '3M', '1Y'];

const CustomTooltip = ({ active, payload, label, selectedTimePeriod }) => {
  const generateDateFormat = () => {
    if(selectedTimePeriod === periods[0] || selectedTimePeriod === periods[1]) {
      return 'H:mma';
    }

    return 'MMM D, YY';
  }
  if (active && payload && payload.length) {
    return (
      <div className="LionSummaryPanel--tooltip">
        <div className="LionSummaryPanel--tooltipDate">{moment(label).format(generateDateFormat())}</div>
        <div className="LionSummaryPanel--tooltipValue">{payload[0].value}</div>
      </div>
    );
  }

  return null;
};

const LionSummaryPanel = () => {
  const { getLionHistory, lionHistory, lionHistorySelectedTimePeriod: selectedTimePeriod, setLionHistorySelectedTimePeriod: setSelectedTimePeriod } = useMarketsDuck();
  const { usdPrices } = useWalletsDuck();
  const { profile } = useProfileDuck();

  const modal = useModal();

  const handleTimePeriodClick = (period) => {
    if(period === selectedTimePeriod) {
      return;
    }

    setSelectedTimePeriod(period);
    requestLionHistory(period);
  };

  const requestLionHistory = (period) => {
    period = period || selectedTimePeriod;
    let startDate = moment.utc();
    let type = '1h';
    let count = 24;
    switch(period) {
      case '24H':
        startDate = startDate.subtract(24, 'hours');
        break;
      case 'W':
        startDate = startDate.subtract(1, 'weeks');
        count = 168;
        break;
      case 'M':
        startDate = startDate.subtract(1, 'months');
        type = '1d';
        count = 31;
        break;
      case '3M':
        startDate = startDate.subtract(3, 'months');
        type = '1d';
        count = 92;
        break;
      case '1Y':
        startDate = startDate.subtract(1, 'years');
        type = '1d';
        count = 366;
        break;
      default:
        return;
    }

    getLionHistory({
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
      type,
      count,
    });
  }

  useEffect(() => {
    requestLionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedChange = useMemo(
    () => {
      if(isObjectNullOrEmpty(lionHistory)) {
        return '--';
      }

      const change = Math.round((lionHistory.change + Number.EPSILON) * 10) / 10;
      if (change > 0) {
        return (
          <div className="TradeHistory-gain">{change.toFixed(1)}%</div>
        )
      } else if (change) {
        return (
          <div className="TradeHistory-loss">{change.toFixed(1)}%</div>
        )
      }
      return '0%'
    }, [lionHistory]
  );

  return (
    <div className="LionSummaryPanel">
      <header className="LionSummaryPanel--header">
        <div className="LionSummaryPanel--logo">
          <Logo />
        </div>

        <div className="LionSummaryPanel--name">
          <strong>
            LION
          </strong>

          CoinLion Token
        </div>

        {formattedChange != null && (
          <div className="LionSummaryPanel--change">
            {formattedChange}
          </div>
        )}
      </header>

      <div className="LionSummaryPanel--timePeriods">
        {periods.map((p) => (
          <Button
            className={`.u-buttonReset LionSummaryPanel--timePeriodButton ${selectedTimePeriod === p ? 'active' : ''}`}
            onClick={() => handleTimePeriodClick(p)}
          >
            {p}
          </Button>
        ))}
      </div>

      {!isObjectNullOrEmpty(lionHistory) && (
        <div className="LionSummaryPanel--chartContainer">
          <div className="LionSummaryPanel--chart-minmax">
            {lionHistory.maximum || '--'}
          </div>

          <div className="LionSummaryPanel--chart">
            <ResponsiveContainer height={64}>
              <ComposedChart data={lionHistory.data} margin={{top: 0, bottom: 0, left: 0, right: 0}}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={profile.darkMode ? '#500078' : '#9C3C9A'} stopOpacity={0.36}/>
                    <stop offset="95%" stopColor={profile.darkMode ? '#323232' : '#F5F7F9'} stopOpacity={0.0}/>
                  </linearGradient>
                </defs>
                <Tooltip content={<CustomTooltip selectedTimePeriod={selectedTimePeriod} />} />
                <Line isAnimationActive={false} dataKey="close" stroke={profile.hasDarkTheme ? '#edc4ed' : '#9C3C9A'} strokeWidth={3} dot={false} />
                <YAxis hide={true} domain={[lionHistory.minimum * .99, lionHistory.maximum * 1.01]} />
                <XAxis hide={true} dataKey="timecode" type="number" domain={[lionHistory.startDate, lionHistory.endDate]} tickCount={5} />
                <Area isAnimationActive={false} dataKey="close" stroke="false" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <div className="LionSummaryPanel--chart-minmax">
            {lionHistory.minimum || '--'}
          </div>
        </div>
      )}

      <footer className="LionSummaryPanel--footer">
        <div className="LionSummaryPanel--buy">
          <button
            type="button"
            className="u-buttonReset"
            onClick={() => {
              modal.setShow(true)
            }}
          >
            Buy/Sell <ArrowRight />
          </button>
        </div>

        {usdPrices && usdPrices.lion && (
          <div className="LionSummaryPanel--price">
            ${usdPrices.lion}
          </div>
        )}
      </footer>

      {!isObjectNullOrEmpty(lionHistory) && (
        <BuySellModal modal={modal} assetId="lion" />
      )}
    </div>
  );
};

export default LionSummaryPanel;
